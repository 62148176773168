export const formatPrice = (price) => {
  if (price === undefined) {
    return null;
  }

  if (price === "Color N/A") {
    return "Color N/A";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);
};
