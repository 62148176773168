import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";
import { formatPrice } from "utilities/formatPrice";
import { useSelector } from "react-redux";

const ItemSummary = ({ orderSummary }) => {
  const { vendorCode } = useParams();
  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const displayCartData = useSelector(state => state.carts?.carts)

  useEffect(() => {
    if (displayCartData) {
      const vendorCartData = displayCartData[vendorCode];
      if (vendorCartData) {
        const newCartItems = vendorCartData["items"];
        setCartItems(newCartItems);
      }
    }
  }, [displayCartData, vendorCode]);

  useEffect(() => {
    const newTotalQuantity = cartItems.reduce(
      (total, item) => total + item.quantity,
      0,
    );
    setTotalQuantity(newTotalQuantity);
  }, [cartItems]);

  const groupCartItems = () => {
    return cartItems.reduce((groups, item) => {
      const key = `${item.style}_${item.colorDesc}`;
      if (!groups[key]) {
        groups[key] = {
          styleNumber: item.style,
          colorDesc: item.colorDesc,
          styleName: item.description,
          items: [],
          totalQuantity: 0,
          totalSubtotal: 0,
          uniqueSizes: new Set(),
          uniqueWarehouses: new Set(),
        };
      }
      groups[key].items.push(item);
      groups[key].totalQuantity += item.quantity;
      groups[key].totalSubtotal += item.totalPrice;
      groups[key].uniqueSizes.add(item.size);
      groups[key].uniqueWarehouses.add(item.warehouse);
      return groups;
    }, {});
  };

  const groupedCartItems = Object.values(groupCartItems());

  return (
    <Fragment>
      <TableContainer
        component={Paper}
        className="confirmationSection"
        sx={{
          mt: 8,
          borderTop: "none",
          boxShadow: "none",
          backgroundColor: "white",
          borderRadius: "0px",
        }}
        elevation={0}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '10%',
                  whiteSpace: "nowrap",
                }}
                className="bg-dustyBlue"
              >
                Style #
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '23%',
                }}
                className="bg-dustyBlue"
              >
                Style Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '18%',
                }}
                className="bg-dustyBlue"
              >
                Color
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '10%',
                }}
                className="bg-dustyBlue"
              >
                Size
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '13%',
                }}
                className="bg-dustyBlue"
              >
                Warehouse
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '8%',
                }}
                className="bg-dustyBlue"
              >
                Quantity
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                  borderBottom: 0,
                  width: '15%',
                  pr: 6,
                }}
                className="bg-dustyBlue"
              >
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedCartItems.map((group, index) => (
              <React.Fragment key={group.styleNumber + '_' + group.colorDesc}>
                <TableRow>
                  <TableCell
                    style={{ padding: 0 }}
                    colSpan={7}
                    sx={{
                      borderBottom: index === groupedCartItems.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)'
                    }}
                  >
                    <Accordion sx={{ boxShadow: 'none', backgroundColor: index % 2 === 0 ? "rgba(239, 243, 245, 1)" : "#fff", borderBottom: 'none' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                      >
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                          <div style={{ width: '10%', flexShrink: 0, textAlign: 'left', paddingLeft: '4px' }}>{group.styleNumber}</div>
                          <div style={{ width: '24%', flexShrink: 1, textAlign: 'left', paddingRight: '18px' }}>{group.styleName}</div>
                          <div style={{ width: '20%', flexShrink: 1, textAlign: 'left', paddingLeft: '8px', marginRight: 2, }}>{group.colorDesc}</div>
                          <div style={{ width: '11%', flexShrink: 1, textAlign: 'left' }}>
                            {group.uniqueSizes.size > 1 ? "Multiple" : [...group.uniqueSizes][0]}
                          </div>
                          <div style={{ width: '13%', flexShrink: 1, textAlign: 'left' }}>
                            {group.uniqueWarehouses.size > 1 ? "Multiple" : [...group.uniqueWarehouses][0]}
                          </div>
                          <div style={{ width: '8%', flexShrink: 1, textAlign: 'right' }}>{group.totalQuantity}</div>
                          <div style={{ width: '13%', flexShrink: 1, textAlign: 'right' }}>
                            {formatPrice(group.totalSubtotal)}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          paddingRight: '32px',
                          paddingY: 0,
                          borderTop: "2px solid lightgray",
                          borderBottom: '2px solid lightgray'
                        }}>
                        <Table>
                          <TableBody>
                            {group.items.map((item, idx) => (
                              <TableRow
                                key={idx}
                                sx={{
                                  borderBottom: 'none'
                                }}
                              >
                                <TableCell sx={{ width: '8%', paddingLeft: '4px', textAlign: 'left', borderBottom: 'none' }}></TableCell>
                                <TableCell sx={{ width: '24%', textAlign: 'left', borderBottom: 'none' }}></TableCell>
                                <TableCell sx={{ width: '19%', paddingLeft: '8px', textAlign: 'left', borderBottom: 'none' }}></TableCell>
                                <TableCell sx={{ width: '8%', textAlign: 'left', paddingLeft: '0px', borderBottom: 'none' }}>{item.size}</TableCell>
                                <TableCell sx={{ width: '14%', textAlign: 'left', borderBottom: 'none' }}>{item.warehouse}</TableCell>
                                <TableCell sx={{ width: '9%', textAlign: 'right', borderBottom: 'none' }}>{item.quantity}</TableCell>
                                <TableCell sx={{ width: '12%', textAlign: 'right', borderBottom: 'none' }}>{formatPrice(item.totalPrice)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            <TableRow sx={{ borderTop: "1px dashed lightgray" }}>
              <TableCell colSpan={4} align="right" sx={{ borderBottom: 0 }}></TableCell>
              <TableCell align="right" sx={{ borderBottom: 0, pr: 9, fontWeight: "bold" }}>
                Total:
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 0, pr: 2, fontWeight: "bold" }}>
                {totalQuantity}
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 0, pr: 6, fontWeight: "bold" }}>
                ${orderSummary.subTotal.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        className="confirmationSection"
        sx={{
          mt: 7,
          width: "400px",
          borderTop: "none",
          pb: 2,
          pt: 0,
          mx: "auto",
          backgroundColor: "white",
        }}
      >
        <Box className="bg-dustyBlue" sx={{ py: 1 }}>
          <Typography
            variant="h6"
            className="tex-charcoal font-bold text-center"
          >
            Order Summary
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ pr: 2, pt: 2 }}>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign="right">
              Subtotal:
            </Typography>
            <Typography variant="body1" textAlign="right">
              Shipping:
            </Typography>
            {orderSummary.creditCardLostCashDiscount > 0 && (
              <Typography variant="body1" textAlign="right">
                CC Lost Cash Discount:
              </Typography>
            )}
            <Typography variant="body1" fontWeight="bold" textAlign="right">
              Grand Total:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign="right">
              ${orderSummary.subTotal.toFixed(2)}
            </Typography>
            <Typography variant="body1" textAlign="right">
              ${orderSummary.shippingCost.toFixed(2)}
            </Typography>
            {orderSummary.creditCardLostCashDiscount > 0 && (
              <Typography variant="body1" textAlign="right">
                ${orderSummary.creditCardLostCashDiscount.toFixed(2)}
              </Typography>
            )}
            <Typography variant="body1" fontWeight="bold" textAlign="right">
              ${orderSummary.total.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ItemSummary;
