const formatTextWithCasing = (word, appendage="") => {
  if (!word) {
    return "";
  }

  const subWords = word.split(" ").map((subWord) => {
    const capitalizedFirstLetter = subWord.trim().charAt(0).toUpperCase();
    const restOfString = subWord.trim().slice(1).toLowerCase();
    return (capitalizedFirstLetter + restOfString).trim();
  });
  return subWords.filter((subWord) => subWord).join(" ") + appendage;
};

export const formatShippingAddress = (addr) => {
  if (addr === undefined) {
    return null;
  }

  let formattedAddr = "";
  if (addr["name"]) {
    formattedAddr += addr["name"] + " - ";
  }
  formattedAddr +=
    formatTextWithCasing(addr["address2"], " ") +
    formatTextWithCasing(addr["city"], ", ") +
    addr["state"] +
    " " +
    addr["zip"];

  return formattedAddr;
};
