import { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import Button from "@mui/material/Button";
import colorHexMap from "../constants/colorSwatchHexes.js";
import { constructSwatchImageUrl } from "utilities/imageUrlUtils";

const getHexCode = (color) => {
  const formattedColor = color
    .replace(/\s/g, "")
    .replace(/\./g, "")
    .toLowerCase()
    .split("/");
  const hexKey = formattedColor.join("");
  return colorHexMap[hexKey];
};

const getColorGradient = (hexCodes) => {
  if (!hexCodes) {
    return "transparent";
  }

  const subColorCount = hexCodes.length;

  if (subColorCount === 1) {
    const color1 = hexCodes[0];
    return `linear-gradient(90deg, 
                    ${color1} 0%,
                    ${color1} 100%)`;
  } else if (subColorCount === 2) {
    const color1 = hexCodes[0];
    const color2 = hexCodes[1];
    return `linear-gradient(90deg, 
                    ${color1} 0%,
                    ${color1} 50%,
                    ${color2} 50%,
                    ${color2} 100%)`;
  } else if (subColorCount === 3) {
    const color1 = hexCodes[0];
    const color2 = hexCodes[1];
    const color3 = hexCodes[2];
    return `linear-gradient(90deg, 
                    ${color1} 0%, 
                    ${color1} 33%, 
                    ${color2} 33%, 
                    ${color2} 66%, 
                    ${color3} 66%, 
                    ${color3} 100%)`;
  } else if (subColorCount === 4) {
    const color1 = hexCodes[0];
    const color2 = hexCodes[1];
    const color3 = hexCodes[2];
    const color4 = hexCodes[3];
    return `linear-gradient(90deg, 
                    ${color1} 0%, 
                    ${color1} 25%, 
                    ${color2} 25%, 
                    ${color2} 50%, 
                    ${color3} 50%, 
                    ${color3} 75%, 
                    ${color4} 75%, 
                    ${color4} 100%)`;
  }
  return;
};

export default function ColorSwatch({
  dgiStyle,
  color,
  hasSwatchText,
  disabled = false,
}) {
  const swatchImageUrl = constructSwatchImageUrl(dgiStyle, color);
  const [imageLoaded, setImageLoaded] = useState("loading");
  const [buttonStyle, setButtonStyle] = useState({});

  // Shout out Claude
  useEffect(() => {
    const image = new Image();
    image.src = swatchImageUrl;
    image.onload = () => setImageLoaded("loaded");
    image.onerror = () => setImageLoaded("error");
  }, [swatchImageUrl, color]);

  useEffect(() => {
    if (imageLoaded === "loaded") {
      setButtonStyle({
        background: `url("${swatchImageUrl}")`,
      })
    } else if (imageLoaded === "error") {
      const colorHexCodes = getHexCode(color);
      const subColorGradient = getColorGradient(colorHexCodes);
      setButtonStyle({
        background: subColorGradient,
      })
    }
  }, [imageLoaded, color]);

  if (imageLoaded === "loading") {
    return;
  }

  return (
    <div className={styles.colorSwatchWrapper} data-is-disabled={disabled}>
      <Button
        className={styles.priceParamButton}
        data-color-param={true}
        data-long={hasSwatchText}
        style={buttonStyle}
      ></Button>
    </div>
  );
}
