import React from "react";
import { useMediaQuery } from "@mui/material";
import IAN_SIGNATURE from "../../assets/IAN_SIGNATURE.svg";
import { Box, Stack } from "@mui/material";

const StorySection = () => {
  const applySmallScreenStyling = useMediaQuery("(max-width:1000px)");
  const storyTextStyling = `max-w-[1050px] text-justify ${
    applySmallScreenStyling && "text-left text-base"
  }`;
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      className="p-[64px] w-full"
      gap="6rem"
      sx={{
        borderTop: "1px solid rgba(225, 229, 230, 1)", // grayscaleSmoke 
        borderBottom: "1px solid rgba(225, 229, 230, 1)", // grayscaleSmoke 
      }}
    >
      <div className="flex flex-col items-start w-full">
        <h1 className="mb-[1rem] text-2xl">
          An open letter from Ian: Our Story
        </h1>
        <p className={storyTextStyling}>
          My name is Ian and I was a screenprinter in North Carolina all through
          high school where I worked under Jay, the owner/operator of a local
          print shop. We always enjoyed serving our customers, working in a
          creative environment, and building relationships in our community.
          Like most custom apparel shops, we just accepted that inventory was a
          cumbersome chore. We spent countless hours locating products,
          navigating backorders, and comparing options across suppliers. And we
          trusted a few key suppliers to take good care of us.
        </p>
        <p className={storyTextStyling}>
          Several years and academic degrees later, I mentioned to my college
          roommates, Grant and Daniel, all of the issues we had while making
          purchases for our shop. They thought we might be able to solve these
          problems by combining my industry experience with their software
          engineering backgrounds. We did a deep dive with Jay and learned how
          suppliers charge different prices to every shop using a system of tiers,
          and how our reps’ commissions increased when they raised our prices.
          We watched as Jay's margins decreased and running a successful
          business became steadily more difficult. We decided to embark on a
          mission to help custom apparel shops like ours <em>and yours</em> do
          better in this challenging environment – DGI Apparel was born.
        </p>
        <p className={storyTextStyling}>
          DGI Apparel isn’t going to solve all of the challenges that your shop
          faces, but we promise that it will make inventory easier, faster, and
          cheaper. It will help you find the best products, the lowest-cost
          supplier to purchase from, and know whether you can meet your customers’
          deadlines. You’ll have access to the most extensive combined catalog
          in the industry, and one easy-to-use tool for purchasing from all the
          suppliers you’re already using.
        </p>
        <h3>“Keep It Free” Commitment</h3>
        <p className={storyTextStyling}>
          Our commitment to the custom apparel community is that we’ll keep DGI
          completely free and accessible to every custom apparel shop that
          wishes to use it. We’ll continue to support and improve DGI every day,
          operate ethically, and ensure that DGI continues to be a force for
          good in the custom decorator community. We know how hard you work to
          serve your customers, and we promise that we’ll be working just as
          hard to serve you.
        </p>
        <Box className="relative w-[250px] h-[100px]">
          <img
            src={IAN_SIGNATURE}
            alt="INSERT CEO SIGNATURE"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minHeight: "100%",
              width: "250px",
              height: "auto",
            }}
          />
        </Box>

        <span>Ian Hinkley</span>
        <span>CEO & Co-founder</span>
      </div>
      <Box className="hidden lg:block mt-[86px]">
        <img
          src={"https://d34c0c1in94n75.cloudfront.net/total_promotion_ian_pic.svg"}
          alt={null}
          style={{
            display: "block",
            height: "auto",
            maxWidth: "675px",
          }}
        />
      </Box>
    </Stack>
  );
};

export default StorySection;
