export const faqData = [
    {
      question: "Is DGI Apparel compatible with my suppliers?",
      shortAnswer:
        "DGI Apparel supports most of the major apparel suppliers in the United States.",
      fullAnswer:
        "You'll be able to see which supplier accounts you can add once you sign up. If you have any questions, give us a call at +1(919)-495-7693.",
    },
    {
      question: "Is DGI Apparel a distributor? Is it a buying group?",
      shortAnswer: "DGI Apparel is neither a distributor, nor a buying group.",
      fullAnswer:
        "We don't buy and resell products, and we don't negotiate deals on behalf of our members. DGI is a purchasing platform that helps shops order smarter and easier from the suppliers they already use. You still qualify for all supplier promotions and rebates through your suppliers.",
    },
    {
      question: "Do I change who I order from?",
      shortAnswer: "Of course not! You keep your suppliers and relationships.",
      fullAnswer:
        "DGI is a purchasing tool that brings all of your existing suppliers into one platform to make purchasing faster and easier. You still order from the suppliers you know and trust, with the same pricing, shipping, billing, and support you're used to. Your reps will still receive their commissions and your relationships with them will not be impacted at all. When you use DGI, the only thing that really changes is your shopping experience - which becomes a whole lot better!",
    },
    {
      question: "Will I still get my negotiated, GPO, or corporate pricing?",
      shortAnswer: "DGI Apparel supports all special pricing,",
      fullAnswer:
        "including buying group deals, corporate group negotiated deals, and special pricing provided by your representatives. Once you add your supplier account to DGI, your special pricing with that supplier will automatically be reflected on DGI. You'll still qualify for rebates, promotions, and loyalty programs with your suppliers, too!",
    },
    {
      question: "Why is DGI Apparel free?",
      shortAnswer:
        "The goal of DGI Apparel is to empower custom apparel shops - not profit from them.",
      fullAnswer:
        "This website is a passion project that stemmed from knowing first-hand the pain of managing several different supplier accounts. Today, we're excited to have suppliers that pay to list on the site so that we can continue to keep DGI free for shop owners. We will NEVER charge a shop to connect their suppliers and start purchasing on DGI.",
    },
    {
      question: "Do I have to add my supplier accounts to use DGI Apparel?",
      shortAnswer: "Yes, you must add your supplier accounts to use DGI.",
      fullAnswer:
        "Suppliers charge different prices to each shop, even for the exact same items. Pricing you see on each supplier's website is specific to your shop, depending on whether you're part of a GPO or have negotiated a particular pricing tier with your rep. By adding your supplier accounts, DGI can display your shop's own unique prices and local stock information while you shop, and allows you to place orders with your suppliers.",
    },
  ];