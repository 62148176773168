import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";

import styles from "./styles.module.scss";
import InventoryTable from "./InventoryTable";
import VendorTabs from "./VendorTabs";
import AlertTitle from "@mui/material/AlertTitle";
import AlertSnackbar from "../../alerts/AlertSnackbar";
import { usePostHog } from "posthog-js/react";

import { useUserStateContext } from "../../App";
import { useDataContext } from "../../App";
import { SAVINGS_THRESHOLD } from "utilities/calculateSavingsPotential";
import { formatPrice } from "utilities/formatPrice";

import axios from "../../axios/prod_client.js";
import ssClient from "../../axios/ssClient.js";
import endpoints from "../../axios/endpoints";
import getStyleNumber from "utilities/getStyleNumber";

import { useSelector, useDispatch } from "react-redux";
import { fetchCartSuccess } from "features/cartsSlice";
import { LOADING_STATES } from "features/productsSlice";

const InventoryContext = createContext();
const useInventoryContext = () => useContext(InventoryContext);
export { InventoryContext, useInventoryContext };

export default function InventoryDetails({
  hit,
  openInvDetails,
  vendorDataKeys,
  productColors,
  selectedVendorKey,
  setSelectedVendorKey,
  showCartAlert,
  setShowCartAlert,
  setCartAlertContent,
  selectedColor,
  fullDisplay = false,
  applyPadding = false,
  applyBorder,
  loadingTableData,
  setLoadingTableData,
  cheapestAvailableVendor,
  vendorProduct,
  calculateSavingsFcn,
  vendorsBestPrices = null,
}) {
  const { searchSession, setSearchSession } = useDataContext();
  const { userCreds } = useUserStateContext();
  const vendorCarts = useSelector((state) => state.carts?.carts);
  const hidePrices = useSelector((state) => state.user?.user?.hide_prices);

  const [checkoutEntries, setCheckoutEntries] = useState({});
  const [ssProductURL, setSSProductURL] = useState(hit.ss_product_url);
  const [updateCartSuccessAlert, setUpdateCartSuccessAlert] = useState(false);
  const [updateCartSavings, setUpdateCartSavings] = useState(null);
  const [updateCartErrorAlert, setUpdateCartErrorAlert] = useState(false);
  const [invalidTableCells, setInvalidTableCells] = useState(new Set());
  const [savingToCart, setSavingToCart] = useState(false);
  const posthog = usePostHog();
  const autoAssignTab = useRef(true);
  const dispatch = useDispatch();

  // When a user jumps from one product page to another,
  // this component is recycled by React. That said,
  // we need to reset autoAssignTab to get it working for the new style.
  useEffect(() => {
    autoAssignTab.current = true;
  }, [hit.id]);

  useEffect(() => {
    setUpdateCartSuccessAlert(false);
    setUpdateCartErrorAlert(false);
  }, [openInvDetails]);

  useEffect(() => {
    if (cheapestAvailableVendor && autoAssignTab.current) {
      setSelectedVendorKey(cheapestAvailableVendor.firstKey);
      if (cheapestAvailableVendor.areKeysOrdered) {
        autoAssignTab.current = false;
      }
    }
  }, [cheapestAvailableVendor?.firstKey, autoAssignTab]);

  const vendorInventoryWrapperStyle = applyPadding
    ? {
        paddingTop: "1.1em",
        paddingBottom: "1.1em",
        paddingLeft: "16px",
        paddingRight: "16px",
      }
    : null;

  const vendorCode = selectedVendorKey.split("_")[0];
  const selectedVendorCartData = vendorCarts?.[vendorCode];

  const productPrice = vendorProduct?.["data"]?.["product_price"];
  const isProductLoading =
    vendorProduct?.["loading"] === LOADING_STATES.LOADING_STATUS;
  const fetchingUpdatedData =
    loadingTableData && productPrice && isProductLoading; // fetching data after stale price is identified
  const saveBtnAvailable =
    (!loadingTableData || fetchingUpdatedData) && invalidTableCells.size === 0;

  const inventoryContext = {
    setSelectedVendorKey,
    selectedVendorKey,
  };

  // const onClickVendorSite = () => {
  //   let vendorLink = vendorLinks[selectedVendorKey];

  //   if (vendorLink) {
  //     if (selectedVendorKey === "ss_data") {
  //       const colorAppendage = selectedColor.name
  //         .toLowerCase()
  //         .replaceAll(" ", "_");
  //       vendorLink = vendorLink + "/" + colorAppendage;
  //     }

  //     openInNewTab(vendorLink);
  //   }
  // };

  if (!openInvDetails) {
    return null;
  }

  let isBlankAllowed = true;
  const selectedVendorData = vendorProduct?.data;
  const inventoryData = selectedVendorData?.inventory_data;
  const sizedPricingData = selectedVendorData?.sized_pricing_data;

  const addCartItems = async (cartItems) => {
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    let body = {
      items: cartItems,
    };
    if (vendorCode === "ss") {
      body.product_url_short = ssProductURL;
    }
    if (vendorCode === "pg") {
      body.pg_style = hit?.pg_style;
      body.pg_color_code = selectedColor?.pg_color_code;
    }
    if (vendorCode === "asc") {
      body.asc_product_id = hit?.asc_product_id;
    }
    const params = {
      distributor: vendorCode,
    };
    var client;
    if (vendorCode === "ss") {
      client = ssClient;
    } else {
      client = axios;
    }
    const cartResp = await client
      .post(endpoints.cart(), body, { params, headers })
      .catch((error) => {
        console.error(
          `Error adding or updating cart for distr ${vendorCode}: ${error}`,
        );
        setCartAlertContent(
          `Error adding or updating cart for distr ${vendorCode}: ${error}`,
        );
        setShowCartAlert(true);
        return null;
      });
    return cartResp;
  };

  const ssCartAlert = (
    <>
      <AlertTitle>
        Did you mean to add the same size to multiple warehouses?
      </AlertTitle>
      Our integration with S&S Activewear requires that each size be added to
      exactly one warehouse.
    </>
  );

  const checkOneSizeMultipleWhses = () => {
    const checkoutEntriesArr = Object.entries(checkoutEntries);

    const checkCartItemRemoval = (cartItem) => {
      const checkoutEntryKey =
        cartItem["size"] + "_" + cartItem["warehouse"].replaceAll(" ", "");
      const isRemovingItem = checkoutEntriesArr.find((entryB) => {
        const [entryBKey, entryBData] = entryB;
        return entryBKey === checkoutEntryKey && entryBData["Qty"] === 0;
      });

      if (isRemovingItem) {
        return true;
      }
      return false;
    };

    for (const checkoutEntry of checkoutEntriesArr) {
      const [checkoutEntryKey, checkoutEntryData] = checkoutEntry;
      const entryAWhse = checkoutEntryKey.split("_")[1];
      const formattedEntryAWhse = entryAWhse.toLowerCase().replaceAll(" ", "");

      const conflictingEntry = checkoutEntriesArr.find((entryB) => {
        const [entryKeyB, entryBData] = entryB;
        const entryBWhse = entryKeyB.split("_")[1];
        const addingTwoWhses =
          checkoutEntryData["Qty"] > 0 && entryBData["Qty"] > 0;
        return (
          checkoutEntryData["SIDM"] === entryBData["SIDM"] &&
          formattedEntryAWhse !==
            entryBWhse.toLowerCase().replaceAll(" ", "") &&
          addingTwoWhses
        );
      });

      let conflictingCartItem = null;
      const ssCartData = vendorCarts["ss"];
      if (ssCartData) {
        const ssCartItems = ssCartData["items"];
        if (ssCartItems) {
          conflictingCartItem = ssCartItems.find((cartItem) => {
            const checkoutEntrySIDM = checkoutEntryData["SIDM"];
            const cartItemSIDM = cartItem["ss_sku_id_master"];
            const cartItemWhse = cartItem["warehouse"]
              .toLowerCase()
              .replaceAll(" ", "");
            const isRemovingCartItem = checkCartItemRemoval(cartItem);
            return (
              checkoutEntrySIDM === cartItemSIDM &&
              formattedEntryAWhse !== cartItemWhse &&
              !isRemovingCartItem
            );
          });
        }
      }

      if (conflictingEntry || conflictingCartItem) {
        setShowCartAlert(true);
        setCartAlertContent(ssCartAlert);
        return true;
      }
    }
    return false;
  };

  const getQtyKey = () => {
    switch (selectedVendorKey) {
      case "sm_data":
        return "quantity";
      case "ss_data":
        return "Qty";
      case "as_data":
        return "quantity";
      default:
        return "qty";
    }
  };

  const onSaveCart = async () => {
    if (selectedVendorKey === "ss_data") {
      const cartWarning = checkOneSizeMultipleWhses();
      if (cartWarning) {
        return;
      }
    }
    setSavingToCart(true);
    setLoadingTableData(true);
    try {
      const cartItems = Object.values(checkoutEntries);
      const newVendorCartResp = await addCartItems(cartItems);
      if (!newVendorCartResp) {
        setLoadingTableData(false);
        setUpdateCartErrorAlert(true);
        return;
      }
      const newVendorCartData = newVendorCartResp["data"]["cartData"];
      dispatch(
        fetchCartSuccess({
          vendorCode: vendorCode,
          cartData: newVendorCartData,
        }),
      );
      // Got an error message from the distributor
      if (newVendorCartResp["data"]?.errorMsg) {
        const errMsg = newVendorCartResp["data"]?.errorMsg;
        const cartAlert = (
          <>
            <span>{errMsg}</span>
          </>
        );
        setCartAlertContent(cartAlert);
        setShowCartAlert(true);
        setLoadingTableData(false);
        setUpdateCartErrorAlert(true);
        return;
      }
      // calculate rough savings estimate
      const vendorQtyKey = getQtyKey();
      const itemQuantities = cartItems.map((cartItem) =>
        Number(cartItem[vendorQtyKey]),
      );
      const initialCount = 0;
      const totalItemCount = itemQuantities.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialCount,
      );
      const savingsAmount = calculateSavingsFcn(
        totalItemCount,
        vendorProduct?.data?.product_price,
      );
      if (savingsAmount > SAVINGS_THRESHOLD) {
        setUpdateCartSavings(() => formatPrice(savingsAmount));
      } else {
        setUpdateCartSavings(null);
      }
      // Send successful add to cart event to posthog
      // Calculate the diff between the new and old cart to get cart volume
      const prevTotal = selectedVendorCartData?.totalPrice ?? 0;
      const priceDelta = newVendorCartData["totalPrice"] - prevTotal;
      posthog?.capture("add_to_cart", {
        potential_savings: savingsAmount,
        total_price: priceDelta,
        items: Object.values(checkoutEntries),
        dgi_style_sku: hit?.id,
        master_color: selectedColor?.name,
        vendorCode: vendorCode,
      });
      if (searchSession.in_progress) {
        setSearchSession((currentSearchSession) => ({
          ...currentSearchSession,
          items_added: [
            ...currentSearchSession.items_added,
            getStyleNumber(hit),
          ],
          updated_cart: true,
        }));
      }
      setCheckoutEntries({});
      setLoadingTableData(false);
      setUpdateCartSuccessAlert(true);
      setShowCartAlert(false);
    } catch (error) {
      console.error(
        `Failed to save ${vendorCode} cart with error: ${error.message}`,
      );
      setCheckoutEntries({});
      setLoadingTableData(false);
      setUpdateCartErrorAlert(true);
      setShowCartAlert(false);
    } finally {
      setSavingToCart(false);
    }
  };

  const handleCloseUpdateCartSuccessAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateCartSuccessAlert(false);
  };

  const handleCloseUpdateCartErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateCartErrorAlert(false);
  };

  const triggerSaveCart = () => {
    onSaveCart();
  };

  return (
    <InventoryContext.Provider value={inventoryContext}>
      <div className={styles.inventoryContent} data-full-display={fullDisplay}>
        <div
          className={styles.centralContent}
          data-ss-cart-warning={showCartAlert}
          data-full-display={fullDisplay}
        >
          <div
            className={styles.vendorInventoryWrapper}
            style={vendorInventoryWrapperStyle}
          >
            <VendorTabs
              vendorDataKeys={vendorDataKeys}
              autoAssignTab={autoAssignTab}
              setCartAlertContent={setCartAlertContent}
              setShowCartAlert={setShowCartAlert}
              showCartAlert={showCartAlert}
              vendorsBestPrices={vendorsBestPrices}
              hidePrices={hidePrices}
            />
            <InventoryTable
              hit={hit}
              sizedPricingData={sizedPricingData}
              inventoryData={inventoryData}
              isBlankAllowed={isBlankAllowed}
              productColors={productColors}
              vendorCode={vendorCode}
              checkoutEntries={checkoutEntries}
              setCheckoutEntries={setCheckoutEntries}
              loadingTableData={loadingTableData}
              setLoadingTableData={setLoadingTableData}
              setInvalidTableCells={setInvalidTableCells}
              showSSCartWarning={showCartAlert}
              triggerSaveCart={triggerSaveCart}
              saveBtnAvailable={saveBtnAvailable}
              selectedColor={selectedColor}
              fullDisplay={fullDisplay}
              applyBorder={applyBorder}
              vendorProduct={vendorProduct}
              selectedVendorCartData={selectedVendorCartData}
              savingToCart={savingToCart}
              fetchingUpdatedData={fetchingUpdatedData}
              hidePrices={hidePrices}
            />
          </div>
        </div>
      </div>
      <AlertSnackbar
        open={updateCartSuccessAlert}
        severity="success"
        text="Successfully Updated Cart"
        handleClose={handleCloseUpdateCartSuccessAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        savings={updateCartSavings}
      />
      <AlertSnackbar
        open={updateCartErrorAlert}
        severity="error"
        text="Unable to Update Cart"
        handleClose={handleCloseUpdateCartErrorAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </InventoryContext.Provider>
  );
}
