import parseReceivedMessage from "features/parseReceivedMessage";

const SOCKET_API = `wss://8bo92l54e9.execute-api.us-east-1.amazonaws.com/dev`;
//const SOCKET_API = `wss://3it2oxrg3l.execute-api.us-east-1.amazonaws.com/test`;

const webSocketMiddleware = (store) => {
  let socket = null;
  let last_socket_user_id = null;
  let pendingMessages = [];

  const onOpen = () => {
    console.log("WebSocket connected");
    console.log(socket);
    // Send pending messages when the connection is opened
    pendingMessages.forEach((message) => {
      socket.send(JSON.stringify(message));
    });
    pendingMessages = [];
  };

  const onClose = (event) => {
    console.log("WebSocket disconnected");
    console.log(event);
    if (
      event?.reason !== "disconnect event connection close" &&
      last_socket_user_id != null
    ) {
      console.log("WebSocket reconnecting");
      // Connect to the WebSocket
      socket = new WebSocket(SOCKET_API + `?user_id=${last_socket_user_id}`);
      // Set up event listeners
      socket.onopen = onOpen;
      socket.onclose = onClose;
      socket.onmessage = onMessage;
    }
  };

  const onMessage = (event) => {
    const { dispatch } = store;
    const lastJsonMessage = JSON.parse(event.data);
    const additionalStoreData = getAdditionalStoreData(
      lastJsonMessage.action,
      lastJsonMessage.vendorCode,
    );
    parseReceivedMessage(dispatch, lastJsonMessage, additionalStoreData);
  };

  // this is where and how we access data across slices
  const getAdditionalStoreData = (action, vendorCode) => {
    if (action === "fetch_products") {
      return {
        est_delivery_days:
          store.getState().user?.user?.Distributors?.[vendorCode]
            ?.est_delivery_days,
      };
    }
    return null;
  };

  return (next) => (action) => {
    switch (action.type) {
      case "WEBSOCKET_CONNECT":
        const user_id = action.payload?.user_id;
        if (last_socket_user_id != null && user_id === last_socket_user_id) {
          break;
        }
        if (socket !== null) {
          console.log(socket);
          console.log(last_socket_user_id);
          socket.close(1000, "connection close");
        }
        // Connect to the WebSocket
        socket = new WebSocket(SOCKET_API + `?user_id=${user_id}`);
        last_socket_user_id = user_id;
        // Set up event listeners
        socket.onopen = onOpen;
        socket.onclose = onClose;
        socket.onmessage = onMessage;
        break;

      case "WEBSOCKET_DISCONNECT":
        console.log("Received disconnect action");
        if (socket !== null) {
          socket.close(1000, "disconnect event connection close");
        }
        socket = null;
        last_socket_user_id = null;
        pendingMessages = [];
        break;

      case "WEBSOCKET_SEND_MESSAGE":
        if (socket === null || socket.readyState !== WebSocket.OPEN) {
          // If the connection is not open, add the message to the pending messages array
          pendingMessages.push(action.payload);
        } else {
          // If the connection is open, send the message immediately
          socket.send(JSON.stringify(action.payload));
        }
        break;

      default:
        return next(action);
    }
  };
};

export default webSocketMiddleware;
