import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import styles from "./styles.module.scss";

/**
 * WarehouseRow component, renders individual warehouse rows.
 */
function WarehouseRow({ whse, handleClick, warehouseName, selectedWhse }) {
  // Added 'selected' prop
  if (selectedWhse.name === undefined) {
    return null; // Return null instead of undefined for valid React component
  }
  if (whse.maxQuantity < 1) {
    return null; // Return null instead of undefined for valid React component
  }

  const selected = selectedWhse.name
    .replaceAll(" ", "")
    .includes(warehouseName.replaceAll(" ", ""));

  return (
    <TableRow
      key={whse.name}
      hover
      onClick={(event) => handleClick(event, whse)}
      sx={{
        border: selected ? "2px solid rgb(2 129 192)" : "none",
        padding: selected ? "calc(0.75rem - 1px)" : "0.75rem",
        width: "100%",
      }}
      className="hover:bg-grayscaleSilver"
    >
      <TableCell sx={{ fontSize: "15px" }}>{whse.name}</TableCell>
      <TableCell align="right" sx={{ fontSize: "15px" }}>
        {whse.maxQuantity}
      </TableCell>
    </TableRow>
  );
}

const LoadingComponent = () => (
  <div className={styles.loadingCartsSpinner} style={{ height: "300px" }}>
    <CircularProgress size="80px" />
    <div className={styles.loadingCartsMsg}>
      <span>Loading warehouses...</span>
    </div>
  </div>
);

/**
 * WarehouseTableBody component, renders the table body.
 */
function WarehouseTableBody({
  warehouseData,
  loading,
  handleClick,
  selectedWhse,
}) {
  if (loading) {
    return (
      <div className={styles.tableWrapper}>
        <div className={styles.loadingCartsSpinner}>
          <CircularProgress size="80px" />
          <div className={styles.loadingCartsMsg}>
            <span>Loading warehouses...</span>
          </div>
        </div>
      </div>
    );
  }
  if (!warehouseData || (warehouseData && warehouseData.length === 0)) {
    return (
      <div className={styles.noWarehouseOptions}>
        <span>
          No other warehouse options available for this vendor, style, size,
          color, and quantity.
        </span>
      </div>
    );
  }

  return (
    <TableBody>
      {warehouseData.map((whse) => (
        <WarehouseRow
          whse={whse}
          handleClick={handleClick}
          warehouseName={whse.name}
          selectedWhse={selectedWhse}
        />
      ))}
    </TableBody>
  );
}

/**
 * SwitchWarehouseDialog component, renders the main dialog.
 */
export default function SwitchWarehouseDialog({
  open,
  warehouseData,
  tableLoading,
  handleUpdateWarehouse,
  handleClose,
  selectedWhse,
  setSelectedWhse,
}) {
  const [originalWarehouse, setOriginalWarehouse] = useState({ name: "" });
  const [hasUserSelected, setHasUserSelected] = useState(false); // New state to track user selection
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    if (warehouseData && Array.isArray(warehouseData)) {
      // warehouseData evaluates to a promise sometimes
      if (!originalWarehouse || originalWarehouse.name === "") {
        const initialSelection = warehouseData.find(
          (whse) => whse.name === selectedWhse.name
        );
        setOriginalWarehouse(initialSelection ?? { name: "" });
      }
    }
  }, [selectedWhse, warehouseData]);

  useEffect(() => {
    const noWarehouses = !warehouseData || warehouseData.length === 0;
    const sameWarehouse =
      selectedWhse && originalWarehouse
        ? selectedWhse.name === originalWarehouse.name
        : true;
    const disableSave =
      tableLoading || !hasUserSelected || noWarehouses || sameWarehouse;
    setIsSaveDisabled(disableSave);
  }, [tableLoading, warehouseData, hasUserSelected, selectedWhse]);

  const handleClick = (event, whse) => {
    setSelectedWhse(whse);
    setHasUserSelected(true);
  };

  const handleSave = async () => {
    const success = await handleUpdateWarehouse(selectedWhse);
    setOriginalWarehouse({ name: "" })
    if (!success) {
      setSelectedWhse(originalWarehouse);
    }
  };

  const handleCancel = () => {
    if (originalWarehouse.name && selectedWhse.name != originalWarehouse.name) {
      setSelectedWhse(originalWarehouse);
    }
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          width: "350px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Select a Warehouse</DialogTitle>
      {tableLoading ? (
        <LoadingComponent />
      ) : (
        <div className={styles.tableWrapper}>
          <div style={{ maxHeight: "410px", overflow: "auto", width: "100%" }}>
            <Table sx={{ widht: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "17px" }}>
                    Warehouse
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", fontSize: "17px" }}
                  >
                    Inventory
                  </TableCell>
                </TableRow>
              </TableHead>
              <WarehouseTableBody
                warehouseData={warehouseData}
                loading={tableLoading}
                handleClick={handleClick}
                selectedWhse={selectedWhse}
              />
            </Table>
          </div>
        </div>
      )}
      <div className={styles.switchWhseButtons}>
        <Button className="text-blueblue hover:bg-lightblue" onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={isSaveDisabled}
          className="bg-blueblue hover:bg-denim text-grayscaleWhite"
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}
