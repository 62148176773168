import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SavingsIcon from "@mui/icons-material/Savings";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SavingsAlert({ onClose, savings }) {
  const message = (
    <span className="text-base text-inherit font-normal" style={{ fontWeight: '400' }}>
      You saved up to {savings}
    </span>
  )
  return (
    <Alert
      severity="success"
      icon={<SavingsIcon fontSize="medium" style={{ marginTop: '1px' }} />}
      onClose={onClose}
    >
      {message}
    </Alert>  
  );
}


export default function AlertSnackbar({
  severity,
  text,
  open,
  handleClose,
  anchorOrigin,
  savings = null,
}) {
  let renderAlert = null;
  if (Boolean(savings)) {
    renderAlert = <SavingsAlert onClose={handleClose} savings={savings} />
  } else {
    renderAlert = (
      <Alert severity={severity} onClose={handleClose}>
        {text}
      </Alert>
    )
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      sx={{
        '&.MuiSnackbar-root': { bottom: '76px' },
      }}
    >
      <div>
        {renderAlert}
      </div>
    </Snackbar>
  );
}
