import React from "react";
import styles from "./styles.module.scss";
import Footer from "../pages/landing_page/Footer";

const PrivacyPolicy = () => {

  return (
    <>
      <div className={styles.terms}>
        <div className={styles.contentWrapper}>
          <h1>Privacy Policy</h1>
          <p>Last updated on August 4th, 2023</p>
          <h1>Overview</h1>
          <p>
            We've created a community where Custom Apparel Businesses can share
            their insights and make better purchasing decisions. In doing so, we
            interface with many different services, and collect and store
            information necessary for the effective use of the platform. This
            policy describes what information we collect and how it is used and
            shared.
          </p>
          <p>
            As you review our policy, keep in mind that it applies to all DGI
            Apparel products and services that do not have a separate privacy
            policy or that link to this policy, which we call the “DGI Apparel
            Services” or “Services.”
          </p>
          <h1>I. What kinds of information do we collect?</h1>
          <p>
            Depending on which Services you use, as your authorized agent we
            collect different kinds of information about your business, company,
            group, or other entity you represent, as well as information about
            you, our user.
          </p>
          <ul>
            <p>
              Information you provide. We collect the content and other
              information you provide when you use our Services, including when
              you sign up for an account, or when you contribute to our
              discussion community. This can include text, as well as contextual
              information such as a date and time actions were taken. It also
              includes encrypted and hashed versions of account information from
              third parties that are provided by you and used by DGI Apparel in
              the course of offering our Services.
            </p>
            <p>
              Things you do. As you use our Services, we collect information
              about the actions you take. This may include information about how
              you use our Services, such as when you search for or view
              products, or when you add products to your DGI Apparel cart. This
              information may include text of queries, product IDs, or vendor
              IDs, as well as contextual information, such as when an action was
              taken, or how many times an action was taken.
            </p>
            <p>
              Purchase history. As your authorized agent, DGI Apparel retrieves
              on your behalf and stores information about your purchases with
              third-party websites that you’ve authorized DGI Apparel to access.
            </p>
            <p>
              Device information. We collect information from or about the
              computers, tablets, phones, or other devices where you access our
              Services. This may include attributes such as the operating
              system, hardware version, device settings, and device identifiers.
              It may also include device locations, including specific
              geographic locations, such as through GPS, Bluetooth, or WiFi
              signals, as well as connection information such as the name of
              your mobile operator or ISP, IP address, browser type, language,
              or time zone.
            </p>
          </ul>
          <h1>II. How do we use this information?</h1>
          <p>
            We are passionate about creating the safest and most enriching
            experience possible for our users. We use all of the information we
            have to help us provide and support our Services. Here’s how:
          </p>
          <ul>
            <p>
              Provide our Services. Using the information you provide, we are
              able to deliver our Services with personalized content that is
              intended to help your business. This includes information we
              retrieve as your authorized agent from third-party services that
              is organized and displayed for you on DGI Apparel, such as product
              pricing and availability information. The information you provide
              also allows us to send information back to third-party services,
              such as when you add an item to a third-party eCommerce cart.
              Additionally, information about how you use and interact with our
              services helps us personalize content to maximize the relevancy of
              our Services to you. For instance, your recently viewed or
              recently added to cart items are used in lists and recommendations
              to improve your experience with our Services. Other information
              collected during your use of the Services may be used, in
              aggregate with data from other users, to provide features of the
              Services, such as a display of popular or trending products.
            </p>
            <p>
              Improve our Services. Information largely about how you use our
              Services is anonymized and used by the DGI Apparel team, from time
              to time, to improve our Services. For instance, how frequently
              features or user interface elements are used help us determine the
              merit of those features or interface elements, and helps us
              improve our Services for all users. We may test features in
              development, or issue voluntary surveys from time to time, the
              results of which will also be used to evaluate and improve our
              Services. Information about device information and geographic
              location are used primarily in aggregated form to improve future
              development of our services, or to troubleshoot bugs experienced
              by our users.
            </p>
            <p>
              Support the community. Information you provide, especially
              contributions to DGI Apparel discussions, are stored and displayed
              publicly for other users, with the intention of helping your
              custom apparel peers improve their businesses. Aggregated and
              anonymized information about how our users collectively use our
              Services may be published or circulated to provide the custom
              apparel community with insights on peer buying habits.
            </p>
            <p>
              Communicate with you. We use your information to communicate with
              you about our Services, and let you know about our policies and
              terms. We also use your information to respond to you when you
              contact us. We may in the future use your information to send you
              marketing communications when you are opted-in to receive such
              communications in your communications preferences, such as
              manufacturer discounts, specials, and exclusive offers relevant to
              your business.
            </p>
            <p>
              Show and measure ads and services. DGI Apparel provides
              manufacturers, vendors, and service providers the ability to
              display ads that are viewable in the course of using the Services.
              If and when DGI Apparel begins to offer advertising or marketing
              services, we plan to use the information we have to improve our
              advertising and measurement systems so we can show you relevant
              ads on our Services and measure the effectiveness and reach of
              ads.
            </p>
            <p>
              Promote safety and security. We use the information we have to
              help verify accounts and activity, and to promote safety and
              security on our Services, such as by investigating suspicious
              activity, unauthorized use of our Services, or violations of our
              terms or policies. We work hard to protect your account
              information and the privacy of your connected accounts using teams
              of engineers, automated systems, and advanced technology such as
              encryption and machine learning.
            </p>
          </ul>
          <p>
            Like most web services, DGI Apparel uses cookies and similar
            technologies to provide and support our Services and each of the
            uses outlined and described in this section of our policy. DGI
            Apparel will not, now or in the future, use your pricing information
            (even if aggregated or de-identified), including group purchasing
            (GPO) and corporate group pricing for any purpose other than
            displaying price information to you.
          </p>
          <h1>III. How is this information shared?</h1>
          <h2>Sharing On Our Services</h2>
          <p>
            Some information you provide with the intent of sharing on DGI
            Apparel is collected, stored, and shared with other DGI Apparel
            users, including the following:
          </p>
          <ul>
            <p>
              Discussions you’ve written or commented on. When you contribute to
              a discussion on DGI Apparel, that information is publicly shared
              and viewable by all users on DGI Apparel.
            </p>
            <p>
              Reviews and Q&A activity is only accessible to DGI Apparel users
              with active accounts in good standing, and is not entirely public
              in the sense that non-users will not be able to access or view the
              information through search engines or other methods.
            </p>
          </ul>
          <h2>Sharing Off Our Services</h2>
          <p>
            In a few select circumstances, information may be shared with
            parties outside of DGI Apparel to provide, improve, or preserve the
            integrity of our Services:
          </p>
          <ul>
            <p>
              Third-party vendor integrations. When you’ve authorized us to
              connect your third-party vendor accounts to DGI Apparel, select
              information is shared with those services, including items added
              to or removed from carts, or quantities desired changed in carts.
              All information shared with or received from these third parties
              is used exclusively to provide our Services. When you authorize
              DGI Apparel to share your information with third party vendors,
              your information will be shared in the same manner as if you were
              using such third party vendors services directly. We do not reveal
              your use of DGI Apparel to third party vendors without your
              consent.
            </p>
            <p>
              Sharing within DGI Apparel. We share information we have about you
              within DGI apparel to provide and improve our Services. Some
              information, such as login information to third-party services, is
              not viewable or accessible by DGI Apparel since it is transmitted
              to us in code and stored in an encrypted and hashed format.
            </p>
            <p>
              New authorized user of account. If due to personnel changes your
              account needs to be transferred to a new authorized representative
              at your businesses, some account information may be shared with
              the new representative at the discretion of DGI Apparel to
              preserve the integrity of the account.
            </p>
            <p>
              New owner. If the ownership or control of all or part of our
              Services or their assets changes, we may transfer your information
              to the new owner.
            </p>
          </ul>
          <h2>
            Possible Future Sharing With Third-Party Partners and Customers
          </h2>
          <p>
            DGI Apparel does not share any information with any third-party
            partners aside from the systems of those third-party vendors whose
            accounts you have authorized us to connect to DGI Apparel, as
            mentioned in the previous section.
          </p>
          <p>
            However, in the future DGI Apparel may work with third party
            companies to help us provide and improve our Services or who use
            advertising or related products, which makes it possible to operate
            our company and provide free services to custom apparel businesses
            around the world. Here are the types of third parties we may, at
            some point in the future, share information with about you:
          </p>
          <ul>
            <p>
              Advertising, Measurement and Analytics Services (Non-Personally
              Identifiable Information Only). We want our advertising to be as
              relevant and interesting as the other information you find on our
              Services. With this in mind, we would use all of the information
              we have about you to show you relevant ads. We will not share
              information that personally identifies you (personally
              identifiable information is information like name or email address
              that can by itself be used to contact you or identifies who you
              are) with advertising, measurement or analytics partners unless
              you give us permission. We may provide these partners with
              information about the reach and effectiveness of their advertising
              without providing information that personally identifies you, or
              if we have aggregated the information so that it does not
              personally identify you. For example, we may tell an advertiser
              how its ads performed, or how many people viewed their ads, or
              provide non-personally identifying demographic information (such
              as 3-employee screenprinter and embroidery shop in New Jersey) to
              these partners to help them understand their audience or
              customers, but only after the advertiser has agreed to abide by
              the advertiser guidelines that govern all advertising on DGI
              Apparel.
            </p>
            <p>
              Vendors, service providers and other partners (Non-Personally
              Identifiable Information Only). In the future, we may transfer
              information to vendors, service providers, and other partners who
              globally support our business, such as providing technical
              infrastructure services, analyzing how our Services are used,
              measuring the effectiveness of ads and services, providing
              customer service, facilitating payments, or conducting academic
              research and surveys. These partners must adhere to strict
              confidentiality obligations in a way that is consistent with this
              Privacy Policy and the agreements we enter into with them.
            </p>
          </ul>
          <p>
            DGI Apparel will not share or transmit, to any third party, any
            personally-identifiable or business-identifiable data without their
            express consent or as required by law. This includes data collected
            about product purchases, pricing, order history or analytics data,
            or actions taken on DGI Apparel. Furthermore, DGI Apparel will not,
            now or in the future, share your pricing information (even if
            aggregated or de-identified), including but not limited to group
            purchasing (GPO) and corporate group pricing with any third party
            for any reason. In the future, certain data, other than pricing,
            collected and stored by DGI Apparel may be utilized by third
            parties, but the identities of the vendor, business, or personnel,
            will not be revealed or transmitted to these third parties for any
            reason.
          </p>
          <h1>
            IV. How can I manage or delete information about me or my business?
          </h1>
          <p>
            You can manage the content and information you share when you use
            DGI Apparel through your Account Settings. You’ll be able to see a
            history of all reviews you’ve written or commented on, as well as
            questions you’ve asked and answered. You’ll also be able to
            disconnect third-party vendor accounts you’ve connected to DGI
            Apparel.
          </p>
          <p>
            We store data for as long as it is necessary to provide products and
            services to you and others, including those described above.
            Information associated with your account will be kept until your
            account is deleted, unless we no longer need the data to provide
            products and services.
          </p>
          <p>
            You can delete your account (including your pricing information) any
            time by contacting us by email at ian@dgiapparel.com. When you
            delete your account, we may delete or anonymize things you have
            posted, such as your reviews or Q&A activity. If you do not want to
            delete your account, but want to temporarily stop using DGI Apparel,
            you may stop using DGI Apparel at any time, and your account
            information will be preserved.
          </p>
          <h1>V. How do we respond to legal requests or prevent harm?</h1>
          <p>
            We may access, preserve and share your information in response to a
            legal request (like a search warrant, court order or subpoena) if we
            have a good faith belief that the law requires us to do so. This may
            include responding to legal requests from jurisdictions outside of
            the United States where we have a good faith belief that the
            response is required by law in that jurisdiction, affects users in
            that jurisdiction, and is consistent with internationally recognized
            standards. We may also access, preserve and share information when
            we have a good faith belief it is necessary to: detect, prevent and
            address fraud and other illegal activity; to protect ourselves, you
            and others, including as part of investigations. For example, we may
            provide information to third-party partners about the reliability of
            your account to prevent fraud and abuse on and off of our Services.
            Information we receive about you, including financial transaction
            data related to purchases made with DGI Apparel if and when such
            services are offered by DGI Apparel may be accessed, processed, and
            retained for an extended period of time when it is the subject of a
            legal request or obligation, governmental investigation, or
            investigations concerning possible violations of our terms or
            policies, or otherwise to prevent harm. We also may retain
            information from accounts disabled for violations of our terms for
            at least a year to prevent repeat abuse or other violations of our
            terms.
          </p>
          <h1>VI. How will we notify you of changes to this policy?</h1>
          <p>
            We’ll notify you when we make any material changes to this policy by
            email and/or public announcement to give you a chance to thoroughly
            review the changes before continuing use of our Services.
          </p>
          <h1>VII. How to contact DGI Apparel with questions</h1>
          <p>
            You can contact us at any time by email via ian@dgiapparel.com. For
            all mail inquiries, please use the following address:
          </p>
          <p>DGI Apparel, Inc.</p>
          <p>2447 Hering Ave</p>
          <p>Bronx, NY 10469</p>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
