import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios/prod_client.js";
import endpoints from "../../axios/endpoints.js";
import { useUserStateContext, useCheckoutContext } from "App";
import AlertSnackbar from "alerts/AlertSnackbar.js";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";

import { formatDateMMDDYY } from "utilities/formatDateMMDDYY";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CM_WHSE_PICKUP_TIMES } from "utilities/parseInventoryData";

const PickUpOptions = ({ displayPickUpOptions, setCheckoutFormData }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");

  return null;
  
  if (!displayPickUpOptions) {
    return null;
  }

  const handleTimeChange = (event) => {
    const digitalTime = event.target.value;
    setSelectedTime(digitalTime);
    setCheckoutFormData((prevCheckoutFormData) => ({
      ...prevCheckoutFormData,
      whsePickupTime: CM_WHSE_PICKUP_TIMES[digitalTime],
    }));
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    setCheckoutFormData((prevCheckoutFormData) => ({
      ...prevCheckoutFormData,
      whsePickupDate: formatDateMMDDYY(newValue),
    }));
  };

  const timeOptions = Object.keys(CM_WHSE_PICKUP_TIMES);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="">
        <Box className="text-grayscaleStone font-bold bg-dustyBlue mb-[8px] p-[8px]">
          *Pick Up Options
        </Box>
        <Stack direction="row" spacing={6} className="m-[8px]">
          <DatePicker
            label="Date"
            value={selectedDate}
            onChange={(newValue) => handleDateChange(newValue)}
            disablePast
          />
          <FormControl sx={{ width: "200px" }}>
            <InputLabel id="time-select-label">Time</InputLabel>
            <Select
              labelId="time-select-label"
              id="time-select"
              value={selectedTime}
              label="Time"
              onChange={handleTimeChange}
            >
              {timeOptions.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

const ShippingMethod = ({
  warehouseName,
  setShippingOptions,
  warehouseShippingOptions,
  warehouseCode,
}) => {
  const { vendorCode } = useParams();
  const { userCreds } = useUserStateContext();
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [orderedShippingOptions, setOrderShippingOptions] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [updateMethodSuccessAlert, setUpdateMethodSuccessAlert] =
    useState(false);
  const [updateMethodErrorAlert, setUpdateMethodErrorAlert] = useState(false);
  const { checkoutFormData, setCheckoutFormData } = useCheckoutContext();
  // only displaying pickup options for CM main whse for now...
  const displayPickUpOptions =
    vendorCode === "cm" && selectedShippingOption?.toLowerCase() === "pick up";

  useEffect(() => {
    if (selectedShippingOption) {
      setCheckoutFormData((prevCheckoutFormData) => {
        const newCheckoutFormData = { ...prevCheckoutFormData };
        let newWarehouseShippingMethods =
          newCheckoutFormData["warehouseShippingMethods"];
        // need to know if this warehouse's shipping method is already represented in state
        const targetWarehouseIndex = newWarehouseShippingMethods.findIndex(
          (whseObj) => {
            if (whseObj) {
              if (whseObj["warehouseCode"] && warehouseCode) {
                return whseObj["warehouseCode"] === warehouseCode;
              }
              return whseObj["warehouse"] === warehouseName;
            }
          }
        );

        const whseShippingOption = orderedShippingOptions.find((option) => {
          return option["name"] === selectedShippingOption;
        });

        // warehouse's shipping method not yet represented in state
        if (targetWarehouseIndex < 0) {
          newCheckoutFormData["warehouseShippingMethods"].push(
            whseShippingOption
          );
          return newCheckoutFormData;
        }

        // warehouse's shipping method already represented in state
        // so we essentially replace it
        newWarehouseShippingMethods = [
          ...newWarehouseShippingMethods.slice(0, targetWarehouseIndex),
          ...newWarehouseShippingMethods.slice(targetWarehouseIndex + 1),
        ];
        newWarehouseShippingMethods.push(whseShippingOption);

        return {
          ...newCheckoutFormData,
          warehouseShippingMethods: newWarehouseShippingMethods,
        };
      });
    }
  }, [selectedShippingOption, orderedShippingOptions]);

  useEffect(() => {
    const restrictSettingOrderedOptions =
      selectedShippingOption &&
      warehouseShippingOptions[0]["name"] !== selectedShippingOption;
    if (!restrictSettingOrderedOptions) {
      setOrderShippingOptions(warehouseShippingOptions);
    }
  }, [warehouseShippingOptions]);

  useEffect(() => {
    if (orderedShippingOptions && orderedShippingOptions[0]) {
      if (!selectedShippingOption) {
        if (checkoutFormData["warehouseShippingMethods"].length > 0) {
          const targetShippingMethod = checkoutFormData[
            "warehouseShippingMethods"
          ].find((method) => {
            if (method["warehouseCode"] && warehouseCode) {
              return method["warehouseCode"] === warehouseCode;
            }
            return method["warehouse"] === warehouseName;
          });
          setSelectedShippingOption(targetShippingMethod["name"]);
        } else {
          setSelectedShippingOption(orderedShippingOptions[0]["name"]);
        }
      }
    }
  }, [orderedShippingOptions]);

  const getUpdatedShippingMethod = async (shippingMethodName) => {
    setOpenBackdrop(true);
    const targetShippingMethod = warehouseShippingOptions.find((option) => {
      return option.name === shippingMethodName;
    });
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    const body = {
      shippingMethods: new Array(targetShippingMethod),
    };

    try {
      const shippingMethodResp = await axios.post(
        endpoints.shippingMethodUpdate(vendorCode),
        body,
        { headers }
      );
      setOpenBackdrop(false);
      setUpdateMethodSuccessAlert(true);
      return shippingMethodResp.data;
    } catch (error) {
      setOpenBackdrop(false);
      setUpdateMethodErrorAlert(true);
      console.error(`Error updating shipping method for distr: ${vendorCode}`);
      return null;
    }
  };

  const constructNewShippingOptions = (
    updatedShippingMethod,
    prevShippingOptions,
    updatedShippingMethodsData = null
  ) => {
    const whseOptionsIndex = prevShippingOptions.findIndex((whseOptions) => {
      return whseOptions[0] === warehouseName;
    });
    const prevWhseOptions = prevShippingOptions[whseOptionsIndex][1];
    const shippingOptionIndex = prevWhseOptions.findIndex((shippingOption) => {
      return shippingOption["name"] === updatedShippingMethod?.name;
    });

    let newWhseOptions;
    if (updatedShippingMethodsData) {
      // case where vendor code is ab or cm
      newWhseOptions = updatedShippingMethodsData["shippingMethods"]
        .concat(prevWhseOptions.slice(0, shippingOptionIndex))
        .concat(prevWhseOptions.slice(shippingOptionIndex + 1))
        .filter(
          (method) =>
            method["warehouseCode"] === updatedShippingMethod["warehouseCode"]
        );
    } else {
      newWhseOptions = [updatedShippingMethod].concat(
        prevWhseOptions
          .slice(0, shippingOptionIndex)
          .concat(prevWhseOptions.slice(shippingOptionIndex + 1))
      );
    }

    const shippingOptionEntry = [warehouseName, newWhseOptions, warehouseCode];
    const newShippingOptions = prevShippingOptions
      .slice(0, whseOptionsIndex)
      .concat([shippingOptionEntry])
      .concat(prevShippingOptions.slice(whseOptionsIndex + 1));
    return newShippingOptions;
  };

  useEffect(() => {
    (async () => {
      if (!selectedShippingOption) {
        return;
      }

      let updatedShippingMethod;

      if (vendorCode === "ab" || vendorCode === "cm") {
        const targetOption = orderedShippingOptions.find((option) => {
          return option["name"] === selectedShippingOption;
        });
        let updatedShippingMethodsData;
        const optionHasCost = Object.keys(targetOption).includes("freightCost");
        if (!optionHasCost) {
          updatedShippingMethodsData = await getUpdatedShippingMethod(
            selectedShippingOption
          );
        }

        if (!updatedShippingMethodsData) {
          return;
        }

        updatedShippingMethod = updatedShippingMethodsData[
          "shippingMethods"
        ].find((method) => {
          return method["warehouse"].trim() === warehouseName;
        });

        if (!updatedShippingMethod) {
          return;
        }

        setShippingOptions((prevShippingOptions) => {
          const newShippingOptions = constructNewShippingOptions(
            updatedShippingMethod,
            prevShippingOptions,
            updatedShippingMethodsData
          );
          return newShippingOptions;
        });
      } else if (vendorCode === "ss" || vendorCode === "sm") {
        const targetShippingMethod = warehouseShippingOptions.find((option) => {
          return option["name"] === selectedShippingOption;
        });
        setShippingOptions((prevShippingOptions) => {
          const newShippingOptions = constructNewShippingOptions(
            targetShippingMethod,
            prevShippingOptions,
            null
          );
          return newShippingOptions;
        });
      }
    })();
  }, [selectedShippingOption]);

  const handleOptionChange = (e) => {
    const shippingMethodName = e.target.value;
    setSelectedShippingOption(shippingMethodName);
  };

  const handleCloseUpdateShippingSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateMethodSuccessAlert(false);
  };

  const handleCloseUpdateShippingError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateMethodErrorAlert(false);
  };

  const formatFreightCost = (freightCost) => {
    if (typeof freightCost === "string") {
      return freightCost;
    }

    if (freightCost == null) {
      return null;
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(freightCost);
  };

  // radio group can't have a changing default value so we use this check
  // to make sure that the default value is declared only once
  if (!selectedShippingOption) {
    return null;
  }

  const renderShippingFigures = (option) => {
    let renderContent = null;

    if (option.estDelivery || option.freightCost !== undefined) {
      renderContent = (
        <>
          <Grid item xs={2} component="label" htmlFor={option.name}>
            <Box sx={{ textAlign: "center", cursor: "pointer" }}>
              {option.estDelivery}
            </Box>
          </Grid>
          {/* <Grid item xs={3} component="label" htmlFor={option.name} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ cursor: 'pointer'}}>{option.cutoffTime}</Box>
            </Grid> */}
          <Grid item xs={3} component="label" htmlFor={option.name}>
            <Box sx={{ textAlign: "right", cursor: "pointer" }}>
              {formatFreightCost(option.freightCost)}
            </Box>
          </Grid>
        </>
      );
    } else {
      renderContent = (
        <>
          <Grid xs={8} component="label" htmlFor={option.name}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                paddingTop: "16px",
              }}
            >
              Click option to load estimated delivery date and freight cost.
            </Box>
          </Grid>
        </>
      );
    }

    return renderContent;
  };

  return (
    <Box sx={{ marginBottom: 0, marginTop: 1 }}>
      <Box
        sx={{
          padding: 1,
          paddingLeft: 1,
          paddingRight: 1,
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.modal + 1,
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="shipping-method"
            value={selectedShippingOption}
            onChange={handleOptionChange}
            name="radio-buttons-group"
          >
            {orderedShippingOptions.map((option, index) => (
              <Box
                key={`${option.name}_${index}`}
                sx={{
                  backgroundColor:
                    index % 2 === 0 ? "rgba(225, 229, 230, 1)" : "white",
                  borderRadius: "2px",
                  paddingBottom: "4px",
                  maxHeight: "58px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  <Grid item xs={4}>
                    <FormControlLabel
                      value={option.name}
                      control={
                        <Radio
                          id={option.name}
                          sx={{
                            span: {
                              color:
                                selectedShippingOption === option.name &&
                                "rgba(0, 110, 165, 1)", // blueblue
                            },
                          }}
                        />
                      }
                      label={option.name}
                    />
                  </Grid>
                  {renderShippingFigures(option)}
                </Grid>
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <PickUpOptions
        displayPickUpOptions={displayPickUpOptions}
        setCheckoutFormData={setCheckoutFormData}
      />
      <AlertSnackbar
        open={updateMethodSuccessAlert}
        severity="success"
        text="Successfully Updated Shipping Method"
        handleClose={handleCloseUpdateShippingSuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={updateMethodErrorAlert}
        severity="error"
        text="Unable to Update Shipping Method"
        handleClose={handleCloseUpdateShippingError}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};

export default ShippingMethod;
