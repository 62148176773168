import { createTheme } from "@mui/material";

export const MUI_THEME = createTheme({
  typography: {
    fontFamily: `"Helvetica", "Arial", sans-serif`,
    color: "#2a2a2a",
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
            fontFamily: `"Helvetica", "Arial", sans-serif`,
            color: "#2a2a2a",
        },
      },
    },
  },
});
