import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import CheckoutItemPriceEntry from "./CheckoutItemPriceEntry";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  createCartEntry,
  createSwitchWarehouseBody,
} from "../../utilities/createCartEntry";
import { formatPrice } from "../../utilities/formatPrice";
import { Stack, Box } from "@mui/material";

import endpoints from "../../axios/endpoints";
import axios from "../../axios/prod_client.js";
import ssClient from "../../axios/ssClient.js";

import { useUserStateContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCartSuccess } from "features/cartsSlice";

const capitalizeWords = (str) => {
  if (str == undefined) {
    return str
  }
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ProductImage = ({ itemImageUrl }) => {
  const [displayProductImage, setDisplayProductImage] = useState(true);

  useEffect(() => {
    if (itemImageUrl && !displayProductImage) {
      setDisplayProductImage(true);
    }
  }, [itemImageUrl]);

  if (!displayProductImage) {
    return null;
  }

  return (
    <img
      className={styles.checkoutItemImage}
      src={itemImageUrl}
      alt={null}
      onError={() => setDisplayProductImage(false)}
    />
  );
};

export default function CheckoutItem({ vendorCode, checkoutItemEntries }) {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { userCreds } = useUserStateContext();
  const [itemStyle, setItemStyle] = useState("");
  const [dgiStyleSKU, setDGIStyleSKU] = useState("");
  const [masterColor, setMasterColor] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemImageUrl, setItemImageUrl] = useState("");
  const [itemSubtotal, setItemSubtotal] = useState(0.0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let newItemSubtotal = 0.0;
    for (const item of checkoutItemEntries) {
      newItemSubtotal += item.totalPrice;
    }
    setItemSubtotal(newItemSubtotal);

    const firstItem = checkoutItemEntries[0];
    setItemStyle(firstItem.style);
    setItemDescription(firstItem.description);
    setItemImageUrl(firstItem.product_image_url);
    setDGIStyleSKU(firstItem.dgi_style_sku);
    setMasterColor(firstItem.master_color);
  }, [checkoutItemEntries]);

  async function handleRemoveItem(code, item, onSuccess, onError) {
    setBackdropOpen(true);
    const itemWhse =
      vendorCode === "ab" || vendorCode === "cm" || vendorCode === "acc" || vendorCode === "sm" || vendorCode === "sta"
        ? item.warehouseCode.toLowerCase()
        : item.warehouse;
    const updateItem = createCartEntry(code, itemWhse, "0", null, item, false);
    const body = {
      items: Array(updateItem),
    };
    if (code === "ss") {
      body["product_url_short"] = item.ss_product_url_short;
    } else if (code === "asc") {
      body["asc_product_id"] = item.asc_product_id;
    }
    return await updateItems(code, body, onSuccess, onError);
  }

  async function handleUpdateWarehouse(
    code,
    item,
    warehouse,
    onSuccess,
    onError
  ) {
    setBackdropOpen(true);
    const updatePayload = createSwitchWarehouseBody(code, item, warehouse);
    return await updateItems(code, updatePayload, onSuccess, onError);
  }

  async function handleUpdateItem(code, item, quantity, onSuccess, onError) {
    setBackdropOpen(true);
    const itemWhse =
      vendorCode === "ab" || vendorCode === "cm" || vendorCode === "acc" || vendorCode === "sm" || vendorCode === "sta"
        ? item.warehouseCode.toLowerCase()
        : item.warehouse;
    const updateItem = createCartEntry(
      code,
      itemWhse,
      quantity,
      null,
      item,
      false
    );
    const body = {
      items: Array(updateItem),
    };
    if (code === "ss") {
      body["product_url_short"] = item.ss_product_url_short;
    } else if (code === "asc") {
      body["asc_product_id"] = item.asc_product_id;
    }
    return await updateItems(code, body, onSuccess, onError);
  }

  const updateItems = async (code, body, onSuccess, onError) => {
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    const params = {
      distributor: code,
    };
    var client
    if (code === "ss") {
      client = ssClient
    } else {
      client = axios
    }
    return await client
      .post(endpoints.cart(), body, { params, headers })
      .then(
        (resp) => {
          // and displaying something
          onSuccess();
          dispatch(fetchCartSuccess({ vendorCode: vendorCode, cartData: resp?.data?.cartData }))
          setBackdropOpen(false);
          return true;
        }
        // Handle successful update by updating displayCartData
      )
      .catch((error) => {
        onError();
        setBackdropOpen(false);
        console.error(`Error updating items: ${error}`);
        return null;
      });
  };

  const navigateToProductPage = (event) => {
    const encodedColor = encodeURIComponent(masterColor);
    navigate(`/product/${dgiStyleSKU}/${encodedColor}`);
    event.preventDefault();
  };

  return (
    <div className={styles.checkoutItemContainer}>
      <div className={styles.detailsAndPricingWrapper}>
        <Stack direction="row" width="100%" spacing={1}>
          <ProductImage itemImageUrl={itemImageUrl} />
          <div className={styles.checkoutItemDetails}>
            <Box
              sx={{
                "&:hover": {
                  "& *": {
                    color: "rgba(0, 110, 165, 1)" // blueblue
                  },
                  cursor: "pointer",
                },
              }}
              onClick={navigateToProductPage}
            >
              <div className={styles.checkoutItemStyle}>
                <span className="text-lg">{itemStyle}</span>
              </div>
              <div className={styles.checkoutItemShortDesc}>
                <span>{itemDescription}</span>
              </div>
            </Box>
            <div className={styles.checkoutItemColor}>
              <span className="text-fadedGray">Color:</span>
              <span> {capitalizeWords(masterColor)}</span>
            </div>
          </div>
        </Stack>
        <Stack direction="column" gap="20px" sx={{ width: "100%" }}>
          {checkoutItemEntries.map((itemEntry, index) => (
            <CheckoutItemPriceEntry
              key={index}
              vendorCode={vendorCode}
              itemEntry={itemEntry}
              handleUpdateItem={handleUpdateItem}
              handleUpdateWarehouse={handleUpdateWarehouse}
              handleRemoveItem={handleRemoveItem}
              setBackdropOpen={setBackdropOpen}
            />
          ))}
        </Stack>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.entryTotalPriceWrapper}>
        <span className="text-charcoal">{formatPrice(itemSubtotal)}</span>
      </div>
    </div>
  );
}
