export const markMatchedText = (text, searchString) => {
  if (!searchString || !text) {
    return [text];
  }

  const escapedSearchString = searchString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(`(${escapedSearchString})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, index) => 
    regex.test(part) ? <mark key={index}>{part}</mark> : part
  );
};