import InventoryNumberCell from "./InventoryNumberCell";

export default function InventoryCells({
  vendorCode,
  warehouseInventoryInfo,
  loadingTableData,
  setCheckoutEntries,
  setInvalidTableCells,
  sizedPricingData,
  checkoutEntries,
  bodyRowIndex,
  setSelectedBodyRowIndex,
  triggerSaveCart,
  saveBtnAvailable,
  selectedVendorCartData,
  fetchingUpdatedData
}) {
  const getItemPrice = (sizeLabel) => {
    const targetDataEntry = sizedPricingData.find((entry) => {
      const [size, price] = entry;
      return size === sizeLabel;
    });

    if (targetDataEntry) {
      return targetDataEntry[1];
    }
    return null;
  };
  return (
    <>
      {warehouseInventoryInfo[1]["quantities"].map((inventoryEntry) => (
        <InventoryNumberCell
          vendorCode={vendorCode}
          inventoryEntry={inventoryEntry}
          sizeLabel={inventoryEntry["sizeLabel"]}
          maxQuantity={inventoryEntry["stockNum"]}
          itemNumber={inventoryEntry["itemNumber"]}
          needToCall={Boolean(inventoryEntry["needToCallWhse"])}
          caseQtyRequired={Boolean(inventoryEntry["caseQtyRequired"])}
          caseQtyIncrement={inventoryEntry["caseQtyIncrement"]}
          itemPrice={getItemPrice(inventoryEntry["sizeLabel"])}
          warehouse={warehouseInventoryInfo[0]}
          warehouseCode={warehouseInventoryInfo[1]["warehouseCode"]}
          checkoutEntries={checkoutEntries}
          setCheckoutEntries={setCheckoutEntries}
          loadingTableData={loadingTableData}
          setInvalidTableCells={setInvalidTableCells}
          bodyRowIndex={bodyRowIndex}
          setSelectedBodyRowIndex={setSelectedBodyRowIndex}
          triggerSaveCart={triggerSaveCart}
          saveBtnAvailable={saveBtnAvailable}
          selectedVendorCartData={selectedVendorCartData}
          fetchingUpdatedData={fetchingUpdatedData}
        />
      ))}
    </>
  );
}
