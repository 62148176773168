import React, { useState, useEffect } from "react";
import { useUserStateContext } from "App";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import dummyManualAttnInvoices from './DummyData';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios_client from "../../axios/invoices_client.js";
import endpoints from "../../axios/endpoints.js";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";

const formatInvoiceName = (name, total, purchaseStatus, itemsNotFound, submitted = false) => {
  const status = submitted ? "Submitted Order" : purchaseStatus;

  let invoiceReadyIndicator = null;
  if (!itemsNotFound) {
    invoiceReadyIndicator = <CircularProgress size={24} />;
  } else {
    invoiceReadyIndicator = itemsNotFound.length === 0 ? <CheckIcon color="success" /> : <CloseIcon color="error" />;
  }

  return (
    <Box className="flex items-center w-full max-w-[500px] justify-between">
      <Box className="flex items-center">
        {invoiceReadyIndicator}
        <Typography>{name.replace("\n", " ")}</Typography>
      </Box>
      <Typography>{total}</Typography>
      <Box className="bg-dustyBlue py-[2px] px-[5px] rounded-[5px]">
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

const LineItemImage = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const onSrcError = () => {
    setImgSrc(null);
  };

  if (!imgSrc) {
    return <CloseIcon color="error" />;
  }

  return (
    <img
      src={src}
      onError={onSrcError}
      alt={null}
      style={{
        display: "block",
        height: "75px",
        maxHeight: "100%",
        margin: 0,
        maxWidth: "100%",
        width: "auto",
      }}
    />
  );
};

const InvoiceLineItems = ({ lineItems, itemsNotFound }) => {
  const getImgUrl = (color, dgiStyle) => {
    const urlColor = getURLColor({ name: color });
    return constructImageUrl(dgiStyle, urlColor, 0);
  };

  const checkIfItemAvailable = (styleNum) => {
    if (!itemsNotFound) {
      return <CircularProgress size={24} />;
    }
    const available = !itemsNotFound?.find(item => item.style_number === styleNum);
    if (available) {
      return <CheckIcon color="success" />;
    }
    return <CloseIcon color="error" />;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Stock Available</TableCell>
            <TableCell></TableCell>
            <TableCell>Style #</TableCell>
            <TableCell>Style Name</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map((lineItem, index) => (
            <TableRow key={index}>
              <TableCell>{checkIfItemAvailable(lineItem?.style_number)}</TableCell>
              <TableCell>
                <LineItemImage
                  src={getImgUrl(lineItem?.color, lineItem?.dgi_style_sku)}
                />
              </TableCell>
              <TableCell>{lineItem.style_number}</TableCell>
              <TableCell>{lineItem.description}</TableCell>
              <TableCell>{lineItem.color}</TableCell>
              <TableCell>{lineItem.size}</TableCell>
              <TableCell>{lineItem.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const InvoiceEntry = ({ invoice, submitted }) => {
  const [stockStatusLoading, setStockStatusLoading] = useState(false);
  const [itemsNotFound, setItemsNotFound] = useState(null);
  const [itemsToAdd, setItemsToAdd] = useState(null);
  const { userCreds } = useUserStateContext();

  useEffect(() => {
    (async () => {
      setStockStatusLoading(true);
      try {
        const headers = {
          "User-Identifier": userCreds.cognitoID,
        };
        const resp = await axios_client.post(
          endpoints.invoiceStockStatusGet(
            userCreds.cognitoID,
            invoice.invoice_id
          ),
          { headers }
        );
        setItemsNotFound(() => resp?.data?.items_not_found);
        setItemsToAdd(() => resp?.data?.items_to_add)
      } catch (error) {
        console.error(`Error getting invoice stock status: ${error.message}`);
      } finally {
        setStockStatusLoading(false);
      }
    })();
  }, []);

  return (
    <Accordion key={invoice.number + " " + invoice.date}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${
            invoice.invoice_number + " " + invoice.date
          }-content`}
          id={`panel-${invoice.invoice_number + " " + invoice.date}-header`}
          className="grow"
        >
          {formatInvoiceName(
            invoice.invoice_number,
            invoice.total,
            invoice.invoice_status,
            itemsNotFound,
            submitted
          )}
        </AccordionSummary>
      </Box>
      <AccordionDetails>
        <InvoiceLineItems lineItems={invoice.dgi_items} itemsNotFound={itemsNotFound} />
      </AccordionDetails>
    </Accordion>
  );
};

const InvoiceList = ({ invoices, submitted }) => {
  return (
    <Box
      sx={{
        border: "1px solid lightgray",
        borderLeft: "2px solid rgb(2, 129, 192)",
        marginTop: "12px",
      }}
    >
      {invoices.map((invoice) => (
        <InvoiceEntry invoice={invoice} submitted={submitted} />
      ))}
    </Box>
  );
};

const LoadedInvoices = ({
  invoices,
  setSubmitted,
  submitted,
  setErrorAlert,
  fetchedInvoices,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userCreds } = useUserStateContext();

  const handleSubmitInvoices = async () => {
    if (submitted) {
      return;
    }
    setErrorAlert("");
    setIsLoading(true);
    try {
      const invoiceIds = invoices.map((invoice) => invoice.invoice_id);
      const body = {
        invoiceIds,
        action: "add_to_cart",
      };
      const headers = {
        "User-Identifier": userCreds.cognitoID,
      };
      const resp = await axios_client.post(
        endpoints.invoices(userCreds.cognitoID),
        body,
        { headers }
      );
      setSubmitted(true);
    } catch (error) {
      //   setErrorAlert(() => {
      //     if (error?.response?.status === 400) {
      //       return "Submission already in progress.";
      //     } else {
      //       return "Failed to submit purchase orders. Please try again.";
      //     }
      //   });
      if (error?.response?.status === 500) {
        setSubmitted(true);
      } else if (error?.response?.status === 400) {
        setErrorAlert("Failed to submit purchase orders. Please try again.");
      }
      console.error(`Failed to submit purchase orders: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  let submitBtnContent = null;
  if (isLoading) {
    submitBtnContent = <CircularProgress size={24} />;
  } else if (!isLoading && submitted) {
    submitBtnContent = "Submitted Purchase Orders";
  } else {
    submitBtnContent = "Submit Purchase Orders";
  }

  if (!fetchedInvoices) {
    return null;
  }

  const invoicesUpdated = submitted && !isLoading;
  let invoicesTitle = "";
  if (fetchedInvoices && invoices.length === 0) {
    invoicesTitle = "No Invoices Ready to Order";
  } else if (fetchedInvoices && invoices.length > 0) {
    invoicesTitle = "Ready to Order";
  }

  return (
    <div>
      <Typography className="text-2xl">{invoicesTitle}</Typography>
      <InvoiceList invoices={invoices} submitted={submitted} />

      {fetchedInvoices && invoices.length > 0 && (
        <Box className="mt-[24px]">
          <Button
            variant="contained"
            onClick={handleSubmitInvoices}
            disabled={submitted || isLoading}
            className={invoicesUpdated && "bg-lightblue"}
            startIcon={invoicesUpdated && <CheckIcon color="success" />}
          >
            {submitBtnContent}
          </Button>
        </Box>
      )}
    </div>
  );
};

const OtherInvoices = ({ invoices, invoicesType }) => {
  if (invoices.length === 0) {
    return null;
  }
  let sectionTitle = "";
  if (invoicesType === "in_progress") {
    sectionTitle = "Orders In Progress";
  } else if (invoicesType) {
    sectionTitle = "Manual Attention Required";
  }
  return (
    <Box className="flex flex-col">
      <Typography className="text-2xl">{sectionTitle}</Typography>
      <InvoiceList invoices={invoices} />
    </Box>
  );
};

const InvoicePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadedInvoices, setLoadedInvoices] = useState([]);
  const [inProgressInvoices, setInProgressInvoices] = useState([]);
  const [manualAttnInvoices, setManualAttnInvoices] = useState([]);
  // const [manualAttnInvoices, setManualAttnInvoices] = useState(dummyManualAttnInvoices);
  const [errorAlert, setErrorAlert] = useState("");
  const [fetchedInvoices, setFetchedInvoices] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { userCreds } = useUserStateContext();

  const handleFetchInvoices = async () => {
    setErrorAlert("");
    setIsLoading(true);
    setFetchedInvoices(false);
    try {
      // fetch invoices API call
      const headers = {
        "User-Identifier": userCreds.cognitoID,
      };
      const invoicesResp = await axios_client.get(
        endpoints.invoices(userCreds.cognitoID),
        { headers }
      );
      setLoadedInvoices(
        invoicesResp?.data?.filter(
          (invoice) => invoice.invoice_status === "Order Loaded"
        ) ?? []
      );
      setInProgressInvoices(
        invoicesResp?.data?.filter(
          (invoice) => invoice.invoice_status === "Ordering in Progress"
        ) ?? []
      );
      setManualAttnInvoices(
        invoicesResp?.data?.filter(
          (invoice) => invoice.invoice_status === "Requries Manual Ordering"
        ) ?? []
      );
      // setManualAttnInvoices(dummyManualAttnInvoices)
    } catch (error) {
      setErrorAlert("Failure to fetch invoices. Please try again.");
      console.error(`Failure to fetch invoices: ${error.message}`);
    } finally {
      setIsLoading(false);
      setFetchedInvoices(true);
    }
  };

  const handleRefetchInvoices = () => {
    setErrorAlert("");
    setSubmitted(false);
    setLoadedInvoices([]);
    handleFetchInvoices();
  };

  return (
    <div>
      {errorAlert && (
        <Alert severity="error" className="text-sm mb-[25px]">
          {errorAlert}
        </Alert>
      )}
      {!fetchedInvoices ? (
        <Button
          variant="contained"
          onClick={handleFetchInvoices}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Fetch Invoices"}
        </Button>
      ) : (
        <Button
          variant="contained"
          className="mb-[55px]"
          onClick={handleRefetchInvoices}
          startIcon={<RefreshIcon sx={{ color: "white" }} />}
        >
          Fetch Invoices Again
        </Button>
      )}

      <Box className="flex flex-col gap-[50px] mb-[150px]">
      <OtherInvoices
          invoices={manualAttnInvoices}
          invoicesType="needs_manual_attn"
        />
        <LoadedInvoices
          invoices={loadedInvoices}
          setSubmitted={setSubmitted}
          submitted={submitted}
          setErrorAlert={setErrorAlert}
          fetchedInvoices={fetchedInvoices}
        />
        <OtherInvoices invoices={inProgressInvoices} invoicesType="in_progress" />
      </Box>
    </div>
  );
};

export default InvoicePage;
