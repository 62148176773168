import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from './app/store'
import { Provider } from 'react-redux'

import { PostHogProvider } from "posthog-js/react";

const phOptions = {
  api_host: "https://d1oqleyi0rb0f5.cloudfront.net",
  ui_host: "https://app.posthog.com",
  session_recording: {
    maskAllInputs: false,
    // maskInputOptions: {
    //   password: true,
    // }
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={phOptions}
      >
        <App />
      </PostHogProvider>
    </Provider>
  </React.StrictMode>,
);
