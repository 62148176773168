import { useState } from "react";
import styles from "./styles.module.scss";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, AlertTitle } from "@mui/material";

function ConfirmButton({ loading, displayErrorMsg }) {
  return (
    <div className={styles.confirmButtonContainer}>
      {displayErrorMsg && (
        <Alert
          severity="error"
          sx={{
            textAlign: "start",
            maxWidth: "550px",
          }}
        >
          <AlertTitle>Sorry, we couldn't confirm your signup</AlertTitle>
          Try 'Confirm' again or click the chat button
          at the bottom right of your screen for assistance.
        </Alert>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          className={styles.confirmButton}
          type="submit"
        >
          Submit
        </Button>
      )}
    </div>
  );
}

export default ConfirmButton;
