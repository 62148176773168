import React, { useState } from "react";
import styles from "./styles.module.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function DistributorInfo({ checkedOptions, setCheckedOptions }) {
  const options = [
    "Alphabroder",
    "Sanmar",
    "S&S Activewear",
    "Carolina Made",
    "Augusta Sportswear",
    "Atlantic Coast Cotton",
    "PrintGear",
    "SLC Activewear",
    "McCrearys Tees",
    "American T-Shirt Company",
    "Mission Imprintables",
    "One Stop",
    "Staton",
    "Sun Apparel",
    "Los Angeles Apparel",
    "Other",
  ];

  const toggleCheckbox = (option) => {
    const updatedChecked = new Set(checkedOptions);
    if (updatedChecked.has(option)) {
      updatedChecked.delete(option);
    } else {
      updatedChecked.add(option);
    }
    setCheckedOptions(updatedChecked);
  };

  return (
    <div className="w-full flex flex-col justify-center">
      <div className={styles.distributorTitle}>
        Which apparel suppliers do you order from?*
      </div>
      <div className={styles.distributorContainer}>
        {options.map((option, index) => (
          <div key={index} className={styles.option}>
            {checkedOptions.has(option) ? (
              <CheckBoxIcon style={{ fontSize: 28 }} onClick={() => toggleCheckbox(option)} />
            ) : (
              <CheckBoxOutlineBlankIcon
                onClick={() => toggleCheckbox(option)}
                style={{ fontSize: 28 }}
              />
            )}
            <label onClick={() => toggleCheckbox(option)}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DistributorInfo;
