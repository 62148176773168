import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export default styled(Paper)((props) => ({
  borderTopLeftRadius: "0px",
  borderBottomLeftRadius: "0px",
  boxShadow: "none",
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  backgroundColor: "#fcfdfd",
}));
