import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRefinementList, useClearRefinements } from "react-instantsearch";
import StatonBannerSept2024 from "../../assets/StatonBannerSept2024.jpg";
import DGITutorialBanner from "../../assets/DGITutorialBanner.png";
import AllmadeBanner from "../../assets/AllmadeBanner.png";
import SLCBannerSept2024 from "../../assets/SLCBannerSept2024.jpg";
import SLC500Banner from "../../assets/SLC500Banner.jpg"
import LaneSevenBannerSept2024 from "../../assets/LaneSevenBannerSept2024.jpg";
import { usePostHog } from "posthog-js/react";

export default function CustomCarousel() {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const clearRefinementsApi = useClearRefinements();
  const categoryRefineApi = useRefinementList({
    attribute: "master_brand",
  });
  const [seenBannerAds, setSeenBannerAds] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    return () => setSeenBannerAds([]);
  }, []);

  const items = [
    {
      name: "Slide 1",
      description: "DGI Feature Tutorials",
      image: DGITutorialBanner,
      link: "https://www.youtube.com/@DGIApparel",
    },
    {
      name: "Slide 2",
      description: "SLC 500 Sale Banner",
      image: SLC500Banner,
      link: "/shop?products_live%5BrefinementList%5D%5Bmaster_brand%5D%5B0%5D=Gildan&products_live%5BrefinementList%5D%5Bmaster_brand%5D%5B1%5D=Comfort%20Colors",
      is_banner_ad: true,
    },
    {
      name: "Slide 3",
      description: "Staton Corporate and Casual",
      image: StatonBannerSept2024,
      link: "/integrations?vendor=sta",
      is_banner_ad: true,
    },
    {
      name: "Slide 4",
      description: "SLC Activewear",
      image: SLCBannerSept2024,
      link: "/integrations?vendor=slc",
      is_banner_ad: true,
    },
    {
      name: "Slide 5",
      description: "Allmade Sustainable Apparel",
      image: AllmadeBanner,
      link: "/shop?products%5BrefinementList%5D%5Bmaster_brand%5D%5B0%5D=Allmade",
      is_banner_ad: true,
    },
    {
      name: "Slide 6",
      description: "Lane Seven Apparel",
      image: LaneSevenBannerSept2024,
      link: "/shop?products%5BrefinementList%5D%5Bmaster_brand%5D%5B0%5D=Lane%20Seven",
      is_banner_ad: true,
    }
  ];

  const handleClick = (link) => {
    if (link.startsWith("http")) {
      window.open(link, "_blank"); // Open external links in a new tab
    } else if (link.includes("Allmade") || link.includes("Lane%20Seven")) {
      navigate(link); // Use useNavigate for internal navigation
      clearRefinementsApi.refine()
      if (link.includes("Allmade")) {
        categoryRefineApi.refine("Allmade");
      } else if (link.includes("Lane%20Seven")) {
        categoryRefineApi.refine("Lane Seven");
      }
    } else {
      navigate(link); // Use useNavigate for internal navigation
    }
  };

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        cursor: hover ? "pointer" : "default",
        width: "100%",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio: "1568 / 320", // Maintain 1568x320 ratio
        }}
      >
        <Carousel
          autoPlay={true}
          interval={5000}
          indicators={false} // Remove slide indicators (bubbles)
          animation="slide"
          duration={1000}
          navButtonsProps={{
            style: {
              opacity: hover ? 1 : 0,
              transition: "opacity 0.3s ease",
              backgroundColor: "#676F78", // Set background color
            },
          }}
          navButtonsAlwaysVisible={true} // We control visibility with opacity
          sx={{
            width: "100%",
            height: "100%",
          }}
          onChange={(index) => setActiveItemIndex(index)}
        >
          {items.map((item, index) => (
            <Item
              key={index}
              item={item}
              index={index}
              handleClick={handleClick}
              seenBannerAds={seenBannerAds}
              setSeenBannerAds={setSeenBannerAds}
              activeItemIndex={activeItemIndex}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
}

function Item({
  item,
  index,
  handleClick,
  seenBannerAds,
  setSeenBannerAds,
  activeItemIndex,
}) {
  const posthog = usePostHog();
  useEffect(() => {
    if (activeItemIndex === index && item.is_banner_ad) {
      // currently look at ad banner associated with this item
      if (!seenBannerAds.includes(item.name)) {
        // we have not yet counted this ad's impression
        posthog?.capture("view_home_banner_ad", {
          description: item.description,
        });
        setSeenBannerAds((prevSeenAds) => prevSeenAds.concat([item.name]));
      }
    }
  }, [activeItemIndex, seenBannerAds, setSeenBannerAds, item, index]);

  const onSlideClick = () => {
    handleClick(item.link);
    if (item.is_banner_ad) {
      posthog?.capture("clicked_home_banner_ad", {
        description: item.description,
      });
    }
  };

  return (
    <Paper
      onClick={() => onSlideClick()}
      sx={{
        textAlign: "center",
        height: "100%", // Make the Paper take the full height of the container
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        cursor: "pointer", // Ensure the cursor indicates it's clickable
      }}
    >
      <img
        src={item.image} // Use the image specified in the items array
        alt={item.description}
        style={{ width: "100%", height: "auto", marginBottom: "1rem" }}
      />
    </Paper>
  );
}
