import React from "react";

import header_logo from "./header_logo.png";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();

  return (
    <div className={styles.headerLogoWrapper} onClick={() => navigate("/")}>
      <img alt="logo" src={header_logo} />
    </div>
  );
};
