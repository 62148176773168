import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function StateDropdownMenu({ state, setState, onChange, value }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(null);
  const buttonRef = useRef(null);
  const open = Boolean(anchorEl);

  const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectedState) => {
    if (selectedState) {
      setState(selectedState);
      onChange(selectedState);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  return (
    <div ref={buttonRef} style={{ position: "relative" }}>
      <Button
        className={`${styles.dropdownMenu} ${!value ? styles.placeholder : styles.selected}`}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {value || "Select State"}
      </Button>
      <Menu
        className={styles.dropdownContent}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        PaperProps={{
          style: { width: buttonWidth ? `${buttonWidth}px` : undefined },
        }}
      >
        {states.map((state) => (
          <MenuItem
            key={state}
            onClick={() => handleClose(state)}
            disableRipple
          >
            {state}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}