import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@mui/material";
import { usePricesContext } from "./Product";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import { selectVendorProduct } from "features/productsSlice";
import { useSelector } from "react-redux";

export default function ProductImage({ dgiStyle, smExclusive }) {
  const { selectedColor } = usePricesContext();
  const [imagesAvailable, setImagesAvailable] = useState(true);
  const [imgIndex, setImgIndex] = useState(0);
  const [maxImgIndex, setMaxImgIndex] = useState(9);
  const [imgColor, setImgColor] = useState("");
  const arrowRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  useEffect(() => {
    if (selectedColor) {
      const urlColor = getURLColor(selectedColor);
      setImgColor(urlColor);
      setImgIndex(0);
      setImagesAvailable(true);
    }
  }, [selectedColor]);

  if (smExclusive) {
    return (
      <SanmarExclusiveProductImage
        dgiStyle={dgiStyle}
        masterColor={selectedColor?.name}
      />
    );
  }
  const imgURL = constructImageUrl(dgiStyle, imgColor, imgIndex);

  const onArrowClick = (direction) => {
    if (direction === "left") {
      arrowRef.current = "left";
      if (imgIndex === 0) {
        setImgIndex(maxImgIndex);
      } else if (imgIndex > 0) {
        setImgIndex((prevImgIndex) => prevImgIndex - 1);
      }
    } else if (direction === "right") {
      arrowRef.current = "right";
      if (imgIndex >= maxImgIndex) {
        setImgIndex(0);
      } else if (imgIndex < maxImgIndex) {
        setImgIndex((prevImgIndex) => prevImgIndex + 1);
      }
    }
    return;
  };

  const onImgError = () => {
    if (imgIndex === 0) {
      setImagesAvailable(false);
    }

    if (arrowRef.current === "left") {
    } else if (arrowRef.current === "right") {
      setImgIndex(0);
      setMaxImgIndex(imgIndex - 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "#fff",
        height: "auto",
        width: "232px",
        boxSizing: "border-box",
        borderRadius: 1,
        flexShrink: 0,
        position: "relative",
        p: !imgColor || !imagesAvailable ? 3 : 0,
        pr: !imgColor || !imagesAvailable ? 4 : 0,
        "&:hover .MuiIconButton-root": {
          opacity: 1,
        },
        ".MuiIconButton-root": {
          opacity: 0,
          cursor: "pointer",
          pointerEvents: "auto",
        },
      }}
    >
      {!imgColor || !imagesAvailable ? (
        <Box
          sx={{
            flexGrow: 1,
            height: "100%",
            bgcolor: !imagesAvailable
              ? "#F6F6F6"
              : !imgColor
                ? "#f5f5f7"
                : "#fff",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#d2d6da",
            borderRadius: 1,
            p: 1.5,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "inherit",
              marginBottom: 0,
            }}
          >
            DGI
          </Typography>
          <Typography
            variant="body2" // This variant is typically smaller
            sx={{
              color: "inherit",
            }}
          >
            {!imgColor ? "Select a color to see image" : "Image coming soon"}
          </Typography>
        </Box>
      ) : (
        <>
          <IconButton
            id="left-carousel-arrow"
            ref={leftArrowRef}
            sx={{
              opacity: 0,
              cursor: "pointer",
              pointerEvents: "auto",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onArrowClick("left");
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "#fff",
              height: 190,
              width: 152,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "gray",
              borderRadius: 1,
              fontSize: "0.9em",
              flexShrink: 0,
              textAlign: "center",
              position: "relative",
            }}
          >
            <img
              src={imgURL}
              onError={onImgError}
              alt="Product image coming soon"
              style={{
                display: "block",
                height: "auto",
                maxHeight: "100%",
                margin: 0,
                maxWidth: "150px",
                width: "auto",
                zIndex: 2,
              }}
            />
          </Box>
          <IconButton
            ref={rightArrowRef}
            sx={{
              opacity: 0,
              cursor: "pointer",
              pointerEvents: "auto",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onArrowClick("right");
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
}

const sharedStyles = {
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    bgcolor: "#fff",
    height: "auto",
    width: "232px",
    boxSizing: "border-box",
    borderRadius: 1,
    flexShrink: 0,
    position: "relative",
    "&:hover .MuiIconButton-root": {
      opacity: 1,
    },
  },
  imageBox: {
    flexGrow: 1,
    bgcolor: "#fff",
    height: 190,
    width: 152,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "gray",
    borderRadius: 1,
    fontSize: "0.9em",
    flexShrink: 0,
    textAlign: "center",
    position: "relative",
  },
  image: {
    display: "block",
    height: "auto",
    maxHeight: "100%",
    margin: 0,
    maxWidth: "150px",
    width: "auto",
    zIndex: 2,
  },
  navigationButton: {
    opacity: 0,
    cursor: "pointer",
    pointerEvents: "auto",
  },
};

function LoadingState() {
  return (
    <Box sx={sharedStyles.imageContainer}>
      <Box sx={sharedStyles.imageBox}>
        <Skeleton
          variant="rectangular"
          width={150}
          height={190}
          animation="wave"
          sx={{ borderRadius: 1 }}
        />
      </Box>
    </Box>
  );
}

function NoImageState({ message }) {
  return (
    <Box
      sx={{
        ...sharedStyles.imageContainer,
        p: 3,
        pr: 4,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          bgcolor: "#F6F6F6",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "#d2d6da",
          borderRadius: 1,
          p: 1.5,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "inherit",
            marginBottom: 0,
          }}
        >
          DGI
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "inherit",
          }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

function SanmarExclusiveProductImage({ dgiStyle, masterColor }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const product = useSelector((state) =>
    selectVendorProduct(state, dgiStyle, "sm", masterColor),
  );
  const images = product?.data?.images;

  const handlePrevious = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  // Loading state
  if (!product) {
    return <LoadingState />;
  }

  // No images state
  if (!images || images.length === 0) {
    return <NoImageState message="No images available" />;
  }

  return (
    <Box sx={sharedStyles.imageContainer}>
      <IconButton
        id="left-carousel-arrow"
        ref={leftArrowRef}
        sx={sharedStyles.navigationButton}
        onClick={handlePrevious}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box sx={sharedStyles.imageBox}>
        <img
          src={images[currentImageIndex]}
          alt={`Product view ${currentImageIndex + 1}`}
          style={sharedStyles.image}
        />
      </Box>
      <IconButton
        ref={rightArrowRef}
        sx={sharedStyles.navigationButton}
        onClick={handleNext}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}
