import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./styles.module.scss";
import { TotalFigureText } from "custom/text/shopping_page/StandardSideCartTexts";

const SideCartHeader = ({ vendorTotals, hidePrices }) => {
  let loadingTotals = true;
  let totalNumberOfItems = null;
  let totalAmount = null;
  let numberOfCarts = null;

  if (Object.keys(vendorTotals).length > 0) {
    let newTotalNumberOfItems = 0;
    let newTotalAmount = 0.0;
    let newNumberOfCarts = 0;
    const vendorTotalValues = Object.values(vendorTotals);
    for (const vendorValues of vendorTotalValues) {
      newTotalNumberOfItems += vendorValues.totalItems;
      newTotalAmount += vendorValues.subtotal;
      newNumberOfCarts += 1;
    }
    const formattedTotalAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(newTotalAmount);

    totalNumberOfItems = newTotalNumberOfItems;
    totalAmount = formattedTotalAmount;
    numberOfCarts = newNumberOfCarts;
    loadingTotals = false;
  }

  const figures = [
    {content: totalNumberOfItems, label: "items"}, 
    {content: totalAmount, label: "subtotal"},
    {content:numberOfCarts, label: "carts"}
  ];

  return (
    <div className={styles.sideCartHeader}>
      {loadingTotals ? (
        <div className={styles.dataSpinnerWrapper}>
          <CircularProgress className={styles.dataSpinner} />
          <div>
            <span>Loading cart data...</span>
          </div>
        </div>
      ) : (
        <>
          {figures.map((figure, index) => (
            <TotalFigureText 
              key={index}
              content={figure.content}
              label={figure.label}
              hidePrices={hidePrices}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default SideCartHeader;
