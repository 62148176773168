import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useGetVendorByCodeQuery } from "features/baseApi";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import WriteReview from "./WriteReviews"; // Import the WriteReview component

export default function SupplierInfo({ open, onClose, vendorInfo, isConnected }) {
  const { data: vendorData, error, isLoading } = useGetVendorByCodeQuery(vendorInfo.code);

  const renderStars = (rating) => {
    const roundedRating = Math.round(rating * 2) / 2;
    const stars = [];
    let remainingRating = roundedRating;

    for (let i = 0; i < 5; i++) {
      if (remainingRating >= 1) {
        stars.push(<StarRoundedIcon key={i} fontSize="medium" sx={{ color: "#FFA552" }} />);
        remainingRating -= 1;
      } else if (remainingRating === 0.5) {
        stars.push(<StarHalfRoundedIcon key={i} fontSize="medium" sx={{ color: "#FFA552" }} />);
        remainingRating -= 0.5;
      } else {
        stars.push(<StarOutlineRoundedIcon key={i} fontSize="medium" sx={{ color: "#FFA552" }} />);
      }
    }
    return stars;
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
            <CircularProgress />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Typography color="error">Error loading vendor information. Please try again later.</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box display="flex" justifyContent="center" sx={{ marginBottom: 4, marginTop: 1 }}>
          <img src={vendorInfo.logo} alt={`${vendorInfo.name} logo`} style={{ maxWidth: 360 }} />
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: "bold", marginBottom: 1 }}>
            {isConnected ? "Review This Supplier:" : "Create an Account:"}
          </Typography>
          <Box display="flex" justifyContent="left">
            {isConnected ? (
              <WriteReview vendorCode={vendorInfo?.code} onClose={onClose} /> // Automatically render WriteReview if connected
            ) : (
              <Button
                className="bg-blueblue hover:bg-denim text-grayscaleWhite text-base py-[6px] px-[14px]"
                startIcon={<AddBusinessOutlinedIcon sx={{ color: "white" }} />}
                onClick={() => window.open(vendorInfo.account_creation_link, "_blank")} // Open account creation link in new tab
                sx={{ textTransform: "none" }}
              >
                {`Get Wholesale Access to ${vendorInfo.name}`}
              </Button>
            )}
          </Box>
        </Box>

        {/* Divider */}
        <Box
          sx={{
            borderBottom: "1px dashed",
            borderColor: "text.secondary",
            marginTop: 1,
            marginBottom: 2,
          }}
        />
        {/* About section */}
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            About:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {vendorData?.about || "Information not available."}
          </Typography>
        </Box>
        {/* Divider */}
        <Box
          sx={{
            borderBottom: "1px dashed",
            borderColor: "text.secondary",
            marginTop: 1,
            marginBottom: 2,
          }}
        />
        {/* Warehouses section */}
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Warehouses:
          </Typography>
          <Box>
            {vendorData?.warehouses?.map((location, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                sx={{ marginBottom: 0.5 }}
              >
                <LocationOnOutlinedIcon
                  fontSize="small"
                  sx={{ color: "rgba(0, 110, 165, 1)", marginRight: 1 }}
                />
                <Typography variant="body2" color="textSecondary">
                  {location}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {/* Divider */}
        <Box
          sx={{
            borderBottom: "1px dashed",
            borderColor: "text.secondary",
            marginTop: 1,
            marginBottom: 2,
          }}
        />
        {/* Brands section */}
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Brands:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {vendorData?.brands?.join(", ") || "No brands available."}
          </Typography>
        </Box>
        {/* Divider */}
        <Box
          sx={{
            borderBottom: "1px dashed",
            borderColor: "text.secondary",
            marginTop: 1,
            marginBottom: 2,
          }}
        />
        {/* Reviews section */}
        <Box sx={{ marginTop: 2 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            Reviews:
          </Typography>
          <Box>
            {vendorData?.reviews &&
              Object.entries(vendorData.reviews).map(([userId, review]) => (
                <Box key={review.reviewId} sx={{ marginBottom: 2.5 }}>
                  <Box display="flex" alignItems="center">
                    {renderStars(review.stars)}
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginTop: 0.5,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {review.writeUp}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Reviewed by: {review.first_name || 'Anonymous'}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          className="text-lighterblueblue hover:bg-lightblue"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
