import React from "react";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

const ExplainerVideo = () => {
  return (
    <Box
      sx={{
        padding: 3,
        textAlign: "center",
      }}
      className="bg-[#fff] cursor-pointer"
    >
      <Box className="mt-[4rem] mb-[28px]">
        <Stack direction="column" spacing={3} className="mb-[28px]">
          <Typography variant="h3" component="span" className="text-charcoal font-light text-4xl">
            We make purchasing blanks quick, easy, and simple.
          </Typography>
          <Typography variant="h5" component="span" className="text-charcoal">
            DGI Apparel is a <i>free</i> tool to shop every wholesale blank
            supplier at once.
          </Typography>
        </Stack>
      </Box>
      <CardMedia
        component="video"
        sx={{
          maxWidth: "950px",
          height: "auto",
          width: "100%",
          margin: "auto auto",
        }}
        src="https://d34c0c1in94n75.cloudfront.net/DGIApparelExplainerVideo.mp4"
        poster={"https://d34c0c1in94n75.cloudfront.net/product_demo_thumbnail.png"}
        controls
      />
    </Box>
  );
};

export default ExplainerVideo;
