export const capitalizeWords = (str, splitChars, isWarehouseName = true) => {
  if (!str) {
    return "";
  }
  const formattedStrings = [];
  let finalString = "";

  for (const splitChar of splitChars) {
    formattedStrings.push(
      str
        .toLowerCase()
        .split(splitChar)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(splitChar),
    );
  }

  let charIndex = 0;
  for (const character of str) {
    const strWithCapitalizedChar = formattedStrings.find((formattedStr) => {
      return character.toUpperCase() === formattedStr[charIndex];
    });

    if (strWithCapitalizedChar) {
      finalString += character.toUpperCase();
    } else {
      finalString += character.toLowerCase();
    }

    charIndex += 1;
  }

  if (finalString.split(",").length > 1 && isWarehouseName) {
    // means we're looking at a warehouse with a city and state
    finalString = finalString
      .split(",")
      .map((word, index) => (index > 0 ? word.toUpperCase() : word))
      .join(",");
  }

  return finalString;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};
