import {
  ShoppingCart,
  CompareArrows,
  LocalShipping,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WarehouseIcon from "@mui/icons-material/Warehouse";

export const cartPagePoints = [
  {
    icon: ShoppingCart,
    title: "Smart Carts",
    description: `See items in all your carts and whether you've reached your shipping minimums.`,
  },
  {
    icon: CompareArrows,
    title: "Move Between Suppliers",
    description: "Easily transfer items between different supplier carts.",
  },
  {
    icon: LocalShipping,
    title: "Logistics Remain The Same",
    description:
      "All your shipping options, billing, and invoicing will still come directly from suppliers.",
  },
];

export const cartPageHeader = {
  title: "Seamless Ordering",
  subtitle: "Build orders, manage carts, and checkout -- all on DGI Apparel.",
};

export const shopPagePoints = [
  {
    icon: SearchIcon,
    title: "Search",
    description:
      "Search across over 100,000 styles from the industry's most extensive catalog",
  },
  {
    icon: AttachMoneyIcon,
    title: "Compare Your Prices",
    description: "Compare your special pricing across all your suppliers",
  },
  {
    icon: WarehouseIcon,
    title: "Check Live Inventory",
    description:
      "See who has your favorite styles in stock and who can ship them the fastest",
  },
];

export const shopPageHeader = {
    title: "Unified Purchasing",
    subtitle: "See your negotiated pricing and live inventory -- all on DGI Apparel.",
};
