import React from "react";

import {Stack, Typography} from "@mui/material";

export const InventoryTableRowText = ({ rowText, emboldenText }) => {
  return (
    <Typography
      sx={{
        display: "inline-block",
        verticalAlign: "middle",
        fontWeight: emboldenText ? "600" : "500",
      }}
      className="text-almostSM"
    >
      {rowText}
    </Typography>
  );
};

export const InventoryTableHeaderText = ({ 
  size, 
  piecePrice,
  dozenPrice,
  originalPrice, 
  salePrice, 
  saleExists,
  hidePrices 
}) => {
  let displaySalePrice = true;
  const priceStyle = hidePrices ? { color: "transparent" } : {};
  const salePriceColor = salePrice === originalPrice ? '#303030' : '#d65252';
  const salePriceStyle = hidePrices ? priceStyle : { ...priceStyle, color: salePrice ? salePriceColor : '' };

  const originalPriceFigure = parseFloat(originalPrice.replace(/[^0-9.-]+/g,""));
  const salePriceFigure = parseFloat(salePrice.replace(/[^0-9.-]+/g,""));
  const noActualSale = originalPriceFigure <= salePriceFigure && !saleExists;
  if (noActualSale) {
    displaySalePrice = false;
  }
  // means that the sale and original prices pertain to case pricing
  const displayPiecePrice = piecePrice && piecePrice !== "-";
  const displayDozenPrice = dozenPrice && dozenPrice !== "-";
  // we don't want to display original pricing if we have best case pricing plus dozen price and piece price
  const hideOriginalPrice = displaySalePrice && (displayPiecePrice || displayDozenPrice);

  const priceFontWeightClass = (salePrice && displaySalePrice) ? "text-[0.9rem] text-[#303030]" : "text-[0.9rem] font-bold";

  return (
    <Stack direction="column" alignItems="center" gap="5px">
      <Typography className="text-sm font-bold">{size}</Typography>
      {displayPiecePrice && (
        <Typography className="text-sm font-normal">
          {piecePrice}
        </Typography>
      )}
      {displayDozenPrice && (
        <Typography className="text-sm font-normal">
          {dozenPrice}
        </Typography>
      )}
      {!hideOriginalPrice && (
        <Typography className={priceFontWeightClass} style={priceStyle}>
          {originalPrice}
        </Typography>
      )}  
      {(salePrice && displaySalePrice) && (
        <Typography
          className="text-sm font-bold"
          style={salePriceStyle}
        >
          {salePrice}
        </Typography>
      )}
    </Stack>
  );
};

export const InventoryTablePriceLabels = () => {
  return (
    <div className="flex flex-col gap-[5px] justify-end items-center h-[100%]">
      <Typography className="text-sm font-semibold">
        Piece
      </Typography>
      <Typography className="text-sm font-semibold">
        Dozen
      </Typography>
      <Typography className="text-sm font-semibold">
        Case
      </Typography>
    </div>
  );
}
