import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { MoreVert } from "@mui/icons-material";

import LoginDialog from "./LoginDialog";
import DisconnectVendor from "./DisconnectVendor";
import SupplierInfo from "./SupplierInfo";
import { useDataContext } from "../App";
import { Skeleton, Typography } from "@mui/material";
import { Construction } from "@mui/icons-material";
import vendors from "constants/vendors";
import SupplierRating from "./SupplierRating";

export default function DistributorModule({
  vendorInfo,
  dataKey,
  resetPasswordLink,
  autoOpen,
}) {
  const [loading, setLoading] = useState(true);
  const [openModule, setOpenModule] = useState(false);
  const [isConnected, setIsConnected] = useState("waiting...");
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);
  const [showSupplierInfo, setShowSupplierInfo] = useState(false);
  const [hovered, setHovered] = useState(false); // Track hover state
  const { connectedVendorCodes } = useDataContext();
  const logoSrc = vendors[dataKey]["logo"];

  const handleMoreVertClick = () => {
    setShowDisconnectPopup(true);
  };

  const handleOpenSupplierInfo = () => {
    setShowSupplierInfo(true);
  };

  const handleCloseSupplierInfo = () => {
    setShowSupplierInfo(false);
  };

  useEffect(() => {
    if (autoOpen && !connectedVendorCodes[dataKey]) {
      const delayTimer = setTimeout(() => {
        setOpenModule(true);
      }, 400);

      return () => clearTimeout(delayTimer);
    }
  }, [autoOpen]);

  useEffect(() => {
    if (connectedVendorCodes && vendorInfo) {
      const enabled = connectedVendorCodes[dataKey];

      if (enabled || enabled === false) {
        setIsConnected(enabled);
        setLoading(false);
      }
    }
  }, [connectedVendorCodes, vendorInfo]);

  const connectButton = (
    <div className={styles.connectButtonContainer}>
      <Button
        className={`bg-blueblue hover:bg-denim text-grayscaleWhite text-base py-[6px] px-[14px]`}
        onClick={() => setOpenModule(true)}
        sx={{ textTransform: "none" }}
      >
        Connect
      </Button>
    </div>
  );

  const blockedConnection = (
    <Box className="flex">
      <Typography className="text-charcoal">Under maintenance...</Typography>
      <div>
        <Construction className={styles.connectionIcon} />
      </div>
    </Box>
  );

  const connectionInterface =
    dataKey === "" ? blockedConnection : connectButton;
  let renderConnectStatus;
  if (isConnected === "waiting...") {
    renderConnectStatus = null;
  } else if (isConnected) {
    renderConnectStatus = (
      <div className={styles.connectionStatusContent}>
        <CheckCircleIcon
          className={`fill-actionGreen ${styles.connectionIcon}`}
        />
        <div>
          <span className={`text-charcoal text-lg ${styles.connectedText}`}>
            Connected
          </span>
        </div>
      </div>
    );
  }

  const connectionStatus = loading ? (
    <Skeleton
      variant="rectangular"
      width={150}
      height={30}
      animation="wave"
      sx={{ borderRadius: 1 }}
    />
  ) : (
    <>
      {renderConnectStatus}
      {!isConnected ? connectionInterface : null}
    </>
  );

  return (
    <>
      <div
        className={styles.distributorContainer}
        style={{
          position: "relative",
          borderColor: isConnected ? "#A3DFA4" : undefined, // Change border color if connected
          borderStyle: "solid", // Ensure the border style is set
          backgroundColor: isConnected ? "#f6fcf6" : undefined, // Set background color if connected
        }}
      >
        {isConnected && (
          <div style={{ position: "absolute", top: 2, right: -4 }}>
            <MoreVert
              sx={{ color: "action.active", cursor: "pointer" }}
              onClick={handleMoreVertClick}
            />
          </div>
        )}
        <div
          className={styles.vendorHeader}
          data-downsize={dataKey === "slc_data"}
          onClick={handleOpenSupplierInfo}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={{
            cursor: "pointer",
          }}
        >
          <img src={logoSrc} alt={vendorInfo.name} />
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
              width: "fit-content",
            }}
          >
            <SupplierRating vendorInfo={vendorInfo} />
          </Box>
        </div>
        <div className="h-full flex flex-col items-center justify-center">
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={4}
            sx={{ padding: "0 0.5rem 0 0.5rem", width: "100%" }}
          >
            {connectionStatus}
          </Stack>
        </div>

        {showDisconnectPopup && (
          <DisconnectVendor
            onClose={() => setShowDisconnectPopup(false)}
            vendorInfo={vendorInfo}
          />
        )}

        {/* SupplierInfo Popup */}
        {showSupplierInfo && (
          <SupplierInfo
            open={showSupplierInfo}
            onClose={handleCloseSupplierInfo}
            vendorInfo={vendorInfo}
            isConnected={isConnected}
          />
        )}
      </div>
      <LoginDialog
        open={openModule}
        onClose={() => setOpenModule(false)}
        vendorInfo={vendorInfo}
        vendorLogoSrc={logoSrc}
        resetPasswordLink={resetPasswordLink}
      />
    </>
  );
}
