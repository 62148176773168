import CustomStats from "dashboard/CustomStats";
import { SortBy } from "react-instantsearch";
import { Stack, Typography } from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import styles from "./styles.module.scss";

export default function SearchResultsHeader({setOpenFacetsDrawer}) {
  return (
    <Stack 
      flexDirection="column" 
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        width: '100%', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '45px',
        '@media (max-width: 1024px)': {
          height: 'auto',
          flexDirection: 'column',
        },
      }}
    >
      <CustomStats />
      <Stack 
        direction="row" 
        alignItems="center"
        justifyContent="center"
        useFlexGap
        className= {`w-full lg:justify-end ${styles.sortFilter}`}
        spacing={2}
      >
        <Stack 
          direction="row" 
          alignItems="center"
          onClick={() => setOpenFacetsDrawer(true)}
          spacing={1} 
          className="
            flex lg:hidden
            justify-between w-[90px]
            bg-white px-quarter
            rounded-full 
            border border-solid border-[#d2d6da] rounded-soft
            shadow-lg cursor-pointer"
        >
          <Typography>Filters</Typography>
          <ViewListIcon sx={{ color: "#7f8188" }} />
        </Stack>
        {/* <SortBy
          items={[
            { label: 'Sort By: Relevancy', value: 'products' },
            { label: 'Sort By: Catalog Price Low to High', value: 'products/sort/catalog_price:asc' },
            { label: 'Sort By: Catalog Price High to Low', value: 'products/sort/catalog_price:desc' },
          ]}
          classNames={{
            root: "flex grow flex-col-reverse bg-white max-w-[250px]",
            select: "text-charcoal text-base rounded-soft border border-solid border-[#d2d6da] shadow-lg p-quarter cursor-pointer",
            option: "rounded-soft cursor-pointer",
          }}
        /> */}
      </Stack>
    </Stack>
  );
};
