import React, { useState, useRef } from "react";
import { Box, Stack } from "@mui/material";
import { facets } from "constants/searchFacets";
import { catalogBrands } from "constants/catalogBrands";
import { catalogCategories } from "constants/catalogCategories";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./styles.module.scss";
import { useRefinementList, useClearRefinements } from "react-instantsearch";
import { useDataContext } from "App";
import { usePostHog } from "posthog-js/react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate, useLocation } from "react-router-dom";

function FacetItem({
  facetItemEntry,
  subItemEntries,
  setSubContentItems,
  refineApi,
  index,
  attribute,
  isHierarchical,
  hoverBaseCategory = null,
  setHoverBaseCategory = null,
  clearRefinementsApi,
  handlePopperClose,
}) {
  const hoverTimeoutRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchSession, setSearchSession } = useDataContext();
  const posthog = usePostHog();

  const searchAttribute = () => {
    clearRefinementsApi.refine();
    refineApi.refine(facetItemEntry);
    handlePopperClose();
    // TODO: still need to get SEO-friendly routing to make routing search in one step
    if (location.pathname !== "shop") {
      navigate("/shop");
    }
    // capture event from last session
    if (searchSession.in_progress) {
      posthog?.capture("search_and_update_cart", {
        updated_cart: searchSession.updated_cart,
        items_added: new Set(searchSession.items_added),
        search_term: searchSession.search_term,
      });
    }
    // start of a new search session
    setSearchSession({
      in_progress: true,
      search_term: facetItemEntry,
      items_added: [],
      updated_cart: false,
      prev_path: null,
    });
  };

  return (
    <div
      key={index}
      onMouseEnter={() => {
        if (isHierarchical) {
          hoverTimeoutRef.current = setTimeout(() => {
            setHoverBaseCategory(facetItemEntry);
            setSubContentItems(subItemEntries);
          }, 100);
        }
      }}
      onMouseLeave={() => {
        if (isHierarchical && hoverTimeoutRef && hoverTimeoutRef.current) {
          clearTimeout(hoverTimeoutRef.current);
        }
      }}
      className={
        attribute === "master_brand" && "shrink-0 grow-0 basis-[200px]"
      }
    >
      <label
        onClick={searchAttribute}
        className={styles.facetItem}
        data-is-hierarchical={isHierarchical}
        data-hover-upper-category={hoverBaseCategory === facetItemEntry}
      >
        <span className="text-sm">{facetItemEntry}</span>
      </label>
    </div>
  );
}

function FacetContent(props) {
  const facetItems = props.isHierarchical
    ? Object.entries(props.filteringData)
    : props.filteringData;
  return (
    <div
      className={`flex 
        ${props.attribute === "master_brand" ? "flex-wrap" : "flex-col"}
        gap-[10px] 
        ${!props.isHierarchical && "pl-[20px]"}
      `}
    >
      {facetItems.map((facetItemEntry, index) => (
        <FacetItem
          facetItemEntry={
            props.isHierarchical ? facetItemEntry[0] : facetItemEntry
          }
          subItemEntries={props.isHierarchical ? facetItemEntry[1] : []}
          setSubContentItems={props.setSubContentItems ?? null}
          index={index}
          attribute={props.attribute}
          isHierarchical={props.isHierarchical}
          hoverBaseCategory={props.hoverBaseCategory}
          setHoverBaseCategory={props.setHoverBaseCategory}
          clearRefinementsApi={props.clearRefinementsApi}
          refineApi={props.refineApi}
          handlePopperClose={props.handlePopperClose}
        />
      ))}
    </div>
  );
}

function FacetContentWrapper({
  facet,
  subContentItems,
  setSubContentItems,
  hoverBaseCategory,
  setHoverBaseCategory,
  clearRefinementsApi,
  handlePopperClose,
}) {
  const refineApi = useRefinementList({
    attribute: facet[0],
    operator: facet[0] === "master_category_tags" ? "and" : "or",
  });
  return (
    <>
      {facet[0] === "master_category_tags" ? (
        <Stack direction="row" spacing={8}>
          <FacetContent
            attribute={facet[0]}
            filteringData={catalogCategories}
            isHierarchical={true}
            setSubContentItems={setSubContentItems}
            hoverBaseCategory={hoverBaseCategory}
            setHoverBaseCategory={setHoverBaseCategory}
            refineApi={refineApi}
            clearRefinementsApi={clearRefinementsApi}
            handlePopperClose={handlePopperClose}
          />
          <div className="flex flex-col gap-[10px]">
            {subContentItems.map((subItemEntry, index) => (
              <FacetItem
                facetItemEntry={subItemEntry}
                index={index}
                attribute={facet[0]}
                isHierarchical={false}
                setHoverBaseCategory={setHoverBaseCategory}
                clearRefinementsApi={clearRefinementsApi}
                refineApi={refineApi}
                handlePopperClose={handlePopperClose}
              />
            ))}
          </div>
        </Stack>
      ) : (
        <FacetContent
          attribute={facet[0]}
          filteringData={catalogBrands}
          isHierarchical={false}
          refineApi={refineApi}
          clearRefinementsApi={clearRefinementsApi}
          handlePopperClose={handlePopperClose}
        />
      )}
    </>
  );
}

function FacetDropdown({ facet, clearRefinementsApi }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const [subContentItems, setSubContentItems] = useState([]);
  const [hoverBaseCategory, setHoverBaseCategory] = useState(null);
  const contentContainerRef = useRef();
  const hoverDropdownRef = useRef();

  const handleButtonMouseEnter = (anchor) => {
    setAnchorEl(anchor);
    setPopperOpen(true);
  };

  const handlePopperMouseEnter = () => {
    setPopperOpen(true);
  };

  const handlePopperMouseLeave = () => {
    setPopperOpen(false);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
    setPopperOpen(false);
  };

  if (!facet) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
      }}
      onMouseLeave={handlePopperMouseLeave}
    >
      <Button
        aria-describedby={popperOpen ? "popper" : undefined}
        aria-haspopup="true"
        onMouseEnter={(e) => {
          const currentTarget = e.currentTarget;
          hoverDropdownRef.current = setTimeout(
            () => handleButtonMouseEnter(currentTarget),
            260
          );
        }}
        onMouseLeave={() => clearTimeout(hoverDropdownRef.current)}
        disableRipple
        className={`normal-case text-sm ${
          popperOpen ? "text-denim" : "text-charcoal"
        }`}
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "40px", // make the same height as the header for design purposes
          border: "2px solid transparent",
          "&:hover": {
            borderBottom: "2px solid rgba(0, 88, 141, 1)", // denim
            color: "rgba(0, 88, 141, 1)", // denim
          },
          borderBottom: popperOpen && "2px solid rgba(0, 88, 141, 1)",
        }}
      >
        {facets[facet[0]]}
      </Button>
      <Popper
        id="popper"
        open={popperOpen}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        onMouseEnter={handlePopperMouseEnter}
        onMouseLeave={handlePopperMouseLeave}
        placement="bottom-start"
        anchororigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformorigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          pointerEvents: "auto",
          zIndex: "1300",
        }}
        keepMounted
      >
        <Paper
          ref={contentContainerRef}
          sx={{
            padding: "2rem",
            paddingLeft: 0,
            height: "100%",
            maxHeight: "600px",
            overflow: "auto",
            width: "auto",
            minWidth: facet[0] === "master_category_tags" ? "575px" : "260px",
            maxWidth: "800px",
            marginRight: "2rem",
            marginBottom: "2rem",
            boxShadow: "0px 8px 20px -6px rgba(0, 0, 0, 0.9)",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            overflowY: "scroll",
          }}
          className="border boder-solid border-standard"
          onMouseEnter={handlePopperMouseEnter}
          onMouseLeave={handlePopperMouseLeave}
        >
          <FacetContentWrapper
            facet={facet}
            subContentItems={subContentItems}
            setSubContentItems={setSubContentItems}
            hoverBaseCategory={hoverBaseCategory}
            setHoverBaseCategory={setHoverBaseCategory}
            clearRefinementsApi={clearRefinementsApi}
            handlePopperClose={handlePopperClose}
          />
        </Paper>
      </Popper>
    </Box>
  );
}

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(-1)}
      startIcon={
        <KeyboardArrowLeftIcon
          className="text-base mr-[-6px]"
          style={{ marginRight: "-6px" }}
        />
      }
      sx={{
        position: "absolute",
        left: 0,
        marginLeft: "20px",
        textTransform: "none",
        borderBottom: "2px solid transparent",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        "&:hover": {
          borderBottom: "2px solid rgba(0, 88, 141, 1)", // denim
          svg: {
            fill: "rgba(0, 88, 141, 1)",
          },
        },
      }}
      className="h-full text-base text-charcoal hover:text-denim"
      disableRipple
    >
      Back
    </Button>
  );
};

export default function SubHeader() {
  const addPaddingRight = useMediaQuery("(min-width:1350px)");
  const clearRefinementsApi = useClearRefinements();
  const location = useLocation();

  // Assuming product pages have /product/ in their paths
  const isProductPage = location.pathname.includes("/product/");

  return (
    <Box
      sx={{
        height: "40px",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "1600px",
        margin: "auto",
        width: "100%",
        position: "relative", // Add this
      }}
    >
      {isProductPage && <BackButton />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "280px",
          paddingRight: addPaddingRight ? "25px" : "0",
        }}
      >
        <Stack direction="row" className="gap-[2rem]">
          {Object.entries(facets)
            .slice(0, 2)
            .map((facet, index) => (
              <FacetDropdown
                key={index}
                facet={facet}
                clearRefinementsApi={clearRefinementsApi}
              />
            ))}
        </Stack>
      </Box>
    </Box>
  );
}
