// DefaultFavorites.js

const DefaultFavorites = [
    "5000",
    "18500",
    "BC3001",
    "8000",
    "64000",
    "2000",
    "NL6210",
    "BC3001CVC",
    "18000",
    "1717",
    "5000B",
    "NL3600",
    "G2400",
    "29M",
    "BC3413",
    "8000B",
    "18500B",
    "5400",
    "2000B",
    "996M",
  ];
  
  export default DefaultFavorites;
  