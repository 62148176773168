import React, { useState, useCallback } from "react";
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  Pagination,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

const OrderHistoryHeader = ({
  searchInput,
  setSearchInput,
  currentPage,
  onPageChange,
  totalPages,
}) => {
  const [localSearchInput, setLocalSearchInput] = useState(searchInput);

  const debouncedSetSearchInput = useCallback(
    debounce((value) => {
      setSearchInput(value);
    }, 300),
    [setSearchInput]
  );

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalSearchInput(newValue);  // Update local state immediately
    debouncedSetSearchInput(newValue);  // Debounce update to parent state
  };

  const handleClearSearch = () => {
    setLocalSearchInput("");
    setSearchInput("");
  };

  return (
    <Box sx={{ padding: "16px 0" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Order History
      </Typography>
      <Box sx={{ marginTop: 2 }}>
        <Box sx={{ width: "100%", maxWidth: 800, margin: "0" }}>
          <TextField
            variant="outlined"
            placeholder="Search by Style, Color, PO #, Tracking #, Vendor, Warehouse..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            value={localSearchInput}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearSearch}
          >
            Clear Search
          </Button>
          <Typography variant="body1" className="text-charcoal" sx={{ display: 'flex', alignItems: 'center' }}>
            *Order data is updated nightly*
          </Typography>
          <Pagination
            count={totalPages}
            boundaryCount={0}
            page={currentPage}
            showFirstButton={currentPage > 3}
            onChange={(_, page) => onPageChange(page)}
            shape="rounded"
            variant="outlined"
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderHistoryHeader;
