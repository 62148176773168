import React from "react";

import styles from "./styles.module.scss";
import { CheckoutItemText } from "custom/text/shopping_page/StandardSideCartTexts";

const SideCheckoutItemEntry = ({ entryData, hidePrices }) => {
  const formattedTotalPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(entryData.totalPrice);;

  return (
    <div className={styles.sideSizedItem}>
      <div className={styles.leftAlign}>
        <CheckoutItemText content={`${entryData.size}: `} />
      </div>
      <div className={styles.rightAlign}>
        <CheckoutItemText content={entryData.quantity} />
      </div>
      <div className={styles.rightAlign}>
        <CheckoutItemText content={formattedTotalPrice} hidePrices={hidePrices} />
      </div>
    </div>
  );
};

export default SideCheckoutItemEntry;
