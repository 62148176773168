import React, { useState, useRef, useEffect } from "react"; // import useRef and useEffect
import styles from "./styles.module.scss";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function RoleDropdownMenu({ role, setRole, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(null); // New state for holding the button width
  const buttonRef = useRef(null); // New ref to hold button DOM element
  const open = Boolean(anchorEl);

  const roles = [
    "Store Manager",
    "Store Owner",
    "Printer/Decorator",
    "Technician",
    "Inventory Purchaser",
    "Sales Representative",
    "Receptionist",
    "Other Staff",
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    if (option) {
      setRole(option);
      setSelectedOption(option);
      onChange(option);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  return (
    <div ref={buttonRef} style={{ position: "relative" }}>
      {" "}
      {/* New ref */}
      <Button
        className={`${styles.dropdownMenu} ${selectedOption === null ? styles.placeholder : ""
          }`}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedOption || "Select Position"}
      </Button>
      <Menu
        className={styles.dropdownContent}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        PaperProps={{
          style: { width: buttonWidth ? `${buttonWidth}px` : undefined },
        }}
      >
        {roles.map((role) => (
          <MenuItem key={role} onClick={() => handleClose(role)} disableRipple>
            {role}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
