import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import AlertSnackbar from "../../alerts/AlertSnackbar";
import SwitchWarehouseDialog from "./SwitchWarehouseDialog";

import { useUserStateContext } from "../../App";
import axios from "../../axios/prod_client.js";
import endpoints from "../../axios/endpoints";
import { switchWarehouseDisabledVendors } from "constants/vendors";

function ItemQuantityInput({
  quantityInput,
  setQuantityInput,
  handleUpdateItemQuantity,
  setBackdropOpen,
}) {
  const [changeQueued, setChangeQueued] = useState(false);
  const [prevValue, setPrevValue] = useState("");

  // Function to handle changes to the quantity input field
  const handleQuantityChange = (event) => {
    if (prevValue === "") {
      setPrevValue(quantityInput);
    }
    const value = event.target.value;
    if (value === "") {
      setQuantityInput(value);
    }
    // Validate to ensure only digits 0-9 are allowed
    if (/^[0-9]+$/.test(value)) {
      setQuantityInput(value);
      setChangeQueued(true);
    }
  };
  const handleClickAway = async (changeQueued) => {
    // If the user clicks away without specifying
    // a number, revert to last set value
    if (quantityInput === "") {
      setQuantityInput(prevValue);
    }
    if (quantityInput === prevValue) {
      return;
    }
    // Otherwise ship the updated value
    else if (changeQueued) {
      setChangeQueued(false);
      setBackdropOpen(true);
      let updateSuccess = await handleUpdateItemQuantity(quantityInput);
      if (updateSuccess) {
        setPrevValue(quantityInput);
      } else {
        setQuantityInput(prevValue);
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={() => handleClickAway(changeQueued)}>
      <TextField
        className={`${styles.itemQuantityField}`}
        sx={{
          "& .MuiOutlinedInput-root": {
            '&:hover fieldset': {
              borderColor: 'rgb(2 129 192)', // lighterblueblue
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgb(2 129 192)', // lighterblueblue
            },
        }}}
        value={quantityInput}
        onChange={handleQuantityChange}
      >
        {quantityInput}
      </TextField>
    </ClickAwayListener>
  );
}

export default function CheckoutItemPriceEntry({
  vendorCode,
  itemEntry,
  setBackdropOpen,
  handleUpdateItem,
  handleUpdateWarehouse,
  handleRemoveItem,
}) {
  const [selectedWhse, setSelectedWhse] = useState({ name: "" });
  const [unitPrice, setUnitPrice] = useState("");
  const [formattedTotalPrice, setFormattedTotalPrice] = useState("");
  const [quantityInput, setQuantityInput] = useState(0);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [updateErrorAlertOpen, setUpdateErrorAlertOpen] = useState(false);
  const [updateSuccessAlertOpen, setUpdateSuccessAlertOpen] = useState(false);
  const [switchWarehouseDialogOpen, setSwitchWarehouseDialogOpen] =
    useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const { userCreds } = useUserStateContext();

  const switchWarehouseDisabled = switchWarehouseDisabledVendors.has(vendorCode);

  const handleCloseSuccessAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessAlertOpen(false);
  };

  const handleCloseErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorAlertOpen(false);
  };

  const handleCloseUpdateSuccessAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateSuccessAlertOpen(false);
  };

  const handleCloseUpdateErrorAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateErrorAlertOpen(false);
  };

  const onUpdateSuccess = () => {
    setUpdateSuccessAlertOpen(true);
  };
  const onUpdateError = () => {
    setUpdateErrorAlertOpen(true);
  };

  const onRemoveSuccess = () => {
    setSuccessAlertOpen(true);
  };
  const onRemoveError = () => {
    setErrorAlertOpen(true);
  };

  const onUpdateWarehouseSuccess = () => {
    setSwitchWarehouseDialogOpen(false);
    setUpdateSuccessAlertOpen(true);
  };

  const onUpdateWarehouseError = () => {
    setSwitchWarehouseDialogOpen(true);
    setUpdateErrorAlertOpen(true);
  };

  const handleSwitchWarehouseDialogOpen = () => {
    setSwitchWarehouseDialogOpen(true);
    setTableLoading(true);
    if (vendorCode == "ss") {
      const availableWarehouses = fetchAvailableWarehouses(
        vendorCode,
        itemEntry,
      );
      setWarehouseData(availableWarehouses);
    } else {
      // wait one second to make it feel like we're fetching something
      setTimeout(() => {
        setTableLoading(false);
      }, 1000);
    }
  };

  const handleSwitchWarehouseDialogClose = () => {
    setSwitchWarehouseDialogOpen(false);
  };

  async function fetchAvailableWarehouses(vendorCode, item) {
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    try {
      const availableWarehouses = await axios.post(
        endpoints.cartWarehouses(),
        { item: item },
        {
          headers,
          params: {
            distributor: vendorCode,
          },
        },
      );
      setWarehouseData(availableWarehouses.data.availableWarehouses);
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      setSwitchWarehouseDialogOpen(false);
    }
  }

  const totalPriceFigure = parseFloat(itemEntry?.totalPrice).toFixed(2);
  const formmattedTotalPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalPriceFigure);
  const unitPriceFigure = parseFloat(itemEntry?.unitPrice).toFixed(2);
  const formattedUnitPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(unitPriceFigure);

  const formattedWhseName = (whseName) => {
    if (whseName === undefined) {
      return "";
    }
    return whseName.replace(/^./, (firstChar) => firstChar.toUpperCase());
  };
  const whseDataFromName = (whseName, availableWarehouses) => {
    if (availableWarehouses === undefined) {
      return { name: whseName };
    }
    let res = availableWarehouses.find((whse) => whse.name === whseName);
    if (res === undefined) {
      return { name: whseName };
    }
    return res;
  };

  useEffect(() => {
    setUnitPrice(formattedUnitPrice);
    setFormattedTotalPrice(formmattedTotalPrice);
    setQuantityInput(itemEntry.quantity);
    setSelectedWhse(
      whseDataFromName(itemEntry.warehouse, itemEntry.availableWarehouses),
    );
    setWarehouseData(itemEntry?.availableWarehouses);
  }, [itemEntry]);

  return (
    <div className={styles.checkoutItemEntry}>
      <div className={styles.checkoutItemPriceData}>
        <div>
          <span className="text-charcoal">{`${itemEntry.size}: `}</span>
        </div>
        <div>
          <ItemQuantityInput
            defaultValue={quantityInput}
            quantityInput={quantityInput}
            setQuantityInput={setQuantityInput}
            value={quantityInput}
            setBackdropOpen={setBackdropOpen}
            handleUpdateItemQuantity={(quantity) =>
              handleUpdateItem(
                vendorCode,
                itemEntry,
                quantity,
                onUpdateSuccess,
                onUpdateError,
              )
            }
          />
        </div>
        <div>
          <span className="text-charcoal">x</span>
        </div>
        <div>
          <span>{unitPrice}</span>
        </div>
        <div>
          <span className="text-charcoal">=</span>
        </div>
        <div>
          <span>{formattedTotalPrice}</span>
        </div>
        <Button
          disabled={switchWarehouseDisabled}
          onClick={handleSwitchWarehouseDialogOpen}
          className={`hover:bg-grayscaleSilver ${styles.switchWarehouseButton}`}
        >
          <span className="text-sm">{formattedWhseName(selectedWhse.name)}</span>
        </Button>
        <SwitchWarehouseDialog
          open={switchWarehouseDialogOpen}
          vendorCode={vendorCode}
          warehouseData={warehouseData}
          tableLoading={tableLoading}
          handleUpdateWarehouse={(warehouse) =>
            handleUpdateWarehouse(
              vendorCode,
              itemEntry,
              warehouse,
              onUpdateWarehouseSuccess,
              onUpdateWarehouseError,
            )
          }
          handleClose={handleSwitchWarehouseDialogClose}
          selectedWhse={selectedWhse}
          setSelectedWhse={setSelectedWhse}
        />
      </div>
      <IconButton
        onClick={() =>
          handleRemoveItem(
            vendorCode,
            itemEntry,
            onRemoveSuccess,
            onRemoveError,
          )
        }
        className="fill-charcoal"
      >
        <CancelIcon />
      </IconButton>
      <AlertSnackbar
        open={successAlertOpen}
        severity="success"
        text="Successfully Removed Item"
        handleClose={handleCloseSuccessAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={errorAlertOpen}
        severity="error"
        text="Unable to remove item from cart"
        handleClose={handleCloseErrorAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={updateSuccessAlertOpen}
        severity="success"
        text="Successfully Updated Item"
        handleClose={handleCloseUpdateSuccessAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={updateErrorAlertOpen}
        severity="error"
        text="Unable to update item"
        handleClose={handleCloseUpdateErrorAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
}
