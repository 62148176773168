export const catalogCategories = {
  "T-Shirts": [
    "T-Shirts",
    "Tops",
    "Fashion",
    "T-Shirts - Long Sleeve",
    "100% Cotton",
    "Ring Spun",
    "Long Sleeve",
    "Specialty",
    "Tanks",
    "50/50 Blend",
    "5-5.6 100% Cotton",
    "6-6.1 100% Cotton",
  ],
  "Polos & Knits": [
    "Polos/Knits",
    "Polos",
    "Knits and Layering",
    "Sweaters",
    "Silk Touch",
    "Basic Knits",
    "Ladies Specialty Knits",
    "Mock & Turtlenecks",
  ],
  "Sweatshirts & Fleece": [
    "Fleece",
    "Sweatshirts/Fleece",
    "Sweatshirts",
    "Fashion",
    "Hoodie",
    "Full Zip",
    "1/2 & 1/4 Zip",
    "Polyester Fleece",
    "Fleece Jackets",
    "Crewnecks",
    "Heavyweight",
    "Scarves/Gloves",
  ],
  "Headwear": [
    "Headwear",
    "Fashion",
    "Caps",
    "Headwear - Winter",
    "Fleece/Beanies",
    "Mesh Black",
    "Twill",
    "Stretch-to-fit",
    "Flexfit",
    "Pigment/Garment Dyed",
    "Visors",
    "Full Brim",
    "Fitted",
    "Canvas",
    "Racing",
  ],
  "Activewear": [
    "Activewear",
    "Sport Shirts",
    "Training Top",
    "Tanks",
    "Golf Outerwear",
    "Cross Over",
  ],
  "Outerwear": [
    "Outerwear",
    "Soft Shells",
    "Coroprate Jackets",
    "Insulated Jackets",
    "Rainwear",
    "Vests",
    "Parkas/ Shells/ Systems",
    "Golf Outerwear",
    "3-in-1",
  ],
  "Woven/Dress Shirts": [
    "Woven Shirts",
    "Wovens",
    "Premium Wovens",
    "Oxfords",
    "Wovens - Fall",
    "Denim",
    "Camp Shirts",
    "Fishing",
  ],
  "Bottoms": [
    "Bottoms",
    "Pants",
    "Shorts",
    "Pants & Shorts",
    "Training Bottom",
    "Sweatpants",
    "On-Fld Bottoms",
    "Industrial Work Pants/Shorts",
  ],
  "Workwear": [
    "Workwear",
    "Tall",
    "Cotton/Poly Blend",
    "Industrial Workwear",
    "Work Jackets",
    "Stain/Soil Resistant",
    "Safety",
    "Medical/Scrubs",
    "Aprons",
    "Industrial Work Pants/Shorts",
  ],
  "Bags & Accessories": [
    "Bags and Accessories",
    "Accessories",
    "Backpacks",
    "Duffels",
    "Totes",
    "Specialty Bags",
    "Briefcases/ Messengers",
    "Blankets",
    "Travel Bags",
    "Coolers & Lunch Bags",
    "Robes/Towels",
    "Cinch Packs",
    "Scarves/Gloves",
    "Bag",
    "Rolling Bags",
    "Golf Towels",
    "Golf Bags",
    "Grocery Totes",
  ],
  "Women": [
    "Ladies",
    "100% Cotton",
    "Girls",
  ],
  "Youth": [
    "Youth",
    "100% Cotton",
    "Infants",
    "Girls",
    "Juniors & Youth",
    "Infant & Toddler",
    "Tops & Bottoms",
  ],
  "Misc & PPE": [
    "Marketing Tools",
    "Decoration Supplies",
    "Misc",
    "Personal Protective Equipment",
    "Other",
    "Face Coverings",
    "Eco-Friendly",
    "Recycled",
    "Protective",
  ],
  "Sports & Athletics": [
    "Performance",
    "Athletics",
    "Sport Shirts",
    "Athletic/Warmups",
    "Training Top",
    "Performance/ Athletic",
    "Baseball",
    "Basketball",
    "Sports",
    "Fanwear",
    "Training Bottom",
    "On-Field Tops",
    "Softball",
    "Track & Field",
    "Football",
    "On-Fld Bottoms",
    "Jerseys",
    "Cheer",
    "Soccer",
    "Lacrosse",
    "Volleyball",
    "Golf Towels",
    "Golf Bags",
    "Socks",
    "Tennis",
  ],
  "Hard Goods": [
    "Desk",
    "Office",
    "Technology",
    "Drinkware",
    "Toys",
    "Novelties",
    "Home",
    "Entertaining",
    "Outdoors",
    "Kits",
    "Gift Sets",
    "Beauty",
    "Health",
  ]
}
