import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PrintavoLogo from "../../assets/PrintavoLogo.png";

const MediaSection = () => {
  const openPrintHustlersLink = () => {
    window.open("https://www.youtube.com/watch?v=Fo_VajMSkcM", "_blank");
  };

  const openPrintavoLink = () => {
    window.open(
      "https://www.printavo.com/blog/how-to-use-software-to-manage-your-inventory-strategy/",
      "_blank"
    );
  };

  const openShirtShowLink = () => {
    window.open("https://www.youtube.com/watch?v=LcPZvdTUBpM", "_blank");
  };

  const printHustlersLogo = "https://d34c0c1in94n75.cloudfront.net/print_hustlers_faces.svg";
  const shirtShowLogo = "https://d34c0c1in94n75.cloudfront.net/shirt_show_faces.svg";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: "2rem",
        paddingBottom: "96px",
        borderBottom: "10px solid #FFA552",
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontSize: "24px", borderBottom: "3px solid #FF7F11" }}
        className="text-typeGrey text-light"
      >
        As featured in
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          minWidth: "100%",
          maxWidth: "1000px",
          gap: "3.5rem",
          flexWrap: "wrap",
          "@media (max-width: 900px)": {
            flexDirection: "column",
            "& > *": {
              marginBottom: "48px",
            },
            "& > :last-child": {
              marginBottom: "0",
            },
          },
        }}
      >
        <a
          href="https://www.youtube.com/watch?v=LcPZvdTUBpM"
          target="_blank"
          rel="noopener noreferrer"
          onClick={openShirtShowLink}
        >
          <img
            src={shirtShowLogo}
            alt="ShirtShowLogo"
            style={{
              width: "180px",
              height: "auto",
              cursor: "pointer",
              transition: "opacity 0.3s ease",
            }}
            className="fill-red"
            onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.85")}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
          />
        </a>
        <a
          href="https://www.printavo.com/blog/how-to-use-software-to-manage-your-inventory-strategy/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={openPrintavoLink}
        >
          <img
            src={PrintavoLogo}
            alt="PrintavoLogo"
            style={{
              width: "330px",
              height: "auto",
              marginLeft: window.innerWidth <= 900 ? "0" : "30px",
              cursor: "pointer",
              transition: "opacity 0.3s ease",
              opacity: 0.4,
            }}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.3")}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = "0.4")}
          />
        </a>
        <a
          href="https://www.youtube.com/watch?v=Fo_VajMSkcM"
          target="_blank"
          rel="noopener noreferrer"
          onClick={openPrintHustlersLink}
        >
          <img
            src={printHustlersLogo}
            alt="PrintHustlersLogo"
            style={{
              width: "260px",
              height: "auto",
              cursor: "pointer",
              transition: "opacity 0.3s ease",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.9")}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
          />
        </a>
      </Box>
    </Box>
  );
};

export default MediaSection;
