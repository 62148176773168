export function formatBrandName(master_brand) {
  switch (master_brand) {
    case "As Colour":
      return "AS Colour";
    case "Bella+canvas":
      return "Bella+Canvas";
    default:
      return master_brand;
  }
}
