import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { user: {}},
  reducers: {
    fetchUserSuccess(state, action) {
      const { userObj } = action.payload;
      state.user = userObj;
    },
  },
});

export default userSlice.reducer;

export const { } = userSlice.selectors;

export const {
  fetchUserSuccess,
} = userSlice.actions;
