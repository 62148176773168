import { fetchProductSuccess, fetchProductFailure } from "./productsSlice";
import { fetchCartSuccess, fetchCartFailure } from "./cartsSlice";
import { addVendorShippingInfo } from "utilities/addShippingInfo";

export default function parseReceivedMessage(dispatch, lastJsonMessage, additionalStoreData=null) {
  if (lastJsonMessage.action === "fetch_products") {
    if (lastJsonMessage?.error === true) {
      dispatch(fetchProductFailure({
        dgi_style_sku: lastJsonMessage.dgi_style_sku,
        master_color: lastJsonMessage.master_color,
        vendorCode: lastJsonMessage.vendorCode,
        errorCode: lastJsonMessage?.statusCode,
        errorMessage: lastJsonMessage?.errorMessage,
      }))
    } else {
      const vendorCode = lastJsonMessage.vendorCode;
      let productData = { ...lastJsonMessage.data };
      productData = addVendorShippingInfo(
        productData,
        vendorCode,
        additionalStoreData?.est_delivery_days
      );
      dispatch(fetchProductSuccess({
        dgi_style_sku: lastJsonMessage.dgi_style_sku,
        master_color: lastJsonMessage.master_color,
        vendorCode: vendorCode,
        productData: productData,
        ttl: lastJsonMessage?.ttl,
      }))
    }
  } else if (lastJsonMessage.action === "get_carts") {
    const vendorCode = lastJsonMessage.vendorCode;
    if (lastJsonMessage?.error === true) {
      dispatch(fetchCartFailure({
        vendorCode: vendorCode,
        errorCode: 500,
        errorMessage: lastJsonMessage?.errorMessage,
      }))
    } else {
      dispatch(fetchCartSuccess({
        vendorCode: vendorCode,
        cartData: lastJsonMessage,
      }))
    }
  }
  return
};
