import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { cartVendorFilters } from "../../constants/cartVendorFilters";
import CartFilterBar from "./CartFilterBar";
import CartCheckout from "./CartCheckout";
import { useDataContext } from "../../App";

export default function CartPage() {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [displayFilters, setDisplayFilters] = useState([]);
  const [loadingCarts, setLoadingCarts] = useState(false);
  const { connectedVendorCodes } = useDataContext();

  useEffect(() => {
    const newDisplayFilters = [];
    for (const [vendorCode, filterData] of Object.entries(cartVendorFilters)) {
      const dataKey = filterData.data_key;
      const vendorName = filterData.name;
      if (vendorCode === "all") {
        newDisplayFilters.push([vendorName, vendorCode]);
      }
      if (connectedVendorCodes[dataKey]) {
        newDisplayFilters.push([vendorName, vendorCode]);
      }
    }
    setDisplayFilters(newDisplayFilters);
  }, [connectedVendorCodes]);

  return (
    <div className={styles.cartPage}>
      <CartFilterBar
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        displayFilters={displayFilters}
      />
      <CartCheckout
        selectedFilter={selectedFilter}
        loadingCarts={loadingCarts}
        setLoadingCarts={setLoadingCarts}
      />
    </div>
  );
}
