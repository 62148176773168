// ShippingMinPopUp.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "./styles.module.scss"; // import your styles

const ShippingMinPopUp = ({ open, onClose }) => {
  const [shippingMin, setShippingMin] = useState("");

  // Handle saving and close the dialog
  const handleSave = () => {
    // Implement your save logic here
    onClose();
  };

  // Handle changes in the input field
  const handleChange = (e) => {
    const value = e.target.value;

    // Only allow numbers and a single dot
    if (/^[0-9]*(\.[0-9]{0,2})?$/.test(value)) {
      setShippingMin(value);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: styles.dialogContainer }} // Apply the style here
    >
      <DialogTitle className={styles.shippingDialogTitle}>
        Set Shipping Minimum
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <DialogContentText>
          Shipping minimums are set to $200 by default and can be updated at any
          time.
        </DialogContentText>
        <TextField
          className={styles.shippingInputField}
          value={shippingMin}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShippingMinPopUp;
