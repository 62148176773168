import React from "react";
import { faqData } from "constants/ourStoryFAQs";
import LandingPageFAQ from "pages/landing_page/LandingPageFAQ";

function StoryPageFAQ() {
  return (
    <LandingPageFAQ
      questionsText={"Your questions"}
      answersText={"answered"}
      faqData={faqData}
    />
  );
}

export default StoryPageFAQ;
