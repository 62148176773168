import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { Dialog, DialogTitle, Button, Box } from "@mui/material";

function hoursToMilliseconds(hours) {
  const millisecondsInHour = 60 * 60 * 1000;
  const milliseconds = hours * millisecondsInHour;
  return milliseconds;
}

export default function IdleUserDialog() {
  const [isUserActive, setIsUserActive] = useState(true);
  useIdleTimer({
    onIdle: () => setIsUserActive(false),
    timeout: hoursToMilliseconds(8),
  });

  return (
    <Dialog
      open={!isUserActive}
      fullWidth
      PaperProps={{
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
          padding: "15px 0",
        },
      }}
    >
      <DialogTitle className="text-center text-2xl text-[#383838]">
        Are you still there?
      </DialogTitle>
      <Box>
        <Button
          className="normal-case"
          variant="contained"
          onClick={() => window.location.reload()}
        >
          Return to DGI
        </Button>
      </Box>
    </Dialog>
  );
}
