import { configureStore } from "@reduxjs/toolkit";
import products from "features/productsSlice";
import carts from "features/cartsSlice";
import user from "features/userSlice";
import styles from "features/styleSlice";
import webSocketMiddleware from "features/webSocketMiddleware";
import { baseApi } from "features/baseApi";

export const store = configureStore({
  reducer: {
    products: products,
    carts: carts,
    user: user,
    styles: styles,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(webSocketMiddleware)
      .concat(baseApi.middleware),
});
