import { useEffect, useState } from "react";
import WarehouseDeliverySections from "./WarehouseDeliverySections";
import ItemSummary from "./ItemSummary";
import ShippingPOSummary from "./ShippingPOSummary";
import BillingSummary from "./BillingSummary";
import { useCheckoutContext } from "App";
import { CM_WHSE_PICKUP_TIMES } from "utilities/parseInventoryData";

const ConfirmationFields = ({ orderSummary }) => {
  const { checkoutFormData } = useCheckoutContext();
  const shippingAddress = checkoutFormData
    ? checkoutFormData["shippingAddress"]
    : null;
  const paymentMethod = checkoutFormData
    ? checkoutFormData["paymentMethod"]
    : null;
  const shippingMethods = checkoutFormData
    ? checkoutFormData["warehouseShippingMethods"]
    : [];
  const whsePickupTime = checkoutFormData?.["whsePickupTime"]
    ? Object.entries(CM_WHSE_PICKUP_TIMES).find(
        (option) => option[1] === checkoutFormData?.["whsePickupTime"]
      )[0]
    : null;
  const whsePickupDate = checkoutFormData?.["whsePickupDate"] ?? null;

  return (
    <div className="w-full">
      <ShippingPOSummary shippingAddress={shippingAddress} />
      <WarehouseDeliverySections
        shippingMethods={shippingMethods}
        whsePickupDate={whsePickupDate}
        whsePickupTime={whsePickupTime}
      />
      <BillingSummary paymentMethod={paymentMethod} />
      <ItemSummary orderSummary={orderSummary} />
    </div>
  );
};

export default ConfirmationFields;
