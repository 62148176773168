import { createSlice } from "@reduxjs/toolkit";

const styleSlice = createSlice({
  name: "styles",
  initialState: { styles: {} },
  reducers: {
    fetchStyleSuccess: (state, action) => {
      const { styleObj } = action.payload;
      state.styles[styleObj.id] = styleObj;
    },
  },
  selectors: {
    selectStyle: (state, dgiStyle) => state.styles?.[dgiStyle]
  },
});
export default styleSlice.reducer;

export const {
  selectStyle
} = styleSlice.selectors;

export const {
  fetchStyleSuccess,
} = styleSlice.actions;

