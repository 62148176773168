import React from "react";

import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import FooterLogo from "../../assets/FooterLogo.svg";
import { Box, Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { YouTube } from "@mui/icons-material";
import openInNewTab from "utilities/openInNewTab";

export default () => {
  const navigate = useNavigate();

  return (
    <div className={styles.footerWrapper}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          py: 10,
          gap: "45px",
        }}
      >
        <div
          className={`
            flex items-start justify-center
            w-[90%] flex-wrap gap-[1.5rem]
            sm:pl-[2rem] sm:justify-between`}
        >
          <div className={styles.logoContainer} onClick={() => navigate("/")}>
            <img src={FooterLogo} alt="Company logo" className={styles.logo} />
          </div>
          <div className={styles.centeredContent}>
            <div className={styles.footerSection}>
              <div className={styles.sectionTitle}>
                <span>Resources</span>
              </div>
              <div
                className={styles.resourceLink}
                onClick={() => navigate("/our-story")}
              >
                <span>Our story</span>
              </div>
              <div
                className={styles.resourceLink}
                onClick={() => navigate("/privacy")}
              >
                <span>Privacy</span>
              </div>
              <div
                className={styles.resourceLink}
                onClick={() => navigate("/terms")}
              >
                <span>Terms</span>
              </div>
            </div>
            <div className={styles.footerSection}>
              <div className={styles.sectionTitle}>
                <span>Contact</span>
              </div>
              <div>
                <span>ian@dgiapparel.com</span>
              </div>
              <div>
                <span>+1(919)-495-7693</span>
              </div>
              <Stack direction="row" gap="12px" className="my-[8px]">
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab(
                      "https://www.facebook.com/people/DGI-Apparel/61556131563451/"
                    );
                  }}
                  className="cursor-pointer"
                >
                  <FacebookIcon className="text-denim" fontSize="large" />
                </Box>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab("https://www.instagram.com/dgiapparel/");
                  }}
                  className="cursor-pointer"
                >
                  <InstagramIcon className="text-denim" fontSize="large" />
                </Box>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab("https://www.youtube.com/@DGIApparel");
                  }}
                  className="cursor-pointer"
                >
                  <YouTube className="text-denim" fontSize="large" />
                </Box>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    openInNewTab(
                      "https://www.linkedin.com/company/dgi-apparel?trk=public_profile_topcard-current-company"
                    );
                  }}
                  className="cursor-pointer"
                >
                  <LinkedInIcon className="text-denim" fontSize="large" />
                </Box>
              </Stack>
              <div>
                <a
                  href="https://calendly.com/ian-dgi/onboarding?month=2024-01"
                  className="text-typeGrey"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a free demo
                </a>
              </div>
            </div>
          </div>
          <div className={styles.emptySpace}></div>
        </div>
        <Box
          className="w-[90%] flex justify-start"
          sx={{
            borderTop: "1px solid rgba(225, 229, 230, 1)",
          }}
        >
          <div className={styles.rightsTag}>
            <div>
              <span>© 2024 DGI Apparel, Inc. All Rights Reserved</span>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};
