import React from "react";
import styles from "./styles.module.scss";

import ColorSelection from "./ColorSelection";
import Stack from "@mui/material/Stack";
import { ProductInfoText } from "custom/text/shopping_page/StandardProductCardTexts";
import { formatBrandName } from "utilities/formatBrandName";
import UtilityBar from "./UtilityBar"; // Import the new UtilityBar component

export default function ProductDetails({
  displayStyleNumber,
  hit,
  sendJsonMessage,
  onSimilarClick,
  selectedColor,
  openSimilarSlide,
}) {
  const { master_short_description, master_brand, colors } = hit;

  const matchedColorIndexes = hit._highlightResult.colors.reduce((indexes, color, index) => {
    if (color.name.matchLevel === "full") {
      indexes.push(index);
    }
    return indexes;
  }, []);

  return (
    <div className={styles.productDetails}>
      <Stack
        id="product-details-header"
        direction="column"
        className="cursor-pointer"
      >
        <ProductInfoText
          emboldenedContent={`${displayStyleNumber} • `}
          content={formatBrandName(master_brand)}
          textClassName={styles.productInfoText}
        />
        <ProductInfoText
          emboldenedContent={null}
          content={master_short_description}
          textClassName={styles.productInfoText}
        />
      </Stack>
      <ColorSelection
        hit={hit}
        dgiStyle={hit.id}
        colorsData={colors}
        matchedColorsIndexes={matchedColorIndexes}
        sendJsonMessage={sendJsonMessage}
        displayStyleNumber={displayStyleNumber}
      />
      <UtilityBar 
        hit={hit} 
        onSimilarClick={onSimilarClick}
        selectedColor={selectedColor}
        openSimilarSlide={openSimilarSlide}
      />
    </div>
  );
}
