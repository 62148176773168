import React from "react";
import { Box, Typography, Paper, Avatar } from "@mui/material";
import testimonialsData from "../../constants/testimonials";
import Carousel from "react-material-ui-carousel";
import { useMediaQuery } from "@mui/material";

const Testimonials = () => {
  const showShortText = useMediaQuery("(max-width:430px)"); // mobile width
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "550px",
        px: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: '0px !important',
      }}
      className={`
      h-[650px] 
      min-[430px]:h-[925px]
      min-[450px]:h-[815px] 
      min-[550px]:h-[745px]
      sm:h-[685px]
      md:h-[645px]
      min-[830px]:h-[615px]
      lg:h-[580px]
      py-[0rem] mt-[0px]`}
    >
      <Carousel
        animation="slide"
        navButtonsAlwaysVisible={!useMediaQuery("(max-width:600px)")} // Inline media query usage
        autoPlay={true}
        interval={4000}
        indicators={false}
        duration={1000}
        stopAutoPlayOnHover={false}
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: "1100px",
          "& .MuiButtonBase-root": {
            backgroundColor: "#676F78 !important", // typeGrey
            "&:hover": {
              backgroundColor: "#F8F8F8", // lightestGrey
            },
            "& svg": {
              fill: "#CEF4FF", // teenBlue
            },
          },
        }}
      >
        {testimonialsData.map((testimonial, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "28px",
              background: "transparent",
              boxShadow: "none",
            }}
            className={`
              h-[650px] 
              min-[430px]:h-[925px] 
              min-[450px]:h-[815px]
              min-[550px]:h-[745px]
              sm:h-[685px]
              md:h-[645px]
              min-[830px]:h-[615px]
              lg:h-[580px]
              pt-[0rem] sm:pt-[2rem]`}
          >
            <Box
              sx={{
                width: "55%",
                margin: "0 auto",
                padding: "5%",
                textAlign: "center",
                mb: 3,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic" }}
                className="text-2xl text-wolfGrey"
              >
                "
                {showShortText
                  ? testimonial.shortText || testimonial.text
                  : testimonial.text}
                "
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                px: 2,
              }}
            >
              <Avatar
                src={testimonial.shop_logo}
                alt={`${testimonial.shopName} logo`}
                className="w-[80px] h-[80px] sm:w-[140px] sm:h-[140px]"
              />
              <Box sx={{ textAlign: "start" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                  className="text-lg text-wolfGrey"
                >
                  {testimonial.author}
                </Typography>
                <Typography variant="body2" className="text-lg text-wolfGrey">
                  {testimonial.position}, {testimonial.shopName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontStyle: "italic" }}
                  className="text-lg text-wolfGrey"
                >
                  {testimonial.location}
                </Typography>
              </Box>
            </Box>
          </Paper>
        ))}
      </Carousel>
    </Box>
  );
};

export default Testimonials;
