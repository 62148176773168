import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import TutorialCarousel from "./TutorialCarousel"; // Import the new carousel component

const TutorialDialog = ({ open, onClose }) => {
  const handleClose = (event, reason) => {
    // Prevent closing if the user clicks outside or presses "escape"
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="tutorial-popup-title"
      fullWidth
      maxWidth="md"
      sx={{
        minHeight: "640px",
        "@media (max-height: 748px)": {
          minHeight: "500px", // Reduce dialog height when screen height is below 748px
        },
      }}
    >
      <DialogContent
        sx={{
          minHeight: "660px",
          "@media (max-height: 748px)": {
            minHeight: "500px", // Reduce dialog content height when screen height is below 748px
          },
        }}
      >
        <TutorialCarousel onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default TutorialDialog;
