import React, { useState, useEffect } from "react";
import { Box, Skeleton, Pagination, Typography, Alert, AlertTitle } from "@mui/material";
import OrderTable from "./OrderTable";
import OrderHistoryHeader from "./OrderHistoryHeader";
import { useUserStateContext } from "App";
import endpoints from "../../axios/endpoints";
import axios_client from "../../axios/order_history_client.js";

const BetaFeatureNotice = () => {
  return (
    <Box sx={{ maxWidth: '100%', my: "2rem" }}>
      <Alert severity="info" className="pb-[0px]">
        <AlertTitle className="text-lg">Beta Feature Notice</AlertTitle>
        <Typography variant="body2" paragraph className="text-base">
          <Box component="span" fontWeight="bold" className="text-base">Important:</Box>
          {' '}This feature is currently in Beta. Our team is actively working to improve it.
          We encourage you to share your feedback with us via the chat button at the bottom-right of your screen.
        </Typography>
      </Alert>
    </Box>
  );
};

export default function OrderHistoryPage() {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [maxPage, setMaxPage] = useState(null);
  const { userCreds } = useUserStateContext();

  const handlePageChange = async (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
    try {
      setLoadingOrders(true);
      const userId = userCreds?.cognitoID;
      const headers = {
        "User-Identifier": userId,
      };
      const ordersResp = await axios_client.get(endpoints.orders(userId), {
        headers,
        params: {
          page,
        },
      });
      console.log(ordersResp?.data);
      const userOrders = ordersResp?.data;
      setOrders(userOrders);
      if (ordersResp?.status === 200 && userOrders.length === 0) {
        // reached end of results
        setMaxPage(page - 1);
        handlePageChange(page - 1);
      }
    } catch (error) {
      console.error(`Failed to get orders with error: ${error}`);
    } finally {
      setLoadingOrders(false);
    }
  };

  useEffect(() => {
    handlePageChange(1);
  }, []);

  // 10 is a good offset s.t. that the user sees ellipsis at either end of the paginated pages
  // this is useful since we don't how many pages we will have
  let totalPages = maxPage ? maxPage : currentPage + 10;

  return (
    <Box sx={{ padding: "0 1rem" }}>
      <BetaFeatureNotice />
      <OrderHistoryHeader
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
      {loadingOrders ? (
        <Skeleton
          variant="rectangular"
          height="40rem"
        />
      ) : (
        <>
          <OrderTable orders={orders} searchInput={searchInput} />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={totalPages}
              boundaryCount={0}
              page={currentPage}
              showFirstButton={currentPage > 3}
              onChange={(_, page) => handlePageChange(page)}
              shape="rounded"
              variant="outlined"
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
