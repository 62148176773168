import React, { useRef, useState, useEffect } from "react";

import MediaSection from "./MediaSection"; // Importing MediaSection
import LandingPageFAQ from "./LandingPageFAQ";
import Footer from "./Footer";
import Testimonials from "./Testimonials";
import { faqData } from "constants/landingPageFAQs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Stack, Box, IconButton } from "@mui/material";
import AutoplayInterfaceVideo from "./AutoplayInterfaceVideo";

import BlueCrewImg from "../../assets/black_crew.jpg";
import GrayPulloverImg from "../../assets/gray_pullover.png";
import GreenShirtImg from "../../assets/green_shirt.png";
import OrangeShirtImg from "../../assets/orange_shirt.png";

import ABLogo from "../../assets/ABLogo.png";
import ASLogo from "../../assets/ASLogo.png";
import SSLogo from "../../assets/SSLogo.png";
import SMLogo from "../../assets/new_sm_logo.png";
import CMLogo from "../../assets/CMLogo.png";
import PGLogo from "../../assets/PGLogo.png";
import ACCLogo from "../../assets/ACCLogo.png";
import STALogo from "../../assets/STALogo.png";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const dummyApparelImages = {
  blue_crew: BlueCrewImg,
  orange_shirt: OrangeShirtImg,
  gray_pullover: GrayPulloverImg,
  green_shirt: GreenShirtImg,
};

const dummyApparelNames = {
  blue_crew: "Adult Crewneck Sweater",
  orange_shirt: "50 Cotton/50 Poly T-Shirt",
  gray_pullover: "Hooded Pullover Sweatshirt",
  green_shirt: "100% Cotton T Shirt",
};

const logoDetails = [
  { src: ABLogo, height: "27px" },
  { src: ASLogo, height: "25px" },
  { src: SSLogo, height: "19px" },
  { src: SMLogo, height: "24px" },
  { src: CMLogo, height: "19px" },
  { src: PGLogo, height: "27px" },
  { src: ACCLogo, height: "32px" },
  { src: STALogo, height: "28px" },
];

const vendorLogos = {
  A: ABLogo,
  B: ASLogo,
  C: SSLogo,
  D: SMLogo,
  E: CMLogo,
  F: ACCLogo,
  G: PGLogo,
  H: ACCLogo,
  I: STALogo,
};

const dummyApparelVendors = {
  blue_crew: [
    {
      vendor: "A",
      inventory_status: "In Stock",
      price: "$17.96",
      is_selected: true,
    },
    {
      vendor: "B",
      inventory_status: "In Stock",
      price: "$20.59",
    },
    {
      vendor: "C",
      inventory_status: "No Stock",
      price: "$19.89",
    },
    {
      vendor: "I",
      inventory_status: "In Stock",
      price: "$21.30",
    },
  ],
  orange_shirt: [
    {
      vendor: "E",
      inventory_status: "In Stock",
      price: "$4.76",
    },
    {
      vendor: "G",
      inventory_status: "In Stock",
      price: "$5.02",
    },
    {
      vendor: "A",
      inventory_status: "In Stock",
      price: "$2.80",
      is_selected: true,
    },
    {
      vendor: "H",
      inventory_status: "No Stock",
      price: "$3.96",
    },
  ],
  green_shirt: [
    {
      vendor: "A",
      inventory_status: "In Stock",
      price: "$5.65",
    },
    {
      vendor: "C",
      inventory_status: "In Stock",
      price: "$6.25",
    },
    {
      vendor: "F",
      inventory_status: "In Stock",
      price: "$6.12",
    },
    {
      vendor: "I",
      inventory_status: "In Stock",
      price: "$4.75",
      is_selected: true,
    },
  ],
  gray_pullover: [
    {
      vendor: "A",
      inventory_status: "No Stock",
      price: "$34.32",
    },
    {
      vendor: "B",
      inventory_status: "In Stock",
      price: "$30.53",
      is_selected: true,
    },
    {
      vendor: "C",
      inventory_status: "In Stock",
      price: "$33.02",
    },
    {
      vendor: "H",
      inventory_status: "No Stock",
      price: "$32.80",
    },
  ],
};

export default ({}) => {
  const howItWorksRef = useRef(null);
  const openingRef = useRef(null);
  const [slideAnimation, setSlideAnimation] = useState(true);
  const [apparelItem, setApparelItem] = useState("green_shirt");
  const apparelIntervalRef = useRef(null);
  const slideStateRef = useRef(1);
  const navigate = useNavigate();

  const dummyVendors = dummyApparelVendors[apparelItem] ?? [];

  const scrollToHowItWorksSection = () => {
    howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const dummyApparelItems = [
    "green_shirt",
    "blue_crew",
    "orange_shirt",
    "gray_pullover",
  ];

  const getNextItem = (currentItem) => {
    const numDummyItems = dummyApparelItems.length;
    const itemIndex = dummyApparelItems.findIndex(
      (item) => item === currentItem
    );

    if (itemIndex === numDummyItems - 1 || currentItem === "") {
      return dummyApparelItems[0];
    }

    return dummyApparelItems[itemIndex + 1];
  };

  Object.keys(dummyApparelVendors).forEach((apparelType) => {
    dummyApparelVendors[apparelType] = dummyApparelVendors[apparelType].map(
      (vendor) => ({
        ...vendor,
        vendor: vendorLogos[vendor.vendor] || vendor.vendor,
      })
    );
  });

  useEffect(() => {
    apparelIntervalRef.current = setInterval(() => {
      setSlideAnimation((prevSlideAnimation) => !prevSlideAnimation);

      if (slideStateRef.current === 0) {
        setApparelItem((prevApparelItem) => getNextItem(prevApparelItem));
      }

      slideStateRef.current = slideStateRef.current === 0 ? 1 : 0;
    }, 4000);

    return () => clearInterval(apparelIntervalRef.current);
  }, []);

  return (
    <div className="bg-[#fff] flex max-w-[2400px] w-full flex-col items-center">
      <AutoplayInterfaceVideo />
      <Box sx={{ paddingY: "2rem", minWidth: "100%", paddingTop: "3rem" }}>
        <Stack direction="column" spacing={5}>
          <MediaSection />
          <Testimonials />
        </Stack>
      </Box>
      <div className="bg-navy py-[112px] px-[1rem] flex justify-center items-center w-full">
        <Box className="flex flex-col justify-center gap-[64px]">
          <Box className="px-[10%]">
            <Typography className="text-grayscaleWhite text-3xl font-light">
              With the most extensive catalog in the industry, you'll never have
              to visit another site.
            </Typography>
            <Typography className="text-grayscaleWhite text-3xl font-light pt-[16px]">
              Join the 2,500+ other shops using DGI today!
            </Typography>
          </Box>
          <Box
            className="w-full flex justify-center cursor-pointer"
            onClick={() => navigate("/product-demo")}
          >
            <Stack
              direction="row"
              gap="4px"
              alignItems="center"
              justifyCenter="row"
            >
              <IconButton
                className={`
                    flex items-center gap-[4px] 
                    bg-grayscaleWhite hover:bg-grayscaleSilver 
                    rounded-[5px] p-[15px]`}
                sx={{
                  boxShadow: `0 15px 35px rgba(50, 50, 93, 0.1),
                      0 5px 15px rgba(0, 0, 0, 0.07)`,
                  border: "1px solid #FF7F11", // light orange
                }}
                onClick={() => navigate("/product-demo")}
              >
                <Typography className="text-hardOrange text-xl font-bold italic">
                  See How It Works
                </Typography>
                <ArrowForwardIosIcon
                  fontSize="4px"
                  className="text-hardOrange font-light"
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </div>
      <LandingPageFAQ
        questionsText={"Questions?"}
        answersText={"We've got answers"}
        faqData={faqData}
      />
      <Footer />
    </div>
  );
};
