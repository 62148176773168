import React from "react";
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import styles from "./styles.module.scss";
import IanTeamPic from "../../assets/IanTeamPic.png";
import ShkreliTeamPic from "../../assets/ShkreliTeamPic.jpg";
import GrantTeamPic from "../../assets/GrantTeamPic.png";

const teamMembers = [
  { name: "Ian", image: IanTeamPic },
  { name: "Grant", image: GrantTeamPic },
  { name: "Daniel", image: ShkreliTeamPic },
  { name: "Whit", image: "https://d34c0c1in94n75.cloudfront.net/whit_headshot.jpg" }
];

const TeamMemberName = styled(Typography)({
  marginTop: '10px',
  fontSize: '1.25rem',
  fontStyle: 'italic',
  color: '#5c5c5c', // wolfGrey
  borderBottom: '3px solid #FF7F11', // hardOrange
  display: 'inline-block',
});

const TeamSection = () => {
  return (
    <Box sx={{ width: 'fit-content', mb: 4 }}>
      <Typography variant="h4" align="center" color="#5c5c5c" gutterBottom>
        Meet the team that powers DGI Apparel
      </Typography>
      <Grid container spacing={8} justifyContent="center">
        {[0, 1].map((rowIndex) => (
          <Grid item xs={12} key={rowIndex}>
            <Grid container spacing={2} justifyContent="center">
              {teamMembers.slice(rowIndex * 2, rowIndex * 2 + 2).map((member, index) => (
                <Grid item xs={6} key={index}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <img
                      src={member.image}
                      alt={`${member.name}'s Picture`}
                      className={styles.teamImage}
                    />
                    <TeamMemberName>{member.name}</TeamMemberName>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TeamSection;
