import React, { useEffect } from "react";

import styles from "./styles.module.scss";
import ColorSwatch from "./ColorSwatch";

import { usePricesContext } from "./Product";
import { markMatchedText } from "utilities/markText";

export default ({
  dgiStyle,
  color,
  setOpen,
  inColorDialog,
  hasSwatchText = true,
  isFirstColor = false,
  sendColorMessage,
  colorInSearchBox,
  resetSelectionDialog = null,
}) => {
  const { selectedColor, setSelectedColor } = usePricesContext();
  const isSelected = color.name === selectedColor.name;

  const selectColorState = () => {
    setOpen(false);
    setSelectedColor(color);
  };

  const onSelectColor = (e) => {
    e.stopPropagation();
    selectColorState();
    sendColorMessage(color?.name);
    if (resetSelectionDialog) {
      resetSelectionDialog();
    }
  };

  useEffect(() => {
    if (isFirstColor && selectedColor.name === "") {
      selectColorState();
    }
  }, [isFirstColor, selectedColor]);

  const markedText = markMatchedText(color?.name, colorInSearchBox);

  if (!color?.name) {
    return null;
  }

  return (
    <div
      className={styles.priceParam}
      data-dialog={inColorDialog}
      data-selected={isSelected}
      data-short-view={!hasSwatchText}
      onClick={onSelectColor}
    >
      <ColorSwatch
        dgiStyle={dgiStyle}
        color={color.name}
        hasSwatchText={hasSwatchText}
      />
      <div className={styles.previewColor}>
        <span className="text-sm font-extralight">{color.name}</span>
      </div>
      {hasSwatchText && (
        <div className={styles.swatchTextWrapper}>
          <span className="text-sm font-extralight">{markedText}</span>
        </div>
      )}
    </div>
  );
};
