import React from "react";
import styles from "./styles.module.scss";

import { Typography } from "@mui/material";

export function PriceRowText({ content, price = false, loading = false }) {
  const shimmerStyling = (price && loading) && styles.shimmer;
  const shrinkContent = content === "Color N/A";
  let textSize = price ? "text-lg" : "text-sm";
  if (shrinkContent) {
    textSize = "text-almostSM";
  }
  const textColor = shrinkContent && "text-[#383838]";
  return <Typography className={`${textSize} ${textColor} ${shimmerStyling}`}>{content}</Typography>;
}

export function ProductInfoText({ emboldenedContent, content, textClassName = null }) {
  let renderText = null;
  if (emboldenedContent) {
    renderText = (
      <>
        <span className={`font-bold text-xl ${textClassName}`}>{emboldenedContent}</span>
        <span className={`text-lg ${textClassName}`}>{content}</span>
      </>
    );
  } else {
    renderText = <span className={`text-lg ${textClassName}`}>{content}</span>;
  }

  return <Typography className={`text-xl ${textClassName}`}>{renderText}</Typography>;
}

export function ColorInfoText({ content, selectedColor }) {
  let renderText = null;
  if (selectedColor) {
    renderText = (
      <>
        <span className="text-fadedGray">{content}</span>
        <span>{selectedColor}</span>
      </>
    );
  } else {
    renderText = <span className="text-fadedGray">{content}</span>;
  }

  return <Typography className="text-base">{renderText}</Typography>;
}
