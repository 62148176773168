import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack, Skeleton } from "@mui/material";
import RecyclingIcon from "@mui/icons-material/Recycling";
import DiscountIcon from "@mui/icons-material/Discount";
import { formatBrandName } from "utilities/formatBrandName";
import { useNavigate, useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { usePostHog } from "posthog-js/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStyleSuccess, selectStyle } from "features/styleSlice";
import { useUserStateContext } from "App";
import lilProductClient from "../../axios/lilProductClient";
import endpoints from "../../axios/endpoints";
import { processProductData } from "./ProductPage";
import { constructImageUrl } from "utilities/imageUrlUtils";
import { RenderStartingPrices } from "./AdditionalStyles";
import getStyleNumber from "utilities/getStyleNumber";
import { fetchPrice } from "features/useSocketPayloadSender";
import { useProductContext } from "./ProductPage";

const AdDescription = ({ adBrand }) => {
  let adIcon = null;
  let adText = null;
  if (adBrand === "Allmade") {
    adIcon = (
      <RecyclingIcon
        className="fill-actionGreen"
        sx={{ fontSize: "1.75rem" }}
      />
    );
    adText = "Sustainable Alternative";
  } else if (adBrand === "Hanes") {
    adIcon = (
      <DiscountIcon className="fill-[#5bd4c0]" sx={{ fontSize: "1.33rem" }} />
    );
    adText = "Low Cost Alternative";
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      marginBottom="0.5rem"
    >
      {adIcon}
      <Typography
        variant="caption"
        component="div"
        align="left"
        sx={{
          fontFamily: "Arial, sans-serif",
          fontWeight: "300",
          color: "#2a2a2a",
          fontSize: "0.6rem",
        }}
        className="text-lg"
      >
        {adText}
      </Typography>
    </Stack>
  );
};

const ProductPageSimilarAd = ({
  originalStyleData,
  origialProductColor,
  sendJsonMessage,
}) => {
  const [loadingProduct, setLoadingProduct] = useState(true);
  const { userCreds } = useUserStateContext();
  const posthog = usePostHog();
  const { setLoadingProductData } = useProductContext();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Select the style from the Redux store
  let adStyleData = useSelector((state) =>
    selectStyle(state, originalStyleData?.ad_dgi_style)
  );

  let connectedVendors = useSelector((state) =>
    Object.keys(state?.user?.user?.Distributors || {})
  );

  const trackSimilarAdImpression = () => {
    posthog?.capture("view_similar_ad", {
      original_vendor_style: getStyleNumber(originalStyleData),
      original_dgi_style_sku: originalStyleData?.dgi_style_sku,
      original_product_brand: originalStyleData?.brand,
      original_product_color: origialProductColor,
      similar_vendor_style: getStyleNumber(adStyleData),
      similar_dgi_style_sku: adStyleData?.dgi_style_sku,
      similar_product_brand: adStyleData?.brand,
      similar_product_color: originalStyleData?.ad_master_color,
      source: location.pathname,
    });
  };

  const trackSimilarAdClick = () => {
    posthog?.capture("clicked_similar_ad", {
      original_vendor_style: getStyleNumber(originalStyleData),
      original_dgi_style_sku: originalStyleData?.dgi_style_sku,
      original_product_brand: originalStyleData?.brand,
      original_product_color: origialProductColor,
      similar_vendor_style: getStyleNumber(adStyleData),
      similar_dgi_style_sku: adStyleData?.dgi_style_sku,
      similar_product_brand: adStyleData?.brand,
      similar_product_color: originalStyleData?.ad_master_color,
      source: location.pathname,
    });
    setLoadingProductData(true);
  };

  useEffect(() => {
    (async () => {
      try {
        // If not, fetch from the API
        if (!adStyleData) {
          setLoadingProduct(true);
          const headers = {
            "User-Identifier": userCreds.cognitoID,
          };
          const productResp = await lilProductClient.get(
            endpoints.getProductInfo(originalStyleData?.ad_dgi_style),
            { headers }
          );

          adStyleData = processProductData(productResp?.data);

          // Dispatch to Redux
          dispatch(fetchStyleSuccess({ styleObj: adStyleData }));
          if (originalStyleData?.ad_dgi_style) {
            // This check is important as we only want to count
            // impressions once the component is mounted + ad content is loaded.
            // There is a check to see whether this conditional would pass
            // a component-level above but keep this just in case.
            trackSimilarAdImpression();
          }
        } else if (adStyleData && originalStyleData?.ad_dgi_style) {
          // This check is important as we only want to count
          // impressions once the component is mounted + ad content is loaded.
          // There is a check to see whether this conditional would pass
          // a component-level above but keep this just in case.
          trackSimilarAdImpression();
        }
        fetchPrice(
          dispatch,
          userCreds?.cognitoID,
          connectedVendors,
          adStyleData,
          originalStyleData?.ad_master_color,
          sendJsonMessage
        );
        setLoadingProduct(false);
      } catch (error) {
        console.error(
          `Failed to get product data for ${originalStyleData?.ad_dgi_style}: ${error.message}`
        );
        setLoadingProduct(false);
      }
    })();
  }, [originalStyleData?.ad_dgi_style, dispatch]);

  if (loadingProduct) {
    return (
      <Skeleton
        className="rounded-[10px]"
        variant="rectangular"
        width={500}
        height={165}
      />
    );
  }
  const adImage = constructImageUrl(
    adStyleData?.dgi_style_sku,
    originalStyleData?.ad_master_color,
    0
  );
  const adStyleNumber = originalStyleData?.ad_style;
  const adBrand = adStyleData?.master_brand;

  const encodedColor = encodeURIComponent(originalStyleData?.ad_master_color);
  const adURL = `/product/${adStyleData?.dgi_style_sku}/${encodedColor}`;

  const fontFamilyStyle = { fontFamily: "Arial, sans-serif" };

  const handleNavigation = () => {
    trackSimilarAdClick();
    navigate(adURL);
  };

  const toRendorStartingPrices =
    adBrand === "Allmade" ? null : (
      <RenderStartingPrices
        dgiStyle={adStyleData?.dgi_style_sku}
        masterColor={originalStyleData?.ad_master_color}
        vendorCodes={connectedVendors}
        fetchingSimilarsPrices={true}
        priceText={adBrand === "Hanes" && "Price as low as"}
      />
    );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="center"
      marginBottom="0rem"
      marginLeft="0rem"
      marginRight="0rem"
      width="500px"
      paddingRight="3.5rem"
      sx={{
        "&:hover .hover-text": {
          color: "rgba(0, 110, 165, 1)",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        padding="0"
        width="500px"
        height="175px"
        borderRadius="5px"
        border="1px solid"
        borderColor="#d2d6da"
        overflow="hidden"
        gap="3rem"
        position="relative"
        sx={{
          cursor: "pointer",
          "&:hover .hover-text": {
            color: "rgba(0, 110, 165, 1)",
          },
          boxShadow:
            "inset 0 -4px 12px 0 rgba(0, 0, 0, 0.05), inset 0 -2px 6px 0 rgba(0, 0, 0, 0.03)",
        }}
        onClick={handleNavigation}
      >
        <Box
          height="100%"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          paddingLeft="0.5rem"
          paddingTop="0.25rem"
          paddingBottom="0.25rem"
        >
          <Box
            component="img"
            src={adImage}
            alt="Similar Product"
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              borderRadius: "5px",
            }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          paddingRight="0.5rem"
          paddingTop="1rem"
          gap="0.25rem"
        >
          <AdDescription adBrand={adBrand} />

          <Stack direction="row" spacing={1}>
            <Typography
              variant="body2"
              component="span"
              className="hover-text text-lg"
              sx={{
                ...fontFamilyStyle,
                fontWeight: "bold",
                marginBottom: "0.2rem",
              }}
            >
              {adStyleNumber}
            </Typography>
            <Typography
              variant="caption"
              component="span"
              className="hover-text text-lg"
              sx={{
                ...fontFamilyStyle,
                whiteSpace: "nowrap",
              }}
            >
              {`by ${formatBrandName(adBrand)}`}
            </Typography>
          </Stack>
          <Typography className="hover-text">
            {adStyleData?.master_short_description}
          </Typography>
          {toRendorStartingPrices}
        </Box>

        <Stack
          direction="row"
          alignItems="flex-start"
          spacing={0.5}
          sx={{
            position: "absolute",
            bottom: "0.5rem",
            right: "0.5rem",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            className="text-grayscaleStone"
            sx={{
              fontFamily: "Arial, sans-serif",
              fontSize: "0.75rem",
              lineHeight: 1, // Add this line
            }}
          >
            Sponsored
          </Typography>
          <InfoIcon
            sx={{
              fontSize: "0.75rem",
              marginTop: "1px", // Add this line
            }}
            className="fill-charcoal"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default ProductPageSimilarAd;
