import React from "react";
import { Stack, Typography } from "@mui/material";

export function CheckoutItemText({ content, infoTag = null, hidePrices = false }) {
  const contentStyle = hidePrices ? { color: "transparent" } : {};
  return (
    <Stack direction="row" spacing={1}>
      {infoTag && (
        <Typography className="text-almostSM text-[#5c606f]">
          {infoTag}
        </Typography>
      )}
      <Typography className="text-almostSM" sx={contentStyle}>
        {content}
      </Typography>
    </Stack>
  );
}

export function MoreCartDetailsText({ content, style }) {
  return (
    <Typography className="text-almostSM" style={style}>
      {content}
    </Typography>
  );
}

export function CartHeaderText({ content, hidePrices }) {
  const contentStyle = hidePrices ? { color: "transparent" } : {};
  return (
    <Typography sx={{ ...contentStyle, fontSize: "0.85rem", whiteSpace: "nowrap" }}>
      {content}
    </Typography>
  );
}

export function TotalFigureText({ content, label, hidePrices }) {
  const contentStyle = hidePrices && label === "subtotal" ? { color: "transparent" } : {};
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Typography className="text-base" sx={contentStyle}>
        {content}
      </Typography>
      <Typography className="text-base">
        {label}
      </Typography>
    </Stack>
  );
}
