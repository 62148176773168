import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import CheckoutHeader from "./CheckoutHeader";
import { useParams } from "react-router-dom";
import { CheckoutDisclaimer } from "custom/text/StandardCheckoutTexts";
import vendors from "constants/vendors";
import RenderCheckoutFields from "./RenderCheckoutFields";

const CheckoutContent = ({
  checkoutDetails,
  loadingCheckoutDetails,
  getShippingMethods,
  loadingShippingMethods,
  setInvalidPONumber
}) => {
  const { vendorCode } = useParams();
  const [vendorName, setVendorName] = useState("");

  useEffect(() => {
    const newVendorName = vendors[vendorCode + "_data"]["name"];
    setVendorName(newVendorName);
  }, [vendorCode]);

  return (
    <div className="flex-1 py-4">
      <Stack 
        direction="column" 
        spacing={2}
        sx={{
          '& .checkoutSection': {
            border: "1px solid lightgray",
            borderLeft: "2px solid rgb(2, 129, 192)" // lighterblueblue
          },
        }}
      >
        <CheckoutHeader vendorName={vendorName} />
        <CheckoutDisclaimer vendorName={vendorName} />
        <RenderCheckoutFields
          vendorName={vendorName}
          loadingCheckoutDetails={loadingCheckoutDetails}
          checkoutDetails={checkoutDetails}
          getShippingMethods={getShippingMethods}
          loadingShippingMethods={loadingShippingMethods}
          setInvalidPONumber={setInvalidPONumber}
        />
      </Stack>
    </div>
  );
};

export default CheckoutContent;
