import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import ColorSwatch from "../ColorSwatch";
import { useInventoryContext } from "./InventoryDetails";
import { getLocalCutoffTime } from "../../utilities/parseInventoryData";
import { Button, CircularProgress } from "@mui/material";
import InventoryCells from "./InventoryCells";
import {
  Stack,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
  TableCell,
  Typography,
  LinearProgress,
} from "@mui/material";
import {
  InventoryTableRowText,
  InventoryTableHeaderText,
  InventoryTablePriceLabels,
} from "custom/text/StandardInventoryTableTexts";
import { DateTime } from "luxon";
import {
  TIMEZONE_LOOKUP,
  checkIfMultipleEQPs,
} from "../../utilities/parseInventoryData";
import { checkIfSaleExists } from "utilities/parseInventoryData";
import { LOADING_STATES } from "features/productsSlice";
import SuggestVendorConnection from "./SuggestVendorConnection";

const CaseQtyTip = ({ displayTip }) => {
  if (displayTip) {
    return (
      <Box>
        <Typography sx={{ fontWeight: "600" }}>
          * Must order in case quantity increments
        </Typography>
      </Box>
    );
  }
  return null;
};

const InventoryTable = ({
  hit,
  sizedPricingData,
  inventoryData,
  isBlankAllowed,
  productColors,
  vendorCode,
  checkoutEntries,
  setCheckoutEntries,
  loadingTableData,
  setLoadingTableData,
  setInvalidTableCells,
  showSSCartWarning,
  triggerSaveCart,
  saveBtnAvailable,
  selectedColor,
  fullDisplay = false,
  applyBorder = false,
  vendorProduct,
  selectedVendorCartData,
  savingToCart,
  fetchingUpdatedData,
  hidePrices,
}) => {
  const { selectedVendorKey } = useInventoryContext();
  const [selectedBodyRowIndex, setSelectedBodyRowIndex] = useState(null);

  useEffect(() => {
    if (vendorProduct) {
      const priceLoadingStatus =
        vendorProduct?.loading === LOADING_STATES.LOADING_STATUS;
      if (priceLoadingStatus) {
        setLoadingTableData(true);
      } else {
        setLoadingTableData(false);
      }
    } else {
      setLoadingTableData(true);
    }
  }, [vendorProduct, selectedVendorKey]);

  useEffect(() => {
    setCheckoutEntries({});
    setInvalidTableCells(new Set());
  }, [selectedVendorKey, selectedColor]);

  let dbFields = {};
  const colorObj = productColors.find((color) => {
    return color.name.toLowerCase() === selectedColor.name.toLowerCase();
  });
  if (colorObj) {
    dbFields = {
      cmColorCode: colorObj?.cm_color_code,
      ssCID: Number(colorObj?.ss_swatch_color_id),
      ss_color: colorObj?.ss_color,
      ssSIDM: Number(colorObj?.ss_sku_id_master),
      abColorCode: colorObj?.ab_color_code,
    };
  }

  const getCutoffTimeText = (cutoffTime) => {
    if (!cutoffTime) {
      return cutoffTime;
    }
    // accounting for non-date strings...once we see more cases
    // come in, we can better abstract the cases
    const nonDateKeywords = ["tomorrow", "today", "shipping", "ship"];
    const nonDateString = nonDateKeywords.some((keyword) => {
      return cutoffTime.toLowerCase().includes(keyword);
    });
    if (nonDateString) {
      return cutoffTime;
    }

    const isCutoffPresent = cutoffTime.split(" ")[0].toLowerCase() === "cutoff";
    if (isCutoffPresent) {
      return cutoffTime;
    }

    let localCutoffTime = cutoffTime;
    const localTimeZone = DateTime.local().zoneName;
    const cutoffTimeZone = TIMEZONE_LOOKUP[cutoffTime.split(" ").slice(-1)];
    if (localTimeZone !== cutoffTimeZone) {
      localCutoffTime = getLocalCutoffTime(localCutoffTime);
    }

    return `Cutoff ${localCutoffTime}`;
  };

  const displayCaseQtyMessage = () => {
    if (!inventoryData) {
      return false;
    }
    const displayMessage = inventoryData.find((whseEntry) => {
      const whseData = whseEntry[1];
      if (!whseData) {
        return false;
      }
      const whseQuantities = whseData["quantities"];
      if (!whseQuantities) {
        return false;
      }
      const foundCaseQtyFlag = whseQuantities.find((stockDataEntry) => {
        return stockDataEntry["caseQtyRequired"];
      });
      return Boolean(foundCaseQtyFlag);
    });
    return Boolean(displayMessage);
  };

  const productPrice = vendorProduct?.["data"]?.["product_price"];
  const isProductLoading =
    vendorProduct?.["loading"] === LOADING_STATES.LOADING_STATUS;
  // loadingTableData lets us know that we are in some kind of intermediate state
  const fetchingInitialData =
    loadingTableData && !productPrice && isProductLoading;
  let renderLoadingState = null;
  if (fetchingInitialData) {
    renderLoadingState = (
      <div className={styles.dataSpinnerWrapper}>
        <CircularProgress size="4rem" />
        <div>
          <span>Loading inventory data...</span>
        </div>
      </div>
    );
  }

  const hasNoData =
    !loadingTableData && (!inventoryData || !inventoryData.length);
  const renderNoDataMsg = hasNoData && isBlankAllowed && (
    <div className={styles.noDataMsg}>
      <span>No inventory data for selected color</span>
    </div>
  );

  const tableWrapperStyle = applyBorder
    ? {
        borderLeft: "1px solid #d2d6da",
        borderRight: "1px solid #d2d6da",
        borderBottom:
          loadingTableData || renderNoDataMsg ? "1px solid #d2d6da" : "none",
        borderRadius: "5px",
      }
    : null;

  const formatPrice = (price) => {
    if (!price) {
      return "-";
    }

    if (typeof price === "string" && (price.startsWith("$") || price === "-")) {
      return price;
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  const isInDateFormat = (dateStr) => {
    // regex for mm/dd format with or without leading zeros
    const dateFormatRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/;
    return dateFormatRegex.test(dateStr);
  };
  const convertToDateFormat = (dateStr) => {
    return dateStr
      .split("/")
      .map((unit) => (unit.length === 1 ? unit.padStart(2, "0") : unit))
      .join("/");
  };
  const getMMDDString = (estDateStr) => {
    if (vendorCode === "ss") {
      return estDateStr.split(" ")[1];
    }
    return estDateStr;
  };

  const checkEarliestDeliveryDate = (estDateStr) => {
    if (!inventoryData || Object.keys(inventoryData).length === 0) {
      return false;
    }

    if (!estDateStr) {
      return false;
    }

    let estDate = getMMDDString(estDateStr);

    // turns e.g. 2/9 into 02/09
    if (isInDateFormat(estDate)) {
      estDate = convertToDateFormat(estDate);
    }

    let earliestDateStr = null;
    let earliestDateTimeObj = null;
    for (const inventoryEntry of inventoryData) {
      if (!Object.keys(inventoryEntry[1]).includes("estDeliveryDate")) {
        continue;
      }

      let dateString = inventoryEntry[1]["estDeliveryDate"];
      if (vendorCode === "ss") {
        dateString = dateString.split(" ")[1];
      }

      // turns e.g. 2/9 into 02/09
      if (isInDateFormat(dateString)) {
        dateString = convertToDateFormat(dateString);
      }

      if (!dateString) {
        continue;
      }

      const dateTimeObj = DateTime.fromFormat(dateString, "MM/dd");
      if (!earliestDateTimeObj && isInDateFormat(dateString)) {
        earliestDateStr = dateString;
        earliestDateTimeObj = dateTimeObj;
        continue;
      }

      if (dateTimeObj < earliestDateTimeObj) {
        earliestDateStr = dateString;
        earliestDateTimeObj = dateTimeObj;
      }
    }

    return estDate === earliestDateStr;
  };

  const sortWarehousesByDeliveryDate = (whseA, whseB) => {
    const whseADeliveryDate = whseA[1] ? whseA[1]["estDeliveryDate"] : null;
    const whseBDeliveryDate = whseB[1] ? whseB[1]["estDeliveryDate"] : null;

    let whseADateTime, whseBDateTime;
    if (!whseADeliveryDate) {
      whseADateTime = Infinity;
    } else {
      whseADateTime = getMMDDString(whseADeliveryDate);
      // turns e.g. 2/9 into 02/09
      if (isInDateFormat(whseADateTime)) {
        whseADateTime = convertToDateFormat(whseADateTime);
        whseADateTime = DateTime.fromFormat(whseADateTime, "MM/dd");
      } else {
        whseADateTime = Infinity;
      }
    }
    if (!whseBDeliveryDate) {
      whseBDateTime = Infinity;
    } else {
      whseBDateTime = getMMDDString(whseBDeliveryDate);
      // turns e.g. 2/9 into 02/09
      if (isInDateFormat(whseBDateTime)) {
        whseBDateTime = convertToDateFormat(whseBDateTime);
        whseBDateTime = DateTime.fromFormat(whseBDateTime, "MM/dd");
      } else {
        whseBDateTime = Infinity;
      }
    }

    if (whseADateTime < whseBDateTime) {
      return -1;
    }
    if (whseADateTime > whseBDateTime) {
      return 1;
    }
    return 0;
  };

  const sortStockedWarehouses = (whseA, whseB) => {
    const aHasStock = whseA[1]?.quantities?.some((item) => item?.stockNum > 0);
    const bHasStock = whseB[1]?.quantities?.some((item) => item?.stockNum > 0);

    if (aHasStock && !bHasStock) return -1;
    if (!aHasStock && bHasStock) return 1;
    return 0;
  };

  const sortWarehouses = (warehouses) => {
    // First, sort by delivery date
    const sortedByDate = warehouses.sort(sortWarehousesByDeliveryDate);

    // Then, sort the result by stock
    return sortedByDate.sort(sortStockedWarehouses);
  };

  const renderNoBlankRow = !loadingTableData && !isBlankAllowed && (
    <div className={styles.noDataMsg}>
      More information available on vendor site
    </div>
  );

  const saleExists = checkIfSaleExists(sizedPricingData);
  const multipleEQPs = checkIfMultipleEQPs(sizedPricingData);
  const displayTableHeader = !fetchingInitialData;
  const displayInventoryContent =
    !loadingTableData || savingToCart || fetchingUpdatedData;
  const saveBtnText = savingToCart ? "Saving..." : "Save to Cart";
  const tableBodyStyling = savingToCart
    ? styles.inventoryTableBodySaveState
    : styles.inventoryTableBody;

  return (
    <div className={styles.inventoryTables} style={tableWrapperStyle}>
      <div className={styles.inventoryTableHeader}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "400",
                  width: "15%",
                  padding: "4px",
                  verticalAlign: "bottom",
                }}
              >
                <div className={multipleEQPs && `flex justify-between`}>
                  <Box width={multipleEQPs ? "50%" : "100%"}>
                    <div className={styles.inventoryColorCell}>
                      <ColorSwatch
                        dgiStyle={hit.id}
                        isSelected={false}
                        color={colorObj.name}
                        hasSwatchText={true}
                        disabled={true}
                      />
                    </div>
                    <div className={styles.invColorTextWrapper}>
                      <span className="text-sm">{selectedColor.name}</span>
                    </div>
                  </Box>
                  {multipleEQPs && <InventoryTablePriceLabels />}
                </div>
              </TableCell>
              {displayTableHeader &&
                sizedPricingData &&
                sizedPricingData.map((dataEntry) => (
                  <TableCell sx={{ padding: "4px" }}>
                    <InventoryTableHeaderText
                      size={dataEntry[0]}
                      piecePrice={formatPrice(dataEntry[4])}
                      dozenPrice={formatPrice(dataEntry[3])}
                      originalPrice={formatPrice(dataEntry[2])}
                      salePrice={formatPrice(dataEntry[1])}
                      saleExists={saleExists}
                      hidePrices={hidePrices}
                    />
                  </TableCell>
                ))}
              <TableCell
                sx={{
                  width:
                    !displayInventoryContent || hasNoData || !selectedVendorKey
                      ? "85%"
                      : "15%",
                }}
              >
                {displayInventoryContent && isBlankAllowed && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        height: "100%",
                        minHeight: "3rem",
                        width: hasNoData ? "120px" : "100%",
                      }}
                      className="text-sm text-grayscaleWhite bg-lighterblueblue hover:bg-blueblue"
                      disabled={
                        saveBtnAvailable &&
                        Object.keys(checkoutEntries).length > 0
                          ? false
                          : true
                      }
                      variant="contained"
                      onClick={triggerSaveCart}
                    >
                      {saveBtnText}
                    </Button>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </div>

      {renderLoadingState}
      {displayInventoryContent && isBlankAllowed && (
        <div
          className={tableBodyStyling}
          data-ss-cart-warning={showSSCartWarning}
          data-full-display={fullDisplay}
        >
          <div
            className={styles.saveToCartLoadingState}
            data-display-loading-animation={savingToCart}
          >
            <LinearProgress />
          </div>
          <Table>
            <TableBody>
              {inventoryData &&
                sortWarehouses(inventoryData.slice()) // need to copy array before sorting
                  .map((dataEntry, index) => (
                    <TableRow
                      sx={{ height: "80px" }}
                      className={`${
                        index === selectedBodyRowIndex
                          ? "bg-zinc-100"
                          : "hover:bg-zinc-50"
                      }`}
                    >
                      <TableCell sx={{ width: "15%", padding: "10px" }}>
                        <Stack
                          direction="column"
                          alignItems="center"
                          sx={{ width: "100%", gap: "4px" }}
                        >
                          <Box
                            sx={{
                              textAlign: "center",
                              display: "inline-block",
                              verticalalign: "middle",
                            }}
                          >
                            <InventoryTableRowText
                              rowText={dataEntry[0]}
                              emboldenText={true}
                            />
                          </Box>
                          <Box
                            sx={{
                              textAlign: "center",
                              display: "inline-block",
                              verticalalign: "middle",
                            }}
                          >
                            <InventoryTableRowText
                              rowText={getCutoffTimeText(
                                dataEntry[1]["deliveryCutoffTime"]
                              )}
                            />
                          </Box>
                        </Stack>
                      </TableCell>
                      <InventoryCells
                        vendorCode={vendorCode}
                        warehouseInventoryInfo={dataEntry}
                        loadingTableData={loadingTableData}
                        setCheckoutEntries={setCheckoutEntries}
                        setInvalidTableCells={setInvalidTableCells}
                        sizedPricingData={sizedPricingData}
                        checkoutEntries={checkoutEntries}
                        bodyRowIndex={index}
                        setSelectedBodyRowIndex={setSelectedBodyRowIndex}
                        triggerSaveCart={triggerSaveCart}
                        saveBtnAvailable={saveBtnAvailable}
                        selectedVendorCartData={selectedVendorCartData}
                        fetchingUpdatedData={fetchingUpdatedData}
                      />
                      <TableCell
                        sx={{
                          width:
                            !displayTableHeader || hasNoData ? "auto" : "15%",
                          padding: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            display: "inline-block",
                            verticalAlign: "middle",
                            backgroundColor:
                              checkEarliestDeliveryDate(
                                dataEntry[1]["estDeliveryDate"]
                              ) && "rgb(124 222 124 / 55%)",
                            padding: "4px 16px",
                            borderRadius: "15px",
                          }}
                        >
                          <InventoryTableRowText
                            rowText={`Est delivery ${
                              dataEntry[1]["estDeliveryDate"] ?? "N/A"
                            }`}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <CaseQtyTip displayTip={displayCaseQtyMessage()} />
        </div>
      )}
      {renderNoBlankRow}
      {renderNoDataMsg}
      <SuggestVendorConnection
        vendorCode={vendorCode}
        inventoryDataExists={Boolean(inventoryData)}
      />
    </div>
  );
};

export default InventoryTable;
