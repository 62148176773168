import { fetchCartsStartBatch } from "./cartsSlice";
// Sends a get_carts websocket request
// Also sets every display cart to loading
export function sendGetCartsPayload(
  userId,
  sendJsonMessage,
  dispatch,
  connectedVendorCodes,
  onlyIfIdle = false,
) {
  if (userId == null || connectedVendorCodes == null) {
    return;
  }
  const vendorCodes =
    Object.entries(connectedVendorCodes)
      .filter((vendor) => {
        return vendor[1];
      })
      .map((vendor) => {
        const dataKey = vendor[0];
        return dataKey?.split("_")[0];
      })
  const dispatchResp = dispatch(
    fetchCartsStartBatch(
      { vendorCodes: vendorCodes, onlyIfIdle: onlyIfIdle }
    )
  )
  const allCartsLoading = dispatchResp?.updatedVendors?.length === 0
  if (allCartsLoading) {
    return;
  }
  const payload = {
    "action": "get_carts",
    "user_id": userId,
  }
  sendJsonMessage(payload);
};
