import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import getTodaysDate from "utilities/getTodaysDate";
import { formatShippingAddress } from "utilities/formatShippingAddress";
import { useCheckoutContext } from "App";

const ShippingPOSummary = ({ shippingAddress }) => {
  const [formattedShippingAddr, setFormattedShippingAddr] = useState("");
  const [displayPONumber, setDisplayPONumber] = useState("");
  const { checkoutFormData } = useCheckoutContext();

  useEffect(() => {
    if (shippingAddress) {
      const newFormatAddress = formatShippingAddress(shippingAddress);
      setFormattedShippingAddr(newFormatAddress);
    }
  }, [shippingAddress]);

  useEffect(() => {
    setDisplayPONumber(
      checkoutFormData["poNumber"].length > 0
        ? checkoutFormData["poNumber"]
        : getTodaysDate(),
    );
  }, [checkoutFormData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        gap: 2,
      }}
    >
      <Box
        className="confirmationSection"
        sx={{
          width: "70%",
          borderTop: "none",
          pb: 2,
          backgroundColor: "white",
        }}
      >
        <Box className="bg-dustyBlue" sx={{ p: 1, mb: 2 }}>
          <Typography className="text-grayscaleStone font-bold">
            Ship To Address:
          </Typography>
        </Box>
        <Typography sx={{ pl: 1 }}>{formattedShippingAddr}</Typography>
      </Box>

      <Box
        className="confirmationSection"
        sx={{
          width: "30%",
          borderTop: "none",
          pb: 2,
          textAlign: "start",
          backgroundColor: "white",
        }}
      >
        <Box className="bg-dustyBlue" sx={{ p: 1, mb: 2 }}>
          <Typography className="text-grayscaleStone font-bold">
            PO Number:
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: "white" }}>
          <Typography sx={{ pl: 1 }}>{displayPONumber}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShippingPOSummary;
