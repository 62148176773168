import React from "react";
import Stack from "@mui/material/Stack";
import WarehouseDeliveryMethod from "./WarehouseDeliveryMethod";

const WarehouseDeliverySections = ({ shippingMethods, whsePickupDate, whsePickupTime }) => {
  return (
    <Stack direction="column">
      {shippingMethods.map((method) => (
        <WarehouseDeliveryMethod 
          shippingMethod={method} 
          whsePickupDate={whsePickupDate}
          whsePickupTime={whsePickupTime}
        />
      ))}
    </Stack>
  );
};

export default WarehouseDeliverySections;
