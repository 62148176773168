import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { formatPrice } from "utilities/formatPrice";

const WarehouseDeliveryMethod = ({
  shippingMethod,
  whsePickupDate,
  whsePickupTime,
}) => {
  const warehouseName = shippingMethod ? shippingMethod["warehouse"] : "";
  const estDeliveryDate = shippingMethod ? shippingMethod["estDelivery"] : "";
  const deliveryMethodName = shippingMethod ? shippingMethod["name"] : "";
  const freightCost = shippingMethod
    ? typeof shippingMethod["freightCost"] === "string"
      ? shippingMethod["freightCost"]
      : formatPrice(shippingMethod["freightCost"])
    : "";
  // in the case user is picking up from CM warehouse
  const displayPickupOptions =
    shippingMethod && shippingMethod?.["name"]?.toLowerCase() === "pick up";

  return (
    <Box>
      <Box
        className="confirmationSection"
        sx={{
          borderTop: "none",
          mt: 2,
          pb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Box className="bg-dustyBlue" sx={{ p: 1, mb: 2, width: "100%" }}>
          <Typography
            className="font-bold text-grayscaleStone"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Shipping Method For:
            <span
              style={{ fontWeight: "700", color: "black", marginLeft: "8px" }}
            >
              {warehouseName}
            </span>
          </Typography>
        </Box>
        <Box className="w-full">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container spacing={4} sx={{ width: "100%", pl: 1 }}>
                <Grid item xs={12} sm={4}>
                  <Typography>{deliveryMethodName}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>Est. Delivery: {estDeliveryDate}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>{freightCost}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {displayPickupOptions && (
              <Grid item>
                <Grid container spacing={4} sx={{ width: "100%", pl: 1 }}>
                  <Grid item xs={12} sm={6}>
                    Pickup Date: {whsePickupDate}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    Pickup Time: {whsePickupTime}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default WarehouseDeliveryMethod;
