import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import styles from "./styles.module.scss";
import RoleDropdownmenu from "./RoleDropdownMenu";
import StateDropdownMenu from "./StateDropdownMenu";

function UserInfoFields({
  control,
  errors,
  setValue,
  role,
  state,
  setRole,
  setState,
}) {
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.onload = initAutocomplete;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && autocompleteRef.current) {
        e.preventDefault(); // Prevents form submission
        const place = autocompleteRef.current.getPlace();
        if (place && place.geometry) {
          handlePlaceSelect();
        }
      }
    };

    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  const initAutocomplete = () => {
    if (!inputRef.current) return;

    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ["address"],
      componentRestrictions: { country: "us" },
    });

    autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) return;

    const addressComponents = place.address_components;
    const street = `${addressComponents.find(c => c.types.includes('street_number'))?.long_name || ''} ${addressComponents.find(c => c.types.includes('route'))?.long_name || ''}`.trim();
    const city = addressComponents.find(c => c.types.includes('locality'))?.long_name || addressComponents.find(c => c.types.includes('sublocality_level_1'))?.long_name;
    const stateComponent = addressComponents.find(c => c.types.includes('administrative_area_level_1'));
    const zipCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '';

    setValue('street', street);
    setValue('city', city);
    if (stateComponent) {
      setValue('state', stateComponent.short_name);
      setState(stateComponent.short_name);
    }
    setValue('zip', zipCode);
  };

  const handleManualAddressInput = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  return (
    <div className={styles.smallFieldsContainer}>
      <label>
        <div className={styles.labelText}>First Name*</div>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: 'First name is required', minLength: { value: 1, message: 'First name must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.firstName && <span className={styles.errorMessage}>{errors.firstName.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Last Name*</div>
        <Controller
          name="lastName"
          control={control}
          rules={{ required: 'Last name is required', minLength: { value: 1, message: 'Last name must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.lastName && <span className={styles.errorMessage}>{errors.lastName.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Your Role*</div>
        <Controller
          name="role"
          control={control}
          rules={{ required: 'Role is required', minLength: { value: 1, message: 'Role must be at least 1 characters' } }}
          render={({ field: { onChange } }) => <RoleDropdownmenu onChange={onChange} role={role} setRole={setRole} />}
        />
        {errors.role && <span className={styles.errorMessage}>{errors.role.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>How did you hear about us?*</div>
        <Controller
          name="referral"
          control={control}
          rules={{ required: 'Referral is required', minLength: { value: 1, message: 'Referral must be at least 1 characters' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.referral && <span className={styles.errorMessage}>{errors.referral.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Street Address*</div>
        <Controller
          name="street"
          control={control}
          rules={{ required: 'Street is required', minLength: { value: 1, message: 'Street must be at least 1 character' } }}
          render={({ field: { onChange, value } }) => (
            <input
              ref={inputRef}
              onChange={(e) => {
                onChange(e);
                handleManualAddressInput(e);
              }}
              value={value}
            />
          )}
        />
        {errors.street && <span className={styles.errorMessage}>{errors.street.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Suite/Office/Apt/Unit (optional)</div>
        <Controller
          name="address2"
          control={control}
          render={({ field }) => <input {...field} />}
        />
      </label>
      <label>
        <div className={styles.labelText}>City*</div>
        <Controller
          name="city"
          control={control}
          rules={{ required: 'City is required', minLength: { value: 1, message: 'City must be at least 1 characters' } }}
          render={({ field }) => <input {...field} value={field.value} />}
        />
        {errors.city && <span className={styles.errorMessage}>{errors.city.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>State*</div>
        <Controller
          name="state"
          control={control}
          rules={{ required: 'State is required' }}
          render={({ field: { onChange, value } }) => (
            <StateDropdownMenu
              state={state}
              setState={setState}
              onChange={(newState) => {
                onChange(newState);
                setState(newState);
              }}
              value={value}
            />
          )}
        />
        {errors.state && <span className={styles.errorMessage}>{errors.state.message}</span>}
      </label>
      <label>
        <div className={styles.labelText}>Zip Code*</div>
        <Controller
          name="zip"
          control={control}
          rules={{ required: 'Zip code is required', minLength: { value: 5, message: 'Zip code must be at least 5 digits' } }}
          render={({ field }) => <input {...field} />}
        />
        {errors.zip && <span className={styles.errorMessage}>{errors.zip.message}</span>}
      </label>
    </div>
  );
}

export default UserInfoFields;
