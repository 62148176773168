import React from "react";
import { Box } from "@mui/material";
import Shortcuts from "./Shortcuts";
import ShortcutsProfile from "./ShortcutsProfile";

export default function ShortcutsContainer() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        padding: "1rem",
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
        gap: "1rem",
        '@media (max-width: 750px)': {
          flexDirection: "column", // Stack vertically on screens 750px and below
        },
      }}
    >
      <ShortcutsProfile />
      <Shortcuts />
    </Box>
  );
}
