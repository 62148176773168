import { React, useEffect } from "react";
import {
  Stack,
  TextField,
  Button,
  Link,
  InputAdornment,
  IconButton,
  Alert,
  Typography,
  LinearProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "./styles.module.scss";

function PasswordFields({
  username,
  setUsername,
  password,
  setPassword,
  showPassword,
  setShowPassword,
  loading,
  onConnectClick,
  failedConnectionAlertType,
  showSuccessAlert,
  resetPasswordLink,
  vendorInfo,
  usernameBorder,
  setUsernameBorder,
  passwordBorder,
  setPasswordBorder,
  handleUsernameBlur,
  handleUsernameFocus,
  handlePasswordBlur,
  handlePasswordFocus,
}) {
  let failedConnectionAlert = null;
  if (failedConnectionAlertType === "error") {
    failedConnectionAlert = (
      <Alert className={styles.alertMessage} severity="error">
        {`Sorry, we couldn't verify your credentials with ${vendorInfo.name}. 
            Try checking your spelling and/or spacing. Do NOT use autofill as it may recommend your password for DGI, not ${vendorInfo.name}.`}
      </Alert>
    );
  } else if (failedConnectionAlertType === "warning") {
    failedConnectionAlert = (
      <Alert className={styles.alertMessage} severity="warning">
        {`Multiple failed attempts may result in a password reset from ${vendorInfo.name}. 
              Double-check your credentials/spelling before entering. Do NOT use autofill as it may recommend your password for DGI, not ${vendorInfo.name}.`}
      </Alert>
    );
  }

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => {
        if (window.Intercom) {
          window.Intercom('trackEvent', 'vendor_connected');
        } else {
          console.error('Intercom is not initialized');
        }
      }, 1000); // Delay of 1 second
    }
  }, [showSuccessAlert]);

  const successAlert = (
    <Alert
      className={styles.alertMessage}
      severity="success"
      sx={{ justifyContent: "center" }}
    >
      Successfully uploaded your credentials!
    </Alert>
  );

  const connectVendorButton = loading ? (
    <LinearProgress className={styles.saveLoginProgress} />
  ) : (
    <Button
      className={`bg-blueblue hover:bg-denim text-grayscaleWhite px-[12px]`}
      onClick={onConnectClick}
    >
      Connect Vendor
    </Button>
  );

  const renderControl = showSuccessAlert ? (
    successAlert
  ) : (
    <>
      {failedConnectionAlert}
      {connectVendorButton}
    </>
  );

  // Determine the type of the password field
  const passwordFieldType =
    showPassword || password.length === 0 ? "text" : "password";

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{ padding: "0 1rem 0.5rem 1rem" }}
      >
        <TextField
          id={`${vendorInfo.name}-username-input`}
          className={styles.loginField}
          data-border={!Boolean(username) && usernameBorder}
          onBlur={handleUsernameBlur}
          onFocus={handleUsernameFocus}
          label={`${vendorInfo.name} Username`}
          placeholder="Enter your username..."
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="off"
          sx={{ mt: 2 }}
        />
        <TextField
          id={`${vendorInfo.name}-password-input`}
          className={styles.loginField}
          data-border={!Boolean(password) && passwordBorder}
          onBlur={handlePasswordBlur}
          onFocus={handlePasswordFocus}
          placeholder="Enter your password..."
          label={`${vendorInfo.name} Password`}
          type={passwordFieldType}
          value={password}
          autoComplete="new-password"
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff className={styles.passwordIcon} />
                  ) : (
                    <Visibility className={styles.passwordIcon} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        spacing={1}
        sx={{ padding: "0 1rem 1rem 1rem" }}
      >
        <a
          href={resetPasswordLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`text-blueblue ${styles.resetPasswordLink}`}
        >
          Forgot your {vendorInfo.name} password?
        </a>
        {renderControl}
        <p style={{ fontSize: "0.9rem" }}>
          All credentials are secured using 256-bit end-to-end encryption.
        </p>
        <Typography
          variant="body2"
          align="center"
          style={{
            marginTop: "16px",
            color: "gray",
            fontSize: "0.8rem",
          }}
        >
          Treat this like logging in on {vendorInfo.name}'s website. Multiple
          failed attempts may result in a password reset from the vendor.
          Double-check your credentials/spelling before entering. You will only
          need to do this process on DGI one time.
        </Typography>
      </Stack>
    </>
  );
}

export default PasswordFields;
