import React from "react";
import styles from "./styles.module.scss";
import CheckIcon from "@mui/icons-material/Check";

function StatusBar() {
  return (
    <div className={styles.statusBarContainer}>
      <div className={styles.statusBarWrapper}>
        <div className={styles.circleLineWrapper}>
          <div className={styles.stepWrapper}>
            <div className={styles.circleFilled}>
              <CheckIcon className={styles.checkIcon} />
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.stepWrapper}>
            <div className={styles.circleHighlighted}></div>
          </div>
          <div className={styles.line}></div>

          <div className={styles.stepWrapper}>
            <div className={styles.circleHighlighted}></div>
          </div>
        </div>

        <div className={styles.textWrapper}>
          <div className={styles.stepText}>Account Creation</div>
          <div className={styles.stepText}>Shop Info</div>
          <div className={styles.stepText}>Vendor Connection</div>
        </div>
      </div>
    </div>
  );
}

export default StatusBar;
