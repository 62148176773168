export default {
  black: ["#000000"],
  caribbeanblue: ["#00ccce"],
  charitypink: ["#ff5a84"],
  heatherdarkgrey: ["#3a3a3a"],
  heathergrey: ["#b5b5b5"],
  royalblue: ["#4169e1"],
  white: ["#ffffff"],
  keylime: ["#e8f48c"],
  heatherpurple: ["#a3819e"],
  stormgrey: ["#747880"],
  heatherblue: ["#8ba8b7"],
  smoke: ["#738276"],
  charcoal: ["#333333"],
  graphiteheather: ["#696969"],
  tealice: ["#b2ffff"],
  heathergreen: ["#96cbb3"],
  citygreen: ["#3e773f"],
  clay: ["#b66a50"],
  ocean: ["#1c4c6a"],
  amethyst: ["#9966cc"],
  citrine: ["#e4d00a"],
  fern: ["#4f7942"],
  denim: ["#1560bd"],
  floblue: ["#66aabb"],
  bluejean: ["#5aabd0"],
  bluespruce: ["#0d5e53"],
  butter: ["#ffdd7f"],
  chalkymint: ["#a8e0bd"],
  crimson: ["#dc143c"],
  truenavy: ["#2d5c88"],
  lightgreen: ["#90ee90"],
  orchid: ["#da70d6"],
  pepper: ["#8b4e62"],
  watermelon: ["#fc6c85"],
  seafoam: ["#71EEB8"],
  periwinkle: ["#ccccff"],
  iceblue: ["#a1e9de"],
  islandreef: ["#3ab09e"],
  ivory: ["#fffff0"],
  chambray: ["#3b5998"],
  sandstone: ["#786d5f"],
  sapphire: ["#0f52ba"],
  brick: ["#9c661f"],
  crunchberry: ["#ff6b53"],
  citrus: ["#9edd40"],
  khaki: ["#f0e68c"],
  lagoon: ["#4cb7a5"],
  vineyard: ["#343642"],
  royalcaribe: ["#00b5e2"],
  banana: ["#FFF2B4"],
  bay: ["#9AAEB0"],
  berry: ["#990033"],
  washeddenim: ["#3399bb"],
  blossom: ["#ffb6c1"],
  grass: ["#8db600"],
  heliconia: ["#DB3E79"],
  violet: ["#8f00ff"],
  hemp: ["#827f79"],
  chinablue: ["#7b8dbf"],
  emerald: ["#50c878"],
  chili: ["#c21807"],
  granite: ["#676767"],
  mustard: ["#ffdb4d"],
  brightsalmon: ["#ff5b5d"],
  neonpink: ["#ff6ec7"],
  neonredorange: ["#ff3f00"],
  graphite: ["#8b8b8b"],
  burntorange: ["#cc5500"],
  yam: ["#ff4500"],
  paprika: ["#801818"],
  melon: ["#febaad"],
  midnight: ["#0B1134"],
  moss: ["#8a9a5b"],
  heatheredcardinal: ["#a43820"],
  heatherednavy: ["#333f48"],
  heatheredindigo: ["#6f7285"],
  heatheredsapphire: ["#3a5795"],
  icegrey: ["#f0f0f0"],
  texasorange: ["#ff6319"],
  tangerine: ["#ECAC5B"],
  ash: ["#b2beb5"],
  azalea: ["#f9c0c4"],
  darkheather: ["#b3b3b3"],
  galapagosblue: ["#317873"],
  indigoblue: ["#00416a"],
  irishgreen: ["#009e60"],
  iris: ["#5a4fcf"],
  jadedome: ["#00a86b"],
  antiquecherryred: ["#8b0000"],
  bluedusk: ["#66829a"],
  cardinalred: ["#c41e3a"],
  daisy: ["#edf707"],
  kiwi: ["#8ee53f"],
  antiqueirishgreen: ["#44944a"],
  cherryred: ["#de3163"],
  gold: ["#ffd700"],
  kellygreen: ["#4cbb17"],
  vegasgold: ["#c5b358"],
  forest: ["#228b22"],
  cornsilk: ["#FBFF6E"],
  natural: ["#ecedbd"],
  olive: ["#808000"],
  orange: ["#ffa500"],
  pfd: ["#fafafa"],
  lightpink: ["#ffb6c1"],
  royal: ["#023AAA"],
  safetygreen: ["#8dff00"],
  sand: ["#E4DB99"],
  sorange: ["#ff4500"],
  antiqueroyal: ["#4169E1"],
  militarygreen: ["#4b5320"],
  metroblue: ["#003399"],
  mintgreen: ["#85F6C5"],
  maroon: ["#800000"],
  safetypink: ["#ff69b4"],
  sky: ["#87ceeb"],
  prairiedust: ["#6c541e"],
  darkchocolate: ["#2F2100"],
  purple: ["#530080"],
  carolinablue: ["#56a0d3"],
  stoneblue: ["#6699cc"],
  pistachio: ["#93c572"],
  sportgrey: ["#898d8d"],
  ashgrey: ["#b2beb5"],
  chocolate: ["#d2691e"],
  truered: ["#ff0000"],
  aquaticblue: ["#5cb3ff"],
  neonorangeheather: ["#ff6c00"],
  neonlemonheather: ["#cfff00"],
  lightsteel: ["#b0c4de"],
  deepred: ["#ff0000"],
  deeproyal: ["#00008b"],
  neonlimeheather: ["#39ff14"],
  neonpinkheather: ["#ff6ec7"],
  safetyorange: ["#ff6600"],
  deepforest: ["#023E00"],
  wowpink: ["#ff4488"],
  forestgreen: ["#043E1C"],
  cyberpink: ["#ff486f"],
  blackheather: ["#3a3a3a"],
  smokegrey: ["#738276"],
  charcoalheather: ["#666053"],
  vintagegrey: ["#828282"],
  denimbue: ["#1560bd"],
  antiqueorange: ["#fa9e79"],
  antiquesapphire: ["#53778c"],
  electricgreen: ["#00ff00"],
  sunset: ["#dc9203"],
  garnet: ["#8b0000"],
  heathermilitarygreen: ["#6c7c71"],
  tropicalblue: ["#4E8BBD"],
  turfgreen: ["#4f7942"],
  cardinal: ["#8c1515"],
  cobalt: ["#0047ab"],
  coralsilk: ["#ff6f61"],
  tweed: ["#787877"],
  antiquejadedome: ["#0F8877"],
  neonblue: ["#1b03a3"],
  oldgold: ["#DFBF48"],
  russet: ["#652514"],
  heatherradiantorchid: ["#d69cbb"],
  gravel: ["#A3A4A3"],
  blackberry: ["#4d0135"],
  tennesseeorange: ["#ff8200"],
  heatherred: ["#b33b32"],
  heathersapphire: ["#4F7AFE"],
  neongreen: ["#39ff14"],
  brownsavana: ["#AA8F65"],
  yellowhaze: ["#fff07c"],
  lilac: ["#D97CDA"],
  teal: ["#00A4A0"],
  oxfordgray: ["#708090"],
  oxfordgrey: ["#708090"],
  athleticred: ["#b33b32"],
  bluebellbreeze: ["#343fff"],
  pebble: ["#778899"],
  daffodilyellow: ["#ffff31"],
  stonewashedblue: ["#8ab8e6"],
  pink: ["#ffc0cb"],
  lavender: ["#e6e6fa"],
  stonewashedgreen: ["#78a489"],
  fatiguegreen: ["#4f7942"],
  shamrockgreen: ["#009e60"],
  palepink: ["#fadadd"],
  grape: ["#6f2da8"],
  terracota: ["#e2725b"],
  cumin: ["#924d00"],
  mystic: ["#d652d6"],
  sage: ["#9cba7f"],
  heathersportroyal: ["#4b69e6"],
  heathersportscarlet: ["#ab2524"],
  sportroyal: ["#4169e1"],
  sportdarkgreen: ["#006400"],
  sportdarknavy: ["#000080"],
  heathersportdarknavy: ["#23238e"],
  sportscarletred: ["#8b0000"],
  heathersportdarkmaroon: ["#3e1a1b"],
  heathersportdarkgreen: ["#35682d"],
  darkheathergrey: ["#a9a9a9"],
  sportorange: ["#ff4500"],
  sportdarkmaroon: ["#800000"],
  heathersportblack: ["#000000"],
  heatherroyal: ["#4169e1"],
  heatherorange: ["#ffa500"],
  heatherindigo: ["#4b0082"],
  heathermaroon: ["#800000"],
  heatheririshgreen: ["#4cbb17"],
  antiqueheliconia: ["#df1995"],
  heatherberry: ["#c83f52"],
  heathercardinal: ["#8c1515"],
  heathergalapagosblue: ["#317873"],
  heatherheliconia: ["#df1995"],
  cactus: ["#5b6f55"],
  cement: ["#a3a09e"],
  royalmist: ["#4169e1"],
  slate: ["#778899"],
  steelblue: ["#4682b4"],
  pitchblackmist: ["#000000"],
  dustyrose: ["#e6a9ec"],
  gunmetal: ["#2c3539"],
  maroonmist: ["#800000"],
  navymist: ["#000080"],
  pitchblack: ["#000000"],
  plumrose: ["#dda0dd"],
  redmist: ["#ff0000"],
  independencered: ["#b32134"],
  tarmac: ["#5b6866"],
  rippedred: ["#e82628"],
  cobaltblue: ["#0047ab"],
  tarmacheather: ["#636f70"],
  riverbluenavy: ["#0e4d92"],
  tarmacgrey: ["#6b6e70"],
  blacktop: ["#000000"],
  roguegrey: ["#9fa09e"],
  deepolive: ["#556b2f"],
  drenched: ["#135239"],
  boltblue: ["#00bfff"],
  duckbrown: ["#986d4d"],
  forceblue: ["#5cacee"],
  tarmacgreyheather: ["#6d7072"],
  dieselgrey: ["#969696"],
  blackcamo: ["#000000"],
  laserred: ["#ff0000"],
  signalred: ["#e6194b"],
  anthracite: ["#282828"],
  universityred: ["#b70a02"],
  deepmaroon: ["#800000"],
  desertorange: ["#ed760e"],
  collegenavy: ["#0d5eaf"],
  gorgegreen: ["#5a9c6e"],
  courtpurple: ["#5c3278"],
  teammaroon: ["#8c1515"],
  applegreen: ["#8db600"],
  valorblue: ["#3a66a7"],
  gameroyal: ["#2558a8"],
  petrol: ["#5d8aa8"],
  trueroyal: ["#4169e1"],
  wasabe: ["#688c18"],
  blackpetrol: ["#000000"],
  olivekhaki: ["#8b7355"],
  varsitypurple: ["#4e2185"],
  universityblue: ["#5d92b1"],
  teamred: ["#cd5c5c"],
  prored: ["#cd0000"],
  luckygreen: ["#1d8915"],
  varsityroyal: ["#0033a0"],
  midnightnavy: ["#2c313c"],
  vividgreen: ["#7fff00"],
  flamingo: ["#fc8eac"],
  claygreen: ["#688e23"],
  sportred: ["#c8102e"],
  birch: ["#d4a45b"],
  mountainblue: ["#367588"],
  solarorange: ["#ff3300"],
  pacificblue: ["#1ca9c9"],
  courtblue: ["#4e6bbd"],
  varsityred: ["#c8102e"],
  pinenut: ["#817a60"],
  darknavy: ["#000080"],
  chlorophyll: ["#4aff00"],
  meangreen: ["#3d8e33"],
  universitygold: ["#ffb71c"],
  actiongreen: ["#30db0b"],
  palevanilla: ["#f4ead5"],
  arcticpink: ["#d8a7b1"],
  bluesapphire: ["#126180"],
  nightpurple: ["#663399"],
  teamorange: ["#fe541e"],
  lawn: ["#4c8031"],
  frenchblue: ["#0072bb"],
  trailsendbrown: ["#8b634b"],
  tidalblue: ["#4f90cd"],
  touryellow: ["#fed02e"],
  brilliantorange: ["#ff6600"],
  cirrusblue: ["#cce4f6"],
  vintagelichen: ["#acada1"],
  signalblue: ["#0073cf"],
  starlight: ["#dabfff"],
  fracture: ["#d0d9d9"],
  metallic: ["#d4af37"],
  blackpindot: ["#000000"],
  flushpink: ["#ff6eb4"],
  hotorange: ["#ff6a00"],
  deepindigoblue: ["#4b0082"],
  stealth: ["#46494c"],
  vibrantblue: ["#003fff"],
  flintgrey: ["#737373"],
  carbonheather: ["#625d5d"],
  mossyoakbreakupcountry: ["#3c6e47"],
  heathercharcoal: ["#36454f"],
  cream: ["#fffdd0"],
  sportsgrey: ["#8d8b8b"],
  asphalt: ["#130e0a"],
  babyblue: ["#89cff0"],
  cranberry: ["#db5079"],
  creme: ["#fffdd0"],
  lieutenant: ["#a5a551"],
  eggplant: ["#614051"],
  newsilver: ["#c0c0c0"],
  lapis: ["#26619c"],
  mint: ["#98ff98"],
  silver: ["#c0c0c0"],
  athleticheather: ["#a0a0a0"],
  jnavy: ["#000080"],
  californiablue: ["#5b83ad"],
  charcoalgrey: ["#36454f"],
  oxford: ["#6c6969"],
  classicpink: ["#ffb6c1"],
  columbiablue: ["#66A5EE"],
  vintageheathermaroon: ["#8c4848"],
  vintageheathernavy: ["#484855"],
  vintageheatherred: ["#9b5454"],
  neonyellow: ["#cfff00"],
  vintageheatherblue: ["#838da1"],
  jade: ["#00a86b"],
  islandyellow: ["#ffe614"],
  kelly: ["#4cbb17"],
  deeppurple: ["#301934"],
  scubablue: ["#00b2ca"],
  coolmint: ["#b6ffbb"],
  safari: ["#8b7355"],
  blackink: ["#000b00"],
  blackinkheather: ["#3a3a3a"],
  minttobe: ["#98ff98"],
  mustardheather: ["#ffdb58"],
  sweetcreamheather: ["#fff5e1"],
  indigoheather: ["#4b0082"],
  vintageheatherorange: ["#ffa549"],
  blushpink: ["#ff6fff"],
  forestgreenheather: ["#228b22"],
  carolinaheather: ["#56a0d3"],
  militarygreenheather: ["#4b5320"],
  maroonheather: ["#800000"],
  retroheatherroyal: ["#436b95"],
  rock: ["#6d6d6d"],
  oatmealheather: ["#cdba96"],
  sportgreyblack: ["#C0C0C0", "#000000"],
  whiteblack: ["#FFFFFF", "#000000"],
  whitenavy: ["#FFFFFF", "#000080"],
  whiteroyal: ["#FFFFFF", "#4169E1"],
  caribbeanblueheathergrey: ["#1AC1DD", "#B0B7BE"],
  heathergreenheathergrey: ["#A8E0BB", "#B0B7BE"],
  independencerednavy: ["#4C516D", "#000080"],
  blackdarkgrey: ["#000000", "#A9A9A9"],
  heatherdarkgreydarkgrey: ["#A9A9A9", "#A9A9A9"],
  navydarkgrey: ["#000080", "#A9A9A9"],
  whitedarkgrey: ["#FFFFFF", "#A9A9A9"],
  heatherblueneonyellow: ["#7AA9DD", "#CCFF00"],
  heatherpurpleneonyellow: ["#9A6AA1", "#CCFF00"],
  electricblueheathergrey: ["#7DF9FF", "#B0B7BE"],
  blackheathergrey: ["#000000", "#B0B7BE"],
  laserredheathergrey: ["#FF0000", "#B0B7BE"],
  tarmacorange: ["#4D5357", "#FFA500"],
  anthraciteanthracite: ["#414A4C", "#414A4C"],
  blackgymred: ["#000000", "#BA0002"],
  blackwhite: ["#000000", "#FFFFFF"],
  gameroyalwhite: ["#4169E1", "#FFFFFF"],
  blackvarsityred: ["#000000", "#B81204"],
  redcharcoal: ["#FF0000", "#36454F"],
  blacksilver: ["#000000", "#C0C0C0"],
  dieselgreyorange: ["#909090", "#FFA500"],
  dieselgreyelectricblue: ["#909090", "#7DF9FF"],
  whiteblacksilver: ["#FFFFFF", "#000000", "#C0C0C0"],
  blizzardpink: ["#ACE5EE", "#FFC0CB"],
  racedaysilver: ["#B0E57C", "#C0C0C0"],
  cynderfunkred: ["#6E7783", "#FF0000"],
  blackblack: ["#000000", "#000000"],
  harborgreysilver: ["#82898F", "#C0C0C0"],
  flushpinkblack: ["#FF6FFF", "#000000"],
  hotorangeblack: ["#FF6600", "#000000"],
  electricblueblack: ["#7DF9FF", "#000000"],
  geargreyblack: ["#696969", "#000000"],
  rippedredblack: ["#B01003", "#000000"],
  greyblack: ["#808080", "#000000"],
  cobaltblueblack: ["#0047AB", "#000000"],
  deepredblack: ["#8B0000", "#000000"],
  sulfurblack: ["#DAA520", "#000000"],
  blacksignalred: ["#000000", "#F00000"],
  blackroyal: ["#000000", "#4169E1"],
  navywhite: ["#000080", "#FFFFFF"],
  blackcoolgrey: ["#000000", "#8C92AC"],
  signalblueblack: ["#003399", "#000000"],
  monsoonheather: ["#83A697"],
  navygymred: ["#000080", "#BA0002"],
  varsityroyalblack: ["#1A237E", "#000000"],
  luckygreenwhite: ["#32CD32", "#FFFFFF"],
  anthraciteblack: ["#414A4C", "#000000"],
  midnightnavyheathermidnightnavy: ["#003366", "#003366"],
  blackdarkgreywhite: ["#000000", "#A9A9A9", "#FFFFFF"],
  darkgreyblacksportfuchsia: ["#A9A9A9", "#000000", "#FF00FF"],
  gameroyalblackwhite: ["#4169E1", "#000000", "#FFFFFF"],
  anthraciteheatherblack: ["#414A4C", "#000000"],
  athleticgreyheatherdarkgrey: ["#B4B3BA", "#A9A9A9"],
  darkgreysportfuchsia: ["#A9A9A9", "#FF00FF"],
  darkgreyblack: ["#A9A9A9", "#000000"],
  luckygreenblack: ["#32CD32", "#000000"],
  gymblueblack: ["#19647E", "#000000"],
  darkgrey: ["#A9A9A9"],
  gymred: ["#BA0002"],
  deepblack: ["#000000"],
  darkkhaki: ["#BDB76B"],
  fusionpink: ["#FF6FFF"],
  truewhite: ["#FFFFFF"],
  deepnavy: ["#000080"],
  fir: ["#68A03D"],
  gymblue: ["#19647E"],
  coolgrey: ["#8C92AC"],
  oldroyal: ["#40426E"],
  briskblue: ["#1273DE"],
  marine: ["#01386A"],
  blustery: ["#A5FBD5"],
  pinkfire: ["#FF6EB4"],
  volt: ["#CEFF00"],
  darksteelgreyanthracite: ["#43464B", "#414A4C"],
  sporttealanthracite: ["#00C2BE", "#414A4C"],
  photobluecollegenavy: ["#5BC0DE", "#00005C"],
  noise: ["#E5E4E2"],
  indigo: ["#4B0082"],
  lightphotoblue: ["#A6D7FF"],
  deeproyalblue: ["#00008B"],
  chartreuse: ["#7FFF00"],
  vividpinkdarkgrey: ["#FF69B4", "#A9A9A9"],
  chartreusedarkgrey: ["#7FFF00", "#A9A9A9"],
  lightphotobluedeeproyal: ["#A6D7FF", "#00008B"],
  whitewolfgrey: ["#FFFFFF", "#D9D9D9"],
  coolgreydarkgrey: ["#8C92AC", "#A9A9A9"],
  midnightnavydarkobsidian: ["#000033", "#000022"],
  blackdarkgreygymred: ["#000000", "#A9A9A9", "#BA0002"],
  deeproyalblueblack: ["#00008B", "#000000"],
  darkgreycoolgreyvolt: ["#A9A9A9", "#8C92AC", "#CEFF00"],
  darkgreywhite: ["#A9A9A9", "#FFFFFF"],
  navynavy: ["#000080", "#000080"],
  whitewhite: ["#FFFFFF", "#FFFFFF"],
  greyheather: ["#B0B7C1"],
  blueheather: ["#696996"],
  blackanthracite: ["#000000", "#414A4C"],
  pinegreenanthracite: ["#01796F", "#414A4C"],
  gymredblack: ["#BA0002", "#000000"],
  coolgreyphotoblue: ["#8C92AC", "#5BC0DE"],
  pinegreen: ["#01796F"],
  teamredanthracite: ["#8B1730", "#414A4C"],
  sportfuchsiasportfuchsia: ["#C71585", "#C71585"],
  coolgreyanthracite: ["#8C92AC", "#414A4C"],
  blusterynavy: ["#A5FBD5", "#000080"],
  oldroyalmarine: ["#40426E", "#01386A"],
  fireberrysportfuchsia: ["#BF2B64", "#C71585"],
  navycoolgrey: ["#000080", "#8C92AC"],
  bluesapphireblack: ["#126180", "#000000"],
  varsityredanthracite: ["#B81204", "#414A4C"],
  midnightnavyphotoblue: ["#000033", "#5BC0DE"],
  blackwolfgrey: ["#000000", "#D9D9D9"],
  whitecoolgrey: ["#FFFFFF", "#8C92AC"],
  greenglow: ["#00FF00"],
  anthraciteheather: ["#414A4C"],
  navyheather: ["#000080"],
  royalheather: ["#4169E1"],
  thunderbluehypermagenta: ["#30A4DC", "#FF00FF"],
  bluenebulagymblue: ["#18A7B5", "#19647E"],
  sportgreynavy: ["#909090", "#000080"],
  blackstone: ["#000000", "#A9A9A9"],
  duckbrownstone: ["#986536", "#A9A9A9"],
  magnetstone: ["#555555", "#A9A9A9"],
  riverbluenavystone: ["#686256", "#A9A9A9"],
  ecooatmealecoblack: ["#CABD91", "#333333"],
  ecogreyecotruered: ["#6F7679", "#FF0038"],
  ecogreyecotruegreen: ["#6F7679", "#00A878"],
  ecogreycamo: ["#6F7679", "#58714D"],
  ecoivoryecotrueblack: ["#F0E6D0", "#333333"],
  ecotrueblackecoivory: ["#333333", "#F0E6D0"],
  ecoblackecotrueblack: ["#333333", "#333333"],
  ashheather: ["#B2BEB5"],
  dirtyheather: ["#A89878"],
  peacockheather: ["#73959B"],
  ecogreyecoblack: ["#6F7679", "#333333"],
  nickel: ["#72776B"],
  bluefog: ["#C1D1E5"],
  ecogreyecotrueblack: ["#6F7679", "#333333"],
  ecogreyecotruenavy: ["#6F7679", "#09192A"],
  ecotruenavyecogrey: ["#09192A", "#6F7679"],
  peacock: ["#006E6D"],
  dustyplum: ["#A38782"],
  redwood: ["#A45A52"],
  vintagenavy: ["#09192A"],
  vintagecoal: ["#333333"],
  smokevintagecoal: ["#6E6E6F", "#333333"],
  bluesky: ["#77B5FE"],
  vintagecoalblack: ["#333333", "#000000"],
  vintageroyalsmoke: ["#534666", "#6E6E6F"],
  smokewhite: ["#6E6E6F", "#FFFFFF"],
  vintagepinesmoke: ["#4F644E", "#6E6E6F"],
  vintagenavynavy: ["#09192A", "#000080"],
  vintagestone: ["#7E7A78"],
  vintageroyal: ["#534666"],
  vintageiris: ["#5A4E88"],
  southernorange: ["#FC8E09"],
  maize: ["#F2C649"],
  silvervintagenavy: ["#C0C0C0", "#09192A"],
  vintagestonevintagepine: ["#7E7A78", "#4F644E"],
  vintagecoalred: ["#333333", "#FF0000"],
  smokevintageroyal: ["#6E6E6F", "#534666"],
  vintageroyalwhite: ["#534666", "#FFFFFF"],
  vintagepinkvintagecoal: ["#D7A7A0", "#333333"],
  vintagecoalvintagepink: ["#333333", "#D7A7A0"],
  whitevintageroyal: ["#FFFFFF", "#534666"],
  heatherdeepcharcoal: ["#464646"],
  heatherrichroyal: ["#506AA1"],
  heathertwilight: ["#8E84A0"],
  heatherdeepcharcoalblack: ["#464646", "#000000"],
  heatherrosequartz: ["#F9CDCD"],
  washedblack: ["#222222"],
  elephantgrey: ["#8D8F8F"],
  darkolive: ["#556B2F"],
  vintagerose: ["#B76E79"],
  lightgrey: ["#D3D3D3"],
  applered: ["#AF2B1E"],
  richroyal: ["#0B609B"],
  softsilver: ["#C0C0C0"],
  deepcharcoal: ["#333333"],
  twilight: ["#EE82EE"],
  fireberry: ["#BF2B64"],
  ecogreyecoblackecoivory: ["#6F7679", "#333333", "#F0E6D0"],
  ecotruecurrant: ["#9B2E46"],
  ecogreycamorib: ["#6F7679", "#58714D"],
  ecotrueblack: ["#333333"],
  brightwhite: ["#FFFFFF"],
  nightskynavy: ["#2C3A63"],
  vinored: ["#915F6D"],
  deepseagreen: ["#095959"],
  suncatchergold: ["#FFCC33"],
  terraingrey: ["#5A5346"],
  fairlywhite: ["#FAF3E0"],
  ilikeyoublue: ["#8AA3FF"],
  herbgreen: ["#579A71"],
  huckleberry: ["#973562"],
  orangeyoufancy: ["#FF8A35"],
  oliveyougreen: ["#708238"],
  earthybrown: ["#835C3B"],
  desertsun: ["#EDC9AF"],
  rebelblue: ["#4075A5"],
  aluminumgrey: ["#A9ACB6"],
  envirogreen: ["#7BB274"],
  riseupred: ["#B6312D"],
  azureblue: ["#007FFF"],
  spaceblack: ["#050505"],
  revolutionred: ["#B3101A"],
  beaconblue: ["#428BCA"],
  whitesand: ["#F5E7A2"],
  reusedroyalheather: ["#405E9B"],
  salvagednavyheather: ["#1D2951"],
  reloadedcharcoalheather: ["#36454F"],
  reclaimedredheather: ["#980E0E"],
  remadegreyheather: ["#B2BEB5"],
  lichengreen: ["#849A40"],
  saltwaterblue: ["#B3C1BF"],
  driftwoodgrey: ["#735853"],
  goldenwheat: ["#F8DE7E"],
  granitegreyheather: ["#676767"],
  heatherblueheathergrey: ["#829EAD", "#D3D3D3"],
  heathergreyheatherred: ["#D3D3D3", "#FF0000"],
  gridiron: ["#72777F"],
  brightlime: ["#BFFF00"],
  chrome: ["#DCDCDC"],
  hyacinth: ["#9368B7"],
  tropicalpink: ["#F2A5BF"],
  turquoise: ["#30D5C8"],
  brightorange: ["#FF7F00"],
  sangria: ["#92000A"],
  navycharcoal: ["#000080", "#36454F"],
  naturalblack: ["#E1DDCB", "#000000"],
  naturalnavy: ["#E1DDCB", "#000080"],
  naturalred: ["#E1DDCB", "#FF0000"],
  naturalspruce: ["#E1DDCB", "#A9A9A9"],
  navyblack: ["#000080", "#000000"],
  royalblack: ["#4169E1", "#000000"],
  blackhunter: ["#000000", "#355E3B"],
  blacktropicalpink: ["#000000", "#F2A5BF"],
  newportblue: ["#3F729B"],
  clubgreen: ["#00C16E"],
  navyblazervintageport: ["#323232", "#722F37"],
  softmint: ["#89F7B8"],
  softpink: ["#FFDDDD"],
  windsorgrey: ["#5D6D7E"],
  charterbluecheck: ["#314678"],
  darkpinemulticheck: ["#355E3B"],
  navyblazerwhitegridcheck: ["#323232", "#FFFFFF"],
  newportbluepearlpinkfinecheck: ["#3F729B", "#F2D1E2"],
  navyblazermulticheck: ["#323232"],
  whitenewportbluegridcheck: ["#FFFFFF", "#3F729B"],
  deepblackcheck: ["#000000"],
  heritageblue: ["#0E4C92"],
  offwhite: ["#FAEBD7"],
  shadowgrey: ["#8B8680"],
  nightnavy: ["#000080"],
  charterblue: ["#314678"],
  navyblazer: ["#323232"],
  pearlpink: ["#F2D1E2"],
  richred: ["#8B0000"],
  navyblazerheather: ["#323232"],
  pineheather: ["#355E3B"],
  aegeanblueheather: ["#336B87"],
  windsorgreyheather: ["#5D6D7E"],
  lightshadowgreyheather: ["#8B8682"],
  charterblueheather: ["#314678"],
  ledgerkhaki: ["#C19A6B"],
  heatherlakeblue: ["#5F9EA0"],
  heatherlieutenant: ["#66CCFF"],
  blackaqua: ["#000000", "#00FFFF"],
  heatherblack: ["#333333"],
  heathercranberry: ["#DC143C"],
  heatherforest: ["#228B22"],
  heatherkellygreen: ["#4CBB17"],
  heatherblackblack: ["#333333", "#000000"],
  heathergreyblack: ["#D3D3D3", "#000000"],
  evergreen: ["#006400"],
  maizeyellow: ["#F4C430"],
  brown: ["#8B4513"],
  rust: ["#B7410E"],
  storm: ["#03065E"],
  oxbloodblack: ["#800020", "#000000"],
  poppy: ["#FF4500"],
  vintagewhite: ["#FBF5E1"],
  autumn: ["#8B795E"],
  pebblebrown: ["#8B795E"],
  oceanblue: ["#4F42B5"],
  teampurple: ["#9370DB"],
  aqua: ["#00FFFF"],
  deepteal: ["#008080"],
  leaf: ["#6B8E23"],
  army: ["#4B5320"],
  canvasred: ["#D41E36"],
  softcream: ["#FFF5E1"],
  coral: ["#FF6F61"],
  mauve: ["#E0B0FF"],
  vintageblack: ["#000000"],
  solidwhiteblend: ["#FFFFFF"],
  heatherkelly: ["#4CBB17"],
  heatherteampurple: ["#77628C"],
  heatherraspberry: ["#872657"],
  heatheraqua: ["#7FFFD4"],
  heatherpeach: ["#FFDAB9"],
  heathermidnightnavy: ["#2C3E50"],
  heatherslate: ["#708090"],
  heatherbrown: ["#A52A2A"],
  darkgreyheather: ["#A9A9A9"],
  heatherstone: ["#AD8A56"],
  heathermint: ["#98FF98"],
  heatheryellow: ["#F0E68C"],
  heatherprismnatural: ["#D2B48C"],
  heatheryellowgold: ["#DAA520"],
  heatherdeepteal: ["#008B8B"],
  heatherstorm: ["#6E7783"],
  heatherseagreen: ["#2E8B57"],
  heathertrueroyal: ["#4169E1"],
  heatherdustyblue: ["#B0C4DE"],
  heathergrassgreen: ["#4CBB17"],
  heatherclay: ["#B66A50"],
  heatherprismpeach: ["#FFE5B4"],
  heatherprismlilac: ["#DB7093"],
  heathermauve: ["#D473D4"],
  heathercolumbiablue: ["#C4D8E2"],
  heatherprismdustyblue: ["#B0C4DE"],
  heatherorchid: ["#DA70D6"],
  heatherlapis: ["#26619C"],
  heatherprismblue: ["#1E90FF"],
  heatherdust: ["#B2BEB5"],
  heathertan: ["#D2B48C"],
  heathericeblue: ["#A1CAF1"],
  heatherolive: ["#808000"],
  heatherprismiceblue: ["#A1CAF1"],
  heatherprismmint: ["#98FF98"],
  heatherprismsunset: ["#FFDAB9"],
  deepheather: ["#778899"],
  heathersunset: ["#FF4500"],
  heatherautumn: ["#995500"],
  heathermustard: ["#FFDB4D"],
  solidblackblend: ["#000000"],
  blackheatherblack: ["#000000", "#000000"],
  greyemeraldtriblend: ["#808080", "#50C878"],
  blackmarbleblack: ["#000000", "#000000"],
  whiteheatherolive: ["#FFFFFF", "#808000"],
  deepheatherblack: ["#778899", "#000000"],
  greycharcoalblacktriblend: ["#808080", "#363636", "#000000"],
  greynavytriblend: ["#808080", "#000080"],
  greyredtriblend: ["#808080", "#FF0000"],
  whitetrueroyal: ["#FFFFFF", "#4169E1"],
  whiteheatherpeach: ["#FFFFFF", "#FFDAB9"],
  greytrueroyaltriblend: ["#808080", "#4169E1"],
  whiteflkcharcoalbitriblend: ["#FFFFFF", "#363636"],
  whitekelly: ["#FFFFFF", "#4CBB17"],
  blackdeepheather: ["#000000", "#778899"],
  whitedeepheather: ["#FFFFFF", "#778899"],
  greymaroontriblend: ["#808080", "#800000"],
  whitedenim: ["#FFFFFF", "#1560BD"],
  whiteasphalt: ["#FFFFFF", "#545454"],
  whitemaroon: ["#FFFFFF", "#800000"],
  blacktrueroyal: ["#000000", "#4169E1"],
  peachtriblend: ["#FFE5B4"],
  solidredtriblend: ["#FF0000"],
  solidwhitetriblend: ["#FFFFFF"],
  purpletriblend: ["#800080"],
  minttriblend: ["#98FF98"],
  bluetriblend: ["#0000FF"],
  mauvetriblend: ["#E0B0FF"],
  sunsettriblend: ["#FF4500"],
  browntriblend: ["#A52A2A"],
  solidnavytriblend: ["#000080"],
  orangetriblend: ["#FFA500"],
  trueroyaltriblend: ["#4169E1"],
  bricktriblend: ["#9C661F"],
  seagreentriblend: ["#2E8B57"],
  greentriblend: ["#008000"],
  charcoalblacktriblend: ["#363636", "#000000"],
  redtriblend: ["#FF0000"],
  tantriblend: ["#D2B48C"],
  emeraldtriblend: ["#50C878"],
  greytriblend: ["#808080"],
  steelbluetriblend: ["#4682B4"],
  aquatriblend: ["#00FFFF"],
  soliddarkgreytriblend: ["#A9A9A9"],
  navytriblend: ["#000080"],
  berrytriblend: ["#9932CC"],
  dustybluetriblend: ["#B0C4DE"],
  solidblacktriblend: ["#000000"],
  yellowgoldtriblend: ["#DAA520"],
  cardinaltriblend: ["#C41E3A"],
  whiteflecktriblend: ["#FFFFFF"],
  blackheathertriblend: ["#000000"],
  claytriblend: ["#B66A50"],
  lilactriblend: ["#DB7093"],
  mustardtriblend: ["#FFDB4D"],
  icebluetriblend: ["#A1CAF1"],
  denimtriblend: ["#1560BD"],
  orchidtriblend: ["#DA70D6"],
  tealtriblend: ["#008080"],
  maroontriblend: ["#800000"],
  oatmealtriblend: ["#C3B091"],
  grassgreentriblend: ["#4CBB17"],
  militarygreentriblend: ["#4B5320"],
  olivetriblend: ["#808000"],
  solidforesttriblend: ["#228B22"],
  solidmaroontriblend: ["#800000"],
  athleticgreytriblend: ["#B2BEB5"],
  stormtriblend: ["#6E7783"],
  asphaltslub: ["#545454"],
  whitemarble: ["#FFFFFF"],
  whiteslub: ["#FFFFFF"],
  maroonmarble: ["#800000"],
  forestmarble: ["#228B22"],
  stonemarble: ["#AD8A56"],
  blackmarble: ["#000000"],
  dtgblack: ["#000000"],
  dtgdarkgrey: ["#A9A9A9"],
  peach: ["#FFE5B4"],
  dtgwhite: ["#FFFFFF"],
  dustyblue: ["#B0C4DE"],
  solidathleticgrey: ["#B2BEB5"],
  heatherbluelagoon: ["#ADD8E6"],
  heathermagenta: ["#FF00FF"],
  heathermarmalade: ["#FF4500"],
  heathersage: ["#9CBA7F"],
  pinktriblend: ["#FFC0CB"],
  blackpolycotton: ["#000000"],
  lightgreymarblefleece: ["#D3D3D3"],
  bluemarble: ["#0000FF"],
  mauvemarble: ["#E0B0FF"],
  trueroyalmarble: ["#4169E1"],
  solidblackslub: ["#000000"],
  charcoalblackslub: ["#363636", "#000000"],
  denimslub: ["#1560BD"],
  peachslub: ["#FFE5B4"],
  naturalslub: ["#FAEBD7"],
  oliveslub: ["#808000"],
  mauveslub: ["#E0B0FF"],
  blackgrey: ["#000000", "#808080"],
  darkcharcoalblack: ["#363636", "#000000"],
  shockblueblack: ["#00BFFF", "#000000"],
  chiliredblack: ["#E23E57", "#000000"],
  royalgreyblack: ["#4169E1", "#808080", "#000000"],
  twilightblue: ["#4A708B"],
  redblack: ["#FF0000", "#000000"],
  twilightblueblack: ["#4A708B", "#000000"],
  snorkelblue: ["#00507F"],
  darkslate: ["#2F4F4F"],
  naturaldeepred: ["#FAEBD7", "#8B0000"],
  naturaltrueroyal: ["#FAEBD7", "#4169E1"],
  naturaldeepblack: ["#FAEBD7", "#000000"],
  naturalriverbluenavy: ["#FAEBD7", "#191970"],
  darkcharcoalroyal: ["#363636", "#4169E1"],
  nearlyblacksmokegrey: ["#191919", "#708090"],
  cambridgebluesmokegrey: ["#98FB98", "#708090"],
  darkcharcoal: ["#363636"],
  trueredblack: ["#8B0000", "#000000"],
  trueroyalblack: ["#4169E1", "#000000"],
  darkgreyblackblack: ["#A9A9A9", "#000000", "#000000"],
  darkgreyblackshockblue: ["#A9A9A9", "#000000", "#00BFFF"],
  realtreextrablack: ["#5D762A", "#000000"],
  darkcharcoalcharcoal: ["#363636", "#525252"],
  blackdarkcharcoal: ["#000000", "#363636"],
  deepnavydarkcharcoal: ["#00008B", "#363636"],
  darksmokegrey: ["#708090"],
  desertkhaki: ["#C3B091"],
  richredblack: ["#8B0000", "#000000"],
  sterlinggreyblack: ["#C0C0C0", "#000000"],
  graphiteheatherblack: ["#669999", "#000000"],
  gustygreyheather: ["#A0A0A0"],
  greysmoke: ["#708090"],
  olivegreen: ["#808000"],
  magnet: ["#696969"],
  nightskyblue: ["#8B008B"],
  fieryred: ["#FF4500"],
  aegeanblue: ["#4B0082"],
  clearblack: ["#FFFFFF", "#000000"],
  nearlyblack: ["#191919"],
  smokegreynearlyblack: ["#708090", "#191919"],
  blackdeepsmoke: ["#000000", "#3D3D3D"],
  deepsmokeroyal: ["#3D3D3D", "#4169E1"],
  darksteelblueblack: ["#46466E", "#000000"],
  chilired: ["#E23E57"],
  darksteelblue: ["#46466E"],
  blackfeltcharcoal: ["#000000", "#4D4D4D"],
  feltcharcoalfeltgrey: ["#4D4D4D", "#B0C4DE"],
  royalfeltcharcoal: ["#4169E1", "#4D4D4D"],
  tropicalpinkcharcoal: ["#FC89AC", "#363636"],
  blackcharcoal: ["#000000", "#363636"],
  turquoisenavy: ["#40E0D0", "#000080"],
  twilightbluenavy: ["#4A708B", "#000080"],
  pinkazalea: ["#D7A9E3"],
  deepaquadarkcharcoal: ["#00CCCC", "#363636"],
  naturalpinkazalea: ["#FAEBD7", "#D7A9E3"],
  naturalturquoise: ["#FAEBD7", "#40E0D0"],
  jetblack: ["#000000"],
  heathergreyriverbluenavy: ["#B0C4DE", "#191970"],
  bluemoon: ["#3C69E7"],
  pewter: ["#A9A9A9"],
  coal: ["#121212"],
  redrock: ["#B32134"],
  charcoaldarkcharcoal: ["#363636", "#363636"],
  riverbluenavydarkcharcoal: ["#191970", "#363636"],
  hyperblueblack: ["#4682B4", "#000000"],
  truenavydeepsmoke: ["#000080", "#3D3D3D"],
  brilliantbluedeepsmoke: ["#4F83C7", "#3D3D3D"],
  limedeepsmoke: ["#00FF00", "#3D3D3D"],
  deepsmoke: ["#3D3D3D"],
  poporange: ["#FF5A09"],
  hyperblue: ["#4682B4"],
  militarycamo: ["#708090"],
  woodlandbluecamo: ["#001E45"],
  limeshock: ["#CCFF00"],
  graphiteblack: ["#708090", "#000000"],
  tropicblueblack: ["#32CD9F", "#000000"],
  limeshockblack: ["#CCFF00", "#000000"],
  truenavyblack: ["#000080", "#000000"],
  sterlinggrey: ["#C0C0C0"],
  militarycamoblack: ["#708090", "#000000"],
  navytwilightblue: ["#000080", "#4A708B"],
  blacklightgreystone: ["#000000", "#D3D3D3", "#8B7355"],
  blackredstone: ["#000000", "#FF0000", "#8B7355"],
  brightlimemagnetblack: ["#32CD32", "#696969", "#000000"],
  royalmagnetblack: ["#4169E1", "#696969", "#000000"],
  blackhunterstone: ["#000000", "#3D9140", "#8B7355"],
  blackmagnetblack: ["#000000", "#696969", "#000000"],
  blackroyalstone: ["#000000", "#4169E1", "#8B7355"],
  redmagnetblack: ["#FF0000", "#696969", "#000000"],
  orangeblack: ["#FFA500", "#000000"],
  darkgreyred: ["#A9A9A9", "#FF0000"],
  darkgreytwilightblue: ["#A9A9A9", "#4A708B"],
  darkchacoalblack: ["#363636", "#000000"],
  blacksoftwhite: ["#000000", "#FAF0E6"],
  truenavysoftwhite: ["#000080", "#FAF0E6"],
  graphitesoftwhite: ["#708090", "#FAF0E6"],
  gustygrey: ["#A0A0A0"],
  coralsoftcoral: ["#FF7F50", "#F88379"],
  gustygreydeepsmoke: ["#A0A0A0", "#3D3D3D"],
  titanblue: ["#878FFF"],
  mauibluetropicblue: ["#32CCCD", "#32CD9F"],
  trueblue: ["#0000FF"],
  blacksmokeblack: ["#6E6E6E", "#000000"],
  safetyyellowblack: ["#FFD700", "#000000"],
  trueroyalgrey: ["#4169E1", "#808080"],
  huntergrey: ["#3D9140", "#808080"],
  navygrey: ["#000080", "#808080"],
  redgrey: ["#FF0000", "#808080"],
  tropicalpinkgrey: ["#FC89AC", "#808080"],
  carolinabluegrey: ["#7CB9E8", "#808080"],
  maroongrey: ["#800000", "#808080"],
  navydarkcharcoal: ["#000080", "#363636"],
  purplegrey: ["#800080", "#808080"],
  darkgreen: ["#006400"],
  midnightheather: ["#191970"],
  irongrey: ["#424242"],
  buffaloplaid: ["#DC143C"],
  marshmallow: ["#F0EDE5"],
  graphitequatrefoil: ["#708090", "#D4AF37"],
  blackwhiteplaid: ["#000000", "#FFFFFF"],
  fawn: ["#E5AA70"],
  dressbluenavy: ["#23297A"],
  darkchocolatebrown: ["#321414"],
  firesidebrown: ["#B94E48"],
  softcamel: ["#C69A6D"],
  hearthgrey: ["#BEBEBE"],
  redrhubarb: ["#983042"],
  navyeclipse: ["#343148"],
  windowpane: ["#111111"],
  blackunevenplaid: ["#000000"],
  blackwatch: ["#000000"],
  fawnespressobrown: ["#E5AA70", "#704214"],
  shadowgreydeepblack: ["#8B8878", "#000000"],
  vinegreen: ["#008B00"],
  pinkcamo: ["#FF6EB4"],
  zebraprint: ["#FFFFFF"],
  greysteeltrueblack: ["#B0C4DE", "#000000"],
  trueroyaltrueblack: ["#4169E1", "#000000"],
  trueblack: ["#000000"],
  aquamarinewhite: ["#7FFFD4", "#FFFFFF"],
  hibiscuswhite: ["#B22222", "#FFFFFF"],
  sunfloweryellowwhite: ["#FFDA03", "#FFFFFF"],
  aegeanbluewhite: ["#5493B4", "#FFFFFF"],
  deepblackwhite: ["#000000", "#FFFFFF"],
  monumentgreywhite: ["#8B8682", "#FFFFFF"],
  skygrey: ["#6D8484"],
  aviatorgrey: ["#8B8680"],
  bluevoid: ["#506C7F"],
  heathergreywhite: ["#B0C4DE", "#FFFFFF"],
  patriotblueflameredwhite: ["#1C2D68", "#D52B1E", "#FFFFFF"],
  coyotebrown: ["#81613C"],
  woodlandcamoblack: ["#556B2F", "#000000"],
  blacktan: ["#000000", "#D2B48C"],
  carolinabluewhite: ["#7CB9E8", "#FFFFFF"],
  greysteeltruenavy: ["#B0C4DE", "#000080"],
  olivedrabgreenblack: ["#6B8E23", "#000000"],
  blackgreysteel: ["#000000", "#B0C4DE"],
  darkgreenwhite: ["#006400", "#FFFFFF"],
  flameredwhite: ["#D52B1E", "#FFFFFF"],
  greysteelneonorange: ["#B0C4DE", "#FF4500"],
  greysteelneonyellow: ["#B0C4DE", "#FFFF00"],
  richnavy: ["#000066"],
  truekhakicoffee: ["#F0E68C", "#6F4E37"],
  blackgold: ["#000000", "#FFD700"],
  greysteelneonblue: ["#B0C4DE", "#4682B4"],
  gustygreyblackgreysteel: ["#7F7F7F", "#000000", "#B0C4DE"],
  heathergreyrichnavy: ["#B0C4DE", "#000066"],
  patriotblue: ["#1C2D68"],
  richnavyheathergreywhite: ["#000066", "#B0C4DE", "#FFFFFF"],
  olivedrabgreen: ["#6B8E23"],
  woodlandbrown: ["#6D5E36"],
  greysteelblack: ["#B0C4DE", "#000000"],
  orangewhite: ["#FFA500", "#FFFFFF"],
  texasorangewhite: ["#FF6C00", "#FFFFFF"],
  flameredblack: ["#D52B1E", "#000000"],
  greysteel: ["#B0C4DE"],
  greysteelneonpink: ["#B0C4DE", "#FF1493"],
  greysteelwhite: ["#B0C4DE", "#FFFFFF"],
  patriotbluewhite: ["#1C2D68", "#FFFFFF"],
  richnavywhite: ["#000066", "#FFFFFF"],
  flamered: ["#D52B1E"],
  greysteelpurple: ["#B0C4DE", "#800080"],
  heathergreypatriotblue: ["#B0C4DE", "#1C2D68"],
  smokegreywhite: ["#708090", "#FFFFFF"],
  trueroyalwhite: ["#4169E1", "#FFFFFF"],
  truenavywhite: ["#000080", "#FFFFFF"],
  trueblueheathersilvermist: ["#007FFF", "#C1C1C1"],
  carolinablueecru: ["#7CB9E8", "#C2B280"],
  heathergreygreysteel: ["#B0C4DE", "#B0C4DE"],
  softcoralecru: ["#FF6F61", "#C2B280"],
  brightseafoamecru: ["#32C6A6", "#C2B280"],
  dressbluenavyheathersilvermist: ["#23297A", "#C1C1C1"],
  patriotbluepatriotbluewhite: ["#1C2D68", "#1C2D68", "#FFFFFF"],
  blackgreysteelwhite: ["#000000", "#B0C4DE", "#FFFFFF"],
  richnavyrichnavywhite: ["#000066", "#000066", "#FFFFFF"],
  richnavyflameredwhite: ["#000066", "#D52B1E", "#FFFFFF"],
  blackdigiblack: ["#000000", "#000000"],
  richnavydigigreysteel: ["#000066", "#B0C4DE"],
  flamereddigigreysteel: ["#D52B1E", "#B0C4DE"],
  patriotbluedigigreysteel: ["#1C2D68", "#B0C4DE"],
  olivedrabgreendigicoyotebrown: ["#6B8E23", "#81613C"],
  olivedrabgreentan: ["#6B8E23", "#D2B48C"],
  darkgreengold: ["#006400", "#FFD700"],
  blacktruered: ["#000000", "#8B0000"],
  flameredtan: ["#D52B1E", "#D2B48C"],
  texasorangetan: ["#FF6C00", "#D2B48C"],
  truenavytan: ["#000080", "#D2B48C"],
  carolinabluetan: ["#7CB9E8", "#D2B48C"],
  gustygreywhite: ["#7F7F7F", "#FFFFFF"],
  lightroyal: ["#4169E1"],
  pearlgrey: ["#D6D6D6"],
  heathertruenavy: ["#000080"],
  blackdarkcharcoalheather: ["#000000", "#4A4A4A"],
  trueredgreyheather: ["#8B0000", "#B0C4DE"],
  trueroyalgreyheather: ["#4169E1", "#B0C4DE"],
  truenavygreyheather: ["#000080", "#B0C4DE"],
  blackkhaki: ["#000000", "#F0E68C"],
  khakistone: ["#F0E68C", "#8B8B83"],
  militarycamokhaki: ["#4B5320", "#F0E68C"],
  lightolivestone: ["#BDB76B", "#8B8B83"],
  riverbluenavykhaki: ["#5B92E5", "#F0E68C"],
  burgundy: ["#800020"],
  steelgrey: ["#707070"],
  orangeblazerealtreextra: ["#FF6700", "#554A3C"],
  orangeblazemossyoaknewbreakup: ["#FF6700", "#785F3B"],
  safetyyellow: ["#FFED0F"],
  mossyoakbreakupcountrychocolate: ["#5C4D31", "#7B3F00"],
  mossyoaknewbreakupblack: ["#785F3B", "#000000"],
  realtreextrakhaki: ["#554A3C", "#F0E68C"],
  loden: ["#688E23"],
  newslate: ["#778899"],
  pinkraspberry: ["#E30B5D"],
  forestgreenwhite: ["#228B22", "#FFFFFF"],
  silverblack: ["#C0C0C0", "#000000"],
  truenavytruenavy: ["#000080", "#000080"],
  graphitegraphite: ["#708090", "#708090"],
  graphitewhite: ["#708090", "#FFFFFF"],
  blackshockpink: ["#000000", "#FF6EB4"],
  blackshockblue: ["#000000", "#4682B4"],
  blackshockgreen: ["#000000", "#66FF00"],
  realtreextra: ["#554A3C"],
  mossyoaknewbreakup: ["#785F3B"],
  realtreemax5tanbass: ["#625D5D", "#D2B48C", "#A9A9A9"],
  mossyoakbreakupcountrychocolateelk: ["#5C4D31", "#7B3F00", "#C19A6B"],
  mossyoaknewbreakuptandeer: ["#785F3B", "#D2B48C", "#BA8759"],
  realtreemax5khakiduck: ["#625D5D", "#F0E68C", "#FFD700"],
  irongreyblack: ["#43464B", "#000000"],
  bluecrush: ["#6495ED"],
  oyster: ["#C0C0C0"],
  khakicharcoalblue: ["#F0E68C", "#36454F"],
  maroonkhaki: ["#800000", "#F0E68C"],
  hunterstone: ["#3F6826", "#8B8B83"],
  oliveblack: ["#808000", "#000000"],
  classicnavyredwhite: ["#23297A", "#FF0000", "#FFFFFF"],
  lightpinkwhite: ["#FFB6C1", "#FFFFFF"],
  whiteclassicnavyred: ["#FFFFFF", "#23297A", "#FF0000"],
  classicnavywhite: ["#23297A", "#FFFFFF"],
  ensignbluewhite: ["#3D59AB", "#FFFFFF"],
  navyreflective: ["#000080", "#878787"],
  blackreflective: ["#000000", "#878787"],
  navylightsand: ["#000080", "#EED5B7"],
  safetyorangeblack: ["#FFA500", "#000000"],
  whiteclassicnavy: ["#FFFFFF", "#23297A"],
  royalwhite: ["#0000CD", "#FFFFFF"],
  olivestone: ["#808000", "#8B8B83"],
  stoneclassicnavy: ["#8B8B83", "#23297A"],
  classicnavy: ["#23297A"],
  enginered: ["#CE2029"],
  brightnavy: ["#1974D2"],
  navycamo: ["#000080"],
  desertcamo: ["#CAB581"],
  wintercamo: ["#83A485"],
  beigenavy: ["#F5F5DC", "#000080"],
  blackbeige: ["#000000", "#F5F5DC"],
  kryptekhighlander: ["#94978B"],
  realtreemax5: ["#625D5D"],
  kryptektyphon: ["#252F3F"],
  realtreehardwoods: ["#556B2F"],
  realtreemax7: ["#B7B09C"],
  blackroyalwhite: ["#000000", "#0000CD", "#FFFFFF"],
  blackredwhite: ["#000000", "#FF0000", "#FFFFFF"],
  multicamblack: ["#4D4D4D"],
  multicam: ["#78645E"],
  ultramarine: ["#3F00FF"],
  kryptekhighlanderbrown: ["#94978B", "#A52A2A"],
  realtreeedgebrown: ["#5B5D50", "#A52A2A"],
  mossyoakbreakupcountrybrown: ["#5C4D31", "#A52A2A"],
  kryptekhighlanderblack: ["#94978B", "#000000"],
  mossyoakbreakupcountryblack: ["#5C4D31", "#000000"],
  kryptekinfernoblack: ["#FF4E00", "#000000"],
  kryptektyphoonblack: ["#252F3F", "#000000"],
  realtreeedgeblack: ["#5B5D50", "#000000"],
  kryptekraidblack: ["#78866B", "#000000"],
  blackwhitetrueroyal: ["#000000", "#FFFFFF", "#4169E1"],
  magnetwhiteblack: ["#696969", "#FFFFFF", "#000000"],
  flareorangewhitemagnet: ["#FF6A00", "#FFFFFF", "#696969"],
  blackwhitemagnet: ["#000000", "#FFFFFF", "#696969"],
  armygreen: ["#4B5320"],
  sagegreen: ["#9C9F84"],
  carbongrey: ["#555555"],
  radiantroyal: ["#002366"],
  brilliantblue: ["#1974D2"],
  greyheatherblack: ["#B0B7C1", "#000000"],
  lagoonblueheatherblack: ["#00779A", "#000000"],
  blackheathercharcoal: ["#000000", "#4A4D4E"],
  mineralgreen: ["#3D9970"],
  lagoonblue: ["#00779A"],
  charcoalwhite: ["#4A4D4E", "#FFFFFF"],
  coffeecream: ["#473729"],
  oliveleaf: ["#6B8E23"],
  blackathleticoxford: ["#000000", "#6B8E23"],
  blackathleticred: ["#000000", "#FF0000"],
  athleticoxfordneonblue: ["#6B8E23", "#1A4D8C"],
  navyathleticoxford: ["#000080", "#6B8E23"],
  sandcamo: ["#C2B280"],
  greencamo: ["#4E782E"],
  sandcamosand: ["#C2B280", "#C2B280"],
  greycamogrey: ["#7F8C8D", "#7F8C8D"],
  realtreextratan: ["#5B5D50", "#D2B48C"],
  realtreextrapinktan: ["#5B5D50", "#D2B48C"],
  mossyoakbreakupcountrytan: ["#5C4D31", "#D2B48C"],
  mossyoakbreakupcountryneonyellow: ["#5C4D31", "#FFFF00"],
  realtreextraneonorange: ["#5B5D50", "#FF4500"],
  mossyoaknewbreakupbrown: ["#4A5D23", "#A52A2A"],
  realtreextrabrown: ["#5B5D50", "#A52A2A"],
  blackirongrey: ["#000000", "#484848"],
  dressbluenavyirongrey: ["#23297A", "#484848"],
  radiantroyalirongrey: ["#002366", "#484848"],
  ivorynavy: ["#FFFFF0", "#000080"],
  ivoryroyal: ["#FFFFF0", "#4169E1"],
  ivoryred: ["#FFFFF0", "#FF0000"],
  silverwhite: ["#C0C0C0", "#FFFFFF"],
  armygreenblack: ["#4B5320", "#000000"],
  carboncharcoal: ["#555555"],
  trueroyalheather: ["#4169E1"],
  olivegreenheather: ["#556B2F"],
  taupe: ["#483C32"],
  tidalwave: ["#1A90AA"],
  bluemoonstone: ["#4A81BF", "#8B8B83"],
  denimbluestone: ["#1560BD", "#8B8B83"],
  taupestone: ["#483C32", "#8B8B83"],
  coalstone: ["#0E0E0E", "#8B8B83"],
  redrockstone: ["#B21807", "#8B8B83"],
  tidalwavestone: ["#1A90AA", "#8B8B83"],
  battleshipgrey: ["#848482"],
  citystreet: ["#5D5D5D"],
  palmtrees: ["#6A7C45"],
  wave: ["#5B9BD5"],
  snowcaps: ["#F1F1F1"],
  insigniablue: ["#4C516D"],
  whiteathleticgold: ["#FFFFFF", "#DAA520"],
  candypink: ["#FF69B4"],
  clovergreen: ["#4A7822"],
  athleticroyal: ["#4169E1"],
  obsidianpure: ["#414153", "#FFFFFF"],
  darkmaroon: ["#800000"],
  cargokhaki: ["#6A6337"],
  teamanthracite: ["#323332"],
  teamnavy: ["#000080"],
  teamroyal: ["#4169E1"],
  teamblack: ["#000000"],
  teamdarkgreen: ["#006400"],
  teamscarlet: ["#FF2400"],
  teamdarkmaroon: ["#800000"],
  surprise: ["#E6E200"],
  greenoasis: ["#008000"],
  ultramarineblue: ["#3A75C4"],
  beach: ["#EFD9B4"],
  driftwood: ["#A3785A"],
  athleticgold: ["#DAA520"],
  blackpebble: ["#000000", "#8D8C8C"],
  khakihunter: ["#C3B091", "#3D9140"],
  khakinavy: ["#C3B091", "#000080"],
  khakimaroon: ["#C3B091", "#800000"],
  pebbleblack: ["#8D8C8C", "#000000"],
  hunterkhaki: ["#3D9140", "#C3B091"],
  neonpinkglo: ["#FF6EC7"],
  athleticorange: ["#FFA500"],
  athleticpurple: ["#800080"],
  blackathleticroyal: ["#000000", "#4169E1"],
  neonorange: ["#FFA500"],
  athleticoxfordblack: ["#6B8E23", "#000000"],
  athleticgreen: ["#008000"],
  athleticoxford: ["#6B8E23"],
  millenniumblue: ["#656D8F"],
  blacknatural: ["#000000", "#F1EAD3"],
  navynatural: ["#000080", "#F1EAD3"],
  petrolbluenavy: ["#5D8AA8", "#000080"],
  whiteblue: ["#FFFFFF", "#0000FF"],
  bluewhite: ["#0000FF", "#FFFFFF"],
  lightbluenavy: ["#ADD8E6", "#000080"],
  greyblue: ["#7F8C8D", "#0000FF"],
  safetyyellowreflective: ["#FFFF00", "#A4ACAC"],
  safetyorangereflective: ["#FF6600", "#A4ACAC"],
  navyblue: ["#000080"],
  tacticalgreen: ["#5E8C31"],
  regattablue: ["#224CBE"],
  blacksmokegrey: ["#000000", "#6E6E6E"],
  darkgreenblack: ["#006400", "#000000"],
  charcoallightgrey: ["#36454F", "#D3D3D3"],
  darknavysmokegrey: ["#000033", "#6E6E6E"],
  tanblack: ["#D2B48C", "#000000"],
  blackshockorange: ["#000000", "#FF6600"],
  darknavylightgrey: ["#000033", "#D3D3D3"],
  bluelake: ["#4F42B5"],
  heatheredcharcoal: ["#706C61"],
  ceilblue: ["#8AAAD6"],
  jadegreen: ["#00A36C"],
  metalgrey: ["#7E7F85"],
  safetyorangesafetyyellow: ["#FF6600", "#FFFF00"],
  safetyyellowsafetyorange: ["#FFFF00", "#FF6600"],
  newnavy: ["#000080"],
  carharttbrown: ["#592804"],
  burntolive: ["#3D2B1F"],
  superior: ["#D4AF37"],
  coalheather: ["#3F4444"],
  darkbrownsandstone: ["#654321", "#C2B280"],
  britelime: ["#AFFF00"],
  briteorange: ["#FFAA00"],
  basilheather: ["#658E64"],
  steel: ["#B0C4DE"],
  greige: ["#BEBAA7"],
  darkbrown: ["#654321"],
  canyonbrown: ["#734222"],
  heathergreycoalheather: ["#D3D3D3", "#3F4444"],
  shadow: ["#8B8682"],
  darkstone: ["#2F4F4F"],
  deepstone: ["#463E3F"],
  lightbrown: ["#B5651D"],
  stonewash: ["#907FA4"],
  huntergreen: ["#3D9140"],
  desert: ["#C19A6B"],
  bluestone: ["#426A79"],
  streamblue: ["#00688B"],
  darkcobaltblueheather: ["#30404F"],
  northwoodsheather: ["#365C62"],
  port: ["#722F37"],
  lakeshore: ["#7092BE"],
  mediumgrey: ["#7F7F7F"],
  maritimeblue: ["#27293D"],
  brightturquoise: ["#08E8DE"],
  freshfatigue: ["#749639"],
  heatheredlatte: ["#A6897A"],
  classicred: ["#993300"],
  cleandenim: ["#336699"],
  darkfuchsia: ["#993366"],
  espresso: ["#4B3621"],
  heatheredsteel: ["#B0C4DE"],
  heatheredloganberry: ["#9A4EAE"],
  heatherednickel: ["#727472"],
  heatheredbrown: ["#835C3B"],
  heatheredbrightturquoise: ["#08E8DE"],
  heatheredeggplant: ["#876667"],
  heatheredred: ["#E06666"],
  rosefleck: ["#FF66CC"],
  heatheredroyal: ["#4F42B5"],
  heatheredolive: ["#6B8E23"],
  deeproyalfleck: ["#0000CC"],
  deepturquoisefleck: ["#00CED1"],
  heatheredteal: ["#388E8E"],
  raspberryfleck: ["#E30B5D"],
  redfleck: ["#CC0000"],
  heatheredpinkraspberry: ["#E30B5D"],
  heatheredwatermelon: ["#FD6C9E"],
  seaglassblue: ["#6DAFAA"],
  softpurple: ["#C8A2C8"],
  heatheredlavender: ["#E6E6FA"],
  heatheredpurple: ["#A020F0"],
  greenfrost: ["#98FB98"],
  greyfrost: ["#D3D3D3"],
  redfrost: ["#CD5C5C"],
  royalfrost: ["#4169E1"],
  maroonfrost: ["#800000"],
  deeporangeheather: ["#FF4500"],
  flintblueheather: ["#6C7A89"],
  heathereddustypeach: ["#FFDAB9"],
  blushfrost: ["#FFF0F5"],
  maritimefrost: ["#27293D"],
  heathereddustysage: ["#B3C4D8"],
  militarygreenfrost: ["#4B5320"],
  fuchsiafrost: ["#FF00FF"],
  heatheredforestgreen: ["#228B22"],
  heatheredrusset: ["#80461B"],
  ochreyellowheather: ["#CC7722"],
  heatheredgrey: ["#D3D3D3"],
  turquoisefrost: ["#40E0D0"],
  heatheredneptuneblue: ["#7C9ED9"],
  navyfrost: ["#000080"],
  purplefrost: ["#800080"],
  aquaheather: ["#00FFFF"],
  coyotebrownheather: ["#81613C"],
  blackfrost: ["#000000"],
  deepestgrey: ["#333333"],
  wisteriaheather: ["#C9A0DC"],
  blackfrostgreyfrost: ["#000000", "#D3D3D3"],
  greyfrostwhite: ["#D3D3D3", "#FFFFFF"],
  navyfrostgreyfrost: ["#000080", "#D3D3D3"],
  purplefrostgreyfrost: ["#800080", "#D3D3D3"],
  redfrostgreyfrost: ["#CD5C5C", "#D3D3D3"],
  royalfrostgreyfrost: ["#4169E1", "#D3D3D3"],
  blackblackfrost: ["#000000", "#000000"],
  militarygreenfrostgreyfrost: ["#4B5320", "#D3D3D3"],
  deeproyalwhite: ["#0000CC", "#FFFFFF"],
  maroonfrostgreyfrost: ["#800000", "#D3D3D3"],
  newnavywhite: ["#000080", "#FFFFFF"],
  heathereddustypeachwhite: ["#FFDAB9", "#FFFFFF"],
  fuchsiafrostgreyfrost: ["#FF00FF", "#D3D3D3"],
  heatheredblack: ["#000000"],
  heathereddeeproyal: ["#0000CC"],
  aquaice: ["#00FFFF"],
  hotcoral: ["#FF6E6E"],
  lightturquoise: ["#40E0D0"],
  maritimeheather: ["#27293D"],
  darkfuchsiaheather: ["#993366"],
  redheather: ["#FF0000"],
  blackgreycosmic: ["#000000", "#696969"],
  navyroyalcosmic: ["#000080", "#4169E1"],
  whitepinkcosmic: ["#FFFFFF", "#FFC0CB"],
  greyastro: ["#808080"],
  royalastro: ["#4169E1"],
  blackheatheredcharcoal: ["#000000", "#4A4A4A"],
  heatheredredheatheredcharcoal: ["#E06666", "#4A4A4A"],
  heatheredtrueroyalheatheredcharcoal: ["#4169E1", "#4A4A4A"],
  heatheredwatermelonheatheredcharcoal: ["#FD6C9E", "#4A4A4A"],
  whiteheatheredpurple: ["#FFFFFF", "#E6E6FA"],
  whiteheatheredtruenavy: ["#FFFFFF", "#000080"],
  whitetrueheatherednavy: ["#FFFFFF", "#000080"],
  heatheredcharcoalblacksilver: ["#4A4A4A", "#000000", "#C0C0C0"],
  heathereddarkfuchsiaheatheredcharcoalblack: ["#993366", "#4A4A4A", "#000000"],
  heatheredtrueroyalheatheredcharcoalblack: ["#4169E1", "#4A4A4A", "#000000"],
  whiteheatheredcharcoalsilver: ["#FFFFFF", "#4A4A4A", "#C0C0C0"],
  lapisblue: ["#26619C"],
  heatheredpinkazalea: ["#FF99CC"],
  thymegreen: ["#4F7942"],
  heatheredposeidonblue: ["#4997D0"],
  deserttanheather: ["#C19A6B"],
  heatheredeucalyptusblue: ["#44944A"],
  desertroseheather: ["#D2B48C"],
  greenheather: ["#008000"],
  naturalheather: ["#E2BF65"],
  aquamarineblack: ["#7FFFD4", "#000000"],
  greydeeproyal: ["#808080", "#0000CC"],
  heatheredsteelblack: ["#B0C4DE", "#000000"],
  militarycamodarkarmy: ["#4B5320", "#5D3935"],
  newnavyheatheredsteel: ["#000080", "#B0C4DE"],
  newredcharcoal: ["#B22222", "#333333"],
  blackheatherednickel: ["#000000", "#727272"],
  deeproyalheatherednickel: ["#0000CC", "#727272"],
  sangriaheatherednickel: ["#92000A", "#727272"],
  newred: ["#B22222"],
  marledblack: ["#000000"],
  marleddeepred: ["#8B0000"],
  marledstormblue: ["#4682B4"],
  heathereddeeproyalheatheredcharcoal: ["#0000CC", "#4A4A4A"],
  heatheredgreyheatheredcharcoal: ["#D3D3D3", "#4A4A4A"],
  frostgrey: ["#778899"],
  eggplantheather: ["#990066"],
  watermelonheatherednickel: ["#FD6C9E", "#727272"],
  neonlimeblack: ["#39FF14", "#000000"],
  purpleorchidheatherednickel: ["#BA55D3", "#727272"],
  brightorangeblack: ["#FFA500", "#000000"],
  newredheatherednickel: ["#B22222", "#727272"],
  truenavyheatherednickel: ["#000080", "#727272"],
  whitebrightorange: ["#FFFFFF", "#FFA500"],
  whitedeeproyal: ["#FFFFFF", "#0000CC"],
  whiteheatherednickel: ["#FFFFFF", "#727272"],
  whiteneonlime: ["#FFFFFF", "#39FF14"],
  heatherednickelblack: ["#727272", "#000000"],
  whitebrightpink: ["#FFFFFF", "#FF69B4"],
  truenavydeeproyal: ["#000080", "#0000CC"],
  blackwatermelon: ["#000000", "#FD6C9E"],
  blackgreyfrost: ["#000000", "#D3D3D3"],
  darkfuchsiagreyfrost: ["#8A2BE2", "#D3D3D3"],
  deeproyalblack: ["#0000CC", "#000000"],
  newredblack: ["#B22222", "#000000"],
  newredlightheathergrey: ["#B22222", "#D3D3D3"],
  purplelightheathergrey: ["#800080", "#D3D3D3"],
  truenavylightheathergrey: ["#000080", "#D3D3D3"],
  deeproyallightheathergrey: ["#0000CC", "#D3D3D3"],
  truepinklightheathergrey: ["#FFC0CB", "#D3D3D3"],
  blacklightheathergrey: ["#000000", "#D3D3D3"],
  marledevergreen: ["#556B2F"],
  whiteblackcosmic: ["#FFFFFF", "#000000"],
  maroonastro: ["#800000"],
  heatheredcharcoalwhite: ["#4A4A4A", "#FFFFFF"],
  heatherednickelwhite: ["#727272", "#FFFFFF"],
  heatheredtruenavywhite: ["#000080", "#FFFFFF"],
  heatheredtrueroyalwhite: ["#4169E1", "#FFFFFF"],
  warmgrey: ["#808069"],
  purpleorchid: ["#BA55D3"],
  heatheredcardinalwhite: ["#C41E3A", "#FFFFFF"],
  heathereddarkfuchsiawhite: ["#8A2BE2", "#FFFFFF"],
  newredheather: ["#B22222"],
  deeporange: ["#FF8C00"],
  whiteheather: ["#F5F5F5"],
  truepink: ["#FFC0CB"],
  washedcoal: ["#3C3C3C"],
  washedindigo: ["#4B0082"],
  gardenia: ["#F9F6AA"],
  lightolive: ["#BDB76B"],
  scotlandblue: ["#0070A3"],
  dashingred: ["#FF4500"],
  lemonyellow: ["#FFF44F"],
  dustypeach: ["#FFDAB9"],
  kiwigreen: ["#8EE53F"],
  heatheredkellygreen: ["#4CBB17"],
  neptuneblue: ["#6D9EEB"],
  heatheredcharcoalblack: ["#4A4A4A", "#000000"],
  heatherednavynewnavy: ["#000080", "#000080"],
  heatheredredclassicred: ["#8B0000", "#8B0000"],
  whitelightheathergrey: ["#FFFFFF", "#D3D3D3"],
  dustylavender: ["#B57EDC"],
  smokeyiris: ["#5A4FCF"],
  chocolatebrown: ["#7B3F00"],
  armywhite: ["#4B5320", "#FFFFFF"],
  redwhite: ["#FF0000", "#FFFFFF"],
  nickelblack: ["#727272", "#000000"],
  desertrose: ["#FFC0CB"],
  whitesmoke: ["#F5F5F5"],
  marigoldheather: ["#EAA221"],
  eucalyptusblue: ["#44D7A8"],
  lightyellow: ["#FFFFE0"],
  plum: ["#8E4585"],
  rosewaterpink: ["#FF66CC"],
  wisteria: ["#C9A0DC"],
  armygold: ["#4B5320", "#FFD700"],
  blackchrome: ["#000000", "#CCCCCC"],
  newnavylightblue: ["#000080", "#ADD8E6"],
  nickelnewred: ["#727272", "#B22222"],
  chromenewnavycharcoal: ["#CCCCCC", "#000080", "#333333"],
  marooncharcoalgrey: ["#800000", "#333333", "#808080"],
  sandstonecharcoalblack: ["#C2B280", "#333333", "#000000"],
  blackdipdye: ["#000000"],
  lightgreyheather: ["#D3D3D3"],
  navydipdye: ["#000080"],
  purplecharcoal: ["#800080", "#333333"],
  heatherednavywhite: ["#000080", "#FFFFFF"],
  heatheredredwhite: ["#8B0000", "#FFFFFF"],
  lightheathergreywhite: ["#D3D3D3", "#FFFFFF"],
  royalfrostwhite: ["#1C39BB", "#FFFFFF"],
  turquoisemulti: ["#40E0D0"],
  burntorangestone: ["#CC5500", "#FFCCAC"],
  chocolatebrownstone: ["#7B3F00", "#FFCCAC"],
  khakichocolatebrown: ["#F0E68C", "#7B3F00"],
  newnavystone: ["#000080", "#FFCCAC"],
  trueviolet: ["#8A2BE2"],
  nostalgiaroseheather: ["#B3446C"],
  silvermist: ["#C1C2C2"],
  rubyred: ["#E0115F"],
  deepbrownheather: ["#5C4033"],
  crystalblue: ["#5ACEE7"],
  deepmagenta: ["#8B008B"],
  coastblue: ["#1B3F8B"],
  deepseablue: ["#09577B"],
  riverblue: ["#1A5091"],
  cayenneorange: ["#E97451"],
  irongate: ["#7D4E38"],
  veryberry: ["#B3446C"],
  peakblue: ["#1B3F8B"],
  greysteelbone: ["#B0C4DE", "#E3DAC9"],
  radishblack: ["#D0215F", "#000000"],
  sapphiregreysteel: ["#0F52BA", "#B0C4DE"],
  evergreengreysteel: ["#006400", "#B0C4DE"],
  concordpurplegreysteel: ["#7E1E9C", "#B0C4DE"],
  ascentblue: ["#4A789C"],
  vividlime: ["#AEEA00"],
  pinklotus: ["#E75480"],
  darkcharcoalheather: ["#333333"],
  denaliblue: ["#4A789C"],
  metalgreygreysteelcitron: ["#7D4E38", "#B0C4DE", "#9FA91F"],
  greycloudgreysteelexpeditionblue: ["#B0C4DE", "#1B3F8B"],
  riverbluenavyheather: ["#1A5091"],
  adriaticblue: ["#5ACEE7"],
  pear: ["#D1E231"],
  blackcherry: ["#412010"],
  charcoalgreyheather: ["#464646"],
  darkadriatic: ["#02525F"],
  rhubarbred: ["#903840"],
  blue: ["#0000FF"],
  canteengreyblack: ["#696969", "#000000"],
  admiralblue: ["#2C3863"],
  ivygreen: ["#4B6F44"],
  brilliantblueheathergrey: ["#333399", "#808080"],
  greyheathergrey: ["#808080", "#808080"],
  metalgreygreysteel: ["#7D4E38", "#B0C4DE"],
  riverbluenavyriverbluenavy: ["#1A5091", "#1A5091"],
  expeditionblueblack: ["#1B3F8B", "#000000"],
  pinklotusgreysteel: ["#E75480", "#B0C4DE"],
  blacksteelgrey: ["#000000", "#7D4E38"],
  deepseabluedarkadriatic: ["#09577B", "#02525F"],
  radishgreysteel: ["#D0215F", "#B0C4DE"],
  whitegreysteel: ["#FFFFFF", "#B0C4DE"],
  cobaltblueriverbluenavy: ["#0047AB", "#1A5091"],
  boulder: ["#7A8A8B"],
  gulfteal: ["#48C1C0"],
  goldenrodyellow: ["#DAA520"],
  bluegill: ["#306C7D"],
  seagrassgreen: ["#2E8B57"],
  blackstormgrey: ["#000000", "#797F88"],
  riverbluecobaltblue: ["#1A5091", "#0047AB"],
  greysteelmetalgrey: ["#B0C4DE", "#7D4E38"],
  riverbluenavybone: ["#1A5091", "#E3DAC9"],
  metalgreyexpeditionblue: ["#7D4E38", "#1B3F8B"],
  coastbluegreysteel: ["#1B3F8B", "#B0C4DE"],
  pewtergreygreysteel: ["#A9A9A9", "#B0C4DE"],
  greysteelheatherblack: ["#B0C4DE", "#000000"],
  sapphirebluegreysteel: ["#0F52BA", "#B0C4DE"],
  oatdressbluenavy: ["#CDB085", "#212A40"],
  smokegray: ["#6E7F80"],
  gustygreysterlinggrey: ["#7C8C8D", "#C0C0C0"],
  insigniablueriverbluenavy: ["#2C3863", "#1A5091"],
  graphitedeepblack: ["#3A3A3A", "#000B1D"],
  blackbattleshipgrey: ["#000000", "#848482"],
  deepsmokebattleshipgrey: ["#6E6E6E", "#848482"],
  skydiverbluebattleshipgrey: ["#00BFFF", "#848482"],
  truenavybattleshipgrey: ["#000080", "#848482"],
  skydiverblue: ["#00BFFF"],
  charcoalheatherblack: ["#464646", "#000000"],
  navyheatherblack: ["#000080", "#000000"],
  battleshipgreyblack: ["#848482", "#000000"],
  navybattleshipgrey: ["#000080", "#848482"],
  truenavypearlgrey: ["#000080", "#D0D0D0"],
  patriotbluebattleshipgrey: ["#1D2951", "#848482"],
  battleshipgreypearlgrey: ["#848482", "#D0D0D0"],
  blackrealtreextra: ["#000000", "#667544"],
  mediumblueheather: ["#0000CD"],
  frostgreymagnet: ["#E1E2E2", "#5E5E5E"],
  regalbluedressbluenavy: ["#333399", "#212A40"],
  vinegreenmagnet: ["#21421E", "#5E5E5E"],
  blackcharcoalheather: ["#000000"],
  lightroyalheather: ["#ADD8E6"],
  pearlgreyheather: ["#D0D0D0"],
  truenavyheather: ["#000080"],
  greysmokeheathergreysmoke: ["#7D7D88", "#7D7D88"],
  navysilver: ["#000080", "#C0C0C0"],
  trueroyalsilver: ["#4169E1", "#C0C0C0"],
  darksmokegreyblack: ["#3F3F3F", "#000000"],
  deepredsilver: ["#8B0000", "#C0C0C0"],
  graphiteheathertruered: ["#3A3A3A", "#8B0000"],
  graphiteheathertrueroyal: ["#3A3A3A", "#4169E1"],
  greyheathertruenavy: ["#7D7D88", "#000080"],
  dressbluenavyheather: ["#212A40"],
  slategrey: ["#708090"],
  sterlinggreyheather: ["#C0C0C0"],
  deepblackheather: ["#000B1D"],
  deepoliveheather: ["#556B2F"],
  paleseafoam: ["#98FB98"],
  coralcraze: ["#FF7F50"],
  concrete: ["#F2F2F2"],
  saltwater: ["#2E8B57"],
  newrailroad: ["#696969"],
  canvascanvas: ["#FAF0E6", "#FAF0E6"],
  canvasnavy: ["#FAF0E6", "#000080"],
  vintagered: ["#8B0000"],
  vintageblue: ["#4682B4"],
  sprucegreen: ["#A9BA9D"],
  snorkelblueblack: ["#5F9EA0", "#000000"],
  truenavyirongrey: ["#000080", "#43464B"],
  mediterraneanblue: ["#19556D"],
  blackdeepgrey: ["#000000", "#676767"],
  blackinkgrey: ["#000000", "#50505C"],
  darkcadmiumorangeshadow: ["#E68117", "#8A795D"],
  insigniabluenavyeclipse: ["#2C3863", "#343148"],
  blackimperialblue: ["#000000", "#002395"],
  dressbluenavybattleshipgrey: ["#212A40", "#848482"],
  richredbattleshipgrey: ["#8B0000", "#848482"],
  battleshipgreychargegreen: ["#848482", "#AAFF32"],
  blackmagnetgrey: ["#000000", "#5E5E5E"],
  admiralblueblackmagnet: ["#3C4454", "#000000", "#5E5E5E"],
  blackmagnetsignalred: ["#000000", "#5E5E5E", "#A00000"],
  blackblackmagnet: ["#000000", "#000000", "#5E5E5E"],
  blackmagnet: ["#000000", "#5E5E5E"],
  chargegreenmagnet: ["#AAFF32", "#5E5E5E"],
  imperialblueblack: ["#002395", "#000000"],
  estateblue: ["#2A6478"],
  truehunter: ["#3D5A5B"],
  directblue: ["#0071BB"],
  nightskyblueblack: ["#151B8D", "#000000"],
  deepsmokecharcoal: ["#6E6E6E", "#464646"],
  magnetorange: ["#5E5E5E", "#FF4500"],
  orangecrush: ["#FF6A00"],
  slickeryellow: ["#F4FA58"],
  nightskybluedressbluenavy: ["#151B8D", "#212A40"],
  dressbluenavygreysteel: ["#212A40", "#B0C4DE"],
  roguegreygreysteel: ["#7D7D88", "#B0C4DE"],
  stratusgrey: ["#807D7D"],
  richreddeepblack: ["#8B0000", "#000B1D"],
  safetyyellowdeepblack: ["#FFED00", "#000B1D"],
  trueroyaldeepblack: ["#4169E1", "#000B1D"],
  greysteeldeepblack: ["#B0C4DE", "#000B1D"],
  steelgreytrueblack: ["#7A8B8B", "#000000"],
  trueblacktrueblack: ["#000000", "#000000"],
  trueredtrueblack: ["#8B0000", "#000000"],
  truehuntertrueblack: ["#3D5A5B", "#000000"],
  fireorange: ["#FF4500"],
  shadowgreystormgrey: ["#8A795D", "#6E6E6E"],
  stormgreyshadowgrey: ["#6E6E6E", "#8A795D"],
  sangriatruered: ["#92000A", "#8B0000"],
  estatebluecobaltblue: ["#2A6478", "#0047AB"],
  charcoallemonyellow: ["#464646", "#FFF44F"],
  mineralgreensoftorange: ["#3D9140", "#FFA07A"],
  blackenginered: ["#000000", "#FF0800"],
  darkslateblack: ["#2F4F4F", "#000000"],
  mediterraneanblueblack: ["#19556D", "#000000"],
  olivecayenne: ["#556B2F", "#E34234"],
  gardengreengreysteel: ["#8FBC8F", "#B0C4DE"],
  greysteelroguegrey: ["#B0C4DE", "#7D7D88"],
  deepblackgreysteel: ["#000B1D", "#B0C4DE"],
  blackpewter: ["#000000", "#A9A9A9"],
  brightnavypewter: ["#1E2F8A", "#A9A9A9"],
  trueblackblack: ["#000000", "#000000"],
  darknavydarknavy: ["#000080", "#000080"],
  khakitrueblack: ["#F0E68C", "#000000"],
  maroontrueblack: ["#800000", "#000000"],
  trueredtruenavy: ["#8B0000", "#000080"],
  trueroyaltruenavy: ["#4169E1", "#000080"],
  trueblackgreyheather: ["#000000", "#7D7D88"],
  truehuntertruenavy: ["#3D5A5B", "#000080"],
  trueblacktrueblackreflective: ["#000000", "#000000", "#B3B3B3"],
  truenavygreyheatherreflective: ["#000080", "#7D7D88", "#B3B3B3"],
  darknavyred: ["#000080", "#8B0000"],
  khakinutmeg: ["#F0E68C", "#800000"],
  reddarknavy: ["#8B0000", "#000080"],
  stonedarknavy: ["#8B7D78", "#000080"],
  blackgunmetal: ["#000000", "#2C3539"],
  insigniabluenavy: ["#2C3863", "#000080"],
  northerngrey: ["#CFCFC4"],
  skydiverbluegreysteel: ["#0071BB", "#B0C4DE"],
  smokegreygreysteel: ["#6E6E6E", "#B0C4DE"],
  atlanticbluechrome: ["#000080", "#D4AF37"],
  smokegreychrome: ["#6E6E6E", "#D4AF37"],
  regattabluenavy: ["#B0E0E6", "#000080"],
  brightpistachiographite: ["#00FF00", "#3A3A3A"],
  engineredblack: ["#FF0800", "#000000"],
  blackgraphite: ["#000000", "#3A3A3A"],
  navygraphite: ["#000080", "#3A3A3A"],
  safetyyellowblackreflective: ["#FFED00", "#000000", "#B3B3B3"],
  treegreenmarinegreen: ["#32CD32", "#3CB371"],
  fireredgraphite: ["#FF4500", "#3A3A3A"],
  regattablueriverbluenavy: ["#B0E0E6", "#003366"],
  sterlinggreygraphite: ["#C0C0C0", "#3A3A3A"],
  duskblue: ["#4682B4"],
  deepsmokeblack: ["#1C1C1C", "#000000"],
  blacklightoxford: ["#000000", "#D3D3D3"],
  redlightoxford: ["#8B0000", "#D3D3D3"],
  royallightoxford: ["#4169E1", "#D3D3D3"],
  brightnavylightoxford: ["#1E2F8A", "#D3D3D3"],
  hunterlightoxford: ["#3D5A5B", "#D3D3D3"],
  maroonlightoxford: ["#800000", "#D3D3D3"],
  blackelectric: ["#000000"],
  greyblackelectric: ["#7D7D88"],
  truenavyelectric: ["#000080"],
  truenavyheathertruenavy: ["#000080", "#000080"],
  trueroyalheatherblack: ["#4169E1", "#000000"],
  maroonwhite: ["#800000", "#FFFFFF"],
  truenavygold: ["#000080", "#FFD700"],
  blackgraphitegreywhite: ["#000000", "#3A3A3A", "#FFFFFF"],
  graphitegreytruenavywhite: ["#3A3A3A", "#000080", "#FFFFFF"],
  graphitegreytrueredwhite: ["#3A3A3A", "#8B0000", "#FFFFFF"],
  graphitegreytrueroyalwhite: ["#3A3A3A", "#4169E1", "#FFFFFF"],
  blackgraphitegrey: ["#000000", "#3A3A3A"],
  kellygreenwhite: ["#32CD32", "#FFFFFF"],
  blackgraphitegreygraphitegrey: ["#000000", "#3A3A3A", "#3A3A3A"],
  blacktrueroyalwhite: ["#000000", "#4169E1", "#FFFFFF"],
  blackvintageheather: ["#000000", "#828282"],
  graphitevintageheather: ["#3A3A3A", "#828282"],
  truenavyvintageheather: ["#000080", "#828282"],
  blackirongreywhite: ["#000000", "#484848", "#FFFFFF"],
  trueroyalblackwhite: ["#4169E1", "#000000", "#FFFFFF"],
  wheat: ["#F5DEB3"],
  coastalblue: ["#8AA8AF"],
  deepforestgreen: ["#228B22"],
  brightkellygreen: ["#32CD32"],
  aquamarine: ["#7FFFD4"],
  coralsplash: ["#FF7F50"],
  sunburstyellow: ["#FFD700"],
  deepblackgraphite: ["#000B1D", "#3A3A3A"],
  riverbluenavywhite: ["#003366", "#FFFFFF"],
  whitedeepblack: ["#FFFFFF", "#000B1D"],
  springgreen: ["#00FF7F"],
  blueberry: ["#4F86F7"],
  fadedolive: ["#7D8471"],
  vinegreenwhite: ["#556B2F", "#FFFFFF"],
  whitejetblack: ["#FFFFFF", "#2D2D2D"],
  charcoalsmokegrey: ["#2E2E2E", "#708090"],
  redjetblack: ["#8B0000", "#2D2D2D"],
  rivierablue: ["#556B79"],
  blueberrynavy: ["#4F86F7", "#000080"],
  steelblack: ["#B0C4DE", "#000000"],
  hibiscus: ["#B02157"],
  lightstone: ["#EAEAD1"],
  sunfloweryellow: ["#F7D71E"],
  tealgreen: ["#008080"],
  charcoalheathergrey: ["#2E2E2E"],
  bark: ["#8B4513"],
  courtgreen: ["#2C8C6B"],
  brightlavender: ["#BF94E4"],
  mauiblue: ["#6B8FD1"],
  deepberry: ["#9932CC"],
  strongblue: ["#5A8BC9"],
  celadonblue: ["#007BA7"],
  peacockblue: ["#33A1C9"],
  verdantgreen: ["#32CD32"],
  navybluelake: ["#000080", "#367588"],
  maroonblack: ["#800000", "#000000"],
  deeptealblue: ["#008B8B"],
  brightroyal: ["#4169E1"],
  cactusgreen: ["#5E992C"],
  regalpurple: ["#7D26CD"],
  splendidyellow: ["#FFD800"],
  vividblue: ["#4C97FF"],
  violetpurple: ["#81007C"],
  autumnorange: ["#FC6B04"],
  greenglen: ["#228B22"],
  brightpurple: ["#8B00FF"],
  parcelblue: ["#538DD5"],
  garnetheather: ["#8C102F"],
  greenglenheather: ["#228B22"],
  moonlightblueheather: ["#3D59AB"],
  shadowgreyheather: ["#708090"],
  brilliantblueblack: ["#4C6EF5", "#000000"],
  limesteelgrey: ["#00FF00", "#B0C4DE"],
  navycarolinablue: ["#000080", "#56A0D3"],
  steelgreywhite: ["#B0C4DE", "#FFFFFF"],
  poseidonblue: ["#438EAC"],
  regalred: ["#8B0000"],
  moonlightblue: ["#3D59AB"],
  auberginepurple: ["#663399"],
  blackmonumentgrey: ["#000000", "#85878B"],
  purpledressbluenavy: ["#8B008B", "#333366"],
  seaportbluedressbluenavy: ["#5D92A8", "#333366"],
  whiteshadowgrey: ["#FFFFFF", "#708090"],
  bluewake: ["#1E7FCB"],
  lushgreen: ["#3AB09E"],
  citron: ["#FEFCE0"],
  dressshirtblue: ["#696598"],
  darkteal: ["#008080"],
  jewelgreen: ["#2E8B57"],
  irisblue: ["#5A4FCF"],
  treetopgreen: ["#1C7A79"],
  tropicblueheather: ["#008D9A"],
  vinegreenheather: ["#1D7F67"],
  blueskies: ["#63B8FF"],
  monumentgrey: ["#85878B"],
  moonlightboxbluewhite: ["#3D59AB", "#FFFFFF"],
  riverbluenavycarolinablue: ["#4F6F8F", "#56A0D3"],
  regattabluegustygrey: ["#4876B1", "#ABACAC"],
  blackthundergrey: ["#000000", "#707070"],
  navysteelgrey: ["#000080", "#B0C4DE"],
  royalsteelgrey: ["#4169E1", "#B0C4DE"],
  steelgreyblack: ["#B0C4DE", "#000000"],
  tropicblue: ["#007BA7"],
  majesticpurple: ["#800080"],
  richredwhite: ["#8B0000", "#FFFFFF"],
  cloudblue: ["#7AA9DD"],
  sunbeamyellow: ["#FFD300"],
  oxfordheather: ["#838996"],
  regattablueirongrey: ["#4876B1", "#43464B"],
  charcoalheathermediumheathergrey: ["#2E2E2E", "#676767"],
  deepblackcharcoalheather: ["#000000", "#2E2E2E"],
  estateblueheathercharcoalheather: ["#363F7C", "#2E2E2E"],
  mediumheathergreycharcoalheather: ["#676767", "#2E2E2E"],
  ecru: ["#C2B280"],
  wildberry: ["#8B008B"],
  marinegreen: ["#2C8C6B"],
  darktealheather: ["#008080"],
  aegeanblueaegeanblueheather: ["#438EAC", "#5087B0"],
  riverbluenavyriverbluenavyheather: ["#4F6F8F", "#586F8F"],
  darkgreenheather: ["#006400"],
  deepsmokeheather: ["#696969"],
  athleticmaroon: ["#800000"],
  popraspberryheather: ["#E30B5D"],
  tealblue: ["#367588"],
  pinkazaleadeepsmoke: ["#FFC0CB", "#696969"],
  pinkblossom: ["#FFB6C1"],
  winterwhite: ["#F8F8FF"],
  amethystpurple: ["#9966CC"],
  purpleheatherblack: ["#8B008B", "#000000"],
  brightpurplebattleshipgrey: ["#8B00FF", "#707070"],
  whitebattleshipgrey: ["#FFFFFF", "#707070"],
  pinkheather: ["#FFC0CB"],
  darkfuchsiamagnet: ["#8B008B", "#2E2E2E"],
  pinkraspberryheather: ["#E30B5C"],
  aquagreenheather: ["#50C878"],
  vintageheather: ["#7B827C"],
  pinkrush: ["#FF6EB4"],
  darknavyheather: ["#000033"],
  magnetgreyblack: ["#6E6E6E", "#000000"],
  cyanbluemagnetgrey: ["#00FFFF", "#6E6E6E"],
  magnetblackveryberry: ["#6E6E6E", "#000000", "#89345D"],
  poshpurple: ["#5D3F6A"],
  battleshipgreydarkrose: ["#707070", "#B76E79"],
  veryberryblack: ["#89345D", "#000000"],
  vinegreenbattleshipgrey: ["#1D7F67", "#707070"],
  darkfuchsiabattleshipgrey: ["#8B008B", "#707070"],
  hotcoralgreysteel: ["#FF6A6A", "#B0C4DE"],
  lightcyanblue: ["#E0FFFF"],
  jetblackcharcoal: ["#000000", "#2E2E2E"],
  moroccanblue: ["#00239C"],
  dustypurple: ["#965886"],
  royalclassicnavy: ["#4169E1", "#000080"],
  lightbluelightstone: ["#ADD8E6", "#EAE4D3"],
  lightstoneclassicnavy: ["#EAE4D3", "#000080"],
  steelgreylightstone: ["#B0C4DE", "#EAE4D3"],
  whitelightstone: ["#FFFFFF", "#EAE4D3"],
  coffeebeanlightstone: ["#6B4423", "#EAE4D3"],
  purplelightstone: ["#800080", "#EAE4D3"],
  texasorangelightstone: ["#FF6319", "#EAE4D3"],
  navylightstone: ["#000080", "#EAE4D3"],
  athleticgoldlightstone: ["#DAA520", "#EAE4D3"],
  redlightstone: ["#FF0000", "#EAE4D3"],
  blacklightstone: ["#000000", "#EAE4D3"],
  darkgreennavy: ["#006400", "#000080"],
  burgundylightstone: ["#800020", "#EAE4D3"],
  classicnavylightstone: ["#000080", "#EAE4D3"],
  bluejewel: ["#19647E"],
  celery: ["#C1D5D8"],
  persianred: ["#CC3333"],
  resortblue: ["#006DB7"],
  whispergreen: ["#D0F0C0"],
  pinkbloom: ["#F9B7FF"],
  redmulberry: ["#FF0000", "#C54B8C"],
  dressbluenavywhite: ["#000033", "#FFFFFF"],
  marinabluetruenavy: ["#76A5AF", "#000080"],
  redrush: ["#FF3E96"],
  magenta: ["#FF00FF"],
  berryheather: ["#92278F"],
  raspberrypink: ["#E25098"],
  skyblue: ["#76D7EA"],
  deepblue: ["#0000B5"],
  darkcactusgreen: ["#5B6F55"],
  grapeharvest: ["#4B0082"],
  pinkorchid: ["#DA70D6"],
  redoxide: ["#6E0808"],
  softblack: ["#2E2E2E"],
  chambrayblue: ["#3E67B1"],
  moonlightbluewhite: ["#C7D3D4", "#FFFFFF"],
  wintergreenwhite: ["#32CD99", "#FFFFFF"],
  ultrablue: ["#3F00FF"],
  vintagekhaki: ["#C3B091"],
  lightindigo: ["#6F00FF"],
  bluepurple: ["#4B0082", "#800080"],
  greenaqua: ["#008000", "#00FFFF"],
  tangerinepink: ["#FFA500", "#FFC0CB"],
  green: ["#008000"],
  oxfordblue: ["#333399"],
  celadon: ["#ACE1AF"],
  deepcoral: ["#FF6F61"],
  chalkwhitecharcoal: ["#F5E9D3", "#2E2E2E"],
  whitedarkslate: ["#FFFFFF", "#2F4F4F"],
  greysteelpinkazalea: ["#B0C4DE", "#DA70D6"],
  whiteroguegrey: ["#FFFFFF", "#808080"],
  deepblackrichred: ["#000000", "#FF0000"],
  deepblacktrueroyal: ["#000000", "#4169E1"],
  millenniumbluedarknavy: ["#5B67E5", "#000080"],
  islablue: ["#98FF98"],
  pinkhorizon: ["#FF6A6A"],
  pinkazaleagreysteel: ["#DA70D6", "#B0C4DE"],
  whitegraphite: ["#FFFFFF", "#1C1C1C"],
  redpepper: ["#FF5349"],
  cardinalwhite: ["#C41E3A", "#FFFFFF"],
  deeporangewhite: ["#FF8C00", "#FFFFFF"],
  deepredwhite: ["#8B0000", "#FFFFFF"],
  goldwhite: ["#FFD700", "#FFFFFF"],
  neongreenwhite: ["#39FF14", "#FFFFFF"],
  neonpinkwhite: ["#FF6EC7", "#FFFFFF"],
  neonyellowwhite: ["#CCFF00", "#FFFFFF"],
  pinkraspberrywhite: ["#E25098", "#FFFFFF"],
  purplewhite: ["#800080", "#FFFFFF"],
  vegasgoldwhite: ["#C5B358", "#FFFFFF"],
  ivorychiffon: ["#FFFFF0"],
  violetpurpleblack: ["#7F00FF", "#800080", "#000000"],
  wine: ["#722F37"],
  brightviolet: ["#8A2BE2"],
  deepjadegreen: ["#003300"],
  deeppink: ["#FF1493"],
  darkroyal: ["#002366"],
  darkroyaltwist: ["#002366"],
  scarlet: ["#FF2400"],
  truenavytwist: ["#000080"],
  lightgraphitetwist: ["#D3D3D3"],
  rainstormgreyheather: ["#A6A6A6"],
  blacktwist: ["#000000"],
  truenavytruenavytwist: ["#000080", "#000080"],
  blackblacktwist: ["#000000", "#000000"],
  blackrainstormgreyheather: ["#000000", "#A6A6A6"],
  graphitelightgraphitetwist: ["#1C1C1C", "#D3D3D3"],
  royalroyalheather: ["#4169E1", "#AB82FF"],
  darkroyaldarkroyaltwist: ["#002366", "#002366"],
  lightgraphitetwistgraphite: ["#D3D3D3", "#1C1C1C"],
  truenavytwisttruenavy: ["#000080", "#000080"],
  blacktwistblack: ["#000000", "#000000"],
  truenavyheathertruenavyrainstormgreyheather: [
    "#778899",
    "#000080",
    "#A6A6A6",
  ],
  blackheatherblackshadowgreyheather: ["#2E2E2E", "#000000", "#778899"],
  royalheatherroyalwhite: ["#AB82FF", "#4169E1", "#FFFFFF"],
  shadowgreyheathergraphitewhite: ["#778899", "#1C1C1C", "#FFFFFF"],
  blacksolid: ["#000000"],
  darkgraphite: ["#1C1C1C"],
  whitesolid: ["#FFFFFF"],
  darkgraphiteblacksolid: ["#1C1C1C", "#000000"],
  blacksolidshadowgrey: ["#000000", "#2D2D2D"],
  shadowgreyblack: ["#2D2D2D", "#000000"],
  blacksolidgraphite: ["#000000", "#1C1C1C"],
  fanwhitesolidshadowgrey: ["#FFFFFF", "#2D2D2D"],
  shadowgreyblacksolid: ["#2D2D2D", "#000000"],
  rainstormgrey: ["#A6A6A6"],
  deepsolidorange: ["#FF8C00"],
  goldenrod: ["#DAA520"],
  darkgreentwist: ["#006400"],
  shadowgreytwist: ["#2D2D2D"],
  lilacheather: ["#C8A2C8"],
  softbeige: ["#F5F5DC"],
  dustymauveheather: ["#D0CCCC"],
  fanwhite: ["#FCFCFC"],
  paceyellow: ["#FFD700"],
  greenshift: ["#008000"],
  geargrey: ["#6C6968"],
  electricblue: ["#7DF9FF"],
  blacktopcamo: ["#2E2E2E"],
  geargreycamo: ["#6C6968"],
  pinkrogue: ["#FF69B4"],
  blacktopspacedye: ["#2E2E2E"],
  electricbluespacedye: ["#7DF9FF"],
  fiercecoralspacedye: ["#FF6FFF"],
  geargreyheather: ["#6C6968"],
  purpleimpact: ["#800080"],
  blacktopheather: ["#2E2E2E"],
  blueprint: ["#0D98BA"],
  pinkflare: ["#FF6FFF"],
  purplefuel: ["#660099"],
  electricblueheather: ["#7DF9FF"],
  pinkflareheather: ["#FF6FFF"],
  trackgreyheather: ["#8B8680"],
  blacktopblackreflective: ["#2E2E2E", "#000000", "#888888"],
  paceyellowblackreflective: ["#FFD700", "#000000", "#888888"],
  blacktopgeargrey: ["#2E2E2E", "#6C6968"],
  flushpinkblacktop: ["#FF6FFF", "#2E2E2E"],
  propelnavy: ["#000080"],
  sparblue: ["#5E7D7E"],
  gridirongreen: ["#4CBB17"],
  pinkcrush: ["#FF69B4"],
  blushred: ["#DE5D83"],
  voltageblue: ["#7AAFFF"],
  blueindigo: ["#4B0082"],
  purpleluxe: ["#9A4EAE"],
  torqueteal: ["#00FFCC"],
  berryburst: ["#800020"],
  tealthrottle: ["#008080"],
  pinkpunch: ["#FF6FFF"],
  petrolgrey: ["#5E5E5E"],
  enzymeblue: ["#6583FE"],
  opticblue: ["#3A75C4"],
  roguegreywhite: ["#A6A6A6", "#FFFFFF"],
  boltblueblacktop: ["#0000FF", "#2E2E2E"],
  blacktopdieselgrey: ["#2E2E2E", "#909090"],
  dieselgreynitroyellow: ["#909090", "#C2FF00"],
  pinkpunchdieselgrey: ["#FF6FFF", "#909090"],
  dieselgreyheather: ["#909090"],
  drivegreen: ["#32CD32"],
  petrolgreyheather: ["#5E5E5E"],
  vividteal: ["#00CC99"],
  ivorysnow: ["#FFFFF0"],
  swiftpink: ["#FF6FFF"],
  deeprose: ["#B54B73"],
  turbogrey: ["#7C7C7C"],
  fadeddenim: ["#758AA2"],
  vintageraspberry: ["#872657"],
  jadeite: ["#00A86B"],
  nostalgiarose: ["#A75D67"],
  blueiris: ["#5A4FCF"],
  dovegrey: ["#6D6C6C"],
  cerisepink: ["#DE3163"],
  glacier: ["#C5E3BF"],
  neonrainbow: ["#FF6EC7"],
  pastelrainbow: ["#FFCCCC"],
  rainbow: ["#FF2052"],
  raspberry: ["#E30B5C"],
  brightredheather: ["#FF0000"],
  vividtealheather: ["#00CC99"],
  teampurpleheather: ["#660099"],
  newpink: ["#FFC0CB"],
  teamcardinal: ["#8C055E"],
  athletickelly: ["#66C992"],
  truepurple: ["#8A2BE2"],
  flashgreen: ["#00FF00"],
  brightgold: ["#FFD700"],
  teamcardinalheather: ["#8C055E"],
  teamnavyheather: ["#000080"],
  athletickellygreenheather: ["#66C992"],
  sapphireheather: ["#0F52BA"],
  brightpink: ["#FF69B4"],
  fadedblue: ["#696969"],
  inkblue: ["#00416A"],
  powerpinkelectric: ["#FF69B4"],
  trueroyalelectric: ["#4169E1"],
  silverelectric: ["#C0C0C0"],
  blackdeepred: ["#000000", "#8B0000"],
  blackdarksmokegrey: ["#000000", "#6E6E6E"],
  darksmokegreynavy: ["#6E6E6E", "#000080"],
  hotcoraldarksmokegrey: ["#FF5733", "#6E6E6E"],
  limeshockdarksmokegrey: ["#32CD32", "#6E6E6E"],
  neonpinkblack: ["#FF6EC7", "#000000"],
  purpledarksmokegrey: ["#800080", "#6E6E6E"],
  whitedarksmokegrey: ["#FFFFFF", "#6E6E6E"],
  blackneonpink: ["#000000", "#FF6EC7"],
  blackgraphiteheatherblack: ["#000000", "#6E6E6E", "#000000"],
  blackgraphiteheathercitron: ["#000000", "#6E6E6E", "#EFFF00"],
  blackgraphiteheathertruered: ["#000000", "#6E6E6E", "#8B0000"],
  blackgraphiteheathertrueroyal: ["#000000", "#6E6E6E", "#4169E1"],
  blackgraphiteheatherwhite: ["#000000", "#6E6E6E", "#FFFFFF"],
  deepredheather: ["#8B0000"],
  darksilverheather: ["#BFC1C2"],
  graphiteheathervintageheather: ["#6E6E6E", "#F7F7F7"],
  blacktriadsolid: ["#000000"],
  softcoralheather: ["#F88379"],
  whitetriadsolid: ["#FFFFFF"],
  blacktropicbluewhite: ["#000000", "#00CDEE", "#FFFFFF"],
  maroonwhiteblack: ["#800000", "#FFFFFF", "#000000"],
  purplewhiteblack: ["#800080", "#FFFFFF", "#000000"],
  truenavywhiteblack: ["#000080", "#FFFFFF", "#000000"],
  blackwhiteblack: ["#000000", "#FFFFFF", "#000000"],
  forestgreenwhiteblack: ["#228B22", "#FFFFFF", "#000000"],
  atomicblue: ["#256EFF"],
  darkforestgreen: ["#006400"],
  dawnblue: ["#013399"],
  pondblue: ["#8CBFEF"],
  greyconcrete: ["#808988"],
  maroonsilver: ["#800000", "#C0C0C0"],
  tropicbluelimeshock: ["#00CDEE", "#32CD32"],
  blacklimeshock: ["#000000", "#32CD32"],
  trueroyalgold: ["#4169E1", "#FFD700"],
  forestgreenirongrey: ["#228B22", "#48494B"],
  maroonirongrey: ["#800000", "#48494B"],
  purpleblack: ["#800080", "#000000"],
  cardinalheather: ["#8C055E"],
  cobaltheather: ["#0047AB"],
  purpleheather: ["#800080"],
  scarletheather: ["#8C055E"],
  turfgreenheather: ["#4E9454"],
  bluewakeheather: ["#657193"],
  vintageheathercarolinablue: ["#F7F7F7", "#56A0D3"],
  vintageheathergold: ["#F7F7F7", "#FFD700"],
  vintageheatherlimeshock: ["#F7F7F7", "#32CD32"],
  vintageheathertruered: ["#F7F7F7", "#8B0000"],
  vintageheathertrueroyal: ["#F7F7F7", "#4169E1"],
  vintageheatherwhite: ["#F7F7F7", "#FFFFFF"],
  vintageheatherforestgreen: ["#F7F7F7", "#228B22"],
  vintageheatherpinkraspberry: ["#F7F7F7", "#E25098"],
  vintageheatherblack: ["#F7F7F7", "#000000"],
  vintageheatherpurple: ["#F7F7F7", "#800080"],
  vintageheathertruenavy: ["#F7F7F7", "#000080"],
  vintageheathergraphiteheather: ["#F7F7F7", "#6E6E6E"],
  vintageheathertruenavyheather: ["#F7F7F7", "#000080"],
  deeporangeheathergraphiteheather: ["#FF4500", "#6E6E6E"],
  scarletheathergraphiteheather: ["#8C055E", "#6E6E6E"],
  pinkraspberryheathergraphiteheather: ["#E25098", "#6E6E6E"],
  purpleheathergraphiteheather: ["#800080", "#6E6E6E"],
  trueroyalheathergraphiteheather: ["#4169E1", "#6E6E6E"],
  irongreywhite: ["#48494B", "#FFFFFF"],
  blackneonorange: ["#000000", "#FF4500"],
  irongreylimeshock: ["#48494B", "#32CD32"],
  irongreypinkraspberry: ["#48494B", "#E25098"],
  maroonelectric: ["#800000"],
  deepredelectric: ["#8B0000"],
  darkroyalblackelectric: ["#000080"],
  forestgreenelectric: ["#228B22"],
  limeshockelectric: ["#32CD32"],
  purpleelectric: ["#800080"],
  greyblackatomicblue: ["#808080", "#256EFF"],
  purpleelectricpurple: ["#800080", "#800080"],
  blackelectricblack: ["#000000", "#000000"],
  blackelectricneonpink: ["#000000", "#FF6EC7"],
  trueroyalelectrictrueroyal: ["#4169E1", "#4169E1"],
  greyblackelectricblack: ["#808080", "#000000"],
  deepredelectricdeepred: ["#8B0000", "#8B0000"],
  truenavyelectrictruenavy: ["#000080", "#000080"],
  pinkraspberryheatherpinkraspberry: ["#E25098", "#E25098"],
  trueredheather: ["#8B0000"],
  pondblueheather: ["#8CBFEF"],
  trueroyalheatherlightgreyheather: ["#4169E1", "#D3D3D3"],
  blacktriadsoliddarkgreyheather: ["#000000", "#A9A9A9"],
  blacktriadsolidwhitetriadsolid: ["#000000", "#FFFFFF"],
  pinkraspberryheatherdarkgreyheather: ["#E25098", "#A9A9A9"],
  truenavyheatherlightgreyheather: ["#000080", "#D3D3D3"],
  darkgreyheatherlightgreyheather: ["#A9A9A9", "#D3D3D3"],
  forestgreenheatherlightgreyheather: ["#228B22", "#D3D3D3"],
  trueredheatherlightgreyheather: ["#8B0000", "#D3D3D3"],
  truereddarkgreyheather: ["#FF0000", "#A9A9A9"],
  deeporangedarkgreyheather: ["#FF4500", "#A9A9A9"],
  truenavydarkgreyheather: ["#000080", "#A9A9A9"],
  trueroyaldarkgreyheather: ["#4169E1", "#A9A9A9"],
  blackblacktriadsolid: ["#000000", "#000000"],
  blackdarkgreyheather: ["#000000", "#A9A9A9"],
  softcoral: ["#FF6A6A"],
  brightseafoam: ["#20B2AA"],
  darkroyalheatherblack: ["#00008B", "#000000"],
  lightgreyheatherlightgrey: ["#D3D3D3", "#D3D3D3"],
  darkroyalheather: ["#00008B"],
  deepnavyheather: ["#000080"],
  graphitegreyblack: ["#2C2C2C", "#000000"],
  pinkraspberryblack: ["#E25098", "#000000"],
  deepredblackelectric: ["#8B0000", "#000000"],
  truenavyheathergrey: ["#000080", "#B0B0B0"],
  whiteheathergrey: ["#FFFFFF", "#B0B0B0"],
  pondblueheatherpondblue: ["#8CBFEF", "#8CBFEF"],
  trueroyalheathertrueroyal: ["#4169E1", "#4169E1"],
  irongreytruered: ["#48494B", "#FF0000"],
  irongreytrueroyal: ["#48494B", "#4169E1"],
  irongreyforestgreen: ["#48494B", "#228B22"],
  irongreymaroon: ["#48494B", "#800000"],
  irongreypurple: ["#48494B", "#800080"],
  irongreytruenavy: ["#48494B", "#000080"],
  whiteirongrey: ["#FFFFFF", "#48494B"],
  truenavygoldwhite: ["#000080", "#FFD700", "#FFFFFF"],
  blacktrueredwhite: ["#000000", "#FF0000", "#FFFFFF"],
  forestgreenblackwhite: ["#228B22", "#000000", "#FFFFFF"],
  varsitypurpleheather: ["#7D26CD"],
  vintageheatherdeeporange: ["#F7F7F7", "#FF4500"],
  graphiteheathertruenavy: ["#6E6E6E", "#000080"],
  bluewakewhite: ["#8CABC5", "#FFFFFF"],
  pinkraspberrygrey: ["#E25098", "#808080"],
  trueredgrey: ["#FF0000", "#808080"],
  darkdenimheather: ["#000035"],
  hyacinthheather: ["#926F5B"],
  chargegreen: ["#33CC33"],
  irispurple: ["#5A4FCF"],
  pinkrushheather: ["#E4007C"],
  blackcharcoalgrey: ["#000000", "#36454F"],
  charcoalgreyheathercharcoalgrey: ["#A9A9A9", "#36454F"],
  charcoalgreyheatherchargegreen: ["#A9A9A9", "#33CC33"],
  charcoalgreyheatherpinkrush: ["#A9A9A9", "#E4007C"],
  charcoalgreyheathertruenavy: ["#A9A9A9", "#000080"],
  charcoalgreyheatherhotcoral: ["#A9A9A9", "#FF6A6A"],
  blackcharcoalgreyheather: ["#000000", "#A9A9A9"],
  pinkrushpinkrushheather: ["#E4007C", "#E4007C"],
  truenavytruenavyheather: ["#000080", "#000080"],
  trueroyaltrueroyalheather: ["#4169E1", "#4169E1"],
  whitecharcoalgreyheather: ["#FFFFFF", "#A9A9A9"],
  pinkraspberryirongrey: ["#E25098", "#48494B"],
  blackpinkraspberry: ["#000000", "#E25098"],
  mediumheathergrey: ["#8E8E8E"],
  biscuit: ["#FFE4C4"],
  blackmarl: ["#000000"],
  navymarl: ["#000080"],
  warmgreymarl: ["#808069"],
  heritageblueroyal: ["#4B8CD4", "#4169E1"],
  navyheritageblue: ["#000080", "#4B8CD4"],
  bluehorizonwhite: ["#4997D0", "#FFFFFF"],
  oxfordbluewhite: ["#384286", "#FFFFFF"],
  snowwhiteblack: ["#FFFAFA", "#000000"],
  darkwash: ["#2F4F4F"],
  mediumwash: ["#7F9A9D"],
  truebluewhite: ["#2D68C4", "#FFFFFF"],
  mistysage: ["#98FB98"],
  rosequartz: ["#FF69B4"],
  townsendsgreen: ["#3B721D"],
  bluenote: ["#1E90FF"],
  anchorgrey: ["#2C3E50"],
  anchorgreyheather: ["#2C3E50"],
  parisianblueheather: ["#6897BB"],
  sageheather: ["#8FBC8F"],
  parisianblue: ["#273BE2"],
  terracotta: ["#E2725B"],
  airblueendonend: ["#56A0D3"],
  gustygreyendonend: ["#78866B"],
  scarletred: ["#FF2400"],
  camo: ["#4B5320"],
  cybergreen: ["#8A2BE2"],
  deepnavywhite: ["#00008B", "#FFFFFF"],
  graphitecybergreen: ["#2C2C2C", "#8A2BE2"],
  charcoalcharcoal: ["#36454F", "#36454F"],
  chocolatekhaki: ["#8B4513", "#F0E68C"],
  scarletredwhite: ["#FF2400", "#FFFFFF"],
  blackgraphitewhite: ["#000000", "#2C2C2C", "#FFFFFF"],
  deepnavygraphitewhite: ["#00008B", "#2C2C2C", "#FFFFFF"],
  graphiteredwhite: ["#2C2C2C", "#FF0000", "#FFFFFF"],
  graphiteroyalwhite: ["#2C2C2C", "#4169E1", "#FFFFFF"],
  royalgreywhite: ["#4169E1", "#808080", "#FFFFFF"],
  leaguenavy: ["#1D2951"],
  rainstormgreycamographite: ["#3D5C5C", "#2C2C2C"],
  redcamo: ["#FF0000"],
  royalcamo: ["#4169E1"],
  graphiteorange: ["#2C2C2C", "#FFA500"],
  graphiteroyal: ["#2C2C2C", "#4169E1"],
  graphitescarlet: ["#2C2C2C", "#FF2400"],
  graphiteskyblue: ["#2C2C2C", "#87CEEB"],
  graphitecarolinablue: ["#2C2C2C", "#7B68EE"],
  graphitedarkgreen: ["#2C2C2C", "#006400"],
  graphitedeepnavy: ["#2C2C2C", "#00008B"],
  graphitegold: ["#2C2C2C", "#FFD700"],
  graphiteviceblue: ["#2C2C2C", "#4997D0"],
  graphitered: ["#2C2C2C", "#FF0000"],
  greysteelgraphite: ["#808080", "#B0C4DE", "#2C2C2C"],
  whiteroyalblack: ["#FFFFFF", "#4169E1", "#000000"],
  blackscarlet: ["#000000", "#FF2400"],
  deepnavygrey: ["#00008B", "#808080"],
  royalgrey: ["#4169E1", "#808080"],
  scarletgraphite: ["#FF2400", "#2C2C2C"],
  whitegrey: ["#FFFFFF", "#808080"],
  deepnavydeepnavy: ["#00008B", "#00008B"],
  whitescarletred: ["#FFFFFF", "#FF2400"],
  greygrey: ["#808080", "#808080"],
  camoblack: ["#4B5320", "#000000"],
  kellywhite: ["#4CBB17", "#FFFFFF"],
  coralwhite: ["#FF6F61", "#FFFFFF"],
  heatherroyalwhite: ["#7B68EE", "#FFFFFF"],
  heathernavynavy: ["#6C7B8B", "#000080"],
  mintwhite: ["#98FF98", "#FFFFFF"],
  scarletwhite: ["#FF2400", "#FFFFFF"],
  blackteamorange: ["#000000", "#FE8C00"],
  charcoaldeepnavy: ["#36454F", "#00008B"],
  sharkteal: ["#5CA3B7"],
  greygraphite: ["#808080", "#2C2C2C"],
  armyblackcamo: ["#4B5320", "#000000"],
  blackblackcamo: ["#000000", "#000000"],
  blackrainstormgreycamo: ["#000000", "#3D5C5C"],
  deepnavydeepnavycamo: ["#00008B", "#00008B"],
  scarletblackcamo: ["#FF2400", "#000000"],
  blackshadowheathergraphite: ["#000000", "#8B8C8D", "#2C2C2C"],
  shadowheatherblack: ["#8B8C8D", "#000000"],
  shadowheatherscarlet: ["#8B8C8D", "#FF2400"],
  blackshadowheatherdeeporange: ["#000000", "#8B8C8D", "#FF8C00"],
  navyshadowheathergrey: ["#000080", "#8B8C8D", "#808080"],
  shadowheatherroyal: ["#8B8C8D", "#4169E1"],
  greycharcoal: ["#808080", "#36454F"],
  leaguenavycharcoal: ["#1D2951", "#36454F"],
  royalcharcoal: ["#4169E1", "#36454F"],
  camocharcoal: ["#4B5320", "#36454F"],
  deepnavyshadowheather: ["#00008B", "#8B8C8D"],
  graphiteshadowheather: ["#2C2C2C", "#8B8C8D"],
  scarletredshadowheather: ["#FF2400", "#8B8C8D"],
  whiteshadowheather: ["#FFFFFF", "#8B8C8D"],
  blackshadowheather: ["#000000"],
  greyshadowheather: ["#8B8C8D"],
  navyshadowheather: ["#000080"],
  rainstormshadowheather: ["#3D5C5C"],
  royalshadowheather: ["#4169E1"],
  scarletshadowheather: ["#FF2400"],
  golddarkgreenshadowheather: ["#FFD700", "#006400"],
  goldblackshadowheather: ["#FFD700", "#000000"],
  graphiteblackshadowheather: ["#2C2C2C", "#000000"],
  greyroyalshadowheather: ["#808080", "#4169E1"],
  orangeblackshadowheather: ["#FFA500", "#000000"],
  scarletblackshadowheather: ["#FF2400", "#000000"],
  scarletnavyshadowheather: ["#FF2400", "#000080"],
  whiterainstormgrey: ["#FFFFFF", "#3D5C5C"],
  coolblue: ["#0A4D67"],
  rednavy: ["#FF0000", "#000080"],
  cybergreengraphite: ["#6AFF00", "#2C2C2C"],
  navyheathergrey: ["#000080", "#B0C4DE"],
  royalheathergrey: ["#4169E1", "#B0C4DE"],
  scarletheathergrey: ["#FF2400", "#B0C4DE"],
  deepnavygraphite: ["#00008B", "#2C2C2C"],
  royalheatherwhite: ["#4169E1", "#FFFFFF"],
  shadowgreyheatherwhite: ["#8B8C8D", "#FFFFFF"],
  truenavyheatherwhite: ["#000080", "#FFFFFF"],
  blackheathershadowgreyheather: ["#000000", "#8B8C8D"],
  blackheatherwhite: ["#000000", "#FFFFFF"],
  fanwhitesolid: ["#F5FCFF"],
  deeporangesolid: ["#FF8C00"],
  deeporangeblack: ["#FF8C00", "#000000"],
  goldblack: ["#FFD700", "#000000"],
  truenavyscarlet: ["#000080", "#FF2400"],
  blackmythiccamo: ["#000000", "#626262"],
  blacktwillheatherblack: ["#000000", "#000000"],
  greytwillheatherroyal: ["#808080", "#4169E1"],
  scarletombre: ["#FF2400"],
  royalombre: ["#4169E1"],
  staticgreyheather: ["#D1D1D1"],
  midgreyasphaltgrey: ["#696969", "#2F4F4F"],
  rainydayivorydarkheathertnfblack: ["#D3D3D3", "#000000"],
  tnfblack: ["#000000"],
  tnfdarkgreyheathercardinalred: ["#2F4F4F", "#8B0000"],
  monsterblueasphaltgrey: ["#63B8FF", "#2F4F4F"],
  tnfblackheather: ["#000000"],
  cosmicblueasphaltgrey: ["#2E37FE", "#2F4F4F"],
  midgreydarkheathermidgrey: ["#696969", "#696969"],
  rageredasphaltgrey: ["#FF2400", "#2F4F4F"],
  monsterbluetnfblack: ["#63B8FF", "#000000"],
  tnfblacktnfwhite: ["#000000", "#FFFFFF"],
  tibetanorangeasphaltgrey: ["#FF4500", "#2F4F4F"],
  monsterblueblack: ["#63B8FF", "#000000"],
  tnfmediumgreyheather: ["#A9A9A9"],
  asphaltgrey: ["#2F4F4F"],
  urbannavy: ["#38454F"],
  tnflightgreyheather: ["#D3D3D3"],
  tnfmediumgreyheathertnfblack: ["#A9A9A9", "#000000"],
  midgreyurbannavy: ["#696969", "#38454F"],
  urbannavyheather: ["#38454F"],
  tnfdarkgreyheather: ["#2F4F4F"],
  shadyblue: ["#5F9EA0"],
  asphaltgreyblack: ["#2F4F4F", "#000000"],
  tnfblackberrywine: ["#4B0082"],
  midgrey: ["#696969"],
  burntolivegreenwoodchipcamoprint: ["#4E342E"],
  firebrickred: ["#B22222"],
  tnfmatteblack: ["#000000"],
  ragered: ["#FF2400"],
  newtaupegreenheather: ["#483C32"],
  monsterblueheather: ["#63B8FF"],
  darkeggplantpurpleheather: ["#311D3F"],
  bluewing: ["#6495ED"],
  monsterblue: ["#63B8FF"],
  asphaltgreyheather: ["#2F4F4F"],
  burntolivegreen: ["#556B2F"],
  orangeochre: ["#FF4500"],
  petticoatpink: ["#FFC0CB"],
  asphaltgreyreigncamoprint: ["#2F4F4F"],
  hyperblueheather: ["#0000FF"],
  tnfwhitewoodchipprint: ["#FFFFFF"],
  highrisegreyheather: ["#A9A9A9"],
  tnfmediumgreyheatherasphalt: ["#A9A9A9", "#2F4F4F"],
  tnfmidgreyurbannavy: ["#7B68EE", "#38454F"],
  tnfblacktnfblack: ["#000000", "#000000"],
  zionorangeheatherurbannavy: ["#FF4500", "#38454F"],
  fourleafcloverheather: ["#32CD32"],
  aztecblue: ["#0D98BA"],
  tnfred: ["#FF0000"],
  tnfblue: ["#0000FF"],
  burntolivegreenasphaltgrey: ["#556B2F", "#2F4F4F"],
  urbannavytnfwhite: ["#38454F", "#FFFFFF"],
  tnfbluetnfblack: ["#0000FF", "#000000"],
  vintagewhiteasphaltgrey: ["#FAEBD7", "#2F4F4F"],
  asphaltgreytnfwhite: ["#2F4F4F", "#FFFFFF"],
  timbertan: ["#D2B48C"],
  tnfyellow: ["#FFD300"],
  midgreydarkheathertnfblack: ["#696969", "#000000"],
  tnfblackheathertnfblue: ["#000000", "#0000FF"],
  newtaupegreen: ["#483C32"],
  bluesapphirewhite: ["#0F52BA", "#FFFFFF"],
  wolfgrey: ["#858885"],
  photoblue: ["#3F729B"],
  gameroyalblack: ["#4169E1", "#000000"],
  anthracitewhite: ["#282828", "#FFFFFF"],
  coolgreywhite: ["#8C92AC", "#FFFFFF"],
  photobluewhite: ["#3F729B", "#FFFFFF"],
  gymbluewhite: ["#3F729B", "#FFFFFF"],
  gymreddarkgreyheather: ["#BA0000", "#A9A9A9"],
  darkgreyheatherdarkgreyheather: ["#A9A9A9", "#A9A9A9"],
  midnightnavymidnightnavy: ["#191970", "#191970"],
  gymbluegymblue: ["#3F729B", "#3F729B"],
  gameroyaldarkgreyheather: ["#4169E1", "#A9A9A9"],
  vividpinkheather: ["#FF69B4"],
  bluejay: ["#3F729B"],
  teamredwhite: ["#7D0000", "#FFFFFF"],
  blackgameroyal: ["#000000", "#4169E1"],
  rushblue: ["#0000FF"],
  wolfgreyheather: ["#858885"],
  gameroyalmidnightnavy: ["#4169E1", "#191970"],
  gymredteamred: ["#BA0000", "#7D0000"],
  bluetint: ["#B0E0E6"],
  urbanlilac: ["#C19A6B"],
  lucidgreen: ["#00FF00"],
  vividpink: ["#FF69B4"],
  varsitymaize: ["#F4C430"],
  gameroyalheather: ["#4169E1"],
  marina: ["#73C2FB"],
  classicorange: ["#FFA500"],
  dkchocolate: ["#654321"],
  warmgray: ["#808069"],
  antiquegold: ["#DAA520"],
  bananacream: ["#FFE135"],
  stonewashdenim: ["#8FCCE3"],
  tahitiblue: ["#61B5C9"],
  heathergray: ["#B0B7C6"],
  lightgray: ["#D3D3D3"],
  heavymetal: ["#B0C4DE"],
  desertpink: ["#FFC0CB"],
  oatmeal: ["#F5E6C4"],
  oxblood: ["#800020"],
  purplerush: ["#8B008B"],
  royalpine: ["#0B6623"],
  cancun: ["#5EFB6E"],
  vibrantyellow: ["#FFD700"],
  purpleberry: ["#8E4585"],
  antiquedenim: ["#393F4D"],
  shiraz: ["#B11226"],
  smokedpaprika: ["#B24601"],
  stonewashgreen: ["#AAD3AB"],
  solidblack: ["#000000"],
  vintageturquoise: ["#40E0D0"],
  solidwhite: ["#FFFFFF"],
  blackforest: ["#0B3D91"],
  envy: ["#3AA655"],
  solidgraphite: ["#738276"],
  legionblue: ["#184172"],
  macchiato: ["#A48D67"],
  premheather: ["#9F8181"],
  venetiangray: ["#D2B48C"],
  vintageheavymetal: ["#394240"],
  vintagepurple: ["#5D478B"],
  envyhthrwhite: ["#3AA655", "#F5F5F5"],
  premhthrhthrwhite: ["#9F8181", "#F5F5F5"],
  vtgnavypremhthr: ["#000080", "#9F8181"],
  vtgpinkhthrwhite: ["#FFC0CB", "#F5F5F5"],
  vtgredhthrwhite: ["#8B0000", "#F5F5F5"],
  premhthrvtgblack: ["#9F8181", "#000000"],
  vtgblackhthrwhite: ["#000000", "#F5F5F5"],
  vtgredpremhthr: ["#8B0000", "#9F8181"],
  vtgroyalhthrwhite: ["#4169E1", "#F5F5F5"],
  vtgroyalpremhthr: ["#4169E1", "#9F8181"],
  vtgblackpremhthr: ["#000000", "#9F8181"],
  heatherforestgreen: ["#228B22"],
  heatherheavymetal: ["#394240"],
  bondiblue: ["#0095B6"],
  heathercoolblue: ["#474AA1"],
  darkheathergray: ["#A9A9A9"],
  silk: ["#C0C0C0"],
  stonegray: ["#8D8970"],
  heathermetal: ["#B0C4DE"],
  lush: ["#3AA655"],
  premiumheather: ["#9F8181"],
  vintagepink: ["#FFC0CB"],
  heatherslateblue: ["#6A5ACD"],
  heatherbayblue: ["#61AAB6"],
  heatherteal: ["#008080"],
  blackonblack: ["#000000", "#000000"],
  shitake: ["#957846"],
  leadlightgray: ["#212121", "#D3D3D3"],
  galaxypurple: ["#8A2BE2"],
  realtreeap: ["#554545"],
  gritgreen: ["#5D8C29"],
  gritgreencamo: ["#5D8C29"],
  paceyellowspacedye: ["#FADA5E"],
  blueindigoheather: ["#4B0082"],
  reflectiveblacktop: ["#C0C0C0", "#000000"],
  metalblueheather: ["#4682B4"],
  flareorange: ["#FFA500"],
  dieselgreyblacktop: ["#959595", "#000000"],
  blacktopblacktop: ["#000000", "#000000"],
  signalredblacktop: ["#FF0000", "#000000"],
  fuelgreen: ["#6DAF45"],
  moltenmaroon: ["#B03060"],
  blacktopdieselgreysignalred: ["#000000", "#959595", "#FF0000"],
  petrolgreyblacktopshockgreen: ["#0F4C5C", "#000000", "#66FF00"],
  signalredblacktopsilvergrey: ["#FF0000", "#000000", "#C0C0C0"],
  voltageblueblacktopsilvergrey: ["#1E90FF", "#000000", "#C0C0C0"],
  rebelred: ["#FF0000"],
  blacktopherringbone: ["#000000"],
  blueprintherringbone: ["#1C3A70"],
  electricblueherringbone: ["#7DF9FF"],
  drivegreenheather: ["#32CD32"],
  forceblueheather: ["#4682B4"],
  deeprust: ["#B7410E"],
  deepblueheather: ["#0000CD"],
  blacktopchilired: ["#000000", "#FF4500"],
  blueprintblacktop: ["#1C3A70", "#000000"],
  heathergeargrey: ["#B2BEB5"],
  strikeblue: ["#1E90FF"],
  fruitpunch: ["#FF4F4F"],
  merlot: ["#7B1F1A"],
  mist: ["#B0C4DE"],
  dijon: ["#C49102"],
  nordicgreen: ["#3B7A57"],
  limeaide: ["#32CD32"],
  cherryblossom: ["#FFB7C5"],
  nectar: ["#E6A83E"],
  popcorn: ["#F0E68C"],
  wineberry: ["#591D35"],
  neoncoral: ["#FF6EC7"],
  cantaloupe: ["#FFA62F"],
  goldfish: ["#FFD700"],
  vintageplum: ["#8E4585"],
  guacamole: ["#807D04"],
  walnut: ["#79443B"],
  honeydew: ["#F0FFF0"],
  limeade: ["#32CD32"],
  blackteal: ["#000000", "#008080"],
  lemonlime: ["#E3FF00", "#32CD32"],
  blazerainbow: ["#FF4500"],
  oceanrainbow: ["#1E90FF"],
  usarainbow: ["#B22234"],
  blackgalaxyspiral: ["#000000"],
  watercolorspiral: ["#5EAE9B"],
  orangesherbet: ["#FFA561"],
  carolinablueheather: ["#56A0D3"],
  prokellygreen: ["#4CBB17"],
  trophygold: ["#DAA520"],
  trueceladon: ["#ACDAB9"],
  brightaqua: ["#0BF9EA"],
  spearmint: ["#64D1AF"],
  teamteal: ["#00827F"],
  heatherathleticmaroon: ["#800000"],
  heatherdarkchocolatebrown: ["#5C4033"],
  heathernavy: ["#000080"],
  heathersangria: ["#92000A"],
  goldroyal: ["#FFD700", "#4169E1"],
  darkheathergreyjetblack: ["#A9A9A9", "#000000"],
  navygold: ["#000080", "#FFD700"],
  heatherroyalnavy: ["#4169E1", "#000080"],
  athleticheatherjetblack: ["#B5B5B5", "#000000"],
  desertsand: ["#EDC9AF"],
  whiteathleticmaroon: ["#FFFFFF", "#800000"],
  whitepurple: ["#FFFFFF", "#800080"],
  whitedarkgreen: ["#FFFFFF", "#006400"],
  athleticheathernavy: ["#B5B5B5", "#000080"],
  coalgrey: ["#3A3B3D"],
  deepmarine: ["#042E60"],
  lightsand: ["#F5E7A2"],
  dustybrown: ["#6A4928"],
  colonialblue: ["#2D52A0"],
  dillgreen: ["#6CAA53"],
  popraspberry: ["#E30B5D"],
  papaya: ["#FFDAB9"],
  cookedcarrot: ["#FF6A4A"],
  dandelion: ["#FED85D"],
  purpledusk: ["#704214"],
  darkpurple: ["#301934"],
  bluepearl: ["#00688B"],
  deepscarlet: ["#D01A1E"],
  slateblue: ["#6A5ACD"],
  mediumblue: ["#0000CD"],
  mallardgreen: ["#006D57"],
  thistlepurple: ["#D8BFD8"],
  blackgreywhite: ["#000000", "#808080", "#FFFFFF"],
  darkgreennavywhite: ["#006400", "#000080", "#FFFFFF"],
  navyplumwhite: ["#000080", "#8E4585", "#FFFFFF"],
  dressshirtbluewhite: ["#3B5F8D", "#FFFFFF"],
  bluehorizon: ["#4971A1"],
  bluegrass: ["#4C9C24"],
  dragonfruitpink: ["#963D8E"],
  mediumbluewhite: ["#0000CD", "#FFFFFF"],
  vintagehotpinkblendedwhite: ["#FF69B4", "#F5F5F5"],
  vintagenavyblendedwhite: ["#312F4F", "#F5F5F5"],
  vintageredblendedwhite: ["#8B0000", "#F5F5F5"],
  vintageroyalblendedwhite: ["#4169E1", "#F5F5F5"],
  vintagesmokeblendedwhite: ["#5E5A5A", "#F5F5F5"],
  chill: ["#B5CED4"],
  graniteheather: ["#6D6968"],
  heather: ["#BEBEBE"],
  mauvelous: ["#EF98AA"],
  vintagecamo: ["#5D762A"],
  vintagehotpink: ["#FF69B4"],
  vintagesmoke: ["#5E5A5A"],
  caribbean: ["#00CCBC"],
  vintageheathervintagehotpink: ["#BEBEBE", "#FF69B4"],
  vintageheathervintagenavy: ["#BEBEBE", "#312F4F"],
  vintageheathervintagered: ["#BEBEBE", "#8B0000"],
  vintageheathervintageroyal: ["#BEBEBE", "#4169E1"],
  vintageheathervintagesmoke: ["#BEBEBE", "#5E5A5A"],
  blackrealtreeedge: ["#000000", "#78866B"],
  greyconcreteheatherrealtreeedge: ["#808080", "#78866B"],
  olivedrabgreenrealtreeedge: ["#6B8E23", "#78866B"],
  magnetrealtreeedge: ["#58595B", "#78866B"],
  deepblackplaid: ["#000000"],
  redplaid: ["#FF0000"],
  cargobrownrealtreeedge: ["#5C382E", "#78866B"],
  deepblackrealtreeedge: ["#000000", "#78866B"],
  darkbluestonewashed: ["#00008B"],
  pinkcandy: ["#FF69B4"],
  blackburgundy: ["#000000", "#8B0000"],
  silvergrey: ["#C0C0C0"],
  greennavy: ["#008000", "#000080"],
  bluenavy: ["#0000FF", "#000080"],
  royalblueheather: ["#4169E1"],
  lighttan: ["#DABDAB"],
  petrolblue: ["#5D8AA8"],
  midnightnavysage: ["#000080", "#9CBA7F"],
  rustbeige: ["#B7410E", "#F5F5DC"],
  charcoalgraygray: ["#36454F", "#808080"],
  alloygray: ["#B2BEB5"],
  bluestripe: ["#0000FF"],
  blackstripe: ["#000000"],
  gray: ["#808080"],
  beardarkgray: ["#3D3D3D"],
  bearblue: ["#0000FF"],
  deerblack: ["#000000"],
  deerorange: ["#FFA500"],
  nordicblack: ["#000000"],
  nordicnavy: ["#000080"],
  nordicgray: ["#808080"],
  navyspeck: ["#000080"],
  goldenspeck: ["#DAA520"],
  maroonspeck: ["#800000"],
  darkgrayspeck: ["#A9A9A9"],
  royalspeck: ["#4169E1"],
  midnightnavyred: ["#000080", "#FF0000"],
  steelgraywhite: ["#7A7B7D", "#FFFFFF"],
  gunmetalgrayblack: ["#2A3439", "#000000"],
  midnightnavywhite: ["#000080", "#FFFFFF"],
  olivegoldenrod: ["#808000", "#DAA520"],
  midnightnavygray: ["#000080", "#808080"],
  gargoylegray: ["#6A6765"],
  olivetan: ["#808000", "#D2B48C"],
  gunmetalgray: ["#2A3439"],
  blackcharcoalgray: ["#000000", "#36454F", "#808080"],
  olivine: ["#9AB973"],
  silvertruenavy: ["#C0C0C0", "#2D68C4"],
  whiteforestgreen: ["#FFFFFF", "#228B22"],
  whitetruenavy: ["#FFFFFF", "#2D68C4"],
  teamblue: ["#0073CF"],
  viridianpine: ["#40826D"],
  cavegrey: ["#8B8C89"],
  chilipepper: ["#E23D28"],
  darksmokegreydarksmokegrey: ["#6E6E6E", "#6E6E6E"],
  truenavynavy: ["#2D68C4", "#000080"],
  trueroyaltrueroyal: ["#41729F", "#41729F"],
  blacksafetyyellow: ["#000000", "#FFED00"],
  darksmokegreyforestgreen: ["#6E6E6E", "#228B22"],
  darksmokegreylimeshock: ["#6E6E6E", "#BFFF00"],
  darksmokegreypurple: ["#6E6E6E", "#800080"],
  blackdeeporange: ["#000000", "#FF8C00"],
  darksmokegreymaroon: ["#6E6E6E", "#800000"],
  darksmokegreywhite: ["#6E6E6E", "#FFFFFF"],
  navydarksmokegrey: ["#000080", "#6E6E6E"],
  graphiteheatherforestgreen: ["#7A7B7D", "#228B22"],
  graphiteheathermaroon: ["#7A7B7D", "#800000"],
  graphiteheatherpurple: ["#7A7B7D", "#800080"],
  trueredvintageheather: ["#FF0000", "#909090"],
  maroonvintageheather: ["#800000", "#909090"],
  trueroyalvintageheather: ["#41729F", "#909090"],
  forestgreenvintageheather: ["#228B22", "#909090"],
  oliveheather: ["#708154"],
  stadiumgreen: ["#3E8E3E"],
  graphitegrey: ["#7A7B7D"],
  truenavycarolinablue: ["#2D68C4", "#99BADD"],
  continental: ["#84A98C"],
  greyconcreteheather: ["#7A7B7D"],
  irongreyheather: ["#6E6E6E"],
  limeshocktruenavyelectric: ["#BFFF00", "#2D68C4"],
  deeporangeblackelectric: ["#FF8C00", "#000000"],
  carolinabluetruenavyelectric: ["#99BADD", "#2D68C4"],
  goldblackelectric: ["#DAA520", "#000000"],
  trueroyalgoldelectric: ["#41729F", "#DAA520"],
  trueroyalgoldwhite: ["#41729F", "#DAA520", "#FFFFFF"],
  trueroyaltrueredwhite: ["#41729F", "#FF0000", "#FFFFFF"],
  trueredblackwhite: ["#FF0000", "#000000", "#FFFFFF"],
  blackvegasgold: ["#000000", "#C5B358"],
  irongreyblackwhite: ["#6E6E6E", "#000000", "#FFFFFF"],
  purpleblackwhite: ["#800080", "#000000", "#FFFFFF"],
  carolinableuetruenavy: ["#99BADD", "#2D68C4"],
  charcoalgreydeeporange: ["#36454F", "#FF8C00"],
  charcoalgreychargegreen: ["#36454F", "#79C753"],
  charcoalgreytrueroyal: ["#36454F", "#41729F"],
  truenavycharcoalgrey: ["#2D68C4", "#36454F"],
  charcoalgreycharcoalgreyheather: ["#36454F", "#6E6E6E"],
  forestgreenblack: ["#228B22", "#000000"],
  trueredwhiteblack: ["#FF0000", "#FFFFFF", "#000000"],
  sportbluetruered: ["#2D68C4", "#FF0000"],
  teamnavysilver: ["#2D68C4", "#C0C0C0"],
  forestgreengold: ["#228B22", "#DAA520"],
  sportbluesilver: ["#2D68C4", "#C0C0C0"],
  teamnavyflashgreen: ["#2D68C4", "#79C753"],
  vintageheatherirongrey: ["#909090", "#6E6E6E"],
  trueroyaltruered: ["#41729F", "#FF0000"],
  heathergreytruenavy: ["#B2BEB5", "#2D68C4"],
  maroonblackwhite: ["#800000", "#000000", "#FFFFFF"],
  pinkraspberryirongreywhite: ["#E25098", "#6E6E6E", "#FFFFFF"],
  trueredtrueroyalwhite: ["#FF0000", "#41729F", "#FFFFFF"],
  truenavygraphite: ["#2D68C4", "#7A7B7D"],
  trueroyalgraphite: ["#41729F", "#7A7B7D"],
  whitepinkraspberry: ["#FFFFFF", "#E25098"],
  goldtruenavywhite: ["#DAA520", "#2D68C4", "#FFFFFF"],
  chocolatebrownkhaki: ["#D2691E", "#F0E68C"],
  blackgreyheather: ["#000000", "#B2BEB5"],
  forestgreengreyheather: ["#228B22", "#B2BEB5"],
  maroongreyheather: ["#800000", "#B2BEB5"],
  graphitegreyheather: ["#7A7B7D", "#B2BEB5"],
  trueroyalheathertrueroyalheather: ["#41729F", "#41729F"],
  vintageheathervintageheather: ["#909090", "#909090"],
  heathergreyroyal: ["#B2BEB5", "#000080"],
  heathergreynavy: ["#B2BEB5", "#000080"],
  whitegold: ["#FFFFFF", "#DAA520"],
  darkheathergreyblack: ["#A9A9A9", "#000000"],
  whitelimeshock: ["#FFFFFF", "#BFFF00"],
  whitecardinal: ["#FFFFFF", "#C41E3A"],
  whitedeeporange: ["#FFFFFF", "#FF8C00"],
  whiteforest: ["#FFFFFF", "#228B22"],
  carolinabluenavy: ["#99BADD", "#000080"],
  heathergreyred: ["#B2BEB5", "#FF0000"],
  poinsettiared: ["#C41E3A"],
  darkfuchsiablack: ["#C71585", "#000000"],
  leadgreyblack: ["#8D8D8D", "#000000"],
  mulledberry: ["#C54B8C"],
  militaryblueblack: ["#5F9EA0", "#000000"],
  graphitegreywhite: ["#7A7B7D", "#FFFFFF"],
  trueblackgrey: ["#000000", "#808080"],
  duckbrownbrown: ["#986960", "#A52A2A"],
  purplesageheather: ["#86608E"],
  vintageindigo: ["#4B0082"],
  vintageindigoblack: ["#4B0082", "#000000"],
  federalblue: ["#4E5180"],
  bluenights: ["#333399"],
  quietshadegreyheather: ["#74788B"],
  berylgreenheather: ["#3B7A57"],
  brilliantblueheather: ["#4C516D"],
  quietshadegreyblack: ["#74788B", "#000000"],
  classicblue: ["#000099"],
  scooterred: ["#BA5536"],
  quietshadegrey: ["#74788B"],
  allureheather: ["#7285A5"],
  bluenightsheather: ["#3A3B87"],
  classicblueheather: ["#3A3B87"],
  palebananaheather: ["#EADAAE"],
  bluenightsbluenightsheather: ["#333399", "#3A3B87"],
  whitequietshadegreyheather: ["#FFFFFF", "#74788B"],
  purplesage: ["#86608E"],
  vintageindigoheather: ["#4B0082"],
  whitevintageindigo: ["#FFFFFF", "#4B0082"],
  roanrouge: ["#954E2C"],
  kentuckyblueheather: ["#5D79B5"],
  rivieraheatherbluenights: ["#8C9C9E", "#333399"],
  cardinalheatherbluenights: ["#C41E3A", "#333399"],
  opalblueheather: ["#A1CBE0"],
  quietshadegreyheatherdarkgrey: ["#74788B", "#A9A9A9"],
  blackaegeanblue: ["#000000", "#547C99"],
  athleticblue: ["#162955"],
  athleticgrey: ["#B2BEB5"],
  triblack: ["#000000"],
  triindigo: ["#4B0082"],
  trioatmeal: ["#F5DEB3"],
  trieevergreen: ["#004953"],
  flagred: ["#B22222"],
  strongnavy: ["#000080"],
  strongnavyheather: ["#000080"],
  deepblacksolid: ["#000000"],
  heathergreysteel: ["#B2BEB5"],
  sanddune: ["#E7C697"],
  oat: ["#F5DEB3"],
  lemondropyellow: ["#FDFD03"],
  trueredwhite: ["#FF0000", "#FFFFFF"],
  agoldblackwhi: ["#D4AF37", "#000000", "#FFFFFF"],
  forestforestcamo: ["#228B22", "#228B22"],
  grapesmashheather: ["#0E3559"],
  goldgoldcamo: ["#DAA520", "#DAA520"],
  graphitewhitecamo: ["#708090", "#FFFFFF"],
  lemonmeringueheather: ["#F6EABE"],
  maroonmarooncamo: ["#800000", "#800000"],
  marbledgreenclay: ["#64A881"],
  marbledicefall: ["#A2D2FF"],
  navynavycamo: ["#000080", "#000080"],
  marbledpaleviolet: ["#D1A3A4"],
  acacia: ["#7CB0A1"],
  academy: ["#414A4C"],
  acadia: ["#1B1404"],
  acidgreen: ["#B0BF1A"],
  acidgreencrbn: ["#B0BF1A", "#A9A9A9"],
  lightdenim: ["#B4C8E8"],
  darkdenim: ["#082567"],
  honey: ["#FFC30B"],
  kellyheather: ["#4CBB17"],
  whitelightblue: ["#FFFFFF", "#ADD8E6"],
  fluorescentyellow: ["#CCFF00"],
  electricyellowcharcoal: ["#FFD700", "#36454F"],
  solidredblend: ["#FF0000"],
  forestgold: ["#228B22", "#DAA520"],
  forestwhite: ["#228B22", "#FFFFFF"],
  columbiabluewhite: ["#C4D8E2", "#FFFFFF"],
  airforceblue: ["#5D8AA8"],
  whitepink: ["#FFFFFF", "#FFC0CB"],
  whiteredblack: ["#FFFFFF", "#FF0000", "#000000"],
  triburgundy: ["#800020"],
  tridarkgrey: ["#A9A9A9"],
  forestforest: ["#228B22", "#228B22"],
  heritagemaroonplaid: ["#B03060"],
  rusty: ["#B7410E"],
  powerblue: ["#6CA0DC"],
  powerpink: ["#FF6EC7"],
  poweryellow: ["#FEDF08"],
  columbiabluenavywhite: ["#C4D8E2", "#000080", "#FFFFFF"],
  limeblackwhite: ["#32CD32", "#000000", "#FFFFFF"],
  navygoldwhite: ["#000080", "#DAA520", "#FFFFFF"],
  orangeblackwhite: ["#FFA500", "#000000", "#FFFFFF"],
  redblackwhite: ["#FF0000", "#000000", "#FFFFFF"],
  goldgoldmod: ["#DAA520", "#DAA520"],
  maroonmaroonmod: ["#800000", "#800000"],
  silvergreywhieblack: ["#C0C0C0", "#FFFFFF", "#000000"],
  whitesilvergreyblack: ["#FFFFFF", "#C0C0C0", "#000000"],
  alpinegreen: ["#5F9F9F"],
  althleticgrey: ["#B2BEB5"],
  whitescarlet: ["#FFFFFF", "#FF2400"],
  whitesilver: ["#FFFFFF", "#C0C0C0"],
  scarletblack: ["#FF2400", "#000000"],
  athleticheatherblack: ["#B2BEB5", "#000000"],
  navyred: ["#000080", "#FF0000"],
  royalblackwhite: ["#4169E1", "#000000", "#FFFFFF"],
  blackblackwhite: ["#000000", "#000000", "#FFFFFF"],
  blackredmetallicsilver: ["#000000", "#FF0000", "#C0C0C0"],
  blackwhitemetallicsilver: ["#000000", "#FFFFFF", "#C0C0C0"],
  navywhitemetallicsilver: ["#000080", "#FFFFFF", "#C0C0C0"],
  blackorange: ["#000000", "#FFA500"],
  emeraldgreen: ["#50C878"],
  fresholive: ["#808000"],
  scarletcarbon: ["#FF2400", "#A9A9A9"],
  fluorescentyellowcharcoal: ["#CCFF00", "#36454F"],
  fluorescentyellownavy: ["#CCFF00", "#000080"],
  arcticblue: ["#5DADEC"],
  collegiateblue: ["#0055A4"],
  watermelonpink: ["#FE7F9C"],
  heatherdenim: ["#B0E0E6"],
  royaldisc: ["#4169E1"],
  heathercantaloupe: ["#FFC49D"],
  heathercassis: ["#D08499"],
  heatherpurpledisc: ["#9C51B6"],
  heatherburgundy: ["#9A1F5A"],
  athleticbluetriblend: ["#5DADEC"],
  puristblue: ["#3CADD4"],
  burgundyheather: ["#800020"],
  graphitetriblend: ["#708090"],
  heatherdisc: ["#B0E0E6"],
  heathertriblend: ["#B0E0E6"],
  jadetriblend: ["#00A86B"],
  midnightb: ["#191970"],
  royalblueblack: ["#4169E1", "#000000"],
  fuchsia: ["#FF00FF"],
  antiquegoldheather: ["#D4A017"],
  fireballredcharcoal: ["#B22222", "#36454F"],
  cactusflowerheather: ["#D5CDB5"],
  antiqcherryred: ["#C04000"],
  antiqirishgrn: ["#009E6D"],
  antiqjadedome: ["#00A86B"],
  antiqujadedome: ["#00A86B"],
  chambrayalohatriblend: ["#3A89C9"],
  navyvegasgold: ["#000080", "#C5B358"],
  coyote: ["#81613E"],
  electricyellow: ["#FFD700"],
  heatherneomint: ["#A4D07B"],
  antiquered: ["#8B0000"],
  bluegrey: ["#6699CC"],
  antiquewhite: ["#FAEBD7"],
  antquecherryrd: ["#C04000"],
  antquegoldblk: ["#D4A017", "#000000"],
  antqueredtan: ["#8B0000", "#D2B48C"],
  antquesapphire: ["#0F52BA"],
  whiteblackwhite: ["#FFFFFF", "#000000", "#FFFFFF"],
  burntorangewhite: ["#CC5500", "#FFFFFF"],
  twistedblack: ["#000000"],
  powerbluewhite: ["#6CA0DC", "#FFFFFF"],
  oxfordmaroon: ["#6C7B8B", "#800000"],
  apple: ["#7CFC00"],
  blackwhitecarbon: ["#000000", "#FFFFFF", "#A9A9A9"],
  truenavywhitewhite: ["#000080", "#FFFFFF", "#FFFFFF"],
  graphitedisc: ["#708090"],
  orangenavy: ["#FFA500", "#000080"],
  propurple: ["#9C51B6"],
  stardust: ["#9F9F9C"],
  forestforestwhite: ["#228B22", "#228B22", "#FFFFFF"],
  graphitegraphitewhite: ["#708090", "#708090", "#FFFFFF"],
  maroonmaroonwhite: ["#800000", "#800000", "#FFFFFF"],
  navynavywhite: ["#000080", "#000080", "#FFFFFF"],
  whiteorange: ["#FFFFFF", "#FFA500"],
  idorion: ["#4F517D"],
  lightlavender: ["#E6A8D7"],
  poweryellowwhite: ["#FEDF00", "#FFFFFF"],
  retroheatherpurple: ["#94618E"],
  vegasgoldblack: ["#C5B358", "#000000"],
  bluelagoon: ["#67C8FF"],
  goldglint: ["#FFD700"],
  navyblackwhite: ["#000080", "#000000", "#FFFFFF"],
  aquagreen: ["#00FFFF"],
  caribbeanblueoxford: ["#00CCCC", "#6C7B8B"],
  indigoheatheroxford: ["#4B0082", "#6C7B8B"],
  rope: ["#A98542"],
  aquasteelhead: ["#00FFFF"],
  aquavelveththr: ["#00FFFF"],
  blacktiedye: ["#000000"],
  aquablackwhite: ["#00FFFF", "#000000", "#FFFFFF"],
  aquawhite: ["#00FFFF", "#FFFFFF"],
  khaki36i: ["#F0E68C"],
  mandarin: ["#F37A48"],
  orangefielddayplaid: ["#FFA500"],
  darksmokedarksmoke: ["#3F3F3F", "#3F3F3F"],
  cementdarksmoke: ["#7D4E57", "#3F3F3F"],
  darksmoke: ["#696969"],
  castlerock: ["#5C5858"],
  frostgreyheather: ["#E2E2E2"],
  platinum: ["#E5E4E2"],
  latte: ["#AA6746"],
  sweetheart: ["#FC6C85"],
  usa: ["#B22234", "#3C3B6E"],
  wildflower: ["#FF43A4"],
  blackcrystal: ["#000000"],
  blackcolumbia: ["#000000", "#9DD3DF"],
  blackredcrystal: ["#000000", "#FF0000"],
  denali: ["#5C6D73"],
  everglades: ["#61713A"],
  fiji: ["#60C3B6"],
  polynesia: ["#273BE2"],
  wanderlust: ["#AB83A1"],
  gulf: ["#1287A5"],
  mediterranean: ["#325CA6"],
  pacific: ["#1CA9C9"],
  frenchmerlot: ["#722F37"],
  glacierblue: ["#5F9F9F"],
  navyathleticgrey: ["#000080", "#808080"],
  cameopinkoatmeal: ["#EF95AE", "#F5DEB3"],
  charcoaloatmeal: ["#36454F", "#F5DEB3"],
  tan: ["#D2B48C"],
  navy: ["#000080"],
  afblue: ["#5D8AA8"],
  fluorescentred: ["#FF1493"],
  abfluorescentyellowgreen: ["#C5E17A", "#ADFF2F"],
  hvfluorescentyellowgreen: ["#C5E17A", "#ADFF2F"],
  redriverclay: ["#E97451"],
  tempoteal: ["#007C7A"],
  burntorangetonalblend: ["#CC5500"],
  royalgold: ["#4169E1", "#D4AF37"],
  americanred: ["#B22234"],
  concretegrey: ["#7D8491"],
  redgraphite: ["#FF0000", "#1C1C1C"],
  paradise: ["#E63E62"],
  navystripenatural: ["#000080", "#F5F5DC"],
  oxfordstripenatural: ["#6D6D6D", "#F5F5DC"],
  darkblue: ["#00008B"],
  naturallightpink: ["#F5F5DC", "#FFB6C1"],
  oxfordwhite: ["#6D6D6D", "#ffffff"],
  fieryredheatheroxford: ["#FF4900", "#6D6D6D"],
  solidtrueroyalblend: ["#4169E1"],
  gygrey: ["#808080"],
  rinsedslate: ["#708090"],
  rinsedindigoblue: ["#4B0082"],
  rinseddarknavy: ["#00008B"],
  fluorescentrainbowspiral: ["#FF1493"],
  hawaiianbreeze: ["#009D6D"],
  tahiti: ["#33A1C9"],
  bluetide: ["#5F9EA0"],
  classicrainbow: ["#6A5ACD"],
  stonewashoatmeal: ["#778899", "#F5E7B4"],
  paleyellow: ["#FFFFE0"],
  sunkissedstripe: ["#FF4500"],
  oxfordroyal: ["#6D6D6D", "#4169E1"],
  oxfordtruered: ["#6D6D6D", "#FF0000"],
  ashgray: ["#B2BEB5"],
  blackdenim: ["#0D0D0D"],
  ashheathergrey: ["#B2BEB5"],
  lightlavenderaquaprint: ["#D8BFD8", "#7FFFD4"],
  crimsonnecru: ["#DC143C", "#F5F4E9"],
  charcoalblue: ["#333333", "#0000FF"],
  asparagus: ["#7BA05B"],
  silvergreywhite: ["#C0C0C0", "#ffffff"],
  blackwhitedot: ["#000000", "#ffffff"],
  navywhitedot: ["#000080", "#ffffff"],
  whitetblackdot: ["#ffffff", "#000000"],
  scarletnavy: ["#FF2400", "#000080"],
  charcoalblacksplit: ["#333333", "#000000"],
  palepeachmaroon: ["#FFE5B4", "#800000"],
  greynavy: ["#808080", "#000080"],
  greyroyal: ["#808080", "#4169E1"],
  vapor: ["#F0FFF0"],
  heatherfuchsia: ["#D8BFD8"],
  forestheather: ["#228B22"],
  blacklightgold: ["#000000", "#DAA520"],
  navylightgold: ["#000080", "#DAA520"],
  charcoalyellow: ["#333333", "#FFFF00"],
  heathergreybirchbiscuit: ["#B2BEB5", "#DEB887", "#FFE4C4"],
  lodenblack: ["#556832", "#000000"],
  lightgreyblack: ["#D3D3D3", "#000000"],
  charcoalcyan: ["#333333", "#00FFFF"],
  blackgreen: ["#000000", "#008000"],
  blackwhitethinthickstripe: ["#000000", "#ffffff"],
  greyredblue: ["#808080", "#FF0000", "#0000FF"],
  lightblueredthinthickstripe: ["#ADD8E6", "#FF0000"],
  mintlilacthinthickstripe: ["#98FF98", "#DB7093"],
  sunsetshades: ["#FF4500"],
  whiteblackblue: ["#ffffff", "#000000", "#0000FF"],
  whiteblackgrey: ["#ffffff", "#000000", "#808080"],
  whiteblackgreythickstripe: ["#ffffff", "#000000", "#808080"],
  whiteblackorange: ["#ffffff", "#000000", "#FFA500"],
  whitebluereddoublestripe: ["#ffffff", "#0000FF", "#FF0000"],
  whitebrowngold: ["#ffffff", "#964B00", "#DAA520"],
  limevolt: ["#BFFF00"],
  chocolatepink: ["#D2691E", "#FFC0CB"],
  babybluenavy: ["#89CFF0", "#000080"],
  royalredwhite: ["#4169E1", "#FF0000", "#ffffff"],
  assorted999: ["#999999"],
  limelimedigi: ["#00FF00", "#00FF00"],
  strobe: ["#A0A0A0"],
  maroonmaroondigi: ["#800000", "#800000"],
  navynavydigi: ["#000080", "#000080"],
  athgreyheather: ["#B2BEB5"],
  athhtspdknv: ["#A0A0A0"],
  athhthrsppgrp: ["#B2BEB5"],
  athhthrspred: ["#B2BEB5"],
  athroyalombre: ["#4169E1"],
  athens: ["#EEF0F3"],
  athleticcrimson: ["#B8122F"],
  whitegreyheather: ["#ffffff", "#B2BEB5"],
  moab: ["#A58975"],
  yellowgreen: ["#ADFF2F"],
  soothingblue: ["#4A8FD2"],
  chicagobulls: ["#CE1141"],
  greyred: ["#808080", "#FF0000"],
  ecrublackbuffalo: ["#C2B280", "#000000"],
  houstonrockets: ["#CE1141"],
  indianapacers: ["#002D62"],
  spiderroyal: ["#1E1ECA"],
  heathercurrant: ["#D19275"],
  midnighttriblend: ["#191970"],
  powderblue: ["#B0E0E6"],
  deepbluecharcoal: ["#00008B", "#333333"],
  tricoral: ["#FF6F61"],
  saddle: ["#8B4513"],
  zoneblack: ["#99AAB5", "#000000"],
  coastwhite: ["#00507C", "#ffffff"],
  neonbuffalo: ["#39FF14"],
  tar: ["#4C4C4C"],
  heatherkhaki: ["#BDB76B"],
  whiteneonheatherpink: ["#ffffff", "#FF6EC7"],
  whiteorchid: ["#ffffff", "#DA70D6"],
  columbiabluetonalblend: ["#9BDDFF"],
  maroontonalblend: ["#800000"],
  navytonalblend: ["#000080"],
  darkash: ["#B2BEB5"],
  heathermocha: ["#A78F6B"],
  cleanmint: ["#98FF98"],
  orchidmetroplaid: ["#DA70D6"],
  multicamgreen: ["#4B5320"],
  frontierblue: ["#4B5A62"],
  parchment: ["#F1E9D2"],
  newrailroadgrey: ["#8D918E"],
  purpleplumraisin: ["#800080", "#8E4585", "#563C5C"],
  freshwater: ["#4F94CD"],
  blackenglishred: ["#000000", "#B6316C"],
  charcoalroyalblue: ["#333333", "#4169E1"],
  denimheather: ["#1560BD"],
  coolroyaltriblend: ["#3A75C4"],
  blackcharcoalbuffalo: ["#000000", "#333333"],
  stormcamo: ["#78866B"],
  blackleopard: ["#000000", "#FC6C85"],
  caribbeanmist: ["#00CCCC"],
  khakicheck: ["#C3B091"],
  titanium: ["#878787"],
  aerial: ["#8CBED6"],
  copper: ["#B87333"],
  hazyrainbow: ["#FF69B4"],
  catseye: ["#928C6F"],
  heat: ["#FF5600"],
  neptune: ["#78A5A3"],
  cloudyskycrystal: ["#A0ECFF"],
  coppercrystal: ["#B87333"],
  aerialspiral: ["#8CBED6"],
  vintagesmokevintagecamo: ["#909090", "#78866B"],
  whitenavyred: ["#ffffff", "#000080", "#FF0000"],
  blackcamoheather: ["#000000"],
  peppergreen: ["#8D8F4A"],
  pinkwhite: ["#FFC0CB", "#ffffff"],
  saltwatermelange: ["#A5A595"],
  caribbeanmelange: ["#00CCCC"],
  greenmelange: ["#4CBB17"],
  oceansidemelange: ["#1D7A46"],
  heavymetalheavymetal: ["#B0C4DE", "#B0C4DE"],
  midnightnavyheathergrey: ["#191970", "#D3D3D3"],
  surfblackout: ["#000000"],
  vintagemilitarygreen: ["#78866B"],
  whitereptile: ["#ffffff"],
  vintagewhiteriored: ["#FFFFF0", "#8B3F43"],
  postmanblue: ["#236192"],
  rc: ["#2C3539"],
  charcoalbluewhitestripe: ["#333333", "#0000FF", "#ffffff"],
  kbkhakiblackmicrocheck: ["#C3B091", "#000000"],
  whiteoliveoil: ["#ffffff", "#808000"],
  whiteskyblue: ["#ffffff", "#87CEEB"],
  heathercoolgrey: ["#8C92AC"],
  heathersanddune: ["#C2B280"],
  putty: ["#EED5B7"],
  moth: ["#BAAF91"],
  lightblueheather: ["#ADD8E6"],
  oliveoil: ["#808000"],
  fieldgreen: ["#4E5541"],
  carbonprintnavy: ["#555555", "#000080"],
  vintagegranite: ["#676767"],
  athleticyellow: ["#FDD017"],
  blackblackmesh: ["#000000", "#000000"],
  grapesoda: ["#6F2DA8"],
  futurelavender: ["#B57EDC"],
  blacksmokeenglishred: ["#000000", "#708090", "#B6316C"],
  smoketriblend: ["#708090"],
  manhattan: ["#E3A869"],
  illusion: ["#F57C00"],
  michelangelo: ["#FF9966"],
  glowworm: ["#BFFF00"],
  columbia: ["#C4D8E2"],
  florainbowspiral: ["#FFC3C0"],
  michelangelospiral: ["#FF9966"],
  greenstone: ["#0D98BA"],
  lavenderslatewhite: ["#E6E6FA", "#708090", "#ffffff"],
  athleticnavy: ["#000080"],
  cobaltfeather: ["#0047AB"],
  glowinthedarkstarfruit: ["#3FFF00"],
  glowinthedarkgrapefruit: ["#FF355E"],
  charcoalheathercharcoalblackmesh: ["#36454F", "#333333", "#000000"],
  blackheatherblackoxford: ["#000000", "#000000", "#6C7B8B"],
  athheatherblack: ["#B5B5B5", "#000000"],
  athltichthnvy: ["#B5B5B5", "#000080"],
  royalslatewhite: ["#4169E1", "#708090", "#ffffff"],
  royalgoldwhite: ["#4169E1", "#D4AF37", "#ffffff"],
  darkgreenathleticheather: ["#006400", "#B5B5B5"],
  jellybean: ["#DA614E"],
  darkmosswhite: ["#4A5D23", "#ffffff"],
  apricot: ["#FBCEB1"],
  breakerblue: ["#6EBDDB"],
  aluminum: ["#A9ACB6"],
  aquablue: ["#1AFFFF"],
  bluemist: ["#C1D8ED"],
  greenwhite: ["#008000", "#ffffff"],
  aurora: ["#EDF8F3"],
  indigorigid: ["#4B0082"],
  columbiabluewhitemetallicsilver: ["#9BDDFF", "#ffffff", "#C0C0C0"],
  blackwhitemetallicgold: ["#000000", "#ffffff", "#D4AF37"],
  orangewhiteblack: ["#FFA500", "#ffffff", "#000000"],
  powerorange: ["#FF6600"],
  azul: ["#1D5E89"],
  heatherbronze: ["#A97142"],
  azurebluntnv: ["#007FFF", "#1D2951"],
  kellycarbon: ["#4CBB17", "#555555"],
  fluorescentyellowblack: ["#CCFF00", "#000000"],
  borangeblawhi: ["#FFA500", "#000000", "#ffffff"],
  bwhivhotptit: ["#ffffff", "#FF69B4", "#A9A9A9"],
  blackblue: ["#000000", "#0000FF"],
  collectionblue: ["#7CB9E8"],
  babybluetan: ["#89CFF0", "#D2B48C"],
  babypink: ["#F4C2C2"],
  ballballwswhi: ["#FE6F5E", "#FE6F5E", "#ffffff"],
  navygraphitenavyprint: ["#000080", "#696969", "#000080"],
  redblackredprint: ["#FF0000", "#000000", "#FF0000"],
  whitecolumbiablue: ["#ffffff", "#9BDDFF"],
  graphitegraphiteblend: ["#708090", "#708090"],
  maroonmaroonblend: ["#800000", "#800000"],
  blackblackgraphiteprint: ["#000000", "#000000", "#708090"],
  darkgreenblackdarkgreenprint: ["#006400", "#000000", "#006400"],
  vegasgoldblackwhite: ["#C5B358", "#000000", "#ffffff"],
  oxfordred: ["#6C7B8B", "#FF0000"],
  bamboo: ["#D1B26F"],
  greenwoodland: ["#556B2F"],
  urbanwoodland: ["#555555"],
  bambooblackout: ["#D1B26F"],
  oxfordblack: ["#6C7B8B", "#000000"],
  oxfordpurple: ["#6C7B8B", "#800080"],
  grapeoxford: ["#6F2DA8", "#6C7B8B"],
  frenchvanilla: ["#F3E5AB"],
  darkgreenwhitegraphite: ["#006400", "#ffffff", "#708090"],
  maroonwhitegraphite: ["#800000", "#ffffff", "#708090"],
  maroonblackmaroonprint: ["#800000", "#000000", "#800000"],
  barbados: ["#42C6B0"],
  delicateblue: ["#76AED6"],
  peachy: ["#FFDAB9"],
  bark_32: ["#3D0C02"],
  bark_34: ["#3D0C02"],
  bark_36: ["#3D0C02"],
  bark_38: ["#3D0C02"],
  bark_40: ["#3D0C02"],
  bark_42: ["#3D0C02"],
  bark_44: ["#3D0C02"],
  barnred: ["#7C0A02"],
  baseballgrey: ["#BFB9B5"],
  reactiverainbow: ["#FF0000"],
  basil: ["#819F56"],
  navyredwhite: ["#000080", "#FF0000", "#ffffff"],
  basiltitanium: ["#819F56", "#D1DEDE"],
  brownduck: ["#6B4423"],
  crimsonfall: ["#DC143C"],
  rose: ["#FF007F"],
  canyon: ["#D9853B"],
  carbonprintscarlet: ["#555555", "#FF2400"],
  tradewind: ["#6D9192"],
  rinsedblack: ["#000000"],
  pumpkin: ["#FF7518"],
  blackcloudprint: ["#000000"],
  columbiabluecloudprint: ["#C4D8E2"],
  darkgreencloudprint: ["#006400"],
  goldcloudprint: ["#FFD700"],
  cleanmintpepperheather: ["#98FF98"],
  rinsedbrownduck: ["#6B4423"],
  bbn: ["#00008B"],
  heathergreyheathernavy: ["#D3D3D3", "#000080"],
  navyfielddayplaid: ["#000080"],
  collegiatenavymelange: ["#000080"],
  collegiateroyalmelange: ["#4169E1"],
  whiteroyalgrey: ["#ffffff", "#4169E1", "#B0C4DE"],
  beehive: ["#FFC1A1"],
  beetroot: ["#3E2723"],
  beetrootblack: ["#3E2723", "#000000"],
  beige: ["#F5F5DC"],
  highlighteryellow: ["#CCFF00"],
  bermudablackout: ["#7FDBFF"],
  hydra: ["#4997D0"],
  blackstormcamo: ["#000000", "#6E6E6E"],
  carolinabluevintageheather: ["#56A0D3", "#D3D3D3"],
  limegreen: ["#32CD32"],
  bigblue: ["#1C39BB"],
  bimini: ["#00A5E0"],
  darkgreengreyheather: ["#006400", "#B0B0B0"],
  goldcarbonheather: ["#FFD700", "#B0B0B0"],
  columbiabluenavy: ["#C4D8E2", "#000080"],
  golddarkgreen: ["#FFD700", "#006400"],
  biminiblue: ["#00A5E0"],
  biminibluewhite: ["#00A5E0", "#ffffff"],
  clevelandcavaliers: ["#6F263D", "#FFB81C"],
  dallasmavericks: ["#0053BC", "#BBC4CA"],
  tobaccoblackbuffalo: ["#6D5843", "#000000"],
  boysenberry: ["#873260"],
  vintagefadedpink: ["#FFB6C1"],
  camonew: ["#708090"],
  earthocean: ["#1C352D"],
  earthwhite: ["#F5F5F5"],
  limetonalblend: ["#32CD32"],
  bkbkpg: ["#000000", "#000000", "#A9A9A9"],
  bkchg: ["#000000", "#708090", "#6E6E6E"],
  powerredwhite: ["#FF0000", "#ffffff"],
  shockbluewhite: ["#00BFFF", "#ffffff"],
  collegiategreen: ["#008000"],
  vistagreyeqtyellow: ["#708090", "#FAD02E"],
  spiderblack: ["#000000"],
  collegiatepurple: ["#7F00FF"],
  midgreyheatherblack: ["#707070", "#000000"],
  powerredheatherblack: ["#FF0000", "#000000"],
  collegiateroyal: ["#4169E1"],
  teamgreenwhite: ["#008000", "#ffffff"],
  titaniumwhite: ["#878681", "#ffffff"],
  blackmelange: ["#000000"],
  collegiateburgundymelange: ["#800000"],
  bluefusion: ["#5B6EED"],
  collegiateburgundy: ["#800000"],
  greyfive: ["#666666"],
  greyfiveheather: ["#666666"],
  midgreymelange: ["#707070"],
  teamnavybluewhite: ["#000080", "#ffffff"],
  mauveheather: ["#E0B0FF"],
  greythreeheatherblack: ["#4D4D4D", "#000000"],
  greythreewhite: ["#4D4D4D", "#ffffff"],
  teamroyalgreytwo: ["#4169E1", "#BEBEBE"],
  teamcollegiateredgreytwo: ["#8B0000", "#BEBEBE"],
  silverstoneheather: ["#C0C0C0"],
  greythreegreyfive: ["#4D4D4D", "#666666"],
  teamnavybluegreyfive: ["#000080", "#666666"],
  blackgreythreegreythreeheather: ["#000000", "#4D4D4D", "#4D4D4D"],
  collegiatenavygreythreeheathergreytwo: ["#000080", "#4D4D4D", "#BEBEBE"],
  whitegreythreeheathergreythree: ["#ffffff", "#4D4D4D", "#4D4D4D"],
  blackwhitegreythree: ["#000000", "#ffffff", "#4D4D4D"],
  glowbluewhitenavy: ["#1C8CEA", "#ffffff", "#000080"],
  greythreeteamroyalnavy: ["#4D4D4D", "#4169E1", "#000080"],
  glowblueheathercollegiatenavyheather: ["#1C8CEA", "#000080"],
  snowcone: ["#DCE3E5"],
  collegiatenavygreythreemelange: ["#000080", "#4D4D4D"],
  greenoxidegreythreemelange: ["#0A7220", "#4D4D4D"],
  greythreemelange: ["#4D4D4D"],
  deepheathercardinal: ["#A9A9A9", "#C41E3A"],
  mediumgreyheather: ["#707070"],
  teamnavyblue: ["#000080"],
  greytwomelange: ["#BEBEBE"],
  candyorange: ["#FF5733"],
  greytwo: ["#BEBEBE"],
  irishgreenheather: ["#4CBB17"],
  forestgreywhite: ["#228B22", "#808080", "#ffffff"],
  goldgreywhite: ["#FFD700", "#808080", "#ffffff"],
  maroongreywhite: ["#800000", "#808080", "#ffffff"],
  navygreywhite: ["#000080", "#808080", "#ffffff"],
  heathercement: ["#A6A6A6"],
  heathercharitypink: ["#E67E9E"],
  heatheremerald: ["#50C878"],
  whitecharcoal: ["#ffffff", "#36454F"],
  chestnut: ["#954535"],
  darklavender: ["#734F96"],
  heathersilver: ["#C0C0C0"],
  columbiabluegreywhite: ["#C4D8E2", "#808080", "#ffffff"],
  lavenderblue: ["#CCCCFF"],
  heatherpinkgravel: ["#FF6E7A"],
  purplegreywhite: ["#800080", "#808080", "#ffffff"],
  lavenderdust: ["#B57EDC"],
  redgreywhite: ["#FF0000", "#808080", "#ffffff"],
  fluorescentorangegreywhite: ["#FF4F00", "#808080", "#ffffff"],
  toast: ["#9C661F"],
  greyblackwhite: ["#808080", "#000000", "#ffffff"],
  heathergold: ["#EDDA74"],
  heatheroatmeal: ["#F5DEB3"],
  athleticheatherred: ["#A9A9A9", "#FF0000"],
  athleticheatherroyal: ["#A9A9A9", "#4169E1"],
  maroongold: ["#800000", "#FFD700"],
  brownwhite: ["#964B00", "#ffffff"],
  striatedblacksolidblack: ["#3D3D3D", "#000000"],
  heathernavynavystripe: ["#6C7A89", "#000080"],
  limenavy: ["#00FF00", "#000080"],
  sanddunetriblend: ["#EDC9AF"],
  solidasphalttriblend: ["#130A06"],
  charlottehornets: ["#1D1160", "#00778B"],
  solidsilvertriblend: ["#C0C0C0"],
  losangelesclippers: ["#ED174C", "#006BB6"],
  memphisgrizzlies: ["#12173F", "#5D76A9"],
  bostonceltics: ["#007A33", "#BA9653"],
  brooklynnets: ["#000000", "#FFFFFF"],
  naturalheatherstar: ["#A89F91"],
  denimstar: ["#1560BD"],
  deepviolet: ["#9400D3"],
  fadedpink: ["#FFC3C5"],
  heathersunsetcoral: ["#FF6E7A"],
  whiskeyrose: ["#D17A56"],
  islandgreen: ["#2E8B57"],
  leopard: ["#A0522D"],
  rosebloom: ["#FF66CC"],
  stonewashblue: ["#486581"],
  heritageroyal: ["#4169E1"],
  darkbluepigment: ["#00008B"],
  vintageoat: ["#F5DEB3"],
  whitemaize: ["#FFFFFF", "#FFDB4D"],
  ivorynew: ["#FFFFF0"],
  nickelnew: ["#72776B"],
  rosebloomnew: ["#FF66CC"],
  ecosetruearmygreen: ["#4B5320"],
  ecosetruecamel: ["#C19A6B"],
  darkgreynew: ["#A9A9A9"],
  darktealnew: ["#008080"],
  ecorsequartz: ["#F7CAC9"],
  ecosetruepacificblue: ["#1CA9C9"],
  military: ["#4B5320"],
  redstar: ["#C8102E"],
  blacknew: ["#000000"],
  lightgreynew: ["#D3D3D3"],
  graniteheatherstar: ["#676767"],
  truffle: ["#C68E17"],
  tobaccofieldkhaki: ["#6C541E", "#B4A682"],
  mermaidgreen: ["#7FFFD4"],
  blackplaid: ["#000000"],
  heatherbluenavy: ["#6C7A89", "#000080"],
  royalblackbuffalo: ["#4169E1", "#000000"],
  heathergreyheatherdarkgrey: ["#B0B0B0", "#A9A9A9"],
  lightbeige: ["#F5F5DC"],
  heritagecolumbiablueplaid: ["#C4D8E2"],
  lightgreymélange: ["#D3D3D3"],
  heritagenavyorangegreyplaid: ["#000080", "#FFA500", "#808080"],
  burgundymélange: ["#800020"],
  greenbottle: ["#006A4E"],
  mustardyellow: ["#FFDB4D"],
  powerpinkwhite: ["#FF6FFF", "#FFFFFF"],
  poweryellowpoweryellowdigi: ["#FFD700", "#DAA520"],
  purplepurpledigi: ["#800080", "#551A8B"],
  royalroyaldigi: ["#4169E1", "#000080"],
  columbiabluecolumbiabluedigi: ["#C4D8E2", "#9BDDFF"],
  darkgreendarkgreendigi: ["#006400", "#006400"],
  orangeorangedigi: ["#FFA500", "#FFA500"],
  redreddigi: ["#FF0000", "#FF0000"],
  blackblackdigi: ["#000000", "#000000"],
  royalwhiteblack: ["#4169E1", "#FFFFFF", "#000000"],
  blacklime: ["#000000", "#00FF00"],
  powerpinkblackwhite: ["#FF6FFF", "#000000", "#FFFFFF"],
  limewhite: ["#00FF00", "#FFFFFF"],
  blackcamoblack: ["#000000", "#000000"],
  navymodwhite: ["#000080", "#FFFFFF"],
  redmodwhite: ["#FF0000", "#FFFFFF"],
  royalmodwhite: ["#4169E1", "#FFFFFF"],
  silvergreyroyal: ["#C0C0C0", "#4169E1"],
  navysilvergreywhite: ["#000080", "#C0C0C0", "#FFFFFF"],
  carbonprintorange: ["#464646", "#FFA500"],
  carbonprintpurple: ["#464646", "#800080"],
  charcoalheathervintagewhite: ["#36454F", "#FAEBD7"],
  vintagenavyvintagewhite: ["#231A40", "#FAEBD7"],
  blacksilvergreywhite: ["#000000", "#C0C0C0", "#FFFFFF"],
  whiteblacksilvergrey: ["#FFFFFF", "#000000", "#C0C0C0"],
  carbonprintforest: ["#464646", "#228B22"],
  silverblackmod: ["#C0C0C0", "#000000"],
  navywhitesilvergrey: ["#000080", "#FFFFFF", "#C0C0C0"],
  limeblack: ["#00FF00", "#000000"],
  powerorangewhite: ["#FF4500", "#FFFFFF"],
  poweryellowblack: ["#FFD700", "#000000"],
  redheatherblack: ["#FF0000", "#000000"],
  steelwhite: ["#B0C4DE", "#FFFFFF"],
  vintageroyalvintagegrey: ["#4169E1", "#B0B0B0"],
  blackecru: ["#000000", "#C2B280"],
  heathernavyheathercharcoal: ["#000080", "#36454F"],
  buter: ["#FFE05D"],
  navytiedye: ["#000080"],
  greywhitedot: ["#808080", "#FFFFFF"],
  lightorangeslatewhite: ["#FFA07A", "#708090", "#FFFFFF"],
  limeslatewhite: ["#00FF00", "#708090", "#FFFFFF"],
  limegraphite: ["#00FF00", "#1C1C1C"],
  maroongraphite: ["#800000", "#1C1C1C"],
  goldsparkle: ["#FFD700"],
  silversparkle: ["#C0C0C0"],
  purplegraphitewhite: ["#800080", "#1C1C1C", "#FFFFFF"],
  redgraphitewhite: ["#FF0000", "#1C1C1C", "#FFFFFF"],
  athleticheathermaroon: ["#B0B0B0", "#800000"],
  darkgreymarble: ["#A9A9A9"],
  redsolid: ["#FF0000"],
  redtriblendbuffalo: ["#FF0000"],
  blackcamotriblend: ["#000000"],
  blackstarsstripestriblend: ["#000000"],
  navywhitecarbon: ["#000080", "#FFFFFF", "#464646"],
  iron: ["#D4D7D9"],
  lake: ["#5D8AA8"],
  royalgreyheather: ["#4169E1", "#B0B0B0"],
  scarletcarbonheather: ["#FF2400", "#464646"],
  greyheatherscarlet: ["#B0B0B0", "#FF2400"],
  athleticheatherdarkgreen: ["#B0B0B0", "#006400"],
  blacklimewhite: ["#000000", "#00FF00", "#FFFFFF"],
  blackpoweryellowwhite: ["#000000", "#FFD700", "#FFFFFF"],
  latenightblue: ["#003366"],
  columbiabluewhitewhite: ["#9BDDFF", "#FFFFFF", "#FFFFFF"],
  redwhitewhite: ["#FF0000", "#FFFFFF", "#FFFFFF"],
  royalwhitewhite: ["#4169E1", "#FFFFFF", "#FFFFFF"],
  darkgreenwhitewhite: ["#006400", "#FFFFFF", "#FFFFFF"],
  maroonwhitemetallicsilver: ["#800000", "#FFFFFF", "#C0C0C0"],
  purplewhitemetallicsilver: ["#800080", "#FFFFFF", "#C0C0C0"],
  redwhitemetallicsilver: ["#FF0000", "#FFFFFF", "#C0C0C0"],
  bodyblush: ["#FF6FFF"],
  graphiteblackgraphiteprint: ["#1C1C1C", "#000000", "#1C1C1C"],
  purpleblackpurpleprint: ["#800080", "#000000", "#800080"],
  blackorangewhite: ["#000000", "#FFA500", "#FFFFFF"],
  cementtriblend: ["#A9A9A9"],
  blackblackblend: ["#000000", "#000000"],
  royalroyalblend: ["#4169E1", "#4169E1"],
  charcoalmarble: ["#36454F"],
  maroonmaroon: ["#800000", "#800000"],
  redacidwash: ["#FF0000"],
  royalroyal: ["#4169E1", "#4169E1"],
  trueroyalacidwash: ["#4169E1"],
  navyblend: ["#000080"],
  oatmealheathersimplyredheather: ["#F5DEB3", "#FF4500"],
  smokeheathercharcoalheather: ["#F5F5F5", "#36454F"],
  smokeheatherblack: ["#F5F5F5", "#000000"],
  smokeheathersimplyredheather: ["#F5F5F5", "#FF4500"],
  smokeheathervintagenavyheather: ["#F5F5F5", "#231A40"],
  smokeheathervintageroyalheather: ["#F5F5F5", "#4169E1"],
  cottoncandy: ["#FFB7D5"],
  unicorn: ["#FFD1DC"],
  stonegrey: ["#8B8680"],
  solidpinkblend: ["#FF69B4"],
  purplegraphite: ["#800080", "#1C1C1C"],
  solidbabyblueblend: ["#A1CAF1"],
  royalpurple: ["#7851A9"],
  kellytriblend: ["#4CBB17"],
  columbiabluegraphite: ["#9BDDFF", "#1C1C1C"],
  forestgraphite: ["#228B22", "#1C1C1C"],
  paleyellowtriblend: ["#FFFFE0"],
  springgreentriblend: ["#00FF7F"],
  gulfstream: ["#80B3C4"],
  fossil: ["#6D7813"],
  redredcamo: ["#FF0000", "#FF0000"],
  whitenavycamo: ["#FFFFFF", "#000080"],
  regalnavyheather: ["#000080"],
  whiteredcamo: ["#FFFFFF", "#FF0000"],
  blackburntorange: ["#000000", "#CC5500"],
  redpepperheather: ["#FF5C5C"],
  pumpkinheather: ["#FF7518"],
  jadepineheather: ["#00A36C"],
  brownsugarheather: ["#AF6E4D"],
  graphitesafetyyellow: ["#1C1C1C", "#EED202"],
  canyonrockbrown: ["#6A4E42"],
  equilibriumgreen: ["#0D5E2C"],
  icedmocha: ["#B0885A"],
  blackreddigital: ["#000000", "#FF0000"],
  blackwhitedigital: ["#000000", "#FFFFFF"],
  goldtonalblend: ["#FFD700"],
  purpletonalblend: ["#800080"],
  blacktonalblend: ["#000000"],
  electricbluetonalblend: ["#7DF9FF"],
  redtonalblend: ["#FF0000"],
  royaltonalblend: ["#4169E1"],
  foresttonalblend: ["#228B22"],
  athleticdarkgreen: ["#006400"],
  royalroyaltonalblend: ["#4169E1", "#4169E1"],
  vegasgoldvegasgoldtonalblend: ["#C5B358", "#C5B358"],
  redredtonalblend: ["#FF0000", "#FF0000"],
  burntorangedigital: ["#CC5500"],
  golddigital: ["#FFD700"],
  redroyaldigital: ["#FF0000", "#4169E1"],
  columbiabluedigital: ["#9BDDFF"],
  limedigital: ["#00FF00"],
  pinkdigital: ["#FFC0CB"],
  graphitesand: ["#1C1C1C", "#C2B280"],
  forestforestblend: ["#228B22", "#228B22"],
  redredblend: ["#FF0000", "#FF0000"],
  whitecapblue: ["#5D8AA8"],
  navynavyblend: ["#000080", "#000080"],
  safetyyellowgraphite: ["#EED202", "#1C1C1C"],
  fieldkhaki: ["#C3B091"],
  tobacco: ["#6D5843"],
  navysafetyyellow: ["#000080", "#EED202"],
  carbonheatherlime: ["#464646", "#00FF00"],
  carbonheatherroyal: ["#464646", "#4169E1"],
  carbonheatherwhite: ["#464646", "#FFFFFF"],
  steelheather: ["#B0C4DE"],
  willowgreen: ["#9ED1B3"],
  darkheathergreycharcoal: ["#A9A9A9", "#36454F"],
  turquoisewhite: ["#40E0D0", "#FFFFFF"],
  royaltiedye: ["#4169E1"],
  graphitetiedye: ["#1C1C1C"],
  steelheathergraphite: ["#B0C4DE", "#1C1C1C"],
  caramelbrown: ["#A06540"],
  tricharcoal: ["#36454F"],
  tridenim: ["#1560BD"],
  marooncarbon: ["#800000", "#555555"],
  solidcharcoal: ["#36454F"],
  triasphalt: ["#120A8F"],
  triathleticgrey: ["#B2BEB5"],
  triblueberry: ["#4F86F7"],
  trinavy: ["#000080"],
  triolive: ["#708238"],
  triorange: ["#FFA500"],
  triplum: ["#8E4585"],
  triroyalblue: ["#4169E1"],
  heatheroat: ["#CDB085"],
  brickheather: ["#C62D42"],
  navymarble: ["#000080"],
  blackmineralwash: ["#000000"],
  athleticheatherwhite: ["#B2BEB5", "#FFFFFF"],
  redmarble: ["#FF0000"],
  navyspeckled: ["#000080"],
  redspeckled: ["#FF0000"],
  palepinkheather: ["#FFD1DC"],
  daffodil: ["#FFFF31"],
  charcoalfleckblack: ["#36454F", "#000000"],
  charcoalfleckfirecoral: ["#36454F", "#FF4040"],
  camel: ["#C19A6B"],
  blush: ["#DE5D83"],
  frostyhunter: ["#3CB371"],
  frostynavy: ["#4682B4"],
  frostygrey: ["#778899"],
  realtreeapg: ["#3C6E47"],
  kellyathleticheather: ["#4CBB17", "#B2BEB5"],
  navywhitegingham: ["#000080", "#FFFFFF"],
  crimsonnavy: ["#DC143C", "#000080"],
  firebrickstripe: ["#B22222"],
  denimstripe: ["#1560BD"],
  greencamoblack: ["#008000", "#000000"],
  palevioletheather: ["#D39BCB"],
  cloudheather: ["#ACE5EE"],
  slatetriblend: ["#708090"],
  royaltriblend: ["#4169E1"],
  minttobeheather: ["#98FF98"],
  aquavelvetheather: ["#00FFFF"],
  blackneonblaze: ["#000000", "#FF6C00"],
  blackneonyellow: ["#000000", "#CCFF00"],
  ebonyheather: ["#555D50"],
  horizonorange: ["#FFA343"],
  nantucketred: ["#E25822"],
  heatherredscarletblack: ["#AD1C1E", "#000000"],
  heatherroyalblack: ["#4169E1", "#000000"],
  fadedfatigue: ["#5E735D"],
  brightredscarlet: ["#FF2400"],
  heatheroxfordgrey: ["#888B8D"],
  fireballred: ["#FF5F31"],
  khakiwhite: ["#C3B091", "#FFFFFF"],
  richgreengrass: ["#4CBB17"],
  redquartzshark: ["#E34234", "#6B8E23"],
  blackfishflag: ["#000000"],
  nighttidefishflag: ["#08192D"],
  titaniumfishflag: ["#878681"],
  tuskfishflag: ["#E3DCC3"],
  citygrey: ["#6B6966"],
  charcoaltriblend: ["#36454F"],
  oxfordirishgreenheather: ["#6D6968", "#32CD32"],
  oxfordtrueblueheather: ["#6D6968", "#007FFF"],
  trueblueheatheroxford: ["#007FFF", "#6D6968"],
  smokeblack: ["#738276", "#000000"],
  darknavysmoke: ["#000033", "#738276"],
  rinseddesertsand: ["#EDC9AF"],
  rinsedtimber: ["#CD683D"],
  rinsedindigorigid: ["#310062"],
  rinsedstonewashedindigoblue: ["#123456"],
  rinsedcharcoal: ["#36454F"],
  rinsedkhaki: ["#C3B091"],
  rigidindigo: ["#4B0082"],
  militarykhaki: ["#C3B091"],
  digitaltealheather: ["#008080"],
  gulfblue: ["#007BA7"],
  firecoralmagenta: ["#FF4040", "#FF00FF"],
  electricblueneongreen: ["#7DF9FF", "#39FF14"],
  blackinkheatherblackink: ["#000000"],
  whitescubablue: ["#FFFFFF", "#00ABAB"],
  whitecharcoalheather: ["#FFFFFF", "#36454F"],
  navyfleck: ["#000080"],
  onyxfleck: ["#353839"],
  blackinkwhite: ["#000000", "#FFFFFF"],
  peppernavy: ["#D3D3D3", "#000080"],
  pepperred: ["#D3D3D3", "#FF0000"],
  oatmealheatherfieryredheather: ["#CDB085", "#FF4901"],
  oatmealheatherforestgreenheather: ["#CDB085", "#4F7942"],
  oatmealheatherindigoheather: ["#CDB085", "#4B0082"],
  oatmealheathermustardheather: ["#CDB085", "#FFDB58"],
  oatmealheatherblackink: ["#CDB085", "#000000"],
  dunemist: ["#D2B48C"],
  blackreptile: ["#000000"],
  brownreptile: ["#8B4513"],
  greenreptile: ["#006400"],
  naturalleopard: ["#D4A017"],
  earth: ["#A2653E"],
  fatigue: ["#808000"],
  nile: ["#E1F5FE"],
  paragon: ["#E9E1D6"],
  malibu: ["#66DDFF"],
  prism: ["#A68064"],
  tucson: ["#765D3F"],
  pomegranate: ["#F34723"],
  fantasticflag: ["#7F00FF"],
  devine: ["#EFDECD"],
  blackrainbow: ["#000000"],
  dunes: ["#D2B48C"],
  erosion: ["#A16B47"],
  redwhiteblue: ["#FF0000", "#FFFFFF", "#0000FF"],
  whiterainbow: ["#FFFFFF"],
  zenith: ["#1A1A00"],
  bolivarspiral: ["#005073"],
  sandalwoodstone: ["#AF8F2C", "#806517"],
  washedmauvelous: ["#EF98AA"],
  truenavytriblend: ["#000080"],
  olivetiedye: ["#808000"],
  sunsettiedye: ["#FF4500"],
  antiquewhitesmoke: ["#FAEBD7", "#738276"],
  vintageburgundy: ["#800020"],
  vintageorange: ["#FFA500"],
  carolinabluemist: ["#56A0D3"],
  vintagesmokegraniteheather: ["#F5F5F5", "#A0A0A0"],
  whitecarbon: ["#FFFFFF", "#666666"],
  carbon: ["#666666"],
  athleticgreyheather: ["#BEBEBE"],
  universitybluewhite: ["#007FFF", "#FFFFFF"],
  royalmelange: ["#4169E1"],
  lightgoldwhite: ["#FAFAD2", "#FFFFFF"],
  greymelange: ["#D3D3D3"],
  goldheather: ["#DAA520"],
  scarletcloudprint: ["#FF2400"],
  silvercloudprint: ["#C0C0C0"],
  royalcloudprint: ["#4169E1"],
  navycloudprint: ["#000080"],
  blendedwhite: ["#FFFFFF"],
  cardinalblackout: ["#C41E3A"],
  blendedblack: ["#000000"],
  terracottaheather: ["#E2725B"],
  blacklightgoldwhite: ["#000000", "#DAA520", "#FFFFFF"],
  blackscarletscarlet: ["#000000", "#FF2400", "#FF2400"],
  vintagebirchheather: ["#D2B48C"],
  blackwhiteorange: ["#000000", "#FFFFFF", "#FFA500"],
  blackwhitescarlet: ["#000000", "#FFFFFF", "#FF2400"],
  forestheatherforest: ["#228B22", "#228B22"],
  silverHeatherSilver: ["#C0C0C0", "#C0C0C0"],
  tundraHazePrint: ["#ACD9E2"],
  throwbackBlack: ["#000000"],
  navyLightBlue: ["#000080", "#ADD8E6"],
  navyNavyRed: ["#000080", "#8B0000"],
  neonOrangeWhite: ["#FF4500", "#FFFFFF"],
  tieDyeCottonCandy: ["#FF69B4"],
  armyBlackZipper: ["#4B5320", "#000000"],
  maroonClassicNavy: ["#800000", "#000080"],
  blushWhiteZipper: ["#FF6EB4", "#FFFFFF"],
  skyHeather: ["#76D7EA"],
  armyCamo: ["#4B5320"],
  forestCamo: ["#228B22"],
  snowCamo: ["#F0E68C"],
  stormBlue: ["#007F9A"],
  gunmetalHeatherCharcoalHeather: ["#2C3539", "#36454F"],
  gunmetalHeatherClassicNavyHeather: ["#2C3539", "#000080"],
  mistyBlue: ["#AFDCEC"],
  forestCamoHeather: ["#228B22"],
  pigmentSandstone: ["#BDB76B"],
  pigmentSlateBlue: ["#6A5ACD"],
  pigmentPink: ["#D70075"],
  burntOrangeHeather: ["#FF7034"],
  bone: ["#E3DAC9"],
  gunmetalHeather: ["#2C3539"],
  lightBlackberry: ["#4B0082"],
  blackLeopard: ["#000000"],
  cashmereBlueFloral: ["#9ED7D8"],
  dustyPinkFloral: ["#D0848E"],
  khakiCamo: ["#C3B091"],
  lavenderStripes: ["#E6E6FA"],
  leopardBlack: ["#8B4513", "#000000"],
  lightBlueWhite: ["#ADD8E6", "#FFFFFF"],
  lightGreyPolkaDots: ["#D3D3D3"],
  navyStripes: ["#000080"],
  oliveCamo: ["#808000"],
  redLeopard: ["#FF0000"],
  rosePolkaDots: ["#FF66B2"],
  darkSmoke: ["#3F3F3F"],
  oxfordSilver: ["#6C6E68", "#C0C0C0"],
  royalSilver: ["#4169E1", "#C0C0C0"],
  wildberrySilver: ["#8B008B", "#C0C0C0"],
  simplyRed: ["#DC143C"],
  silverGreyTriblend: ["#C0C0C0"],
  magentaHeather: ["#FF00FF"],
  emeraldNeonYellow: ["#50C878", "#CCFF00"],
  magentaNeonYellow: ["#FF00FF", "#CCFF00"],
  royalFleck: ["#4169E1"],
  navyFleckNavy: ["#000080", "#000080"],
  redFleckRed: ["#FF0000", "#FF0000"],
  royalfleckroyal: ["#4169E1", "#4169E1"],
  charcoalfleckemerald: ["#36454F", "#50C878"],
  fieryredheather: ["#FF3030"],
  oxfordindigoheather: ["#6C6E68", "#4B0082"],
  blazingorange: ["#FFA500"],
  stonekhaki: ["#A9A491"],
  advantageclassic: ["#464646"],
  mossyoakcountry: ["#5E6B4D"],
  mossyoakshadowgrassblades: ["#72744A"],
  newtimber: ["#6B4226"],
  allpurposeusaflag: ["#B22222", "#3C3B6E"],
  blackblackskull: ["#000000", "#000000"],
  blackusaflag: ["#000000", "#B22222"],
  darkgreenredmexicoflag: ["#006400", "#FF0000"],
  greyrainbow: ["#808080"],
  heathercanada: ["#A9A9A9"],
  limeorange: ["#00FF00", "#FFA500"],
  limeblue: ["#00FF00", "#0000FF"],
  redlime: ["#FF0000", "#00FF00"],
  whitevintageheather: ["#FFFFFF", "#A9A9A9"],
  graniteheathervintagesmoke: ["#708090", "#A9A9A9"],
  vintagehotpinkwhite: ["#FF69B4", "#FFFFFF"],
  heatherwhitedotwhite: ["#A9A9A9", "#FFFFFF"],
  heatherwhitestripe: ["#A9A9A9", "#FFFFFF"],
  blacksolidwhite: ["#000000", "#FFFFFF"],
  papayamelange: ["#FFDAB9"],
  redmelange: ["#FF0000"],
  naturalcamo: ["#8B8B83"],
  oceanside: ["#77B5FE"],
  passionfruit: ["#FF5971"],
  rainbowstripe: [
    "#FF0000",
    "#FF7F00",
    "#FFFF00",
    "#00FF00",
    "#0000FF",
    "#4B0082",
  ],
  sangriablackout: ["#92000A"],
  shadowstripe: ["#8B8680"],
  vintagesmokevintageheather: ["#A9A9A9", "#A9A9A9"],
  naturalheathergraniteheather: ["#8B8B83", "#708090"],
  vintageburgundywhite: ["#800020", "#FFFFFF"],
  smokemelange: ["#736F6E"],
  lavenderkhaki: ["#E6E6FA", "#C3B091"],
  lightbluekhaki: ["#ADD8E6", "#C3B091"],
  marinebluekhaki: ["#7B68EE", "#C3B091"],
  mericakhaki: ["#E32636", "#C3B091"],
  nantucketredkhaki: ["#E25822", "#C3B091"],
  navyfieldcamokhaki: ["#000080", "#78866B"],
  navybrown: ["#000080", "#A52A2A"],
  aquakhaki: ["#00FFFF", "#C3B091"],
  econavywhite: ["#000080", "#FFFFFF"],
  ecoorange: ["#FFA500"],
  ecopurple: ["#800080"],
  charcoalslubblack: ["#36454F", "#000000"],
  copperslubblack: ["#B87333", "#000000"],
  greywoolplaid: ["#808080"],
  heathertanblack: ["#A9A9A9", "#D2B48C"],
  marineblueblack: ["#7B68EE", "#000000"],
  navyslubblack: ["#000080", "#000000"],
  oliveslubblack: ["#808000", "#000000"],
  mossyoakcountrybreakup: ["#5E6B4D"],
  periwinkleblue: ["#CCCCFF"],
  seaglassgreen: ["#00FFCC"],
  goldenyellow: ["#FFDF00"],
  naturalroyal: ["#FAEBD7", "#4169E1"],
  metallicgold: ["#D4AF37"],
  grassgreen: ["#4CBB17"],
  finekellygreen: ["#4CBB17"],
  silverleopard: ["#C0C0C0"],
  caliblue: ["#357ABD"],
  cameopink: ["#EFBBCC"],
  sunglow: ["#FFCC33"],
  cameopinkorchidice: ["#EFBBCC", "#E6E6FA"],
  orchidice: ["#E6E6FA"],
  cameopinkleopard: ["#EFBBCC"],
  pasteltiedye: ["#77DD77", "#FFB347", "#B19CD9", "#FFD1DC"],
  creamsicle: ["#FFB6C1"],
  sunray: ["#E3A857"],
  heatherashcharcoal: ["#A9A9A9", "#B2BEB5", "#36454F"],
  navyashcharcoal: ["#000080", "#B2BEB5", "#36454F"],
  cameopinkorchidiceleopard: ["#EFBBCC", "#E6E6FA"],
  atmosphere: ["#8FBC8F"],
  washedcardinal: ["#C41E3A"],
  washedgraphiteblack: ["#708090", "#000000"],
  washedmauve: ["#E0B0FF"],
  washednatural: ["#FAEBD7"],
  washedpurplerush: ["#9B30FF"],
  washedstonewashdenim: ["#6E7F80"],
  washedwhite: ["#FFFFFF"],
  forestgreennatural: ["#228B22", "#FAEBD7"],
  maroonnatural: ["#800000", "#FAEBD7"],
  vintageredsleevesvintageblackbody: ["#D23C23", "#000000"],
  kellygreensleeveswhitebody: ["#4CBB17", "#FFFFFF"],
  venetiangrey: ["#D4D4D4"],
  vintagelightorange: ["#FFA07A"],
  vintagelilac: ["#DB7093"],
  blonde: ["#FAF0BE"],
  clover: ["#00FF40"],
  naturalhotpink: ["#FAEBD7", "#FF69B4"],
  forgediron: ["#4E4E4E"],
  newgraniteheather: ["#BEBEBE"],
  blackout: ["#000000"],
  darkbrush: ["#483C32"],
  redline: ["#FF0000"],
  mossyoakbreakupkhaki: ["#5E6B4D", "#C3B091"],
  countrydnablaze: ["#7B997E", "#FF6600"],
  mossyoakbreakup: ["#5E6B4D"],
  countrydna: ["#7B997E"],
  periblueheather: ["#6699CC"],
  steelgreyheather: ["#7F8C8D"],
  navyroyal: ["#000080", "#4169E1"],
  peachcoral: ["#FFCBA4", "#FF7F50"],
  bluewater: ["#0000FF"],
  greyaquawater: ["#808080", "#00FFFF"],
  navynaturalcarolinablue: ["#000080", "#FAEBD7", "#7B68EE"],
  purplenaturallavender: ["#800080", "#FAEBD7", "#E6E6FA"],
  naturalmaroon: ["#FAEBD7", "#800000"],
  naturalnatural: ["#FAEBD7", "#FAEBD7"],
  naturalarmy: ["#FAEBD7", "#4B5320"],
  gunmetalgrey: ["#2C3539"],
  pacificnavy: ["#1C1C8E"],
  kellyred: ["#4CBB17", "#FF0000"],
  lavenderpurple: ["#E6E6FA", "#800080"],
  vintagecamovintagesmoke: ["#5D762A", "#7D7D88"],
  ballerina: ["#F2C3FF"],
  purplemelange: ["#9370DB"],
  stonemelange: ["#7D7D88"],
  chillindigo: ["#98F5FF", "#4B0082"],
  graniteheatherblack: ["#BEBEBE", "#000000"],
  graniteheathernaturalheather: ["#BEBEBE", "#D3D3D3"],
  lightbluenavynavynavywhitestripe: [
    "#ADD8E6",
    "#000080",
    "#000080",
    "#FFFFFF",
  ],
  mauvelousballerina: ["#E0B0FF", "#F2C3FF"],
  mintgrass: ["#98FF98", "#4CBB17"],
  naturalheatherheather: ["#FAEBD7", "#D3D3D3"],
  tradewindspot: ["#607B8B"],
  mauvelousspot: ["#E0B0FF"],
  ballerinamauvelous: ["#F2C3FF", "#E0B0FF"],
  whitetitanium: ["#FFFFFF", "#D1D1E0"],
  burgundymelange: ["#800020"],
  navyyellowtrim: ["#000080", "#FFFF00"],
  prewashedindigo: ["#4E5180"],
  prewashedindigo31i: ["#4E5180"],
  whitebluepinstripe: ["#FFFFFF", "#0000FF"],
  exbluecharcoal: ["#333366", "#36454F"],
  bluewhitestripe: ["#0000FF", "#FFFFFF"],
  lightgreyorangetrim: ["#D3D3D3", "#FFA500"],
  denimbluemicrocheck: ["#1560BD"],
  greybluestripe: ["#808080", "#0000FF"],
  navypostmanblue: ["#000080", "#536878"],
  gwwhitegreenstripe: ["#FFFFFF", "#00FF00"],
  hkhuntergreenkhaki: ["#355E3B", "#C3B091"],
  lightbluenavystripe: ["#ADD8E6", "#000080"],
  bsbluewhitestripe33: ["#3333A0", "#FFFFFF"],
  bsbluewhitestripe34: ["#3333A1", "#FFFFFF"],
  fbfrenchblue33: ["#6699CC"],
  gygrey33: ["#7D7D88"],
  gygrey36: ["#7D7D8B"],
  gygrey37: ["#7D7D8C"],
  gygrey38: ["#7D7D8D"],
  lblightblue32: ["#ADD8E5"],
  lblightblue33: ["#ADD8E6"],
  lblightblue34: ["#ADD8E7"],
  lblightblue35: ["#ADD8E8"],
  white34: ["#FFFFFF"],
  fbfrenchblue35: ["#6699CE"],
  fbfrenchblue36: ["#6699CF"],
  gygrey34: ["#7D7D89"],
  gygrey35: ["#7D7D8A"],
  white35: ["#FFFFFF"],
  white36: ["#FFFFFF"],
  navydiamondplate: ["#000080"],
  blackdiamondplate: ["#000000"],
  blackroyalbluelightgrey: ["#000000", "#4169E1", "#D3D3D3"],
  fluorescentyellowgreengrey: ["#CCFF00", "#00FF00", "#808080"],
  fluorescentyellowgreennavy: ["#CCFF00", "#00FF00", "#000080"],
  lightgreycharcoal: ["#D3D3D3", "#36454F"],
  blackroyalblue: ["#000000", "#4169E1"],
  navyduck: ["#000080"],
  armycamoblack: ["#4B5320", "#000000"],
  columbiabluecharcoalnavy: ["#B0E0E6", "#36454F", "#000080"],
  columbiabluekhaki: ["#B0E0E6", "#C3B091"],
  driftwoodkhaki: ["#A78D84", "#C3B091"],
  redcharcoalblack: ["#FF0000", "#36454F", "#000000"],
  stoneblackorange: ["#C3B091", "#000000", "#FFA500"],
  stoneblackred: ["#C3B091", "#000000", "#FF0000"],
  texasorangekhaki: ["#FF5511", "#C3B091"],
  whitecharcoalblack: ["#FFFFFF", "#36454F", "#000000"],
  kryptektyphonblack: ["#1B1B1B", "#000000"],
  militarydigitalcamolightgreen: ["#4B5320", "#90EE90"],
  mossyoakbottomlandblack: ["#4E4B2C", "#000000"],
  mossyoakcountrydnablack: ["#5E6B4D", "#000000"],
  mossyoakobsessionkhaki: ["#5E6B4D", "#C3B091"],
  realtreemax7buck: ["#535657", "#A52A2A"],
  realtreemax1brown: ["#747474", "#A52A2A"],
  realtreeoriginalblack: ["#5D762A", "#000000"],
  charcoalcolumbiablue: ["#36454F", "#B0E0E6"],
  charcoalkelly: ["#36454F", "#4CBB17"],
  charcoalnavy: ["#36454F", "#000080"],
  charcoalneonblue: ["#36454F", "#4D4DFF"],
  charcoalneongreen: ["#36454F", "#39FF14"],
  columbiabluewhitenavy: ["#B0E0E6", "#FFFFFF", "#000080"],
  cyanwhite: ["#00FFFF", "#FFFFFF"],
  darkgreenwhiteheathergrey: ["#006400", "#FFFFFF", "#D3D3D3"],
  darkgreenyellow: ["#006400", "#FFFF00"],
  greycharcoalblack: ["#808080", "#36454F", "#000000"],
  greycharcoalnavy: ["#808080", "#36454F", "#000080"],
  hotpinkblack: ["#FF69B4", "#000000"],
  hotpinkwhite: ["#FF69B4", "#FFFFFF"],
  navywhiteheathergrey: ["#000080", "#FFFFFF", "#D3D3D3"],
  navywhitered: ["#000080", "#FFFFFF", "#FF0000"],
  royalwhiteheathergrey: ["#4169E1", "#FFFFFF", "#D3D3D3"],
  royalwhitered: ["#4169E1", "#FFFFFF", "#FF0000"],
  armyolivegreentan: ["#4B5320", "#D2B48C"],
  beetlequarry: ["#555D50", "#6C7073"],
  desertcamobrown: ["#8B7355", "#A52A2A"],
  navydigitalcamocharcoal: ["#000080", "#36454F"],
  realtreeedgeneonorange: ["#564A4A", "#FFA500"],
  realtreeedgeneonpink: ["#564A4A", "#FFC0CB"],
  realtreeedgeneonyellow: ["#564A4A", "#FFFF00"],
  realtreetimberblack: ["#4A766E", "#000000"],
  heathergreybircharmy: ["#D3D3D3", "#DEB887", "#4B5320"],
  heathergreybirchcardinal: ["#D3D3D3", "#DEB887", "#C41E3A"],
  heathergreydarkcharcoal: ["#D3D3D3", "#333333"],
  heathernavysilver: ["#708090", "#C0C0C0"],
  heatherredsilver: ["#FF0000", "#C0C0C0"],
  heatherroyalsilver: ["#4169E1", "#C0C0C0"],
  redgreycharcoal: ["#FF0000", "#808080", "#36454F"],
  royalgreycharcoal: ["#4169E1", "#808080", "#36454F"],
  cardinalblackwhite: ["#C41E3A", "#000000", "#FFFFFF"],
  creammocha: ["#FFFDD0", "#A47555"],
  heathergreyrust: ["#D3D3D3", "#B7410E"],
  charcoaloldgold: ["#36454F", "#CFB53B"],
  palekhakilodengreen: ["#C3B091", "#6C7C7C"],
  ashblackheather: ["#B2BEB5", "#000000"],
  oxforddarkgreen: ["#6D7563", "#006400"],
  almond: ["#FFEBCD"],
  heatheredblue: ["#ADAFAA"],
  nantucketnavy: ["#1C252A"],
  onyxblack: ["#0C0000"],
  whiteneongreen: ["#FFFFFF", "#39FF14"],
  whiteoceanblue: ["#FFFFFF", "#4F42B5"],
  whiteorangeblue: ["#FFFFFF", "#FFA500", "#0000FF"],
  whiteredthickstripe: ["#FFFFFF", "#FF0000"],
  whiteredblue: ["#FFFFFF", "#FF0000", "#0000FF"],
  orangecharcoal: ["#FFA500", "#36454F"],
  heatherdarkroyal: ["#484C7D"],
  royalmustardstone: ["#4169E1", "#FFDB58", "#C3B091"],
  royalstone: ["#4169E1", "#C3B091"],
  stonestone: ["#C3B091", "#C3B091"],
  texasorangestone: ["#FF5714", "#C3B091"],
  bottlegreen: ["#006A4E"],
  lightolivegreen: ["#B5CC6A"],
  cassis: ["#D16F62"],
  neomint: ["#39FF14"],
  heathergraphite: ["#8D8681"],
  lemon: ["#FFF700"],
  sunshine: ["#FFFD37"],
  heatherpink: ["#FFC0CB"],
  heatherathleticblue: ["#6A93D4"],
  heatherdenimnavy: ["#567394", "#000080"],
  vintagewhiteblack: ["#FFFFE0", "#000000"],
  vintagewhiteinkedindia: ["#FFFFE0", "#5B92E5"],
  grapeleaf: ["#515949"],
  inkedindia: ["#5B92E5"],
  heatherlightblue: ["#B0E2FF"],
  brownheather: ["#8B4513"],
  peachheather: ["#FFDAB9"],
  turquoiseheather: ["#40E0D0"],
  trigrey: ["#828282"],
  triwhite: ["#FFFFFF"],
  tribrown: ["#8B4513"],
  triathleticblue: ["#1F497D"],
  redpaisley: ["#FF0000"],
  usaflag: ["#B22234"],
  whitepolyester: ["#FFFFFF"],
  englishblue: ["#3B4992"],
  greymist: ["#AFBAC9"],
  belairblue: ["#67AAB8"],
  darkpewter: ["#536267"],
  heatherdeeppacific: ["#556B2F"],
  heatherlapisblue: ["#6A5ACD"],
  heathershiraz: ["#8E3841"],
  spruce: ["#4E5452"],
  heathergreypurple: ["#D3D3D3", "#800080"],
  multicamalpine: ["#BBB9B8"],
  charcoalblack: ["#36454F", "#000000"],
  maroondigital: ["#7D1920"],
  blackgrill: ["#000000", "#444444"],
  navydigital: ["#000080"],
  redred: ["#FF0000", "#FF0000"],
  bluejaycolumbianavy: ["#5AABD0", "#3C7DC4"],
  pastelmint: ["#98FF98"],
  brightorangewhite: ["#FF4500", "#FFFFFF"],
  shockbluemidgrey: ["#46B4E4", "#696969"],
  solarlimewhite: ["#F4F142", "#FFFFFF"],
  blazeorangevistagrey: ["#FF6700", "#6699CC"],
  flag: ["#B22234"],
  collegiateredgreyfiveblack: ["#8B0000", "#A9A9A9", "#000000"],
  collegiateroyalgreythreecollegiatenavy: ["#4169E1", "#A9A9A9", "#000080"],
  retroheathercoral: ["#FF6F61"],
  greyfivegreythree: ["#A9A9A9", "#A9A9A9"],
  blackheathercarbon: ["#000000", "#3A3A3A"],
  powerredheathercarbon: ["#FF3030", "#3A3A3A"],
  collegiateroyalheathermidgrey: ["#4169E1", "#696969"],
  pride: ["#E0218A"],
  collegiateredblack: ["#8B0000", "#000000"],
  whitegreythreemelange: ["#FFFFFF", "#A9A9A9"],
  acidorangemelange: ["#FF4500"],
  forestforestdigital: ["#228B22", "#228B22"],
  blacksafetyorange: ["#000000", "#FF6600"],
  navyburntorange: ["#000080", "#CC5500"],
  vegasgoldheather: ["#C5B358"],
  solidkellyblend: ["#4CBB17"],
  solidnavyblend: ["#000080"],
  solidnaturalblend: ["#E0E0E0"],
  woodstock: ["#896A45"],
  retroheathergreen: ["#8A9A5B"],
  collegiatenavyheathercarbon: ["#000080", "#3A3A3A"],
  retroheatherpink: ["#FF6EB4"],
  luckybluemelange: ["#3666FF"],
  lightflashpurple: ["#B266FF"],
  gloryblue: ["#4997D0"],
  focusbluemelange: ["#336699"],
  goldgolddigital: ["#FFD700", "#FFD700"],
  burntorangegraphite: ["#CC5500", "#3A3A3A"],
  graphitelime: ["#3A3A3A", "#00FF00"],
  heatherfrenchvanilla: ["#F3E5AB"],
  collegiateburgundywhite: ["#8B0000", "#FFFFFF"],
  solaryellowwhite: ["#FFFF30", "#FFFFFF"],
  whitegreythreegreyfive: ["#FFFFFF", "#A9A9A9", "#A9A9A9"],
  sagestone: ["#BAAF96"],
  collegiateroyalgreythree: ["#4169E1", "#A9A9A9"],
  atlantic: ["#2E6E9F"],
  blackblacktonalblend: ["#000000", "#000000"],
  hotpinkheather: ["#FF69B4"],
  graphiteblacktonalblend: ["#3A3A3A", "#000000"],
  pastelblue: ["#AEC6CF"],
  eternity: ["#211A23"],
  stlucia: ["#43D8FF"],
  collegiateroyalheathercarbon: ["#4169E1", "#3A3A3A"],
  powerred: ["#FF3030"],
  greythreeheather: ["#A9A9A9"],
  powerredheather: ["#FF3030"],
  onix: ["#6F6E70"],
  collegiatepurplewhite: ["#800080", "#FFFFFF"],
  blackvistagrey: ["#000000", "#6699CC"],
  spidernavy: ["#000080"],
  spidersilver: ["#C0C0C0"],
  collegiatenavygreythreegreyfive: ["#000080", "#A9A9A9", "#A9A9A9"],
  unionjack: ["#0047AB"],
  blackheathermidgrey: ["#000000", "#696969"],
  blazeorangeblack: ["#FF6700", "#000000"],
  collegiatenavywhite: ["#000080", "#FFFFFF"],
  greyfiveblack: ["#A9A9A9", "#000000"],
  crystalbabyblue: ["#89CFF0"],
  crystalblack: ["#000000"],
  cardinalcardinaldigital: ["#8B0000", "#8B0000"],
  blueocean: ["#4F42B5"],
  blacklineembossed: ["#000000"],
  graphitelineembossed: ["#3A3A3A"],
  navynavytonalblend: ["#000080", "#000080"],
  blackgray: ["#000000", "#808080"],
  blackblizzard: ["#000000"],
  heritagegarnetplaid: ["#6A0DAD"],
  greenfluorescent: ["#00FF00"],
  graphitewhitedigi: ["#3A3A3A", "#FFFFFF"],
  lightmaroonwhite: ["#800000", "#FFFFFF"],
  columbiablackwhite: ["#6699CC", "#000000", "#FFFFFF"],
  whiteblackmod: ["#FFFFFF", "#000000"],
  carbonprintmaroon: ["#4B4D52", "#800000"],
  blackcamoblk: ["#000000", "#000000"],
  blackchambray: ["#000000"],
  oregano: ["#8A9A5B"],
  regalnavy: ["#000080"],
  utahjazz: ["#002B5C"],
  washingtonwizards: ["#E31837"],
  blueblackbuffalo: ["#0000FF", "#000000"],
  blackcrdbrd: ["#000000"],
  syntheticgreen: ["#8A9A5B"],
  blackdenimblk: ["#000000", "#000000"],
  victory: ["#F3E130"],
  blackdot: ["#000000"],
  spanishmoss: ["#79786F"],
  melanenavy: ["#000080"],
  melanegsilver: ["#C0C0C0"],
  arcticwhitestone: ["#FFFFFF", "#a9a68f"],
  naturalbrickred: ["#E0E0E0", "#801818"],
  naturalcharcoal: ["#E0E0E0", "#333333"],
  naturalmistblue: ["#E0E0E0", "#67A2B2"],
  whitesolidblack: ["#FFFFFF", "#000000"],
  vintagegreenwhite: ["#40A040", "#FFFFFF"],
  darkgreyneonorange: ["#696969", "#FF3300"],
  fogwhite: ["#D1D1D1", "#FFFFFF"],
  glacierwhite: ["#A3D6D7", "#FFFFFF"],
  grasswhite: ["#4CBB17", "#FFFFFF"],
  lightgreywhite: ["#D3D3D3", "#FFFFFF"],
  melonorangewhite: ["#FEBA4C", "#FFFFFF"],
  navyoxford: ["#000080", "#6C7B8B"],
  avocado: ["#568203"],
  earthgreen: ["#526B2D"],
  blackfauxfur: ["#000000"],
  blackfleck: ["#000000"],
  blackwhitecheck: ["#000000", "#FFFFFF"],
  blackmaroon: ["#000000", "#800000"],
  darknavydarknavylightgold: ["#00005B", "#00005B", "#D4AF37"],
  darknavydarknavywhite: ["#00005B", "#00005B", "#FFFFFF"],
  fbfrenchblue: ["#0072BB"],
  blackglitter: ["#000000"],
  coyotebrownsanddigitalnatural: ["#81613C", "#C2B280", "#FAEACB"],
  blackurbanwoodlandred: ["#000000", "#4B5320", "#FF0000"],
  spiderred: ["#E60000"],
  heatherdarkteal: ["#007575"],
  militarygreenstar: ["#4B5320"],
  ecoblackecoivory: ["#000000", "#F5E1A4"],
  ecogreyecoivory: ["#808080", "#F5E1A4"],
  coalpigment: ["#121212"],
  rosequartzpigment: ["#AA98A9"],
  deepsage: ["#607C47"],
  navynew: ["#000080"],
  olivetonaltiedye: ["#708238"],
  whitetruffle: ["#FFFFFF", "#D1B183"],
  purplewhitesilvergrey: ["#800080", "#FFFFFF", "#C0C0C0"],
  carbonheatherblack: ["#4B4E53", "#000000"],
  columbiabluecarbonheather: ["#9BDDFF", "#4B4E53"],
  scarletgreyheather: ["#FF2400", "#BEBEBE"],
  blackdigital: ["#000000"],
  athleticgreyroyal: ["#808080", "#000080"],
  greenclay: ["#0D9A8F"],
  lemonmeringue: ["#F6EABE"],
  rinsedmoss: ["#4A5D23"],
  tigercamo: ["#756847"],
  nickelheatherforestcamo: ["#A9A9A9", "#4B5320"],
  nickelcarbon: ["#A9A9A9", "#4B4E53"],
  redbuffaloplaid: ["#FF0000"],
  pigmentivory: ["#FFFFF0"],
  pigmentlightblue: ["#ADD8E6"],
  tiedyedustypink: ["#D3B4AD"],
  harvestgold: ["#DA9100"],
  vintagedenim: ["#1560BD"],
  vintagegreen: ["#3C7F3F"],
  naturalheathervintageburgundynatural: ["#E0E0E0", "#800020", "#E0E0E0"],
  vintageheathervintagenavywhite: ["#808080", "#000080", "#FFFFFF"],
  oasis: ["#00AFB5"],
  bluelagoonswirl: ["#5F9EA0"],
  greyscaletiedye: ["#B0B0B0"],
  neontiedye: ["#CCFF00"],
  frostedcharcoal: ["#706C61"],
  warmgreyblack: ["#A49E9E", "#000000"],
  mandarinorange: ["#E44D2E"],
  greyblueplaid: ["#A0AAB3"],
  lblightblue37: ["#ADD8E6"],
  lblightblue38: ["#B0E0E6"],
  white32: ["#FFFFFF"],
  lilacb: ["#C8A2C8"],
  trirust: ["#B7410E"],
  blackathleticgrey: ["#000000", "#7F8C8D"],
  blueaqua: ["#00FFFF"],
  vintageshockingpink: ["#FC0FC0"],
  pigmentyellow: ["#FFD700"],
  blackhthroxford: ["#000000", "#6C7B8B"],
  blackhthoxfrd: ["#000000", "#6C7B8B"],
  blackhthrblk: ["#000000"],
  blackhthrpol: ["#000000"],
  blackhthrred: ["#000000", "#FF0000"],
  blackhthrwht: ["#000000", "#FFFFFF"],
  blackjaspe: ["#000000"],
  pinktiedye: ["#FFC0CB"],
  smokestar: ["#E5E5E5"],
  cementoceanberry: ["#A9A9A9", "#50A7C9"],
  cementveryberry: ["#A9A9A9", "#D90056"],
  cementwildberry: ["#A9A9A9", "#8B008B"],
  blackslatemelan: ["#2C2B2C"],
  blacknfc: ["#000000"],
  blackombre: ["#000000"],
  whiteheatherdarkgrey: ["#FFFFFF", "#A9A9A9"],
  blackplastic: ["#000000"],
  blackpowdrblk: ["#000000"],
  blacksilk: ["#000000"],
  blacksmoke: ["#000000"],
  greypurple: ["#A9A9A9", "#800080"],
  pigmentblack: ["#000000"],
  tiedyesunsets: ["#FF4500"],
  pigmentalpinegreen: ["#148B3A"],
  pigmentdustypink: ["#DCCCD7"],
  pigmentplum: ["#8E4585"],
  tiedyepink: ["#FF69B4"],
  porcelain: ["#E1E4E8"],
  blackoxford: ["#000000", "#6C7B8B"],
  blackspacedye: ["#000000"],
  blackspeckled: ["#000000"],
  wisteriablackout: ["#C9A0DC", "#000000"],
  heathervintageburgundy: ["#A9A9A9", "#800020"],
  titaniumindigo: ["#878681", "#4B0082"],
  naturalforestgreen: ["#8B8B83", "#228B22"],
  dukbrownbrown: ["#654321", "#654321"],
  countrydnablack: ["#644117", "#000000"],
  countrydnaneonorange: ["#644117", "#FFA500"],
  kryptekhighlanderneonyellow: ["#99876D", "#FFFF00"],
  melonheather: ["#FDBCB4"],
  heathergreycancun: ["#A9A9A9", "#00FFFF"],
  olivetea: ["#808000", "#B5B581"],
  redtea: ["#FF0000", "#B5B581"],
  skybluetea: ["#87CEEB", "#B5B581"],
  blackveganlthr: ["#000000"],
  black_30: ["#000000"],
  black_32: ["#000000"],
  black_34: ["#000000"],
  black_36: ["#000000"],
  black_38: ["#000000"],
  black_40: ["#000000"],
  black_42: ["#000000"],
  black_44: ["#000000"],
  blackgoldglittr: ["#000000", "#FFD700"],
  bohemianblue: ["#1E90FF"],
  blackantqwht: ["#000000", "#FAEBD7"],
  blackathheather: ["#000000", "#A9A9A9"],
  charismacoral: ["#FF6F61"],
  brickred: ["#CB4154"],
  athleticcardinal: ["#B22222"],
  blackblcnt: ["#000000", "#0000FF"],
  heathergreyheathercharcoal: ["#A9A9A9", "#4A545C"],
  graphitecoral: ["#708090", "#FF6F61"],
  graphitelightlavender: ["#708090", "#E6E6FA"],
  maroonwhitewhite: ["#800000", "#FFFFFF"],
  navywhitewhite: ["#000080", "#FFFFFF"],
  athleticheatherneonpink: ["#A9A9A9", "#FF69B4"],
  mintheather: ["#98FF98"],
  stonewashedindigoblue: ["#606E8C"],
  blackheatheroxford: ["#000000", "#6C7B8B"],
  coralsoftyellow: ["#FF6F61", "#FDFD96"],
  arctichazeprint: ["#D0ECE7"],
  carbonlime: ["#57554C", "#00FF00"],
  mauvelousmelange: ["#EF98AA"],
  royalwhiteroyal: ["#4169E1", "#FFFFFF"],
  scarletwhitescarlet: ["#FF2400", "#FFFFFF"],
  whiteredskyblue: ["#FFFFFF", "#FF0000", "#87CEEB"],
  whitewheatelmwood: ["#FFFFFF", "#F5DEB3", "#8E735B"],
  blacksilverleopard: ["#000000", "#C0C0C0"],
  sunrisetiedye: ["#FFD700"],
  periblue: ["#6699CC"],
  whitekellygreen: ["#FFFFFF", "#4CBB17"],
  blackblackhth: ["#000000", "#000000"],
  seagreen: ["#2E8B57"],
  pigmentcement: ["#95A3A6"],
  washedblacknew: ["#000000"],
  winenew: ["#722F37"],
  blackblkrope: ["#000000", "#000000"],
  blackblkred: ["#000000", "#000000", "#FF0000"],
  blackbrown: ["#000000", "#8B4513"],
  blackdarkheathergrey: ["#000000", "#A9A9A9"],
  darknavynew: ["#000080"],
  orangewhitewhite: ["#FFA500", "#FFFFFF"],
  blackcamogrn: ["#000000"],
  oklahomacitythunder: ["#0072CE"],
  orlandomagic: ["#0077C0"],
  navyscarlet: ["#000080", "#FF2400"],
  blackcharhth: ["#000000"],
  blackgraphiteblackblend: ["#000000", "#708090"],
  whiteblackdigital: ["#FFFFFF", "#000000"],
  arid: ["#9E7B40"],
  collegiateblueblueblack: ["#5D76A9", "#0000FF", "#000000"],
  classicnavyroyal: ["#000080", "#4169E1"],
  classicnavysaddle: ["#000080", "#8B4513"],
  classickhaki: ["#BDB76B"],
  sail: ["#A5D7E1"],
  blackchrclhth: ["#000000"],
  blackcrbnhth: ["#000000"],
  blackcream: ["#000000", "#FFFDD0"],
  blackcreme: ["#000000", "#FFFDD0"],
  greytwoheathercollegiateroyalheather: ["#A9A9A9", "#4169E1"],
  blackgreytwogreyfivemelange: ["#000000", "#A9A9A9", "#A9A9A9"],
  collegiatenavygreytwogreyfivemelange: ["#120A8F", "#A9A9A9", "#A9A9A9"],
  topazblue: ["#74BBFB"],
  whitedeepviolet: ["#FFFFFF", "#9400D3"],
  trinavysolid: ["#08088A"],
  vintagesmokewhite: ["#A9A9A9", "#FFFFFF"],
  vintagecoyotebrown: ["#563C0D"],
  vintageredwhite: ["#FF0000", "#FFFFFF"],
  blackwhitestripe: ["#000000", "#FFFFFF"],
  marbledcharcoal: ["#4A545C"],
  vintagenavywhite: ["#120A8F", "#FFFFFF"],
  graphitenavy: ["#708090", "#000080"],
  blackdesrtpnk: ["#000000", "#FFC0CB"],
  blackdkcharcl: ["#000000", "#4A545C"],
  blackdkchrcl: ["#000000", "#4A545C"],
  blackengred: ["#000000", "#AB4B52"],
  greytwoheatherhirescoralheather: ["#A9A9A9", "#FD7C6E"],
  collegiateredblackgreyfivemelange: ["#8B0000", "#000000", "#A9A9A9"],
  atlantahawks: ["#E03A3E"],
  deserttan: ["#CC9966"],
  graphitepowerpink: ["#708090", "#FF69B4"],
  stonegreenheather: ["#5E6B6B"],
  americus: ["#3B3B6D"],
  saintvincentspiral: ["#4F6D5C"],
  mindfulness: ["#89CFF0"],
  burgundyspeck: ["#800020"],
  blackpurple: ["#000000", "#800080"],
  houndstooth: ["#000000", "#FFFFFF"],
  midnightnavysleevesdarkheathergreybody: ["#191970", "#A9A9A9"],
  royalsleeveswhitebody: ["#4169E1", "#FFFFFF"],
  blackgrphwht: ["#000000", "#A9A9A9", "#FFFFFF"],
  royalgraphitewhite: ["#4169E1", "#708090", "#FFFFFF"],
  whitegraphitewhite: ["#FFFFFF", "#708090"],
  royaloxford: ["#4169E1", "#8E735B"],
  vegasgoldwhiteblack: ["#C5B358", "#FFFFFF", "#000000"],
  whitenavyscarlet: ["#FFFFFF", "#000080", "#FF2400"],
  blackhthrgray: ["#000000", "#A9A9A9"],
  blackhthrgrey: ["#000000", "#A9A9A9"],
  orangecloudprint: ["#FFA500"],
  purplegranite: ["#800080", "#676767"],
  redgranite: ["#FF0000", "#676767"],
  seasalt: ["#F1F7F8"],
  bermuda: ["#7DD8C0"],
  vintageheathervintageorange: ["#A9A9A9", "#FFA500"],
  marblednavy: ["#120A8F"],
  marbledorange: ["#FFA500"],
  marbledpurple: ["#800080"],
  marbledroyal: ["#4169E1"],
  blackscarletwhite: ["#000000", "#FF2400", "#FFFFFF"],
  kellywhitestripe: ["#4CBB17", "#FFFFFF"],
  whitegreen: ["#FFFFFF", "#008000"],
  blackkelly: ["#000000", "#4CBB17"],
  greyforest: ["#808080", "#228B22"],
  greymaroon: ["#808080", "#800000"],
  portlandtrailblazers: ["#E03A3E"],
  sacramentokings: ["#5A2D81"],
  navymelange: ["#000080"],
  olivegreenblack: ["#808000", "#000000"],
  graphitewhitedigital: ["#708090", "#FFFFFF"],
  redreddigisilver: ["#FF0000", "#CC0000", "#C0C0C0"],
  whitewhitedigisilver: ["#FFFFFF", "#F5F5F5", "#C0C0C0"],
  blacklimegrn: ["#000000", "#32CD32"],
  pepperedredheather: ["#D32E31"],
  blackmandarin: ["#000000", "#F37A17"],
  blackmarine: ["#000000", "#042E4C"],
  blacknavy: ["#000000", "#000080"],
  blackneonblue: ["#000000", "#1B03A3"],
  blackneongrn: ["#000000", "#39FF14"],
  blackneonlime: ["#000000", "#BFFF00"],
  blackneonorng: ["#000000", "#FF3E00"],
  blackneonyllw: ["#000000", "#FFFF00"],
  philadelphia76ers: ["#006BB6"],
  vintagepine: ["#5E8D7E"],
  navystarsnew: ["#000080"],
  blackorngwht: ["#000000", "#FFA500", "#FFFFFF"],
  blackoxfrdhth: ["#000000", "#7D8471"],
  blackoyster: ["#000000", "#C2BCB6"],
  blackpink: ["#000000", "#FFC0CB"],
  blackplrred: ["#000000", "#7D1515"],
  charcoalorange: ["#36454F", "#FFA500"],
  grapesmash: ["#09294A"],
  newmaroon: ["#800000"],
  navynavydigital: ["#000080", "#000066"],
  blackwhitedarkheather: ["#000000", "#FFFFFF", "#A9A9A9"],
  blackwhitedeeporange: ["#000000", "#FFFFFF", "#FF4500"],
  blackwhiteforest: ["#000000", "#FFFFFF", "#228B22"],
  blackwhitegold: ["#000000", "#FFFFFF", "#FFD700"],
  blackwhitered: ["#000000", "#FFFFFF", "#FF0000"],
  blackwhiteroyal: ["#000000", "#FFFFFF", "#4169E1"],
  chalk: ["#EFEFEF"],
  cirrusgreyheather: ["#BBBBBB"],
  leadgrey: ["#8D8D8D"],
  blackred: ["#000000", "#FF0000"],
  purplepurpleblend: ["#800080", "#9932CC"],
  blackredblk: ["#000000", "#FF0000", "#000000"],
  steelheatherblack: ["#B0C4DE", "#000000"],
  blackredwht: ["#000000", "#FF0000", "#FFFFFF"],
  blackwhitewhite: ["#000000", "#FFFFFF", "#FFFFFF"],
  blackroywht: ["#000000", "#4169E1", "#FFFFFF"],
  smokegreyvintagecoal: ["#708090", "#373E41"],
  phoenixsuns: ["#1D1160"],
  hotcoralwhite: ["#FF6F61", "#FFFFFF"],
  greytiedye: ["#B0B0B0"],
  sunlight: ["#FFDA7A"],
  naturallime: ["#FAEBD7", "#00FF00"],
  sanantoniospurs: ["#C4CED4"],
  metallicpink: ["#E4717A"],
  metallicsilver: ["#C0C0C0"],
  blackslvrblk: ["#000000", "#C0C0C0", "#000000"],
  greenoxideblack: ["#679267", "#000000"],
  greythreeblack: ["#B2BEB5", "#000000"],
  blacksnryyllw: ["#000000", "#EEDD82"],
  blackspsilver: ["#000000", "#C0C0C0"],
  heathermediumblue: ["#6C7B8B"],
  recycledblack: ["#303030"],
  recyclednatural: ["#E0C38F"],
  techmaroon: ["#800000"],
  techpink: ["#FF6EB4"],
  blacksunryylw: ["#000000", "#EEDD82"],
  blackteamorng: ["#000000", "#FF4500"],
  blacktmorange: ["#000000", "#FF4500"],
  royalgranite: ["#4169E1", "#606060"],
  serenity: ["#9FA8DA"],
  swirlblack: ["#000000"],
  ancientfossilcordovanmarled: ["#907964", "#693D3D"],
  chambrayheather: ["#9ABAD9"],
  electricorange: ["#FF6A00"],
  granitewhite: ["#676767", "#FFFFFF"],
  keywestvividblue: ["#45ABA6", "#4C92C3"],
  whitecapwhite: ["#E7EBED", "#FFFFFF"],
  marbledsapphire: ["#8C92AC"],
  graphiteheatherforest: ["#708090", "#228B22"],
  graphiteheatherkelly: ["#708090", "#4CBB17"],
  solidnaturaltriblend: ["#EAE9E0"],
  charitypinktriblend: ["#E68FAC"],
  solidcarolinabluetriblend: ["#56A0D3"],
  spectrumspiraltiedye: ["#5B4FED"],
  heathercucumber: ["#66B2A9"],
  steelheathernavy: ["#B0C4DE", "#000080"],
  columbiablueheather: ["#B0E0E6"],
  champ: ["#E5B200"],
  energy: ["#FF5F5F"],
  orangecarbon: ["#FF4500", "#505050"],
  royalcarbon: ["#4169E1", "#505050"],
  dustypink: ["#D399AE"],
  nickelheatherblack: ["#72776B", "#000000"],
  naturallforest: ["#FAEBD7", "#228B22"],
  teamfir: ["#6E7783"],
  kellyredredwhitestripe: ["#4CBB17", "#FF0000", "#FF0000", "#FFFFFF"],
  armycamowhite: ["#4B5320", "#FFFFFF"],
  whitemaroonvegasgold: ["#FFFFFF", "#800000", "#C5B358"],
  whitemismatchflag: ["#FFFFFF", "#B22234", "#3C3B6E"],
  neonheatherblue: ["#4D4DFF"],
  tang: ["#D46A4C"],
  lightaqua: ["#64C2AF"],
  torontoraptors: ["#CE1141"],
  blackwhtgrph: ["#000000", "#FFFFFF", "#808080"],
  blackyellow: ["#000000", "#FFFF00"],
  blackblkcarbon: ["#000000", "#000000", "#505050"],
  blackblkgraphit: ["#000000", "#000000", "#1C1C1C"],
  blackcamouflauge: ["#000000", "#4B5320"],
  blackcarbon: ["#000000", "#505050"],
  blackcarbonhthr: ["#000000", "#707070"],
  blackdarkgreen: ["#000000", "#006400"],
  blackgreyhthr: ["#000000", "#B0B0B0"],
  blackiron: ["#000000", "#484848"],
  blackltgoldwhi: ["#000000", "#D4AF37", "#FFFFFF"],
  blackorangewhi: ["#000000", "#FF4500", "#FFFFFF"],
  blackrwdotwhi: ["#000000", "#FF0000", "#FFFFFF"],
  metallicgreen: ["#008000"],
  metallicpurple: ["#9F00C5"],
  elementsaguablf: ["#00FFFF"],
  blackfin: ["#000000"],
  blackfinltchr: ["#000000", "#D3D3D3"],
  twistedroyal: ["#6A5ACD"],
  greenlime: ["#32CD32", "#00FF00"],
  greylime: ["#808080", "#00FF00"],
  pinklime: ["#FFC0CB", "#00FF00"],
  grey: ["#808080"],
  red: ["#FF0000"],
  royalbluelime: ["#4169E1", "#00FF00"],
  hotpinkwhiteblack: ["#FF69B4", "#FFFFFF", "#000000"],
  brownleopard: ["#A52A2A"],
  pinkvintageheather: ["#FFC0CB", "#B0B0B0"],
  vintageheathervintagepurple: ["#B0B0B0", "#800080"],
  blacktitanium: ["#000000", "#878787"],
  navydarkolive: ["#000080", "#556B2F"],
  blackcarameldarkgrey: ["#000000", "#8B4513", "#A9A9A9"],
  arcticskytiedye: ["#ACE5EE"],
  cottoncandyswirl: ["#FFB6D9"],
  dustyolive: ["#6B8E23"],
  guava: ["#C08081"],
  iceblackout: ["#1F1F1F"],
  marinestripe: ["#70A8A0"],
  rouge: ["#B7485D"],
  bkblackcharcoal: ["#000000", "#36454F"],
  mgmossgreennavy: ["#4A5D23", "#000080"],
  nvnavymediumblue: ["#000080", "#0000CD"],
  burgundycharcoal: ["#800020", "#36454F"],
  o2fluorescentorange: ["#FF4500"],
  offluorescentorange: ["#FF4500"],
  lightorange: ["#FFA07A"],
  puttynavy: ["#EED5B7", "#000080"],
  blackdenimwhite: ["#1F1F1F", "#FFFFFF"],
  bluedenimwhite: ["#1560BD", "#FFFFFF"],
  navydenimwhite: ["#000080", "#FFFFFF"],
  brownblack: ["#964B00", "#000000"],
  naturalblackfloral: ["#FAEBD7", "#000000"],
  naturalbluefloral: ["#FAEBD7", "#0000FF"],
  naturalblueleaf: ["#FAEBD7", "#0000FF"],
  naturalflag: ["#FAEBD7"],
  naturalorangeleaf: ["#FAEBD7", "#FFA500"],
  athleticforest: ["#228B22"],
  navydenim: ["#000080"],
  blackdenimblack: ["#1F1F1F", "#000000"],
  navydenimnavy: ["#000080", "#000080"],
  vintageheathervintageburgundy: ["#B0B0B0", "#800020"],
  yelloworange: ["#FFFF00", "#FFA500"],
  lakeblue: ["#6C7B8B"],
  mossgreen: ["#4A5D23"],
  carameldarkgrey: ["#8B4513", "#A9A9A9"],
  brownkhaki: ["#964B00", "#F0E68C"],
  sprucekhaki: ["#A3C1AD", "#F0E68C"],
  bluedenim: ["#1560BD"],
  navydarkgreysilver: ["#000080", "#A9A9A9", "#C0C0C0"],
  navyvegasdarkgrey: ["#000080", "#C5B358", "#A9A9A9"],
  peachstone: ["#FFDAB9", "#8B4513"],
  ßblackwatchplaid: ["#000000", "#556B2F", "#000080"],
  blast: ["#FF4500"],
  blaze: ["#FF6A00"],
  funnelcake: ["#E9E4D4"],
  slushy: ["#79DEEC"],
  skyblueheather: ["#87CEEB"],
  blckinvsblstrp: ["#000000"],
  blckdkchrcoal: ["#000000", "#36454F"],
  heritagehunterplaid: ["#365C1A", "#965A3E"],
  royalstar: ["#4169E1"],
  scottishtartan: ["#003399", "#CC3333"],
  blackpinkprint: ["#000000", "#FFC0CB"],
  blkchrryshark: ["#5B4B49", "#686868"],
  blkflagtacular: ["#000000"],
  blkhthdgrht: ["#000000", "#696969"],
  blkhthorsoda: ["#000000", "#FA8072"],
  blkhthrblack: ["#000000", "#000000"],
  blkhthrcarbon: ["#000000", "#4A545C"],
  blkhthrltchr: ["#000000", "#778899"],
  blkinksnowhth: ["#000000", "#D3D3D3"],
  blkleopardblk: ["#000000", "#000000"],
  blkmelangeblk: ["#000000", "#000000"],
  blkmineralwash: ["#000000"],
  blktipltchrc: ["#000000", "#778899"],
  blkwhstrpwht: ["#000000", "#FFFFFF", "#FFFFFF"],
  blkblkblk: ["#000000", "#000000", "#000000"],
  blkblkcarbn: ["#000000", "#000000", "#4A545C"],
  blkblkch: ["#000000", "#000000", "#000000"],
  blkclred: ["#000000", "#FF0000"],
  blkcmpsgold: ["#000000", "#D4AF37"],
  blkdkchrcoal: ["#000000", "#36454F"],
  blkdkgryhthr: ["#000000", "#696969"],
  blkgrhtgrph: ["#000000", "#696969", "#696969"],
  blackmustard: ["#000000", "#FFDB58"],
  blkneonornge: ["#000000", "#FF4500"],
  blkneonyellow: ["#000000", "#FFFF00"],
  blkreddchrc: ["#000000", "#FF0000", "#36454F"],
  blksyldkch: ["#000000", "#FFFF00", "#696969"],
  blksftyyellow: ["#000000", "#FFFF00"],
  blkslvrlurex: ["#000000", "#C0C0C0"],
  blktruroyal: ["#000000", "#4169E1"],
  blkwhtbflchk: ["#000000", "#FFFFFF"],
  blkwhtblk: ["#000000", "#FFFFFF", "#000000"],
  blkwhtdchrc: ["#000000", "#FFFFFF", "#36454F"],
  blkwhtsmgry: ["#000000", "#FFFFFF", "#696969"],
  blktpmoelement: ["#000000"],
  blrnawhwwstr: ["#0000FF", "#FFFFFF", "#FFFFFF"],
  blubelldkdnm: ["#73C2FB", "#1560BD"],
  bluwhtbflchk: ["#0000FF", "#FFFFFF"],
  concretecamo: ["#8B8C7A", "#696969"],
  sea: ["#2E8B57"],
  raspberryheather: ["#B03060"],
  blackblackmod: ["#000000", "#000000"],
  darkgreendarkgreenmod: ["#006400", "#006400"],
  bluebell: ["#1C39BB"],
  bluechambray: ["#3A5894"],
  bluegreen: ["#0D98BA"],
  bluegreymaroon: ["#6699CC", "#800000"],
  bluegreynavy: ["#6699CC", "#000080"],
  bluegreypurple: ["#6699CC", "#800080"],
  tropicswirl: ["#00FFFF"],
  mochatriblend: ["#8B4513"],
  militarytriblend: ["#4B5320"],
  washedbananacream: ["#FFE135"],
  charcoalburgundy: ["#36454F", "#800020"],
  bluejerry: ["#42A5F5"],
  goldnavy: ["#FFD700", "#000080"],
  multiblue: ["#0000FF"],
  trifuchsia: ["#FF00FF"],
  trihuntergreen: ["#355E3B"],
  trimidnight: ["#191970"],
  oxfordmintheather: ["#6C848D", "#98FF98"],
  whiteheatherblackheather: ["#FFFFFF", "#696969"],
  mosaicblueheather: ["#B0E0E6"],
  blueplaid: ["#1E90FF"],
  blueprocess: ["#0000FF"],
  blueridge: ["#5D8AA8"],
  bluesage: ["#0073CF"],
  mellowyellow: ["#F8DE7E"],
  heathermellowyellow: ["#F8DE7E"],
  blueseersucker: ["#1E90FF"],
  bluetealbirnav: ["#1E90FF", "#008080", "#000080"],
  idmaroon: ["#800000"],
  brownsand: ["#8B4513", "#C2B280"],
  tealorange: ["#008080", "#FFA500"],
  blueblack: ["#0000FF", "#000000"],
  bluegray: ["#0000FF", "#808080"],
  bluelimegrn: ["#0000FF", "#32CD32"],
  blueorange: ["#0000FF", "#FFA500"],
  blueteal: ["#0000FF", "#008080"],
  vistagrey: ["#78866B"],
  safetyyellowgreengraphite: ["#ADFF2F", "#4A545C"],
  heathercarolinablue: ["#99BADD"],
  deepheatherred: ["#B0C4DE", "#FF0000"],
  forestlineembossed: ["#228B22"],
  irishkelly: ["#4CBB17"],
  maroonlineembossed: ["#800000"],
  purplelineembossed: ["#800080"],
  redlineembossed: ["#FF0000"],
  redredstriped: ["#FF0000", "#FF0000"],
  whitesilverstriped: ["#FFFFFF", "#C0C0C0"],
  royalroyaldigital: ["#4169E1", "#4169E1"],
  sandsanddigital: ["#C2B280", "#C2B280"],
  blueyellow: ["#0000FF", "#FFFF00"],
  blueecru: ["#0000FF", "#C2B280"],
  bluefinwhite: ["#1C1CF0", "#FFFFFF"],
  lime: ["#00FF00"],
  blackdarkgreysilver: ["#000000", "#A9A9A9", "#C0C0C0"],
  browncamo: ["#A52A2A", "#567544"],
  darkgreencamelbrown: ["#006400", "#C19A6B", "#964B00"],
  darkgreydarkgrey: ["#A9A9A9", "#A9A9A9"],
  greyburgundyblack: ["#808080", "#800020", "#000000"],
  aquatonic: ["#00FFFF"],
  blzbrbcntblz: ["#FF4500"],
  blzbrkupcntry: ["#FF4500"],
  limered: ["#00FF00", "#FF0000"],
  heatherwhite: ["#ADADD8", "#FFFFFF"],
  orangewhitegraphite: ["#FFA500", "#FFFFFF", "#1C1C1C"],
  poweryellowblackwhite: ["#F6DC12", "#000000", "#FFFFFF"],
  purplegoldwhite: ["#800080", "#DAA520", "#FFFFFF"],
  bottle: ["#006A4E"],
  bottlegrntan: ["#006A4E", "#D2B48C"],
  brcoralsnwhth: ["#FF6F61"],
  breakupcountry: ["#567544"],
  breezygrntrbln: ["#99C5C4"],
  powerbluepowerbluedigi: ["#0000FF", "#0000FF"],
  merlotheatherblackmesh: ["#800000", "#000000"],
  brightblue: ["#0000FF"],
  brightbluewhite: ["#0000FF", "#FFFFFF"],
  brightcobalt: ["#0047AB"],
  tealtealmesh: ["#008080", "#008080"],
  greyblackplaid: ["#808080", "#000000"],
  darkgreenheatherwhite: ["#006400", "#FFFFFF"],
  visualgreen: ["#8F9779"],
  peony: ["#E30B5D"],
  blackgoldheather: ["#000000", "#DAA520"],
  brightpoppy: ["#FF5A09"],
  forestspeck: ["#228B22"],
  graphitemineralwash: ["#696969"],
  navymineralwash: ["#000080"],
  greenstonestripe: ["#006400"],
  rainbowsorbet: ["#FF745A"],
  navyredlightgrey: ["#000080", "#FF0000", "#D3D3D3"],
  lightgreyyellowtrim: ["#D3D3D3", "#FFFF00"],
  navyorangetrim: ["#000080", "#FFA500"],
  techbluecharcoal: ["#5E9DC8", "#36454F"],
  topocharcoal: ["#778899", "#36454F"],
  darkoxford: ["#414A4C"],
  darkgreengoldwhite: ["#006400", "#DAA520", "#FFFFFF"],
  leafcharcoal: ["#6B8E23", "#36454F"],
  realtreextracharcoal: ["#567544", "#36454F"],
  militarygreenmelange: ["#4B5320"],
  bluesteel: ["#4682B4"],
  charcoalredwhitestripe: ["#36454F", "#FF0000", "#FFFFFF"],
  brightyellow: ["#FFFF00"],
  brilliantgreen: ["#00FF00"],
  heatherhunter: ["#629755"],
  redwhiteroyal: ["#FF0000", "#FFFFFF", "#4169E1"],
  whiteblackblack: ["#FFFFFF", "#000000", "#000000"],
  whitenavynavy: ["#FFFFFF", "#000080", "#000080"],
  heatherlightnavy: ["#708090"],
  armyolive: ["#4B5320"],
  birchblack: ["#DEB887", "#000000"],
  blackcharcoalblack: ["#000000", "#36454F", "#000000"],
  charcoalblackblack: ["#36454F", "#000000", "#000000"],
  biscuitblack: ["#FFE4C4", "#000000"],
  darkorangeblack: ["#FF8C00", "#000000"],
  lodenambergold: ["#6C7C7C", "#FFBF00"],
  brkupcntryblk: ["#567544", "#000000"],
  brkupcntrycho: ["#567544", "#D2691E"],
  brkupcntrykha: ["#567544", "#BDB76B"],
  brkupcntryora: ["#567544", "#FF4500"],
  brkupcntrywht: ["#567544", "#FFFFFF"],
  carboncolumbiablue: ["#36454F", "#9BDDFF"],
  carbonred: ["#36454F", "#FF0000"],
  carbonroyal: ["#36454F", "#4169E1"],
  carbonwhite: ["#36454F", "#FFFFFF"],
  taupeheather: ["#483C32"],
  desertrainbow: ["#EDC9AF"],
  blackblackdigisilver: ["#000000", "#000000", "#C0C0C0"],
  onyxfleckelectricblue: ["#353839", "#7DF9FF"],
  royaldigital: ["#4169E1"],
  oatmealheatherarmyheather: ["#F5DEB3", "#4B5320"],
  oatmealheathervintagenavyheather: ["#F5DEB3", "#000080"],
  oatmealheathersmokeheather: ["#F5DEB3", "#708090"],
  hazyblue: ["#BFDAE3"],
  realcoral: ["#FF4040"],
  techpurple: ["#800080"],
  brownduck_32: ["#964B00"],
  brownduck_34: ["#964B00"],
  brownduck_36: ["#964B00"],
  brownduck_38: ["#964B00"],
  brownduck_40: ["#964B00"],
  brownduck_42: ["#964B00"],
  brownduck_44: ["#964B00"],
  browntiedye: ["#964B00"],
  brownveganlthr: ["#964B00"],
  brownkhkbrwn: ["#964B00", "#BDB76B", "#964B00"],
  brtcoblthgrs: ["#1E90FF"],
  brtorngdkgry: ["#FF4500", "#A9A9A9"],
  brtorngereflc: ["#FF4500", "#C0C0C0"],
  brtwhtnvyblz: ["#FFFFFF", "#1C39BB"],
  brtwhtpmablk: ["#FFFFFF", "#000000"],
  breezygreen: ["#89CFF0"],
  brzygreentrbln: ["#89CFF0"],
  pinkpinkdigital: ["#FFC0CB", "#FFC0CB"],
  purplepurpledigital: ["#800080", "#800080"],
  bttmlndltchrc: ["#646040", "#D3D3D3"],
  bubblegum: ["#FFC1CC"],
  bubblegumpink: ["#FFC1CC"],
  buck: ["#62422B"],
  buffalocheck: ["#DC143C"],
  burghtholybl: ["#800020", "#808000"],
  burgbgholb: ["#800020", "#F0E68C", "#3A75C4"],
  burgundyhgrey: ["#800020", "#B0B0B0"],
  burgndyolyblu: ["#800020", "#3A75C4"],
  goldgraphite: ["#FFD700", "#808080"],
  darkheathergreywhite: ["#A9A9A9", "#FFFFFF"],
  bluepalmtree: ["#0000FF"],
  maroonheatherwhite: ["#800000", "#FFFFFF"],
  carbonprintroyal: ["#696969", "#4169E1"],
  vintagebirchvintageblack: ["#FFE4C4", "#000000"],
  burgundycarbon: ["#800020", "#696969"],
  burgundygray: ["#800020", "#808080"],
  burgundykhaki: ["#800020", "#F0E68C"],
  summerskyblue: ["#87CEEB"],
  burgundywhite: ["#800020", "#FFFFFF"],
  burgundygold: ["#800020", "#FFD700"],
  perfectpink: ["#FFC0CB"],
  heatherarctic: ["#ADD8E6"],
  graphitedigital: ["#808080"],
  blackvintagewhite: ["#000000", "#FAEBD7"],
  sportpurple: ["#800080"],
  burro: ["#7B3F00"],
  heathernatural: ["#DAB3A9"],
  cgold: ["#FFD700"],
  cgrey: ["#808080"],
  cabanared: ["#FF0033"],
  cabernet: ["#4B0082"],
  navyoxfordgrey: ["#000080", "#808080"],
  cactusblack: ["#5A4FCF", "#000000"],
  heatherarmy: ["#4B5320"],
  camelnavy: ["#C19A6B", "#000080"],
  powerpinkgraphiteheather: ["#FF69B4", "#808080"],
  greyheathernavy: ["#808080", "#000080"],
  seafoamgreen: ["#71EEB8"],
  camogreen: ["#78866B"],
  camogreenblk: ["#78866B", "#000000"],
  camogreenoliv: ["#78866B", "#808000"],
  camoolive: ["#78866B"],
  camorock: ["#78866B"],
  camosaddle: ["#78866B"],
  camoswirl: ["#78866B"],
  camoxtra: ["#78866B"],
  camoblkkhaki: ["#78866B", "#F0E68C", "#8FBC8F"],
  camoivory: ["#78866B", "#FFFFF0"],
  camouflage: ["#78866B"],
  camouflagegreen: ["#78866B", "#3B5323"],
  campusgldcrbn: ["#FFD700", "#000000"],
  campusgold: ["#FFD700"],
  campusorange: ["#FFA500"],
  campuspurple: ["#800080"],
  candyheartshthr: ["#D3A0A2"],
  redblueplaid: ["#FF0000", "#0000FF"],
  nightmare: ["#191970"],
  scarletttruenvywhite: ["#FF2400", "#003366", "#FFFFFF"],
  canered: ["#960018"],
  capriblue: ["#0B5583"],
  carameltf: ["#8A6E51"],
  caramelblack: ["#8A6E51", "#000000"],
  carbonporange: ["#303030", "#FF4500"],
  carbonpscarlet: ["#303030", "#FF2400"],
  lightlavenderwhite: ["#D2B4DE", "#FFFFFF"],
  dennuggets: ["#4B90D2"],
  greybuffalo: ["#848482"],
  fadedteal: ["#00CED1"],
  lavendersophiaplaid: ["#9C8AA5"],
  whiteheatherblack: ["#FFFFFF", "#8A8C8E"],
  shadowgrashabitatbrown: ["#395741", "#8B4513"],
  biscuittrueblue: ["#FFE4B5", "#0000CD"],
  realtreeexcapeblack: ["#8A795D", "#000000"],
  realtreefishinglightbluenavy: ["#87CEFA", "#000080"],
  realtreefishinglightbluewhite: ["#87CEFA", "#FFFFFF"],
  reddigitalcamocharcoal: ["#FF4500", "#36454F"],
  greencamostarsstripes: ["#008000", "#3C3B6E"],
  greygreycamo: ["#808080", "#3C3B6E"],
  heathergreystarsstripes: ["#8A8C8E", "#3C3B6E"],
  lodengreencamo: ["#556B2F", "#3C3B6E"],
  mossyoakbreakupcountrystarsstripes: ["#4E4D32", "#3C3B6E"],
  navynavytowhitefade: ["#000080", "#FFFFFF"],
  redredtowhitefade: ["#FF0000", "#FFFFFF"],
  arubabluebirch: ["#2B65EC", "#9F8170"],
  bluetealbirchnavy: ["#008080", "#9F8170", "#000080"],
  lightrosepink: ["#FF6B6B"],
  triberry: ["#8A2BE2"],
  heatherbone: ["#D3D3D3"],
  heatherblackforest: ["#000000", "#228B22"],
  heatherblackgold: ["#000000", "#FFD700"],
  heatherblacknavy: ["#000000", "#000080"],
  carbonhthblk: ["#303030", "#000000"],
  carbonhthrblk: ["#303030", "#000000"],
  carbonhthrblack: ["#303030", "#000000"],
  carbonhthrcoral: ["#303030", "#FF6F61"],
  carbonhthrwhite: ["#303030", "#FFFFFF"],
  carbonpblack: ["#303030", "#000000"],
  carbonpwhite: ["#303030", "#FFFFFF"],
  carbonpnavy: ["#303030", "#000080"],
  carbonproyal: ["#303030", "#0000CD"],
  carbonacdgrn: ["#303030", "#008000"],
  carboncarbon: ["#303030", "#303030"],
  carbonclnvht: ["#303030", "#3C3B6E"],
  carboncrbnhth: ["#303030", "#808080"],
  carbonolyred: ["#303030", "#FF4500"],
  deepbluehthdeepblue: ["#00008B", "#00008B"],
  carbonaqua: ["#303030", "#00FFFF"],
  carbonblack: ["#303030", "#000000"],
  carbonblkgraphi: ["#303030", "#808080"],
  carbonblkscarle: ["#303030", "#FF2400"],
  carbongold: ["#303030", "#FFD700"],
  cardblcktrblnd: ["#303030", "#808080"],
  losangeleslakers: ["#4A2583"],
  greyacidwash: ["#848482"],
  highlighterpink: ["#FF69B4"],
  greypigment: ["#848482"],
  blackwhitepoweryellow: ["#000000", "#FFFFFF", "#F4D03F"],
  darkgreenwhitesunriseheatherblack: ["#006400", "#FFFFFF", "#FF6F61"],
  darkgreyheatherblack: ["#A9A9A9", "#000000"],
  royalsteel: ["#4169E1", "#738E9D"],
  patriot: ["#C8102E"],
  scarletblackwhite: ["#FF2400", "#000000", "#FFFFFF"],
  vintagepurplewhite: ["#A23DAD", "#FFFFFF"],
  greymistwater: ["#E0E6F8"],
  whitealuminumcamo: ["#FFFFFF", "#848482"],
  vintageheathernavynavy: ["#A9A9A9", "#000080"],
  whitegreymesh: ["#FFFFFF", "#B0C4DE"],
  cardinaltriblnd: ["#8A0808"],
  heathercitygreen: ["#3CB371"],
  khakiburgundy: ["#F0E68C", "#800020"],
  khakicoffee: ["#F0E68C", "#6F4E37"],
  cardinantan: ["#8A0808", "#D2B48C"],
  chocolatechipbirch: ["#7B3F00", "#9F8170"],
  cobaltbluegrey: ["#0047AB", "#808080"],
  heathergreyambergold: ["#8A8C8E", "#FFBF00"],
  heathergreybircharmymoss: ["#8A8C8E", "#9F8170", "#4B5320"],
  lodengreenblack: ["#556B2F", "#000000"],
  ombrebluenavy: ["#1E90FF", "#000080"],
  kryptekinfernoblaze: ["#FF4500", "#FF681D"],
  kryptektyphonneonorange: ["#808080", "#FFA500"],
  whitecolumbiabluenavytri: ["#FFFFFF", "#98B4D4", "#000080"],
  whitecolumbiablueredtri: ["#FFFFFF", "#98B4D4", "#FF0000"],
  whiteroyalredtri: ["#FFFFFF", "#4169E1", "#FF0000"],
  olivenavy: ["#808000", "#000080"],
  sunriseheather: ["#FF6F61"],
  woodgrain: ["#DEB887"],
  caribbluhthrox: ["#00BFFF", "#8A4500"],
  caribblhthrox: ["#00BFFF", "#8A4500"],
  caribblueivory: ["#00BFFF", "#FFFFF0"],
  caribbluehthr: ["#00BFFF", "#A9A9A9"],
  sanddigital: ["#D2B48C"],
  bluewoodland: ["#000080"],
  aquastone: ["#00FFFF", "#808080"],
  carribeanblue: ["#1E90FF"],
  caribbeanbluesh: ["#1E90FF"],
  miamiheat: ["#98002E"],
  milwaukeebucks: ["#00471B"],
  vintageforestvintagegrey: ["#254117", "#848482"],
  heathercharcoalcharcoalstripe: ["#8A8C8E", "#36454F"],
  carnival: ["#FF6F61"],
  wildspider: ["#FF681D"],
  navybluewhitethreecarbonheather: ["#000080", "#FFFFFF", "#738E9D"],
  whiteblackcharcoal: ["#FFFFFF", "#000000", "#36454F"],
  whitenavycharcoal: ["#FFFFFF", "#000080", "#36454F"],
  whiteredcharcoal: ["#FFFFFF", "#FF0000", "#36454F"],
  whiteroyalcharcoal: ["#FFFFFF", "#4169E1", "#36454F"],
  columbiablueblack: ["#98B4D4", "#000000"],
  navyathleticheather: ["#000080", "#A9A9A9"],
  nimbusgreycitygrey: ["#B4CDCD", "#A9A9A9"],
  sequoia: ["#8B4513"],
  graphiteheatherlightgold: ["#808080", "#FFD700"],
  navyheatherwhite: ["#000080", "#FFFFFF"],
  whiteprintcarbon: ["#FFFFFF", "#303030"],
  whitecamo: ["#FFFFFF"],
  realtreeallpurpose: ["#8b4513"],
  realtreeapgreen: ["#006400"],
  realtreehardwoodhd: ["#8b4513"],
  dustyoliveblack: ["#556b2f", "#000000"],
  whitespot: ["#ffffff"],
  limepink: ["#00ff00", "#ffc0cb"],
  naturalpurple: ["#d3d3d3", "#800080"],
  whitecharcoalfleck: ["#ffffff", "#36454f"],
  skyeblue: ["#87ceeb"],
  limelime: ["#00ff00"],
  cat: ["#ffe4e1"],
  cdswatch: ["#8a2be2"],
  cedargreen: ["#556b2f"],
  cedargreenhthr: ["#556b2f"],
  cedargrnblack: ["#556b2f", "#000000"],
  pinkwoodland: ["#ffc0cb"],
  gk: ["#8a2be2"],
  greentan: ["#008000"],
  khakibrown: ["#f0e68c"],
  khakibrownstitch: ["#f0e68c"],
  frenchgrey: ["#bdbdbd"],
  bluefrost: ["#87ceeb"],
  navygreen: ["#000080", "#008000"],
  orangetea: ["#ff4500"],
  rosin: ["#a94064"],
  nightshade: ["#000080"],
  blazeorange: ["#ff6103"],
  navywhitenavy: ["#000080", "#ffffff", "#000080"],
  pinkwhitepink: ["#ffc0cb", "#ffffff", "#ffc0cb"],
  redwhitered: ["#ff0000", "#ffffff", "#ff0000"],
  oak: ["#8a2be2"],
  olivenight: ["#556b2f"],
  heatherdarkgreen: ["#808080"],
  poseidonblack: ["#336699", "#000000"],
  veilwideland: ["#cdcdcd", "#556b2f"],
  midnightstripe: ["#003366", "#ff4500"],
  celesteheather: ["#87ceeb"],
  ceramicblue: ["#1e90ff"],
  greyblackred: ["#808080", "#000000", "#ff0000"],
  chalkheather: ["#d3d3d3"],
  mauvelouswhite: ["#ef98aa", "#ffffff"],
  collegiategreenmelange: ["#006400"],
  chambrayaloha: ["#4682b4"],
  chamoisyellow: ["#ffffc0"],
  charclsnowhth: ["#36454f"],
  charclblkrope: ["#36454f", "#000000"],
  charcldkchrcl: ["#36454f", "#333333"],
  charclhthrgry: ["#36454f", "#808080"],
  whitevistagrey: ["#ffffff", "#7e888c"],
  heathercanvasred: ["#b0c4de", "#ff4500"],
  pine: ["#01796f"],
  lemonzest: ["#fff44f"],
  armytiedye: ["#4b5320"],
  redslatewhite: ["#ff0000", "#808080", "#ffffff"],
  greyalohatriblend: ["#808080"],
  electricpink: ["#ff3388"],
  purplewhitegraphite: ["#800080", "#ffffff", "#808080"],
  redwhitegraphite: ["#ff0000", "#ffffff", "#808080"],
  steelforest: ["#738e9d", "#006400"],
  steelmaroon: ["#738e9d", "#800000"],
  steelnavy: ["#738e9d", "#000080"],
  steelpurple: ["#738e9d", "#800080"],
  ruby: ["#e0115f"],
  twilightblack: ["#483d8b"],
  royalroyalcamo: ["#4169e1"],
  oreganoheather: ["#556b2f"],
  silverblackcamo: ["#c0c0c0", "#000000"],
  poppyredheather: ["#ff2400"],
  bodyblushheather: ["#ef98aa"],
  purplerainheather: ["#9f4d95"],
  reddreddigital: ["#ff0000", "#ff2400"],
  darkgreenlightgoldwhite: ["#006400", "#ffd700", "#ffffff"],
  navyoxfordheatherwhite: ["#000080", "#a9a9a9", "#ffffff"],
  garnetblackbuffaloplaid: ["#6b8e23", "#000000"],
  hydrangea: ["#97D4F1"],
  smokeheather: ["#848482"],
  heathercharcoalfatigue: ["#8a8c8e", "#556b2f"],
  charcoalrealtreextra: ["#36454f"],
  coraldream: ["#ff6f61"],
  darkmaroonblackwhite: ["#800000", "#000000", "#ffffff"],
  forestblackwhite: ["#008000", "#000000", "#ffffff"],
  purplerushsleeves: ["#800080"],
  tahitibluesleeves: ["#00a5c6"],
  vintageblacksleeves: ["#000000"],
  vintagepurplesleeves: ["#a23dad"],
  snowheatherred: ["#fffafa"],
  blackturquoise: ["#000000", "#40e0d0"],
  militarygreenblack: ["#556b2f", "#000000"],
  naturalcarolinablue: ["#d3d3d3", "#56a0d3"],
  naturalchocolate: ["#d3d3d3", "#d2691e"],
  naturalgold: ["#d3d3d3", "#ffd700"],
  naturalkelly: ["#d3d3d3", "#008000"],
  naturallavender: ["#d3d3d3", "#b57edc"],
  bananakeylime: ["#ffd700", "#adff2f"],
  pinksilver: ["#ffc0cb"],
  ballerinaballerinawhitestripewhite: ["#ff69b4"],
  icbluebrownwhite: ["#b0e0e6", "#a52a2a", "#ffffff"],
  cardinalgold: ["#b22222", "#ffd700"],
  heatherblackheathergrey: ["#808080", "#d3d3d3"],
  heathercardinalheathergrey: ["#a52a2a", "#d3d3d3"],
  seabreeze: ["#00ced1"],
  burntorangeburntorange: ["#ff4500"],
  odgreen: ["#556b2f"],
  navylineembossed: ["#000080"],
  greenoxidemelange: ["#556b2f"],
  zenrainbow: [
    "#ff0000",
    "#ff4500",
    "#ff8c00",
    "#ffd700",
    "#008000",
    "#0000ff",
    "#4b0082",
    "#ee82ee",
  ],
  royallineembossed: ["#4169e1"],
  blackathleticheather: ["#000000", "#a9a9a9"],
  collegiateroyalwhite: ["#4169e1"],
  leadblack: ["#000000", "#a9a9a9"],
  collegiatenavytechink: ["#000080", "#5b92e5"],
  whitesilverblend: ["#ffffff", "#c0c0c0"],
  graphiteforest: ["#808080", "#228b22"],
  graphitemaroon: ["#808080", "#800000"],
  graphitepurple: ["#808080", "#800080"],
  charcoalfleck: ["#36454f"],
  powerblueblack: ["#001f3f", "#000000"],
  citygreyheather: ["#808080"],
  stonegreen: ["#556b2f"],
  heathercolorado: ["#808080"],
  heatherredmexico: ["#808080", "#ff0000"],
  heathertexasflag: ["#808080", "#3c3b6e", "#d66030", "#ffffff"],
  heatherusaflag: ["#808080", "#b22234", "#3c3b6e", "#ffffff"],
  hotpinkrainbow: [
    "#ff69b4",
    "#ff4500",
    "#ffd700",
    "#008000",
    "#0000ff",
    "#4b0082",
  ],
  navyusaflag: ["#000080", "#b22234", "#3c3b6e", "#ffffff"],
  purplerainbow: [
    "#800080",
    "#ff4500",
    "#ffd700",
    "#008000",
    "#0000ff",
    "#4b0082",
  ],
  darknocturnal: ["#808080"],
  delta: ["#808080"],
  charcoalgry: ["#36454f"],
  charcoalgryhth: ["#36454f"],
  heritageblackplaid: ["#000000"],
  kingstonplaid: ["#4169e1"],
  navywhiteblack: ["#000080", "#ffffff", "#000000"],
  redwhitesilvergrey: ["#ff0000", "#ffffff", "#c0c0c0"],
  paradiseheather: ["#00ced1"],
  limewhiteblack: ["#00ff00", "#ffffff", "#000000"],
  truetimberkanati: ["#006400"],
  athleticgreycarbon: ["#808080", "#2f4f4f"],
  hunterheather: ["#556b2f"],
  charcoalhthr: ["#36454f"],
  orangegreywhite: ["#ff4500", "#808080", "#ffffff"],
  bluegreyblack: ["#808080", "#000000"],
  deephthrblack: ["#696969", "#000000"],
  whitetrroyal: ["#ffffff", "#4169e1"],
  charcoalftg: ["#36454F", "#7F7F7F"],
  charcoalgray: ["#36454F", "#808080"],
  purplepurple: ["#800080", "#800080"],
  charcoalnaturl: ["#36454F", "#FAEBD7"],
  charcoaloyster: ["#36454F", "#C2B280"],
  charcoalwht: ["#36454F", "#FFFFFF"],
  hotpinktonalblend: ["#FF69B4"],
  charcoaljblack: ["#36454F", "#000000"],
  charcolblkwht: ["#36454F", "#FFFFFF"],
  scarletheatherwhite: ["#FF2400", "#FFFFFF"],
  whitecarbonheather: ["#FFFFFF", "#696969"],
  whitecharcoalstripe: ["#FFFFFF", "#36454F"],
  chartypnkcrbn: ["#E75480", "#696969"],
  chcrlgrhtblk: ["#B2BEB5", "#000000"],
  cherrycola: ["#6A3735"],
  cherrytomato: ["#FF6347"],
  whiterasta: ["#FFFFFF", "#8A2BE2"],
  chiliblgrskh: ["#C21807", "#7D92A1", "#BD9B60"],
  chilibrown: ["#C21807", "#A52A2A"],
  powerbluegraphite: ["#879ADC", "#808080"],
  chino: ["#CEB180"],
  chocbrowntan: ["#704214", "#D2B48C"],
  graphiteb: ["#808080"],
  chocolatecbirch: ["#7B3F00", "#FFE4C4"],
  chocolatechip: ["#7B3F00"],
  chocolateheathr: ["#704214"],
  chrclblackslub: ["#363636"],
  chrclblkblk: ["#36454F", "#000000", "#000000"],
  charcaribbean: ["#36454F", "#00CCCC"],
  charcoalcoral: ["#36454F", "#FF6F61"],
  charhotpink: ["#36454F", "#FF69B4"],
  chrcllbroys: ["#36454F", "#E9DAB5", "#C2B280"],
  charmustard: ["#36454F", "#FFDB58"],
  chrclneonblue: ["#36454F", "#0000FF"],
  chrclneongren: ["#36454F", "#39FF14"],
  chrclneonorng: ["#36454F", "#FF4500"],
  chrclneonpink: ["#36454F", "#FF6EC7"],
  charneonyellow: ["#36454F", "#FFFF00"],
  charpalepink: ["#36454F", "#FFD1DC"],
  charcoalred: ["#36454F", "#FF0000"],
  charcoalroyal: ["#36454F", "#0000FF"],
  charcoalseafoam: ["#36454F", "#71EEB8"],
  chrcoalblkslub: ["#36454F"],
  chrcoalhthblk: ["#36454F", "#000000"],
  chrcolblkblk: ["#36454F", "#000000", "#000000"],
  charcoalorangec: ["#36454F", "#FFA500"],
  cielblue: ["#77B5FE"],
  cilantro: ["#667E2A"],
  cilntrostnkh: ["#667E2A", "#A8A490", "#BD9B60"],
  cinder: ["#0E0E18"],
  cindersltgrey: ["#0E0E18", "#A0A0A0"],
  cinnamon: ["#D26911"],
  citynights: ["#191970"],
  clnvyhtstprt: ["#0F4D92"],
  clascnavycrbn: ["#0F4D92", "#696969"],
  classcnavycrbn: ["#0F4D92", "#696969"],
  classcredcrbn: ["#990000", "#696969"],
  tristeelblue: ["#4682B4"],
  trikelly: ["#4CBB17"],
  trimaroon: ["#800000"],
  classicdenim: ["#1560BD"],
  leafgreen: ["#228B22"],
  lightavioblue: ["#6495ED"],
  blackblackwhiteprint: ["#000000", "#000000", "#FFFFFF"],
  graphitegraphiteblackprint: ["#808080", "#808080", "#000000"],
  limelimeblackprint: ["#00FF00", "#00FF00", "#000000"],
  royalorangewhite: ["#000080", "#FFA500", "#FFFFFF"],
  royalsilvergreywhite: ["#000080", "#C0C0C0", "#FFFFFF"],
  royalwhitesilvergrey: ["#000080", "#FFFFFF", "#C0C0C0"],
  classicnavyhth: ["#0F4D92"],
  classicrayon: ["#0F4D92"],
  royalfloral: ["#000080"],
  sagepolkadots: ["#9CBA7F"],
  sherbetstripes: ["#FBA0E3"],
  sunsetyellowpolkadots: ["#FCC200"],
  whitefloral: ["#FFFFFF"],
  oceanberry: ["#61B2E4"],
  lavenderpink: ["#B57EDC", "#FFC0CB"],
  mauvelousnatural: ["#EF98AA", "#FAEBD7"],
  naturaltitanium: ["#FAEBD7", "#878681"],
  blackredwhitedot: ["#000000", "#FF0000", "#FFFFFF"],
  classicredblk: ["#990000", "#000000"],
  clayred: ["#E21B1B"],
  clear: ["#F5F5F5"],
  clearblue: ["#0000FF"],
  clnmintpprhth: ["#00FF7F"],
  cloudblack: ["#A9A9A9"],
  corecamo: ["#586E6A"],
  cloudbluecrem: ["#87CEFA", "#F5FFFA"],
  cloudpurple: ["#9678B6"],
  clsnvclnvht: ["#000080"],
  clsnvyhtcrbn: ["#000080", "#696969"],
  clscnvyhtcrb: ["#000080", "#696969"],
  clscnvyclsnv: ["#000080"],
  dynastygreenstripe: ["#225C2B"],
  mediumbluecharcoal: ["#0000CD", "#36454F"],
  huntergreencharcoal: ["#355E3B", "#36454F"],
  kryptekneptuneblack: ["#5C8D87", "#000000"],
  kryptektyphonneonpink: ["#3D403D", "#FF6EC7"],
  coast: ["#6495ED"],
  slategreyheather: ["#708090"],
  whitecirrusgrey: ["#FFFFFF", "#C0C2CE"],
  cocoa: ["#D2691E"],
  coffee: ["#6F4E37"],
  shark: ["#43464B"],
  colblucarbonh: ["#3A75C4", "#696969"],
  colblue: ["#3A75C4"],
  colbluenavwhi: ["#3A75C4", "#000080", "#FFFFFF"],
  kellydigital: ["#4CBB17"],
  safetyyellowdigital: ["#FFED00"],
  colmbiabluhthr: ["#64B4FF"],
  colonyblue: ["#6E93B4"],
  columbiablunavy: ["#64B4FF", "#000080"],
  columbluewht: ["#64B4FF", "#FFFFFF"],
  columbbluewht: ["#64B4FF", "#FFFFFF"],
  heathersoftcream: ["#F5F5DC"],
  columbiabluhth: ["#64B4FF"],
  columbiabluewhi: ["#64B4FF", "#FFFFFF"],
  columbianavy: ["#1C3050"],
  columbiablawhi: ["#64B4FF", "#000000", "#FFFFFF"],
  concretebrown: ["#A9A491", "#A52A2A"],
  confluencelch: ["#707070", "#D3D3D3"],
  kellygraphite: ["#4CBB17", "#1C1C1C"],
  metallicsilverwhite: ["#C0C0C0", "#FFFFFF"],
  coolgreen: ["#00FF00"],
  teardrop: ["#00FFFF"],
  seafoamtriblend: ["#71EEB8"],
  currant: ["#9A0339"],
  bluecharcoalmicrocheck: ["#0000FF", "#36454F"],
  envysleevespremiumheatherbody: ["#50C878", "#D3D3D3"],
  goldtiedye: ["#FFD700"],
  coralreef: ["#FF6F61"],
  coralivory: ["#FF6F61", "#FFFFF0"],
  core365: ["#DABDAB"],
  cornflower: ["#6495ED"],
  cosmicblue: ["#2E2EFE"],
  cosmicpink: ["#FF6EC7"],
  cosmogray: ["#E1E4E8", "#808080"],
  ginger: ["#A06500"],
  whiteheliconia: ["#FFFFFF", "#DF73FF"],
  blacksteel: ["#000000", "#484848"],
  gridironsilver: ["#8D9093"],
  gtgold: ["#D4AF37"],
  neonlime: ["#39FF14"],
  naturalwhite: ["#FAEBD7", "#FFFFFF"],
  naturalyellow: ["#FAEBD7", "#FFFF00"],
  fatiguekhaki: ["#808000", "#F0E68C"],
  oceansage: ["#1A2421", "#9F8170"],
  crbnblkhblk: ["#555555", "#000000", "#000000"],
  crbnorngsoda: ["#555555", "#FA5B3D"],
  crdblkblktrb: ["#3D3D3D", "#000000"],
  creamsolid: ["#FFFDD0"],
  creamblue: ["#FFFDD0", "#0000FF"],
  creamfleck: ["#FFFDD0"],
  creamtriblend: ["#FFFDD0"],
  creamblcktrib: ["#FFFDD0", "#000000"],
  creamcrmlbrw: ["#FFFDD0", "#8B4513"],
  creamnavytrib: ["#FFFDD0", "#000080"],
  royalgraphiteheather: ["#4169E1", "#708090"],
  placidblue: ["#ADD8E6"],
  tiedyeaquablue: ["#00FFFF"],
  pigmentamber: ["#FFBF00"],
  pigmentsage: ["#9CBA7F"],
  crimsonltchar: ["#DC143C", "#D3D3D3"],
  crmcrdblktrb: ["#FFFDD0", "#3D3D3D"],
  crmlbrwncream: ["#8B4513", "#FFFDD0"],
  crownraspberry: ["#B3446C"],
  crusgysprkprt: ["#696969"],
  crystalnavy: ["#000080"],
  crystalsilver: ["#C0C0C0"],
  ct102: ["#DABDAB"],
  washednavy: ["#000080"],
  acidorangegreytwo: ["#FF4500", "#BEBEBE"],
  heatherboneheatherarmy: ["#8B7355", "#4B5320"],
  cypress: ["#4F7942"],
  cypresscamo: ["#4F7942"],
  cypressbrown: ["#4F7942", "#A52A2A"],
  dchdgrnblk: ["#3D3D3D", "#006400", "#000000"],
  darkgreenathh: ["#006400"],
  dgreengoldwhi: ["#006400", "#FFD700", "#FFFFFF"],
  dgrnsldgrn: ["#006400", "#2E8B57", "#006400"],
  dnvtrydch: ["#000080", "#2E8B57", "#3D3D3D"],
  navylightgoldwhite: ["#000080", "#FFD700", "#FFFFFF"],
  crimsonfielddayplaid: ["#DC143C"],
  sorbet: ["#FF6EC7"],
  sunrise: ["#FFD700"],
  greyscale: ["#808080"],
  deepknitblack: ["#000000"],
  lynx: ["#858582"],
  blackdigitalcamocharcoal: ["#000000", "#36454F"],
  darkcamo: ["#4B5320"],
  collegiatered: ["#800000"],
  teampowerredwhite: ["#B22222", "#FFFFFF"],
  trueblueheathergreythree: ["#0000FF", "#BEBEBE"],
  blackgreytwo: ["#000000", "#BEBEBE"],
  teamnavybluegreytwo: ["#000080", "#BEBEBE"],
  heathernavymidnight: ["#000080", "#191970"],
  frostygarnet: ["#DC143C"],
  collegiatenavyheather: ["#363839"],
  teamgreenmelange: ["#4CBB17"],
  spiderpurple: ["#800080"],
  independence: ["#4A5D23"],
  midgreyheather: ["#696969"],
  hiresaquawhite: ["#00FFFF", "#FFFFFF"],
  blackmelangemidgreymelange: ["#000000", "#696969"],
  collegiateroyalmelangemidgreymelange: ["#4169E1", "#696969"],
  teamgreenmelangemidgreymelange: ["#4CBB17", "#696969"],
  mediumgreyheatherblackmidgrey: ["#696969", "#000000", "#696969"],
  blackgreythreegreyfive: ["#000000", "#8B8989", "#D3D3D3"],
  collegiategold: ["#FFB81C"],
  greytwoheathercollegiatenavyheather: ["#8B8989", "#363839"],
  oxfordblackcharcoalheather: ["#6C6462", "#000000", "#36454F"],
  naturalgreenwoodlandorange: ["#FFF5E1", "#556B2F", "#FFA500"],
  navywhitestripe: ["#000080", "#FFFFFF"],
  raspberrywhitedot: ["#E30B5D", "#FFFFFF"],
  titaniumnaturalstripe: ["#878681", "#FFF5E1"],
  granitenaturalbuffaloplaid: ["#676767", "#FFF5E1"],
  stormcloudprint: ["#4F666A"],
  tiedyeblack: ["#000000"],
  pigmentmaroon: ["#B03060"],
  pigmentmint: ["#98FF98"],
  darkgray: ["#A9A9A9"],
  whiteteal: ["#FFFFFF", "#008080"],
  darkgrayblck: ["#A9A9A9", "#000000"],
  solidteampurpletriblend: ["#800080"],
  royalbuffalo: ["#4169E1"],
  ecoivoryseasidestripe: ["#FDF5E6"],
  vintagemaroon: ["#B03060"],
  forestforesttonalblend: ["#228B22", "#228B22"],
  solidred: ["#FF0000"],
  greystripe: ["#B0C4DE"],
  cowboycamo: ["#BC8F8F"],
  cairo: ["#C4A484"],
  graphiteheathernavy: ["#708090", "#000080"],
  graphiteheatherorange: ["#708090", "#FFA500"],
  tiedyenavy: ["#000080"],
  preparedfordye: ["#FFFFFF"],
  goldenwheatheather: ["#DAA520"],
  fisherherringbone: ["#4C5354"],
  bluetiedye: ["#0000FF"],
  heatherblacktruffle: ["#C0C0C0", "#614051"],
  heathergreylapis: ["#C0C0C0", "#26619C"],
  darkgreengray: ["#006400", "#808080"],
  espressotriblend: ["#5E2605"],
  solidgoldtriblend: ["#FFD700"],
  ashwhitestripe: ["#B2BEB5", "#FFFFFF"],
  graphitegraphitetonalblend: ["#708090", "#708090"],
  darkgreenwht: ["#006400", "#FFFFFF"],
  darkgreengreyh: ["#006400", "#B0C4DE"],
  aubergine: ["#3D0734"],
  azure: ["#007FFF"],
  blendedwhitevintagegreentitanium: ["#FFFFFF", "#7F9A65", "#878681"],
  naturalmidnightnavy: ["#FFF5E1", "#191970"],
  kryptektyphoncharcoal: ["#1C1C1C", "#36454F"],
  realtreeedgetan: ["#654321", "#D2B48C"],
  rosegold: ["#B76E79"],
  yellowblack: ["#FFFF00", "#000000"],
  mossyoakbreakupamericanflag: ["#3C341F", "#B22234"],
  darkgreyheathr: ["#A9A9A9"],
  carbonheatherred: ["#696969", "#FF0000"],
  darkgryheather: ["#A9A9A9"],
  darkgryblack: ["#A9A9A9", "#000000"],
  smoketriblendbuffalo: ["#738276"],
  blackleopardtriblend: ["#000000"],
  camotriblend: ["#78866B"],
  blackpowerblue: ["#000000", "#0000FF"],
  blackpowerpink: ["#000000", "#FFC0CB"],
  blackpoweryellow: ["#000000", "#FFFF00"],
  royalwhitegraphite: ["#4169E1", "#FFFFFF", "#708090"],
  deepheathernavy: ["#B0C4DE", "#000080"],
  darkred: ["#8B0000"],
  darknavysmokeroyalblue: ["#000080", "#708690", "#4169E1"],
  coolroyalheather: ["#4169E1"],
  rustspeck: ["#B7410E"],
  peppermaroon: ["#734F3B", "#800000"],
  darkheathergry: ["#A9A9A9"],
  darkhthrgray: ["#A9A9A9"],
  triash: ["#A0AAB5"],
  steelgraphite: ["#B0C4DE", "#708090"],
  darkmountain: ["#2C3539"],
  greytwoheatherblackheather: ["#8B8989", "#000000"],
  whitecanvasred: ["#FFFFFF", "#FF0000"],
  khakiplaid: ["#F0E68C"],
  oxfordgreycharcoalheather: ["#6C6462", "#36454F"],
  simplyaquaheather: ["#00FFFF"],
  militarygreencream: ["#4B5320", "#FFFDD0"],
  lilacbirch: ["#C8A2C8", "#E8D0A9"],
  palebananabirch: ["#FFE135", "#E8D0A9"],
  patinagreenbirch: ["#669999", "#E8D0A9"],
  smokebluealuminum: ["#738276", "#A9ACB6"],
  sprucebirchlightnavy: ["#A2AA52", "#E8D0A9", "#000080"],
  sunkissedpeachbirch: ["#FFCBA4", "#E8D0A9"],
  tanlodenbrown: ["#D2B48C", "#6C541E", "#964B00"],
  whitebluehawaiinpaleorange: ["#FFFFFF", "#00FFFF", "#FFDAB9"],
  whitecolumbiabluebrown: ["#FFFFFF", "#9BDDFF", "#964B00"],
  blackcharcoalsplit: ["#000000", "#36454F"],
  blackwhitecontrast: ["#000000", "#FFFFFF"],
  blackwhitesplit: ["#000000", "#FFFFFF"],
  blackwhiteredtri: ["#000000", "#FFFFFF", "#FF0000"],
  collegiateroyalheather: ["#4169E1"],
  collegiatenavyblack: ["#000080", "#000000"],
  luckybluewhite: ["#1E90FF", "#FFFFFF"],
  teamcollegiategoldblack: ["#FFD700", "#000000"],
  greythreeheathercarbon: ["#8B8989", "#4B4E78"],
  greyfour: ["#838484"],
  mystique: ["#FF00FF"],
  zerog: ["#E5E5E5"],
  amazonblack: ["#3B7A57", "#000000"],
  teampowerred: ["#FF0000"],
  darknavyhthr: ["#000080"],
  whitegreythree: ["#FFFFFF", "#8B8989"],
  darkorchid: ["#9932CC"],
  whitegreyblack: ["#FFFFFF", "#808080", "#000000"],
  maroonheathermaroon: ["#800000", "#800000"],
  navyheathernavy: ["#000080", "#000080"],
  blackcheetah: ["#000000"],
  blackcheckerstrap: ["#000000"],
  cheetah: ["#BD8D46"],
  polkadot: ["#DDDDDD"],
  southwest: ["#3CB371"],
  pigmentclay: ["#B66A50"],
  darksapphire: ["#082567"],
  vintageheathervintageredwhite: ["#808080", "#FF0000", "#FFFFFF"],
  washedgrey: ["#A9A9A9"],
  graphiteheatherroyal: ["#808080", "#4169E1"],
  graphiteheatherscarlet: ["#808080", "#FF2400"],
  graphiteheatherwhite: ["#808080", "#FFFFFF"],
  midnightnavynatural: ["#191970", "#FAEBD7"],
  greywater: ["#B0C4DE"],
  whiteaquacamo: ["#FFFFFF", "#00FFFF"],
  aluminumnavy: ["#A9ACB6", "#000080"],
  heatherdarkgreyblack: ["#A9A9A9", "#000000"],
  heatherlightgreyblack: ["#D3D3D3", "#000000"],
  purplelavender: ["#800080", "#E6E6FA"],
  pacificyellow: ["#FFEF00"],
  pastelripple: ["#FDE5E5"],
  daylghtbluwht: ["#87CEFA", "#FFFFFF"],
  daylightblue: ["#87CEFA"],
  deckblue: ["#507FFF"],
  greywhitegingham: ["#808080", "#FFFFFF"],
  dustyroseheatherwhite: ["#856363", "#FFFFFF"],
  passionpinkstripe: ["#FF69B4"],
  quarry: ["#A7A9AC"],
  pepperroyal: ["#3D4252", "#4169E1"],
  pepperblack: ["#3D4252", "#000000"],
  navypepper: ["#000080"],
  turqberry: ["#40E0D0"],
  mauibluesilver: ["#00BFFF", "#C0C0C0"],
  redsilver: ["#FF0000", "#C0C0C0"],
  goldentriblend: ["#DAA520"],
  outdoorcamo: ["#4B5320"],
  ballerinamauvelousstripe: ["#FF9EB6", "#FFB3E6"],
  ballerinamauvelousballerinamauvelousstripe: [
    "#FF9EB6",
    "#FFB3E6",
    "#FF9EB6",
    "#FFB3E6",
  ],
  bspetrolbluenavy: ["#5E9DC8", "#000080"],
  navykhakistripe: ["#000080", "#F0E68C"],
  navyredstripe: ["#000080", "#FF0000"],
  whiteblueplaid: ["#FFFFFF", "#0000FF"],
  lightblue: ["#ADD8E6"],
  stormheatherwhite: ["#6E7783", "#FFFFFF"],
  blackscarletheather: ["#000000", "#FF2400"],
  maroonwhitemaroon: ["#800000", "#FFFFFF", "#800000"],
  navywhitegold: ["#000080", "#FFFFFF", "#DAA520"],
  heatherbubblegum: ["#FFB3DE"],
  fororange: ["#FF4500"],
  deepfog: ["#A3AAB9"],
  cameopinkstonewash: ["#EF95AE", "#B0E0E6"],
  dragonfruitnavy: ["#FF355E", "#000080"],
  deepforte: ["#625D5D"],
  deephthrnavy: ["#A9A9A9", "#000080"],
  deephthrred: ["#A9A9A9", "#FF0000"],
  powerpinkheather: ["#FF69B4"],
  dayglo: ["#FF4500"],
  sunsetcoral: ["#FF6E7A"],
  deepnavybrown: ["#000080", "#964B00"],
  deepnavygold: ["#000080", "#DAA520"],
  deepnavykhaki: ["#000080", "#F0E68C"],
  orangeathleticheather: ["#FFA500", "#EBEBEB"],
  deepperiwinkle: ["#CCCCFF"],
  gunmetalheatherroyalheather: ["#2A3439", "#4169E1"],
  blackgunmetalheather: ["#000000", "#2A3439"],
  purewhite: ["#FFFFFF"],
  softyellow: ["#FDFD96"],
  redredwhite: ["#FF0000", "#FF0000", "#FFFFFF"],
  flashback: ["#BFFF00"],
  chiclime: ["#BFFF00"],
  karma: ["#ADFF2F"],
  classicnavyred: ["#000080", "#FF0000"],
  smokeblackzipper: ["#6E7783", "#000000"],
  whiterednavywhitestripe: ["#FFFFFF", "#FF0000", "#000080", "#FFFFFF"],
  blackcharcoallightgrey: ["#000000", "#36454F", "#D3D3D3"],
  blackcharcoalred: ["#000000", "#36454F", "#FF0000"],
  blacklightgreycharcoal: ["#000000", "#D3D3D3", "#36454F"],
  redoxford: ["#FF0000", "#808080"],
  royalheatherroyal: ["#4169E1", "#4169E1"],
  forestgraphiteheather: ["#228B22", "#A9A9A9"],
  maroongraphiteheather: ["#800000", "#A9A9A9"],
  rayredblackwhite: ["#FF5349", "#000000", "#FFFFFF"],
  shockblueblackwhite: ["#4682B4", "#000000", "#FFFFFF"],
  royalroyalwhite: ["#4169E1", "#4169E1", "#FFFFFF"],
  mushroom: ["#BA9E88"],
  clubblue: ["#3093C7"],
  pearlgold: ["#EAE0C8", "#DAA520"],
  venetiangreysleevesheatherwhitebody: ["#D4D0CD", "#F5F5F5"],
  navyorangekhaki: ["#000080", "#FFA500", "#F0E68C"],
  navyscarletredkhaki: ["#000080", "#FF2400", "#F0E68C"],
  navyyellowkhaki: ["#000080", "#FFFF00", "#F0E68C"],
  orangekhaki: ["#FFA500", "#F0E68C"],
  purplekhaki: ["#800080", "#F0E68C"],
  redstripekhaki: ["#FF0000", "#F0E68C"],
  metallicroyal: ["#4169E1"],
  floridateal: ["#008080"],
  whitesafetyyellow: ["#FFFFFF", "#FFD700"],
  redclay: ["#B66A50"],
  redtiedye: ["#FF0000"],
  cardinalgraphite: ["#C41E3A", "#A9A9A9"],
  blackacidwash: ["#000000"],
  blacknaturallightgrey: ["#000000", "#8B4513", "#D3D3D3"],
  denimwhite: ["#1560BD", "#FFFFFF"],
  whiteheatherdenim: ["#FFFFFF", "#1560BD"],
  denimbrown: ["#1560BD", "#964B00"],
  denimkhaki: ["#1560BD", "#F0E68C"],
  antiquewhitetriblend: ["#FAEBD7"],
  blackmelangeblackgreythree: ["#000000", "#000000", "#A9A9A9"],
  collegiatenavymelangenavygreythree: ["#1D2951", "#000080", "#A9A9A9"],
  charcoalstone: ["#36454F", "#8B7355"],
  collegiateroyalblue: ["#1D2951", "#0000FF"],
  greytwogreythree: ["#A9A9A9", "#B0C4DE"],
  darkmaroonstone: ["#800000", "#8B7355"],
  navystone: ["#000080", "#8B7355"],
  purplestone: ["#800080", "#8B7355"],
  collegiatenavymidgrey: ["#1D2951", "#696969"],
  desertwhite: ["#EDC9AF", "#FFFFFF"],
  skybluewhite: ["#87CEEB", "#FFFFFF"],
  devonjones: ["#60ADEC"],
  dharma: ["#815E85"],
  coyotebrownblack: ["#81613E", "#000000"],
  brburgundyblack: ["#800020", "#000000"],
  sawgrass: ["#68976E"],
  coffeekhaki: ["#6F4E37", "#F0E68C"],
  fuchsiapink: ["#FF00FF", "#FFC0CB"],
  darkgreygrey: ["#A9A9A9", "#B0C4DE"],
  khakibeige: ["#F0E68C", "#F5F5DC"],
  navyturquoise: ["#000080", "#40E0D0"],
  redpink: ["#FF0000", "#FFC0CB"],
  greydigitalcamo: ["#A9A9A9"],
  digicamoroyal: ["#4682B4"],
  digitalgreencam: ["#008000"],
  digitalcamotan: ["#556B2F", "#D2B48C"],
  digtlcamouflage: ["#808080"],
  dill: ["#6F422D"],
  dillnavy: ["#6F422D", "#000080"],
  dillstone: ["#6F422D", "#8B7355"],
  dkbrown: ["#654321"],
  dkchardkchar: ["#363636", "#363636"],
  heatherpuristblue: ["#ADD8E6"],
  dkgnwhtdgr: ["#006400", "#FFFFFF", "#A9A9A9"],
  dkgreengold: ["#006400", "#FFD700"],
  dkgreenwht: ["#006400", "#FFFFFF"],
  dkgreyheather: ["#A9A9A9"],
  dkgreyhthblk: ["#A9A9A9", "#000000"],
  dkgrnhthdgn: ["#4B6F44", "#006400"],
  dkgrnhthrwht: ["#4B6F44", "#FFFFFF"],
  dkgryhthblk: ["#A9A9A9", "#000000"],
  dkgryhthrblk: ["#A9A9A9", "#000000"],
  dkgrymrbleflc: ["#A9A9A9"],
  dkheathergrey: ["#A9A9A9"],
  dkhthgryblk: ["#A9A9A9", "#000000"],
  dkhthgrynavy: ["#A9A9A9", "#000080"],
  dknavy: ["#00008B"],
  dknavydkchrc: ["#00008B", "#363636"],
  dknvydkchrcl: ["#00008B", "#363636"],
  dktlgrhtdktl: ["#00CED1", "#B0C4DE", "#00CED1"],
  dolphin: ["#646464"],
  dolphinwhite: ["#646464", "#FFFFFF"],
  dpheatherblk: ["#696969", "#000000"],
  dphtdkgryht: ["#696969", "#A9A9A9"],
  dphthrspeckled: ["#696969"],
  dphthrcardnal: ["#696969", "#C41E3A"],
  dphthrdpththr: ["#696969", "#696969"],
  dpnavyhthgry: ["#00008B", "#B0C4DE"],
  dpnavyscarlet: ["#00008B", "#FF2400"],
  heathergranitegrey: ["#B0C4DE"],
  butterscotch: ["#F0E68C"],
  lilacstripe: ["#DB7093"],
  drkheathergray: ["#A9A9A9"],
  drknoctrnlhthr: ["#363636"],
  purpleiris: ["#800080"],
  whiteblackink: ["#FFFFFF", "#000000"],
  whitejnavy: ["#FFFFFF", "#000080"],
  naturalorange: ["#EAE6C7", "#FFA500"],
  naturalsapphire: ["#EAE6C7", "#0F52BA"],
  dusk: ["#4E5755"],
  carbonwhitecarbon: ["#555555", "#FFFFFF", "#555555"],
  dustygreen: ["#91A092"],
  dustylavenderwh: ["#B57EDC", "#FFFFFF"],
  atmosphereblackleopard: ["#9FB1BC", "#000000"],
  navynavywhiteprint: ["#000080", "#000080", "#FFFFFF"],
  orangeorangeblackprint: ["#FFA500", "#FFA500", "#000000"],
  powerbluepowerblueblackprint: ["#8C92AC", "#8C92AC", "#000000"],
  silvergreynavy: ["#C0C0C0", "#000080"],
  silvergreyred: ["#C0C0C0", "#FF0000"],
  blackalpinetundracamo: ["#000000", "#789AA1"],
  heatherlakebluenavy: ["#6699CC", "#000080"],
  lightblueheathergrey: ["#ADD8E6", "#D3D3D3"],
  dustyrosetidy: ["#C5988C"],
  etrnveetred: ["#008080", "#FF0000"],
  earthcoal: ["#4B5320", "#333333"],
  earthoyster: ["#4B5320", "#C2B280"],
  ebonyblack: ["#555555", "#000000"],
  ecblkclscstr: ["#333333"],
  ecgryectblk: ["#A9A9A9", "#333333"],
  ecgryectnvy: ["#A9A9A9", "#000080"],
  ecgryectrrd: ["#A9A9A9", "#FF0000"],
  ecivrysesdstr: ["#FDEE00"],
  ecoblkecogry: ["#333333", "#A9A9A9"],
  ecocanvas: ["#F0E4C7"],
  ecogryecoblk: ["#A9A9A9", "#333333"],
  ecoivorystars: ["#FFFFF0"],
  ecotrblack: ["#333333"],
  blackputty: ["#000000", "#E9DAB4"],
  dukbrown: ["#5C3317"],
  browncountrydna: ["#654321"],
  olivecountrydna: ["#808000"],
  blackcountrydnagrey: ["#000000", "#B0C4DE"],
  ecrublack: ["#C2B280", "#000000"],
  elcbluclnvy: ["#7DF9FF", "#000080"],
  elctrcblucrbn: ["#7DF9FF", "#555555"],
  electricbluewhi: ["#7DF9FF", "#FFFFFF"],
  electricpinkmel: ["#FF1493"],
  electrcbluwht: ["#7DF9FF", "#FFFFFF"],
  rasta: ["#CE1126", "#FEDD00", "#009E49"],
  brownlime: ["#964B00", "#32CD32"],
  purplelime: ["#800080", "#32CD32"],
  lightmint: ["#98FF98"],
  performancegrey: ["#B0BFC6"],
  darkgreendarkgreendigisilver: ["#006400", "#006400", "#C0C0C0"],
  limelimedigisilver: ["#32CD32", "#32CD32", "#C0C0C0"],
  mango: ["#FFC800"],
  ecolightgreyecogrey: ["#D3D3D3", "#A9A9A9"],
  ecolightgreyecotruecurrant: ["#D3D3D3", "#9A031E"],
  elementsaguasth: ["#8ED7C5"],
  emerfrstoyster: ["#00A877", "#C2B280"],
  emeraldforest: ["#04693C"],
  tealblack: ["#008080", "#000000"],
  englishred: ["#AB4B52"],
  eom101: ["#474853"],
  wakeformequator: ["#62A9E0"],
  equator: ["#018187"],
  equatorwhite: ["#018187", "#FFFFFF"],
  whiteblackgraphite: ["#FFFFFF", "#000000", "#474853"],
  paleturquoise: ["#AFEEEE"],
  ballerinawhitedot: ["#FF6FFF", "#FFFFFF"],
  eveningsky: ["#855E42"],
  duckcamo: ["#9AA09D"],
  neonblazeorange: ["#FF6700"],
  khakiolivestars: ["#C3B091", "#808000"],
  khakioliveusa: ["#C3B091", "#808000"],
  navyflag: ["#000080"],
  olivekhakistars: ["#808000", "#C3B091"],
  olivekhakiusa: ["#808000", "#C3B091"],
  truenavywhitetrueredstars: ["#2E6BB2", "#FFFFFF", "#E32636"],
  truenavywhitetrueredusa: ["#2E6BB2", "#FFFFFF", "#E32636"],
  blackdeeporangewhite: ["#000000", "#FF8C00", "#FFFFFF"],
  woodlandcamo: ["#4B5320"],
  urbancamo: ["#73716E"],
  vintagetigercamo: ["#705214"],
  olivegreensilver: ["#808000", "#C0C0C0"],
  royalbluecharcoal: ["#4169E1", "#474853"],
  charcoalhuntergreen: ["#474853", "#355E3B"],
  fadednavy: ["#647A87"],
  fashiongreencam: ["#8D8979"],
  fatigublkblk: ["#708090", "#000000", "#000000"],
  goldlineembossed: ["#D4AF37"],
  columbiabluecolumbiabluecamo: ["#B0E0E6", "#B0E0E6"],
  cactusheather: ["#5F8148"],
  ferriswheel: ["#5F9EA0"],
  festival: ["#8855DD"],
  fildkhkitobac: ["#C3B091", "#79443B"],
  fire: ["#E25822"],
  firecracker: ["#AA381E"],
  fireredawhite: ["#B22222", "#FAEBD7"],
  fireredhgrey: ["#B22222", "#B0BFC6"],
  fireredjetbla: ["#B22222", "#2C2C2C"],
  firered: ["#B22222"],
  firefighter: ["#CE2029"],
  steelheathermaroon: ["#B0BFC6", "#800000"],
  flagtacular: ["#B22222"],
  flagtacularnat: ["#B22222", "#FAEBD7"],
  flame: ["#E25822"],
  cationicred: ["#E34234"],
  cationicroyal: ["#4169E1"],
  sharkgrey: ["#707070"],
  fldkhakitob: ["#C3B091", "#79443B"],
  fldkhkineylw: ["#C3B091", "#FFF700"],
  bsbluewhitestripe35: ["#0000FF", "#FFFFFF"],
  fbfrenchblue34: ["#0072BB"],
  flintgray: ["#708090"],
  flmngomntblu: ["#FF66FF", "#98FF98", "#0000FF"],
  flobluepink: ["#00BFFF", "#FFC0CB"],
  fluorbluepink: ["#5D8AA8", "#FF1493"],
  fluorescentswirl: ["#FF1493"],
  limeslate: ["#32CD32", "#708090"],
  powerblueslate: ["#8ACFFF", "#708090"],
  powerorangeslate: ["#FFA07A", "#708090"],
  slatewhite: ["#708090", "#FFFFFF"],
  royalfielddayplaid: ["#4169E1"],
  marbledcantaloupe: ["#FFA07A"],
  whitewhitecamo: ["#FFFFFF", "#FFFFFF"],
  fog: ["#D3D3D3"],
  fogheathernvy: ["#D3D3D3", "#000080"],
  limelimecamo: ["#32CD32", "#32CD32"],
  odgreenodgreencamo: ["#6B8E23", "#6B8E23"],
  ectrueolive: ["#556B2F"],
  kellyfielddayplaid: ["#4CBB17"],
  powerpinkwhiteblack: ["#FFC0CB", "#FFFFFF", "#000000"],
  poweryellowwhiteblack: ["#FFD700", "#FFFFFF", "#000000"],
  forestcamoblk: ["#228B22", "#000000"],
  blackgreythreemelange: ["#000000", "#474853"],
  saintlucia: ["#43BFC7"],
  heatherpaleturmericyellow: ["#D2B48C"],
  rosequartznew: ["#E9967A"],
  heathercharcoalblack: ["#474853", "#000000"],
  oxfordcharcoal: ["#6C7B8B", "#36454F"],
  granitestripe: ["#807D7D"],
  blackfrostygreynatural: ["#000000", "#A9A9A9", "#FAEBD7"],
  navyfrostygreynatural: ["#000080", "#A9A9A9", "#FAEBD7"],
  deltatreeflag: ["#006994", "#32CD32"],
  sharktreeflag: ["#5E7D7E", "#32CD32"],
  citygreyblack: ["#8A8D8F", "#000000"],
  darkmountaincollegiatenavy: ["#555555", "#000080"],
  julep: ["#BFD833"],
  aquabluelightlime: ["#00FFFF", "#BFFF00"],
  whitebluemistcamo: ["#FFFFFF", "#62B1F6"],
  heatherheatherwhitedotwhite: ["#ADADAD", "#ADADAD", "#FFFFFF"],
  khakiforest: ["#F0E68C", "#228B22"],
  heatherrust: ["#B7410E"],
  watermelonstripe: ["#FC6C85"],
  whitegranite: ["#FFFFFF", "#807D7D"],
  adirondack: ["#5A4D41"],
  forestgrenhthr: ["#228B22"],
  forestgrntan: ["#228B22", "#D2B48C"],
  forestgrnwht: ["#228B22", "#FFFFFF"],
  tristorm: ["#708090"],
  heatherimperialpurple: ["#9D81BA"],
  heatherplum: ["#DDA0DD"],
  forestforstht: ["#228B22", "#4E5755"],
  forestkhaki: ["#228B22", "#F0E68C"],
  forestnavypld: ["#228B22", "#000080"],
  foreststone: ["#228B22", "#A9A9A9"],
  olivegreencamo: ["#556B2F"],
  forestblackwhi: ["#228B22", "#000000", "#FFFFFF"],
  forestcarbon: ["#228B22", "#4B4E53"],
  forestlightgold: ["#228B22", "#FAD02E"],
  fossilgrey: ["#6D6968"],
  fieryredhthrox: ["#FF4500", "#6C7B8B"],
  frredhthoxfr: ["#FF4500", "#6C7B8B"],
  frchblfblht: ["#0072BB", "#9999FF"],
  freedom: ["#B22222"],
  frenchbluwht: ["#0072BB", "#FFFFFF"],
  collegiatenavynavy: ["#0072BB", "#000080"],
  frenchbluehthr: ["#0072BB"],
  frenchbluewht: ["#0072BB", "#FFFFFF"],
  frenchnavy: ["#0E4D92"],
  frenchnavyhgry: ["#0E4D92", "#808080"],
  frenchnavyskyb: ["#0E4D92", "#00BFFF"],
  frenchnavyfred: ["#0E4D92", "#DC143C"],
  frntierblkbl: ["#30434E", "#000000", "#00BFFF"],
  frntierblkwh: ["#30434E", "#000000", "#FFFFFF"],
  frntierrefgry: ["#30434E", "#808080"],
  frntrhthfrntr: ["#30434E", "#30434E"],
  frontier: ["#30434E"],
  frontierfrost: ["#30434E"],
  frontierjaspe: ["#30434E"],
  frontiermelange: ["#30434E"],
  frontierstripe: ["#30434E"],
  frontierblack: ["#30434E", "#000000"],
  frontierred: ["#30434E", "#FF0000"],
  frontrhthrblk: ["#30434E", "#000000"],
  mauvepepperheather: ["#B67768"],
  frostedclear: ["#E6E6FA"],
  frstgrnsnwhth: ["#228B22"],
  navypinktomboyplaid: ["#000080"],
  blackandwhitecheck: ["#000000"],
  seafoamwhite: ["#50C878", "#FFFFFF"],
  snowleopardwhite: ["#E2E2E2", "#FFFFFF"],
  asphaltnavy: ["#080808", "#000080"],
  clubbluesmokedpearl: ["#5F9EA0"],
  lightgreynavy: ["#D3D3D3", "#000080"],
  fuschiawhite: ["#FF00FF", "#FFFFFF"],
  ghthtgfbl: ["#B0C4DE"],
  wakeformgale: ["#536878"],
  gale: ["#536878"],
  galtltcharcl: ["#7C8E00"],
  charcoalfireballred: ["#36454F", "#B22222"],
  whiteredgrey: ["#FFFFFF", "#FF0000", "#808080"],
  whitedigi: ["#FFFFFF"],
  electricbluelime: ["#7EF9FF", "#00FF00"],
  orangelime: ["#FFA500", "#00FF00"],
  fadedcamo: ["#78866B"],
  lead: ["#212121"],
  georgiatechgold: ["#B78E5C"],
  charcoalblacktriblendsolidblacktriblend: ["#36454F"],
  frostygreynatural: ["#A9A9A9", "#FAEBD7"],
  vegasgoldnavy: ["#C5B358", "#000080"],
  glacierblack: ["#C0C9D1", "#000000"],
  collegiateredmelange: ["#800000"],
  greysixblack: ["#696969", "#000000"],
  techolivelegendearth: ["#808000"],
  camoecotrueolive: ["#78866B"],
  fadedred: ["#FF6961"],
  powerpinkpowerpinkblackprint: ["#FF69B4", "#FF69B4", "#000000"],
  poweryellowpoweryellowblackprint: ["#FFFF00", "#FFFF00", "#000000"],
  redredblackprint: ["#FF0000", "#FF0000", "#000000"],
  royalroyalblackprint: ["#000080", "#000080", "#000000"],
  carbonprintwhite: ["#696969", "#FFFFFF"],
  redbuffaloblack: ["#FF0000", "#000000"],
  garnetoatmeal: ["#800000"],
  granitegarnet: ["#807D7D", "#800000"],
  maroonathleticheather: ["#800000"],
  saturn: ["#708090"],
  melangeblack: ["#000000"],
  royalpepper: ["#4169E1"],
  greyolive: ["#8B8C83"],
  heatherdeserttan: ["#E0AC69"],
  heatherstonewashblue: ["#727F9E"],
  limeroyalblue: ["#00FF00", "#000080"],
  classicnavyheather: ["#34414E"],
  marineoatmeal: ["#013152", "#F5DEB3"],
  whitesouthernorange: ["#FFFFFF", "#FFA500"],
  goldblackwhite: ["#FFD700", "#000000", "#FFFFFF"],
  goldcarbonhthr: ["#FFD700", "#36454F"],
  whitestarsstripes: ["#FFFFFF"],
  powerorangeblack: ["#FF4500", "#000000"],
  grandcanyon: ["#DD9475"],
  ectrurerosequartz: ["#FF66A1"],
  silvernavy: ["#C0C0C0", "#000080"],
  powerpinkpowerpink: ["#FF69B4", "#FF69B4"],
  granitehthvsmo: ["#676767"],
  granitehthrgry: ["#808080"],
  granitenavy: ["#708090", "#000080"],
  grapeheather: ["#6F2DA8"],
  grapeice: ["#601A4A"],
  grapetriblend: ["#4B0082"],
  grapegrapehth: ["#6A5ACD", "#6A5ACD"],
  grapenavy: ["#6A5ACD", "#000080"],
  graphblkgrap: ["#808080", "#000000", "#6A5ACD"],
  graphwhite: ["#808080", "#FFFFFF"],
  graphwhtgrap: ["#808080", "#FFFFFF", "#6A5ACD"],
  blackglitterblack: ["#000000", "#000000"],
  goldglitterblack: ["#FFD700", "#000000"],
  navyslatewhite: ["#000080", "#708090", "#FFFFFF"],
  navygreyheather: ["#000080", "#B0BEC5"],
  orangecarbonheather: ["#FFA500", "#663D3D"],
  darkchocolateheather: ["#432622"],
  sportgreyroyal: ["#C0C0C0", "#4169E1"],
  cardinalgunmetalheather: ["#C41E3A", "#2C3539"],
  stoneheather: ["#ADABA9"],
  navycharcoalfleck: ["#000080", "#36454F"],
  blackelectricblue: ["#000000", "#7DF9FF"],
  pepperforest: ["#505050", "#228B22"],
  wildberrytriblend: ["#8D4E85"],
  blackgreytriblend: ["#000000", "#808080"],
  blacknavytriblend: ["#000000", "#000080"],
  grigioscuro: ["#708090"],
  pixel: ["#DDDDDD"],
  fathom: ["#041E42"],
  blendedwhitevintageheather: ["#FFFFFF", "#A8A8A8"],
  whiteraspberry: ["#FFFFFF", "#E30B5D"],
  blackredcharcoal: ["#000000", "#FF0000", "#36454F"],
  blacklightgreyroyal: ["#000000", "#D3D3D3", "#4169E1"],
  whiteteamblue: ["#FFFFFF", "#007BB5"],
  greysix: ["#7B7B7B"],
  cardinalblackkhaki: ["#C41E3A", "#000000", "#F0E68C"],
  cardinalkhaki: ["#C41E3A", "#F0E68C"],
  coralkhaki: ["#FF6F61", "#F0E68C"],
  darkgreenkhaki: ["#006400", "#F0E68C"],
  darkpinkkhaki: ["#E75480", "#F0E68C"],
  greenfieldcamojava: ["#4B5320", "#D2691E"],
  greenfieldcamokhaki: ["#4B5320", "#F0E68C"],
  indigopepperheather: ["#4B0082"],
  ashcharcoal: ["#B2BEB5", "#36454F"],
  neonheatherorange: ["#FF9933"],
  raspberrywhitestripe: ["#E30B5D"],
  neworleanspelicans: ["#0C2340"],
  newyorkknicks: ["#006BB6"],
  brownsepia: ["#704214"],
  cocoabrown: ["#D2691E"],
  classickhakiwhitezipper: ["#F0E68C"],
  classicnavyarmy: ["#000080", "#4B5320"],
  carbonprintgraphite: ["#A9A9A9"],
  graphitemelange: ["#808080"],
  melangebluemelangecharcoal: ["#1E90FF", "#36454F"],
  heathervintagegreen: ["#32CD32"],
  neonheatherpink: ["#FF69B4"],
  graphitegrght: ["#808080"],
  graphitekhaki: ["#808080", "#F0E68C"],
  graphitenblue: ["#808080", "#0000FF"],
  graphitengrn: ["#808080", "#008000"],
  graphitenorng: ["#808080", "#FF4500"],
  graphitenyllw: ["#808080", "#FFFF00"],
  graphitepink: ["#808080", "#FFC0CB"],
  saltpepper: ["#C0C0C0"],
  galaxy: ["#1D2951"],
  graphiteblkroya: ["#808080", "#000000"],
  graphitecarbon: ["#808080"],
  redstone: ["#FF0000", "#CD5C5C"],
  redwhiteheathergrey: ["#FF0000", "#FFFFFF", "#D3D3D3"],
  redwhitenavy: ["#FF0000", "#FFFFFF", "#000080"],
  royalcharcoalblacktri: ["#4169E1", "#36454F", "#000000"],
  royalwhitesplit: ["#4169E1", "#FFFFFF"],
  whiteblackcontrast: ["#FFFFFF", "#000000"],
  whiteblackorangetri: ["#FFFFFF", "#000000", "#FFA500"],
  whiteroyalblue: ["#FFFFFF", "#4169E1"],
  graychambray: ["#708090"],
  grayplaid: ["#808080"],
  grayblack: ["#808080", "#000000"],
  graylimegrn: ["#808080", "#32CD32"],
  grayorange: ["#808080", "#FFA500"],
  graypink: ["#808080", "#FFC0CB"],
  grayred: ["#808080", "#FF0000"],
  grayteal: ["#808080", "#008080"],
  graywhite: ["#808080", "#FFFFFF"],
  grayyelllow: ["#808080", "#FFFF00"],
  maroonmaroondigisilver: ["#800000", "#800000", "#C0C0C0"],
  navynavydigisilver: ["#000080", "#000080", "#C0C0C0"],
  greenapplehthr: ["#32CD32"],
  vegasgoldtonalblend: ["#C5B358"],
  greencamouflage: ["#4B5320"],
  greenhexcamo: ["#4A5320"],
  detroitpistons: ["#ED174C"],
  vintagegreyvintageblack: ["#808080", "#000000"],
  greenmist: ["#98FB98"],
  greenwhiteblack: ["#008000", "#FFFFFF", "#000000"],
  electricbluegraphite: ["#7DF9FF", "#808080"],
  greywhite: ["#808080", "#FFFFFF"],
  orangeorangedigisilver: ["#FFA500", "#FFA500", "#C0C0C0"],
  greyaloha: ["#808080"],
  greybrown: ["#808080", "#964B00"],
  greycamouflage: ["#808080"],
  fuchsiatriblend: ["#FF00FF"],
  stonesandalwood: ["#8B4513", "#964B00"],
  vintagechocolate: ["#4E2428"],
  blackforestcamo: ["#000000", "#228B22"],
  greydenim: ["#808080"],
  greyfauxfur: ["#808080"],
  darknavylightgoldlightgold: ["#000080", "#FFD700", "#FFD700"],
  blackwhitepolkadots: ["#000000", "#FFFFFF"],
  darknavyfloral: ["#000080"],
  fashioncamo: ["#78866B"],
  persianrednavy: ["#CC3333", "#000080"],
  fig: ["#801818"],
  rednavytiedye: ["#FF0000", "#000080"],
  cloudyblue: ["#ADD8E6"],
  burgundystar: ["#800020"],
  naturalindigoplaid: ["#8FB28F"],
  whitegraphiteblackprint: ["#FFFFFF", "#808080", "#000000"],
  whitegraphiteblack: ["#FFFFFF", "#808080", "#000000"],
  yellow: ["#FFFF00"],
  redwhitedot: ["#FF0000", "#FFFFFF"],
  redwhitestripe: ["#FF0000", "#FFFFFF"],
  greyhthrblack: ["#808080", "#000000"],
  greyhthrwhite: ["#808080", "#FFFFFF"],
  greyhthrscarlet: ["#808080", "#FF2400"],
  mintyrainbow: ["#98FF98"],
  minnesotatimberwolves: ["#0C2340"],
  vintagemaroonvintagegrey: ["#800000", "#808080"],
  heatherredscarlet: ["#FF0000"],
  redscarlet: ["#FF2400"],
  oatmealheatherwhite: ["#F5DEB3", "#FFFFFF"],
  darkgreenwhitedarkgreen: ["#006400", "#FFFFFF", "#006400"],
  royalkhaki: ["#000080", "#F0E68C"],
  scarletredblackkhaki: ["#FF2400", "#000000", "#F0E68C"],
  scarletredkhaki: ["#FF2400", "#F0E68C"],
  scarletrednavykhaki: ["#FF2400", "#000080", "#F0E68C"],
  yellowkhaki: ["#FFFF00", "#F0E68C"],
  armycamobrownkhaki: ["#4B5320", "#964B00", "#F0E68C"],
  bluepines: ["#0000FF"],
  browncamoblotch: ["#964B00"],
  armycamokhaki: ["#4B5320", "#F0E68C"],
  ecosand: ["#E6C799"],
  ecoscarletred: ["#FF2400"],
  greycamodots: ["#808080"],
  tricranberry: ["#DB4C40"],
  whiteathleticheather: ["#FFFFFF", "#B5B5B5"],
  onyxblackstone: ["#353839", "#8B4513"],
  puttywhite: ["#D2B48C", "#FFFFFF"],
  lapisgreen: ["#007FFF"],
  whitenavywhitered: ["#FFFFFF", "#000080", "#FFFFFF", "#FF0000"],
  greyblacktrib: ["#808080", "#000000"],
  greycardinal: ["#808080", "#C41E3A"],
  greyhotpink: ["#808080", "#FF69B4"],
  greyneonblue: ["#808080", "#1E90FF"],
  greyneongreen: ["#808080", "#39FF14"],
  greyneonpink: ["#808080", "#FF6EB4"],
  greyneonyellw: ["#808080", "#FFFF33"],
  steelheatherforest: ["#B0C4DE", "#228B22"],
  greyredtribld: ["#808080", "#FF0000"],
  greyroyaltrib: ["#808080", "#000080"],
  greyscarlet: ["#808080", "#FF2400"],
  firecoralheather: ["#FF4040"],
  greystone: ["#778899"],
  blackcamelgrey: ["#000000", "#C19A6B", "#808080"],
  bluesteelgrey: ["#4682B4", "#808080"],
  burgundygrey: ["#800020", "#808080"],
  grnglwbeige: ["#00FF00", "#F5F5DC"],
  grphtlhtrch: ["#808080", "#D3D3D3"],
  grphtlhtrwht: ["#808080", "#FFFFFF"],
  grphtmlngegrp: ["#808080", "#808080"],
  grphtantqwht: ["#808080", "#FAEBD7"],
  grphtewhtblk: ["#808080", "#FFFFFF", "#000000"],
  gryheathernvy: ["#808080", "#000080"],
  gryhthrblack: ["#808080", "#000000"],
  gryhthrwhite: ["#808080", "#FFFFFF"],
  grystmstonx: ["#808080", "#708090"],
  gryneonorng: ["#808080", "#FF4500"],
  vintageorangeblendedwhite: ["#FF7F00", "#FFFFFF"],
  gumdrop: ["#FFDDC1"],
  gummybear: ["#FD6C9E"],
  greenoxfordbuffalo: ["#008000"],
  columbiabluegraphitewhite: ["#9BDDFF", "#808080", "#FFFFFF"],
  limegraphitewhite: ["#00FF00", "#808080", "#FFFFFF"],
  orangegraphitewhite: ["#FFA500", "#808080", "#FFFFFF"],
  powerpinkgraphitewhite: ["#FF6EB4", "#808080", "#FFFFFF"],
  poweryellowgraphitewhite: ["#FFFF00", "#808080", "#FFFFFF"],
  navygraphitewhite: ["#000080", "#808080", "#FFFFFF"],
  blackelectricorange: ["#000000", "#FF4500"],
  blackelectricred: ["#000000", "#FF6A6A"],
  charcoalspeck: ["#43464B"],
  greenstonejunglegreen: ["#029E73", "#29AB87"],
  blackblendblackblack: ["#000000", "#000000", "#000000"],
  hotcoralsafetyorangegraphite: ["#FF6E6E", "#FFA500", "#808080"],
  silverlineembossed: ["#C0C0C0"],
  graphitemonocam: ["#808080"],
  navymonocam: ["#000080"],
  redmonocam: ["#FF0000"],
  royalmonocam: ["#000080"],
  wowpinkheather: ["#FF69B4"],
  darkgreencarbon: ["#006400"],
  cactusflower: ["#D5E8D4"],
  hgrydgreenbl: ["#808080", "#006400", "#0000FF"],
  hgryredblack: ["#808080", "#FF0000", "#000000"],
  hgryroyblack: ["#808080", "#000080", "#000000"],
  hgrylchhg: ["#808080", "#D3D3D3"],
  hgrylodngry: ["#808080", "#9ACD32", "#808080"],
  hgryltcharcl: ["#808080", "#778899"],
  hgryslblbk: ["#808080", "#6A5ACD", "#000000"],
  hgrysmblbk: ["#808080", "#4682B4", "#000000"],
  hgreybiragol: ["#808080", "#FFE4C4"],
  hgraybirchaol: ["#808080", "#FFE4C4"],
  habitat: ["#5E644F"],
  hailstonewhite: ["#D2D2D2", "#FFFFFF"],
  hangers: ["#A3A3A3"],
  harborblue: ["#4272B8"],
  hardwoodhdgreen: ["#556B2F"],
  harmony: ["#FFEBCD"],
  harriton: ["#B0E0E6"],
  hawaiianblue: ["#00FFFF"],
  powerpinkgraphite: ["#FF6EB4", "#808080"],
  blackvegasgoldwhite: ["#000000", "#C5B358", "#FFFFFF"],
  blazerealtreeedge: ["#FF6600", "#734F3B"],
  brownedgetan: ["#A52A2A", "#915C83", "#D2B48C"],
  darkbrownblack: ["#654321", "#000000"],
  blackcardinalstone: ["#000000", "#8C1515", "#FFCBA4"],
  brownstone: ["#A52A2A", "#FFCBA4"],
  cardinalroyalstone: ["#8C1515", "#4169E1", "#FFCBA4"],
  cardinalstone: ["#8C1515", "#FFCBA4"],
  redwhitegreyblack: ["#FF0000", "#FFFFFF", "#808080", "#000000"],
  royalwhitegrey: ["#4169E1", "#FFFFFF", "#808080"],
  darkpink: ["#D74894"],
  redroyal: ["#FF0000", "#4169E1"],
  greyorange: ["#808080", "#FFA500"],
  greypink: ["#808080", "#FFC0CB"],
  heatherdarkgry: ["#A9A9A9"],
  heatherdkgrey: ["#A9A9A9"],
  stonelightolivewhite: ["#FFCBA4", "#6B8E23", "#FFFFFF"],
  heathergrape: ["#AB83A1"],
  purplepassion: ["#800080"],
  raspberrymist: ["#E25098"],
  graphiteheathergraphiteheather: ["#808080", "#808080"],
  graphiteheatherlime: ["#808080", "#00FF00"],
  silvergreyblack: ["#C0C0C0", "#808080", "#000000"],
  orangeroyal: ["#FFA500", "#4169E1"],
  espressoheather: ["#704214"],
  malbec: ["#800000"],
  heathergreycardinalnavy: ["#D3D3D3", "#8C1515", "#000080"],
  heathergreycharcoaldarkorange: ["#D3D3D3", "#43464B", "#FF8C00"],
  heathergreycharcoalmaroon: ["#D3D3D3", "#43464B", "#800000"],
  heathergreydarkgreen: ["#D3D3D3", "#006400"],
  heathergreylightgrey: ["#D3D3D3", "#D3D3D3"],
  kryptekneptunewhite: ["#5A6650", "#FFFFFF"],
  kryptektyphonneonyellow: ["#1C1C1B", "#FFFF00"],
  mossyoakbottomlandloden: ["#645645", "#6C5731"],
  mossyoakelementsblacktipcharcoal: ["#404739", "#43464B"],
  mossyoakelementsbonefishlightgrey: ["#B5A094", "#D3D3D3"],
  mossyoakhabitatbrown: ["#797763", "#A52A2A"],
  royalroyaltowhitefade: ["#4169E1", "#4169E1", "#FFFFFF"],
  lodenkhaki: ["#6C5731", "#C3B091"],
  carmelbirch: ["#8B5742", "#FFE4C4"],
  carmelblack: ["#8B5742", "#000000"],
  chocolatechipgreybrown: ["#7B3F00", "#835C3B"],
  heatherlightgrey: ["#D3D3D3"],
  darkgreengraphitewhite: ["#006400", "#808080", "#FFFFFF"],
  darkmaroongraphitewhite: ["#800000", "#808080", "#FFFFFF"],
  greenclayheather: ["#556B2F"],
  cantaloupeheather: ["#FFA07A"],
  espressobrown: ["#6E4A33"],
  olivewoodland: ["#808000", "#556B2F"],
  realtreeedgestone: ["#734F3B", "#FFCBA4"],
  woodlandkhaki: ["#556B2F", "#C3B091"],
  dadagrey: ["#A9A9A9"],
  heathergryblk: ["#A9A9A9", "#000000"],
  heathergryred: ["#A9A9A9", "#FF0000"],
  heathergryroy: ["#A9A9A9", "#4169E1"],
  heatheririshgrn: ["#00FF00"],
  heatherlimegrn: ["#32CD32"],
  pacificblueheather: ["#1CA9C9"],
  whitenightshade: ["#FFFFFF", "#708090"],
  darkashnavy: ["#B0C4DE", "#000080"],
  blackdesertpink: ["#000000", "#FFD1DC"],
  leopardtriblend: ["#BD8F13"],
  vividbluejupiter: ["#1E90FF", "#DCAE1D"],
  mustardyellowstone: ["#FFDB58", "#FFCBA4"],
  navycardinalstone: ["#000080", "#8C1515", "#FFCBA4"],
  navytexasstone: ["#000080", "#00205B", "#FFCBA4"],
  rednavystone: ["#FF0000", "#000080", "#FFCBA4"],
  greyteal: ["#808080", "#008080"],
  greywhitespeckled: ["#808080", "#FFFFFF"],
  blackheatherathleticheather: ["#000000", "#A9A9A9"],
  navylightbluestripe: ["#000080", "#ADD8E6"],
  redcharcoalstripe: ["#FF0000", "#43464B"],
  vintagebluekhaki: ["#4682B4", "#C3B091"],
  vintageredkhaki: ["#C80815", "#C3B091"],
  graphiteaqua: ["#808080", "#00FFFF"],
  melangecharcoal: ["#3A3B3C"],
  heatherhotpink: ["#A9A9A9", "#FF69B4"],
  darknavywhite: ["#00008B", "#FFFFFF"],
  darkpurplewhite: ["#800080", "#FFFFFF"],
  scarletblackscarlet: ["#FF2400", "#000000", "#FF2400"],
  darknavycreamlightgold: ["#00008B", "#FFFDD0", "#DABDAB"],
  cardinalsilver: ["#8C1515", "#C0C0C0"],
  peacoatnavy: ["#000080"],
  realred: ["#8B0000"],
  pinklady: ["#FFD1DC"],
  stormyash: ["#A9A9A9"],
  atlanticblue: ["#0000A0"],
  naturalcrimson: ["#FAF0E6", "#DC143C"],
  blackheathergreywhite: ["#000000", "#A9A9A9", "#FFFFFF"],
  cardinalheathergreywhite: ["#8C1515", "#A9A9A9", "#FFFFFF"],
  columbiablueheathergreywhite: ["#C6E2FF", "#A9A9A9", "#FFFFFF"],
  darkgreenheathergreywhite: ["#006400", "#A9A9A9", "#FFFFFF"],
  goldheathergreywhite: ["#FFD700", "#A9A9A9", "#FFFFFF"],
  forestgreensilver: ["#228B22", "#C0C0C0"],
  atomicorangecosmicpinktiedye: ["#FF4500", "#FF69B4"],
  limemauitiedye: ["#00FF00", "#23C48B"],
  stardusttiedye: ["#9F9F9C"],
  blacksleevesdarkheathergreybody: ["#000000", "#A9A9A9"],
  heatherheatherwhitestripe: ["#A9A9A9", "#A9A9A9", "#FFFFFF"],
  myrtle: ["#21421E"],
  blackburntorangeburntorange: ["#000000", "#CC5500", "#CC5500"],
  myrtleblackwhite: ["#21421E", "#000000", "#FFFFFF"],
  maroonlightgold: ["#800000", "#DABDAB"],
  cameopinkheathergrey: ["#EFBBCC", "#A9A9A9"],
  stardustheathergrey: ["#9F9F9C", "#A9A9A9"],
  darkroyallightgold: ["#00008B", "#DABDAB"],
  whiteoxford: ["#FFFFFF", "#6D6968"],
  lightbluedenim: ["#B0E0E6"],
  graphiteheathergreywhite: ["#808080", "#A9A9A9", "#FFFFFF"],
  kellyheathergreywhite: ["#4CBB17", "#A9A9A9", "#FFFFFF"],
  maroonheathergreywhite: ["#800000", "#A9A9A9", "#FFFFFF"],
  navyheathergreywhite: ["#000080", "#A9A9A9", "#FFFFFF"],
  orangeheathergreywhite: ["#FFA500", "#A9A9A9", "#FFFFFF"],
  purpleheathergreywhite: ["#800080", "#A9A9A9", "#FFFFFF"],
  redheathergreywhite: ["#FF0000", "#A9A9A9", "#FFFFFF"],
  royalheathergreywhite: ["#4169E1", "#A9A9A9", "#FFFFFF"],
  darkroyalwhite: ["#00008B", "#FFFFFF"],
  scarletcream: ["#FF2400", "#FFFDD0"],
  lightbluewhitepolkadots: ["#ADD8E6", "#FFFFFF"],
  lightpinkfloral: ["#FFB6C1"],
  navywhitepolkadots: ["#000080", "#FFFFFF"],
  navywhitestars: ["#000080", "#FFFFFF"],
  fusioncoral: ["#FF6F61"],
  heatherpacific: ["#A9A9A9"],
  heatherheatherwhitestripewhite: ["#A9A9A9", "#A9A9A9", "#FFFFFF"],
  navynavywhitestripe: ["#000080", "#000080", "#FFFFFF"],
  purplelightgold: ["#800080", "#DABDAB"],
  blackburntorangewhite: ["#000000", "#CC5500", "#FFFFFF"],
  blackcreamburntorange: ["#000000", "#FFFDD0", "#CC5500"],
  scarletscarletwhite: ["#FF2400", "#FF2400", "#FFFFFF"],
  danishblue: ["#3C5F8C"],
  oxfordnaturalstripe: ["#6D6968", "#FAF0E6"],
  snowheatherblue: ["#63B8FF"],
  myrtledarkgreenwhite: ["#21421E", "#006400", "#FFFFFF"],
  myrtlewhite: ["#21421E", "#FFFFFF"],
  heathrnvyred: ["#A9A9A9", "#000080", "#FF0000"],
  heathrnvywht: ["#A9A9A9", "#000080", "#FFFFFF"],
  heathrredwht: ["#A9A9A9", "#FF0000", "#FFFFFF"],
  heathrroywht: ["#A9A9A9", "#4169E1", "#FFFFFF"],
  heathrwhistripe: ["#A9A9A9", "#FFFFFF"],
  outdoorcamotriblend: ["#4B5320"],
  darkgreenheathergraphiteheather: ["#006400", "#A9A9A9"],
  maroonheathergraphiteheather: ["#800000", "#A9A9A9"],
  heritagebrown: ["#5A3A06"],
  hghrshhtnvblz: ["#F1E0B0"],
  hghrshhtqtsh: ["#F1E0B0", "#D1B2A1"],
  hhpairsgrey: ["#808080"],
  hivisorange: ["#FF4500"],
  hivisyellow: ["#FFFF00"],
  hibiscusbrown: ["#B06500", "#964B00"],
  hickoryheather: ["#5D4A00"],
  highrise: ["#8C8C8C"],
  highrisehthr: ["#B0B0B0"],
  highriskred: ["#FF5349"],
  highseaswhite: ["#045C5A", "#FFFFFF"],
  highlander: ["#5D762A"],
  horizonblue: ["#1C3A70"],
  hotchocolate: ["#905D5D"],
  jazzberrypinktriblend: ["#C71585"],
  blackbrownleopard: ["#000000", "#964B00"],
  heatherecru: ["#C2B280"],
  oxfordburntorange: ["#6D6968", "#CC5500"],
  neonfuchsia: ["#FE4164"],
  hotpinkfnavy: ["#FF69B4", "#000080"],
  hotpinkhgrey: ["#FF69B4", "#A9A9A9"],
  hardwoodscamo: ["#556B2F"],
  htgrbgamgd: ["#767676", "#826644", "#DAA520"],
  htgrbgocbl: ["#767676", "#826644", "#1C3A70"],
  htgrbgsmbl: ["#767676", "#826644", "#003366"],
  htgrltcag: ["#767676", "#B0E0E6", "#DAA520"],
  htgrltclc: ["#767676", "#B0E0E6", "#D3D3D3"],
  htgrltchmg: ["#767676", "#D3D3D3", "#FF00FF"],
  htgrltchob: ["#767676", "#D3D3D3", "#CC7722"],
  htgrltchvr: ["#767676", "#D3D3D3", "#8B00FF"],
  htgrnghtgr: ["#767676", "#FFA500", "#767676"],
  htgrrylhg: ["#767676", "#0033AA", "#767676"],
  htgrwhhtgr: ["#767676", "#FFFFFF", "#767676"],
  htspdrkmaroon: ["#800000"],
  htsprtdrknavy: ["#000080"],
  hthrhwhitehwstr: ["#A9A9A9", "#FFFFFF", "#A9A9A9"],
  hthblkgold: ["#000000", "#FFD700"],
  hthcaribblue: ["#3E9ED2"],
  hthchrcoalblk: ["#666666", "#000000"],
  hthdarkgreen: ["#006400"],
  hthforestgreen: ["#228B22"],
  hthgalapblue: ["#4682B4"],
  hthgrtrhblue: ["#767676", "#7B68EE"],
  hthgrnneoyel: ["#008000", "#FFFF00"],
  hthgryinvstrp: ["#A9A9A9"],
  hthrgreyfred: ["#A9A9A9", "#FF0000"],
  hthrgreyfnavy: ["#A9A9A9", "#000080"],
  hthgryhthgry: ["#A9A9A9", "#A9A9A9"],
  hthrgreyjblack: ["#A9A9A9", "#000000"],
  hthgrylcgr: ["#A9A9A9", "#D3D3D3", "#808080"],
  hthgryltchar: ["#A9A9A9", "#D3D3D3"],
  hthltbluwht: ["#ADD8E6", "#FFFFFF"],
  hthseafoamwht: ["#20B2AA", "#FFFFFF"],
  hthspdrkgreen: ["#006400"],
  hthsptscrltrd: ["#FF2400"],
  hthrbluelagoon: ["#66CCCC"],
  hthrbluereflex: ["#5F9EA0"],
  hthrbluenavy: ["#6699CC", "#000080"],
  hthrburgundy: ["#800020"],
  hthrcaribblue: ["#3E9ED2"],
  hthrcolumblue: ["#6495ED"],
  hthrdarkgray: ["#666666"],
  hthrforestgreen: ["#228B22"],
  hthrgrayblack: ["#808080", "#000000"],
  hthrgraywhite: ["#808080", "#FFFFFF"],
  hthrgreyblack: ["#A9A9A9", "#000000"],
  hthrgreylapis: ["#A9A9A9", "#26619C"],
  hthrgreynavy: ["#A9A9A9", "#000080"],
  hthrgreyred: ["#A9A9A9", "#FF0000"],
  hthrgreyroyal: ["#A9A9A9", "#4169E1"],
  hthrgreywhite: ["#A9A9A9", "#FFFFFF"],
  hthrgrybrgndy: ["#A9A9A9", "#800020"],
  hthrlakeblue: ["#5F9EA0"],
  hthrlieutenant: ["#A2AAAD"],
  hthrmilitrygrn: ["#4B5320"],
  hthrnavyblue: ["#000080"],
  hthrnvymdnite: ["#000080", "#191970"],
  hthrpinkgravel: ["#FFC0CB"],
  hthrpinkwht: ["#FFC0CB", "#FFFFFF"],
  hthrpurplewht: ["#800080", "#FFFFFF"],
  hthrrdntorchid: ["#DA70D6"],
  hthrreddphth: ["#FF0000", "#B90E0A"],
  hthrslateblk: ["#708090", "#000000"],
  hthrsportroyal: ["#4169E1"],
  hthrtrroynvy: ["#838B8B", "#000080"],
  hthrblkgld: ["#A9A9A9", "#000000", "#FFD700"],
  hthrblkornge: ["#A9A9A9", "#000000", "#FFA500"],
  hthrblkred: ["#A9A9A9", "#000000", "#FF0000"],
  hthrblkwht: ["#A9A9A9", "#000000", "#FFFFFF"],
  hthrdgrnwht: ["#A9A9A9", "#006400", "#FFFFFF"],
  hthrkllywht: ["#A9A9A9", "#4CBB17", "#FFFFFF"],
  hthrmarwht: ["#A9A9A9", "#800000", "#FFFFFF"],
  hthrnvclblu: ["#A9A9A9", "#000080", "#7F00FF"],
  hthrtealwht: ["#A9A9A9", "#008080", "#FFFFFF"],
  hthrhwdotwhi: ["#A9A9A9", "#A9A9A9", "#FFFFFF"],
  hthrhthrwswhi: ["#A9A9A9", "#A9A9A9", "#FFFFFF"],
  huntergrn: ["#355E3B"],
  blackorangeheather: ["#000000", "#FFA500"],
  hunterwhite: ["#3EB489", "#FFFFFF"],
  redwhiteredwhitestripe: ["#FF0000", "#FFFFFF", "#FF0000", "#FFFFFF"],
  kellywhiteredwhitestripe: ["#4CBB17", "#FFFFFF", "#FF0000", "#FFFFFF"],
  hypnotize: ["#E2E2E2"],
  icebldhtrgr: ["#99FFFF", "#A9A9A9"],
  blushheather: ["#DE5D83"],
  pinkred: ["#FFC0CB", "#FF0000"],
  redredwhitestripewhite: ["#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF"],
  icefleckneongr: ["#E0FFFF", "#39FF14"],
  dustyrosetriblend: ["#856363"],
  indigodenim: ["#4E5180"],
  indigohthrox: ["#4B0082", "#4B0082"],
  indigohthoxfr: ["#4B0082", "#4B0082"],
  indigoblkkh: ["#4B0082", "#000000", "#F0E68C"],
  indigogray: ["#4B0082", "#808080"],
  indigosilk: ["#4B0082", "#4B0082"],
  indigowhite: ["#4B0082", "#FFFFFF"],
  industryblue: ["#5B92E5"],
  truenavytrueredwhite: ["#000080", "#FF0000", "#FFFFFF"],
  trueroyalheatherwhite: ["#4169E1", "#A9A9A9", "#FFFFFF"],
  truenavysilver: ["#000080", "#C0C0C0"],
  trueredsilver: ["#FF0000", "#C0C0C0"],
  inknavy: ["#000080"],
  triblacksolid: ["#000000"],
  whitepineapple: ["#FFFFFF", "#FFD700"],
  whiteredroyalthinthickstripe: ["#FFFFFF", "#FF0000", "#4169E1"],
  pastel: ["#FFD1DC"],
  charcoalblackink: ["#333333", "#000000"],
  navyheathergraphiteheather: ["#000080", "#A9A9A9"],
  powerpinkheathergraphiteheather: ["#FF1493", "#A9A9A9"],
  irishgreenhthr: ["#4CBB17"],
  heatherpurpledusk: ["#A020F0"],
  citygreyheatherchalk: ["#8C8C8C", "#A9A9A9"],
  darkcharcoalroyalblue: ["#333333", "#4169E1"],
  dustyrosetiedye: ["#856363"],
  realtreemax4: ["#645452"],
  realtreextragreen: ["#6B8E23"],
  mossyoakbreakupblack: ["#645452", "#000000"],
  mossyoakcountrytan: ["#645452", "#D2B48C"],
  realtreemax5tan: ["#645452", "#D2B48C"],
  greenpalmkhaki: ["#00FF00", "#F0E68C"],
  greenbrown: ["#00FF00", "#964B00"],
  greykhaki: ["#808080", "#F0E68C"],
  kellygreenkhaki: ["#4CBB17", "#F0E68C"],
  khakikhaki: ["#F0E68C", "#F0E68C"],
  surfgreenheather: ["#00FF7F"],
  melanegedarkgreywhite: ["#696969", "#FFFFFF"],
  melangeheatherwhite: ["#A9A9A9", "#FFFFFF"],
  melanegenavywhite: ["#000080", "#FFFFFF"],
  keywest: ["#B3D9D9"],
  retrocamo: ["#78866B"],
  ironwhite: ["#CD5C5C", "#FFFFFF"],
  islandblue: ["#22BAA0"],
  greyblackbuffalo: ["#808080", "#000000"],
  redbuffalo: ["#FF0000"],
  trueredstars: ["#FF0000"],
  whiteheathermustard: ["#FFFFFF", "#FDBE46"],
  whiteheatherarctic: ["#FFFFFF", "#D0D3D4"],
  melangeblue: ["#4682B4"],
  java: ["#D2691E"],
  jazzbrypnktrbl: ["#DB7093"],
  seafoamblue: ["#77DD77"],
  slushieclassic: ["#30D5C8"],
  florainbow: ["#FF4500"],
  caramel: ["#FFD700"],
  orangeathleticgrey: ["#FFA500", "#A9A9A9"],
  lightdenimheather: ["#A8D0E6"],
  hyperbluecharcoal: ["#4682B4", "#333333"],
  hyperpinkcharcoal: ["#FF69B4", "#333333"],
  snowheathergreen: ["#90EE90"],
  heathergreykellygreen: ["#A9A9A9", "#4CBB17"],
  jetblackgold: ["#000000", "#FFD700"],
  jetblackwhite: ["#000000", "#FFFFFF"],
  jetblackfred: ["#000000", "#FF0000"],
  jetblackhpink: ["#000000", "#FFC0CB"],
  jetblackhthgry: ["#000000", "#A9A9A9"],
  jgtlbjgtl: ["#3D9970", "#F5F5DC", "#3D9970"],
  jgtlcjgtl: ["#3D9970", "#696969", "#3D9970"],
  junglecamo: ["#78866B"],
  jetblackoracru: ["#000000", "#FF8C00"],
  jblacksyellow: ["#000000", "#FFD700"],
  jungle: ["#29AB87"],
  junglebrown: ["#29AB87", "#964B00"],
  jungleoyster: ["#29AB87", "#C0C0C0"],
  dust: ["#B2996E"],
  greyoatmeal: ["#808080", "#F3E5AB"],
  rosecrystal: ["#FF66CC"],
  classicrainbowspiral: ["#D2E80D"],
  pinkblack: ["#FFC0CB", "#000000"],
  biminibluepeach: ["#01B8AA", "#FFDAB9"],
  kellygreenhthr: ["#4CBB17"],
  kellygrnheathr: ["#4CBB17"],
  oxfordredtomboyplaid: ["#B22222"],
  kellyathheather: ["#4CBB17", "#A9A9A9"],
  kellyblack: ["#4CBB17", "#000000"],
  kelp: ["#4B6922"],
  royalblackroyalprint: ["#4169E1", "#000000", "#4169E1"],
  columbiabluecolumbiablue: ["#B0E0E6", "#B0E0E6"],
  greythreemelangegreythreeblack: ["#A9A9A9", "#A9A9A9", "#000000"],
  safetyyellowgreen: ["#9ACD32"],
  khakibrownblk: ["#F0E68C", "#964B00", "#000000"],
  khakiflagtaculr: ["#F0E68C"],
  khakigreen: ["#8B864E"],
  khakiheather: ["#F0E68C"],
  khakiaqua: ["#F0E68C", "#00FFFF"],
  khakiblack: ["#F0E68C", "#000000"],
  khakiblkrope: ["#F0E68C", "#000000"],
  khakiblkblk: ["#F0E68C", "#000000", "#000000"],
  khakiblue: ["#F0E68C", "#0000FF"],
  khakicactus: ["#F0E68C", "#5F5F37"],
  ribboncandy: ["#FF83CC"],
  khakicharcoal: ["#F0E68C", "#333333"],
  khakicoral: ["#F0E68C", "#FF7F50"],
  khakidkgreen: ["#F0E68C", "#006400"],
  khakimidblue: ["#F0E68C", "#0000CD"],
  khakipurple: ["#F0E68C", "#800080"],
  khakiroyal: ["#F0E68C", "#4169E1"],
  khakitan: ["#F0E68C", "#D2B48C"],
  khakiteal: ["#F0E68C", "#008080"],
  khakiwillow: ["#F0E68C", "#9ACD32"],
  khakidusk: ["#F0E68C", "#3C2F41"],
  khakispruce: ["#F0E68C", "#A3A484"],
  kellywhistripe: ["#4CBB17", "#FFFFFF"],
  kllywhstrwht: ["#4CBB17", "#FFFFFF", "#FFFFFF"],
  kellyws: ["#4CBB17"],
  kellyhthrgrey: ["#4CBB17", "#A9A9A9"],
  kellygreenawhi: ["#4CBB17", "#F0F8FF"],
  knitblackhthr: ["#000000"],
  kryptekraid: ["#6A5B52"],
  kyanosblue: ["#4F83C4"],
  lagoontiedye: ["#5DBCD2"],
  lakerpurple: ["#7D3CB5"],
  lavalamp: ["#FF4500"],
  darkplum: ["#8E4585"],
  blackdigi: ["#000000"],
  lavendertan: ["#E6E6FA", "#D2B48C"],
  columbiabluecolumbiabluedigital: ["#B0E0E6", "#B0E0E6"],
  legbrwnoyster: ["#A0522D", "#C0C0C0"],
  legacybrown: ["#8B4513"],
  libertyred: ["#B32134"],
  lichen: ["#6A936D"],
  collegiatenavymelangemidgreymelange: ["#120A8F", "#696969"],
  techink: ["#4B0082"],
  redheathergraphiteheather: ["#FF0000", "#A9A9A9"],
  blackcarbonheather: ["#000000", "#696969"],
  goldgold: ["#FFD700", "#FFD700"],
  blackredblack: ["#000000", "#FF0000", "#000000"],
  reddigitalredred: ["#FF0000", "#FF0000", "#FF0000"],
  brightmulti: ["#FF1493"],
  whitebabyblue: ["#FFFFFF", "#89CFF0"],
  whiteneonblue: ["#FFFFFF", "#1B03A3"],
  whiteneonorange: ["#FFFFFF", "#FF4500"],
  whiteneonpink: ["#FFFFFF", "#FF6EB4"],
  blackblackstriped: ["#000000", "#000000"],
  blackwhitecamo: ["#000000", "#FFFFFF"],
  burntorangeburntorangecamo: ["#CC5500", "#CC5500"],
  purplepurplecamo: ["#800080", "#800080"],
  graphitesafetyorange: ["#696969", "#FF6600"],
  brownwaxycanvas: ["#964B00"],
  gobi: ["#A49A87"],
  blacklightcharcoal: ["#000000", "#262626"],
  aluminumfade: ["#A9ACB6"],
  ltblue: ["#ADD8E6"],
  royalheathergraphiteheather: ["#4169E1", "#A9A9A9"],
  lightblueblk: ["#ADD8E6", "#000000"],
  ancientfossil: ["#6D6C6A"],
  lightbluewht: ["#ADD8E6", "#FFFFFF"],
  lightforest: ["#A0DBA4"],
  oatmealheathersaltpepperstripe: ["#F5DEB3", "#A9A9A9"],
  blackwhitehotpink: ["#000000", "#FFFFFF", "#FF69B4"],
  whiteneonyellow: ["#FFFFFF", "#CCFF00"],
  ballerinawhitestripewhite: ["#FFB6C1", "#FFFFFF", "#FFFFFF"],
  blendedwhitevintageheatherwhite: ["#FFFFFF", "#D1D0CE", "#FFFFFF"],
  lightblueindigo: ["#ADD8E6", "#4B0082"],
  lightgreyblck: ["#D3D3D3", "#000000"],
  lightheather: ["#D3D3D3"],
  silverheathergreywhite: ["#C0C0C0", "#A9A9A9", "#FFFFFF"],
  texasorangeheathergreywhite: ["#FF781F", "#A9A9A9", "#FFFFFF"],
  vegasgoldheathergreywhite: ["#C5B358", "#A9A9A9", "#FFFFFF"],
  navychrome: ["#000080"],
  thundercloud: ["#4A545C"],
  purplesilver: ["#800080", "#C0C0C0"],
  navywhiteredwhitestripe: ["#000080", "#FFFFFF", "#FF0000"],
  bsbluewhitestripe36: ["#0000FF", "#FFFFFF"],
  blackblackscarlet: ["#000000", "#000000", "#FF2400"],
  blacklightgoldlightgold: ["#000000", "#FFD700", "#FFD700"],
  graphiteblackwhite: ["#696969", "#000000", "#FFFFFF"],
  columbiabluedigi: ["#B0E0E6"],
  darkgreendigi: ["#006400"],
  limedigi: ["#00FF00"],
  maroondigi: ["#800000"],
  graphitenavywhite: ["#696969", "#000080", "#FFFFFF"],
  icetriblend: ["#E0FFFF"],
  navytonal: ["#000080"],
  lightpinkwht: ["#FFB6C1", "#FFFFFF"],
  oxfordgreyheather: ["#7F8C8D"],
  navydigi: ["#000080"],
  orangedigi: ["#FFA500"],
  lilacgrey: ["#C8A2C8"],
  lilacgreyhthr: ["#C8A2C8"],
  lilacheathergrey: ["#C8A2C8", "#A9A9A9"],
  whitehotpink: ["#FFFFFF", "#FF69B4"],
  lilacmist: ["#E6E6FA"],
  lilacdhtgry: ["#C8A2C8"],
  lilachthrgray: ["#C8A2C8"],
  charcoalblackbuffalo: ["#36454F", "#000000"],
  tealwhite: ["#008080", "#FFFFFF"],
  poweryellowslate: ["#FFEC40", "#778899"],
  blackwhiteprint: ["#000000", "#FFFFFF"],
  limeblackprint: ["#00FF00", "#000000"],
  graphiteblackprint: ["#696969", "#000000"],
  navywhiteprint: ["#000080", "#FFFFFF"],
  orangeblackprint: ["#FFA500", "#000000"],
  powerblueblackprint: ["#1F8FFF", "#000000"],
  purpledigi: ["#800080"],
  royaldigi: ["#4169E1"],
  sanddigi: ["#C2B280"],
  powerbluedigi: ["#1F8FFF"],
  poweryellowdigi: ["#FFEC40"],
  reddigi: ["#FF0000"],
  powerblueblackwhite: ["#1F8FFF", "#000000", "#FFFFFF"],
  twistedbordeaux: ["#872657"],
  orchidsophiaplaid: ["#DA70D6"],
  bear: ["#73503C"],
  lotus: ["#DCCBDD"],
  graphiteheatherpowerpink: ["#696969", "#FF69B4"],
  limegreenblk: ["#00FF00", "#000000"],
  limegreenblue: ["#00FF00", "#0000FF"],
  limegreengray: ["#00FF00", "#808080"],
  limegreengrn: ["#00FF00", "#008000"],
  limegreenorng: ["#00FF00", "#FFA500"],
  limegreenpink: ["#00FF00", "#FFC0CB"],
  limegreenred: ["#00FF00", "#FF0000"],
  limegreenteal: ["#00FF00", "#008080"],
  limegreenwht: ["#00FF00", "#FFFFFF"],
  limegreenyllw: ["#00FF00", "#FFFF00"],
  limegrndkgry: ["#00FF00", "#A9A9A9"],
  limegrnltblu: ["#00FF00", "#ADD8E6"],
  limegrnreflct: ["#00FF00"],
  poweryellowblackprint: ["#FFEC40", "#000000"],
  hunterstripe: ["#3E8A5D"],
  navypaisley: ["#000080"],
  tyedyepaisley: ["#FE4365"],
  purplegraphiteheather: ["#800080", "#A9A9A9"],
  limestripe: ["#00FF00"],
  limegrhtlme: ["#00FF00"],
  spicyorange: ["#FF5600"],
  limeroyal: ["#00FF00", "#4169E1"],
  linclngrn: ["#195905"],
  lincolngreen: ["#195905"],
  linen: ["#FAF0E6"],
  lollypop: ["#CC0066"],
  londongrey: ["#A9A9A9"],
  lp104: ["#DCD0FF"],
  ltblunatbrn: ["#ADD8E6", "#FFFAF0", "#964B00"],
  ltblueheather: ["#ADD8E6"],
  ltbluetiedye: ["#ADD8E6"],
  ltbluegraphit: ["#ADD8E6", "#696969"],
  ltbluenavy: ["#ADD8E6", "#000080"],
  ltbluewhite: ["#ADD8E6", "#FFFFFF"],
  ltcollegeblue: ["#8AA8FF"],
  ltdenimofwht: ["#3A6794", "#F5F5DC"],
  ltgrayheather: ["#D3D3D3"],
  ltgraynavy: ["#D3D3D3", "#000080"],
  ltgreyblack: ["#D3D3D3", "#000000"],
  ltgryneongrn: ["#D3D3D3", "#39FF14"],
  ltheathergray: ["#D3D3D3"],
  lththrcarbon: ["#D3D3D3", "#4B4B4B"],
  ltnauticalblu: ["#6093D1"],
  ltpurplekhaki: ["#D8BFD8", "#F0E68C"],
  ltblueindigo: ["#ADD8E6", "#4B0082"],
  ltgoldheather: ["#FFD700"],
  lunarrock: ["#C4C3C0"],
  mbeigechaambg: ["#F5F5DC", "#704214", "#A52A2A"],
  mocountryblaze: ["#4B5320", "#FFA07A"],
  ma1: ["#000000"],
  ma10: ["#000000"],
  ma11: ["#000000"],
  ma2: ["#000000"],
  ma3: ["#000000"],
  ma4: ["#000000"],
  ma5: ["#000000"],
  ma6: ["#000000"],
  ma7: ["#000000"],
  ma8: ["#000000"],
  ma9: ["#000000"],
  graphiteheatherpowerblue: ["#696969", "#1F8FFF"],
  magentamagic: ["#FF00FF"],
  magentaneonyel: ["#FF00FF", "#FFFF00"],
  heatherbabyblue: ["#89CFF0"],
  mantawhite: ["#EAEDED", "#FFFFFF"],
  manzanita: ["#AF4034"],
  marblforestgrn: ["#0D635D"],
  marblegalpblue: ["#6A90B0"],
  marblemaroon: ["#800000"],
  mardigras: ["#880085"],
  slateathleticheatherwhite: ["#778899", "#696969", "#FFFFFF"],
  marineblutan: ["#013ADF", "#D2B48C"],
  marineblue: ["#013ADF"],
  bluemistlightteal: ["#838EBB", "#90E0D0"],
  aquafade: ["#00FFFF"],
  ballerinaballerinamauvelousstripe: ["#FFA0E0", "#FFA0E0"],
  nvnavy: ["#000080"],
  marineheather: ["#013ADF"],
  powerpinkslatewhite: ["#FF69B4", "#778899", "#FFFFFF"],
  marineocean: ["#013ADF", "#1E90FF"],
  snowheathergrey: ["#D1D1D1"],
  snowheatherindigo: ["#4B0082"],
  marledbluheron: ["#00008B"],
  marnwhtslvr: ["#800000", "#FFFFFF", "#C0C0C0"],
  striatedredstriatedblack: ["#FF0000", "#000000"],
  solidtrueroyaltriblend: ["#4169E1"],
  ectrudeeppurple: ["#800080"],
  ectruedustypine: ["#556B2F"],
  heatherblush: ["#FF6EB4"],
  pinkglitterblack: ["#FF69B4", "#000000"],
  redglitterblack: ["#FF0000", "#000000"],
  royalglitterblack: ["#4169E1", "#000000"],
  silverglitterblack: ["#C0C0C0", "#000000"],
  powerpinkdigi: ["#FF69B4"],
  orangegraphite: ["#FFA500", "#696969"],
  kellygoldwhite: ["#32CD32", "#FFD700", "#FFFFFF"],
  oxfordforest: ["#808080", "#228B22"],
  vegasgolddigital: ["#C5B358"],
  granitehunter: ["#676767", "#3B5323"],
  maroonstripe: ["#800000"],
  garnetwhite: ["#8B0000", "#FFFFFF"],
  sandheather: ["#C2B280"],
  blackcrinkle: ["#000000"],
  charcoalheathershark: ["#36454F", "#43464B"],
  deltaheatherdelta: ["#C7C7C7"],
  inferno: ["#FF4C00"],
  blackrealtreehardwood: ["#000000"],
  brownrealtreeap: ["#654321"],
  olivehardwoods: ["#808000"],
  tanrealtreemax4: ["#D2B48C"],
  mossyoakshadowgrass: ["#556B2F"],
  tricoffee: ["#704214"],
  heatherlilac: ["#DA70D6"],
  maroonhthrmarmaroonhthrwht: ["#800000", "#800000", "#FFFFFF"],
  maroonombre: ["#800000"],
  maroonathheathr: ["#800000"],
  maroongray: ["#800000", "#808080"],
  columbiagrey: ["#91A3B0"],
  blackrainbowcutspiral: ["#000000"],
  maroonblackwhi: ["#800000", "#000000", "#FFFFFF"],
  maroongreyhthr: ["#800000", "#B0C4DE"],
  maroonsteelwhi: ["#800000", "#4682B4", "#FFFFFF"],
  bluedenim37ui: ["#1560BD"],
  kellyredwhite: ["#32CD32", "#FF0000", "#FFFFFF"],
  mauvepeprhthr: ["#E0B0FF"],
  whiteheathercharcoal: ["#FFFFFF", "#36454F"],
  mauvelousballeri: ["#EF98AA"],
  mdgyhthpblk: ["#696969", "#000000"],
  mdnavystl: ["#000080", "#4682B4"],
  mdnydhtrgry: ["#000080", "#696969"],
  mdntnvynaturl: ["#191970"],
  meadow: ["#8FBC8F"],
  purplegreyheather: ["#800080", "#B0C4DE"],
  purpleathleticgrey: ["#800080", "#808080"],
  whiteroyalcamo: ["#FFFFFF", "#4169E1"],
  meerkat: ["#733D1A"],
  kellygreenheather: ["#32CD32"],
  meteorite: ["#3A5FCD"],
  mg1: ["#2B2B2B"],
  mg2: ["#3B3B3B"],
  mg3: ["#4B4B4B"],
  citygreyshark: ["#3D3D3D", "#43464B"],
  midnvyhthgry: ["#000080", "#B0C4DE"],
  midnvywht: ["#000080", "#FFFFFF"],
  midnyhthrgry: ["#000080", "#696969"],
  midnightblue: ["#191970"],
  lightflashpurpleblack: ["#D02090", "#000000"],
  truenavysolid: ["#000080"],
  darkorange: ["#FF8C00"],
  medievalblue: ["#483D8B"],
  whitenavyredthickstripe: ["#FFFFFF", "#000080", "#FF0000"],
  whitemint2stripe: ["#FFFFFF", "#98FF98"],
  midniteblue: ["#191970"],
  charcoalblackwhite: ["#36454F", "#000000", "#FFFFFF"],
  graphiteheatherpoweryellow: ["#696969", "#FFFF00"],
  sunrayheathergrey: ["#FDB813", "#B0C4DE"],
  militarygreenh: ["#556B2F"],
  mandarinheather: ["#FF4500"],
  vintagenavyvintagegrey: ["#191970", "#808080"],
  militarygrnhth: ["#556B2F"],
  militaryolive: ["#556B2F"],
  milkyway: ["#F0EAD6"],
  zion: ["#A9A9A9"],
  miltrygrncrm: ["#556B2F", "#FFFDD0"],
  navyfrostygrey: ["#000080", "#778899"],
  mintfusion: ["#98FF98"],
  turquoiseblack: ["#40E0D0", "#000000"],
  darkheathergreynavy: ["#696969", "#000080"],
  darkheathergreyred: ["#696969", "#FF0000"],
  minththoxford: ["#98FF98", "#808080"],
  minththrwhite: ["#98FF98", "#FFFFFF"],
  mintsnowhthr: ["#98FF98"],
  minttobehthr: ["#98FF98"],
  mintmarine: ["#98FF98", "#000080"],
  mississippimud: ["#734A12"],
  mlgreenwhite: ["#006400", "#FFFFFF"],
  mltrygreenblk: ["#556B2F", "#000000"],
  mohabitat: ["#6E8B3D"],
  mocha: ["#3E2723"],
  mochavnlla: ["#3E2723", "#FFF5E1"],
  moltenred: ["#B22222"],
  moonbeam: ["#F5F5DC"],
  moondance: ["#D3D3D3"],
  moondancered: ["#FF4500"],
  heathergraniteblack: ["#706565", "#000000"],
  trueblueheather: ["#007FFF"],
  navycolumbiabluewhite: ["#000080", "#9BDDFF", "#FFFFFF"],
  charcoalredsplit: ["#36454F", "#FF0000"],
  charcoalroyalsplit: ["#36454F", "#4169E1"],
  charcoalwhitesplit: ["#36454F", "#FFFFFF"],
  greywhiteblacktri: ["#808080", "#FFFFFF", "#000000"],
  marooncharcoalblacktri: ["#800000", "#36454F", "#000000"],
  navywhitesplit: ["#000080", "#FFFFFF"],
  navywhiteredtri: ["#000080", "#FFFFFF", "#FF0000"],
  redcharcoalblacktri: ["#FF0000", "#36454F", "#000000"],
  redwhitesplit: ["#FF0000", "#FFFFFF"],
  mossgreenbge: ["#4A5D23", "#F5F5DC"],
  mossgreenblk: ["#4A5D23", "#000000"],
  mossgrnltchr: ["#4A5D23", "#D3D3D3"],
  fluorescentrainbowswirl: [
    "#FFA500",
    "#4B0082",
    "#ADFF2F",
    "#8A2BE2",
    "#FF4500",
    "#0000FF",
  ],
  khakidesertcamo: ["#F0E68C", "#DEB887"],
  solidasphaltblend: ["#555555"],
  msgrnltcmg: ["#4A5D23", "#B0C4DE", "#2E8B57"],
  ms1: ["#366738"],
  mshbkgrphor: ["#404040", "#FF4500"],
  mtnredcolnvy: ["#8B0000", "#000080"],
  mulberry: ["#C54B8C"],
  multirainbow: ["#FF0000", "#0000FF", "#00FF00", "#FFFF00", "#FF00FF"],
  multcorcoyoteb: ["#FF4500", "#AD8F67"],
  multiblack: ["#000000"],
  multicolor: ["#FFFFFF", "#000000"],
  multicolorheart: ["#FF6347"],
  multicolormushrm: ["#000000"],
  mustardbaywh: ["#FFDB58", "#87CEFA", "#FFFFFF"],
  mutedblue: ["#6A5ACD"],
  mykonos: ["#FFC0CB"],
  nblugrpplt: ["#000080", "#9370DB", "#D1BEA8"],
  nantcktredkha: ["#E32636", "#BDB76B"],
  lightpinkkellywhite: ["#FFB6C1", "#4CBB17", "#FFFFFF"],
  maroongoldwhite: ["#800000", "#D4AF37", "#FFFFFF"],
  starsstripestriblend: ["#B22234", "#FFFFFF"],
  naturalhvmilg: ["#F5E9D3", "#4B5320"],
  navystripe: ["#000080"],
  naturalhheather: ["#F5E9D3"],
  naturalblue: ["#F5E9D3", "#0000FF"],
  naturalbrkred: ["#F5E9D3", "#8B0000"],
  naturalbrown: ["#F5E9D3", "#8B4513"],
  naturalchrcl: ["#F5E9D3", "#36454F"],
  naturalhotpnk: ["#F5E9D3", "#FF69B4"],
  naturalltblue: ["#F5E9D3", "#ADD8E6"],
  naturalmstblu: ["#F5E9D3", "#60A4F4"],
  navywhitescarlet: ["#000080", "#FFFFFF", "#FF2400"],
  naturalpink: ["#F5E9D3", "#FFC0CB"],
  naturlbkfxfr: ["#F5E9D3", "#292421"],
  nautblupltnm: ["#000080", "#E5E4E2"],
  nauticalred: ["#FF0000"],
  nautcanvywht: ["#1D2951", "#FFFFFF"],
  nautclredkhki: ["#FF0000", "#F0E68C"],
  nauticanavy: ["#1D2951"],
  nauticanavywh: ["#1D2951", "#FFFFFF"],
  nauticanvyroy: ["#1D2951", "#4169E1"],
  nauticalblue: ["#1D2951"],
  collegiateburgundyblack: ["#8D021F", "#000000"],
  collegiateroyalblack: ["#4169E1", "#000000"],
  whitenvygrey: ["#FFFFFF", "#000080", "#808080"],
  solidbluetriblend: ["#0000FF"],
  goldenstatewarriors: ["#1D428A"],
  whitereactive: ["#FFFFFF"],
  deepgreen: ["#006400"],
  limewhitesilvergrey: ["#00FF00", "#FFFFFF", "#C0C0C0"],
  vintagescarletvintagegrey: ["#C24641", "#808080"],
  poweryellowgraphite: ["#FFDF00", "#1C1C1C"],
  athleticheatherorange: ["#B5B5B5", "#FF4500"],
  redstripe: ["#FF0000"],
  deepheatherspeckled: ["#666666"],
  carbonheatherburntorange: ["#36454F", "#CC5500"],
  columbiacamocolumbiacolumbia: ["#9DD3DF", "#9DD3DF", "#9DD3DF"],
  hotpinklimehotpink: ["#FF69B4", "#00FF00", "#FF69B4"],
  maroondigitalmaroonmaroon: ["#800000", "#800000", "#800000"],
  royalblendroyalroyal: ["#000080", "#000080", "#000080"],
  royalsafetyyellowroyal: ["#000080", "#FFAA00", "#000080"],
  sandcamosandgraphite: ["#C2B280", "#C2B280", "#1C1C1C"],
  oilred: ["#8B0000"],
  darkmulti: ["#333333"],
  arcticgrey: ["#808080"],
  hotpinkhotpinktonalblend: ["#FF69B4", "#FF69B4"],
  cardinaldigital: ["#8C1515"],
  whitedigital: ["#FFFFFF"],
  heatherroyalblue: ["#5D8AA8"],
  candyheartsheather: ["#E07A5F"],
  railroadgreyheather: ["#8D8B8B"],
  melanegdarkgrey: ["#404040"],
  melangeheather: ["#A9A9A9"],
  sweetcreamheatherindigoheather: ["#FFF5E1", "#4B0082"],
  realtreeedgemesh: ["#5C4939"],
  mirage: ["#C0C0C0"],
  andromeda: ["#9C7E9A"],
  greyheathercurrant: ["#B5B5B5", "#DC143C"],
  greyheatherslateblue: ["#B5B5B5", "#6A5ACD"],
  navytitanium: ["#000080", "#878681"],
  camelbrown: ["#C19A6B", "#8B4513"],
  darkgreengrey: ["#006400", "#808080"],
  greencamelbrown: ["#008000", "#C19A6B", "#8B4513"],
  khakigrey: ["#F0E68C", "#808080"],
  marinebluegrey: ["#013A65", "#808080"],
  marinenavygrey: ["#013A65", "#000080", "#808080"],
  nantucketredgrey: ["#E25822", "#808080"],
  washedmilitarygreen: ["#667C3E"],
  washedroyal: ["#000080"],
  heathergreymidnightnavy: ["#B5B5B5", "#000080"],
  navysteel: ["#000080", "#4682B4"],
  blackrosegold: ["#000000", "#B76E79"],
  palaceblue: ["#4169E1"],
  quarrygrey: ["#A9A9A9"],
  charcoalneonorange: ["#36454F", "#FF4500"],
  charcoalneonyellow: ["#36454F", "#FFFF00"],
  blackwhiteheathergrey: ["#000000", "#FFFFFF", "#B5B5B5"],
  cardinalblack: ["#8C1515", "#000000"],
  charcoalneonpink: ["#36454F", "#FF6EC7"],
  kryptekpontuswhite: ["#5DADEC", "#FFFFFF"],
  whiteneonblueblack: ["#FFFFFF", "#1B03A3", "#000000"],
  coffeeclaret: ["#6F4E37", "#7F1734"],
  creamlodengreendarkorange: ["#FFFDD0", "#6C7C32", "#FF8C00"],
  cyanblack: ["#00FFFF", "#000000"],
  darklodenjaffaorange: ["#556832", "#FF8C00"],
  darkorangebirchpatriotblue: ["#FF8C00", "#FFE4C4", "#1C39BB"],
  heatherdarkgreensilver: ["#004B49", "#C0C0C0"],
  heathergreybirchambergold: ["#B5B5B5", "#FFE4C4", "#FFBF00"],
  blackcamostone: ["#000000", "#78866B", "#A9A9A9"],
  charcoalgarnet: ["#36454F", "#800000"],
  redgold: ["#FF0000", "#D4AF37"],
  blackwhitegreyred: ["#000000", "#FFFFFF", "#808080", "#FF0000"],
  maroonwhitegrey: ["#800000", "#FFFFFF", "#808080"],
  navywhitegreygold: ["#000080", "#FFFFFF", "#808080", "#D4AF37"],
  navywhitegreyred: ["#000080", "#FFFFFF", "#808080", "#FF0000"],
  agave: ["#67A5B4"],
  rainbowtiedye: ["#A0522D"],
  greenchicory: ["#20C073"],
  crimsonblack: ["#DC143C", "#000000"],
  cloudcovermelange: ["#B4C8BE"],
  bronzegreen: ["#4E5754"],
  bikingredwhitenavy: ["#D02090", "#FFFFFF", "#000080"],
  blackkhakibronzegreen: ["#000000", "#F0E68C", "#4E5754"],
  heatherjetblack: ["#2C2F33"],
  rosemauve: ["#C08081"],
  brownwhitebrown: ["#654321", "#FFFFFF", "#654321"],
  carolinabluewhitecarolinablue: ["#99BADD", "#FFFFFF", "#99BADD"],
  evergreenwhite: ["#006400", "#FFFFFF"],
  blackstarsstripes: ["#000000"],
  mineralblack: ["#121212"],
  mineralgrey: ["#3E3E40"],
  electricbluedigital: ["#7EF9FF"],
  whitewhiteblack: ["#FFFFFF", "#FFFFFF", "#000000"],
  mineralnavy: ["#151B54"],
  starsstripes: ["#B2BEB5"],
  hotpinkgraphite: ["#FF69B4", "#1C1C1C"],
  navyblazercamo: ["#000080"],
  navyblazerhthr: ["#000080"],
  blackolive: ["#000000", "#556B2F"],
  naturaltexasorange: ["#F5F5DC", "#FF5721"],
  purplepurpledigisilver: ["#800080", "#800080", "#C0C0C0"],
  navybluewhite: ["#000080", "#FFFFFF"],
  navyblzhghrs: ["#000080"],
  navyblzrhthhr: ["#000080"],
  abstractflag: ["#D40000"],
  navydot: ["#000080"],
  staygold: ["#FFD700"],
  whiteevergreen: ["#FFFFFF", "#006400"],
  navyhthrnavy: ["#000080", "#000080"],
  navyhthrwht: ["#000080", "#FFFFFF"],
  deepheatherdeepheather: ["#666666", "#666666"],
  navymelangenv: ["#000080"],
  navysnowheathr: ["#000080"],
  navyws: ["#000080"],
  navyathheather: ["#000080"],
  whitetruered: ["#FFFFFF", "#FF0000"],
  navyburgundy: ["#000080", "#800000"],
  navycamel: ["#000080", "#C19A6B"],
  navycream: ["#000080", "#FFFDD0"],
  navycreme: ["#000080", "#FFFDD0"],
  scarletgraphiteheather: ["#FF2400", "#607D8B"],
  navydkchrcoal: ["#000080"],
  navyfloral: ["#000080"],
  navygraph: ["#000080"],
  navygray: ["#000080", "#808080"],
  navygraygray: ["#000080", "#808080", "#808080"],
  navyhthrgray: ["#000080"],
  navyhthrgry: ["#000080"],
  navykhaki: ["#000080", "#F0E68C"],
  stonewash37ui: ["#789"],
  kryptekpontus: ["#5DADEC"],
  navymint: ["#000080", "#98FF98"],
  navynautred: ["#000080", "#FE6F5E"],
  navynavyhthr: ["#000080", "#000080"],
  navyneonylw: ["#000080", "#FFFF00"],
  navyolive: ["#000080", "#556B2F"],
  navyoyster: ["#000080"],
  navyredpatrtc: ["#000080", "#FF0000"],
  navyrednavy: ["#000080", "#FF0000", "#000080"],
  pollen: ["#FFFF31"],
  navyslvrnavy: ["#000080", "#C0C0C0", "#000080"],
  navyslvrwht: ["#000080", "#C0C0C0", "#FFFFFF"],
  navytan: ["#000080", "#D2B48C"],
  solidkellytriblend: ["#4CBB17"],
  triorchid: ["#DA70D6"],
  trilemon: ["#FFF44F"],
  navywhtgrph: ["#000080", "#FFFFFF", "#808080"],
  navywhtnavy: ["#000080", "#FFFFFF", "#000080"],
  navyblackcarbon: ["#000080", "#000000", "#4A4A4A"],
  navycarbon: ["#000080", "#4A4A4A"],
  navycarmel: ["#000080", "#954535"],
  navycharcoalfl: ["#000080", "#36454F"],
  navycolumbia: ["#000080", "#9BDDFF"],
  navycolumbiablu: ["#000080", "#9BDDFF"],
  navynavathgry: ["#000080", "#000080", "#696969"],
  navynavywswht: ["#000080", "#000080", "#FFFFFF"],
  navyscarletwhi: ["#000080", "#FF2400", "#FFFFFF"],
  navysteelwhite: ["#000080", "#808080", "#FFFFFF"],
  navytredwhite: ["#000080", "#8B0000", "#FFFFFF"],
  nectarine: ["#FFA07A"],
  charneonberry: ["#36454F", "#FF6EB4"],
  neonblueberry: ["#1E90FF"],
  neonbubblegum: ["#FF6EB4"],
  maroonnaturalbuffalo: ["#800000", "#F5DEB3"],
  smokegraphite: ["#6E7F80", "#607D8B"],
  charneongreen: ["#36454F", "#39FF14"],
  neonhthrpink: ["#FF69B4"],
  scarletroyal: ["#FF2400", "#4169E1"],
  coralheather: ["#FF6F61"],
  charneonorange: ["#36454F", "#FF4500"],
  neonorangehthr: ["#FF4500"],
  neonorngneylw: ["#FF4500", "#FFFF00"],
  aquawhitezipper: ["#00FFFF", "#FFFFFF"],
  charneonpink: ["#36454F", "#FF69B4"],
  neonpinktribld: ["#FF69B4"],
  neonpinkivory: ["#FF69B4", "#FFFFF0"],
  maroonoatmeal: ["#800000", "#F5DEB3"],
  flameyellow: ["#E25822"],
  merlotheatherblack: ["#800000", "#000000"],
  neonylwneorg: ["#FFFF00", "#FF4500"],
  newbottomland: ["#727A2F"],
  burgandyteal: ["#800020", "#008080"],
  dustyrosewhite: ["#F08080", "#FFFFFF"],
  fashioncamoblack: ["#78866B", "#000000"],
  wildberryveryberrytiedye: ["#8B008B", "#DDA0DD"],
  wildberrywhite: ["#8B008B", "#FFFFFF"],
  bananayellow: ["#E5E5AA", "#FFFF00"],
  royaldigitalcamocharcoal: ["#4169E1", "#36454F"],
  blackcheck: ["#000000"],
  techblue: ["#5E9DC8"],
  heathergraphitekhaki: ["#808080", "#F0E68C"],
  whitegoldthickstripe: ["#FFFFFF", "#D4AF37"],
  whitegoldblue: ["#FFFFFF", "#D4AF37", "#0000FF"],
  whitegoldmaroon: ["#FFFFFF", "#D4AF37", "#800000"],
  whitegoldpurple: ["#FFFFFF", "#D4AF37", "#800080"],
  cwwhitecharcoalstripe: ["#FFFFFF", "#36454F"],
  niagara: ["#30E5FF"],
  niagarablue: ["#30E5FF"],
  niagarabrown: ["#30E5FF", "#8B4513"],
  nickle: ["#727272"],
  night: ["#0C090A"],
  blackiceblue: ["#000000", "#B0E2FF"],
  nocturnal: ["#9400D3"],
  whitestpattys: ["#FFFFFF"],
  whitestars: ["#FFFFFF"],
  northend: ["#000080"],
  blackcamoloden: ["#000000", "#78866B", "#6C7C7C"],
  charcoalburntorangeblack: ["#36454F", "#CC5500", "#000000"],
  fbfrenchblue37: ["#0072BB"],
  fbfrenchblue38: ["#0072BB"],
  ntnavynvhth: ["#000080", "#000080"],
  ntnvyantqwht: ["#000080", "#FAEBD7"],
  nt102: ["#1D2951"],
  nude: ["#F5DABD"],
  null: ["#808080"],
  nutmeg: ["#7E5E60"],
  nvyblzhbrwh: ["#000080"],
  nvyblzskipat: ["#000080"],
  nvyblzrqtsh: ["#000080"],
  nvywhtstrwht: ["#000080", "#FFFFFF", "#FFFFFF"],
  nvygrhtgrap: ["#000080", "#808080", "#8B008B"],
  navytangerine: ["#000080", "#FFA500"],
  oatmealfleck: ["#F5DEB3"],
  oatmlhthrtk: ["#F5DEB3", "#8B7355"],
  obsession: ["#FF4500"],
  ocblbgamgd: ["#000080"],
  ocblltcob: ["#000080"],
  ocblnvocbl: ["#000080"],
  oceanbluehthr: ["#1C92C0"],
  oceanbluebge: ["#1C92C0", "#F5F5DC"],
  oceanbluechar: ["#1C92C0", "#36454F"],
  oceanbreeze: ["#4F94CD"],
  royalstripe: ["#4169E1"],
  offwhitedenim: ["#F5F5DC"],
  oilgreen: ["#556B2F"],
  oldgldblkrp: ["#CFB53B", "#000000"],
  oldgoldblack: ["#CFB53B", "#000000"],
  oldgoldoyster: ["#CFB53B", "#C0C0C0"],
  ecoivoryecotruevintageblack: ["#FFFFF0", "#000000"],
  ectruegreenecoivory: ["#008000", "#FFFFF0"],
  blackmodwhite: ["#000000", "#FFFFFF"],
  olivestan: ["#808000", "#D2B48C"],
  blackhighvisyellow: ["#000000", "#FFFF00"],
  oliveduck: ["#808000"],
  olivegreywht: ["#808000", "#FFFFFF"],
  olivegrncamo: ["#808000"],
  cationiccharcoal: ["#36454F"],
  tricream: ["#FFFDD0"],
  olivecamogrn: ["#808000", "#556B2F"],
  olivechestnut: ["#808000", "#CD5C5C"],
  oliveoyster: ["#808000", "#C0C0C0"],
  olymblucrbn: ["#1C86EE", "#696969"],
  olympicred: ["#FF4500"],
  one: ["#000000"],
  onyx: ["#0C0C0C"],
  opalblue: ["#66CCCC"],
  blackvintagecamo: ["#000000", "#78866B"],
  caribbeanwhite: ["#00CCCC", "#FFFFFF"],
  opticwhite: ["#FFFFFF"],
  orangeathheathr: ["#FFA500", "#696969"],
  solidslatetriblend: ["#708090"],
  fadedpinkpigment: ["#FFDAB9"],
  redblackprint: ["#FF0000", "#000000"],
  royalblackprint: ["#4169E1", "#000000"],
  kellyblackwhite: ["#4CBB17", "#000000", "#FFFFFF"],
  silverstripe: ["#C0C0C0"],
  blacknaturalbuffaloplaid: ["#000000"],
  borabora: ["#66CCCC"],
  blendedwhitetitanium: ["#FFFFFF", "#D1D1E0"],
  cranberryblack: ["#DC143C", "#000000"],
  tiedyedpastel: ["#DEA5A4"],
  armygreenstone: ["#556B2F", "#696969"],
  bottlegreenstone: ["#006A4E", "#696969"],
  orangecamo: ["#FFA500"],
  heathermilitary: ["#708090"],
  lightbluesand: ["#ADD8E6", "#F4A460"],
  orangepoppy: ["#FF4500"],
  orangeblue: ["#FFA500", "#0000FF"],
  orangegray: ["#FFA500", "#808080"],
  orangegreen: ["#FFA500", "#008000"],
  orangelimegrn: ["#FFA500", "#32CD32"],
  orangeltblue: ["#FFA500", "#ADD8E6"],
  orangepink: ["#FFA500", "#FFC0CB"],
  orangepurple: ["#FFA500", "#800080"],
  orangered: ["#FFA500", "#FF0000"],
  orangeteal: ["#FFA500", "#008080"],
  yosemite: ["#65AAB0"],
  orangeyellow: ["#FFA500", "#FFFF00"],
  orangeblackwhi: ["#FFA500", "#000000", "#FFFFFF"],
  orangecarbonh: ["#FFA500", "#A9A9A9"],
  orcagrey: ["#848482"],
  heathercomfreymauve: ["#B48395"],
  orchidheather: ["#DA70D6"],
  darkashblack: ["#B2BEB5", "#000000"],
  origbottomland: ["#727A5E"],
  orion: ["#535353"],
  orngpoppyoyst: ["#FF4500", "#C0C0C0"],
  oxfgrytmblue: ["#D3D3D3", "#4682B4"],
  oxfnavyhgrey: ["#000080", "#B0C4DE"],
  oxfnavysunyel: ["#000080", "#FDB813"],
  realtreextramesh: ["#706950"],
  barkorange: ["#8B4513", "#FFA500"],
  brownrealtreextra: ["#8B4513", "#706950"],
  realtreextraamericana: ["#706950"],
  realtreextraorange: ["#706950", "#FFA500"],
  washedroyalpine: ["#4169E1"],
  oxfordlilac: ["#E0B0FF"],
  oxfordnavy: ["#000080"],
  oxfordnavywhite: ["#000080", "#FFFFFF"],
  oxfordorange: ["#FFA500"],
  oxfordpink: ["#FFC0CB"],
  oxfordyellow: ["#FFFF00"],
  oxfordindigohth: ["#808080", "#4B0082"],
  oxfordindghth: ["#808080", "#4B0082"],
  oxfordminthth: ["#808080", "#98FF98"],
  oxfordminththr: ["#808080", "#98FF98"],
  oxfordnatnvy: ["#808080", "#000080"],
  oxfordnautcnv: ["#808080", "#000080"],
  maroonmaroontonalblend: ["#800000", "#800000"],
  burntorangeburntorangetonalblend: ["#CC5500", "#CC5500"],
  columbiabluecolumbiabluetonalblend: ["#C4D8E2", "#C4D8E2"],
  electricblueelectricbluetonalblend: ["#7DF9FF", "#7DF9FF"],
  goldgoldtonalblend: ["#FFD700", "#FFD700"],
  oxfordfieryred: ["#F5F5DC", "#FF4500"],
  oxfrdirgrnht: ["#F5F5DC", "#32CD32"],
  oxfordtrueblue: ["#F5F5DC", "#0000FF"],
  oysteroyster: ["#C0C0C0", "#C0C0C0"],
  oystrpacfcblk: ["#C0C0C0", "#1CA9C9", "#000000"],
  psunsetgold: ["#FDB813"],
  pa102: ["#A8A899"],
  buttercream: ["#FFF6E0"],
  pacificoyster: ["#1CA9C9", "#C0C0C0"],
  athleticgreyblack: ["#B0C4DE", "#000000"],
  palmgreen: ["#98FB98"],
  palmparadise: ["#98FB98"],
  pantlbeige: ["#3CB371", "#F5F5DC"],
  pantlcharcoal: ["#3CB371", "#708090"],
  vintageorangevintagegrey: ["#FFA500", "#D3D3D3"],
  mlmediumbluelightblue: ["#0000CD", "#ADD8E6"],
  parrotred: ["#FF4500"],
  spiderlime: ["#00FF00"],
  blackgraphiteprint: ["#000000", "#708090"],
  pastelneon: ["#77DD77"],
  pastelpurple: ["#B19CD9"],
  pe102: ["#A8A899"],
  blackredprint: ["#000000", "#FF0000"],
  peacoat: ["#1F4D5A"],
  peacoatheather: ["#1F4D5A"],
  peacoatbrtwht: ["#1F4D5A", "#FFFFFF"],
  peacoatqutshd: ["#1F4D5A", "#8B4513"],
  orchidwhite: ["#DA70D6", "#FFFFFF"],
  navynaturalbuffalo: ["#000080", "#FFF8DC"],
  chocolatenaturalkhaki: ["#8B4513", "#FFF8DC", "#F0E68C"],
  collageblue: ["#79A3CF"],
  peanutbutter: ["#FFE5B4"],
  poppyred: ["#FF4500"],
  sapphirepepperheather: ["#0F52BA"],
  pebbleblue: ["#4682B4"],
  peonypink: ["#FFC0CB"],
  peonypkdeppnk: ["#FFC0CB", "#FF1493"],
  pepperedredhthr: ["#FF4500"],
  peppermint: ["#3CB371"],
  periwinklehthr: ["#CCCCFF"],
  periwinkletidy: ["#CCCCFF"],
  whiteflecknavytriblend: ["#FFFFFF", "#1E90FF"],
  limemesh: ["#00FF00"],
  slatespot: ["#708090"],
  saffroncamo: ["#F4C430", "#78866B"],
  stonebronzelightolivegreen: ["#8B4513", "#CD7F32", "#556B2F"],
  tannavyolivegreen: ["#D2B48C", "#000080", "#556B2F"],
  wheatfieldblack: ["#F5DEB3", "#000000"],
  whitecaramelbrown: ["#FFFFFF", "#FFA07A", "#8B4513"],
  whiteredroyal: ["#FFFFFF", "#FF0000", "#4169E1"],
  tealsophiaplaid: ["#008080"],
  blackpepperblack: ["#000000", "#000000"],
  forestnaturalkhaki: ["#228B22", "#FAF0E6", "#F0E68C"],
  smokeyblue: ["#A1CAF1"],
  darkbluecombo: ["#00008B"],
  bikingred: ["#B22222"],
  orchidhush: ["#DA70D6"],
  multicamarid: ["#806517"],
  multicamtropic: ["#4B5320"],
  pgmnthedgegren: ["#008000"],
  pgmntsunsetgld: ["#FDB813"],
  pgmthedgegreen: ["#008000"],
  picante: ["#FF4500"],
  picanteheather: ["#FF4500"],
  picanteoyster: ["#FF4500", "#C0C0C0"],
  pigmentnavy: ["#000080"],
  deepheatherdarkgreyheather: ["#B0C4DE", "#A9A9A9"],
  pigmentwhite: ["#FFFFFF"],
  pinacolada: ["#FFE5B4"],
  pineapple: ["#FFE5B4"],
  blackroyalprint: ["#000000", "#4169E1"],
  oxfordkelly: ["#F5F5DC", "#4CBB17"],
  pinkcamouflage: ["#FFC0CB"],
  pinkheart: ["#FFC0CB"],
  pinklemondehth: ["#FFB6C1"],
  redkhaki: ["#FF0000", "#F0E68C"],
  aquaaqua: ["#00FFFF", "#00FFFF"],
  brownnavy: ["#8B4513", "#000080"],
  bluecrinkle: ["#1E90FF"],
  kellymist: ["#4CBB17"],
  blackrealtreeap: ["#000000", "#78866B"],
  blazerealtreehardwoods: ["#FF4500", "#5C4033"],
  whitegreenyellow: ["#FFFFFF", "#ADFF2F"],
  whitelightbluered: ["#FFFFFF", "#ADD8E6", "#FF0000"],
  pinkribbon: ["#FFC0CB"],
  pinkseersucker: ["#FFC0CB"],
  pinksnowhthr: ["#FFC0CB"],
  pinkgrey: ["#FFC0CB", "#D3D3D3"],
  pitchgrey: ["#696969"],
  placidbluehthr: ["#ADD8E6"],
  plaidblueu: ["#1E90FF"],
  plaidgraya: ["#B0C4DE"],
  plaidgreen: ["#008000"],
  plaidred: ["#FF0000"],
  plaidslate: ["#708090"],
  plaidwhiteblk: ["#FFFFFF", "#000000"],
  platinumcharcoal: ["#E5E4E2", "#708090"],
  platnmcrbn: ["#E5E4E2"],
  platnmolyblu: ["#E5E4E2", "#4169E1"],
  plcdbluhnbb: ["#5F6F81", "#000080"],
  pldcdrgrncrm: ["#2B3E50", "#FFE4B5"],
  plumsilver: ["#DDA0DD", "#C0C0C0"],
  pmablkhqtsh: ["#000080", "#00BFFF"],
  pmablkhthrs: ["#000080", "#808080"],
  pmablkpmablk: ["#000080", "#000080"],
  pmablkpmaWht: ["#000080", "#FFFFFF"],
  pmablkhqth: ["#000080", "#008B8B"],
  pmablkhqutshd: ["#000080", "#6A5ACD"],
  pmablksmkprl: ["#000080", "#696969"],
  polar: ["#F2F2F2"],
  polarwhite: ["#F2F2F2", "#FFFFFF"],
  polarfrost: ["#F2F2F2"],
  polarhthpolar: ["#F2F2F2", "#F2F2F2"],
  polarjaspe: ["#F2F2F2"],
  polarmelange: ["#F2F2F2"],
  polarpowdrblk: ["#F2F2F2", "#000000"],
  polaralloy: ["#F2F2F2", "#A9A9A9"],
  polarblack: ["#F2F2F2", "#000000"],
  polarblkblk: ["#F2F2F2", "#000000", "#000000"],
  polarblkWht: ["#F2F2F2", "#FFFFFF"],
  polarfrontier: ["#F2F2F2", "#A52A2A"],
  polarred: ["#F2F2F2", "#FF0000"],
  polyneon: ["#F6AE78"],
  melonorange: ["#FFA500"],
  blendedwhitevintagehotpinktitanium: ["#FFFFFF", "#FF69B4", "#708090"],
  graniteheathervintagesmokeblack: ["#708090", "#D3D3D3", "#000000"],
  kryptekinferno: ["#FF4500"],
  poolblue: ["#00BFFF"],
  paradisewhite: ["#00C0C0", "#FFFFFF"],
  sagewhite: ["#9ACD32", "#FFFFFF"],
  oxfordnaturalbuffalo: ["#708090"],
  powderblack: ["#000000"],
  greenapple: ["#8DB600"],
  powderpink: ["#FF69B4"],
  powderpolar: ["#000000", "#F2F2F2"],
  powderbluewhite: ["#0000FF", "#FFFFFF"],
  bluehawaiiin: ["#1E90FF"],
  bluewaves: ["#008080"],
  floralmist: ["#FF1493"],
  frogskincamobrown: ["#4E2C21", "#8B4513"],
  prescottyellow: ["#FFD700"],
  limelimedigital: ["#00FF00", "#00FF00"],
  burntorangeburntorangedigital: ["#FF4500", "#FF4500"],
  pumablack: ["#000000"],
  pumablackhthr: ["#000000", "#808080"],
  pumablkhgrs: ["#000000", "#A9A9A9"],
  purpplumraisin: ["#800080", "#9B111E"],
  purpwhthgrph: ["#800080", "#FFFFFF", "#808080"],
  saltwaterheather: ["#20B2AA"],
  blackgranite: ["#000000", "#696969"],
  purpleathleticheather: ["#800080", "#808080"],
  multicamalpinewhite: ["#FFFAF0", "#FFFFFF"],
  multicamaridbrown: ["#7B8B6F", "#8B7355"],
  multicamaridtan: ["#7B8B6F", "#D2B48C"],
  multicamtropicgreen: ["#708238", "#008000"],
  darknavysilver: ["#000080", "#C0C0C0"],
  arctic: ["#87CEEB"],
  sugarplum: ["#FF69B4"],
  charcoalhthrnat: ["#36454F", "#808080", "#D3D3D3"],
  olivehthrnvy: ["#808000", "#808080", "#000080"],
  greynaturalstripe: ["#808080", "#D3D3D3"],
  naturalheathertitanium: ["#D3D3D3", "#C0C0C0"],
  highrisegrey: ["#808080"],
  malibublue: ["#66CC99"],
  oliverealtreeap: ["#808000", "#800080"],
  apwhiteblack: ["#800080", "#000000"],
  apbrown: ["#800080", "#A52A2A"],
  aptan: ["#800080", "#D2B48C"],
  blackblazeap: ["#000000", "#FF4500"],
  blazextra: ["#FF4500", "#006400"],
  hardwoodgreenolive: ["#228B22", "#808000"],
  blackreddots: ["#000000", "#A52A2A"],
  mbmediumblue: ["#0000CD"],
  royalroyaldigisilver: ["#4169E1", "#4169E1", "#C0C0C0"],
  greenfloral: ["#008000"],
  hawaiinbiome: ["#1E90FF"],
  hawaiinrainforest: ["#008000"],
  hawaiinsky: ["#1E90FF"],
  winterblue: ["#87CEEB"],
  konablue: ["#1E90FF"],
  konagreen: ["#008000"],
  darkheathergreytruffle: ["#A9A9A9", "#C0C0C0"],
  whitemetallicgold: ["#FFFFFF", "#D3AF37"],
  whitenavyneon: ["#FFFFFF", "#000080", "#FFD700"],
  whiteneonmix: ["#FFFFFF", "#FFD700", "#FF4500"],
  whitetealpurple: ["#FFFFFF", "#008080", "#800080"],
  vintagepurplevintagegrey: ["#800080", "#708090"],
  royalsolid: ["#4169E1"],
  athleticgreynavy: ["#808080", "#000080"],
  purplesnowhth: ["#800080", "#D3D3D3"],
  purplestripe: ["#800080", "#D3D3D3"],
  purpleathheathr: ["#800080", "#808080"],
  purplegold: ["#800080", "#FFD700"],
  purplehthrgray: ["#800080", "#808080"],
  kryptekwraith: ["#708238"],
  purplelimegrn: ["#800080", "#32CD32"],
  purpleorange: ["#800080", "#FFA500"],
  purplepink: ["#800080", "#FFC0CB"],
  purplered: ["#800080", "#FF0000"],
  purpleteal: ["#800080", "#008080"],
  solidorangetriblend: ["#FFA500"],
  spiderbabyblue: ["#1E90FF"],
  swirlblue: ["#0000FF"],
  purpleyellow: ["#800080", "#FFFF00"],
  purpleblackwhi: ["#800080", "#000000", "#FFFFFF"],
  purplecarbon: ["#800080", "#36454F"],
  purplegreyhthr: ["#800080", "#808080"],
  purplesunyellow: ["#800080", "#FFFF00"],
  puttyblack: ["#C0C0C0", "#000000"],
  pwpnkwhtgrp: ["#FF69B4", "#FFFFFF", "#808080"],
  qtshdhgrsht: ["#708238", "#8B7355", "#8B4513"],
  quarrystripe: ["#808080", "#D3D3D3"],
  quarrybrtwhit: ["#8B7355", "#FFFFFF"],
  quest: ["#228B22"],
  quietgrey: ["#808080"],
  quietshade: ["#D3D3D3"],
  solidnavy: ["#000080"],
  triwhitesolid: ["#FFFFFF"],
  whitegreengold: ["#FFFFFF", "#008000", "#FFD700"],
  blackarmy: ["#000000", "#4B5320"],
  raspberryblawhi: ["#E30B5D", "#000000", "#FFFFFF"],
  rastablue: ["#0000FF"],
  rastaweb: ["#FF0000", "#FFFF00", "#008000"],
  rawblack_30: ["#000000"],
  rawblack_32: ["#000000"],
  rawblack_34: ["#000000"],
  rawblack_36: ["#000000"],
  rawblack_38: ["#000000"],
  rawblack_40: ["#000000"],
  rawblack_42: ["#000000"],
  rawblack_44: ["#000000"],
  rawindigo_30: ["#4B0082"],
  rawindigo_32: ["#4B0082"],
  rawindigo_34: ["#4B0082"],
  rawindigo_36: ["#4B0082"],
  rawindigo_38: ["#4B0082"],
  rawindigo_40: ["#4B0082"],
  rawindigo_42: ["#4B0082"],
  rawindigo_44: ["#4B0082"],
  rtedgecharcoal: ["#8B4513", "#36454F"],
  realtreeedge: ["#8B4513"],
  heatherb: ["#B0C4DE"],
  realtreeedge_32: ["#8B4513"],
  realtreeedge_34: ["#8B4513"],
  realtreeedge_36: ["#8B4513"],
  realtreeedge_38: ["#8B4513"],
  realtreeedge_40: ["#8B4513"],
  realtreeedge_42: ["#8B4513"],
  realtreeedge_44: ["#8B4513"],
  realtreemax5buc: ["#8B4513", "#A0522D"],
  coconutmilk: ["#FFF8DC"],
  whitegraphiteprint: ["#FFFFFF", "#36454F"],
  smokestarstriblend: ["#808080"],
  kellygraphitewhite: ["#008000", "#36454F", "#FFFFFF"],
  heatherturquoise: ["#B0C4DE"],
  lightgreymarble: ["#D3D3D3"],
  multicamblackblack: ["#000000"],
  multicamgreenblack: ["#006400"],
  fieldkhakitobacco: ["#8B4513", "#A0522D"],
  rainbowtide: ["#FFA500", "#4B0082", "#1E90FF", "#32CD32"],
  baltic: ["#0000FF"],
  sandalwood: ["#DEB887"],
  lightgoldblack: ["#D3AF37", "#000000"],
  royalathleticgrey: ["#000080", "#808080"],
  navytitaniumwhite: ["#000080", "#A0A0A0", "#FFFFFF"],
  canadianflag: ["#FF0000", "#FFFFFF"],
  tiedyedrainbow: [
    "#FF0000",
    "#FFA500",
    "#FFFF00",
    "#008000",
    "#0000FF",
    "#4B0082",
  ],
  pinkdarkpink: ["#FFC0CB", "#8B008B"],
  redmaroon: ["#FF0000", "#800000"],
  royalnavy: ["#000080", "#000080"],
  redblizzard: ["#FF0000"],
  redclayheather: ["#FF0000"],
  redfleckblack: ["#FF0000", "#000000"],
  redhotchilli: ["#FF4500"],
  redhthrrd: ["#FF4500", "#FF0000"],
  redhthrwht: ["#FF4500", "#FFFFFF"],
  fireredblack: ["#FF4500", "#000000"],
  heathergreyheatheraubergine: ["#808080", "#6B4423"],
  blackherringbone: ["#000000"],
  oatmealherringbone: ["#D3AF37"],
  redpepperhthr: ["#D2691E"],
  calmwatersnavykhaki: ["#87CEFA", "#000080", "#F0E68C"],
  maroonnaturallightpink: ["#800000", "#D3D3D3", "#FFB6C1"],
  redsnowhthr: ["#FF0000"],
  redvolt: ["#FF4500"],
  redwhtstrwht: ["#FF0000", "#FFFFFF"],
  redathletich: ["#FF0000", "#808080"],
  redblackblk: ["#FF0000", "#000000"],
  redblackwht: ["#FF0000", "#000000", "#FFFFFF"],
  redblue: ["#FF0000", "#0000FF"],
  redgray: ["#FF0000", "#808080"],
  redgreen: ["#FF0000", "#008000"],
  redhtgrywht: ["#FF0000", "#808080", "#FFFFFF"],
  redlightblue: ["#FF0000", "#ADD8E6"],
  redlimegreen: ["#FF0000", "#32CD32"],
  rednavyplaid: ["#FF0000"],
  rednavyred: ["#FF0000", "#000080", "#FF0000"],
  redorange: ["#FF0000", "#FFA500"],
  redoyster: ["#FF0000", "#D2691E"],
  redpurple: ["#FF0000", "#800080"],
  redteal: ["#FF0000", "#008080"],
  redttniumwht: ["#FF0000", "#D3D3D3", "#FFFFFF"],
  redwhiteblk: ["#FF0000", "#FFFFFF", "#000000"],
  redwhitegrph: ["#FF0000", "#FFFFFF", "#36454F"],
  redwhitewht: ["#FF0000", "#FFFFFF", "#FFFFFF"],
  redwhtbflchk: ["#FF0000", "#FFFFFF"],
  redyellow: ["#FF0000", "#FFFF00"],
  redcharcoalf: ["#FF0000", "#36454F"],
  redwhiteblock: ["#FF0000", "#FFFFFF"],
  naturalwater: ["#D3AF37"],
  brass: ["#B5A642"],
  reflexblue: ["#001489"],
  reflexbluewh: ["#001489", "#FFFFFF"],
  naturaltitaniumstripe: ["#D3AF37", "#A0A0A0"],
  raspberryraspberrydot: ["#872657", "#872657"],
  retrohtcoral: ["#FF6B6B"],
  retrohtgreen: ["#99CC00"],
  retrohtpink: ["#FF69B4"],
  retrohtpurp: ["#9370DB"],
  retrohtroyal: ["#4169E1"],
  retrohthrpurple: ["#9370DB"],
  retropink: ["#FF6B6B"],
  indigoheatherwhite: ["#4B0082", "#FFFFFF"],
  whiteheatherlakeblue: ["#FFFFFF", "#87CEFA"],
  redreddot: ["#FF0000", "#FF0000"],
  herringbone: ["#D3AF37"],
  hotpinksleeveswhitebody: ["#FF69B4", "#FFFFFF"],
  rinseddrkbrown: ["#8B4513"],
  rinsedindigo: ["#4B0082"],
  clearmint: ["#B0E0E6"],
  limebrown: ["#00FF00", "#8B4513"],
  riptide: ["#8A2BE2"],
  tropicalblueleavesnavy: ["#1E90FF", "#000080"],
  aztec: ["#D06239"],
  brownherringbone: ["#8B4513"],
  charcoalheatherwhite: ["#36454F", "#FFFFFF"],
  dustysage: ["#9C9F84"],
  blackneongreen: ["#000000", "#39FF14"],
  blackvolt: ["#000000"],
  graphitevolt: ["#708090"],
  orangevolt: ["#FF4500"],
  americana: ["#B22234"],
  rainbowombre: [
    "#FF0000",
    "#FF7F00",
    "#FFFF00",
    "#00FF00",
    "#0000FF",
    "#8B00FF",
  ],
  twistedplum: ["#8E4585"],
  fabricstripes: ["#708090"],
  mtsunsetmaroonkhaki: ["#C76114", "#800000", "#F0E68C"],
  pasteltiedyenavykhaki: ["#FFEFD5", "#000080", "#F0E68C"],
  poncho: ["#8B4513"],
  rainbowtiedyenavykhaki: [
    "#FF0000",
    "#FF7F00",
    "#FFFF00",
    "#00FF00",
    "#0000FF",
    "#8B00FF",
    "#000080",
    "#F0E68C",
  ],
  darkolivegreencamoblack: ["#556B2F", "#000000"],
  ecocardinal: ["#C41E3A"],
  ecodarkgreen: ["#006400"],
  ecodarkgrey: ["#A9A9A9"],
  ecodarkgreywhite: ["#A9A9A9", "#FFFFFF"],
  wbbluewhite: ["#0000FF", "#FFFFFF"],
  rnsblkolive: ["#000000"],
  rnsbrwndck: ["#8B4513"],
  rnsdsrtsnd: ["#F4A460"],
  rnsindblue: ["#4B0082"],
  rnsmossgrn: ["#8A9A5B"],
  rnsdindblue: ["#4B0082"],
  heatherheather: ["#D3D3D3"],
  papayawhite: ["#FFEFD5", "#FFFFFF"],
  olivegrey: ["#808000", "#808080"],
  orangegrey: ["#FF4500", "#808080"],
  orchidgrey: ["#DA70D6", "#808080"],
  scarletredblack: ["#FF2400", "#000000"],
  rosewhite: ["#FF007F", "#FFFFFF"],
  blackmonocam: ["#000000"],
  cindergrey: ["#3D3D3D"],
  heatherbotanicalaqua: ["#ADD8E6"],
  heatherindigowoadrootblue: ["#4B0082"],
  fadedrednew: ["#DC143C"],
  graphiteblackmod: ["#708090", "#000000"],
  latteheather: ["#AA6E28"],
  denimoatmeal: ["#1560BD", "#F5DEB3"],
  greyecru: ["#808080", "#C2B280"],
  blackbuck: ["#000000"],
  denimvintage: ["#1560BD"],
  fatiguevintage: ["#808000"],
  scarletathleticgrey: ["#FF2400", "#808080"],
  redtitaniumwhite: ["#FF0000", "#D1D1E0", "#FFFFFF"],
  royaltitaniumwhite: ["#4169E1", "#D1D1E0", "#FFFFFF"],
  titaniumblackwhite: ["#D1D1E0", "#000000", "#FFFFFF"],
  darkgreycolumbiablue: ["#A9A9A9", "#C4D8E2"],
  darkgreynavy: ["#A9A9A9", "#000080"],
  darkgreyroyal: ["#A9A9A9", "#4169E1"],
  skybluestone: ["#87CEEB", "#a9a491"],
  blackstonestitch: ["#000000"],
  brownstonestitch: ["#8B4513"],
  danishfrenchblue: ["#6699CC"],
  redhenna: ["#CA3C3C"],
  kellywhitekelly: ["#4CBB17", "#FFFFFF", "#4CBB17"],
  royalblizzard: ["#4169E1"],
  royalblktrblnd: ["#4169E1"],
  goldstone: ["#FFD700"],
  khakicamobrown: ["#F0E68C", "#808000", "#8B4513"],
  maroonstone: ["#800000"],
  navycamostone: ["#000080", "#808000"],
  royalblueawhi: ["#4169E1"],
  royalcobalt: ["#4169E1"],
  royalfleckblack: ["#4169E1", "#000000"],
  redwhitepolkadots: ["#FF0000", "#FFFFFF"],
  redwhitestars: ["#FF0000", "#FFFFFF"],
  blackcowwhite: ["#000000", "#FFFFFF"],
  blackleopardkhaki: ["#000000", "#F0E68C"],
  armyvintagewhite: ["#4B5320", "#FFFFFF"],
  brownvintagewhite: ["#8B4513", "#FFFFFF"],
  simplyredvintagewhite: ["#FF4500", "#FFFFFF"],
  redwhitestripewhite: ["#FF0000", "#FFFFFF"],
  royalhthrroyl: ["#4169E1"],
  royalhthrwht: ["#4169E1", "#FFFFFF"],
  royalsnowhthr: ["#4169E1"],
  royalvolt: ["#4169E1"],
  royalathheather: ["#4169E1"],
  tridusk: ["#3C5185"],
  sandeddenim: ["#607B8B"],
  royalhthrgrey: ["#4169E1", "#D3D3D3"],
  royalred: ["#4169E1", "#FF0000"],
  royalslvrwht: ["#4169E1", "#C0C0C0", "#FFFFFF"],
  berrypink: ["#FF6EC7"],
  royalwhtblk: ["#4169E1", "#FFFFFF", "#000000"],
  royalwhtgrph: ["#4169E1", "#FFFFFF"],
  royalwhtroyl: ["#4169E1", "#FFFFFF"],
  royalwhtwht: ["#4169E1", "#FFFFFF"],
  royalathgrey: ["#4169E1", "#D3D3D3"],
  royalcharcoalfl: ["#4169E1", "#36454F"],
  royalgreyhthr: ["#4169E1", "#B0C4DE"],
  royallightgold: ["#4169E1", "#FDD017"],
  royalscarlet: ["#4169E1", "#FF2400"],
  royalbluehgrey: ["#4169E1", "#778899"],
  rpstpdggrcamo: ["#556B2F"],
  rpstptigercamo: ["#E08D3C"],
  rssportgrey: ["#B0C4DE"],
  rtallcamoxt: ["#78866B"],
  russianblue: ["#3D59AB"],
  rustyred: ["#E97451"],
  rylblkblktrb: ["#4169E1", "#000000"],
  saddleblack: ["#8B4513", "#000000"],
  saddlecharcoal: ["#8B4513", "#36454F"],
  wisteriawhite: ["#C9A0DC", "#FFFFFF"],
  garnetblack: ["#733635", "#000000"],
  pinkwhitegrey: ["#FFC0CB", "#FFFFFF", "#D3D3D3"],
  whiteblackband: ["#FFFFFF", "#000000"],
  safeorangecamo: ["#FF6600"],
  orangestone: ["#FFA500", "#a9a491"],
  vintagenavystone: ["#4682B4", "#a9a491"],
  darkgreenstone: ["#006400", "#a9a491"],
  kellystone: ["#4CBB17", "#a9a491"],
  truereddarkgrey: ["#B22222", "#A9A9A9"],
  caribbeanbluedarkgrey: ["#00CCCB", "#A9A9A9"],
  snapdragon: ["#FFA535"],
  whitetitaniumblack: ["#FFFFFF", "#D1D1E0", "#000000"],
  gmbluewhite: ["#318CE7", "#FFFFFF"],
  heathergreyheatherblue: ["#B0C4DE", "#ADD8E6"],
  whitenavywhite: ["#FFFFFF", "#000080", "#FFFFFF"],
  sagecamo: ["#9C9F84"],
  sailblue: ["#0F52BA"],
  salmon: ["#FA8072"],
  blueskywhitenavy: ["#87CEEB", "#FFFFFF", "#000080"],
  samplekit: ["#FFFFFF"],
  blackgraphitemarled: ["#000000", "#A9A9A9"],
  caspian: ["#004F9E"],
  sandstripe: ["#C2B280"],
  sandbar: ["#EDC9AF"],
  sandshell: ["#FCE6C9"],
  powerpinkwhitesilvergrey: ["#FF6EB4", "#FFFFFF", "#C0C0C0"],
  gordonplaid: ["#743A3A"],
  begoniacrystal: ["#FA6E79"],
  santabarbara: ["#83AF9B"],
  santorini: ["#16324F"],
  navybluemist: ["#000080", "#C1CDCD"],
  blueaquafade: ["#4682B4"],
  sapphirebhgry: ["#0F52BA", "#B0C4DE"],
  sapphirebora: ["#0F52BA", "#FFA500"],
  sapphireblue: ["#0F52BA"],
  americanflag: ["#B22234", "#3C3B6E"],
  heatherjade: ["#B0C4DE", "#00A86B"],
  sapphirepprhth: ["#0F52BA"],
  sapphirestripe: ["#0F52BA"],
  satinblack: ["#2D2D2D"],
  satinwhite: ["#EDEAE0"],
  earthgrey: ["#836953"],
  ecublue: ["#C2B280", "#1C39BB"],
  heatherstaygold: ["#B0C4DE", "#FFD700"],
  yellowochre: ["#CC7722"],
  trilieutenant: ["#9EFD38"],
  whitelapis: ["#FFFFFF", "#26619C"],
  lightblueblack: ["#ADD8E6", "#000000"],
  scarletgraphit: ["#FF2400", "#A9A9A9"],
  scarletgrphite: ["#FF2400", "#A9A9A9"],
  scarletathgrey: ["#FF2400", "#B0C4DE"],
  scarletblackwhi: ["#FF2400", "#000000", "#FFFFFF"],
  scarletgreyhthr: ["#FF2400", "#B0C4DE"],
  highdive: ["#03CDD2"],
  sddkgrytrblnd: ["#A9A9A9"],
  seafrost: ["#8DD9C7"],
  seamist: ["#6CDAE7"],
  seafoamivory: ["#50C878", "#FFFFF0"],
  heatherteja: ["#B0C4DE"],
  seamseal: ["#76A5AF"],
  seashell: ["#FFF5EE"],
  seattlegrey: ["#787878"],
  seersucker: ["#74C365"],
  safetygreencamo: ["#9ACD32"],
  sftygreencamo: ["#9ACD32"],
  sftyorangeblk: ["#FF6600", "#000000"],
  sftyorngegrph: ["#FF6600", "#A9A9A9"],
  sftyyellowblk: ["#FFFF00", "#000000"],
  sftyyellowwht: ["#FFFF00", "#FFFFFF"],
  sftyyellwgrph: ["#FFFF00", "#A9A9A9"],
  sftiylwcrbn: ["#FFFF00", "#A9A9A9"],
  sgbladesbrown: ["#2E8B57", "#964B00"],
  sh101: ["#8B4513"],
  redcharcoalfleck: ["#FF0000", "#36454F"],
  royalcharcoalfleck: ["#4169E1", "#36454F"],
  icefleckneongreen: ["#D1E9E9", "#39FF14"],
  ballerinawhitestripe: ["#FEBFD2", "#FFFFFF"],
  ballerinawhiteballerinawhitestripe: ["#FEBFD2", "#FFFFFF", "#FEBFD2"],
  navyredredwhitestripe: ["#000080", "#FF0000", "#FF0000"],
  prewashedblackdenim: ["#333366"],
  greentealheatherbirch: ["#008080", "#D2B48C"],
  redheatherbirch: ["#B0171F", "#D2B48C"],
  blackgreycha: ["#000000", "#808080", "#36454F"],
  olivekhakilightkhaki: ["#808000", "#F0E68C", "#F0E68C"],
  pinkgreylightpink: ["#FFC0CB", "#808080", "#FFB6C1"],
  charcoalheatherarmyheather: ["#36454F", "#4B5320"],
  classicnavygunmetalheather: ["#000080", "#2C3539"],
  tiedyeolive: ["#3D9970"],
  lblightblue36: ["#ADD8E6"],
  white33: ["#FFFFFF"],
  shdgrsbldlc: ["#DDA0DD", "#FFC0CB"],
  sherbert: ["#FBAED2"],
  sherbetrainbow: ["#FFD1DC"],
  shinycarbon: ["#464646"],
  shinyolive: ["#808000"],
  shinyslateblue: ["#6A5ACD"],
  shinyvintwhite: ["#FAF0E6"],
  sienna: ["#882D17"],
  siennablack: ["#882D17", "#000000"],
  scarletredgrey: ["#FF2400", "#808080"],
  scarletrednavy: ["#FF2400", "#000080"],
  spearmintnavy: ["#64D3FB", "#000080"],
  stonecamelbrown: ["#8B7355", "#C19A6B", "#964B00"],
  greycaramelblack: ["#808080", "#C19A6B", "#000000"],
  lightbluenavywhite: ["#ADD8E6", "#000080", "#FFFFFF"],
  lightgreysalmondarkgrey: ["#D3D3D3", "#FA8072", "#A9A9A9"],
  lightgreyseafoamsilver: ["#D3D3D3", "#50C878", "#C0C0C0"],
  marinecamelbrown: ["#013163", "#C19A6B", "#964B00"],
  melangeblackwhite: ["#333333", "#FFFFFF"],
  melangeburgundywhite: ["#800020", "#FFFFFF"],
  blacklightolive: ["#000000", "#BDB76B"],
  blackarmycamokhaki: ["#000000", "#4B5320", "#F0E68C"],
  blackcardinalkhaki: ["#000000", "#C41E3A", "#F0E68C"],
  blackgreenkhaki: ["#000000", "#008000", "#F0E68C"],
  signs: ["#4B0082"],
  coralgranite: ["#FF6F61", "#D5D5D5"],
  redathleticheather: ["#FF0000", "#B0C4DE"],
  breakupbreakup: ["#7B8D42", "#7B8D42"],
  newtimbertan: ["#0E5404", "#D2B48C"],
  prym1ampedgreenblack: ["#4CBB17", "#000000"],
  prym1voltageyellowblack: ["#FFD300", "#000000"],
  realtreeapbrown: ["#555D50", "#964B00"],
  realtreeapneonorange: ["#555D50", "#FF4500"],
  realtreeapneonyellow: ["#555D50", "#FFD700"],
  naturalheathervintagecamonaturalheather: ["#D2B48C", "#78866B", "#D2B48C"],
  vintageredred: ["#D65282", "#FF0000"],
  silverblizzard: ["#AFBFC9"],
  whitebuffalo: ["#FFFFFF"],
  scarletheatherscarlet: ["#980002", "#FF2400"],
  silvergray: ["#C0C0C0"],
  silvergreytribl: ["#BFC1C2"],
  silvergrytrbln: ["#C0C0C0"],
  silverhshagry: ["#C0C0C0"],
  silverheather: ["#C0C0C0"],
  silverhthrblack: ["#C0C0C0", "#000000"],
  silverhthrcarbo: ["#C0C0C0"],
  silvermelange: ["#C0C0C0"],
  silverrainbow: ["#C0C0C0"],
  silverblue: ["#C0C0C0", "#0000FF"],
  silverwhtblk: ["#C0C0C0", "#FFFFFF", "#000000"],
  caribbeanblueheather: ["#1AC1DD"],
  skipatrol: ["#FF4500"],
  skyblueawhite: ["#87CEEB", "#FFFFFF"],
  charcoalskyblue: ["#36454F", "#87CEEB"],
  skybluehthwh: ["#87CEEB"],
  skywhite: ["#87CEEB", "#FFFFFF"],
  blackwhitemarled: ["#000000", "#FFFFFF"],
  slateblsltbl: ["#708090"],
  slatecamo: ["#708090"],
  slategrayhthr: ["#708090"],
  slatenavy: ["#708090", "#000080"],
  slatered: ["#708090", "#FF0000"],
  slteblunvyrp: ["#708090", "#000080"],
  smkblbgagd: ["#708090"],
  smkblbesmk: ["#708090"],
  smkgryreactive: ["#708090"],
  whiteyellow: ["#FFFFFF", "#FFFF00"],
  smokebluebge: ["#87A8BE", "#F5F5DC"],
  smokebluechar: ["#87A8BE", "#36454F"],
  smokegreywht: ["#708090", "#FFFFFF"],
  smokeblgrskh: ["#708090"],
  smokebrown: ["#708090", "#A52A2A"],
  redlightorange: ["#FF0000", "#FFA07A"],
  bluemistfade: ["#6593E6"],
  solidblkblend: ["#000000"],
  solidblktrblnd: ["#000000"],
  solidwhtblend: ["#FFFFFF"],
  solidwhttrblnd: ["#FFFFFF"],
  heathergreyheatherdarkgreen: ["#B0C4DE", "#556B2F"],
  spathgoldwht: ["#FFD700", "#FFFFFF"],
  spathleticgold: ["#FFD700"],
  spburntorange: ["#CC5500"],
  spcharitypink: ["#FF6EB4"],
  spdnvhtspg: ["#000080", "#008000"],
  spdnvyhtsg: ["#000080", "#008000"],
  spdarkmaroon: ["#5E1519"],
  spdarknavyhth: ["#00008B"],
  spdknavywht: ["#000080", "#FFFFFF"],
  spdknvsdnv: ["#000080"],
  spdknvspgrp: ["#000080"],
  spdrknvyhth: ["#00008B"],
  spforesththr: ["#228B22"],
  spforestwhite: ["#228B22", "#FFFFFF"],
  spfrsthtsgr: ["#228B22", "#008000"],
  spgraphitewht: ["#668284", "#FFFFFF"],
  spltbluewht: ["#ADD8E6", "#FFFFFF"],
  spmaroonhthr: ["#800000"],
  spmaroonwhite: ["#800000", "#FFFFFF"],
  spmrhtspsil: ["#800000"],
  spmrnhtspgr: ["#800000", "#008000"],
  sporangewhite: ["#FFA500", "#FFFFFF"],
  sppurplewhite: ["#800080", "#FFFFFF"],
  spredheather: ["#FF0000"],
  spredhtspgr: ["#FF0000", "#008000"],
  spredblkhthr: ["#FF0000", "#000000"],
  sproyalheather: ["#4169E1"],
  sproyalwhite: ["#4169E1", "#FFFFFF"],
  sproylsdnkv: ["#4169E1", "#000080"],
  sprylhtspgr: ["#4169E1", "#008000"],
  spscarletred: ["#FF2400"],
  spsilverwhite: ["#C0C0C0", "#FFFFFF"],
  spvegasgldwht: ["#C5B358", "#FFFFFF"],
  spaceblue: ["#1D2951"],
  vintagecamotitanium: ["#78866B", "#848482"],
  sparkred: ["#FF6C60"],
  heatherwhitevintageblack: ["#F5F5F5", "#000000"],
  spicebrown: ["#8D6E63"],
  spiderburgundy: ["#7D0A2B"],
  spidercrimson: ["#DC143C"],
  spiderdandelion: ["#F0E130"],
  spidergold: ["#D4A017"],
  spidergray: ["#6F7285"],
  spiderforest: ["#228B22"],
  spiderkelly: ["#4CBB17"],
  spiderlavender: ["#D6AEDD"],
  spidermint: ["#98FF98"],
  spiderorange: ["#FFA000"],
  spiderpink: ["#FC6C85"],
  spiderturquoise: ["#40E0D0"],
  spindriftwhite: ["#B2E1FF", "#FFFFFF"],
  spiralblue: ["#5EC8F8"],
  spiralgold: ["#FFD700"],
  spiralbluelaven: ["#5EC8F8", "#E6E6FA"],
  spirallime: ["#00FF00"],
  spiralorange: ["#FFA500"],
  spirallink: ["#FFC0CB"],
  spiralkpinkyello: ["#FFC0CB", "#FFFF00"],
  spiralpurple: ["#800080"],
  spiralrainbow: [
    "#FF0000",
    "#FF7F00",
    "#FFFF00",
    "#00FF00",
    "#0000FF",
    "#4B0082",
    "#9400D3",
  ],
  spiralred: ["#FF0000"],
  spiralroyalred: ["#4169E1", "#FF0000"],
  spiralyellowlim: ["#FFFF00", "#00FF00"],
  sportathgold: ["#DAA520"],
  sportbnrtornge: ["#FF4500"],
  sportbrown: ["#8B4513"],
  sportcharitypk: ["#FF69B4"],
  sportchrtypink: ["#FF69B4"],
  sportdarkbrown: ["#8B4513"],
  blackvintageredkhaki: ["#000000", "#8B0000", "#F0E68C"],
  charcoalkhaki: ["#36454F", "#F0E68C"],
  sportdarkmroon: ["#800000"],
  sportdrkmaroon: ["#800000"],
  sportforest: ["#228B22"],
  sportgraphite: ["#778899"],
  sportgreen: ["#00FF7F"],
  sportgreyblk: ["#B0C4DE", "#000000"],
  sportgreyred: ["#B0C4DE", "#FF0000"],
  sportgreyheathr: ["#B0C4DE"],
  sportgryroyal: ["#B0C4DE", "#4169E1"],
  sportsgreyroyal: ["#B0C4DE", "#4169E1"],
  sportkelly: ["#4CBB17"],
  sportlightblue: ["#ADD8E6"],
  sportmaroon: ["#800000"],
  sportredblack: ["#FF0000", "#000000"],
  sportredwhite: ["#FF0000", "#FFFFFF"],
  sportroyalhthr: ["#4169E1"],
  darkmaroonkhaki: ["#8B0000", "#F0E68C"],
  labluekhaki: ["#B0E0E6", "#F0E68C"],
  lightolivekhaki: ["#BDB76B", "#F0E68C"],
  marinekhaki: ["#70A6B7", "#F0E68C"],
  sportscrletred: ["#FF2400"],
  sportsilver: ["#C0C0C0"],
  sportvegasgold: ["#C5B358"],
  sprflgmigration: ["#004225"],
  sprtathltcgold: ["#DAA520"],
  sprtbrntorange: ["#FF4500"],
  sprtburntornge: ["#FF4500"],
  sprtchritypink: ["#FF69B4"],
  sprtdarkbrown: ["#8B4513"],
  sprtdarkgreen: ["#006400"],
  sprtdarkmaroon: ["#800000"],
  sprtdrkmaroon: ["#800000"],
  sprtdrknavy: ["#000080"],
  sprtgraphtblk: ["#2F4F4F", "#000000"],
  sprtscarletred: ["#FF2400"],
  sprtvegasgold: ["#C5B358"],
  sptsftyorange: ["#FFA500"],
  sptsftyyellow: ["#FFFF00"],
  squash: ["#FDB813"],
  stbluevnlla: ["#0000FF", "#F3E5AB"],
  stainless: ["#C0C0C0"],
  stealthblack: ["#708090", "#000000"],
  steelbluehthr: ["#4682B4"],
  steelbluewht: ["#4682B4", "#FFFFFF"],
  steelgray: ["#7B9095"],
  chalkwhiteblack: ["#F5F5F5", "#000000"],
  melangecharcoalblack: ["#4B4747", "#000000"],
  melangesilverwhite: ["#C0C0C0", "#FFFFFF"],
  steelonyx: ["#6F8E9F"],
  steelsteelonx: ["#B0C4DE", "#6F8E9F"],
  steelhead: ["#5F8A8B"],
  steelheadwhite: ["#5F8A8B", "#FFFFFF"],
  heathergreyheathergrey: ["#B0C4DE", "#B0C4DE"],
  heatherwhitedot: ["#A9A9A9", "#FFFFFF"],
  stlthgrst: ["#2D2C2C"],
  stlthgrwh: ["#2D2C2C", "#FFFFFF"],
  stnbrkupcntry: ["#A9A9A9"],
  stnltstnwht: ["#A9A9A9", "#A9A9A9", "#FFFFFF"],
  stnwashblack30: ["#2C2C2C"],
  stnwashblack32: ["#2C2C2C"],
  stnwashblack34: ["#2C2C2C"],
  stnwashblack36: ["#2C2C2C"],
  stnwashblack38: ["#2C2C2C"],
  stnwashblack40: ["#2C2C2C"],
  stnwashblack42: ["#2C2C2C"],
  stnwashblack44: ["#2C2C2C"],
  stnwshbrdck: ["#706C6C"],
  stnwshinblu: ["#5F7B8B"],
  caramalkhaki: ["#FFD700", "#F0E68C"],
  coyotebrownkhaki: ["#81613C", "#F0E68C"],
  navyfirefighter: ["#000080"],
  charcoalmining: ["#36454F"],
  mossblack: ["#4A5D23", "#000000"],
  stonegrnshark: ["#8B8C7A"],
  stoneblack: ["#A9A9A9", "#000000"],
  stoneforest: ["#A9A9A9", "#228B22"],
  stonenavy: ["#A9A9A9", "#000080"],
  stoneroyal: ["#A9A9A9", "#4169E1"],
  stonewhite: ["#A9A9A9", "#FFFFFF"],
  graphiteheatherred: ["#778899", "#FF0000"],
  blushwhite: ["#DE5D83", "#FFFFFF"],
  stoneworkgray: ["#7B9095"],
  stonewshmoss: ["#5E8B3E"],
  stonewshslte: ["#6D7976"],
  ecoblackclassicstripe: ["#000000"],
  stormcamoblack: ["#46494C", "#000000"],
  whiteballerinastripe: ["#FFFFFF"],
  whiteneonheatherblue: ["#FFFFFF"],
  strathhtnpk: ["#B0C4DE"],
  strathhtrwht: ["#A8A2A2", "#FFFFFF"],
  vintagesmokeblack: ["#708090", "#000000"],
  summerescape: ["#FFDAB9"],
  summergreen: ["#00FF7F"],
  summersquash: ["#F4A460"],
  whiteredwhite: ["#FFFFFF", "#FF0000", "#FFFFFF"],
  whiteroyalslate: ["#FFFFFF", "#1E90FF", "#2F4F4F"],
  sunyellow: ["#FFD700"],
  sunburstrainbow: ["#FF4500"],
  sunflower: ["#FFC512"],
  sunnyyellow: ["#F4A460"],
  sunrayyellow: ["#FFFACD"],
  sunsetheather: ["#FF4500"],
  sunsetorange: ["#FF4500"],
  sunsetpink: ["#FFB6C1"],
  sunwashedmint: ["#98FB98"],
  superflaugegame: ["#7B8B8B"],
  superflaugesnow: ["#DCDCDC"],
  blackblacktowhitefade: ["#000000", "#FFFFFF"],
  browndesertcamo: ["#964B00"],
  darkgreendarkgreentowhitefade: ["#006400", "#FFFFFF"],
  surf: ["#00CED1"],
  surfblue: ["#4682B4"],
  surfocean: ["#1E90FF"],
  surfpink: ["#FFC0CB"],
  surfpurple: ["#800080"],
  surftheweb: ["#4682B4"],
  surfyellow: ["#FFFF00"],
  swbrownduck30: ["#964B00"],
  swbrownduck32: ["#964B00"],
  swbrownduck34: ["#964B00"],
  swbrownduck36: ["#964B00"],
  swbrownduck38: ["#964B00"],
  swbrownduck40: ["#964B00"],
  swbrownduck42: ["#964B00"],
  swbrownduck44: ["#964B00"],
  swcrmhtblpk: ["#F5DEB3"],
  swcrmhthtmv: ["#F5DEB3"],
  swcrmhtmlgn: ["#F5DEB3"],
  swindblue: ["#4F83CC"],
  swindblue30: ["#4F83CC"],
  swindblue32: ["#4F83CC"],
  swindblue34: ["#4F83CC"],
  swindblue36: ["#4F83CC"],
  swindblue38: ["#4F83CC"],
  swindblue40: ["#4F83CC"],
  swindblue42: ["#4F83CC"],
  swindblue44: ["#4F83CC"],
  swindblue46: ["#4F83CC"],
  swindblue48: ["#4F83CC"],
  swindblue50: ["#4F83CC"],
  swmossgreen30: ["#4F7942"],
  swmossgreen32: ["#4F7942"],
  swmossgreen34: ["#4F7942"],
  swmossgreen36: ["#4F7942"],
  swmossgreen38: ["#4F7942"],
  swmossgreen40: ["#4F7942"],
  swmossgreen42: ["#4F7942"],
  swmossgreen44: ["#4F7942"],
  swtmbrbrown30: ["#654321"],
  swtmbrbrown32: ["#654321"],
  swtmbrbrown34: ["#654321"],
  swtmbrbrown36: ["#654321"],
  swtmbrbrown38: ["#654321"],
  swtmbrbrown40: ["#654321"],
  swtmbrbrown42: ["#654321"],
  swtmbrbrown44: ["#654321"],
  swatch: ["#F0E68C"],
  swatchbinder: ["#F0E68C"],
  swatchcard: ["#F0E68C"],
  swirlpurple: ["#800080"],
  swirlred: ["#FF0000"],
  torange: ["#FF8C00"],
  tredroyalwhite: ["#FF0000", "#4169E1", "#FFFFFF"],
  tamale: ["#FF4500"],
  tandardgreen: ["#D2B48C", "#006400"],
  tannavy: ["#D2B48C", "#000080"],
  tangerineorange: ["#FFA500"],
  taupevnlla: ["#483C32"],
  teaberryheather: ["#D0F0C0"],
  teabryhtnvbl: ["#D0F0C0", "#000080"],
  teak: ["#8B7355"],
  olivestonestitch: ["#808000"],
  redstonestitch: ["#FF0000"],
  charcoalheatherathleticroyal: ["#36454F", "#4169E1"],
  charcoalheatheroxfordgrey: ["#36454F", "#B0C4DE"],
  mediumgreyconcrete: ["#A9A9A9"],
  blendedwhitevintagesmoke: ["#FFFFFF", "#708090"],
  vintagewhitemellowyellow: ["#FAEBD7", "#F8DE7E"],
  tealbeige: ["#008080", "#F5F5DC"],
  tealpnksophia: ["#008080", "#FFC0CB"],
  tealblackwhite: ["#008080", "#000000", "#FFFFFF"],
  team365: ["#4B778D"],
  camoheather: ["#78866B"],
  rainbowpride: [
    "#FF0000",
    "#FF7F00",
    "#FFFF00",
    "#00FF00",
    "#0000FF",
    "#4B0082",
    "#8B00FF",
  ],
  rainbowswirl: [
    "#FF0000",
    "#FF7F00",
    "#FFFF00",
    "#00FF00",
    "#0000FF",
    "#4B0082",
    "#8B00FF",
  ],
  bahamablue: ["#00ACC1"],
  kellyathleticgrey: ["#4CBB17", "#D3D3D3"],
  jadecharcoal: ["#00A36C", "#36454F"],
  techred: ["#FF2052"],
  techroyal: ["#4169E1"],
  navypeppernavy: ["#000080", "#1D2951"],
  indigosleevesheatherwhitebody: ["#4B0082", "#D3D3D3"],
  tiedye: ["#1E90FF", "#9400D3", "#4B0082", "#FFD700"],
  athleticgoldwhite: ["#FFB81C", "#FFFFFF"],
  tiffblue: ["#0ABAB5"],
  tiffanyblue: ["#0ABAB5"],
  tilered: ["#E23D28"],
  timber: ["#6E8B3D"],
  powerbluepowerblue: ["#5F9EA0", "#5F9EA0"],
  titaniumheather: ["#B9C0C0"],
  blackcaliword: ["#000000"],
  blackrasta: ["#000000"],
  greyeagle: ["#808080"],
  greystpattys: ["#808080"],
  whitebear: ["#FFFFFF"],
  whiteblackgold: ["#FFFFFF", "#000000", "#FFD700"],
  whiteblackred: ["#FFFFFF", "#000000", "#FF0000"],
  whitebrown: ["#FFFFFF", "#A52A2A"],
  jig: ["#2E8B57"],
  blackbear: ["#000000"],
  organicrust: ["#6A4928"],
  whitebrowngoldthickstripe: ["#FFFFFF", "#A52A2A", "#FFD700"],
  whitecalisun: ["#FFFFFF"],
  whitecaliword: ["#FFFFFF"],
  tnthrtkhaki: ["#C3B091"],
  toastedcoconut: ["#A3826F"],
  trroyaltriblnd: ["#4169E1"],
  trroyalblack: ["#4169E1", "#000000"],
  translucentblue: ["#AFEEEE"],
  translucentred: ["#FF4500"],
  translucentsmke: ["#F5F5F5"],
  translucentteal: ["#66CCCC"],
  translucntgreen: ["#32CD32"],
  translucntornge: ["#FFA07A"],
  translucntpurpl: ["#9370DB"],
  transparentyell: ["#FFFFE0"],
  transprntyellow: ["#FFFF00"],
  triblue: ["#6699CC"],
  trilightblue: ["#ADD8E6"],
  trilightpink: ["#FFB6C1"],
  trired: ["#FF4500"],
  trnslucntlmgrn: ["#32CD32"],
  blackcirrusgrey: ["#000000", "#636363"],
  tropicaljungle: ["#54BBAB"],
  hunterblack: ["#355E3B", "#000000"],
  truebluehthrox: ["#0073CF", "#8C92AC"],
  trubluhthoxf: ["#0073CF", "#F0E68C"],
  trurednanavy: ["#8B0000", "#000080"],
  truroyalcrbn: ["#4169E1", "#A9A9A9"],
  truroylcnvh: ["#4169E1"],
  trurylhtcrbn: ["#4169E1", "#A9A9A9"],
  jellyfish: ["#DA70D6"],
  space: ["#414A4C"],
  daybreak: ["#696969"],
  lightcharcoal: ["#777777"],
  trueblueheathr: ["#4682B4"],
  truecardinal: ["#C41E3A"],
  militarygreenpremiumheather: ["#4B5320", "#D3D3D3"],
  truegray: ["#808080"],
  carmineredtriblend: ["#D70040"],
  kellygreentriblend: ["#4CBB17"],
  blackcharcoalfleck: ["#000000", "#363636"],
  charcoalfleckwhite: ["#363636", "#FFFFFF"],
  fuchsiagranite: ["#FF00FF", "#676767"],
  truegreenheather: ["#32CD32"],
  darksapphireheather: ["#082567"],
  deepbluecheck: ["#00008B"],
  marbledheliconia: ["#E667CE"],
  periwinkletiedye: ["#CCCCFF"],
  charcoalheatherburgundyheather: ["#363636", "#800020"],
  charcoalfleckelectricblue: ["#363636", "#7EF9FF"],
  realtreeaptan: ["#59554B", "#D2B48C"],
  realtreefishingbluewhite: ["#00FFFF", "#FFFFFF"],
  realtreehardwoodsblack: ["#556B2F", "#000000"],
  rye: ["#C5B358"],
  mossyoakbreakupblazeorange: ["#3C341F", "#FF6600"],
  tangerineheather: ["#F28500"],
  bluemistmintgreen: ["#AFDBF5", "#98FF98"],
  vintageroyalroyal: ["#484C7F", "#4169E1"],
  graniteheatherwhite: ["#676767", "#FFFFFF"],
  white37: ["#FFFFFF"],
  white38: ["#FFFFFF"],
  jewelnavywhite: ["#D9E9BE", "#000080", "#FFFFFF"],
  royalnantucketgrey: ["#4169E1", "#A4A4A4", "#B0C4DE"],
  stonemosscharcoal: ["#8B8B83", "#708238", "#36454F"],
  vanillaredribbondarknavy: ["#F3E5AB", "#B22222", "#000080"],
  whitecharcoalorange: ["#FFFFFF", "#36454F", "#FFA500"],
  brightgreen: ["#32CD32"],
  robinsegg: ["#00CCCC"],
  seaglass: ["#00FFFF"],
  sunbeam: ["#FFDA03"],
  gunmetalheatherarmyheather: ["#2C3539", "#4B5320"],
  washedtradewind: ["#ACD8E2"],
  naturalheathergraniteheathertitanium: ["#8C8C83", "#676767", "#E1E5E8"],
  vintageindigovintagenavywhite: ["#4B0082", "#191970", "#FFFFFF"],
  antiquegoldblack: ["#D4AF37", "#000000"],
  neonheathergreen: ["#32CD32"],
  kryptekpontusblack: ["#1A5674", "#000000"],
  mossyoakcountryblack: ["#ADFF2F", "#000000"],
  mossyoakmarlinwhite: ["#FF4500", "#FFFFFF"],
  realtreesplashwhite: ["#75D59D", "#FFFFFF"],
  blacktea: ["#000000", "#965A3E"],
  gunmetalheatherblack: ["#2C3539", "#000000"],
  coolgreyvividblue: ["#8C92AC", "#4B0082"],
  vividbluecoolgrey: ["#4B0082", "#8C92AC"],
  frostychocolate: ["#D2691E"],
  ecopacificblueecoivory: ["#1CA9C9", "#FFFFF0"],
  ecutruredecoivory: ["#8B0000", "#FFFFF0"],
  purplepurpletonalblend: ["#800080", "#800080"],
  mintgranite: ["#98FF98", "#676767"],
  royalathleticheather: ["#4169E1", "#B0C4DE"],
  melangeolive: ["#708238"],
  clearbluewhite: ["#1C92D2", "#FFFFFF"],
  cypressmodcamo: ["#4F7942"],
  brightindigocollegiatenavy: ["#4C516D", "#000080"],
  heathercaribbeanblue: ["#3FE0D0"],
  blazerealtreeap: ["#FF4500"],
  pinkrealtreeap: ["#FFC0CB"],
  whiterealtreeap: ["#FFFFFF"],
  originalmossyoak: ["#556B2F"],
  carribeanwater: ["#02CCFE"],
  beachblue: ["#5BB5D6"],
  beachorange: ["#FFA500"],
  beerhops: ["#A0522D"],
  blackpirates: ["#000000"],
  blackskulls: ["#000000"],
  bluewave: ["#5DADEC"],
  voodoo: ["#784921"],
  navygranite: ["#000080", "#676767"],
  melangetdarkgreyblack: ["#696969", "#000000"],
  amour: ["#E081A5"],
  deepsea: ["#3B3C6E"],
  safariyellow: ["#EAAE69"],
  sportathleticgold: ["#FFD700"],
  redbuffalotriblend: ["#FF0000"],
  smokebuffalotriblend: ["#6E8B3D"],
  maroonathleticgrey: ["#800000", "#808080"],
  darkmoss: ["#3C341F"],
  blazerealtreeapblack: ["#FF4500", "#000000"],
  redusaflag: ["#FF0000"],
  whiteusaflag: ["#FFFFFF"],
  allpurposeorange: ["#FFA500"],
  countrydnabrown: ["#5C3317"],
  khakirealtreeedge: ["#C3B091", "#696969"],
  mossyoakbottomlandbrown: ["#556B2F", "#A52A2A"],
  limeaquablue: ["#00FF00", "#00FFFF"],
  darkwater: ["#8B0000"],
  whiteimperialnavyred: ["#FFFFFF", "#002147", "#FF0000"],
  whiteindigodarknavy: ["#FFFFFF", "#4B0082", "#000080"],
  whitenassaucharcoal: ["#FFFFFF", "#48D1CC", "#36454F"],
  whitereddarknavy: ["#FFFFFF", "#FF0000", "#000080"],
  ivoryblack: ["#FFFFF0", "#000000"],
  konalavender: ["#967BB6"],
  konapink: ["#FF1493"],
  konayellow: ["#FFE135"],
  frogskingreen: ["#91A681"],
  greenduckcamo: ["#4B5320"],
  greybonefish: ["#808080"],
  khakiskulls: ["#C3B091"],
  pacifictropical: ["#1CA9C9"],
  tanduckcamo: ["#D2B48C"],
  whitebonefish: ["#FFFFFF"],
  whitelobster: ["#FFFFFF"],
  vintagesmokevintagecamoblack: ["#A9A9A9", "#5D762A", "#000000"],
  raspberryswirl: ["#B3446C"],
  coastalbluetea: ["#1CA9C9", "#965A3E"],
  coraltea: ["#FF6F61", "#965A3E"],
  dustyyellowtea: ["#E8E46E", "#965A3E"],
  lavendertea: ["#E6E6FA", "#965A3E"],
  minttea: ["#98FF98", "#965A3E"],
  navytea: ["#000080", "#965A3E"],
  blackblackblack: ["#000000", "#000000", "#000000"],
  whitewhitenavy: ["#FFFFFF", "#FFFFFF", "#000080"],
  trueroyalmrble: ["#4169E1"],
  whitegraphiteheather: ["#FFFFFF", "#A0A0A0"],
  trueroyalblk: ["#4169E1", "#000000"],
  oxfordgreyblack: ["#808080", "#000000"],
  oxfordgreynavy: ["#808080", "#000080"],
  oxfordgreyteamblue: ["#808080", "#4C516D"],
  icebluesleevesdarkheathergreybody: ["#F0F8FF", "#A9A9A9"],
  lilacsleevesdarkheathergreybody: ["#C8A2C8", "#A9A9A9"],
  trueroyalwht: ["#4169E1", "#FFFFFF"],
  tulip: ["#FF878D"],
  tulpclntrowh: ["#FF878D", "#00A550", "#FFFFFF"],
  oxfordjnavy: ["#808080", "#000080"],
  turqinvsblstrp: ["#40E0D0"],
  belair: ["#83AF9B"],
  navypetitedots: ["#000080"],
  palmbeach: ["#96CDB1"],
  peacockturquoise: ["#00CC99"],
  tangier: ["#D57800"],
  turquoisefleck: ["#40E0D0"],
  turquoisehthr: ["#40E0D0"],
  turquoisetrblnd: ["#40E0D0"],
  turquoisekhaki: ["#40E0D0", "#C3B091"],
  turquoisesilvr: ["#40E0D0", "#C0C0C0"],
  tuscan: ["#7D4E57"],
  tuskgrill: ["#C3B091", "#535353"],
  typhon: ["#6A737B"],
  typhoon: ["#6C7A89"],
  ultraclub: ["#E1C699"],
  scuba: ["#00FFFF"],
  smokeblue: ["#6699CC"],
  oxfordfieryredheather: ["#808080", "#FF4900"],
  topsailred: ["#FF3B3F"],
  navyredwhitestripe: ["#000080", "#FF0000", "#FFFFFF"],
  univbluewhite: ["#4C69A5", "#FFFFFF"],
  urban: ["#594F4F"],
  greymismatchflag: ["#808080"],
  starspangledpeace: ["#2C75FF"],
  whiteblackthinthickstripe: ["#FFFFFF", "#000000"],
  heathercharcoalwhite: ["#808080", "#FFFFFF"],
  ambergoldnavy: ["#FFBF00", "#000080"],
  whitecarolinablue: ["#FFFFFF", "#56A0D3"],
  whiteeagle: ["#FFFFFF"],
  whitefuchsia: ["#FFFFFF", "#FF77FF"],
  vorangebwhite: ["#FFA500", "#FFFFFF"],
  vanillamilkshake: ["#F3E5AB"],
  vanillawht: ["#F3E5AB", "#FFFFFF"],
  boulderheather: ["#7E7F85"],
  vegasgoldwht: ["#C5B358", "#FFFFFF"],
  FFDB4Dvetiver: ["#808000"],
  vibrantgreen: ["#7FFF00"],
  vibrantgrnwht: ["#7FFF00", "#FFFFFF"],
  vibrantorange: ["#FF7000"],
  vincoalblk: ["#59636E", "#000000"],
  vindenimnat: ["#1560BD", "#FBF5D7"],
  vinnavywhite: ["#121236", "#FFFFFF"],
  vinsmokewhite: ["#708090", "#FFFFFF"],
  vinthmaroon: ["#800000"],
  vintagecamel: ["#C19A6B"],
  ecutruenavyecotruered: ["#000080", "#8B0000"],
  vintagecharcoal: ["#333333"],
  heatherreddeepheather: ["#FF0000", "#8B0000"],
  limelimetonalblend: ["#00FF00"],
  festivaltiedye: ["#D5A253"],
  patriotic: ["#B22234"],
  lemonice: ["#FFF700"],
  seagreenwhite: ["#2E8B57", "#FFFFFF"],
  sunshinewhite: ["#FFD700", "#FFFFFF"],
  citygreynimbusgrey: ["#6A6A6A", "#E0E0E0"],
  wildplum: ["#8E4585"],
  heathertrueroyalnavy: ["#4682B4", "#000080"],
  vintagehthblue: ["#4682B4"],
  vintagehthrred: ["#8B0000"],
  vintagelake: ["#5A7D9A"],
  vintagemavi: ["#5D8AA8"],
  vintagemustard: ["#FFDB58"],
  vintagenvynvy: ["#000080"],
  vintageolive: ["#808000"],
  vintageorchid: ["#DA70D6"],
  vintagepebble: ["#708090"],
  vintageshpink: ["#FFC0CB"],
  vintageblackvintagegrey: ["#000000", "#808080"],
  vintageturq: ["#40E0D0"],
  vintagewine: ["#800000"],
  vintagezinc: ["#7D7D7D"],
  slateheather: ["#708090"],
  vntfadedpink: ["#FF6EB4"],
  vntwhtreactive: ["#FFFFFF"],
  volcano: ["#CC3333"],
  agaveheather: ["#858885"],
  melangedarkgreenwhite: ["#006400", "#FFFFFF"],
  melangegreyblack: ["#808080", "#000000"],
  melangegreywhite: ["#808080", "#FFFFFF"],
  melangelightbluecardinalstone: ["#ADD8E6", "#C41E3A", "#8B8B8B"],
  melangetnavybronzestone: ["#000080", "#CD7F32", "#8B8B8B"],
  melangetscarletwhite: ["#C41E3A", "#FFFFFF"],
  mintnavysilver: ["#98FF98", "#000080", "#C0C0C0"],
  blackorangekhaki: ["#000000", "#FF4500", "#C3B091"],
  blackscarletredkhaki: ["#000000", "#FF2400", "#C3B091"],
  blackyellowkhaki: ["#000000", "#FFFF00", "#C3B091"],
  bluecamojava: ["#0000FF", "#D2691E"],
  bluedenimkhaki: ["#1560BD", "#C3B091"],
  bluestripekhaki: ["#1E90FF", "#C3B091"],
  wakefrmhighseas: ["#4E87A3"],
  whiteblackbuffalo: ["#FFFFFF", "#000000"],
  camelstripennatural: ["#C19A6B", "#FBF5E1"],
  warmgrayblack: ["#808080", "#000000"],
  wasabi: ["#A4C639"],
  washedbasil: ["#658D6D"],
  bsbluewhitestripe32: ["#0000FF", "#FFFFFF"],
  fbfrenchblue32: ["#6699CC"],
  gygrey32: ["#808080"],
  washedgray: ["#A9A9A9"],
  ashcameopinkcharcoal: ["#B2BEB5", "#EFBBCC", "#36454F"],
  washedrouge: ["#B7474D"],
  washedsaltwater: ["#A0C1B8"],
  water: ["#56B2E3"],
  waxycanvas: ["#DFC184"],
  wedgewood: ["#4E7F9E"],
  charcoalballerina: ["#36454F", "#F4C2C2"],
  redkellywhitestripe: ["#FF0000", "#4CBB17", "#FFFFFF"],
  whialuminumbla: ["#FFFFFF", "#A9ACB6", "#000000"],
  whialuminumnav: ["#FFFFFF", "#A9ACB6", "#000080"],
  whineonyelblac: ["#FFFFFF", "#CCFF00", "#000000"],
  collegiategreenwhite: ["#008633", "#FFFFFF"],
  darkgreengraphite: ["#006400", "#1C1C1C"],
  whiteblackgraphiteprint: ["#FFFFFF", "#000000", "#1C1C1C"],
  navycamonavynavy: ["#000080", "#5B6868", "#000080"],
  navygraphitenavy: ["#000080", "#1C1C1C", "#000080"],
  pinkblendpinkpink: ["#FF69B4", "#FF69B4", "#FF69B4"],
  whitedigitalwhiteblack: ["#FFFFFF", "#BEBEBE", "#000000"],
  graphitegraphitestriped: ["#1C1C1C", "#8A8A8A"],
  navynavystriped: ["#000080", "#1C1C1C"],
  rainbowpastel: ["#A1E9DE"],
  whitelightnavy: ["#FFFFFF", "#000080"],
  whitesmokeblue: ["#FFFFFF", "#778899"],
  navychalkwhite: ["#000080", "#F5F5F5"],
  melangeheatherblack: ["#9A8B8D", "#000000"],
  charcoalblackgrey: ["#36454F", "#000000", "#808080"],
  fieldkhakitobaccotan: ["#C3B091", "#79443B", "#D2B48C"],
  saddletan: ["#8B4513", "#D2B48C"],
  camoblackkhaki: ["#78866B", "#000000", "#C3B091"],
  fieldkhakineonyellow: ["#C3B091", "#CCFF00"],
  rockcamo: ["#778899", "#78866B"],
  saddlecamo: ["#8B4513", "#78866B"],
  blackcyclone: ["#000000"],
  antiquewhiteblack: ["#FAEBD7", "#000000"],
  asterblue: ["#6E7F80"],
  gunmetalheatherburgundyheather: ["#8A8A8A", "#800020"],
  blackzebrawhite: ["#000000", "#FCFFF0", "#FFFFFF"],
  vintagewhitecharcoal: ["#FAEBD7", "#36454F"],
  blackroyaltriblend: ["#000000", "#4169E1"],
  advantagetimber: ["#6C7E55"],
  allpurposetexasflag: ["#BF0A30"],
  hotpinkrealtreeap: ["#FF69B4"],
  realtreefishingblue: ["#6AAFE2"],
  ecomarineblue: ["#5E97A1"],
  ecomaroon: ["#800000"],
  digitalredcamo: ["#B22222"],
  emeraldwater: ["#50C878"],
  mauvelousballerinaandmauvelousstripe: ["#EF98AA", "#F4C2C2"],
  whiteballerinawhitedot: ["#FFFFFF", "#F4C2C2"],
  greenmoss: ["#4A5D23"],
  pinkstone: ["#FFC0CB", "#FF8C00"],
  heathernavyheathergrey: ["#000080", "#B0C4DE"],
  heatherroyalheathergrey: ["#4169E1", "#B0C4DE"],
  seabluewhite: ["#006994", "#FFFFFF"],
  lightolivegreenstone: ["#556B2F", "#FF8C00"],
  whiteroyalred: ["#FFFFFF", "#4169E1", "#FF0000"],
  navystonestitch: ["#000080", "#A9A9A9"],
  diamondblue: ["#0000B9"],
  mosskhaki: ["#708238", "#F0E68C"],
  multicamgreenkhaki: ["#4B5320", "#F0E68C"],
  rusticorangekhaki: ["#CD5700", "#F0E68C"],
  steelbluesilver: ["#4682B4", "#C0C0C0"],
  whiteblizzard: ["#FFFFFF"],
  idsilver: ["#C0C0C0"],
  whitecamowht: ["#FFFFFF", "#FFFFFF"],
  whitecrdbrd: ["#FFFFFF"],
  whitefauxfur: ["#FFFFFF"],
  whitenfc: ["#FFFFFF"],
  whiteplastic: ["#FFFFFF"],
  heatherrednavy: ["#B0171F", "#000080"],
  whitesublmtn: ["#FFFFFF"],
  whitetiedye: ["#FFFFFF"],
  whiteshamrock: ["#FFFFFF"],
  whiteskulls: ["#FFFFFF"],
  whitetropical: ["#FFFFFF"],
  cobaltbluewhite: ["#0047AB", "#FFFFFF"],
  whiteathheather: ["#FFFFFF", "#B0C4DE"],
  mazarineblue: ["#273BE2"],
  whiteblackdot: ["#FFFFFF", "#000000"],
  whiteblkhthr: ["#FFFFFF", "#000000"],
  whiteblkplaid: ["#FFFFFF", "#000000"],
  whiteblkblk: ["#FFFFFF", "#000000", "#000000"],
  whitebluegry: ["#FFFFFF", "#0000FF", "#808080"],
  whiteburgundy: ["#FFFFFF", "#800020"],
  whitecamogrn: ["#FFFFFF", "#4B5320"],
  whitecanvasrd: ["#FFFFFF", "#FF0000"],
  whitecharcoalh: ["#FFFFFF"],
  whitechrclhth: ["#FFFFFF"],
  whitegraph: ["#FFFFFF"],
  whitegrphthr: ["#FFFFFF", "#808080"],
  whitehthgrey: ["#FFFFFF", "#B0C4DE"],
  whitejade: ["#FFFFFF", "#00A86B"],
  whitekellygrn: ["#FFFFFF", "#4CBB17"],
  whitekhaki: ["#FFFFFF", "#F0E68C"],
  monarch: ["#800080"],
  mysticblue: ["#2E2EFE"],
  whiteltblue: ["#FFFFFF", "#ADD8E6"],
  whitemarine: ["#FFFFFF", "#013220"],
  whitemint: ["#FFFFFF", "#98FF98"],
  whitemossgrn: ["#FFFFFF", "#4A5D23"],
  whitenautcanv: ["#FFFFFF", "#000080"],
  bonnetblueheather: ["#A2A2D0"],
  icefallheather: ["#B7DFDB"],
  whitenavyhthr: ["#FFFFFF", "#000080"],
  whitenvynvy: ["#FFFFFF", "#000080", "#000080"],
  whiteredhthr: ["#FFFFFF", "#FF0000"],
  heathertanbrown: ["#D2B48C", "#964B00"],
  whiteroyalhth: ["#FFFFFF", "#4169E1"],
  whitesky: ["#FFFFFF", "#87CEEB"],
  whitespsilver: ["#FFFFFF", "#C0C0C0"],
  whitesthrnorn: ["#FFFFFF"],
  whitecarblue: ["#FFFFFF"],
  whitecolbluyel: ["#FFFFFF"],
  whitecolblue: ["#FFFFFF"],
  whitegreyhthr: ["#FFFFFF", "#808080"],
  whiteiron: ["#FFFFFF"],
  whitenavytred: ["#FFFFFF", "#000080", "#FF0000"],
  whitered: ["#FFFFFF", "#FF0000"],
  whiteroyalwhite: ["#FFFFFF", "#4169E1", "#FFFFFF"],
  whitestealth: ["#FFFFFF"],
  whitetredwhite: ["#FFFFFF", "#FF0000", "#FFFFFF"],
  whitevheather: ["#FFFFFF"],
  whitecap: ["#FFFFFF"],
  whtflcktriblnd: ["#FFFFFF"],
  whtinvsblstrp: ["#FFFFFF"],
  whtburgslvr: ["#FFFFFF", "#800020", "#C0C0C0"],
  whtcolumblue: ["#FFFFFF", "#9CDDF4"],
  whtforestlme: ["#FFFFFF", "#228B22", "#32CD32"],
  whtgreyfxfur: ["#FFFFFF", "#808080"],
  whtgrphltgr: ["#FFFFFF", "#808080", "#D3D3D3"],
  whtlimegreen: ["#FFFFFF", "#32CD32"],
  whtltrbfb: ["#FFFFFF"],
  whtltrblue: ["#FFFFFF", "#ADD8E6"],
  whtnoptrtl: ["#FFFFFF"],
  whtnavypntlt: ["#FFFFFF", "#000080"],
  whtneonyellow: ["#FFFFFF", "#FFFF00"],
  whtnvycrystl: ["#FFFFFF", "#000080"],
  whtreflexblue: ["#FFFFFF", "#3A66A7"],
  whtruststone: ["#FFFFFF", "#B7410E", "#A9A9A9"],
  whtslvrlurex: ["#FFFFFF", "#C0C0C0"],
  whtslvrslate: ["#FFFFFF", "#C0C0C0", "#708090"],
  whtspdknvht: ["#FFFFFF"],
  wildberrytb: ["#D9007E"],
  willow: ["#9A8E7D"],
  tancamo: ["#D2B48C"],
  asphaltmelange: ["#696969"],
  darkheatherblack: ["#A9A9A9", "#000000"],
  darkheatherred: ["#A9A9A9", "#FF0000"],
  grill: ["#585858"],
  wshcoyotebrwn: ["#81613C"],
  wshdcytebrwn: ["#81613C"],
  wshedsaltwater: ["#A9C7C9"],
  wshedtradewind: ["#B0E0E6"],
  fatigueblackblack: ["#808000", "#000000", "#000000"],
  blackfog: ["#000000", "#A0A0A0"],
  charcoalfog: ["#808080", "#A0A0A0"],
  fieldkhakiblack: ["#C3B091", "#000000"],
  yellownavy: ["#FFFF00", "#000080"],
  yellowstone: ["#D2B48C"],
};
