import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import AllmadeSampleAd from "../../assets/AllmadeSampleAd.png";

import Typography from "@mui/material/Typography";
import { usePostHog } from "posthog-js/react";
import getStyleNumber from "utilities/getStyleNumber";
import { useLocation } from "react-router-dom";

const ProductPageSampleAd = ({ originalStyleData, colorQuery }) => {
  const posthog = usePostHog();
  const location = useLocation();

  const trackSampleAdClick = () => {
    posthog?.capture("clicked_sample_ad", {
      sample_vendor_style: getStyleNumber(originalStyleData),
      sample_dgi_style_sku: originalStyleData?.dgi_style_sku,
      sample_product_brand: originalStyleData?.brand,
      sample_product_color: colorQuery,
      source: location.pathname,
    });
    window.open("https://allmade.com/pages/sample-request-form?utm_source=dgi_apparel", "_blank");
  };

  const handleLoad = () => {
    posthog?.capture("view_sample_ad", {
      sample_vendor_style: getStyleNumber(originalStyleData),
      sample_dgi_style_sku: originalStyleData?.dgi_style_sku,
      sample_product_brand: originalStyleData?.brand,
      sample_product_color: colorQuery,
      source: location.pathname,
    });
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="0"
      marginBottom="0rem"
      marginTop="-0.5rem"
      width="720px"
      height="90px"
      borderRadius="5px"
      overflow="hidden"
      sx={{ cursor: "pointer" }}
      onClick={() => trackSampleAdClick()}
    >
      <img
        src={AllmadeSampleAd}
        onLoad={handleLoad}
        alt="Allmade Sample Ad"
        width="100%"
        height="100%"
        style={{
          transition: "transform 0.3s ease"
        }}
        onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.01)"}
        onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
      />
    </Box>
  );
};

export default ProductPageSampleAd;
