import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useProductContext } from "./ProductPage";
import { useNavigate } from "react-router-dom";
import { useClearRefinements, useRefinementList } from "react-instantsearch";

const ProductCategories = () => {
  const { categories } = useProductContext();
  // const clearRefinementsApi = useClearRefinements();
  // const navigate = useNavigate();
  // const categoryRefineApi = useRefinementList({
  //   attribute: "master_category_tags",
  //   operator: "and"
  // });

  // const handleCategoryClick = (categoryName) => {
  //   // TODO: still need to get SEO-friendly routing to make routing search in one step
  //   clearRefinementsApi.refine();
  //   categoryRefineApi.refine(categoryName);
  //   navigate("/shop");
  // };

  const numberOfCategories =
    categories.length +
    " " +
    (categories.length === 1 ? "Category" : "Categories");

  return (
    <Box textAlign="center" marginTop="1em" width="80%">
      <Typography variant="h6" marginBottom="0.5em">
        {numberOfCategories}
      </Typography>
      <Box className="w-full flex justify-center">
        <Box className="flex flex-wrap justify-center gap-[10px]">
          {categories.map((category, index) => (
            <Box
              key={index}
              border="1px solid #414B56" // wolfGrey
              padding="5px"
              borderRadius="5px"
              // className="cursor-pointer"
              // onClick={() => handleCategoryClick(category)}
            >
              {category}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCategories;
