import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Notifications() {
  const navigate = useNavigate();

  const notifications = [
    {
      id: 1,
      text: (
        <>
          <strong>New Supplier:</strong> SLC Activewear is now available on DGI, offering a wide selection of blank apparel at low prices with free next day delivery on most orders!{" "}
          <Link
            component="button"
            color="primary"
            onClick={() => navigate('/integrations?vendor=slc')}
          >
            Connect your SLC Activewear account here!
          </Link>
        </>
      ),
      borderColor: "#3357FF", // Blue
    },
    // {
    //   id: 2,
    //   text: (
    //     <>
    //       Labor Day: Most vendor warehouses will be closed 9/2 for Labor Day. Orders placed after cut-off on Friday, 8/30, will be shipped on Tuesday, 9/3.{" "}
    //     </>
    //   ),
    //   borderColor: "#33FF57", // Green
    // },
    // {
    //   id: 3,
    //   text: (
    //     <>
    //       <strong>New Feature:</strong> We've added a new reporting dashboard!{" "}
    //       <Link
    //         component="button"
    //         color="primary"
    //         onClick={() => navigate('/order-history')}
    //       >
    //         Check it out here!
    //       </Link>
    //     </>
    //   ),
    //   borderColor: "#3357FF", // Blue
    // },
    // {
    //   id: 4,
    //   text: (
    //     <>
    //       <strong>Upcoming Event:</strong> Don't miss the next vendor integration webinar!{" "}
    //       <Link
    //         component="button"
    //         color="primary"
    //         onClick={() => navigate('/integrations')}
    //       >
    //         Register here!
    //       </Link>
    //     </>
    //   ),
    //   borderColor: "#FFD700", // Yellow
    // },
  ];

  return (
    <Box>
      {notifications.map((notification) => (
        <Box
          key={notification.id}
          sx={{
            borderLeft: `5px solid ${notification.borderColor}`,
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            mb: "0.3rem",
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, fontSize: "13.5px" }}>
            {notification.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
