import React from "react";
import VendorTab from "./VendorTab";
import styles from "./styles.module.scss";

export default function VendorTabs({ 
  vendorDataKeys, 
  autoAssignTab ,
  setCartAlertContent,
  setShowCartAlert,
  showCartAlert,
  vendorsBestPrices,
  hidePrices
}) {
  return (
    <div className={styles.vendorTabs}>
      {vendorDataKeys.length <= 5 ? (
        vendorDataKeys.map((vendorDataKey, index) => (
          <VendorTab
            key={vendorDataKey}
            vendorDataKey={vendorDataKey}
            isDropdown={false}
            autoAssignTab={autoAssignTab}
            setCartAlertContent={setCartAlertContent}
            setShowCartAlert={setShowCartAlert}
            showCartAlert={showCartAlert}
            vendorsBestPrices={vendorsBestPrices}
            hidePrices={hidePrices}
          />
        ))
      ) : (
        <>
          {vendorDataKeys.slice(0, 4).map((vendorDataKey) => (
            <VendorTab
              key={vendorDataKey}
              vendorDataKey={vendorDataKey}
              isDropdown={false}
              autoAssignTab={autoAssignTab}
              setCartAlertContent={setCartAlertContent}
              setShowCartAlert={setShowCartAlert}
              showCartAlert={showCartAlert}
              vendorsBestPrices={vendorsBestPrices}
              hidePrices={hidePrices}
            />
          ))}
          <VendorTab
            isDropdown={true}
            dropdownKeys={vendorDataKeys.slice(4)}
            autoAssignTab={autoAssignTab}
            setCartAlertContent={setCartAlertContent}
            setShowCartAlert={setShowCartAlert}
            showCartAlert={showCartAlert}
            vendorsBestPrices={vendorsBestPrices}
            hidePrices={hidePrices}
          />
        </>
      )}
    </div>
  );
}
