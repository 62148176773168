import React from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import SimilarSlideContent from "./SimilarSlideContent";

export default function SimilarSlide({
  open,
  containerRef,
  hit,
  selectedColor,
}) {
  return (
    <Slide direction="up" in={open} container={containerRef.current}>
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          paddingLeft: "16px",
          paddingBottom: "1rem",
          backgroundColor: "#f9f9f9",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "410px",
          boxShadow: "none",
        }}
      >
        <Box>
          <SimilarSlideContent
            hit={hit}
            selectedColor={selectedColor}
            isSlideOpen={open}
          />
        </Box>
      </Paper>
    </Slide>
  );
}
