import React from "react";
import { Box } from "@mui/material";
import Carousel from "./Carousel";
import NotificationContainer from "./NotificationContainer";
import ShortcutsContainer from "./ShortcutsContainer";
import TopStyles from "pages/home/TopStyles";
import AnalyticsContainer from "./AnalyticsContainer";

export default function HomePage() {
  return (
    <Box sx={{ padding: "1rem", paddingTop: "0" }}>
      <NotificationContainer />
      <Box
        sx={{
          mt: 2,
          '@media (max-width: 700px)': {
            display: 'none',
          },
        }}
      >
        <Carousel />
      </Box>
      <Box sx={{ mt: 4 }}>
        <TopStyles />
      </Box>
      <Box sx={{ mt: 4 }}>
        <AnalyticsContainer />
      </Box>
      <Box sx={{ mt: 4 }}>
        <ShortcutsContainer />
      </Box>
    </Box>
  );
}
