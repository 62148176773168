import React, { useEffect, useState, useRef } from "react";

import styles from "./styles.module.scss";
import { createCartEntry } from "../../utilities/createCartEntry";
import { TableCell, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const CaseQtyIncrement = ({ caseQtyRequired, caseQtyIncrement }) => {
  if (!caseQtyRequired) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "50px" }}>
      <Typography
        sx={{ fontWeight: "600", textAlign: "center", fontSize: "13px" }}
      >
        {`(${caseQtyIncrement})*`}
      </Typography>
    </Box>
  );
};

export default function InventoryNumberCell(props) {
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemNumber, setItemNumber] = useState(null);
  const [cartItem, setCartItem] = useState({});
  const [originalQuantityNumber, setOriginalQuantityNumber] = useState(0);
  const userChangedInput = useRef(null);
  const initializedByFetch = useRef(null);
  const [isValid, setIsValid] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const entryKey =
    props?.sizeLabel + "_" + props?.warehouse.replaceAll(" ", ""); // arbitrary but sufficient to differentiate items

  // TODO: I wonder if this can be all be placed into a function
  // we might be able to call it where we currently call setItemQuantity
  useEffect(() => {
    const updateCheckoutEntries = props?.setCheckoutEntries;
    if (Number.isInteger(itemQuantity) && itemQuantity >= 0 && itemNumber) {
      let updatingEntry = false;

      if (userChangedInput.current) {
        if (initializedByFetch.current && originalQuantityNumber > 0) {
          updatingEntry = true;
        } else {
          updatingEntry = false;
        }
      } else {
        // entry is initialized based off cart data fetch
        updatingEntry = true;
      }

      if (userChangedInput.current) {
        const isAddingZero = itemQuantity === 0 && !updatingEntry; // don't add entries where we just add 0 of some item
        const isAddingOriginalQuantity =
          itemQuantity === originalQuantityNumber; // don't add original quantity from cart fetch
        if (isAddingZero || isAddingOriginalQuantity) {
          updateCheckoutEntries((prevCheckoutEntries) => {
            const newCheckoutEntries = { ...prevCheckoutEntries };
            delete newCheckoutEntries[entryKey];
            return newCheckoutEntries;
          });
        } else {
          const checkoutEntry = createCartEntry(
            props?.vendorCode,
            props?.warehouseCode,
            itemQuantity,
            props?.itemPrice,
            cartItem,
            false,
            updatingEntry,
            itemNumber,
            props?.inventoryEntry
          );

          updateCheckoutEntries((prevCheckoutEntries) => ({
            ...prevCheckoutEntries,
            [entryKey]: checkoutEntry,
          }));
        }

        if (itemQuantity === 0 && originalQuantityNumber === 0) {
          setItemQuantity("");
        }
      }
    }
  }, [itemQuantity, itemNumber, userChangedInput]);

  const getItemQuantity = () => {
    // TODO: improvement...check for style a level above
    if (!props?.selectedVendorCartData) {
      return null;
    }

    const vendorCartData = props?.selectedVendorCartData;

    if (!vendorCartData || !vendorCartData["items"]) {
      return null;
    }

    const vendorCartItems = vendorCartData["items"];

    if (!vendorCartItems) {
      return null;
    }

    const targetCartItem = vendorCartItems.find((cartItem) => {
      let sameSKU = false;
      let sameWarehouse = false;

      if (props?.vendorCode === "ss") {
        if (props?.itemNumber && cartItem["ss_sku_id_master"]) {
          const itemNumberSplit = props?.itemNumber.split("_")
          const itemSIDM = itemNumberSplit[3];
          sameSKU = itemSIDM === cartItem["ss_sku_id_master"];
          const itemSID = itemNumberSplit[0];
          const itemWHID = itemNumberSplit[1];
          sameWarehouse = itemSID === cartItem["ss_site_id"] &&
            itemWHID === cartItem["ss_warehouse_id"];
        }
        return sameSKU && sameWarehouse;
      }

      if (props?.itemNumber && cartItem["itemNumber"]) {
        if (props?.vendorCode === "pg") {
          // e.g. 6210E;AG;S;NEXT-LEVL-AL and 6210E;AG;S;None turn to 6210E;AG;S
          const cellItemNumberParts = props?.itemNumber?.split(";");
          const cellItemNumber = cellItemNumberParts.slice(0, 3).join(";");
          const cartItemNumberParts = cartItem["itemNumber"].split(";");
          const cartItemNumber = cartItemNumberParts.slice(0, 3).join(";");
          sameSKU = cellItemNumber.toLowerCase() === cartItemNumber.toLowerCase();
        } else {
          sameSKU =
            props?.itemNumber.toLowerCase() ===
            cartItem["itemNumber"].toLowerCase();
        }
      }
      if (props?.warehouseCode && cartItem?.warehouseCode) {
        sameWarehouse =
          props?.warehouseCode.toLowerCase().replace(" ", "") ===
          cartItem["warehouseCode"].toLowerCase().replace(" ", "");
      } else if (props?.warehouse && cartItem["warehouse"]) {
        sameWarehouse =
          props?.warehouse.toLowerCase().replace(" ", "") ===
          cartItem["warehouse"].toLowerCase().replace(" ", "");
      }

      if (props?.vendorCode === "as") {
        if (props?.itemNumber && cartItem["as_product_id"]) {
          return props.itemNumber.toString() === cartItem["as_product_id"].toString();
        }
      }
      return sameSKU && sameWarehouse;
    });

    if (targetCartItem) {
      let targetItemNumber = targetCartItem["itemNumber"].toLowerCase();
      if (props?.vendorCode === "ss") {
        const colorParam = targetCartItem["colorDesc"]
          .toLowerCase()
          .replaceAll(" ", "_");
        const sidm = targetCartItem["ss_sku_id_master"];
        targetItemNumber = targetItemNumber + "_" + sidm + "_" + colorParam;
      } else if (props?.vendorCode === "as") {
        targetItemNumber = targetCartItem["id"];
      }

      return {
        quantity: targetCartItem.quantity,
        itemNumber: targetItemNumber,
        cartItem: targetCartItem,
      };
    }
    return null;
  };

  const updateItemData = () => {
    const newItemInfo = getItemQuantity();
    if (newItemInfo) {
      const newItemQuantity = props.needToCall ? "Call" : newItemInfo.quantity;
      setItemQuantity(newItemQuantity);
      setOriginalQuantityNumber(newItemInfo.quantity);
      setItemNumber(newItemInfo.itemNumber);
      setCartItem(newItemInfo?.cartItem);
      initializedByFetch.current = true;
      setIsValid(true);
    } else {
      const newItemQuantity = props.needToCall ? "Call" : "";
      setItemNumber(props?.itemNumber);
      setOriginalQuantityNumber(0);
      setItemQuantity(newItemQuantity);
      setCartItem({});
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (!props.loadingTableData || props.fetchingUpdatedData) {
      updateItemData();
    }
  }, [props.itemNumber, props.loadingTableData]);

  const onQuantityChange = (e) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*$/;

    if (inputValue === "" || regex.test(inputValue)) {
      let newQuantity;

      if (inputValue) {
        newQuantity = Number(inputValue);
      } else {
        newQuantity = 0;
      }

      let invalidCaseQty = false;
      if (props.caseQtyRequired) {
        invalidCaseQty =
          newQuantity % props?.caseQtyIncrement !== 0;
      }

      const updateInvalidTableCells = props?.setInvalidTableCells;
      if (newQuantity > Number(props?.maxQuantity) || invalidCaseQty) {
        setIsValid(false);
        updateInvalidTableCells((prevInvalidTableCells) => {
          const newInvalidTableCells = new Set(prevInvalidTableCells);
          newInvalidTableCells.add(entryKey);
          return newInvalidTableCells;
        });
      } else {
        setIsValid(true);
        updateInvalidTableCells((prevInvalidTableCells) => {
          const newInvalidTableCells = new Set(prevInvalidTableCells);
          newInvalidTableCells.delete(entryKey);
          return newInvalidTableCells;
        });
      }

      userChangedInput.current = true;
      setItemQuantity(newQuantity);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    const updateSelectedRow = props.setSelectedBodyRowIndex;
    updateSelectedRow(props.bodyRowIndex);
  };

  const handleBlur = () => {
    setIsFocused(false);
    const updateSelectedRow = props.setSelectedBodyRowIndex;
    updateSelectedRow(null);
  };

  const errorClass = !isValid ? styles.error : "";
  const focusedClass = isFocused && isValid ? styles.focused : "";
  const highlightClass =
    itemQuantity !== "" &&
      isValid &&
      Number.isInteger(itemQuantity) &&
      itemQuantity >= 0 &&
      !isFocused
      ? styles.greenHighlight
      : "";
  let additionalClass = "";
  if (errorClass) {
    additionalClass = errorClass;
  } else if (focusedClass) {
    additionalClass = focusedClass;
  } else if (highlightClass) {
    additionalClass = highlightClass;
  }

  return (
    <TableCell sx={{ padding: "10px" }}>
      <div
        className={styles.inventoryNumberContainer}
        style={{
          display:
            props?.maxQuantity === "null" ||
              props?.maxQuantity === "-"
              ? "none"
              : "flex",
        }}
      >
        <div className="text-center">
          <input
            ref={inputRef}
            className={`${styles.inventoryInput} ${additionalClass}`}
            data-is-valid={isValid && !props.needToCall}
            data-need-to-call={props.needToCall}
            type="tel"
            value={itemQuantity}
            disabled={props.needToCall}
            onChange={onQuantityChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                props.saveBtnAvailable &&
                Object.keys(props.checkoutEntries).length > 0
              ) {
                inputRef.current.blur(); // removes focus animation
                props.triggerSaveCart();
              } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                let newQuantity = parseInt(itemQuantity) || 0;
                if (e.key === "ArrowUp") {
                  newQuantity++;
                } else if (e.key === "ArrowDown" && newQuantity > 0) {
                  newQuantity--;
                }
                e.preventDefault();
                setItemQuantity(newQuantity.toString());
                onQuantityChange({ target: { value: newQuantity.toString() } });
              }
            }}
          />
        </div>
        <div
          className={styles.inventoryNumber}
          data-case-qty-required={props.caseQtyRequired}
          data-need-to-call={props.needToCall}
        >
          <span className="text-almostSM">{props.maxQuantity}</span>
        </div>
        <CaseQtyIncrement
          caseQtyRequired={props.caseQtyRequired}
          caseQtyIncrement={props.caseQtyIncrement}
        />
      </div>
    </TableCell>
  );
}
