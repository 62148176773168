import { AB_WAREHOUSE_MAP, SM_WAREHOUSE_MAP } from "./parseInventoryData";

/*
    Create singular object for items body param in cart API calls.
*/
export const createCartEntry = (
  vendorCode,
  selectedWhse,
  itemQuantity,
  itemPrice,
  item = null,
  warehouseKey = false, // weird but we need to account for different use cases wrt Sanmar
  updatingEntry = true,
  itemNumber = null, // need this for adding items in the inventory table
  inventoryEntry = null,
) => {
  const definedItemNumber = (item && item?.itemNumber) ? item?.itemNumber : itemNumber;

  switch (vendorCode) {
    case "ab":
      return createABEntry(selectedWhse, itemQuantity, definedItemNumber);
    case "cm":
      return createCMEntry(itemQuantity, definedItemNumber, selectedWhse);
    case "sm":
      return createSMEntry(
        definedItemNumber,
        itemQuantity,
        selectedWhse,
        warehouseKey,
        updatingEntry,
      );
    case "ss":
      let definedSIDM = null;
      let definedKey = null;
      if (!item.ss_sku_id_master) {
        definedSIDM = itemNumber.split("_")[3];
        definedKey = itemNumber.split("_").slice(0, 3).join("_");
      } else {
        definedSIDM = item.ss_sku_id_master;
        definedKey = item.itemNumber;
      }
      return createSSEntry(
        itemPrice,
        itemQuantity,
        definedKey,
        updatingEntry,
        definedSIDM,
        item,
      );
    case "as":
      const asItemNumber = (item && Object.keys(item).length > 0) ? item.id : itemNumber;
      return createASEntry(
        asItemNumber,
        itemQuantity,
        updatingEntry
      );
    case "acc":
      return {
        itemNumber: definedItemNumber,
        qty: itemQuantity.toString(),
        warehouse: selectedWhse,
      };
    case "pg":
      return createPGEntry(
        definedItemNumber,
        itemQuantity,
        updatingEntry,
        item,
      );
    case "asc":
      // TODO does this work on the cart page?
      return createASCEntry(
        definedItemNumber,
        itemQuantity,
        selectedWhse,
        updatingEntry,
        item,
        inventoryEntry,
      )
    case "slc":
      return createSLCEntry(
        definedItemNumber,
        itemQuantity,
        selectedWhse,
        updatingEntry,
        item,
        inventoryEntry,
      )
    default:
      // Staton is default fdm4 payload
      return {
        itemNumber: definedItemNumber,
        qty: itemQuantity.toString(),
        warehouse: selectedWhse,
      };
  }
};

const createSLCEntry = (itemNumber, itemQuantity, selectedWhse, updatingEntry, item, inventoryEntry) => {
  if (updatingEntry) {
    return {
      sku: itemNumber,
      quantity: itemQuantity.toString(),
      warehouseCode: item?.warehouseCode,
      itemId: item?.id,
      uid: item?.uid,
    }
  }
  return {
    sku: itemNumber,
    quantity: itemQuantity.toString(),
    warehouseCode: selectedWhse,
    Add: true,
  }
};

const createASCEntry = (itemNumber, itemQuantity, selectedWhse, updatingEntry, item, inventoryEntry) => {
  if (updatingEntry) {
    return {
      itemNumber: itemNumber,
      quantity: itemQuantity.toString(),
      warehouseCode: selectedWhse,
      itemId: item?.id,
      asc_product_id: item?.asc_product_id,
      variant_id: item?.variant_id,
    }
  }
  return {
    asc_product_id: item?.asc_product_id,
    itemNumber: itemNumber,
    quantity: itemQuantity.toString(),
    warehouseCode: selectedWhse,
    variant_id: inventoryEntry?.variant_id,
  }
}

const createABEntry = (selectedWhse, itemQuantity, itemNumber) => {
  return {
    itemNumber: itemNumber,
    qty: itemQuantity.toString(),
    warehouse: selectedWhse,
    prodType: "blank",
  };
};

const createCMEntry = (itemQuantity, itemNumber, warehouse) => {
  if (warehouse === "Indian Trail, NC") {
    warehouse = "NC";
  }
  return {
    itemNumber: itemNumber,
    qty: itemQuantity.toString(),
    warehouse: warehouse,
  };
};

const createSMEntry = (
  itemNumber,
  itemQuantity,
  selectedWhse,
  warehouseKey,
  updatingEntry,
) => {
  return {
    product: { code: itemNumber },
    warehouseCode: selectedWhse.toString(),
    quantity: itemQuantity.toString(),
    updatingEntry: updatingEntry,
  };
};

const createSSEntry = (
  itemPrice,
  itemQuantity,
  itemNumber,
  updatingEntry,
  sidm,
  item,
) => {
  if (updatingEntry) {
    return {
      Key: itemNumber,
      Qty: Number(itemQuantity),
      SIDM: sidm,
      ss_product_url_short: item?.ss_product_url_short,
    };
  } else {
    return {
      Key: itemNumber,
      Qty: Number(itemQuantity),
      Price: itemPrice,
      SIDM: sidm,
      Add: true,
    };
  }
};

const createASEntry = (itemNumber, itemQuantity, updatingEntry) => {
  if (updatingEntry) {
    return {
      "orderItemId": itemNumber.toString(),
      "quantity": itemQuantity.toString(),
    }
  } else {
    return {
      "catEntryId": itemNumber.toString(),
      "quantity": itemQuantity.toString(),
    }
  }
}

function createPGEntry(itemNumber, qty, updatingEntry, item) {
  if (updatingEntry) {
    return {
      itemNumber: itemNumber,
      qty: Number(qty),
      odidlist: item?.odidlist,
    };
  } else {
    return {
      itemNumber: itemNumber,
      qty: Number(qty),
      Add: true,
    };
  }
}

export const createSwitchWarehouseBody = (vendorCode, item, warehouse) => {
  switch (vendorCode) {
    case "ab":
      const oldWhse = Object.entries(AB_WAREHOUSE_MAP).find((whse) => {
        const [whseKey, whseName] = whse;
        return item.warehouse === whseName;
      });
      const oldWhseCode = oldWhse && oldWhse[0];
      return {
        items: [
          {
            itemNumber: item.itemNumber,
            qty: item.quantity.toString(),
            oldwhse: oldWhseCode,
            warehouse: warehouse.code,
            prodType: "blank",
          },
        ],
      };
    case "cm":
      return {
        items: [
          {
            itemNumber: item.itemNumber,
            qty: item.quantity.toString(),
            warehouse: warehouse.code,
          },
        ],
      };
    case "sm":
      const oldSMWhse = Object.entries(SM_WAREHOUSE_MAP).find(([whseKey, whseName]) => {
        const normalizedItemWarehouse = item.warehouse.trim().toLowerCase();
        const normalizedWhseName = whseName.trim().toLowerCase();
        return normalizedItemWarehouse === normalizedWhseName;
      });
      const oldSMWhseCode = oldSMWhse ? oldSMWhse[0] : null;
      return {
        items: [
          {
            product: { code: item.itemNumber },
            warehouseCode: warehouse.code.toString(),
            quantity: item.quantity.toString(),
            oldWarehouseCode: oldSMWhseCode,
            updatingWarehouse: true,
          },
        ],
      };
    case "ss":
      return {
        items: [
          {
            Key: item.itemNumber,
            Qty: 0,
            SIDM: item.ss_sku_id_master,
          },
          {
            Key: warehouse.itemNumber,
            Qty: item.quantity,
            SIDM: warehouse.ss_sku_id_master,
            Price: item.unitPrice,
            Add: true,
          },
        ],
        product_url_short: item.ss_product_url_short,
      };
    default:
      return null;
  }
};
