import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lilProductClient from '../../axios/lilProductClient';
import endpoints from '../../axios/endpoints';
import { highlightText } from './orderSearchUtilities';

const ProductPageLink = ({ styleCode, dgiStyle, searchInput }) => {
  const [firstColor, setFirstColor] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (dgiStyle) {
      const fetchFirstColor = async () => {
        try {
          const response = await lilProductClient.get(endpoints.getProductInfo(dgiStyle));
          const productData = response?.data;

          const validJSONColors = productData?.colors?.replace(/'/g, '"');
          const availableColors = validJSONColors ? JSON.parse(validJSONColors) : [];
          if (availableColors.length > 0) {
            setFirstColor(availableColors[0].name);
          }
        } catch (error) {
          console.error(`Failed to fetch color for style ${dgiStyle}:`, error);
        }
      };

      fetchFirstColor();
    }
  }, [dgiStyle]);

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop the click event from bubbling up
    if (dgiStyle && firstColor) {
      const encodedColor = encodeURIComponent(firstColor);
      navigate(`/product/${dgiStyle}/${encodedColor}`);
    }
  };

  const highlightedStyleCode = highlightText(styleCode, searchInput);

  return (
    <>
      {dgiStyle ? (
        <a
          href="#"
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleLinkClick}
          onMouseDown={(e) => e.stopPropagation()} // Ensure propagation is stopped on mouse down as well
        >
          {highlightedStyleCode}
        </a>
      ) : (
        <span
          style={{
            color: 'inherit',
            textDecoration: 'none',
            cursor: 'default',
          }}
        >
          {highlightedStyleCode}
        </span>
      )}
    </>
  );
};

export default ProductPageLink;
