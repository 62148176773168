import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CognitoAuth from "./CognitoAuth";
import TermsConditions from "terms/TermsConditions";
import PrivacyPolicy from "terms/PrivacyPolicy";
import OurStory from "pages/our_story_page/OurStory";
import LandingPage from "pages/landing_page/LandingPage";
import { useCheckoutContext } from "App";
import { Navigate, useSearchParams } from "react-router-dom";
import Header from "header/Header";
import { useUserStateContext, useDataContext } from "App";
import IdleUserDialog from "IdleUserDialog";
import { usePostHog } from "posthog-js/react";
import ProductDemo from "pages/landing_page/ProductDemo";

const CHECKOUT_PAGES_PATHS = ["/checkout", "/confirmation", "/receipt"];

const RouteWrapper = ({ isAuthed, component, sendJsonMessage }) => {
  const {
    lastRoutePath,
    setCheckoutFormData,
    setCanConfirmOrder,
    setLastRoutePath,
    canConfirmOrder,
  } = useCheckoutContext();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isUserAuthed, hasUserZip } = useUserStateContext();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { searchSession, setSearchSession } = useDataContext();
  const posthog = usePostHog();

  useEffect(() => {
    if (searchSession.in_progress) {
      const onShoppingPage = location.pathname.includes("/shop");
      const searchSessionOnProductPage = searchSession.prev_path === "shop";
      if (searchSessionOnProductPage && !onShoppingPage) {
        setSearchSession((currentSearchSession) => (
          {
            ...currentSearchSession,
            prev_path: null,
          }
        ));
      } else if (!searchSessionOnProductPage && !onShoppingPage) {
        // capture event from last session
        posthog?.capture("search_and_update_cart", {
          updated_cart: searchSession.updated_cart,
          items_added: new Set(searchSession.items_added),
          search_term: searchSession.search_term,
        })
        // reset search session
        setSearchSession({
          in_progress: false,
          search_term: null,
          items_added: [],
          updated_cart: false,
          prev_path: null,
        });
      }
    }
  }, [location.pathname])

  useEffect(() => {
    // Reset checkout form data after leaving
    // one of the checkout pages since we use checkoutFormData
    // to gatekeep checkout pages.

    const isPagePathInLastRoute = (pagePath) => {
      if (lastRoutePath) {
        return lastRoutePath.includes(pagePath);
      }
      return false;
    };

    const isPagePathInNextRoute = (pagePath) => {
      if (location.pathname) {
        return location.pathname.includes(pagePath);
      }
      return false;
    };

    const isLastRouteCheckoutPage = CHECKOUT_PAGES_PATHS.some(
      isPagePathInLastRoute
    );
    const isNextRouteCheckoutPage = CHECKOUT_PAGES_PATHS.some(
      isPagePathInNextRoute
    );

    if (isLastRouteCheckoutPage && !isNextRouteCheckoutPage) {
      setCheckoutFormData({
        shippingAddress: null,
        warehouseShippingMethods: [],
        paymentMethod: null,
        whsePickupTime: null,
        whsePickupDate: null
      });
      setCanConfirmOrder(false);
    }

    setLastRoutePath(location.pathname);
  }, [location.pathname, lastRoutePath, setCheckoutFormData, setLastRoutePath]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // user trying to access confirmation or receipt page directly
  if (!canConfirmOrder) {
    if (
      process.env.NODE_ENV !== "development" &&
      (location.pathname.includes("/confirmation") ||
        (location.pathname.includes("/receipt") &&
          !Boolean(searchParams.get("orderId"))))
    ) {
      return <Navigate to="/cart" />;
    }
  }

  // if the user created an account but hasn't given any shop info,
  // we navigate them to the signup page if not already on it
  if (hasUserZip !== null && hasUserZip === false && isAuthed) {
    if (!location.pathname.includes("/signup")) {
      return <Navigate to="/signup" />;
    }
  }

  // if user is seeking signup page, we navigate them
  // to the shopping page if they already are signed in + gave shop info
  // to the auth page if they are signed out
  // to nowhere if data is still being set after being logged in
  if (location.pathname.includes("/signup")) {
    if (hasUserZip) {
      return <Navigate to="/shop" />;
    } else if (hasUserZip === null && isUserAuthed === false) {
      return <Navigate to="/auth" />;
    } else if (hasUserZip === null && isUserAuthed) {
      return null;
    }
  }

  let unauthedPageComponent = null;
  let isLandingPage = false;
  if (component === "terms") {
    unauthedPageComponent = <TermsConditions />;
  } else if (component === "privacy") {
    unauthedPageComponent = <PrivacyPolicy />;
  } else if (component === "our-story") {
    unauthedPageComponent = <OurStory />;
  } else if (component === "landing-page") {
    unauthedPageComponent = <LandingPage />;
    isLandingPage = true;
  } else if (component === "dashboard" && location.pathname.includes("/shop") && !isUserAuthed) {
    isAuthed = false;
    unauthedPageComponent = <LandingPage />;
    isLandingPage = true;
  } else if (searchParams.get("utm_medium") === "printingunited" && !isUserAuthed) {
    console.log("PRINTING UNITED")
    return <Navigate to="/" />;
  } else if (component === "product-demo") {
    unauthedPageComponent = <ProductDemo />;
  }


  const renderRoutePage = isAuthed ? (
    <CognitoAuth
      windowWidth={windowWidth}
      component={component}
      sendJsonMessage={sendJsonMessage}
    />
  ) : (
    <div className="flex flex-col items-center">
      <Header
        windowWidth={windowWidth}
        landingPage={isLandingPage ? true : !isUserAuthed}
      />
      {unauthedPageComponent}
    </div>
  );

  return (
    <>
      {renderRoutePage}
      {isUserAuthed && <IdleUserDialog />}
    </>
  );
};

export default RouteWrapper;
