import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { usePostVendorReviewMutation } from "features/baseApi";
import { useUserStateContext } from "App";

export default function WriteReview({ vendorCode, onClose }) {
  const [hoveredStar, setHoveredStar] = useState(null); // For hover effect
  const [selectedStar, setSelectedStar] = useState(0); // For selected rating
  const [writeUp, setWriteUp] = useState("");
  const { userCreds } = useUserStateContext();

  const [
    postVendorReview,
    {
      isLoading, // True while the mutation is in progress
      isSuccess, // True if the mutation succeeded
      isError, // True if the mutation failed
      error, // Error object if the mutation failed
      data, // Response data if the mutation succeeded
      reset, // Function to reset the mutation state
    },
  ] = usePostVendorReviewMutation();

  const handleMouseEnter = (star) => {
    setHoveredStar(star);
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  const handleClick = (star) => {
    setSelectedStar(star);
  };

  const renderStar = (index) => {
    const isFilled = index <= (hoveredStar || selectedStar);
    return (
      <IconButton
        key={index}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(index)}
        sx={{ color: "#FFA552", padding: 0 }}
      >
        {isFilled ? (
          <StarRoundedIcon fontSize="large" />
        ) : (
          <StarOutlineRoundedIcon fontSize="large" />
        )}
      </IconButton>
    );
  };

  const onSubmitReview = async (e) => {
    e.preventDefault();
    const reviewData = {
      stars: selectedStar,
      writeUp: writeUp,
    };
    try {
      await postVendorReview({
        vendorCode: vendorCode,
        reviewData: reviewData,
        userId: userCreds.cognitoID
      }).unwrap();
      // close out page on success
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      console.error("Failed to post review:", err);
    }
  };

  const showAlert = isSuccess || isError;
  let alertMessage = null;
  if (isSuccess) {
    alertMessage = "Your review has been sucessfully submitted!";
  } else if (isError) {
    alertMessage = "We could not submit your review at this time.";
  }

  return (
    <Box
      sx={{
        width: "100%",
        mt: 0,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box display="flex" justifyContent="flex-start">
        {[1, 2, 3, 4, 5].map((index) => renderStar(index))}
      </Box>
      <TextField
        label="Write a review..."
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        onChange={(e) => setWriteUp(e.target.value)}
      />
      {!isSuccess && (
        <Button
          disabled={selectedStar === 0} // Disable button if no rating is selected
          sx={{
            textTransform: "none",
            backgroundColor:
              selectedStar === 0 ? "#B0B0B0" : "rgba(0, 110, 165, 1)",
            color: selectedStar === 0 ? "rgba(0, 0, 0, 0.26)" : "white",
            cursor: selectedStar === 0 ? "default" : "pointer",
            paddingY: "6px",
            paddingX: "14px",
            width: "180px",
            fontSize: "16px",
            "&:hover": {
              backgroundColor:
                selectedStar === 0 ? "#B0B0B0" : "rgba(0, 90, 140, 1)",
            },
          }}
          onClick={onSubmitReview}
          loading={true}
        >
          {isLoading ? (
            <CircularProgress size="28px" className="text-dgiWhite" />
          ) : (
            "Submit Review"
          )}
        </Button>
      )}
      {showAlert && (
        <Alert className="w-fit" severity={isSuccess ? "success" : "error"}>
          {alertMessage}
        </Alert>
      )}
    </Box>
  );
}
