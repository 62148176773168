import React, { useState, useEffect, useRef } from "react";
import ShippingMethod from "./ShippingMethod";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Alert,
} from "@mui/material";
import { useCheckoutContext } from "App";

function ExtendedShippingButton({ getShippingMethods }) {
  return (
    <div>
      <Button
        variant="contained"
        onClick={() => getShippingMethods(true)}
        className="text-xs space-x-4"
      >
        <div>Fetch Extended Shipping Methods</div>
        <LocalShippingIcon />
      </Button>
    </div>
  );
}

const CustomWarehouseName = ({ warehouseName }) => {
  return (
    <Box className="bg-dustyBlue" sx={{ padding: 1 }}>
      <Typography component="span" className="font-bold text-grayscaleStone">
        *Shipping Method For:
      </Typography>
      <Typography component="span" sx={{ marginLeft: 1 }} className="font-bold">
        {warehouseName}
      </Typography>
    </Box>
  );
};

const WarehouseShippingMethods = ({
  vendorCode,
  shippingMethods,
  setShippingMethods,
  getShippingMethods,
  loadingShippingMethods,
  errorFetchingShippingMethods,
}) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const timerRef = useRef(null);

  const messages = [
    "Fetching shipping methods...",
    "Verifying your shipping options...",
    "Almost done...",
  ];

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setMessageIndex((prevIndex) =>
        prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex
      );
    }, 3000);

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (!loadingShippingMethods) {
      clearInterval(timerRef.current);
    }
  }, [loadingShippingMethods]);

  const includeExtendedButton =
    vendorCode === "sm" &&
    shippingMethods[0] != null &&
    shippingMethods[0][1].length < 5;
  let extendedButton;
  if (includeExtendedButton) {
    extendedButton = (
      <ExtendedShippingButton getShippingMethods={getShippingMethods} />
    );
  } else {
    extendedButton = <></>;
  }

  if (errorFetchingShippingMethods) {
    return (
      <Stack flexDirection="column" alignItems="center">
        <Alert
          severity="error"
          className="text-base"
          action={
            <Button
              onClick={() => getShippingMethods(false)}
              color="inherit"
              size="small"
              className="text-sm mt-[1px]"
              variant="outlined"
              sx={{
                mt: 0.5,
                padding: "4px 8px",
                color: "error.main",
                "&:hover": {
                  color: "error.main",
                },
              }}
            >
              <RefreshIcon
                sx={{
                  fontSize: "1rem",
                  marginRight: "4px",
                }}
              />
              Retry
            </Button>
          }
          sx={{
            "& .MuiAlert-action": {
              paddingLeft: 4,
            },
          }}
        >
          Unable to fetch shipping methods
        </Alert>
      </Stack>
    );
  } else if (loadingShippingMethods) {
    return (
      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          gap: "20px",
          paddingTop: "48px",
          paddingBottom: "48px",
        }}
      >
        <Typography variant="subtitle1">{messages[messageIndex]}</Typography>
        <LinearProgress sx={{ width: "400px" }} />
      </Stack>
    );
  } else if (!loadingShippingMethods && shippingMethods) {
    return (
      <Box>
        <Stack direction="column" spacing={2}>
          {extendedButton}
          {shippingMethods.map((warehouseOptions) => (
            <Box
              key={warehouseOptions[0]}
              className="checkoutSection"
              sx={{
                borderTop: "none",
                pb: 2,
                backgroundColor: "white",
              }}
            >
              <CustomWarehouseName
                warehouseName={warehouseOptions[0]}
                includeExtendedButton={includeExtendedButton}
                getShippingMethods={getShippingMethods}
              />
              <ShippingMethod
                warehouseName={warehouseOptions[0]}
                setShippingOptions={setShippingMethods}
                warehouseShippingOptions={warehouseOptions[1]}
                warehouseCode={warehouseOptions[2]}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    );
  }
};

export default WarehouseShippingMethods;
