import vendors from "constants/vendors";

const inspectOrderData = (order) => {
  const orderEntries = [];
  for (const orderEntry of Object.entries(order)) {
    const [entryKey, entryValue] = orderEntry;
    if (!entryValue) {
      // for null cases
      continue;
    }
    if (typeof entryValue === "object") {
      const additionalOrderEntries = inspectOrderData(entryValue);
      orderEntries.splice(orderEntries.length, 0, ...additionalOrderEntries);
    } else if (Array.isArray(entryValue)) {
      const newEntryValue = entryValue.map((subValue) =>
        subValue.toString().toLowerCase(),
      );
      orderEntries.splice(orderEntries.length, 0, [entryKey, ...newEntryValue]);
    } else {
      orderEntries.splice(orderEntries.length, 0, [
        entryKey,
        entryValue.toString().toLowerCase(),
      ]);
    }
  }
  return orderEntries;
};

const matchTermAndEntryValue = (term, entryValue) => {
  if (Array.isArray(entryValue)) {
    for (const subValue of entryValue) {
      return subValue.includes(term);
    }
  } else {
    return entryValue.includes(term);
  }
  return false;
};

export const getVendorName = (vendorCode) => {
  const vendorDataKey = vendorCode + "_data";
  return vendors[vendorDataKey]?.["name"];
};

const transformEntryValue = (entryKey, entryValue) => {
  if (entryKey === "vendor") {
    return getVendorName(entryValue).toLowerCase();
  }
  return entryValue;
};

export const shouldIncludeOrder = (order, searchInput) => {
  if (searchInput === "") {
    return true;
  }
  const searchTerms = searchInput.trim().toLowerCase().split(" ");
  const orderEntries = inspectOrderData(order);
  for (const term of searchTerms) {
    for (const orderEntry of orderEntries) {
      const [entryKey, entryValue] = orderEntry;
      const isMatch = matchTermAndEntryValue(
        term,
        transformEntryValue(entryKey, entryValue),
      );
      if (isMatch) {
        return true;
      }
    }
  }
  return false;
};

export const highlightText = (entryValue, searchInput) => {
  if (!entryValue || !searchInput) {
    return entryValue;
  }

  const searchTerms = searchInput.trim().toLowerCase().split(/\s+/);

  const processValue = (value) => {
    if (typeof value !== "string") {
      return value;
    }

    let result = value;
    let positions = [];

    searchTerms.forEach((term) => {
      const newPositions = findMatchPositions(result.toLowerCase(), term);
      positions = mergePositions(positions, newPositions);
    });

    return highlightPositions(result, positions);
  };

  if (Array.isArray(entryValue)) {
    return entryValue.map(processValue);
  } else {
    return processValue(entryValue);
  }
};

const findMatchPositions = (text, searchTerm) => {
  const positions = [];
  let index = text.indexOf(searchTerm);
  while (index !== -1) {
    positions.push([index, index + searchTerm.length]);
    index = text.indexOf(searchTerm, index + 1);
  }
  return positions;
};

const mergePositions = (existing, newPos) => {
  const merged = [...existing, ...newPos]
    .filter(
      (pos) =>
        Array.isArray(pos) &&
        pos.length === 2 &&
        typeof pos[0] === "number" &&
        typeof pos[1] === "number",
    )
    .sort((a, b) => a[0] - b[0]);

  if (merged.length === 0) return [];

  const result = [];
  let current = merged[0];

  for (let i = 1; i < merged.length; i++) {
    if (merged[i] && current) {
      if (merged[i][0] <= current[1]) {
        current[1] = Math.max(current[1], merged[i][1]);
      } else {
        result.push(current);
        current = merged[i];
      }
    }
  }
  if (current) result.push(current);

  return result;
};

const highlightPositions = (text, positions) => {
  if (!positions || positions.length === 0) {
    return text;
  }

  const result = [];
  let lastIndex = 0;

  positions.forEach((position, index) => {
    if (!Array.isArray(position) || position.length !== 2) {
      console.warn(`Invalid position at index ${index}:`, position);
      return;
    }

    const [start, end] = position;

    if (typeof start !== "number" || typeof end !== "number") {
      console.warn(`Invalid position values at index ${index}:`, position);
      return;
    }

    if (start > lastIndex) {
      result.push(text.substring(lastIndex, start));
    }
    result.push(<mark key={index}>{text.substring(start, end)}</mark>);
    lastIndex = end;
  });

  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result.length > 0 ? result : text;
};
