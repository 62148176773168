import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import VendorSpendChart from "./VendorSpendChart";
import TopStylesPieChart from "./TopStylesPieChart";
import { useSelector } from "react-redux";

export default function AnalyticsContainer() {
  const vendorSpendByWeek = useSelector((state) => state.user?.user?.vendorSpendByWeek);
  const topStyles = useSelector((state) => state.user?.user?.topStyles);
  // Check if the screen width is greater than 1080px
  const isAbove1080px = useMediaQuery('(min-width:1080px)');

  const displayData = vendorSpendByWeek && topStyles;

  if (!isAbove1080px || !displayData) {
    return null; // Do not render the component if the screen width is below 1080px
  }

  return (
    <Box sx={{ padding: "1rem", backgroundColor: "#f9f9f9", borderRadius: "8px", marginTop: 6, marginBottom: 6 }}>
      <Typography variant="h4" sx={{ mb: 0, textAlign: "center", color: 'rgba(65, 75, 86, 1)' }}>
        90-Day Spending Summary
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "10rem",
          paddingRight: '4rem',
          alignItems: 'baseline',
          '@media (max-width: 1340px)': {
            gap: "5rem",
            paddingRight: '2rem',
          },
          '@media (max-width: 1240px)': {
            gap: "2rem",
            paddingRight: '1rem',
          },
          '@media (max-width: 1140px)': {
            gap: "0rem",
            paddingRight: '0rem',
          },
        }}
      >
        <VendorSpendChart vendorSpendByWeek={vendorSpendByWeek} />
        <TopStylesPieChart topStyles={topStyles} />
      </Box>
    </Box>
  );
}
