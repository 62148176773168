import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import SideCheckoutItem from "./SideCheckoutItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from '@mui/material/Skeleton';
import VendorLogo from "custom/img/VendorLogo";
import { CartHeaderText } from "custom/text/shopping_page/StandardSideCartTexts";
import { LOADING_STATES } from "features/cartsSlice";

function SideVendorCart({ vendorCode, vendorData, totals, hidePrices }) {
  const [styleItems, setStyleItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const formattedSubtotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totals?.subtotal || 0);

  useEffect(() => {
    if (!vendorData?.items) {
      return;
    }
    let newStyleItems = [];
    for (const checkoutItem of vendorData?.items) {
      let targetStyleItemIndex = newStyleItems.findIndex((styleItem) => {
        const sameStyleCode = styleItem.style === checkoutItem.style;
        const sameColorID = styleItem.colorDesc === checkoutItem.colorDesc;
        return sameStyleCode && sameColorID;
      });
      const foundStyleItem = Boolean(targetStyleItemIndex >= 0);

      let checkoutItemTotalPrice = checkoutItem.totalPrice;
      if (typeof checkoutItemTotalPrice === "string") {
        checkoutItemTotalPrice = Number(
          checkoutItemTotalPrice.replace("$", "")
        );
      }
      let checkoutItemWarehouse = checkoutItem.warehouse;
      if (vendorCode === "cm" && checkoutItemWarehouse === "NC") {
        checkoutItemWarehouse = "Indian Trail, NC";
      }

      let targetStyleItem;
      if (foundStyleItem) {
        targetStyleItem = newStyleItems[targetStyleItemIndex];
        if (targetStyleItem.warehouse !== checkoutItemWarehouse) {
          targetStyleItem.warehouse = "Multiple Warehouses";
        }
        const targetSizeEntryIndex = targetStyleItem.sizeEntries.findIndex(
          (sizeEntry) => {
            return sizeEntry.size === checkoutItem.size;
          }
        );
        const foundTargetSizeEntry = Boolean(targetSizeEntryIndex > -1);

        if (foundTargetSizeEntry) {
          const targetSizeEntry =
            targetStyleItem.sizeEntries[targetSizeEntryIndex];
          const newQuantity = targetSizeEntry.quantity + checkoutItem.quantity;
          const newTotalPrice =
            targetSizeEntry.totalPrice + checkoutItemTotalPrice;
          const newTargetSizeEntry = {
            ...targetSizeEntry,
            quantity: newQuantity,
            totalPrice: newTotalPrice,
          };
          const newSizeEntries = targetStyleItem.sizeEntries
            .slice(0, targetSizeEntryIndex)
            .concat(
              targetStyleItem.sizeEntries.slice(targetSizeEntryIndex + 1)
            );
          newSizeEntries.push(newTargetSizeEntry);
          targetStyleItem.sizeEntries = newSizeEntries;
        } else {
          targetStyleItem.sizeEntries.push({
            size: checkoutItem.size,
            totalPrice: checkoutItem.totalPrice,
            quantity: checkoutItem.quantity,
          });
        }
        newStyleItems = newStyleItems
          .slice(0, targetStyleItemIndex)
          .concat(newStyleItems.slice(targetStyleItemIndex + 1));
      } else {
        targetStyleItem = {
          style: checkoutItem.style,
          colorDesc: checkoutItem.colorDesc,
          description: checkoutItem.description,
          dgiStyleSKU: checkoutItem.dgi_style_sku,
          masterColor: checkoutItem.master_color,
          warehouse: checkoutItemWarehouse,
          sizeEntries: [
            {
              size: checkoutItem.size,
              quantity: checkoutItem.quantity,
              totalPrice: checkoutItemTotalPrice,
            },
          ],
        };
      }
      newStyleItems.push(targetStyleItem);
    }
    setStyleItems(newStyleItems);
  }, [vendorData]);

  if (vendorData?.loading === LOADING_STATES.LOADING_STATUS) {
    return (
      <Skeleton variant="rounded" height={48}>
      </Skeleton>
    );
  }

  return (
    <Accordion
      onChange={() => setIsCartOpen(!isCartOpen)}
      className={styles.sideVendorCart}
      disabled={styleItems.length < 1}
      sx={{
        "&.Mui-expanded": {
          margin: "0px",
        },
        "&.MuiPaper-root": {
          marginTop: "0px",
          borderRadius: "5px",
        },
        borderTop: "none",
        border: "1px solid #d2d6da",
        boxShadow: "none",
        "&:hover": {
          boxShadow:
            "0 10px 20px rgba(50, 50, 93, 0.07), 0 3px 10px rgba(0, 0, 0, 0.05)",
        },
        transition: "box-shadow 0.3s",
        "&::before": {
          content: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: "#dce3e8",
          color: "#999",
          "&:hover": {
            boxShadow: "none",
            cursor: "normal",
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-charcoal" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          padding: "4px 0px 4px 8px",
          "&.MuiAccordionSummary-root": {
            display: "flex",
            alignItems: "center",
            gap: "0px",
            height: "48px",
            "&.Mui-disabled": {
              background: 'rgba(239, 243, 245, 1)'
            }
          },
          "&.Mui-expanded": {
            minHeight: "48px",
            padding: "4px 0px 4px 8px",
          },
          "& .MuiAccordionSummary-content": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            order: 2,
          },
          borderRadius: "5px",
        }}
      >
        <div className="flex flex-row items-center gap-x-[6px]">
          <VendorLogo vendorDataKey={`${vendorCode}_data`} />
          <CartHeaderText
            content={` (${totals?.totalItems || 0})`}
            sx={{ order: 1 }}
          />
        </div>
        <CartHeaderText
          content={formattedSubtotal}
          hidePrices={hidePrices}
          sx={{ order: 3 }}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          cursor: "default",
          paddingTop: "12px",
          paddingBottom: "4px",
          borderTop: "dashed 1px #d2d6da",
          px: '8px'
        }}
      >
        <div className={styles.sideCheckoutItems}>
          {styleItems.map((styleItem, index) => (
            <SideCheckoutItem
              key={`${styleItem["style"]}_${index}`}
              styleItem={styleItem}
              hidePrices={hidePrices}
              isCartOpen={isCartOpen}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default SideVendorCart;
