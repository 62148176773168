import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDataContext } from "App";
import { usePostHog } from "posthog-js/react";
import { useSearchBox } from "react-instantsearch";
import { typesenseClient } from "App";
import SearchIcon from "@mui/icons-material/Search";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import {
  TextField,
  IconButton,
  Popper,
  Paper,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import DefaultFavorites from "./DefaultFavorites"; // Import DefaultFavorites

const SearchBar = ({
  isFocused,
  setIsFocused,
  autoCompleteSuggestions,
  setAutoCompleteSuggestions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchSession, setSearchSession } = useDataContext();
  const posthog = usePostHog();
  const { refine, query } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const [anchorEl, setAnchorEl] = useState(null);
  const containerRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

  const topStyles = useSelector((state) => state.user?.user?.topStyles);
  const firstName = useSelector((state) => state.user?.user?.firstName);

  // State variables to track suggestions source
  const [suggestionsFromTopStyles, setSuggestionsFromTopStyles] = useState(false);
  const [usingDefaultFavorites, setUsingDefaultFavorites] = useState(false);

  // State variables for loading
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [isInitialFocusLoading, setIsInitialFocusLoading] = useState(false); // New state variable

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "k") {
        event.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setMenuWidth(containerRef.current.offsetWidth);
      }
    };

    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Need to reset after leaving shopping page
    if (!location.pathname.includes("/shop")) {
      setInputValue("");
      setAutoCompleteSuggestions([]);
      setSelectedIndex(-1);
      refine("");
    }
  }, [location.pathname]);

  const getAutoCompleteSuggestions = async (query) => {
    if (query === "") {
      return;
    }

    const results = await typesenseClient
      .collections("products_queries")
      .documents()
      .search({
        q: query,
        query_by: "q",
        sort_by: "count:desc",
      });

    setAutoCompleteSuggestions(
      results.hits.flatMap((hit) =>
        hit.highlights.map((highlight) => highlight.snippet)
      )
    );

    // Update state flags after fetching new suggestions
    setSuggestionsFromTopStyles(false);
    setUsingDefaultFavorites(false);
    setSelectedIndex(-1);

    setIsSuggestionsLoading(false); // Loading finished
  };

  const submitQuery = (value = inputValue) => {
    if (searchSession.in_progress) {
      posthog?.capture("search_and_update_cart", {
        updated_cart: searchSession.updated_cart,
        items_added: new Set(searchSession.items_added),
        search_term: searchSession.search_term,
      });
    }
    setSearchSession({
      in_progress: true,
      search_term: value,
      items_added: [],
      updated_cart: false,
      prev_path: null,
    });
    if (location.pathname !== "/shop") {
      navigate("/shop");
    }
    refine(value);
    handleClose();
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (newValue === "") {
      setSuggestionsFromTopStylesFunc();
    } else {
      getAutoCompleteSuggestions(newValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (
        selectedIndex !== -1 &&
        selectedIndex < autoCompleteSuggestions.length
      ) {
        const selectedOption = autoCompleteSuggestions[selectedIndex];
        const optionValue = selectedOption.replace(/<\/?mark>/g, "");
        setInputValue(optionValue);
        submitQuery(optionValue);
      } else {
        submitQuery();
      }
      event.target.blur();
    } else if (
      event.key === "ArrowDown" ||
      (event.key === "Tab" && !event.shiftKey)
    ) {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < autoCompleteSuggestions.length - 1 ? prevIndex + 1 : -1
      );
    } else if (
      event.key === "ArrowUp" ||
      (event.key === "Tab" && event.shiftKey)
    ) {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex > -1 ? prevIndex - 1 : autoCompleteSuggestions.length - 1
      );
    }
  };

  const handleFocus = (e) => {
    e.target.select();
    if (inputValue === "") {
      setSuggestionsFromTopStylesFunc();
      setIsFocused(true);
      setAnchorEl(containerRef.current);
      if (containerRef.current) {
        setMenuWidth(containerRef.current.offsetWidth);
      }
    } else {
      // Clear suggestions and set loading state before setting focus
      setIsInitialFocusLoading(true);
      setAutoCompleteSuggestions([]);
      setSuggestionsFromTopStyles(false);
      setUsingDefaultFavorites(false);
      setIsFocused(true);
      setAnchorEl(containerRef.current);
      if (containerRef.current) {
        setMenuWidth(containerRef.current.offsetWidth);
      }
      getAutoCompleteSuggestions(inputValue).then(() => {
        setIsInitialFocusLoading(false);
      });
    }
  };

  const handleClose = () => {
    setIsFocused(false);
    setAnchorEl(null);
  };

  const setSuggestionsFromTopStylesFunc = () => {
    // Filter and map topStyles if available
    let styles = topStyles
      ? topStyles.filter((item) => item.style).map((item) => item.style)
      : [];

    if (styles.length > 0) {
      // Limit to 20 items if more than 20
      if (styles.length > 20) {
        styles = styles.slice(0, 20);
      }
      setUsingDefaultFavorites(false);
    } else {
      // Use DefaultFavorites if no user favorites
      styles = DefaultFavorites.slice(0, 20);
      setUsingDefaultFavorites(true);
    }
    setAutoCompleteSuggestions(styles);
    setSuggestionsFromTopStyles(true);
    setSelectedIndex(-1);
  };

  const renderOption = (option, index) => {
    if (!option) return null;
    const isSelected = index === selectedIndex;
    const isLastOption = index === autoCompleteSuggestions.length - 1;
    const parts = option.split(/<mark>|<\/mark>/);
    const optionValue = parts.filter((part) => part).join("");
    return (
      <div
        key={index}
        className={`flex flex-col gap-[8px] ${
          suggestionsFromTopStyles ? "font-light" : "font-bold"
        } pt-[8px]
                     hover:bg-grayscaleIce hover:cursor-pointer w-full
                     ${isLastOption && "pb-[8px]"}
                     ${isSelected ? "bg-grayscaleIce" : ""}`}
        onClick={() => {
          setInputValue(optionValue);
          submitQuery(optionValue);
        }}
      >
        <div className="pl-[12px] flex items-center">
          <SearchIcon className="text-grayscaleSmoke" fontSize="small" />
          <div className="pl-[1.33rem]">
            {parts.map((part, idx) =>
              idx % 2 === 0 ? (
                part.replace(/"/g, "")
              ) : (
                <span key={idx} className="font-light">
                  {part.replace(/"/g, "")}
                </span>
              )
            )}
          </div>
        </div>
        {!isLastOption && (
          <div className="flex justify-center">
            <div className="bg-dustyBlue h-[1px] w-[98%]" />
          </div>
        )}
      </div>
    );
  };

  // Adjusted showSuggestions to consider loading states
  const showSuggestions =
    isFocused &&
    !isSuggestionsLoading &&
    !isInitialFocusLoading &&
    autoCompleteSuggestions.length > 0;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        ref={containerRef}
        className={`flex flex-col max-w-[550px] w-full bg-grayscaleWhite 
                rounded-tr-softer rounded-tl-softer
                ${!showSuggestions && "rounded-br-softer rounded-bl-softer"}`}
      >
        <div
          className={`w-full h-12 bg-grayscaleWhite
                        rounded-tr-softer rounded-tl-softer
                        ${
                          !showSuggestions &&
                          "rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer"
                        }`}
        >
          <div
            className={`flex flex-row items-end gap-[0.5rem] 
                            w-full h-full p-[4px]
                            ${
                              showSuggestions
                                ? "rounded-tr-softer rounded-tl-softer"
                                : "rounded-br-softer rounded-bl-softer rounded-tr-softer rounded-tl-softer"
                            }`}
          >
            <div className={`flex-grow h-full w-full relative`}>
              <TextField
                fullWidth
                autoComplete="off"
                value={inputValue}
                onChange={handleChange}
                placeholder="Search items by style number, description, or brand..."
                variant="standard"
                className="pb-[1px]"
                InputProps={{
                  disableUnderline: !showSuggestions,
                  className: `custom-search-input bg-grayscaleWhite w-full min-h-full 
                          rounded-l-full rounded-r-full flex justify-start gap-[0.75rem] pt-[2px]`,
                  inputRef: inputRef,
                  classes: {
                    underline: "custom-underline",
                  },
                  startAdornment: (
                    <IconButton
                      onClick={() => submitQuery()}
                      className="hover:bg-transparent pt-[6px]"
                      aria-label="search"
                    >
                      <SearchIcon className="fill-charcoal text-[22px]" />
                    </IconButton>
                  ),
                  inputProps: {
                    className: `${styles.customPlaceholder} ${
                      inputValue.length > 0 && "pt-[6px]"
                    }`,
                  },
                }}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
        <Popper
          open={isFocused}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ width: menuWidth, zIndex: 1300 }}
        >
          <Paper
            elevation={3}
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
            }}
          >
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
              }}
            >
              {showSuggestions && (
                <div
                  className="pl-[12px] flex items-center pt-[2px] pb-[2px]"
                  style={{
                    borderBottom: "1px solid rgba(223, 233, 240, 1)", // dustyBlue
                    borderWidth: "thin",
                  }}
                >
                  {suggestionsFromTopStyles ? (
                    <WhatshotIcon
                      fontSize="small"
                      style={{ color: "#FF7F11", paddingBottom: "2px" }}
                    />
                  ) : (
                    <SearchIcon
                      fontSize="small"
                      style={{ color: "#FF7F11", paddingBottom: "2px" }}
                    />
                  )}
                  <div className="pl-[1.33rem] flex items-center w-full">
                    <Typography
                      variant="caption"
                      className="text-grayscaleSmoke"
                    >
                      {suggestionsFromTopStyles
                        ? usingDefaultFavorites
                          ? "Trending"
                          : firstName
                          ? `${firstName}'s Favorites`
                          : "Your Favorites"
                        : "Suggested"}
                    </Typography>
                    <div className="flex-grow border-t border-gray-300 ml-2" />
                  </div>
                </div>
              )}
              {autoCompleteSuggestions.map((option, index) => (
                <div key={index} style={{ padding: 0 }}>
                  {renderOption(option, index)}
                </div>
              ))}
            </div>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default SearchBar;
