const testimonialsData = [
  {
    text: `These guys are awesome and this platform is an awesome training tool and purchasing tool!! Keep it up fellas 👊🏻👊🏻`,
    author: "Ed",
    position: "Operations Manager",
    shopName: "Thread Mob Custom Apparel",
    location: "Columbus, GA",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/threadmob_logo.svg",
  },
  {
    text: `DGI Apparel has transformed how we order blank apparel, and quote product. It's a game changer!`,
    author: "Scott",
    position: "Owner",
    shopName: "King Screen",
    location: "Roanoke, VA",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/king_screen_logo.svg",
  },
  {
    text: `I used to spend hours every day comparing 
    different supplier pricing on items, stock levels and availability.  
    The time it takes to calculate and place orders 
    has been shortened and so has the stress.  Thank you DGI for your service!`,
    shortText: `I used to spend hours every day comparing different supplier pricing on items,
    stock levels and availability...Thank you DGI for your service!`,
    author: "Sharon",
    position: "Owner",
    shopName: "UME Custom Embroidery & Imprinting",
    location: "Fort Lupton, CO",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/ume_logo.svg",
  },
  {
    text: `I no longer visit each individual supplier to place my blank garment orders - I rely completely on DGI.  
    It saves me time (lots of time) and money. DGI has changed the industry.`,
    author: "Kerry",
    position: "Owner",
    shopName: "Big Frog of the Upper West Side",
    location: "New York, NY",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/big_frog_logo.svg",
  },
  {
    text: `It's so amazing and our ordering person has been singing praises all week. So awesome!!`,
    author: "Richard",
    position: "Owner",
    shopName: "Imagination Athletics",
    location: "Conway, SC",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/imagination_athletics_logo.svg",
  },
  {
    text: `Absolutely LOVE this platform for my ordering needs! 
    This has cut our ordering time in half and makes it super easy to train new hires on ordering! Thanks DGI :)`,
    author: "Kelsey",
    position: "Store Manager",
    shopName: "CBJ Custom Apparel",
    location: "Sulphur Springs, TX",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/cbj_customs_logo.svg",
  },
  {
    text: `Big time saver and money saver. I consider DGI a must have if you are purchasing apparel from multiple suppliers.`,
    author: "Jeff",
    position: "Owner",
    shopName: "Big Frog of San Marcos",
    location: "San Marcos, CA",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/big_frog_logo.svg",
  },
  {
    text: `An amazing site and time-saver!`,
    author: "Laura",
    position: "Owner",
    shopName: "Green Tee Promos",
    location: "Longmont, CO",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/green_tee_promos_logo.svg",
  },
  {
    text: `DGI offers a concise and well put together solution for a problem that I had previously thought was unavoidable. 
    Major props to the team for identifying a weak spot in our industry, designing a well made solution, AND OFFERING IT FOR FREE.`,
    shortText: `Major props ... for identifying a weak spot in our industry, designing a well made solution, AND OFFERING IT FOR FREE.`,
    author: "Jordan",
    position: "Account Manager",
    shopName: "Danger Press",
    location: "Atlanta, GA",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/danger_press_logo.svg",
  },
  {
    text: `This platform is amazing! If you are using the major suppliers and not using this tool, you are missing a great opportunity!`,
    author: "Gail",
    position: "Owner",
    shopName: "Big Frog of Shorewood",
    location: "Shorewood, IL",
    shop_logo: "https://d34c0c1in94n75.cloudfront.net/big_frog_logo.svg",
  },
];
export default testimonialsData;
