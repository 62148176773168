import React, { useState } from "react";
import { Paper, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Carousel from "react-material-ui-carousel";

const TutorialCarousel = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isNext, setIsNext] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  const items = [
    {
      bigText: (
        <Typography variant="h4" component="span">
          DGI Works in <Typography variant="h4" component="span" sx={{ borderBottom: "3px solid rgb(255, 127, 17)" }}>3 Magic Steps</Typography>
        </Typography>
      ),
      smallText: "Click 'Next' to see how we cut your purchasing time in half.",
      gif: "https://d34c0c1in94n75.cloudfront.net/Wand.gif"
    },
    {
      bigText: "1. Connect Your Vendors",
      smallText: (
        <Typography variant="body1" component="span">
          This helps us verify you as a wholesale purchaser and load <em>your</em> custom pricing.
        </Typography>
      ),
      gif: "https://d34c0c1in94n75.cloudfront.net/VendorConnect.gif",
    },
    {
      bigText: (
        <Typography variant="h4" component="span">
          2. Compare <Typography variant="h4" component="span" sx={{ borderBottom: "3px solid rgb(255, 127, 17)", fontStyle: "italic" }}>Your</Typography>&nbsp;Custom Pricing and Inventory
        </Typography>
      ),
      gif: "https://d34c0c1in94n75.cloudfront.net/CheckInventory.gif",
    },
    {
      bigText: (
        <Typography variant="h4" component="span">
          3. Add Items Directly to <Typography variant="h4" component="span" sx={{ borderBottom: "3px solid rgb(255, 127, 17)", fontStyle: "italic" }}>Your</Typography>&nbsp;Vendor Cart
        </Typography>
      ),
      gif: "https://d34c0c1in94n75.cloudfront.net/AddingToCart.gif",
    },
    {
      bigText: "Ready to Rock n Roll?",
      gif: "https://d34c0c1in94n75.cloudfront.net/Rocking.gif",
    }
  ];

  const handleNext = () => {
    setIsNext(true);
    if (activeStep === items.length - 1) {
      onClose(); // Close the dialog
      setTimeout(() => {
        if (window.Intercom) {
          window.Intercom('startTour', 521634);
        } else {
          console.error("Intercom is not initialized");
        }
      }, 300);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };    

  const handleBack = () => {
    setIsNext(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "640px",
        "@media (max-height: 748px)": {
          minHeight: "500px", // Set minHeight to 500px when the screen height is below 748px
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Carousel
          autoPlay={false}
          indicators={false}
          animation={isNext ? "slide" : "fade"}
          index={activeStep}
          duration={1000}
          navButtonsAlwaysInvisible={true}
        >
          {items.map((item, index) => (
            <Item key={index} item={item} index={index} />
          ))}
        </Carousel>
      </Box>

      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
          gap: "8rem",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{
            padding: "0.5rem 3rem",
            fontSize: "1.25rem",
            color: "rgba(0, 110, 165, 1)",
            borderColor: "rgba(0, 110, 165, 1)",
            "&:hover": {
              borderColor: "rgba(0, 110, 165, 1)",
              backgroundColor: "rgba(0, 110, 165, 0.1)",
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{
            padding: activeStep === items.length - 1 ? "0.5rem 3rem 0.5rem 3rem" : "0.5rem 2rem 0.5rem 3rem",
            fontSize: "1.25rem",
            backgroundColor: "rgba(0, 110, 165, 1)",
            "&:hover": {
              backgroundColor: "rgba(0, 110, 165, 0.9)",
            },
          }}
        >
          {activeStep === items.length - 1 ? "Let's Do It!" : <>Next <ArrowForwardIcon sx={{ ml: 1 }} /></>}
        </Button>
      </Box>
    </Box>
  );
};

function Item({ item, index }) {
  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        justifyContent: item.gif ? "center" : "center",
        alignItems: "center",
        padding: "1rem",
        backgroundColor: "none",
        boxShadow: "none",
        "@media (max-height: 748px)": {
          minHeight: "480px", // Reduce minHeight to 400px when screen height is below 748px
        },
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          paddingBottom: index < 2 ? "0.5rem" : "0rem",
          marginBottom: index >= 2 ? "0rem" : "0.5rem",
          color: "#150DOA"
        }}
      >
        {item.bigText}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ paddingBottom: "1.5rem", maxWidth: "420px", color: "rgba(65, 75, 86, 1)" }}
      >
        {item.smallText}
      </Typography>
      {item.gif && (
        <Box
          component="img"
          src={item.gif}
          alt={item.smallText}
          sx={{
            maxWidth: "600px",
            width: "100%",
            height: "auto",
            // Regular behavior for slides 1 and 5 (objectFit: contain)
            maxHeight: index === 0 || index === 4 ? "280px" : "none",
            paddingTop: index === 0 || index === 4 ? "0.5rem" : "none",
            objectFit: index === 0 || index === 4 ? "contain" : "cover", // Ensure "contain" for slides 1 and 5
            // Crop GIFs for slides 3 and 4 when the screen height is below 748px
            "@media (max-height: 748px)": {
              maxHeight: index === 0 || index === 4 ? "280px" : "300px", // Crop slides 3 and 4 to 300px
              objectFit: index === 0 || index === 4 ? "contain" : "cover", // Ensure "contain" for slides 1 and 5
            },
            border: index > 0 && index !== 4 ? "1px solid #d2d6da" : "none",
          }}
        />      
      )}
    </Paper>
  );
}

export default TutorialCarousel;
