import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useCheckoutContext } from "App";
import { useNavigate, useParams } from "react-router-dom";

const NavButtons = ({ invalidPONumber }) => {
  const { canConfirmOrder } = useCheckoutContext();
  const { vendorCode } = useParams();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: 3,
        paddingBottom: 6,
        "& .MuiButton-root": {
          background: "rgba(0, 110, 165, 1)", // blueblue
          color: "rgba(255, 255, 255, 1)", // grayscaleWhite
          ":hover": {
            background: "rgba(0, 88, 141, 1)" // denim
          }
        }
      }}
    >
      <Button
        startIcon={<ArrowBackIcon sx={{ color: "white" }} />}
        variant="contained"
        sx={{ marginRight: 6 }}
        onClick={() => navigate("/cart")}
      >
        Back
      </Button>
      <Button
        endIcon={<ArrowForwardIcon sx={{ color: "white" }} />}
        variant="contained"
        color="primary"
        sx={{ marginLeft: 6 }}
        disabled={(canConfirmOrder && !invalidPONumber) ? false : true}
        onClick={() => navigate(`/confirmation/${vendorCode}`)}
      >
        Next
      </Button>
    </Box>
  );
};

export default NavButtons;
