export const SAVINGS_THRESHOLD = 5; // dollar amount
const SAVINGS_FALLBACK = 0; // if we can't calculate savings

export const calculateSavingsPotential = (quantity, selectedVendorPrice, vendorProducts) => {
  let highestOverallPrice = null;
  const vendorProductData = Object.values(vendorProducts);
  for (const vendorData of vendorProductData) {
    const vendorPrice = vendorData?.data?.product_price;
    if (!highestOverallPrice || vendorPrice > highestOverallPrice) {
      highestOverallPrice = vendorPrice;
    }
  }
  if (!highestOverallPrice || !selectedVendorPrice) {
    return SAVINGS_FALLBACK;
  }
  return quantity * (highestOverallPrice - selectedVendorPrice);
}
