import { createApi } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";

const BASE_API_URLS = {
  development: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com",
  staging: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com",
  production: "https://byyhh7s57b.execute-api.us-east-1.amazonaws.com",
};

const BASE_API_URL =
  process.env.REACT_APP_LOCAL_DEV === "true"
    ? "http://127.0.0.1:8000"
    : BASE_API_URLS[process.env.NODE_ENV] || BASE_API_URLS.production;

const customBaseQuery = async (args, api, extraOptions) => {
  const { baseUrl, url, method, headers = {}, ...rest } = args;
  try {
    const session = await Auth.currentSession();
    const jwtToken = session.accessToken.jwtToken;

    const response = await fetch(`${baseUrl}${url}`, {
      method,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
      ...rest,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const data = await response.json();
      return { data };
    }
    
    return { data: null };

  } catch (error) {
    console.error("API Error:", error);
    return {
      error: {
        status: error.response?.status || 500,
        data: error.response?.data || error.message,
      },
    };
  }
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getVendorByCode: builder.query({
      query: (vendorCode) => ({
        baseUrl: BASE_API_URL,
        url: `/vendors/${vendorCode}`,
        method: "GET",
      }),
      providesTags: (result, error, vendorCode) => [{ type: 'Vendor', id: vendorCode }],
    }),
    postVendorReview: builder.mutation({
      query: ({vendorCode, reviewData, userId}) => ({
        baseUrl: BASE_API_URL,
        url: `/vendors/${vendorCode}/review`,
        method: 'POST',
        body: JSON.stringify(reviewData),
        headers: {
          'Content-Type': 'application/json',
          'user-identifier': userId,
        },
      }),
      invalidatesTags: (result, error, { vendorCode }) => [
        { type: 'Vendor', id: vendorCode }
      ],
    }),
    getUserById: builder.query({
      query: (userId) => ({
        baseUrl: BASE_API_URL,
        url: `/users/${userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetVendorByCodeQuery, usePostVendorReviewMutation, useGetUserByIdQuery } = baseApi;