import React, { useState, useEffect } from "react";
import WarehouseShippingMethods from "./WarehouseShippingMethods";
import BillingInfo from "./BillingInfo";
import ShippingLocation from "./ShippingLocation";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

const CheckoutFields = ({
  checkoutDetails,
  getShippingMethods,
  loadingShippingMethods,
  errorFetchingShippingMethods,
  setInvalidPONumber,
  setMissingShippingMethods
}) => {
  const { vendorCode } = useParams();
  const [shippingAddrs, setShippingAddrs] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [billingOptions, setBillingOptions] = useState([]);

  useEffect(() => {
    if (checkoutDetails && Object.keys(checkoutDetails).length > 0) {
      const selectedShippingAddr = checkoutDetails["shippingAddr"] ?? null;
      const otherShippingAddrs = checkoutDetails["shippingOptions"] ?? null;
      if (selectedShippingAddr && otherShippingAddrs) {
        const allShippingAddrs = new Array(selectedShippingAddr).concat(
          otherShippingAddrs
        );
        setShippingAddrs(allShippingAddrs);
      }
    }
  }, [checkoutDetails]);

  const sortMethodsByFreightCost = (methodA, methodB) => {
    let freightCostA = methodA["freightCost"] ?? 0;
    let freightCostB = methodB["freightCost"] ?? 0;

    if (typeof freightCostA === "string") {
      freightCostA = Number(freightCostA.replace("$", ""));
    }

    if (typeof freightCostB === "string") {
      freightCostB = Number(freightCostB.replace("$", ""));
    }

    if (freightCostA > freightCostB) {
      return -1;
    } else if (freightCostB > freightCostA) {
      return 1;
    }

    return 0;
  };

  const constructShippingOptions = () => {
    const newShippingOptions = [];
    let uniqueWarehouses = [];

    if (vendorCode === "ss" || vendorCode === "sm") {
      uniqueWarehouses = [
        ...new Set(
          checkoutDetails["shippingMethods"].map((method) => {
            return method["warehouse"];
          })
        ),
      ];
    } else if (vendorCode === "ab" || vendorCode === "cm") {
      uniqueWarehouses = checkoutDetails["shippingMethods"].reduce(
        (acc, method) => {
          if (!acc[method["warehouseCode"]]) {
            acc[method["warehouseCode"]] = method["warehouse"].trim();
          }
          return acc;
        },
        {}
      );
    }

    if (vendorCode === "ss" || vendorCode === "sm") {
      for (const uniqueWhse of uniqueWarehouses) {
        const warehouseDeliveryMethods = checkoutDetails[
          "shippingMethods"
        ].filter((shippingMethod) => {
          return shippingMethod["warehouse"] === uniqueWhse;
        });
        newShippingOptions.push([uniqueWhse, warehouseDeliveryMethods]);
      }
    } else if (vendorCode === "ab" || vendorCode === "cm") {
      for (const [uniqueWhseCode, uniqueWhse] of Object.entries(
        uniqueWarehouses
      )) {
        const warehouseDeliveryMethods = checkoutDetails["shippingMethods"]
          .filter((shippingMethod) => {
            return shippingMethod["warehouseCode"] === uniqueWhseCode;
          })
          .sort(sortMethodsByFreightCost);
        newShippingOptions.push([
          uniqueWhse,
          warehouseDeliveryMethods,
          uniqueWhseCode,
        ]);
      }
    }
    return newShippingOptions;
  };

  useEffect(() => {
    if (checkoutDetails && Object.keys(checkoutDetails).length > 0) {
      // There is mysterious type error where AB's shipping methods
      // cannot call .reduce()
      console.log("resp shipping methods:", checkoutDetails["shippingMethods"])
      if (
        checkoutDetails["shippingMethods"] &&
        Array.isArray(checkoutDetails["shippingMethods"])
      ) {
        const newShippingOptions = constructShippingOptions();
        setShippingMethods(newShippingOptions);
      } else {
        if (vendorCode === "ab") {
          setMissingShippingMethods(true);
        }
      }
    }
  }, [checkoutDetails]);

  useEffect(() => {
    if (checkoutDetails && Object.keys(checkoutDetails).length > 0) {
      if (checkoutDetails["billingOptions"]) {
        setBillingOptions(checkoutDetails["billingOptions"]);
      }
    }
  }, [checkoutDetails]);

  return (
    <Box>
      <ShippingLocation
        shippingAddrs={shippingAddrs}
        setInvalidPONumber={setInvalidPONumber}
      />
      <WarehouseShippingMethods
        vendorCode={vendorCode}
        shippingMethods={shippingMethods}
        setShippingMethods={setShippingMethods}
        getShippingMethods={getShippingMethods}
        loadingShippingMethods={loadingShippingMethods}
        errorFetchingShippingMethods={errorFetchingShippingMethods}
      />
      <BillingInfo
        billingOptions={billingOptions}
        setBillingOptions={setBillingOptions}
      />
    </Box>
  );
};

export default CheckoutFields;
