// TODO kill this
export const cartVendorFilters = {
  all: {
    name: "All Carts",
    data_key: "all",
    code: "all",
    short_name: "all",
  },
  ab: {
    name: "Alphabroder",
    data_key: "ab_data",
    code: "ab",
    short_name: "Alphabroder",
  },
  sm: {
    name: "Sanmar",
    data_key: "sm_data",
    code: "sm",
    short_name: "Sanmar",
  },
  ss: {
    name: "S&S Activewear",
    data_key: "ss_data",
    code: "ss",
    short_name: "S&S",
  },
  cm: {
    name: "Carolina Made",
    data_key: "cm_data",
    code: "cm",
    short_name: "Carolina Made",
  },
  as: {
    name: "Augusta Sportswear",
    data_key: "as_data",
    code: "as",
    short_name: "Augusta",
  },
  acc: {
    name: "Atlantic Coast Cotton",
    data_key: "acc_data",
    code: "acc",
    short_name: "ACC",
  },
  pg: {
    name: "PrintGear",
    data_key: "pg_data",
    code: "pg",
    short_name: "PrintGear",
  },
  asc: {
    name: "AS Colour",
    data_key: "asc_data",
    code: "asc",
    short_name: "AS Colour",
  },
  sta: {
    name: "Staton",
    data_key: "sta_data",
    code: "sta",
    short_name: "Staton",
  },
  slc: {
    name: "SLC Activewear",
    data_key: "slc_data",
    code: "slc",
    short_name: "SLC",
  },
};
