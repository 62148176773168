import React, { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import styles from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";
import { useUserStateContext } from "App";
import { sendGetCartsPayload } from "features/sendGetCartsPayload";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { useDispatch, useSelector } from "react-redux";
import { getVendorName } from "./CartCheckout";

export default function CartsHeader({
  selectedFilter,
  connectedVendorCodes,
}) {

  const [numberOfItems, setNumberOfItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [numberOfCarts, setNumberOfCarts] = useState(0);
  const [searchParams] = useSearchParams();
  const displayCartData = useSelector(state => state.carts?.carts)
  const { userCreds } = useUserStateContext();
  const { sendJsonMessage } = useWebsocketContext();
  const dispatch = useDispatch();

  const handleRefreshCarts = () => {
    sendGetCartsPayload(
      userCreds.cognitoID,
      sendJsonMessage,
      dispatch,
      connectedVendorCodes
    )
  };


  useEffect(() => {
    if (displayCartData) {
      let cartDataEntries = Object.entries(displayCartData).filter(
        (cartEntry) => cartEntry[1],
      ); // filter for valid cart data

      if (searchParams.get("distributor")) {
        cartDataEntries = cartDataEntries.filter((cartEntry) => {
          return cartEntry[0] === searchParams.get("distributor");
        });
      }

      let totalItems = 0;
      let priceTotal = 0.0;
      let numberOfCarts = 0;
      const newVendorSubtotals = {};
      for (const distributorCartEntry of cartDataEntries) {
        const [distributorCode, distributorCartData] = distributorCartEntry;
        let subtotal = 0.0;
        if (distributorCartData.items) {
          numberOfCarts++;
          for (const item of distributorCartData.items) {
            if (item.quantity) {
              totalItems += item.quantity;
            }
            if (item.totalPrice) {
              priceTotal += item.totalPrice;
              subtotal += item.totalPrice;
            }
          }
        }
        newVendorSubtotals[distributorCode] = subtotal;
      }

      priceTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(priceTotal);

      setNumberOfItems(totalItems);
      setTotalAmount(priceTotal);
      setNumberOfCarts(numberOfCarts);
    }
  }, [displayCartData, searchParams]);

  return (
    <div className={`mb-[25px] ${styles.checkoutHeader}`}>
      <div className={`bg-dustyBlue ${styles.headerInfo}`}>
        <div>
          <span className="text-charcoal text-2xl">{getVendorName(selectedFilter)}</span>
        </div>
        <IconButton
          className={`bg-blueblue hover:bg-denim px-[8px] py-[4px] normal-case ${styles.refreshCartWrapper}`}
          onClick={handleRefreshCarts}
        >
          <RefreshIcon className="text-grayscaleWhite" />
          <span className="text-grayscaleWhite text-lg">Refresh Carts</span>
        </IconButton>
      </div>
      <div className={styles.checkoutTotals}>
        <div>
          <span className="text-lg">{`${numberOfItems} items`}</span>
        </div>
        <div>
          <span className="text-lg">{totalAmount}</span>
        </div>
        <div>
          <span className="text-lg">
            {numberOfCarts > 1
              ? `${numberOfCarts} carts`
              : `${numberOfCarts} cart`}
          </span>
        </div>
      </div>
    </div>

  );
};
