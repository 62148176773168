import vendors from "constants/vendors";
import styles from "./styles.module.scss";

export default function VendorLogo({ vendorDataKey, alt = "" }) {
  const vendorLogoSrc = vendors[vendorDataKey]?.logo;
  const logoAlt = <span className={styles.priceRowCellText}>{alt}</span>;

  if (vendorLogoSrc == null) {
    return logoAlt;
  }

  return (
    <div
      className={styles.vendorLogo}
      data-upsize={
        vendorDataKey === "ab_data" ||
        vendorDataKey === "as_data" ||
        vendorDataKey === "pg_data" ||
        vendorDataKey === "acc_data" ||
        vendorDataKey === "sta_data"
      }
      data-downsize={vendorDataKey === "ss_data" || vendorDataKey === "sm_data"}
      data-downsize-more={vendorDataKey === "slc_data"}
    >
      <img src={vendorLogoSrc} />
    </div>
  );
}
