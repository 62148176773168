import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const BillingSummary = ({ paymentMethod }) => {
  const [cardPreview, setCardPreview] = useState("");

  useEffect(() => {
    if (paymentMethod) {
      setCardPreview(paymentMethod["name"]);
    }
  }, [paymentMethod]);

  return (
    <Box
      className="confirmationSection"
      sx={{
        mt: 2,        
        borderTop: "none",
        pb: 2,
        width: "100%",
        maxWidth: "550px",
        backgroundColor: "white",
      }}
    >
      <Box className="bg-dustyBlue" sx={{ p: 1, mb: 2 }}>
        <Typography className="text-grayscaleStone font-bold">
          Billing Method:
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ width: "100%", pl: 1 }}
      >
        <Grid item xs>
          <Typography>{cardPreview}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingSummary;
