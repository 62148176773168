import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import styles from "./styles.module.scss";
import DistributorModule from "./DistributorModule";
import vendors from "../constants/vendors";

export default () => {
  const vendorsInfo = Object.values(vendors);
  const vendorDataKeys = Object.keys(vendors);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const targetVendorCode = params.get('vendor');
  const resetPasswordLinks = {
    "Alphabroder": "https://www.alphabroder.com/login",
    "Carolina Made": "https://www.carolinamade.com/cgi-bin/live/wam_tmpl/login.p?site=CMD&layout=Base_b2b&page=login&loginLocation=/wam_tmpl/marketing.p%26page%3Dhomepage",
    "Sanmar": "https://www.sanmar.com/login",
    "S&S Activewear": "https://www.ssactivewear.com/helpcenter/forgotpassword",
    "Augusta Sportswear": "https://www.augustasportswear.com/ResetPasswordErrorView?state=forgetpassword&catalogId=10601&langId=-1&storeId=10251",
    "PrintGear": "https://www.printgear.com/recoverpassword",
    "Atlantic Coast Cotton": "https://www.orderacc.com/cgi-bin/liveb2b/wam_tmpl/login.p?site=ACC&layout=Responsive&page=login",
    "AS Colour": "https://ascolour.com/",
    "Staton": "https://www.statononline.com/login",
  };

  return (
    <div className={styles.connectionModules}>
      {vendorsInfo.map((vendorInfo, index) => (
        <DistributorModule
          key={vendorInfo.code}
          vendorInfo={vendorInfo}
          dataKey={vendorDataKeys[index]}
          resetPasswordLink={resetPasswordLinks[vendorInfo.name]}
          autoOpen={vendorInfo.code === targetVendorCode}
        />
      ))}
    </div>
  );
};
