import React from "react";
import {
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { highlightText } from "./orderSearchUtilities";
import ProductPageLink from "./ProductPageLink";
import ProductImage from "./ProductImage";

const OrderTableLineItems = ({ order, searchInput, vendor }) => {
  const formatToCurrency = (value) => {
    const number = formatToNumber(value);
    if (isNaN(number)) {
      return "Invalid input";
    }
    return `$${number.toFixed(2)}`;
  };

  const formatToNumber = (value) => {
    if (typeof value !== "string") {
      return value;
    }
    return parseFloat(value.replace(/[$\s]/g, ""));
  };

  // Centralize column widths with responsive behavior
  const columnStyles = {
    image: {
      width: "90px",
      borderBottom: "none",
      paddingLeft: "0px",
      "@media (max-width: 1000px)": {
        display: "none",
      },
    },
    style: {
      width: "12%",
      borderBottom: "none",
      "@media (max-width: 900px)": {
        width: "11%",
      },
      "@media (max-width: 750px)": {
        width: "23%",
      },
      "@media (max-width: 500px)": {
        width: "25%",
      },
    },
    color: {
      width: "16%",
      borderBottom: "none",
      "@media (max-width: 900px)": {
        width: "15%",
      },
      "@media (max-width: 750px)": {
        width: "35%",
      },
      "@media (max-width: 500px)": {
        width: "35%",
      },
    },
    size: {
      width: "10%",
      borderBottom: "none",
      "@media (max-width: 900px)": {
        width: "10%",
      },
      "@media (max-width: 750px)": {
        width: "15%",
      },
      "@media (max-width: 500px)": {
        width: "15%",
      },
    },
    brand: {
      width: "14%",
      borderBottom: "none",
      "@media (max-width: 900px)": {
        width: "13%",
      },
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
    warehouse: {
      width: "10%",
      borderBottom: "none",
      "@media (max-width: 900px)": {
        width: "14%",
      },
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
    unitPrice: {
      width: "10%",
      borderBottom: "none",
      textAlign: "right",
      "@media (max-width: 900px)": {
        width: "14%",
      },
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
    quantity: {
      width: "10%",
      borderBottom: "none",
      textAlign: "right",
      paddingRight: "40px",
      "@media (max-width: 900px)": {
        width: "14%",
      },
      "@media (max-width: 750px)": {
        width: "25%",
      },
      "@media (max-width: 500px)": {
        width: "25%",
      },
    },
    totalPrice: {
      width: "10%",
      borderBottom: "none",
      textAlign: "right",
      "@media (max-width: 900px)": {
        display: "none",
      },
    },
  };

  // Define header widths separately with responsive behavior
  const headerStyles = {
    image: {
      width: "80px",
      borderBottom: "none",
      fontWeight: "bold",
      "@media (max-width: 1000px)": {
        display: "none",
      },
    },
    style: {
      width: "12%",
      borderBottom: "none",
      fontWeight: "bold",
      "@media (max-width: 900px)": {
        width: "15%",
      },
      "@media (max-width: 750px)": {
        width: "23%",
      },
      "@media (max-width: 500px)": {
        width: "25%",
      },
    },
    color: {
      width: "16%",
      borderBottom: "none",
      fontWeight: "bold",
      "@media (max-width: 900px)": {
        width: "18%",
      },
      "@media (max-width: 750px)": {
        width: "35%",
      },
      "@media (max-width: 500px)": {
        width: "35%",
      },
    },
    size: {
      width: "10%",
      borderBottom: "none",
      fontWeight: "bold",
      "@media (max-width: 900px)": {
        width: "14%",
      },
      "@media (max-width: 750px)": {
        width: "15%",
      },
      "@media (max-width: 500px)": {
        width: "15%",
      },
    },
    brand: {
      width: "14%",
      borderBottom: "none",
      fontWeight: "bold",
      "@media (max-width: 900px)": {
        width: "15%",
      },
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
    warehouse: {
      width: "10%",
      borderBottom: "none",
      fontWeight: "bold",
      "@media (max-width: 900px)": {
        width: "20%",
      },
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
    unitPrice: {
      width: "10%",
      borderBottom: "none",
      fontWeight: "bold",
      textAlign: "right",
      "@media (max-width: 900px)": {
        width: "18%",
      },
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
    quantity: {
      width: "10%",
      borderBottom: "none",
      fontWeight: "bold",
      textAlign: "right",
      "@media (max-width: 900px)": {
        width: "18%",
      },
      "@media (max-width: 750px)": {
        width: "25%",
      },
      "@media (max-width: 500px)": {
        width: "25%",
      },
    },
    totalPrice: {
      width: "10%",
      borderBottom: "none",
      fontWeight: "bold",
      textAlign: "right",
      "@media (max-width: 900px)": {
        display: "none",
      },
    },
  };

  const groupedItems = [];
  let currentGroup = [];

  order.orderItems.forEach((item, index) => {
    if (
      index === 0 ||
      item.styleCode !== order.orderItems[index - 1].styleCode ||
      item.color !== order.orderItems[index - 1].color ||
      item.brand !== order.orderItems[index - 1].brand
    ) {
      if (currentGroup.length > 0) {
        groupedItems.push(currentGroup);
      }
      currentGroup = [item];
    } else {
      currentGroup.push(item);
    }
  });

  if (currentGroup.length > 0) {
    groupedItems.push(currentGroup);
  }

  const renderTableCells = (item) => (
    <>
      <TableCell sx={columnStyles.style}>
        <ProductPageLink styleCode={item.styleCode} dgiStyle={item.dgiStyle} searchInput={searchInput} />
      </TableCell>
      <TableCell sx={columnStyles.color}>
        {highlightText(item.color, searchInput)}
      </TableCell>
      <TableCell sx={columnStyles.size}>
        {highlightText(item.size, searchInput)}
      </TableCell>
      <TableCell sx={columnStyles.brand}>
        {highlightText(item.brand, searchInput)}
      </TableCell>
      <TableCell sx={columnStyles.warehouse}>
        {highlightText(item.warehouse, searchInput)}
      </TableCell>
      <TableCell sx={columnStyles.unitPrice}>
        {formatToCurrency(item.unitPrice)}
      </TableCell>
      <TableCell sx={columnStyles.quantity}>
        {highlightText(item.quantity, searchInput)}
      </TableCell>
      <TableCell sx={columnStyles.totalPrice}>
        {formatToCurrency(
          Number(item.quantity * formatToNumber(item.unitPrice)).toFixed(2)
        )}
      </TableCell>
    </>
  );

  return (
    <AccordionDetails
      sx={{
        boxShadow: "none",
        borderTop: "1px dashed #d2d6da",
        paddingTop: "16px",
      }}
    >
      <TableContainer component={Paper} style={{ width: "100%" }} elevation={0}>
        <Table size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "1px solid #d2d6da",
              }}
            >
              <TableCell sx={headerStyles.image}>
                
              </TableCell>
              <TableCell sx={headerStyles.style}>
                Style
              </TableCell>
              <TableCell sx={headerStyles.color}>
                Color
              </TableCell>
              <TableCell sx={headerStyles.size}>
                Size
              </TableCell>
              <TableCell sx={headerStyles.brand}>
                Brand
              </TableCell>
              <TableCell sx={headerStyles.warehouse}>
                Warehouse
              </TableCell>
              <TableCell sx={headerStyles.unitPrice}>
                Unit Price
              </TableCell>
              <TableCell sx={headerStyles.quantity}>
                Quantity
              </TableCell>
              <TableCell sx={headerStyles.totalPrice}>
                Total Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedItems.map((group, groupIndex) => (
              <TableRow
                key={groupIndex}
                sx={{
                  backgroundColor: groupIndex % 2 === 0 ? "white" : "#f7f7f7",
                  borderBottom: groupIndex === groupedItems.length - 1
                    ? "1px dashed #d2d6da"
                    : "none",
                }}
              >
                <TableCell sx={columnStyles.image}>
                  <ProductImage imageUrl={group[0].imageURL} vendor={vendor} />
                </TableCell>
                <TableCell
                  colSpan={8}
                  sx={{
                    padding: 0,
                    borderBottom: "none",
                  }}
                >
                  <Table size="small">
                    <TableBody>
                      {group.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                          {renderTableCells(item)}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDetails>
  );
};

export default OrderTableLineItems;
