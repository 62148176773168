import React, { useState, useContext, createContext, useRef } from "react";

import styles from "./styles.module.scss";
import ProductPrices from "./ProductPrices";
import ProductDetails from "./ProductDetails";
import ProductImage from "./ProductImage";
import InventoryDetails from "./inventory_info/InventoryDetails";
import { Skeleton, Paper, Slide, Box } from "@mui/material";

import vendors from "../constants/vendors";
import { suggestVendorConnections } from "../constants/vendors";
import { useDataContext } from "../App";
import CartErrorAlert from "./CartErrorAlert";
import getStyleNumber from "utilities/getStyleNumber";
import { LOADING_STATES } from "features/productsSlice";
import { calculateSavingsPotential } from "utilities/calculateSavingsPotential";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { checkIfSaleExists } from "utilities/parseInventoryData";
import { formatPrice } from "utilities/formatPrice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SimilarSlide from "./SimilarSlide";

const PricesContext = createContext();
const usePricesContext = () => useContext(PricesContext);
export { PricesContext, usePricesContext };

const RenderProductPrices = ({
  displayVendors,
  displaySuggestedVendors,
  hit,
  openInvDetails,
  setOpenInvDetails,
  setSelectedVendorKey,
  masterColor,
  setVendorProducts,
  cheapestAvailableVendor,
  showOnlySyncedVendors,
}) => {
  const { connectedVendorCodes } = useDataContext();
  const hasAtLeastOneConnection = Object.values(connectedVendorCodes).some(
    (status) => status,
  );

  if (!hasAtLeastOneConnection) {
    return (
      <Skeleton
        sx={{ bgcolor: "grey.300" }}
        variant="rectangular"
        width={360}
        height={198}
      />
    );
  }

  return (
    <ProductPrices
      displayVendors={displayVendors}
      displaySuggestedVendors={displaySuggestedVendors}
      hit={hit}
      openInvDetails={openInvDetails}
      setOpenInvDetails={setOpenInvDetails}
      setSelectedVendorKey={setSelectedVendorKey}
      masterColor={masterColor}
      setVendorProducts={setVendorProducts}
      cheapestAvailableVendor={cheapestAvailableVendor}
      showOnlySyncedVendors={showOnlySyncedVendors}
    />
  );
};

const Product = React.memo(function Product({ hit }) {
  const [selectedColor, setSelectedColor] = useState({ name: "" });
  const [openInvDetails, setOpenInvDetails] = useState(false);
  const [openSimilarSlide, setOpenSimilarSlide] = useState(false);
  const [showCartAlert, setShowCartAlert] = useState(false);
  const [cartAlertContent, setCartAlertContent] = useState();
  const [selectedVendorKey, setSelectedVendorKey] = useState("");
  const { connectedVendorCodes, setSearchSession } = useDataContext();
  const inventorySlideRef = useRef(null);
  const productCardRef = useRef(null);
  const similarSlideRef = useRef(null);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [vendorProducts, setVendorProducts] = useState({});
  const { sendJsonMessage } = useWebsocketContext();
  const navigate = useNavigate();
  const vendorCode = selectedVendorKey.split("_")[0];
  const selectedProductKey = hit.id + vendorCode + selectedColor.name;
  const vendorProduct = vendorProducts[selectedProductKey];
  const showOnlySyncedVendors = useSelector(
    (state) => state.user?.user?.show_only_synced_vendors,
  );
  const [similarProductData, setSimilarProductData] = useState([]);

  // New function to handle slider toggling
  const toggleSlider = (slider) => {
    if (slider === "inventory") {
      setOpenInvDetails((prev) => {
        if (!prev) setOpenSimilarSlide(false); // Close the similar slide if opening inventory
        return !prev;
      });
    } else if (slider === "similar") {
      setOpenSimilarSlide((prev) => {
        if (!prev) setOpenInvDetails(false); // Close the inventory slide if opening similar
        return !prev;
      });
    }
  };

  const sortVendorsByPrice = (vendorA, vendorB) => {
    const dataKeyA = vendorA[0];
    const dataKeyB = vendorB[0];
    const dataCodeA = dataKeyA?.split("_")[0];
    const dataCodeB = dataKeyB?.split("_")[0];
    let priceA, priceB;
    const keyA = hit.id + dataCodeA + selectedColor.name;
    const keyB = hit.id + dataCodeB + selectedColor.name;
    if (vendorProducts && vendorProducts[keyA]) {
      const vendorAData = vendorProducts[keyA];
      if (vendorAData.data) {
        priceA = vendorAData.data?.product_price;
      }
    }
    if (vendorProducts && vendorProducts[keyB]) {
      const vendorBData = vendorProducts[keyB];
      if (vendorBData.data) {
        priceB = vendorBData.data?.product_price;
      }
    }

    if (priceA === "Color N/A" || !priceA) {
      priceA = Infinity;
    }
    if (priceB === "Color N/A" || !priceB) {
      priceB = Infinity;
    }

    if (priceA < priceB) {
      return -1;
    }
    if (priceA > priceB) {
      return 1;
    }
    return 0;
  };

  const checkAllPricesRendered = (displayVendors) => {
    if (!displayVendors || displayVendors.length === 0) {
      return false;
    }
    if (!vendorProducts) {
      return false;
    }
    for (const vendor of displayVendors) {
      const vendorDataKey = vendor[0];
      const key = hit.id + vendorDataKey + selectedColor.name;
      const vendorData = vendorProducts[key];
      if (!vendorData) {
        return false;
      }

      if (vendorData?.loading === LOADING_STATES.LOADING_STATUS) {
        return false;
      }
    }

    return true;
  };

  const displayVendors = Object.entries(vendors)
    .filter((vendor) => {
      const [dataKey, vendorFields] = vendor;
      const styleField = vendorFields["style_field"];
      if (connectedVendorCodes[dataKey] && hit && hit[styleField]) {
        return true;
      } else {
        return false;
      }
    })
    .sort(sortVendorsByPrice);

  const displaySuggestedVendors = Object.entries(vendors).filter((vendor) => {
    const [dataKey, vendorFields] = vendor;
    if (!suggestVendorConnections.has(vendorFields["code"])) {
      return false;
    }
    const styleField = vendorFields["style_field"];
    if (!connectedVendorCodes[dataKey] && hit && hit[styleField]) {
      return true;
    } else {
      return false;
    }
  });
  const suggestedAndConnectedVendors = displayVendors.concat(
    displaySuggestedVendors,
  );
  const allRelevantVendors = showOnlySyncedVendors
    ? displayVendors
    : suggestedAndConnectedVendors;

  const vendorDataKeys = allRelevantVendors.map((vendorData) => {
    const vendorDataKey = vendorData[0];
    return vendorDataKey;
  });

  const smExclusive =
    hit?.distributors.length === 1 && hit?.distributors[0] === "Sanmar";
  const vendorDataKeysOrdered = checkAllPricesRendered(displayVendors);

  // in the case that the user didn't already select a tab,
  // we continually assign the cheapest vendor tab until all prices come in
  const firstVendorDataKey =
    vendorDataKeys.length > 0 ? vendorDataKeys[0] : null;
  const firstVendorCode = firstVendorDataKey?.split("_")[0];
  const firstKey = hit.id + firstVendorCode + selectedColor.name;
  const isVendorAvailable =
    vendorProducts &&
    vendorProducts[firstKey] &&
    vendorProducts[firstKey]?.data?.product_price;
  const cheapestAvailableVendor = isVendorAvailable
    ? { firstKey: firstVendorDataKey, areKeysOrdered: vendorDataKeysOrdered }
    : null;

  if (hit === undefined) {
    return;
  }

  const calculateSavingsFcn = (quantity, selectedVendorPrice) => {
    calculateSavingsPotential(quantity, selectedVendorPrice, vendorProducts);
  };

  const displayStyleNumber = getStyleNumber(hit);
  const pricesContext = {
    setSelectedColor,
    selectedColor,
  };

  const renderCartAlert = showCartAlert && (
    <CartErrorAlert alertContent={cartAlertContent} />
  );

  const vendorsBestPrices = {};
  for (const vendorDataKey of vendorDataKeys) {
    const vendorCode = vendorDataKey.split("_")[0];
    const vendorProductKey = hit.id + vendorCode + selectedColor?.name;
    const vendorProduct = vendorProducts[vendorProductKey];
    const vendorSizedPricingData =
      vendorProduct?.["data"]?.["sized_pricing_data"];
    const saleExists = checkIfSaleExists(vendorSizedPricingData);
    const formattedDollarAmount = formatPrice(
      vendorProduct?.["data"]?.["product_price"],
    );
    vendorsBestPrices[vendorDataKey] = {
      productPrice: formattedDollarAmount,
      saleExists,
    };
  }

  const navigateToProductPage = (e) => {
    e.stopPropagation();
    const dgiStyle = hit["id"];
    const encodedColor = encodeURIComponent(selectedColor.name);
    const productPath = `/product/${dgiStyle}/${encodedColor}`;
    navigate(productPath);
    setSearchSession((currentSearchSession) => ({
      ...currentSearchSession,
      prev_path: "shop",
    }));
  };

  const onSimilarClick = (event) => {
    event.stopPropagation();
    toggleSlider("similar");
  };

  return (
    <PricesContext.Provider value={pricesContext}>
      <div className={styles.productContainer} ref={productCardRef}>
        <div
          className={
            openInvDetails ? styles.expandedContainer : styles.previewContainer
          }
        >
          <div className="flex w-full flex-wrap md:flex-nowrap h-full">
            <div
              className={styles.productInformation}
              style={{ cursor: "pointer" }}
              onClick={navigateToProductPage}
            >
              <ProductImage dgiStyle={hit["id"]} smExclusive={smExclusive} />
              <ProductDetails
                displayStyleNumber={displayStyleNumber}
                hit={hit}
                sendJsonMessage={sendJsonMessage}
                onSimilarClick={onSimilarClick}
                selectedColor={selectedColor}
                openSimilarSlide={openSimilarSlide}
              />
            </div>
            <RenderProductPrices
              displayVendors={displayVendors}
              displaySuggestedVendors={displaySuggestedVendors}
              hit={hit}
              openInvDetails={openInvDetails}
              setOpenInvDetails={(open) => {
                toggleSlider("inventory");
                setOpenInvDetails(open);
              }}
              setSelectedVendorKey={setSelectedVendorKey}
              masterColor={selectedColor.name}
              setVendorProducts={setVendorProducts}
              cheapestAvailableVendor={cheapestAvailableVendor}
              showOnlySyncedVendors={showOnlySyncedVendors}
            />
          </div>
        </div>
        <div
          className={styles.slideContainer}
          data-open={openInvDetails}
          ref={inventorySlideRef}
        >
          <Slide
            in={openInvDetails}
            direction="down"
            timeout={200}
            className={styles.detailsSlide}
            container={inventorySlideRef.current}
            anchor="up"
          >
            <Paper
              sx={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                boxShadow: "none",
                height: "100%",
              }}
            >
              {renderCartAlert}
              <InventoryDetails
                hit={hit}
                openInvDetails={openInvDetails}
                vendorDataKeys={vendorDataKeys}
                productColors={hit.colors}
                selectedVendorKey={selectedVendorKey}
                setSelectedVendorKey={setSelectedVendorKey}
                showCartAlert={showCartAlert}
                setShowCartAlert={setShowCartAlert}
                setCartAlertContent={setCartAlertContent}
                selectedColor={selectedColor}
                applyPadding={true}
                loadingTableData={loadingTableData}
                setLoadingTableData={setLoadingTableData}
                cheapestAvailableVendor={cheapestAvailableVendor}
                vendorProduct={vendorProduct}
                calculateSavingsFcn={calculateSavingsFcn}
                vendorsBestPrices={vendorsBestPrices}
              />
            </Paper>
          </Slide>
        </div>
        <div
          className={styles.similarSlideContainer}
          data-open={openSimilarSlide}
          ref={similarSlideRef}
        >
          <Slide
            in={openSimilarSlide}
            direction="down"
            timeout={200}
            className={styles.detailsSlide}
            container={similarSlideRef.current}
            anchor="up"
          >
            <Paper
              sx={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                boxShadow: "none",
                height: "auto",
              }}
            >
              <SimilarSlide
                open={openSimilarSlide}
                containerRef={similarSlideRef}
                hit={hit}
                selectedColor={selectedColor}
              />
            </Paper>
          </Slide>
        </div>
      </div>
    </PricesContext.Provider>
  );
});

export default Product;
