import { useState } from "react";
import Container from "@mui/material/Container";
import ConfirmationContents from "./ConfirmationContents";
import NavButtons from "./NavButtons";

const ConfirmationPage = () => {
  const [canExecuteOrder, setCanExecuteOrder] = useState(false);

  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: 1000, width: "100%", margin: "0 auto", paddingBottom: 6 }}
    >
      <ConfirmationContents setCanExecuteOrder={setCanExecuteOrder} />
      <NavButtons canExecuteOrder={canExecuteOrder} />
    </Container>
  );
};

export default ConfirmationPage;
