import {
  SM_CUTOFF_TIMES,
  ACC_CUTOFF_TIMES,
  TIMEZONE_LOOKUP,
  getLocalCutoffTime,
  calculateDeliveryDate,
  ASC_CUTOFF_TIMES,
  STA_CUTOFF_TIMES,
  SLC_CUTOFF_TIMES
} from "utilities/parseInventoryData";
import { DateTime } from "luxon";
import { vendorsWithoutAttachedCutoffTimes } from "constants/vendors";

export function getCutoffData(closestWhse, vendorKey, givenCutoffTime = null) {
  try {
    let cutoffIndicator = "gray";
    let timeLeft = null;
    let whseCutoffTime = null;
    if (vendorKey === "sm_data") {
      whseCutoffTime = SM_CUTOFF_TIMES[closestWhse];
    } else if (vendorKey === "asc_data") {
      whseCutoffTime = ASC_CUTOFF_TIMES[closestWhse];
    } else if (vendorKey === "slc_data") {
      whseCutoffTime = SLC_CUTOFF_TIMES[closestWhse];
    } else {
      if (givenCutoffTime) {
        whseCutoffTime = givenCutoffTime;
      } else {
        // cannot do rest of analysis
        return {
          cutoffIndicator,
          timeLeft
        }
      }
    }

    // we hit this case if only drop shipping warehouses are available
    if (!whseCutoffTime) {
      return {
        cutoffIndicator,
        timeLeft
      }
    }

    const [cutoffTime, cutoffTimeZone] = whseCutoffTime?.split(" ");
    const [hours, minutes] = cutoffTime.split(":").map(Number);
    const timezoneDesc = TIMEZONE_LOOKUP[cutoffTimeZone];
    const cutoffDateTime = DateTime.now()
      .setZone(timezoneDesc)
      .set({ hour: hours + 12, minute: minutes });
    const cutoffDateTimeUTC = cutoffDateTime.toUTC();

    const timeNowUTC = DateTime.utc();
    const nearCutoffDateTime = cutoffDateTimeUTC.minus({ hour: 1 });

    const atOrAfterCutoff = timeNowUTC >= cutoffDateTimeUTC;
    const almostAtCutoff =
      timeNowUTC >= nearCutoffDateTime && timeNowUTC < cutoffDateTimeUTC;
    const beforeCutoff = timeNowUTC < nearCutoffDateTime;

    if (almostAtCutoff) {
      cutoffIndicator = "orange";
      timeLeft = cutoffDateTimeUTC.diff(timeNowUTC).as("minutes");
    } else if (atOrAfterCutoff) {
      cutoffIndicator = "red";
    } else if (beforeCutoff) {
      cutoffIndicator = "green";
      timeLeft = cutoffDateTimeUTC.diff(timeNowUTC).as("hours");
    } else {
      cutoffIndicator = "gray";
    }
    return {
      cutoffIndicator,
      timeLeft,
    };
  } catch (e) {
    console.error(`Failed to get cutoff data for ${vendorKey} and ${closestWhse}`)
  }
};

export function addVendorShippingInfo(productData, vendorCode, est_delivery_days) {
  if (vendorCode === "pg") {
    return addPGShippingInfo(est_delivery_days, productData)
  } else if (vendorCode === "cm") {
    return addCMDeliveryDates(productData, est_delivery_days);
  }
  return addDeliveryDates(productData, vendorCode, est_delivery_days);
}

export function addDeliveryDates(productData, vendorCode, est_delivery_days, cutoffTimeMap=null) {
  if (!est_delivery_days || !Object.keys(productData).length) {
    // vendor doesn't require us to add delivery dates to the inventory data obj
    return productData;
  }

  let newProductData = {...productData};
  let cutoffTimes = cutoffTimeMap;
  if (vendorCode === "sm") {
    cutoffTimes = SM_CUTOFF_TIMES;
  } else if (vendorCode === "acc") {
    cutoffTimes = ACC_CUTOFF_TIMES;
  } else if (vendorCode === "asc") {
    cutoffTimes = ASC_CUTOFF_TIMES;
  } else if (vendorCode === "sta") {
    cutoffTimes = STA_CUTOFF_TIMES;
  } else if (vendorCode === "slc") {
    cutoffTimes = SLC_CUTOFF_TIMES;
  }
  if (!cutoffTimes) {
    console.log(`Warning: no cutoff times for ${vendorCode}`)
    return productData;
  }
  try {
    const new_inventory_data = []
    for (const warehouse of productData?.inventory_data) {
      const [warehouseName, warehouseData] = warehouse;
      if (!warehouseName) {
        console.log(`Warning: warehouse name for ${vendorCode} does not exist`)
        continue;
      }
      let rawCutoffTime = cutoffTimes[warehouseName.trim()];
      if (!rawCutoffTime) {
        // We write this conditional for ACC dropshipping warehouses.
        // We choose to either display Shipping Today or Shipping Next Business Day,
        // because we don't get back the timezone for dropshipping warehouses.
        if (warehouseData["deliveryCutoffMsg"]) {
          rawCutoffTime = warehouseData["deliveryCutoffMsg"];
        }
      }
      const localCutoffTime = rawCutoffTime ? getLocalCutoffTime(rawCutoffTime) : "N/A";
      // if rawCutoffTime is undefined, check that SM_CUTOFF_TIMES is up-to-date
      if (!rawCutoffTime || localCutoffTime === "N/A") {
        new_inventory_data.push([
          warehouseName,
          {
            ...warehouseData,
            estDeliveryDate: "3-7 Days",
            deliveryCutoffTime: "N/A",
          }
        ])
        continue;
      }
      const estDate = calculateDeliveryDate(warehouseName.trim(), est_delivery_days, localCutoffTime);
      new_inventory_data.push([
        warehouseName,
        {
          ...warehouseData,
          estDeliveryDate: estDate,
          deliveryCutoffTime: localCutoffTime,
        }
      ])
    }
    if (vendorsWithoutAttachedCutoffTimes.has(vendorCode)) {
      newProductData.cutoff_whse = newProductData?.inventory_data[0][0];
      const vendorDataKey = vendorCode + "_data";
      const { cutoffIndicator, timeLeft } = getCutoffData(
        newProductData.cutoff_whse.trim(),
        vendorDataKey
      );
      newProductData.cutoff_indicator = cutoffIndicator;
      newProductData.remaining_order_time = timeLeft;
    }
    newProductData.inventory_data = new_inventory_data;
    
    return newProductData;
  } catch (error) {
    console.error(`Failed to calculate delivery dates for ${vendorCode} with error: ${error.message}`);
    return productData;
  }
};

export function addPGShippingInfo(pgEstDeliveryDays, respData) {
  try {
    if (!pgEstDeliveryDays || Object.keys(respData).length === 0) {
      return respData;
    }
    let newProductData = {...respData};

    // need to sort warehouses based off distance 
    // and get corresponding cutoff times (for cutoff time indicator and inventory table)
    let cutoffWarehouse = null;
    const sortedWarehouses = [...Object.entries(pgEstDeliveryDays)].sort((a, b) => a[1] - b[1]);
    const whseCutoffTimeMap = newProductData?.inventory_data?.reduce((accumulator, whse) => {
      const warehouseName = whse[0];
      if (whse[1]["deliveryCutoffTime"].includes("Cutoff")) {
        const cutoffTimeDetails = whse[1]["deliveryCutoffTime"].split(" "); // e.g. Cutoff 3:00 PM PT
        const warehouseCutoffTime = cutoffTimeDetails[1] + " " + cutoffTimeDetails[3]; // to get e.g. 3:00 PT
        accumulator[warehouseName] = warehouseCutoffTime;
        return accumulator;
      }
      // cases for Ships Next Day
      accumulator[warehouseName] = whse[1]["deliveryCutoffTime"];
      return accumulator;
    }, {});

    // getting data to display cutoff time indicator and tooltip
    cutoffWarehouse = sortedWarehouses.length > 0 ? sortedWarehouses[0] : null;
    if (cutoffWarehouse) {
      const targetWhseCutoffEntry = Object.entries(whseCutoffTimeMap).find((whseEntry) => whseEntry[0] === cutoffWarehouse[0]);
      const givenCutoffTime = targetWhseCutoffEntry ? targetWhseCutoffEntry[1] : null;
      const { cutoffIndicator, timeLeft } = getCutoffData(
        cutoffWarehouse[0],
        "pg_data",
        givenCutoffTime
      );
      newProductData.cutoff_whse = cutoffWarehouse[0];
      newProductData.cutoff_indicator = cutoffIndicator;
      newProductData.remaining_order_time = timeLeft;
    } else {
      newProductData.cutoff_indicator = "gray";
    }

    // add estimated delivery dates to render in inventory table
    newProductData = addDeliveryDates(
      newProductData,
      "pg",
      pgEstDeliveryDays,
      whseCutoffTimeMap
    )

    return newProductData;
  } catch (error) {
    console.error(`Error adding pg shipping info: ${error.message}`)
  }
}

export function addCMDeliveryDates(productData, cmEstDeliveryDays) {
  let newProductData = {...productData};
  const new_inventory_data = [];
  if (!productData?.inventory_data) {
    return newProductData;
  }
  for (const warehouse of productData?.inventory_data) {
    const [warehouseName, warehouseData] = warehouse;

    if (!warehouseName) {
      continue;
    }
    if (!warehouseName.includes("Indian Trail")) {
      new_inventory_data.push([
        warehouseName,
        {
          ...warehouseData,
          estDeliveryDate: "3-7 days",
          deliveryCutoffTime: warehouseData.deliveryCutoffMsg
        }
      ])
      continue
    }

    const rawCutoffTime = warehouseData.deliveryCutoffTime
    const localCutoffTime = getLocalCutoffTime(rawCutoffTime.trim());
    new_inventory_data.push([
      warehouseName,
      {
        ...warehouseData,
        estDeliveryDate: calculateDeliveryDate(warehouseName.replace("NC-Main Warehouse", " NC"), cmEstDeliveryDays, localCutoffTime),
        deliveryCutoffTime: localCutoffTime,
      }
    ])
  }
  newProductData.inventory_data = new_inventory_data;
  return newProductData;
};
