import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CheckoutItem from "./CheckoutItem";
import CartSummary from "./CartSummary";
import { LinearProgress, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Skeleton from "@mui/material/Skeleton";
import ShippingMinPopUp from "./ShippingMinPopUp";
import vendors from "constants/vendors";
import { useSelector } from "react-redux";
import { LOADING_STATES, selectCart } from "features/cartsSlice";
import {
  AB_WAREHOUSE_MAP,
  AS_WAREHOUSE_MAP,
  CM_WAREHOUSE_MAP,
  PG_WAREHOUSE_MAP,
  SM_WAREHOUSE_MAP,
  SS_WAREHOUSE_MAP,
  ACC_WAREHOUSE_MAP,
  ASC_WAREHOUSE_MAP,
  STA_WAREHOUSE_MAP,
  SLC_WAREHOUSE_MAP,
} from "utilities/parseInventoryData";

// TODO why is this necessary?
const VENDOR_WHSES = {
  ab: AB_WAREHOUSE_MAP,
  cm: CM_WAREHOUSE_MAP,
  sm: SM_WAREHOUSE_MAP,
  ss: SS_WAREHOUSE_MAP,
  as: AS_WAREHOUSE_MAP,
  pg: PG_WAREHOUSE_MAP,
  acc: ACC_WAREHOUSE_MAP,
  sta: STA_WAREHOUSE_MAP,
  slc: SLC_WAREHOUSE_MAP,
};

export default function VendorCart({ vendorCode, vendorName }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const vendorCartData = useSelector((state) => selectCart(state, vendorCode));
  const dataKey = String(vendorCode) + "_data";

  if (
    !vendorCartData ||
    vendorCartData?.loading === LOADING_STATES.LOADING_STATUS ||
    vendorCartData?.loading === LOADING_STATES.IDLE_STATUS
  ) {
    return <Skeleton variant="rectangular" height={48}></Skeleton>;
  }

  const createItemGroupings = (items) => {
    const newItemGroupings = {};
    for (const item of items) {
      const style = item.style;
      const colorName = item.colorDesc || item?.master_color;
      // arbitrary key format but sufficient
      // we assume that colors don't have questions marks
      const groupingKey = style + "_" + colorName.replace(" ", "?");

      if (newItemGroupings[groupingKey]) {
        const groupItems = [...newItemGroupings[groupingKey]];
        groupItems.push(item);
        newItemGroupings[groupingKey] = groupItems;
      } else {
        newItemGroupings[groupingKey] = new Array(item);
      }
    }
    return Object.values(newItemGroupings);
  };

  // Display Value
  const itemGroups = vendorCartData?.items
    ? createItemGroupings(vendorCartData?.items)
    : [];

  let discountDSTotalPrice = 0.0;
  if (vendorCartData && vendorCartData["items"]) {
    for (const item of vendorCartData["items"]) {
      const itemWhse = item["warehouse"];
      const nonDSWhses = VENDOR_WHSES[vendorCode] || {};
      const isStandardWhse = Object.values(nonDSWhses).includes(itemWhse);
      if (isStandardWhse) {
        const itemTotal = item["totalPrice"];
        discountDSTotalPrice += itemTotal;
      }
    }
  }

  const cartTotalPrice = vendorCartData?.totalPrice ?? 0;
  // Display Value
  const subTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cartTotalPrice);
  // Display Value
  const minShipping = vendors[dataKey]?.freeShippingMin;

  const formattedDiscountDSTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(discountDSTotalPrice);

  const newSubTotal = parseFloat(
    formattedDiscountDSTotal.replace(/[^0-9.-]+/g, "")
  );

  const progress = (newSubTotal / minShipping) * 100; // Using numerical value for calculation
  // Display Value
  let freeShippingProgress = Math.min(progress, 100);
  // we want to refrain from making users think that a progress of 99% is 100%
  if (freeShippingProgress >= 95 && freeShippingProgress < 100) {
    freeShippingProgress = 95;
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  // Retrieve vendor logo from mapping
  const vendorLogo = vendors[dataKey]?.logo;
  const startExpanded = cartTotalPrice > 0;

  if (vendorCartData?.loading === true) {
    return <Skeleton variant="rectangular" height={48}></Skeleton>;
  }

  return (
    <Accordion
      key={`${vendorCode}-accordion`}
      id={`${vendorCode}-accordion`}
      elevation={0}
      defaultExpanded={startExpanded}
      classes={{ root: styles.vendorCartWrapper }}
      sx={{
        "&:hover": {
          boxShadow:
            "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
        },
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className="text-charcoal" />}
        aria-controls={`${vendorCode}-content`}
        id={`${vendorCode}-header`}
        disableGutters={true}
        classes={{
          expanded: styles.vendorCartHeader,
          content: styles.vendorCartHeader,
          root: styles.vendorCartHeader,
        }}
        sx={{
          borderRight: "1px solid #e0e3e9",
          borderTop: "1px solid #e0e3e9",
          borderBottom: "1px solid #e0e3e9",
          position: "sticky",
          top: "0",
          zIndex: "5",
        }}
        className="bg-dustyBlue"
      >
        <div className={`bg-dustyBlue ${styles.vendorCartName}`} data>
          {vendorLogo ? (
            <img
              className={styles.vendorLogoImage}
              data-downsize={vendorCode === "slc"}
              src={vendorLogo}
              alt={vendorName}
            />
          ) : (
            <span>{vendorName}</span>
          )}
        </div>
        <div className={styles.freeShippingStatus}>
          <span className={styles.price}>$0</span>
          <Tooltip
            title={
              <React.Fragment>
                {minShipping - discountDSTotalPrice <= 0 ? (
                  <>
                    <Typography className="text-base">
                      You've reached your free shipping minimum for {vendorName}
                      !
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "0.6em" }}>
                      Some exclusions may apply. Proceed to checkout or view on
                      vendor site for exact shipping estimates.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>
                      Spend{" "}
                      <strong>{` $${(
                        minShipping - discountDSTotalPrice
                      ).toFixed(2)} `}</strong>
                      more to get FREE shipping with {vendorName}.
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "0.6em" }}>
                      Some exclusions may apply. Proceed to checkout or view on
                      vendor site for exact shipping estimates.
                    </Typography>
                  </>
                )}
              </React.Fragment>
            }
            placement="top"
            classes={{ tooltip: styles.customTooltip }}
          >
            <LinearProgress
              variant="determinate"
              value={freeShippingProgress}
              sx={{
                height: "16px",
                width: "300px",
                borderRadius: "8px",
                backgroundColor: "#CCCCCC",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "rgba(0, 110, 165, 1)",
                },
              }}
            />
          </Tooltip>
          <span className={styles.price}>{`$${minShipping}`}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={styles.vendorCartData}
        sx={{
          borderRight: "1px solid #e0e3e9",
          borderBottom: "1px solid #e0e3e9",
          paddingX: "0",
        }}
      >
        <div className={styles.checkoutItems}>
          {itemGroups.map((checkoutItemEntries, index) => (
            <CheckoutItem
              key={index}
              vendorCode={vendorCode}
              checkoutItemEntries={checkoutItemEntries}
            />
          ))}
        </div>
        <CartSummary
          subTotal={subTotal}
          vendorName={vendorName}
          vendorCartData={vendorCartData}
        />
      </AccordionDetails>
      <ShippingMinPopUp open={isDialogOpen} onClose={handleCloseDialog} />
    </Accordion>
  );
}
