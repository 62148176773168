import React, { useMemo } from "react";

import styles from "./styles.module.scss";
import ProductPriceRow from "./ProductPriceRow.js";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import PaperBack from "./PaperBack.js";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useSelector } from "react-redux";
import RemoveIcon from '@mui/icons-material/Remove';

function ProductRows({
  vendorsArray,
  setOpenInvDetails,
  setSelectedVendorKey,
  masterColor,
  setVendorProducts,
  getProductStyle,
  id,
  areConnectionRows = false,
}) {
  const hidePrices = useSelector((state) => state.user?.user?.hide_prices);
  return (
    <>
      {vendorsArray.map((vendorEntry, index) => (
        <ProductPriceRow
          key={`connectionrow-${id}-${index}`}
          dgi_style_sku={id}
          dataKey={vendorEntry[0]}
          vendorCode={vendorEntry[0].split("_")[0]}
          vendorName={vendorEntry[1].name}
          setOpenInvDetails={setOpenInvDetails}
          setSelectedVendorKey={setSelectedVendorKey}
          masterColor={masterColor}
          setVendorProducts={setVendorProducts}
          productStyle={getProductStyle(vendorEntry[0].split("_")[0])}
          isConnectionRow={areConnectionRows}
          hidePrices={hidePrices}
        />
      ))}
    </>
  );
}

const RenderProductRows = ({
  displayVendors,
  displaySuggestedVendors,
  isPricingAvailable,
  setOpenInvDetails,
  setSelectedVendorKey,
  masterColor,
  setVendorProducts,
  getProductStyle,
  id,
  showOnlySyncedVendors,
}) => {
  const priceRows = (
    <ProductRows
      vendorsArray={displayVendors}
      setOpenInvDetails={setOpenInvDetails}
      setSelectedVendorKey={setSelectedVendorKey}
      masterColor={masterColor}
      setVendorProducts={setVendorProducts}
      getProductStyle={getProductStyle}
      id={id}
    />
  );

  const connectionRows = (
    <ProductRows
      vendorsArray={displaySuggestedVendors}
      setOpenInvDetails={setOpenInvDetails}
      setSelectedVendorKey={setSelectedVendorKey}
      masterColor={masterColor}
      setVendorProducts={setVendorProducts}
      getProductStyle={getProductStyle}
      id={id}
      areConnectionRows={true}
    />
  );

  if (showOnlySyncedVendors) {
    return <>{priceRows}</>;
  }

  return (
    <>
      {isPricingAvailable ? (
        <>
          {priceRows}
          {connectionRows}
        </>
      ) : (
        <>
          {connectionRows}
          {priceRows}
        </>
      )}
    </>
  );
};

const ProductPrices = ({
  displayVendors,
  displaySuggestedVendors,
  hit,
  setOpenInvDetails,
  setSelectedVendorKey,
  openInvDetails,
  masterColor,
  setVendorProducts,
  cheapestAvailableVendor,
  showOnlySyncedVendors,
}) => {
  const {
    id,
    ab_style,
    cm_style,
    acc_style,
    sm_style,
    ss_style,
    pg_style,
    as_style,
    asc_style,
    sta_style,
    slc_style,
  } = hit;

  const getProductStyle = (vendorCode) => {
    switch (vendorCode) {
      case "ab":
        return ab_style;
      case "cm":
        return cm_style;
      case "acc":
        return acc_style;
      case "sm":
        return sm_style;
      case "ss":
        return ss_style;
      case "pg":
        return pg_style;
      case "as":
        return as_style;
      case "asc":
        return asc_style;
      case "sta":
        return sta_style;
      case "slc":
        return slc_style;
    }
  };

  return (
    <div className={styles.productPrices}>
      <TableContainer
        className={styles.pricesTableContainer}
        sx={{
          flexGrow: 1,
          width: "360px",
          "@media (max-width: 768px)": {
            width: "100%",
          },
        }}
        component={PaperBack}
      >
        <Table
          sx={{ tableLayout: "fixed", width: "100%" }}
          aria-label="simple table"
        >
          <TableBody
            id={`price-table-body-${id}`}
            className={styles.pricesTableBody}
          >
            <RenderProductRows
              displayVendors={displayVendors}
              displaySuggestedVendors={displaySuggestedVendors}
              isPricingAvailable={Boolean(cheapestAvailableVendor)}
              setOpenInvDetails={setOpenInvDetails}
              setSelectedVendorKey={setSelectedVendorKey}
              masterColor={masterColor}
              setVendorProducts={setVendorProducts}
              getProductStyle={getProductStyle}
              id={id}
              showOnlySyncedVendors={showOnlySyncedVendors}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          height: "40px",
          flexShrink: 0,
          width: "100%",
          borderTop: "1px dashed #d2d6da",
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          flexShrink: 0,
          "&:hover": {
            backgroundColor: "#f4f4f4",
          },
          "@media (max-width:768px)": {
            display: "none",
          },
        }}
        onClick={() => setOpenInvDetails(!openInvDetails)}
      >
        <Button
          disableFocusRipple
          disableRipple
          sx={{
            textTransform: "none",
            padding: "1px 8px 1px 8px",
            backgroundColor: "transparent",
          }}
          className="text-blueblue text-sm hover:bg-transparent"
        >
          {openInvDetails ? (
            <RemoveIcon
              sx={{ color: "inherit", marginRight: 1, fontSize: "1.25rem" }}
            />
          ) : (
            <AddShoppingCartIcon
              sx={{ color: "inherit", marginRight: 1, fontSize: "1.25rem" }}
            />
          )}
          {openInvDetails ? "Close inventory" : "Shop"}
        </Button>
      </Box>
    </div>
  );
};

export default ProductPrices;
