import React from "react";
import styles from "./styles.module.scss";
import Footer from "../pages/landing_page/Footer";

const TermsConditions = () => {

  return (
    <>
      <div className={styles.terms}>
        <div className={styles.contentWrapper}>
          <h1>Terms of Service</h1>
          <p>Last updated on August 4th, 2023</p>
          <h1>Overview</h1>
          <p>
            Welcome to DGI Apparel. We strive to provide practical tools and
            technology to the custom apparel community. This Statement of Terms
            of Use ("Statement," "Terms, or “TOS”) governs our relationship with
            custom apparel businesses and others who interact with DGI Apparel,
            as well as DGI Apparel brands, products and services, which we
            collectively call the “DGI Apparel Services” or “Services”. By using
            or accessing the DGI Apparel Services, you agree to these
            conditions, as updated from time to time in accordance with our
            update policies in this document. Please read them carefully.
          </p>
          <h1>I. Privacy</h1>
          <p>
            <span>Your privacy is very important to us. We designed our </span>
            <a href="/privacy" rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            to make important disclosures about how we collect and use your
            content and information. We encourage you to read it to help you
            make informed decisions about using DGI Apparel.
          </p>
          <h1>II. Electronic Communications</h1>
          <p>
            When you use our Services, or send e-mails to us, you are
            communicating with us electronically. You consent to receive
            communications from us electronically. We will communicate with you
            by e-mail or by posting notices on this site or through the other
            DGI Apparel Services. You agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing.
          </p>
          <h1>III. Copyright</h1>
          <p>
            The DGI Apparel Services, including the DGI Apparel website, are
            owned and operated by DGI Apparel. All content included in or made
            available through any DGI Apparel Services, such as text, visual
            interfaces, graphics, design, logos, icons, images, digital
            downloads, computer code (including source code or object code),
            data compilations, software, and all other elements of the Services
            is the exclusive property of DGI Apparel and protected by United
            States and international copyright laws. Some content displayed by
            DGI Apparel, including company names and logos of manufacturers and
            vendors, as well as product names, descriptions, documentation, and
            images, may be owned by their originators rather than DGI Apparel.
            As an authorized agent of our users, DGI Apparel accesses and uses
            these content items for the sole purpose of providing the Services
            to our users, but claims no copyright to such materials owned and
            copyrighted by their originators. Companies that upload or enter
            content into DGI Apparel give DGI Apparel a permanent and
            unrestricted license to such content for all as long as the DGI
            Apparel Services remain operational.
          </p>
          <h1>IV. Trademarks</h1>
          <p>
            The graphics, logos, page headers, button icons, scripts, and
            service names included in or made available through any DGI Apparel
            Service are trademarks or trade dress of DGI Apparel in the United
            States and other countries. DGI Apparel’s trademarks and trade dress
            may not be used in connection with any product or service that is
            not DGI Apparel's, in any manner that is likely to cause confusion
            among customers, or in any manner that disparages or discredits DGI
            Apparel. All other trademarks not owned by DGI Apparel that appear
            in any DGI Apparel Service are the property of their respective
            owners, who may or may not be affiliated with, connected to, or
            sponsored by DGI Apparel.
          </p>
          <h1>V. Your Account and Authorized Use</h1>
          <p>
            Only custom apparel tradespeople with an active business in the
            United States, or explicitly authorized representatives of such
            person(s), including but not limited to technicians, office
            managers, and inventory managers, may access the DGI Apparel
            Services. You are required to enter information about yourself and
            your custom apparel business during the signup process, which must
            be truthful and verifiable to the best of your knowledge. Some
            products on DGI Apparel may be hazardous. Any accounts whose
            information cannot be verified or are found to be unauthorized to
            access the Services will be terminated immediately.
          </p>
          <p>
            If you use any DGI Apparel Service, you are responsible for
            maintaining the confidentiality of your account and password and for
            restricting access to your computer, and you agree to accept
            responsibility for all activities that occur under your account or
            password.
          </p>
          <h1>VI. Reviews, Comments, Communications, and Other Content</h1>
          <p>
            DGI Apparel users may post product reviews, discussion threads,
            comments, images, and other content; and submit suggestions, ideas,
            comments, questions, or other information, so long as the content is
            not illegal, obscene, threatening, defamatory, invasive of privacy,
            infringing of intellectual property rights, or otherwise injurious
            to third parties or objectionable and does not consist of or contain
            software viruses, political campaigning, commercial solicitation,
            chain letters, mass mailings, or any form of "spam." You may not use
            a false e-mail address, impersonate any person or entity, or
            otherwise mislead as to the origin of content. DGI Apparel reserves
            the right (but not the obligation) to remove or edit such content.
          </p>
          <p>
            If you do post content or submit material, and unless we indicate
            otherwise, you grant DGI Apparel a nonexclusive, royalty-free,
            perpetual, irrevocable, and fully sublicensable right to use,
            reproduce, modify, adapt, publish, translate, create derivative
            works from, distribute, and display such content throughout the
            world in any media. You grant DGI Apparel and sublicensees the right
            to use the name that you submit in connection with such content, if
            they choose. You represent and warrant that you own or otherwise
            control all of the rights to the content that you post; that the
            content is accurate; that use of the content you supply does not
            violate this policy and will not cause injury to any person or
            entity; and that you will indemnify DGI Apparel for all claims
            resulting from content you supply. DGI Apparel has the right, but
            not the obligation, to monitor,edit, or remove any activity or
            content. DGI Apparel takes no responsibility and assumes no
            liability for any content posted by you or any third party.
          </p>
          <h1>VII. Copyright Complaints</h1>
          <p>
            DGI Apparel respects the intellectual property of others. If you
            believe that your work has been copied in a way that constitutes
            copyright infringement, please follow our Notice and Procedure for
            Making Claims of Copyright Infringement below.
          </p>
          <h1>VIII. Risk of Loss, Returns, Refunds and Title</h1>
          <p>
            DGI Apparel is not a seller or shipper of any products. Thus, all
            items viewed or purchased through DGI Apparel are sold under the
            shipment terms of the vendor or manufacturer from whom you are
            purchasing. Pursuant to most vendor terms, the risk of loss and
            title for such items may pass to you upon delivery to the carrier,
            and conversely, title may not be taken for such items until they
            reach the vendor’s fulfillment center. Please refer to the
            purchasing and account terms for each vendor for complete
            information and policies related to your purchases.
          </p>
          <h1>IX. Product Descriptions</h1>
          <p>
            Information about products, including names, descriptions, images,
            documentation, and other information is gathered from various
            sources, including but not limited to websites and documentation of
            vendors and product manufacturers. DGI Apparel may attempt to curate
            such information, to the best of our ability, in an attempt to be as
            accurate as possible. However, DGI Apparel does not warrant that
            product descriptions or other content of any DGI Apparel Service is
            accurate, complete, reliable, current, or error-free. If you are
            unsure about the accuracy of a product’s information, we suggest
            cross-referencing the product information with authorized sellers of
            the product, or directly with the product’s manufacturer, to ensure
            accuracy. If a product discovered on or ordered through DGI Apparel
            is not as described, your sole remedy is to return the product to
            the seller whence it came, pursuant to the terms of that seller’s
            return policy. DGI Apparel assumes no responsibility for products
            ordered using information obtained on DGI Apparel, and will not
            interact with sellers on your behalf to initiate or negotiate a
            return of any product.
          </p>
          <h1>X. Account Information From Third-Party Sites</h1>
          <p>
            When you use the DGI Apparel Services, you may direct DGI Apparel to
            retrieve your own information maintained online by third-parties
            with which you have customer relationships, maintain accounts, or
            engage in purchase transactions to supply your custom apparel
            organization (“Account Information”). DGI Apparel does not review
            the Account Information for accuracy, legality or non-infringement.
            DGI Apparel is not responsible for the Account Information or
            products and services offered by or on third-party sites.
          </p>
          <p>
            DGI Apparel cannot always foresee or anticipate technical or other
            difficulties which may result in failure to obtain data or loss of
            data, personalization settings or other service interruptions. DGI
            Apparel cannot assume responsibility for the timeliness, accuracy,
            deletion, non-delivery or failure to store any user data,
            communications or personalization settings.
          </p>
          <h1>XI. Rights You Grant to Us as Your Authorized Agent</h1>
          <p>
            By submitting information, data, passwords, usernames, other login
            information, materials and other content to DGI Apparel through the
            Services, you are licensing that content to DGI Apparel for the
            purpose of providing the Services. DGI Apparel may use and store the
            content in accordance with this Agreement and our Privacy Policy.
            You represent that you are entitled to submit it to DGI Apparel for
            use for this purpose, without any obligation by DGI Apparel to pay
            any fees or be subject to any restrictions or limitations.
          </p>
          <p>
            When you sign up for a DGI Apparel account, you designate DGI
            Apparel as your authorized agent with permission to use your login
            information to third-party services, such as your vendors, and
            represent that you are authorized to do so by your custom apparel
            business or company. DGI Apparel’s systems utilize this login
            information to access these third-party services’ websites on your
            behalf to obtain and analyze pricing, availability, order history,
            shopping cart, and other information.
          </p>
          <p>
            By using the Services, you expressly authorize DGI Apparel to access
            your Account Information maintained by identified third parties, on
            your behalf as your agent, and you expressly authorize such third
            parties to disclose your information to us. When you use the DGI
            Apparel Services, you will be directly connected to the website for
            the third party you have identified. DGI Apparel will submit
            information including usernames and passwords that you provide to
            log into the third party site. You hereby authorize and permit DGI
            Apparel to use and store information submitted by you to accomplish
            the foregoing and to configure the Services so that it is compatible
            with the third party sites for which you submit your information.
          </p>
          <p>
            Actions you expressly authorize DGI Apparel to take on your behalf
            for all vendors whose information you’ve submitted to DGI Apparel
            include, but are not limited to, the following:
          </p>
          <ul>
            <p>
              Product Retrieval - DGI Apparel will locate a given product on a
              vendor’s website and view that product’s page or listing in order
              to serve you appropriate content.
            </p>
            <p>
              Availability Retrieval - DGI Apparel will obtain availability
              information, including stock or backorder status, quantities in
              stock, warehouse information, drop-ship information, and other
              availability information when available.
            </p>
            <p>
              Pricing Retrieval - DGI Apparel will obtain the pricing
              information for a product specific to your own business, including
              quantity discounts, promotions, and special instructions related
              to that product’s purchasing or shipment.
            </p>
            <p>
              Cart Modification - DGI Apparel will manage your cart on the
              vendor’s website by adding or removing products to/from your cart,
              or changing quantities in your cart.
            </p>
            <p>
              Order History Retrieval - DGI Apparel will retrieve and store
              information on past orders from your vendors to provide our order
              history and analytics features.
            </p>
            <p>
              Order Placement - DGI Apparel will place orders on your behalf
              with select third-party vendor websites.
            </p>
            <p>
              Invoice Retrieval - DGI Apparel will retrieve and store past
              invoices from your vendors to provide invoice features, analytics,
              and financial systems integrations.
            </p>
          </ul>
          <p>
            DGI Apparel takes the above actions only after you have signed up
            for a DGI Apparel account as an authorized representative of your
            business or company. DGI Apparel will not, except for purposes of
            providing the Services to you, take actions on your vendors’
            websites without explicit permission.
          </p>
          <p>
            For purposes of this Agreement and solely to provide the Account
            Information to you as part of the Services, you grant DGI Apparel a
            limited power of attorney, and appoint DGI Apparel as your
            attorney-in-fact and agent, to access third party sites, retrieve
            and use your information with the full power and authority to do and
            perform each thing necessary in connection with such activities, as
            you could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN DGI
            APPAREL IS ACCESSING AND RETRIEVING ACCOUNT INFORMATION FROM THIRD
            PARTY SITES, DGI APPAREL IS ACTING AS YOUR AGENT, AND NOT AS THE
            AGENT OF OR ON BEHALF OF THE THIRD PARTY THAT OPERATES THE THIRD
            PARTY SITE. You understand and agree that the Services are not
            sponsored or endorsed by any third parties accessible through the
            Services. DGI Apparel is not responsible for any payment processing
            errors or fees or other Services-related issues, including those
            issues that may arise from inaccurate account information.
          </p>
          <h1>XII. Pricing and Availability</h1>
          <p>
            DGI Apparel is not a seller of any products. Any prices viewable to
            you on DGI Apparel are the prices offered to you by the seller of
            that product. These prices are retrieved, on your behalf, in real
            time, and represent the pricing specifically for your business just
            as you would see on that seller’s eCommerce website.
          </p>
          <p>
            DGI Apparel makes no warranties to the accuracy of pricing or
            availability information. Despite our best efforts, a small number
            of the items in our catalog may be mispriced, or stock may be
            mislabeled, due to technical error of either the seller or DGI
            Apparel. If the error resides with the seller, please contact the
            seller and/or refer to the seller’s policies for remedies. If the
            price of the item on DGI Apparel does not match the price of the
            item on the seller’s eCommerce website, please contact us at
            ian@dgiapparel.com or +1 (919) 495-7693 so that we may look into it.
          </p>
          <p>
            Pricing and availability information at any given vendor may be
            unavailable, from time to time, due to an error or downtime on a
            vendor’s website, or changes to a vendor’s website that makes DGI
            Apparel’s interfacing with their website temporarily unavailable.
            DGI Apparel makes no warranties of uptime or that any connected
            vendor will have pricing and availability retrievable at any given
            time.
          </p>
          <h1>XIII. DGI Apparel Software Terms and Additional Terms</h1>
          <p>
            In addition to these Terms of Use, the Software Terms found at the
            end of this document apply to any software (including any updates or
            upgrades to the software and any related documentation) that we make
            available to you from time to time for your use in connection with
            DGI Apparel Services (the "DGI Apparel Software"). The additional
            terms at the end of this document also apply to your use of the DGI
            Apparel Services and constitute a part of the Terms of Use.
          </p>
          <h1>XIV. Other Businesses</h1>
          <p>
            Parties other than DGI Apparel may add or modify content, provide
            services, sell, or advertise product lines on this site. In
            addition, we provide links to the sites of affiliated companies and
            certain other businesses. We are not responsible for examining or
            evaluating, and we do not warrant the offerings of, any of these
            businesses or organizations or the content of their websites. DGI
            Apparel does not assume any responsibility or liability for the
            actions, product, and content of all these and any other third
            parties. You should carefully review their privacy statements and
            other conditions of use.
          </p>
          <h1>XV. Indemnity</h1>
          <p>
            To the fullest extent permitted by law, you are responsible for your
            use of the Service, and you agree to defend, indemnify and hold
            harmless DGI Apparel and its officers, directors, employees,
            consultants, affiliates, subsidiaries and agents (collectively, the
            "DGI Apparel Entities") from and against any and all claims,
            liabilities, damages, losses and expenses, including reasonable
            attorneys' and accounting fees and costs, arising out of or in any
            way connected with (i) your access to, use of or alleged use of the
            Service; (ii) your violation of the Terms or any representation,
            warranty, or agreements referenced herein, or any applicable law or
            regulation; (iii) your violation of any third party right, including
            without limitation any intellectual property right, publicity,
            confidentiality, property or privacy right; or (iv) any disputes or
            issues between you and any third party. We reserve the right, at our
            own expense, to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you, and in such
            case, you agree to cooperate with our defense of such claim.
          </p>
          <h1>XVI. Disclaimer of Warranties and Limitation of Liability</h1>
          <p>
            THE DGI APPAREL SERVICES AND ALL INFORMATION, CONTENT, MATERIALS,
            PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR
            OTHERWISE MADE AVAILABLE TO YOU THROUGH THE DGI APPAREL SERVICES ARE
            PROVIDED BY DGI APPAREL ON AN "AS IS" AND "AS AVAILABLE" BASIS,
            UNLESS OTHERWISE SPECIFIED IN WRITING. DGI APPAREL MAKES NO
            REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
            THE OPERATION OF THE DGI APPAREL SERVICES, OR THE INFORMATION,
            CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE DGI
            APPAREL SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THE DGI APPAREL SERVICES IS AT YOUR
            SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, DGI
            APPAREL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
            FOR A PARTICULAR PURPOSE. DGI APPAREL DOES NOT WARRANT THAT THE DGI
            APPAREL SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS
            (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THE DGI APPAREL SERVICES, DGI APPAREL’S
            SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM DGI APPAREL ARE FREE
            OF VIRUSES OR OTHER HARMFUL COMPONENTS. DGI APPAREL WILL NOT BE
            LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY DGI
            APPAREL SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
            PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR
            OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY DGI APPAREL SERVICE,
            INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
            AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
          </p>
          <p>
            OUR AGGREGATE LIABILITY ARISING OUT OF THIS STATEMENT OR DGI APPAREL
            WILL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE
            AMOUNT YOU HAVE PAID US IN THE PAST TWELVE MONTHS. APPLICABLE LAW
            MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL
            OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY
            NOT APPLY TO YOU. IN SUCH CASES, DGI APPAREL’S LIABILITY WILL BE
            LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
          <h1>XVII. Applicable Law</h1>
          <p>
            By using any DGI Apparel Service, you agree that the Federal
            Arbitration Act, applicable federal law, and the laws of the state
            of New York, without regard to principles of conflict of laws, will
            govern these Terms of Use and any dispute of any sort that might
            arise between you and DGI Apparel.
          </p>
          <h1>XVIII. Site Policies, Modification, and Severability</h1>
          <p>
            Please review our other policies, such as our Privacy Policy posted
            on this site. These policies also govern your use of DGI Apparel
            Services. We reserve the right to make changes to our site, Privacy
            Policy, and Terms of Use at any time. If any of these conditions
            shall be deemed invalid, void, or for any reason unenforceable, that
            condition shall be deemed severable and shall not affect the
            validity and enforceability of any remaining condition.
          </p>
          <ul>
            <p>DGI Apparel, Inc.</p>
            <p>2447 Hering Ave</p>
            <p>Bronx, NY 10469</p>
            <p>www.dgiapparel.com</p>
          </ul>
          <h2>
            Notice and Procedure for Making Claims of Copyright Infringement
          </h2>
          <p>
            If you believe that your work has been copied in a way that
            constitutes copyright infringement, please submit a report in
            writing to the above address, and please provide us with this
            information.
          </p>
          <ul>
            <p>
              A physical signature of the person authorized to act on behalf of
              the owner of the copyright interest
            </p>
            <p>
              A description of the copyrighted work that you claim has been
              infringed upon
            </p>
            <p>
              A description of where the material that you claim is infringing
              is located on the site
            </p>
            <p>Your address, telephone number, and e-mail address</p>
            <p>
              A statement by you that you have a good-faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law
            </p>
            <p>
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's
              behalf.
            </p>
          </ul>
          <h2>Additional DGI Apparel Software Terms</h2>
          <ul>
            <p>
              Use of the DGI Apparel Software - You may use DGI Apparel Software
              solely for purposes of enabling you to use and enjoy the DGI
              Apparel Services as provided by DGI Apparel, and as permitted by
              the Terms of Use, these Software Terms and any Service Terms,
              including those of third party vendors. You may not incorporate
              any portion of the DGI Apparel Software into your own programs or
              compile any portion of it in combination with your own programs,
              transfer it for use with another service, or sell, rent, lease,
              lend, loan, distribute or sub-license the DGI Apparel Software or
              otherwise assign any rights to the DGI Apparel Software in whole
              or in part. You may not use the DGI Apparel Software for any
              illegal purpose. We may cease providing any DGI Apparel Software
              and we may terminate your right to use any DGI Apparel Software at
              any time. Your rights to use the DGI Apparel Software will
              automatically terminate without notice from us if you fail to
              comply with any of these Software Terms or the Terms of Use.
              Additional third party terms contained within or distributed with
              certain DGI Apparel Software that are specifically identified in
              related documentation may apply to that DGI Apparel Software (or
              software incorporated with the DGI Apparel Software) and will
              govern the use of such software in the event of a conflict with
              these Terms of Use. All software used in any DGI Apparel Service
              is the property of DGI Apparel or its software vendors and
              protected by United States and international copyright laws.
            </p>
            <p>
              Use of Third Party Services - When you use the DGI Apparel
              Software, you may also be using the services of one or more third
              parties, such as a wireless carrier or a mobile platform provider.
              Your use of these third party services may be subject to the
              separate policies, terms of use, and fees of these third parties.
            </p>
            <p>
              No Reverse Engineering - You may not, and you will not encourage,
              assist or authorize any other person to copy, modify, reverse
              engineer, decompile or disassemble, extract source code, or
              otherwise tamper with, the DGI Apparel Software, whether in whole
              or in part, or create any derivative works from or of the DGI
              Apparel Software.
            </p>
            <p>
              Updates - In order to keep the DGI Apparel Software up-to-date, we
              may offer automatic or manual updates at any time and without
              notice to you.
            </p>
            <p>
              Export Regulations; Government End Users - You must comply with
              all export and re-export restrictions and regulations of the
              Department of Commerce and other United States agencies and
              authorities that may apply to the DGI Apparel Software. If you are
              a U.S. Government end user, we are licensing the DGI Apparel
              Software to you as a "Commercial Item" as that term is defined in
              the U.S. Code of Federal Regulations (see 48 C.F.R. § 2.101), and
              the rights we grant you to the DGI Apparel Software are the same
              as the rights we grant to all others under these Terms of Use.
            </p>
          </ul>
          <h2>Additional Conditions of to Ensure Safety</h2>
          <p>
            We do our absolute best to keep DGI Apparel safe. By using the DGI
            Apparel Services, you agree to the following to help ensure the
            safety of our Services and the DGI Apparel community:
          </p>
          <ul>
            <p>
              You will not post unauthorized commercial communications (such as
              spam) on DGI Apparel.
            </p>
            <p>
              You will not collect users' content or information, or otherwise
              access DGI Apparel, using automated means (such as harvesting
              bots, robots, spiders, or scrapers) without our prior permission.
            </p>
            <p>
              You will not engage in unlawful multi-level marketing, such as a
              pyramid scheme, on DGI Apparel.
            </p>
            <p>You will not upload viruses or other malicious code.</p>
            <p>
              You will not solicit login information or access an account
              belonging to someone else.
            </p>
            <p>You will not bully, intimidate, or harass any user.</p>
            <p>
              You will not post content that: is hate speech, threatening, or
              pornographic; incites violence; or contains nudity or graphic or
              gratuitous violence.
            </p>
            <p>
              You will not attempt to sell any product that is illegal in any
              U.S. jurisdiction, or that you are not authorized to sell.
            </p>
            <p>
              You will not use DGI apparel to do anything unlawful, misleading,
              malicious, or discriminatory.
            </p>
            <p>
              You will not do anything that could disable, overburden, or impair
              the proper working or appearance of DGI Apparel, such as a denial
              of service attack or interference with page rendering or other DGI
              Apparel functionality.
            </p>
            <p>
              You will not facilitate or encourage any violations of this
              Statement or our policies.
            </p>
          </ul>
          <h2>Additional Conditions for Registration and Account Security</h2>
          <p>
            DGI Apparel users provide their real names, contact information, and
            business information, and we need your help to keep it that way.
            Here are some commitments you make to us relating to registering and
            maintaining the security of your account:
          </p>
          <ul>
            <p>
              You will not provide any false personal or business information on
              DGI Apparel, or create an account for anyone other than yourself
              without permission.
            </p>
            <p>You will not create more than one account for your business.</p>
            <p>
              You will not misrepresent yourself for your business, by
              impersonating another person or business, on any part of DGI
              Apparel.
            </p>
            <p>
              If we disable your account, you will not create another one
              without our permission.
            </p>
            <p>
              You will not use your DGI Apparel account for your commercial
              gain, except for the purposes for which DGI Apparel was
              specifically created to help businesses.
            </p>
            <p>
              You will not use DGI apparel if you are not a custom apparel
              business owner in the United States or an explicitly authorized
              agent acting on behalf of a licensed custom apparel business
              owner.
            </p>
            <p>
              You will keep your contact information accurate and up-to-date.
            </p>
            <p>
              You will not share your password, let anyone else access your
              account outside of your custom apparel business, or do anything
              else that might jeopardize the security of your account.
            </p>
            <p>
              You will not transfer your account to anyone without first getting
              our written permission.
            </p>
          </ul>
          <h2>Additional Conditions for Protecting the Rights of Others</h2>
          <p>
            We respect other people's rights, and expect you to do the same.
          </p>
          <ul>
            <p>
              You will not post content or take any action on DGI Apparel that
              infringes or violates someone else's rights or otherwise violates
              the law.
            </p>
            <p>
              We can remove any content or information you post on DGI Apparel
              if we believe that it violates this Statement or our policies.
            </p>
            <p>
              If we remove your content for infringing someone else's copyright,
              and you believe we removed it by mistake, we will provide you with
              an opportunity to appeal.
            </p>
            <p>
              If you repeatedly infringe other people's intellectual property
              rights, we will disable your account when appropriate.
            </p>
            <p>
              You will not use our copyrights or Trademarks or any confusingly
              similar marks, without our express prior written permission.
            </p>
            <p>
              You will not attempt to collect information from DGI Apparel or
              DGI Apparel users.
            </p>
            <p>
              You will not post any person’s or entity’s identification
              documents or sensitive financial information on DGI Apparel.
            </p>
          </ul>
          <h2>Additional Miscellaneous Conditions of Use</h2>
          <ul>
            <p>
              This Statement makes up the entire agreement between the parties
              regarding DGI Apparel, and supersedes any prior agreements.
            </p>
            <p>
              If any portion of this Statement is found to be unenforceable, the
              remaining portion will remain in full force and effect.
            </p>
            <p>
              If we fail to enforce any of this Statement, it will not be
              considered a waiver.
            </p>
            <p>
              Any amendment to or waiver of this Statement must be made in
              writing and signed by us.
            </p>
            <p>
              You will not transfer any of your rights or obligations under this
              Statement to anyone else without our consent.
            </p>
            <p>
              All of our rights and obligations under this Statement are freely
              assignable by us in connection with a merger, acquisition, or sale
              of assets, or by operation of law or otherwise.
            </p>
            <p>
              Nothing in this Statement shall prevent us from complying with the
              law.
            </p>
            <p>
              This Statement does not confer any third party beneficiary rights.
            </p>
            <p>We reserve all rights not expressly granted to you.</p>
            <p>
              You will comply with all applicable laws when using or accessing
              DGI Apparel.
            </p>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
