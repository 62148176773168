import React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { useGetVendorByCodeQuery } from "features/baseApi";

export default function SupplierRating({ vendorInfo, showReviewCount = true }) {
  const {
    data: vendorData,
    error,
    isLoading,
  } = useGetVendorByCodeQuery(vendorInfo.code);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width={150}
        height={20}
        animation="wave"
        sx={{ borderRadius: 1 }}
      />
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="body2">
        Error loading rating
      </Typography>
    );
  }

  let sum = 0;
  let reviewCount = 0;
  for (let id in vendorData.reviews) {
    sum += Number(vendorData.reviews[id].stars);
    reviewCount++;
  }
  const averageRating = sum / reviewCount;
  const roundedRating = Math.round(averageRating * 2) / 2;

  const renderStars = () => {
    const stars = [];
    let remainingRating = roundedRating;

    for (let i = 0; i < 5; i++) {
      if (remainingRating >= 1) {
        stars.push(
          <StarRoundedIcon key={i} fontSize="small" sx={{ color: "#FFA552" }} />
        );
        remainingRating -= 1;
      } else if (remainingRating === 0.5) {
        stars.push(
          <StarHalfRoundedIcon
            key={i}
            fontSize="small"
            sx={{ color: "#FFA552" }}
          />
        );
        remainingRating -= 0.5;
      } else {
        stars.push(
          <StarOutlineRoundedIcon
            key={i}
            fontSize="small"
            sx={{ color: "#FFA552" }}
          />
        );
      }
    }

    return stars;
  };

  return (
    <Box display="flex" alignItems="center">
      {renderStars()}
      {showReviewCount && (
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{
            marginLeft: 0.5,
            color: "#676F78",
          }}
        >
          (
          <span
            style={{
              textDecoration: "none",
              color: "#676F78",
              fontSize: "0.875rem",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            {reviewCount > 1
              ? reviewCount + " reviews"
              : reviewCount + " review"}
          </span>
          )
        </Typography>
      )}
    </Box>
  );
}
