import axios from "axios";
import { Auth } from "aws-amplify";

const BASE_API_URL = "https://pm9gjozpxh.execute-api.us-east-1.amazonaws.com";
const client = axios.create({
  baseURL: BASE_API_URL,
});

client.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const jwtToken = session.accessToken.jwtToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    },
  };
});

export default client;
