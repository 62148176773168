import { sortBy } from "lodash";
import { DateTime } from "luxon";
import { capitalizeFirstLetter, capitalizeWords } from "./formatStringCasing";

export const TIMEZONE_LOOKUP = {
  ET: "America/New_York",
  EDT: "America/New_York",
  EST: "America/New_York",
  PT: "America/Los_Angeles",
  PDT: "America/Los_Angeles",
  CDT: "America/Chicago",
  CT: "America/Chicago",
  MT: "America/Denver",
};

export const CM_WAREHOUSE_MAP = {
  nc: "Indian Trail, NC",
};

export const AB_WAREHOUSE_MAP = {
  ph: "Harrisburg, PA",
  ma: "Middleboro, MA",
  gd: "Atlanta, GA",
  cc: "Chicago, IL",
  fo: "Orlando, FL",
  td: "Dallas, TX",
  cn: "Fresno, CA",
  kc: "Kansas City, KS",
};

export const SIZE_ORDER_MAP = {
  XXS: 0,
  XS: 1,
  S: 2,
  M: 3,
  L: 4,
  XL: 5,
  "2XL": 6,
  "3XL": 7,
  "4XL": 8,
  "5XL": 9,
};

export const SM_WAREHOUSE_MAP = {
  1: "Seattle, WA",
  2: "Cincinnati, OH",
  3: "Dallas, TX",
  4: "Reno, NV",
  5: "Robbinsville, NJ",
  6: "Jacksonville, FL",
  7: "Minneapolis, MN",
  8: "Backorder",
  12: "Phoenix, AZ",
  31: "Richmond, VA",
};

export const SS_WAREHOUSE_MAP = {
  3: "Robbinsville, NJ",
  12: "Reading, PA",
  11: "West Chester, OH",
  8: "McDonough, GA",
  10: "Lockport, IL",
  2: "Pompano Beach, FL",
  7: "Fort Worth, TX",
  4: "Olathe, KS",
  9: "Reno, NV",
};

export const AS_WAREHOUSE_MAP = {
  as: "Augusta, GA",
};

export const PG_WAREHOUSE_MAP = {
  OXNARD: "Oxnard, CA",
  COLUMBIA: "Columbia, SC",
};

export const ACC_WAREHOUSE_MAP = {
  v: "Virginia Warehouse",
  a4: "A4 Apparel Dropship - CA",
  am: "A4 Apparel Dropship - MO",
  vf: "VF Imagewear Dropship - TN",
  wp: "Whispering Pines Drop - SC",
  sp: "Sierra Pacific Dropship - TX",
};

export const ASC_WAREHOUSE_MAP = {
  nc: "Charlotte, NC",
  ca: "Carson, CA",
};

export const STA_WAREHOUSE_MAP = {
  20: "DALLAS, TX",
  30: "MEMPHIS, TN",
  39: "GILDAN DISTRIBUTION CENTER",
  44: "COLORTONE-Plantation, FL",
};

export const SLC_WAREHOUSE_MAP = {
  A: "Salt Lake City, UT",
};

export const SM_CUTOFF_TIMES = {
  "Seattle, WA": "4:00 PT",
  "Cincinnati, OH": "4:00 ET",
  "Ashland, VA": "2:00 ET",
  "Richmond, VA": "2:00 ET",
  "Dallas, TX": "4:00 CT",
  "Reno, NV": "4:00 PT",
  "Robbinsville, NJ": "4:00 ET",
  "Jacksonville, FL": "4:00 ET",
  "Minneapolis, MN": "4:00 CT",
  "Phoenix, AZ": "4:00 MT",
};

export const ACC_CUTOFF_TIMES = {
  "VIRGINIA WAREHOUSE": "4:30 ET",
};

export const ASC_CUTOFF_TIMES = {
  "Charlotte, NC": "2:00 ET",
  "Carson, CA": "2:00 PT",
};

export const STA_CUTOFF_TIMES = {
  "DALLAS, TX": "4:00 CT",
  "MEMPHIS, TN": "4:00 CT",
};

export const SLC_CUTOFF_TIMES = {
  "Salt Lake City, UT": "4:00 MT",
  "Phoenix, AZ": "4:00 MT",
};

export const CM_WHSE_PICKUP_TIMES = {
  "8:00 AM": "480",
  "8:30 AM": "510",
  "9:00 AM": "540",
  "9:30 AM": "570",
  "10:00 AM": "600",
  "10:30 AM": "630",
  "11:00 AM": "660",
  "11:30 AM": "690",
  "12:00 PM": "720",
  "12:30 PM": "750",
  "1:00 PM": "780",
  "1:30 PM": "810",
  "2:00 PM": "840",
  "2:30 PM": "870",
  "3:00 PM": "900",
  "3:30 PM": "930",
  "4:00 PM": "960",
  "4:30 PM": "990",
  "5:00 PM": "1020",
};

export const checkIfSaleExists = (sizedPricingData) => {
  if (!sizedPricingData) {
    return false;
  }
  return sizedPricingData.some((sizeData) => {
    const [size, salePrice, originalPrice] = sizeData;
    if (
      salePrice === "-" ||
      originalPrice === "-" ||
      salePrice == undefined ||
      originalPrice == undefined
    ) {
      return false;
    }
    const salePriceFigure = parseFloat(
      salePrice.toString().replace(/[^0-9.-]+/g, "")
    );
    const originalPriceFigure = parseFloat(
      originalPrice.toString().replace(/[^0-9.-]+/g, "")
    );
    if (originalPriceFigure > salePriceFigure) {
      return true;
    }
    return false;
  });
};

export const checkIfMultipleEQPs = (sizedPricingData) => {
  if (!sizedPricingData) {
    return false;
  }
  return sizedPricingData.some((dataEntry) => dataEntry[3] || dataEntry[4]);
};

export const getLocalCutoffTime = (warehouseCutoff) => {
  // for ACC dropshipping warehouses
  if (
    warehouseCutoff === "Shipping Today" ||
    warehouseCutoff === "Order Will Ship Tomorrow" ||
    warehouseCutoff === "Ships Next Day"
  ) {
    return warehouseCutoff;
  }
  // very crucial check esp if a distr were to change warehouse names
  if (!warehouseCutoff) {
    return "N/A";
  }
  let [rawTime, whseLocalTimeZone] = warehouseCutoff.trim().split(" ");
  if (!whseLocalTimeZone) {
    whseLocalTimeZone = "ET";
  }
  const whseLocalCityZone = TIMEZONE_LOOKUP[whseLocalTimeZone];

  const [hours, minutes] = rawTime.split(":");
  let numberOfHrs;
  let mins;
  if (!minutes || !hours) {
    return "N/A";
  }
  if (minutes.toLowerCase().includes("am")) {
    numberOfHrs = Number(hours);
    mins = Number(minutes.toLowerCase().replace("am", ""));
  } else {
    numberOfHrs = Number(hours) === 12 ? 12 : Number(hours) + 12;
  }
  const sourceDateTime = DateTime.fromObject(
    { hour: numberOfHrs, minute: mins },
    { zone: whseLocalCityZone }
  );
  let localCutoffTime = sourceDateTime.setZone("local").toLocaleString({
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
  localCutoffTime = localCutoffTime.replace(/^0(?:0:0?)?/, "").replace(" ", "");
  return localCutoffTime;
};

export const parseInventory = (
  dataKey,
  productData,
  ssCID = "",
  ssSIDM = "",
  cmSKU = "",
  smEstDeliveryDays = null,
  cmEstDeliveryDays = null
) => {
  if (productData === null) {
    return [[], [], false, true];
  }

  if (Object.keys(productData).length === 0) {
    return [[], [], false, true];
  }

  const vendorData = productData[dataKey];

  if (vendorData === null || vendorData === undefined) {
    return [[], [], false, true];
  }

  const invalidVendorData =
    (Array.isArray(vendorData[0]) && vendorData[0] === "msg") ||
    Object.keys(vendorData).length === 0 ||
    Object.keys(vendorData)[0] === "msg";

  if (invalidVendorData) {
    return [[], [], false, true];
  }

  switch (dataKey) {
    case "ab_data":
      return parseABInventory(vendorData);
    case "cm_data":
      return parseCMInventory(vendorData, cmSKU, cmEstDeliveryDays);
    case "sm_data":
      return parseSMInventory(vendorData, smEstDeliveryDays);
    case "ss_data":
      return parseSSInventory(vendorData, ssCID, ssSIDM);
    default:
      return [[], [], false, true];
  }
};

const getABSizeNames = (sizeData) => {
  const sizeLookup = {};

  for (const dataObj of sizeData) {
    const sizeCode = dataObj["sizeCode"];
    const sizeDescr = dataObj["description"];
    sizeLookup[sizeCode] = sizeDescr;
  }

  return sizeLookup;
};

const getABSizeOrders = (sizeData) => {
  const sizeLookup = {};

  for (const dataObj of sizeData) {
    const sizeDesc = dataObj["description"];
    const sizeOrder = dataObj["sortOrder"];
    sizeLookup[sizeDesc] = sizeOrder;
  }

  return sizeLookup;
};

const sortABSizesByOrder = (sizeName, orderLookup) => {
  const sizeOrder = orderLookup[sizeName];
  return sizeOrder;
};

export const sortDeliveryDates = (deliveryDate) => {
  const dateObj = new Date(deliveryDate);
  const currentYear = new Date().getFullYear();
  dateObj.setYear(currentYear);
  return dateObj.getTime();
};

const getCutoffHHMM = (cutoffTime, warehouseName = undefined) => {
  try {
    let [cutoffHours, cutoffMinutes] = cutoffTime.slice(0, -2).split(":");
    cutoffMinutes = Number(cutoffMinutes);
    if (cutoffTime.slice(-2) === "AM") {
      cutoffHours = Number(cutoffHours);
      if (cutoffHours === 12) {
        cutoffHours = 0;
      }
    } else if (cutoffTime.slice(-2) === "PM") {
      cutoffHours = Number(cutoffHours);
      if (cutoffHours !== 12) {
        cutoffHours += 12;
      }
    }
    return [cutoffMinutes, cutoffHours];
  } catch (e) {
    console.error(
      `Error getting cutoff hh and mm for ${warehouseName} with cutoff time ${cutoffTime}: ${e.message}`
    );
  }
};

export const calculateDeliveryDate = (
  warehouseName,
  estDeliveryDays,
  localCutoffTime
) => {
  try {
    if (!estDeliveryDays) {
      return "3-7 Days";
    }
    let estDays = estDeliveryDays[warehouseName];
    if (!estDays) {
      return "3-7 Days";
    }
    const today = DateTime.local();
    estDays = Number(estDays);

    if (!estDays) {
      console.log(`Warning: est days (${estDays}) could not be converted to Number type`)
    }

    // checking if user is looking to order past cutoff time
    const [cutoffTime, cutoffTimeZone] = localCutoffTime.split(" ");

    if (!cutoffTime || !cutoffTimeZone) {
      console.log(
        `Warning: no cutoff time or timezone available for warehouse: ${warehouseName}`
      );
    }

    const [cutoffMinutes, cutoffHours] = getCutoffHHMM(
      cutoffTime,
      warehouseName
    );
    const whseTimeZone = TIMEZONE_LOOKUP[cutoffTimeZone];

    if (!whseTimeZone) {
      console.log(
        `Warning: no time zone found for warehouse: ${warehouseName}`
      );
    }

    const cutoffDateTime = DateTime.fromObject(
      {
        hour: cutoffHours,
        minute: cutoffMinutes,
        second: 0,
      },
      { zone: whseTimeZone }
    );
    const timeNow = DateTime.now().setZone(whseTimeZone);
    if (timeNow >= cutoffDateTime) {
      estDays += 1;
    }

    if (today.weekday === 6) {
      // ordering on a saturday
      estDays += 2;
    } else if (today.weekday === 7) {
      // ordering on a sunday
      estDays += 1;
    }
    const estDate = today.plus({ days: estDays });

    const nextSaturday = today.set({ weekday: 6 });
    const nextSunday = today.set({ weekday: 7 });
    const isOnWeekend =
      estDate.equals(nextSaturday) || estDate.equals(nextSunday);

    if (isOnWeekend) {
      let nextMonday = today.plus({ days: (1 - today.weekday + 7) % 7 });
      if (today.weekday === 1) {
        // luxon recognizes Monday as the first day of the week
        nextMonday = today.plus({ days: 7 });
      }
      return nextMonday.toFormat("LL/dd");
    }

    return estDate.toFormat("LL/dd");
  } catch (e) {
    console.error(
      `Failed to calculate delvery date format for warehouse: ${warehouseName} and local cutoff time: ${localCutoffTime} with error: ${e.message}`
    );
  }
};

const parseABInventory = (vendorData) => {
  let sizedPricingData = [];
  let inventoryData = {};

  const whseMetaData = vendorData["warehouseList"];
  const sizeData = vendorData["product"][0]["size"];
  const isBlankAllowed = vendorData["product"][0]["blankAllowed"];
  const sizeNameLookup = getABSizeNames(sizeData);
  const sizeOrderLookup = getABSizeOrders(sizeData);
  const sizedItems = vendorData["product"][0]["item"];

  for (const sizedItem of sizedItems) {
    const sizeCode = sizedItem["sizeCode"];

    // Odd check but AB just throws a lot of data when there really isn't data
    // If there is more than one entry for a sized item, then the data is garbage
    const numberOfItems = sizedItems.filter((sizedItem) => {
      return sizedItem["sizeCode"] === sizeCode;
    });
    if (numberOfItems.length > 1) {
      return [[], [], false, isBlankAllowed];
    }

    const sizeLabel = sizeNameLookup[sizeCode];

    let displayPrice = null;
    const hasSalePrice =
      sizedItem["specialPriceHtml"] !== "N/A" && sizedItem["specialPrice"] > 0;
    const hasStandardPrice =
      sizedItem["standardPriceHtml"] !== "N/A" &&
      sizedItem["standardPrice"] > 0;
    const hasBestPrice =
      sizedItem["bestPriceHtml"] !== "N/A" && sizedItem["bestPrice"] > 0;

    if (!sizedItem["priceMethod"]) {
      if (hasSalePrice) {
        displayPrice = sizedItem["specialPrice"];
      } else if (hasBestPrice) {
        displayPrice = sizedItem["bestPrice"];
      } else if (hasStandardPrice) {
        displayPrice = sizedItem["standardPrice"];
      } else {
        displayPrice = null;
      }
    }

    displayPrice =
      displayPrice &&
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(displayPrice);

    if (displayPrice) {
      sizedPricingData.push([sizeLabel, displayPrice]);
    }

    const itemNumber = sizedItem["itemNumber"];
    for (const whseInfo of sizedItem["itemWhse"]) {
      const needToCallWhse = whseInfo["needToCall"];
      const whseCode = whseInfo["warehouse"];
      const stockNum = whseInfo["availQty"];
      const whseLocationData = whseMetaData.find(
        (dataObj) => dataObj["whse"] === whseCode
      );

      if (!whseLocationData || whseCode === "*") {
        continue;
      }

      let whseLocation = "";
      if (whseLocationData["ds-warehouse"]) {
        whseLocation = capitalizeWords(whseLocationData["whseDesc"], [
          " ",
          "/",
        ]);
      } else {
        whseLocation = whseLocationData["whseDesc"];
      }

      if (!inventoryData[whseLocation]) {
        const warehouse = whseMetaData.find((whse) => {
          return whse["whse"] === whseCode;
        });

        const rawCutoffTime = warehouse["cutoffTime"];
        const localCutoffTime = getLocalCutoffTime(rawCutoffTime);

        inventoryData[whseLocation] = {
          quantities: [{ sizeLabel, stockNum, itemNumber, needToCallWhse }],
          estDeliveryDate: warehouse["estDate"],
          deliveryCutoffTime: localCutoffTime,
        };
      } else {
        let currentQuants = inventoryData[whseLocation]["quantities"];
        currentQuants.push({ sizeLabel, stockNum, itemNumber, needToCallWhse });
        inventoryData[whseLocation]["quantities"] = currentQuants;
      }
    }
  }

  const sizeNames = Object.keys(sizeOrderLookup);
  const invDataEntries = Object.entries(inventoryData);
  for (const dataEntry of invDataEntries) {
    const [warehouse, dataVals] = dataEntry;
    const unsortedQuantities = dataVals["quantities"];
    const unsortedSizes = unsortedQuantities.map((quant) => quant["sizeLabel"]);

    for (const size of sizeNames) {
      if (!unsortedSizes.includes(size)) {
        unsortedQuantities.push({
          sizeLabel: size,
          stockNum: "-",
          itemNumber: false,
          needToCallWhse: false,
        }); // giving false values for itemNumber and needToCallWhse

        const priceDataEntry = sizedPricingData.find((dataEntry) => {
          return dataEntry[0] === size;
        });

        if (!priceDataEntry) {
          sizedPricingData.push([size, "-"]);
        }
      }
    }

    const sortedQuantities = sortBy(unsortedQuantities, [
      function (quantEntry) {
        return sortABSizesByOrder(quantEntry["sizeLabel"], sizeOrderLookup);
      },
    ]);
    inventoryData[warehouse]["quantities"] = sortedQuantities;
  }

  inventoryData = sortBy(invDataEntries, [
    function (dataEntry) {
      return sortDeliveryDates(dataEntry[1]["estDeliveryDate"]);
    },
  ]);

  sizedPricingData = sortBy(sizedPricingData, [
    function (dataEntry) {
      return sortABSizesByOrder(dataEntry[0], sizeOrderLookup);
    },
  ]);
  return [sizedPricingData, inventoryData, isBlankAllowed]; // TODO: maybe do sizedPricingData in price parsing
};

const parseSMInventory = (vendorData, smEstDeliveryDays) => {
  let sizedPricingData = [];
  let inventoryData = {};

  // note that sizes are always return in sorted order from sanmar
  const sizedItems = vendorData["variantOptions"];

  if (!sizedItems) {
    return [[], [], false, true];
  }

  if (!sizedItems.length) {
    return [[], [], false, true];
  }

  for (const sizedItem of sizedItems) {
    // sizedItem["priceDataMap"] maps integer keys to pricing data objs
    // 5 seems to give the best pricing in any case
    // 1,3 seems to show up in different cases and gives original pricing
    // below is a generalized solution to get the cheapest pricing from any key,value pair
    let lowestSizedItemPrice = Infinity;
    if (sizedItem["priceDataMap"]) {
      const itemDataObjs = Object.values(sizedItem["priceDataMap"]);
      for (const itemData of itemDataObjs) {
        const itemPrice = itemData["minPrice"];
        if (itemPrice && itemPrice < lowestSizedItemPrice) {
          lowestSizedItemPrice = itemPrice;
        }
      }
    }

    const itemNumber = sizedItem["code"];
    const displayPrice = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(lowestSizedItemPrice);
    const sizeLabel = sizedItem["size"];
    sizedPricingData.push([sizeLabel, displayPrice]);

    const warehouseStocks = Object.entries(sizedItem["availableStockMap"]);

    for (const warehouse of warehouseStocks) {
      const [warehouseKey, stockNum] = warehouse;
      const warehouseName = SM_WAREHOUSE_MAP[warehouseKey];

      if (!warehouseName) {
        continue;
      }

      const rawCutoffTime = SM_CUTOFF_TIMES[warehouseName];
      const localCutoffTime = getLocalCutoffTime(rawCutoffTime);

      if (!inventoryData[warehouseName]) {
        inventoryData[warehouseName] = {
          quantities: [{ sizeLabel, stockNum, itemNumber }],
          estDeliveryDate: calculateDeliveryDate(
            warehouseName,
            smEstDeliveryDays,
            localCutoffTime
          ),
          deliveryCutoffTime: localCutoffTime,
        };
      } else {
        let currentQuants = inventoryData[warehouseName]["quantities"];
        currentQuants.push({ sizeLabel, stockNum, itemNumber });
        inventoryData[warehouseName]["quantities"] = currentQuants;
      }
    }
  }

  inventoryData = Object.entries(inventoryData);
  inventoryData = sortBy(inventoryData, [
    function (dataEntry) {
      return sortDeliveryDates(dataEntry[1]["estDeliveryDate"]);
    },
  ]);
  return [sizedPricingData, inventoryData, true]; // TODO: maybe do sizedPricingData in price parsing
};

const parseCMInventory = (vendorData, cmSKU, cmEstDeliveryDays) => {
  let sizedPricingData = [];
  let inventoryData = {};

  if (!cmSKU) {
    return [[], [], false, true];
  }

  const warehouseList = vendorData["warehouseList"];
  const productItems = vendorData["product"][0]["item"];
  const sizeItems = vendorData["product"][0]["size"];
  const sizeCodeLookups = {};

  // we assume that the sizes always come back in an order from smallest to largest
  for (const item of sizeItems) {
    sizeCodeLookups[item.sizeCode] = item.description;
  }
  const sizeCodeEntries = Object.entries(sizeCodeLookups);

  // item size identifiers don't always match size descriptions so we do extra work here...
  const matchingItem = productItems.find((item) => {
    return cmSKU.toLowerCase() === item.itemNumber.toLowerCase();
  });

  if (!matchingItem) {
    return [[], [], false, true];
  }

  const matchingItemNum = matchingItem.itemNumber.toLowerCase();
  const matchingColorCode = matchingItem.colorCode.toLowerCase();
  const generalSKU = matchingItemNum.slice(
    0,
    matchingItemNum.lastIndexOf(matchingColorCode) + matchingColorCode.length
  );

  for (const entry of sizeCodeEntries) {
    const [sizeCode, sizeLabel] = entry;

    const targetItem = productItems.find((item) => {
      const isCorrectSize = sizeCode === item.sizeCode; // we are looking at an item with the right size
      const isCorrectSKU = item.itemNumber.toLowerCase().includes(generalSKU); // we are looking at an item with the right style and color
      return isCorrectSize && isCorrectSKU;
    });

    if (!targetItem) {
      continue;
    }

    const itemNumber = targetItem.itemNumber;
    const warehouses = targetItem["itemWhse"];
    for (const warehouse of warehouses) {
      const displayPrice =
        targetItem["salePrice"] === 0
          ? targetItem["regPrice"]
          : targetItem["salePrice"];

      const sizeDataEntry = sizedPricingData.find((dataEntry) => {
        return sizeLabel === dataEntry[0];
      });
      if (!sizeDataEntry) {
        sizedPricingData.push([sizeLabel, displayPrice]);
      }

      const stockNum = warehouse["availQty"];

      let warehouseName = warehouseList
        .find((whse) => {
          return warehouse["warehouse"] === whse["whse"];
        })
        ["whseDesc"].trim();

      if (warehouseName.includes("Main Warehouse")) {
        warehouseName = "Indian Trail, NC";
      }

      let localCutoffTime = "";
      const warehouseDataObj = warehouseList.find((whse) => {
        return warehouse["warehouse"] === whse["whse"];
      });
      let estDeliveryDate;
      if (warehouse["warehouse"] === "nc") {
        // cutoff times only
        const warehouseCutoff = warehouseDataObj["cutoffTime"] + "ET"; // manually appending ET
        localCutoffTime = getLocalCutoffTime(warehouseCutoff);
        estDeliveryDate = calculateDeliveryDate(
          warehouseName,
          cmEstDeliveryDays,
          localCutoffTime
        );
      } else {
        localCutoffTime = capitalizeFirstLetter(warehouseDataObj["cutoffMsg"]);
        estDeliveryDate = "3-7 days";
      }

      if (!inventoryData[warehouseName]) {
        inventoryData[warehouseName] = {
          quantities: [{ sizeLabel, stockNum, itemNumber }],
          estDeliveryDate: estDeliveryDate,
          deliveryCutoffTime: localCutoffTime,
        };
      } else {
        let currentQuants = inventoryData[warehouseName]["quantities"];
        currentQuants.push({ sizeLabel, stockNum, itemNumber });
        inventoryData[warehouseName]["quantities"] = currentQuants;
      }
    }

    // sometimes not all warehouses are represented
    // which causes visual incongruencies in our inventory table
    if (warehouses.length < warehouseList.length) {
      for (const whse of warehouseList) {
        const matchingWhse = warehouses.find((itemWarehouse) => {
          return itemWarehouse["warehouse"] === whse["whse"];
        });

        if (!matchingWhse) {
          let warehouseName = "";
          if (whse["whseDesc"].includes("Main Warehouse")) {
            warehouseName = "Indian Trail, NC";
          } else {
            warehouseName = whse["whseDesc"];
          }
          if (!inventoryData[warehouseName]) {
            continue;
          }
          let currentQuants = inventoryData[warehouseName]["quantities"];
          currentQuants.push({ sizeLabel, stockNum: "-", itemNumber });
          inventoryData[warehouseName]["quantities"] = currentQuants;
        }
      }
    }
  }
  inventoryData = Object.entries(inventoryData);
  return [sizedPricingData, inventoryData, true]; // TODO: maybe do sizedPricingData in price parsing
};

const parseSSInventory = (dataBody, ssCID, ssSIDM) => {
  if (!dataBody) {
    return [[], [], false, true];
  }

  let sizedPricingData = [];
  let inventoryData = {};
  const caseQtyIncrements = {};

  const warehouseList = dataBody["WHRepo"];
  let targetColorItem = dataBody["OG"].find((itemColor) => {
    return ssCID === itemColor["CID"];
  });

  if (!targetColorItem) {
    targetColorItem = dataBody["OG"].find((itemColor) => {
      const itemWarehouseData = itemColor["WH_Current"].concat(
        itemColor["WH_DS"]
      );
      for (const whseData of itemWarehouseData) {
        const sizeData = whseData["SizesByWH"];
        for (const itemSize of sizeData) {
          if (ssSIDM === itemSize["SIDM"]) {
            return true;
          }
        }
      }
      return false;
    });
  }

  if (!targetColorItem) {
    return [[], [], false, true];
  }

  const colorname = targetColorItem["CN"].toLowerCase();
  const sizedItems = targetColorItem["THLV"];
  const regularWarehouseInfo = targetColorItem["WH_Current"];
  const dropShipWarehouseInfo = targetColorItem["WH_DS"];
  const warehouseInfo = regularWarehouseInfo.concat(dropShipWarehouseInfo);
  const seenSizes = new Set();
  const unavailableSizes = new Set();

  // sizes seem to be sorted in the order we observe them
  for (const sizedItem of sizedItems) {
    let displayPrice;
    const itemDataKeys = Object.keys(sizedItem);

    if (itemDataKeys.includes("AP") && itemDataKeys.includes("CP")) {
      const adjustedPriceInfo = sizedItem["AP"];
      let adjustedPrice, currentPrice;
      if (Object.keys(adjustedPriceInfo).includes("V1")) {
        adjustedPrice = Number(adjustedPriceInfo["V1"]);
      }
      currentPrice = Number(sizedItem["CP"]);

      if (currentPrice && adjustedPrice) {
        displayPrice = Math.min(currentPrice, adjustedPrice);
      } else if (currentPrice) {
        displayPrice = currentPrice;
      } else if (adjustedPrice) {
        displayPrice = adjustedPrice;
      }
    }

    if (!displayPrice) {
      displayPrice = "-";
    }

    const sizeID = sizedItem["SIDM"];
    let sizeLabel;

    for (const warehouse of warehouseInfo) {
      const sizeInventories = warehouse["SizesByWH"];
      const targetInventory = sizeInventories.find((sizeInventory) => {
        const inventorySizeID = sizeInventory["SIDM"];
        const sizeName = sizeInventory["N"];
        if (inventorySizeID === sizeID && !seenSizes.has(sizeName)) {
          seenSizes.add(sizeName);
          return true;
        }
        return false;
      });

      if (targetInventory) {
        sizeLabel = targetInventory["N"];
        break;
      }
    }

    const caseQtyIncr = sizedItem["CQ"];
    caseQtyIncrements[sizeLabel] = caseQtyIncr;

    if (displayPrice === "-") {
      unavailableSizes.add(sizeLabel);
    }

    sizedPricingData.push([sizeLabel, displayPrice]);
  }

  for (const warehouse of warehouseInfo) {
    const whseMetaData = warehouse["H"];
    const caseQtyRequired = whseMetaData["FCLY"];
    const whseInventoryData = warehouse["SizesByWH"];
    const whseQuantities = [];

    for (const whseDataEntry of whseInventoryData) {
      const sizeLabel = whseDataEntry["N"];
      const stockNum = unavailableSizes.has(sizeLabel)
        ? "-"
        : whseDataEntry["ATS"];
      const itemNumber =
        whseDataEntry["SiteId"] +
        "_" +
        whseDataEntry["WareHouseId"] +
        "_" +
        whseDataEntry["SID"] +
        "_" +
        whseDataEntry["SIDM"] +
        "_" +
        colorname;
      const caseQtyIncrement = caseQtyIncrements[sizeLabel];
      whseQuantities.push({
        sizeLabel,
        stockNum,
        itemNumber,
        caseQtyRequired,
        caseQtyIncrement: caseQtyIncrement ?? 0,
      });
    }

    const warehouseSiteID = whseMetaData["STID"];
    const warehouseID = whseMetaData["WHID"];
    const targetWarehouse = warehouseList.find((whse) => {
      // standard warehouses share the same whse id but drop shipping ones share the same site id
      if (warehouseSiteID === 5) {
        return whse["WarehouseID"] === warehouseID;
      } else {
        return whse["SiteId"] === warehouseSiteID;
      }
    });
    const warehouseName =
      targetWarehouse["Name"] + (caseQtyRequired ? "*" : "");
    const warehouseCutoff = targetWarehouse["COT"];

    let localCutoffTime = "";
    let warehouseDeliveryDate = "";
    const isDropShippingWhse = dropShipWarehouseInfo.find((dropShipWhse) => {
      return dropShipWhse["H"]["STID"] === warehouseSiteID;
    });
    if (isDropShippingWhse) {
      localCutoffTime = targetWarehouse["L1"];
      warehouseDeliveryDate = targetWarehouse["ESTDEL"].toLowerCase();
    } else {
      localCutoffTime = getLocalCutoffTime(warehouseCutoff);
      warehouseDeliveryDate = targetWarehouse["ESTDEL"].split(" ")[1];
    }

    inventoryData[warehouseName] = {
      quantities: whseQuantities,
      estDeliveryDate: warehouseDeliveryDate,
      deliveryCutoffTime: localCutoffTime,
    };
  }

  // seem to be already sorted by delivery date
  inventoryData = Object.entries(inventoryData);
  return [sizedPricingData, inventoryData, true];
};
