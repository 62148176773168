import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Typography, Box } from '@mui/material';

export default function VendorSpendChart({ vendorSpendByWeek }) {
  const processChartData = (data) => {
    if (!data) {
      return { dataset: [], vendors: [] };
    }

    const xAxisData = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
    const vendors = new Set();

    const dataset = xAxisData.map((week) => {
      const entry = { x: week };
      Object.keys(data[week]).forEach((vendor) => {
        vendors.add(vendor);
        entry[vendor] = parseFloat(data[week][vendor]) || 0;
      });
      return entry;
    });

    return { dataset, vendors: Array.from(vendors) };
  };

  const { dataset, vendors } = processChartData(vendorSpendByWeek);

  const formatCurrency = (value) => {
    return `$${value.toLocaleString()}`;
  };

  const hideLegend = vendors.length > 4;

  return (
    <Box>
      <LineChart
        xAxis={[{
          id: 'Weeks',
          dataKey: 'x',
          scaleType: 'point',
          valueFormatter: (week) => {
            const [month, day, year] = week.split('-');
            return `${parseInt(month)}/${day}`;
          },
        }]}
        yAxis={[{
          id: 'Spend',
          valueFormatter: formatCurrency,
        }]}
        series={vendors.map(vendor => ({
          label: vendor,
          dataKey: vendor,
        }))}
        dataset={dataset}
        width={700}
        height={400}
        slotProps={{
          legend: {
            hidden: hideLegend,
          },
        }}
      />
      <Typography variant="h5" align="center" sx={{ mt: 1, color: 'rgba(65, 75, 86, 1)' }}>
        Weekly Spend by Vendor
      </Typography>
    </Box>
  );
}
