import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useCheckoutContext } from "App";

const BillingInfo = ({ billingOptions, setBillingOptions }) => {
  const { checkoutFormData, setCheckoutFormData } = useCheckoutContext();
  const [orderedBillingOptions, setOrderedBillingOptions] = useState([]);
  const [selectedBillingMethod, setSelectedBillingMethod] = useState("");

  useEffect(() => {
    if (billingOptions.length > 0) {
      const billingOptionIdentifier = billingOptions[0]["name"];
      const restrictSettingOrderedOptions =
        selectedBillingMethod &&
        billingOptionIdentifier !== selectedBillingMethod;
      if (!restrictSettingOrderedOptions) {
        setOrderedBillingOptions(billingOptions);
      }
    }
  }, [billingOptions]);

  useEffect(() => {
    if (orderedBillingOptions && orderedBillingOptions[0]) {
      if (!selectedBillingMethod) {
        if (checkoutFormData["paymentMethod"]) {
          const targetMethod = checkoutFormData["paymentMethod"]["name"];
          setSelectedBillingMethod(targetMethod);
        } else {
          const targetMethod = orderedBillingOptions[0]["name"];
          setSelectedBillingMethod(targetMethod);
        }
      }
    }
  }, [orderedBillingOptions]);

  useEffect(() => {
    const targetOption = orderedBillingOptions.find((option) => {
      return option["name"] === selectedBillingMethod;
    });

    if (targetOption) {
      setCheckoutFormData((prevCheckoutFormData) => ({
        ...prevCheckoutFormData,
        paymentMethod: targetOption,
      }));
    }
  }, [selectedBillingMethod]);

  useEffect(() => {
    if (selectedBillingMethod) {
      const targetBillingOption = orderedBillingOptions.find((option) => {
        return option["name"] === selectedBillingMethod;
      });
      setBillingOptions((prevBillingOptions) => {
        let newBillingOptions = [...prevBillingOptions];
        const targetBillingOptionIndex = newBillingOptions.findIndex(
          (option) => {
            return option["name"] === targetBillingOption["name"];
          },
        );

        if (targetBillingOptionIndex < 0) {
          return;
        }

        newBillingOptions = [targetBillingOption].concat(
          newBillingOptions
            .slice(0, targetBillingOptionIndex)
            .concat(newBillingOptions.slice(targetBillingOptionIndex + 1)),
        );
        return newBillingOptions;
      });
    }
  }, [selectedBillingMethod]);

  const handleChange = (event) => {
    const billingOptionName = event.target.value;
    setSelectedBillingMethod(billingOptionName);
  };

  return (
    <Box
      className="checkoutSection"
      sx={{
        borderTop: "none",
        marginBottom: 2,
        marginTop: 2,
      }}
    >
      <FormLabel
        component="legend"
        sx={{
          marginBottom: 1,
          p: 1,
        }}
        className="text-grayscaleStone font-bold bg-dustyBlue"
      >
        *Billing Method:
      </FormLabel>
      <Box sx={{ padding: 1, maxHeight: "250px", overflowY: "auto" }}>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="billing-method"
            value={selectedBillingMethod}
            onChange={handleChange}
            name="radio-buttons-group"
          >
            {orderedBillingOptions.map((option, index) => (
              <FormControlLabel
                key={`${option.cardHolder}_${index}`}
                value={option.name}
                disabled={option?.expired}
                control={
                  <Radio
                    sx={{
                      span: {
                        color:
                          selectedBillingMethod === option.name && "rgba(0, 110, 165, 1)", // blueblue
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="body1">{`${option.name}`}</Typography>
                }
                sx={{
                  width: "100%",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  "& .MuiFormControlLabel-label": {
                    marginLeft: 1,
                  },
                }}
                componentsProps={{ typography: { sx: { width: "100%" } } }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default BillingInfo;
