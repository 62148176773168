import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';

export default function TopStylesPieChart({ topStyles }) {
  // Process the topStyles data for the PieChart
  const processChartData = (styles) => {
    if (!styles || styles.length === 0) {
      return [];
    }

    // Convert the topStyles data to the format required by the PieChart
    return styles.map((style) => {
      const isOther = style.dgiStyle === 'other';
      return {
        id: isOther ? 'Other Styles' : style.style,
        value: style.quantity,
        label: isOther ? 'Other' : `${style.brand} ${style.style}`, // Set label to 'Other' if dgiStyle is 'other'
      };
    });
  };

  const chartData = processChartData(topStyles);

  return (
    <Box sx={{ textAlign: 'center', height: '100%' }}>
      <PieChart
        series={[
          {
            data: chartData,
            outerRadius: 170, // Adjust outer radius to better fit within the container
            cx: '70%', // Center the chart horizontally
            cy: '50%', // Center the chart vertically
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 4, additionalRadius: -1, opacity: 0.8 }, // Adjust opacity for a faded effect
          },
        ]}
        width={380}
        height={440}
        slotProps={{
          legend: {
            hidden: true, // Keep the legend hidden
          },
        }}
      />
      <Typography variant="h5" sx={{ mt: 1, color: 'rgba(65, 75, 86, 1)' }}>
        Top Styles
      </Typography>
    </Box>
  );
}
