import React from "react";
import { Box, Typography } from "@mui/material";
import Notifications from "./Notifications";

export default function NotificationContainer() {
  return (
    <Box
      sx={{
        border: "1px solid #d2d6da",
        borderRadius: "4px",
        overflow: "hidden",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          height: "3rem",
        }}
      >
        <Typography variant="h6"
          sx={{
            color: "#676F78"
          }}
        >
          Notifications
        </Typography>
      </Box>

      <Box
        sx={{
          borderTop: "1px dashed #d2d6da",
          
        }}
      >
        <Box sx={{ padding: "0.5rem" }}>
          <Notifications />
        </Box>
      </Box>
    </Box>
  );
}
