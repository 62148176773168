import React from "react";

import { Image } from "@aws-amplify/ui-react";
import header_logo from "./header_logo.png";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

export default ({}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.headerLogoWrapper} onClick={() => navigate("/")}>
      <Image alt="logo" src={header_logo} />
    </div>
  );
};
