import React, { useState, useEffect } from "react";
import HeaderLogo from "./HeaderLogo";
import StatusBar from "./StatusBar";
import styles from "./styles.module.scss";
import UserInfoFields from "./UserInfoFields";
import DistributorInfo from "./DistributorInfo";
import DecoratingInfo from "./DecoratingInfo";
import BuyingGroupInfo from "./BuyingGroupInfo";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ConfirmButton from "./ConfirmButton";
import ax from "../../axios/prod_client.js";
import endpoints from "../../axios/endpoints.js";
import { useNavigate } from "react-router-dom";
import { useUserStateContext } from "App";
import { useForm } from "react-hook-form";
import { useDataContext } from "App";
import { useMediaQuery } from "@mui/material";

function extractCookieFpromTid() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("_fprom_tid=")) {
      return cookie.substring("_fprom_tid=".length);
    }
  }
  return null; // Return null if the cookie is not found
}

function SignUpPage() {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [state, setState] = useState("");
  const [checkedOptions, setCheckedOptions] = useState(new Set());
  const [decoratingOptions, setDecoratingOptions] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false);
  const [buyingGroupOption, setBuyingGroupOption] = useState(new Set());
  const [buyingGroupName, setBuyingGroupName] = useState("");
  const isOnMobile = useMediaQuery("(max-width:800px)");

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const capitalizeWords = (string) => {
    return string
      .split(" ")
      .map((word) =>
        word
          .split("-")
          .map(
            (part) =>
              part.charAt(0).toUpperCase() + part.substring(1).toLowerCase()
          )
          .join("-")
      )
      .join(" ");
  };

  const { userCreds, setHasUserZip } = useUserStateContext();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const cleanedData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => key !== "" && value !== "")
      );
      await ax.put(endpoints.users(userCreds.cognitoID), {
        generic: true,
        signup: true,
        ...cleanedData,
        firstName: capitalizeWords(data?.firstName),
        lastName: capitalizeWords(data?.lastName),
        role: role,
        state: state,
        distributors_in_use: Array.from(checkedOptions),
        decorating_info: Array.from(decoratingOptions),
        buyingGroupName: buyingGroupName,
        tid: extractCookieFpromTid(),
        email_mobile_sign_up: isOnMobile,
      });
      setHasUserZip(true);
      window.rdt("track", "SignUp");
      navigate("/integrations?mode=tutorial");
    } catch (error) {
      console.error(`Failed to update user info with error: ${error.message}`);
      setDisplayErrorMsg(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderLogo />
      <StatusBar />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mt-2 items-center md:max-w-l max-w-sm" // Add px-{value} here
      >
        <UserInfoFields
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          role={role}
          state={state}
          setRole={setRole}
          setState={setState}
        />
        <DistributorInfo
          checkedOptions={checkedOptions}
          setCheckedOptions={setCheckedOptions}
        />
        <DecoratingInfo
          decoratingOptions={decoratingOptions}
          setDecoratingOptions={setDecoratingOptions}
        />
        <BuyingGroupInfo
          buyingGroupOption={buyingGroupOption}
          setBuyingGroupOption={setBuyingGroupOption}
          buyingGroupName={buyingGroupName}
          setBuyingGroupName={setBuyingGroupName}
        />
        <ConfirmButton
          loading={loading}
        >
          Submit
        </ConfirmButton>
      </form>
    </div>
  );
}

export default SignUpPage;
