import React from "react";
import { Box, Typography } from "@mui/material";
import { useUserStateContext } from "App";

export default function ShortcutsProfile() {
  const { userCreds } = useUserStateContext();

  return (
    <Box
      sx={{
        width: "360px",
        height: "240px",
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
        padding: "0.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        flexShrink: 0,
        '@media (max-width: 750px)': {
          width: "100%",
          height: "200px",
          paddingBottom: 0,
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          borderBottom: "1px dashed #d2d6da",
          paddingBottom: "0.5rem",
          width: "100%",
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {userCreds.shopName}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "#676F78",
          textAlign: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Box>
          DGI syncs with all of your suppliers to help compare pricing, inventory, shipping time, and order history.
        </Box>
        <Box sx={{ mt: 4 }}>
          Need help? Chat with us using the button in the bottom right of your screen!
        </Box>
      </Typography>
    </Box>
  );
}
