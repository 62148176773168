import React from "react";
import ExplainerVideo from "./ExplainerVideo";
import Footer from "./Footer";
import PageMockup from "./PageMockup";
import LandingPageFAQ from "./LandingPageFAQ";
import { faqData } from "constants/landingPageFAQs";
import {
  shopPageHeader,
  cartPageHeader,
  shopPagePoints,
  cartPagePoints,
} from "./productMockupData";

function ProductDemo() {
  return (
    <div className="flex max-w-[2400px] w-full flex-col items-center">
      <div className="w-full flex flex-col gap-[4rem] sm:gap-[7rem] bg-[#fff] pb-[4rem]">
        <ExplainerVideo />
        <PageMockup
          mockupHeader={shopPageHeader}
          mockupPoints={shopPagePoints}
          mockupSrc={"https://d34c0c1in94n75.cloudfront.net/desktop_shop.svg"}
          reverseLayout={true}
        />
        <PageMockup
          mockupHeader={cartPageHeader}
          mockupPoints={cartPagePoints}
          mockupSrc={"https://d34c0c1in94n75.cloudfront.net/desktop_cart.svg"}
        />
      </div>
      <LandingPageFAQ
        questionsText={"Questions?"}
        answersText={"We've got answers"}
        faqData={faqData}
      />
      <Footer />
    </div>
  );
}

export default ProductDemo;
