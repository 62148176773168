import { createSlice } from '@reduxjs/toolkit';

export const LOADING_STATES = {
  IDLE_STATUS: "idle",
  LOADING_STATUS: "loading",
  SUCCESS_STATUS: "success",
  FAILURE_STATUS: "failure",
};

const statusesThatRequireDataRequests = new Set([LOADING_STATES.IDLE_STATUS]);

const cartsSlice = createSlice({
  name: "carts",
  initialState: { carts: {} },
  reducers: {
    fetchCartsStartBatch(state, action) {
      const { vendorCodes, onlyIfIdle } = action.payload;
      const updatedCarts = {};
      const updatedVendors = [];

      vendorCodes.forEach((vendorCode) => {
        const cartLoadingStatus = state.carts?.[vendorCode]?.loading;
        if (
          (onlyIfIdle && cartLoadingStatus === LOADING_STATES.IDLE_STATUS)
          || (!onlyIfIdle && cartLoadingStatus !== LOADING_STATES.LOADING_STATUS)
        ) {
          updatedCarts[vendorCode] = {
            ...state.carts?.[vendorCode],
            loading: LOADING_STATES.LOADING_STATUS,
            error: null,
          };
          updatedVendors.push(vendorCode);
        }
      });

      state.carts = {
        ...state.carts,
        ...updatedCarts,
      };
      action.payload.updatedVendors = updatedVendors;
    },

    fetchCartSuccess(state, action) {
      const { vendorCode, cartData } = action.payload;
      state.carts[vendorCode] = {
        ...state.carts[vendorCode],
        loading: LOADING_STATES.SUCCESS_STATUS,
        error: null,
        totalPrice: cartData?.totalPrice ?? 0,
        ...cartData,
      };
    },

    fetchCartFailure(state, action) {
      const { vendorCode, errorCode, errorMessage } = action.payload;
      state.carts[vendorCode] = {
        ...state.carts[vendorCode],
        loading: LOADING_STATES.FAILURE_STATUS,
        error: { [errorCode]: errorMessage },
        data: { items: [], totalPrice: 0.0 },
      };
    },
  },
  selectors: {
    selectCart: (state, vendorCode) => state.carts?.[vendorCode],
    selectCarts: (state, vendorCodes) => {
      const selectedCarts = {};
      for (const code of vendorCodes) {
        selectedCarts[code] = state.carts?.[code];
      }
      return selectedCarts;
    },
  },
});

export default cartsSlice.reducer;
export const {
  selectCart,
  selectCarts,
} = cartsSlice.selectors

export const {
  fetchCartsStartBatch,
  fetchCartSuccess,
  fetchCartFailure,
} = cartsSlice.actions
