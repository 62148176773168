import React, { useState } from "react";

import styles from "./styles.module.scss";
import VendorCart from "./VendorCart";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { cartVendorFilters } from "../../constants/cartVendorFilters";
import { useDataContext } from "App";
import AlertSnackbar from "alerts/AlertSnackbar";
import CartsHeader from "./CartsHeader";

export const getVendorName = (vendorCode) => {
  const targetFilter = cartVendorFilters[vendorCode];
  return targetFilter?.name;
};

export const sortByVendorName = (vendorA, vendorB) => {
  if (!vendorA) {
    return
  }
  const vendorAName = getVendorName(vendorA)
  const vendorBName = getVendorName(vendorB)
  return vendorAName.localeCompare(vendorBName);
};

function VendorCartWrapper({
  selectedFilter,
  loadingCarts,
  connectedVendorCodes,
}) {
  let sortedVendorCarts = []
  const vendorCodes =
    Object.entries(connectedVendorCodes)
      .filter((vendor) => {
        return vendor[1];
      })
      .map((vendor) => {
        const dataKey = vendor[0];
        return dataKey?.split("_")[0];
      })
  if (connectedVendorCodes) {
    sortedVendorCarts = vendorCodes.sort(sortByVendorName);
  }

  if (loadingCarts) {
    return (
      <div className={styles.loadingCartsSpinner}>
        <CircularProgress size="80px" />
        <div className={styles.loadingCartsMsg}>
          <span>Loading carts...</span>
        </div>
      </div>
    );
  } else if (selectedFilter != "all" && sortedVendorCarts) {
    return (
      <div className={styles.vendorCarts}>
        <VendorCart
          key={`${selectedFilter}-vendorCart`}
          vendorCode={selectedFilter}
          vendorName={getVendorName(selectedFilter)}
        />
      </div>
    );
  } else if (sortedVendorCarts) {
    return (
      <div className={styles.vendorCarts}>
        {sortedVendorCarts?.map((element, _) => (
          <VendorCart
            key={`${element}-vendorCart`}
            vendorCode={element}
            vendorName={getVendorName(element)}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
}
export default function CartCheckout({
  selectedFilter,
  loadingCarts,
  setLoadingCarts,
}) {
  const { connectedVendorCodes } = useDataContext();
  const [refreshSuccessOpen, setRefreshSuccessOpen] = useState(false);
  const [refreshErrorOpen, setRefreshErrorOpen] = useState(false);

  const handleCloseRefreshSuccess = () => {
    setRefreshSuccessOpen(false);
  };

  const handleCloseRefreshError = () => {
    setRefreshErrorOpen(false);
  };

  return (
    <div className={styles.cartCheckout} data-loading-carts={loadingCarts}>
      <Stack spacing={2} useflexgap sx={{ marginBottom: "24px" }}>
        <CartsHeader
          selectedFilter={selectedFilter}
          connectedVendorCodes={connectedVendorCodes}
        />
        <VendorCartWrapper
          selectedFilter={selectedFilter}
          loadingCarts={loadingCarts}
          connectedVendorCodes={connectedVendorCodes}
          setLoadingCarts={setLoadingCarts}
        />
      </Stack>
      <AlertSnackbar
        open={refreshSuccessOpen}
        severity="success"
        text="Successfully Refreshed Carts"
        handleClose={handleCloseRefreshSuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <AlertSnackbar
        open={refreshErrorOpen}
        severity="error"
        text="Unable to Refresh Carts"
        handleClose={handleCloseRefreshError}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
}
