// AccountCreationPrompt.js

import React from "react";
import { Typography, Link } from "@mui/material";

function AccountCreationPrompt({ accountCreationLink }) {
  return (
    <div className="flex gap-[4px] pt-[14px]">
      <Typography className="text-charcoal">Don't have an account?</Typography>
      <Link
        id={accountCreationLink}
        className="cursor-pointer no-underline text-denim"
        sx={{
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        href={accountCreationLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Create a wholesale account
      </Link>
    </div>
  );
}

export default AccountCreationPrompt;
