import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import NavButtons from "./NavButtons";
import { useParams, useSearchParams } from "react-router-dom";
import vendors from "constants/vendors";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import DGITeamPic from '../../assets/DGITeamPic.png'

const ReceiptPage = () => {
  const { vendorCode } = useParams();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');
  const [vendorName, setVendorName] = useState();

  useEffect(() => {
    const newVendorName = vendors[vendorCode + "_data"]["name"];
    setVendorName(newVendorName);
  }, [vendorCode]);

  const handleCopyOrderId = () => {
    navigator.clipboard.writeText(orderId)
      .then(() => {
        alert("Order ID copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy Order ID:", err);
        alert("Failed to copy the Order ID.");
      });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText("www.dgiapparel.com")
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy text:", err);
        alert("Failed to copy the link.");
      });
  };

  return (
    <Box
      sx={{
        maxWidth: 1000,
        width: "100%",
        margin: "0 auto",
        paddingBottom: 6,
        paddingTop: 4,
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        '& .receiptSection': {
          border: "1px solid lightgray",
          borderLeft: "2px solid rgb(2, 129, 192)" // lighterblueblue
        },
      }}
    >
      <Box
        sx={{
          border: "1px solid #414B56",
          padding: 2,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "600px",
          mt: 0,
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <CheckCircleIcon className="fill-actionGreen" sx={{ fontSize: 38, marginRight: 2 }} />
          <Typography variant="h5" className="text-actionGreen">
            Thank you, your order has been placed.
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ color: "black", textAlign: "center", color: "#414B56" }}>
          You should receive a confirmation email from {vendorName} shortly.
        </Typography>
        {orderId && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1.5 }}>
            <Typography variant="body1" sx={{ textAlign: "center", color: "#414B56" }}>
              Confirmation Number: {orderId}
            </Typography>
            <IconButton onClick={handleCopyOrderId} size="small" sx={{ ml: 1, color: "#414B56" }}>
              <ContentCopyIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <NavButtons vendorName={vendorName} />
      <Typography variant="h6" sx={{ width: "600px", textAlign: "center", mb: 2, mt: 6, fontWeight: '500', color: "#414B56" }}>
        Please share us!
      </Typography>
      <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            mb: 4,
            padding: 1,
            border: '1px solid #414B56',
            borderRadius: '4px'
        }}>
            <Typography variant="body1" sx={{ userSelect: 'none', ml: 1, color: "#414B56" }}>
                www.dgiapparel.com
            </Typography>
            <IconButton onClick={handleCopy} sx={{ marginLeft: 2, color: "#414B56" }}>
                <ContentCopyIcon />
            </IconButton>
        </Box>
        <Typography variant="body1" sx={{ width: "620px", textAlign: "center", mb: 2, mt: 0, color: "#414B56" }}>
          We would greatly appreciate your help by sharing our website with others who might benefit from DGI. Referrals from people like you help us to continue to grow and make improvements to the DGI project while keeping it free for everyone. Thank you!
        </Typography>
        <Typography variant="body1" sx={{ width: "600px", textAlign: "center", mb: 2, mr: 2, color: "#414B56" }}>
          - Daniel, Grant & Ian
        </Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
          <img src={DGITeamPic} alt="DGI Team" style={{ maxWidth: '400px', width: '100%', borderRadius: '5px' }} />
      </Box>
    </Box>
  );
};

export default ReceiptPage;
