import axios from "axios";
import { Auth } from "aws-amplify";

const SS_BASE_API_URLS = {
  development:
    "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com/go-server",
  staging: "https://fcba17gw9k.execute-api.us-east-1.amazonaws.com/go-server",
  production:
    "https://byyhh7s57b.execute-api.us-east-1.amazonaws.com/go-server",
};

const SS_BASE_API_URL =
  process.env.REACT_APP_LOCAL_DEV === "true"
    ? "http://127.0.0.1:8080/go-server"
    : SS_BASE_API_URLS[process.env.NODE_ENV] || SS_BASE_API_URLS.production;

const ssClient = axios.create({
  baseURL: SS_BASE_API_URL,
});

ssClient.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const jwtToken = session.accessToken.jwtToken;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${jwtToken}`,
    },
  };
});

export default ssClient;
