import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import lilProductClient from '../../axios/lilProductClient';
import endpoints from '../../axios/endpoints';

const ProductImage = ({ imageUrl, vendor }) => {
  const navigate = useNavigate();

  const handleImageClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const filename = imageUrl.split("/").splice(-1);
    const [dgiStyle, firstColor] = filename[0].split("-");
    if (dgiStyle && firstColor) {
      const encodedColor = encodeURIComponent(firstColor);
      navigate(`/product/${dgiStyle}/${encodedColor}`);
    }
  };

  if (!imageUrl && (vendor === 'cm' || vendor === 'ss')) {
    return null;
  }

  if (!imageUrl) {
    return (
      <Box
        sx={{
          width: '60px',
          height: '70px',
          bgcolor: '#F6F6F6',
          border: '1px solid #d2d6da',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#d2d6da',
          borderRadius: 1,
        }}
        onClick={handleImageClick}
      >
        <Typography variant="h6" sx={{ color: 'inherit', marginBottom: 0 }}>
          DGI
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      component="img"
      src={imageUrl}
      alt="Product"
      sx={{
        width: '70px',
        height: '80px',
        objectFit: 'cover',
        marginRight: '8px',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
      onClick={handleImageClick}
    />
  );
};

export default ProductImage;
