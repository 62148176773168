import React from "react";

import styles from "./styles.module.scss";
import SideVendorCart from "./SideVendorCart";

const SideVendorCarts = ({ vendorCarts, vendorTotals, hidePrices }) => {

  const sortVendorDataBySubtotals = (vendorCartA, vendorCartB) => {
    const vendorCodeA = vendorCartA[0];
    const vendorCodeB = vendorCartB[0];

    if (
      vendorTotals?.[vendorCodeA] === undefined ||
      vendorTotals?.[vendorCodeB] === undefined
    ) {
      return 0;
    }

    const cartSubtotalA = vendorTotals[vendorCodeA]["subtotal"];
    const cartSubtotalB = vendorTotals[vendorCodeB]["subtotal"];

    if (cartSubtotalB < cartSubtotalA) {
      return -1;
    } else if (cartSubtotalA < cartSubtotalB) {
      return 1;
    }

    return 0;
  };

  if (Object.keys(vendorCarts).length === 0) {
    return
  }
  const sortedVendorCarts = Object.entries(vendorCarts)
    .sort(sortVendorDataBySubtotals);

  return (
    <div className={styles.sideVendorCarts}>
      {sortedVendorCarts.map((vendorCart) => (
        <SideVendorCart
          key={vendorCart[0]}
          vendorCode={vendorCart[0]}
          vendorData={vendorCart[1]}
          totals={vendorTotals?.[vendorCart[0]]}
          hidePrices={hidePrices}
        />
      ))}
    </div>
  );
};

export default SideVendorCarts;
