import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import CartFilter from "./CartFilter";
import { getVendorName } from "./CartCheckout";
import { useSelector } from "react-redux";

const sortByVendorCode = (a, b) => {
  if (!a) {
    return
  }
  const vendorAName = getVendorName(a)
  const vendorBName = getVendorName(b)
  return vendorAName.localeCompare(vendorBName);
};


export default function CartFilterBar({
  selectedFilter,
  displayFilters,
  setSelectedFilter,
}) {
  const displayCartData = useSelector(state => state.carts?.carts)
  const [vendorQuantityTotals, setVendorQuantityTotals] = useState({});

  useEffect(() => {
    if (displayCartData) {
      const newVendorQuantityTotals = {};
      let allQuantityTotal = 0;
      for (const cartData of Object.entries(displayCartData)) {
        const [vendorCode, vendorData] = cartData;
        if (!vendorData["items"]) {
          continue;
        }
        let quantityTotal = 0;
        for (const item of vendorData["items"]) {
          const quantityExists = Object.keys(item).includes("quantity");
          if (quantityExists) {
            quantityTotal += item["quantity"];
          }
        }
        allQuantityTotal += quantityTotal;
        newVendorQuantityTotals[vendorCode] = quantityTotal;
      }
      newVendorQuantityTotals["all"] = allQuantityTotal;
      setVendorQuantityTotals(newVendorQuantityTotals);
    }
  }, [displayCartData]);

  return (
    <div className={styles.cartFilterBar}>
      {displayFilters.sort((a, b) => (sortByVendorCode(a[1], b[1]))).map((filter) => (
        <CartFilter
          key={filter[1]}
          filterName={filter[0]}
          filterCode={filter[1]}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          vendorQuantityTotals={vendorQuantityTotals}
        />
      ))}
    </div>
  );
}
