import React from "react";
import styles from "./styles.module.scss";

import Stack from "@mui/material/Stack";
import { CheckoutHeaderSteps } from "custom/text/StandardCheckoutTexts";
import { CheckoutHeaderVendorName } from "custom/text/StandardCheckoutTexts";

const ConfirmationHeader = ({ vendorName }) => {
  return (
    <Stack className={`confirmationSection ${styles.confirmationHeader}`}>
      <CheckoutHeaderVendorName vendorName={vendorName} />
      <CheckoutHeaderSteps activeStep="confirmation" />
    </Stack>
  );
};

export default ConfirmationHeader;
