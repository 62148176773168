import React from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";

const PageMockupWithPoints = ({
  mockupHeader,
  mockupPoints,
  mockupSrc,
  magnifiedAsset = false,
  reverseLayout = false,
}) => {
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box
        className={`flex ${
          reverseLayout && "flex-row-reverse"
        } justify-center items-center gap-[2rem]
          flex-wrap lg:flex-nowrap lg:gap-[6rem]`}
      >
        <Stack direction="column" alignItems="flex-center">
          <Box className="flex flex-col justify-center items-center">
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              className="text-blueblue text-center"
            >
              {mockupHeader.title}
            </Typography>
            <Typography variant="h6" paragraph className="text-wolfGrey text-center">
              {mockupHeader.subtitle}
            </Typography>
          </Box>
          <Grid container spacing={4} sx={{ mb: 6 }} direction="column" className="max-w-[565px]">
            {mockupPoints.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <item.icon
                    sx={{ fontSize: 48, mb: 2 }}
                    className="text-hardOrange"
                  />
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    className="text-wolfGrey font-light"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" className="text-wolfGrey text-lg">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Box className={`flex justify-center ${magnifiedAsset && "overflow-hidden"}`}>
          <img
            src={mockupSrc}
            alt={null}
            style={{
              width: `${magnifiedAsset ? "112%" : "auto"}`,
              height: "auto",
              maxHeight: "750px",
              maxWidth: "98%",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ProductMockup = ({
  mockupHeader,
  mockupPoints,
  mockupSrc,
  reverseLayout = false,
}) => {
  return (
    <PageMockupWithPoints
      mockupHeader={mockupHeader}
      mockupPoints={mockupPoints}
      mockupSrc={mockupSrc}
      magnifiedAsset={false}
      reverseLayout={reverseLayout}
    />
  );
};

export default ProductMockup;
