export default {
  products: () => `/products`,
  getABPrice: (id) => `/product/${id}/ab`,
  getSMPrice: (id) => `/product/${id}/sm`,
  getCMPrice: (id) => `/product/${id}/cm`,
  getSSPrice: (id) => `/product/${id}/ss`,
  getInventory: (distributor) => `/${distributor}/inventory`,
  getMiscInventory: () => `/misc/inventory`,
  users: (id) => `/users/${id}`,
  vendors: (code) => `/vendors/${code}`,
  cart: () => `/cart`,
  cartAdd: () => `/cart/add`,
  cartWarehouses: () => `/cart/warehouses`,
  checkoutDetails: (id) => `/checkout/${id}`,
  shippingMethodUpdate: (distributor) => `/shippingmethod/${distributor}`,
  checkout: (distributor) => `/checkout/${distributor}`,
  checkoutFinalize: (distributor) => `/checkout/${distributor}/finalize`,
  getShippingMethods: (distributor) =>
    `/checkout/${distributor}/shippingMethods`,
  getProductInfo: (dgiStyle) => `/styles/${dgiStyle}`,
  invoices: (userId) => `/${userId}/invoices`,
  invoiceStockStatusGet: (userId, invoiceId) =>
    `/${userId}/${invoiceId}/invoices`,
  orders: (userId) => `/orders/${userId}`,
};
