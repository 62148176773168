import React from "react";
import TeamSection from "./TeamSection";
import StorySection from "./StorySection";
import StoryPageFAQ from "./StoryPageFAQ";
import Footer from "../landing_page/Footer";

const OurStory = () => {
  return (
    <div className="bg-[#fff] flex max-w-[2400px] w-full flex-col items-center">
      <TeamSection />
      <StorySection />
      <StoryPageFAQ />
      <Footer />
    </div>
  );
};

export default OurStory;
