import { useMemo } from "react";
import RefinementLists from "./RefinementLists";
import { Drawer } from "@mui/material";

export default function MobileRefinementsDrawer({
  openFacetsDrawer,
  setOpenFacetsDrawer,
  drawerMarginTop,
}) {
  const memoizedRefinements = useMemo(() => <RefinementLists />, []);

  return (
    <Drawer
      disableEnforceFocus
      anchor="left"
      open={openFacetsDrawer}
      onClose={() => setOpenFacetsDrawer(false)}
      ModalProps={{ keepMounted: true }}
      sx={{
        "& > *": {
          marginTop: `${drawerMarginTop}`,
        },
        "& > .MuiPaper-root": {
          width: "215px",
        },
      }}
    >
      {memoizedRefinements}
    </Drawer>
  );
}
