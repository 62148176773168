import React from "react";

import styles from "./styles.module.scss";
import Stack from "@mui/material/Stack";
import { CheckoutHeaderSteps } from "custom/text/StandardCheckoutTexts";
import { CheckoutHeaderVendorName } from "custom/text/StandardCheckoutTexts";

const CheckoutHeader = ({ vendorName }) => {
  return (
    <Stack className={`checkoutSection ${styles.checkoutHeader}`}>
      <CheckoutHeaderVendorName vendorName={vendorName} />
      <CheckoutHeaderSteps activeStep="options" />
    </Stack>
  );
};

export default CheckoutHeader;
