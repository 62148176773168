import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

export default function Shortcuts() {
  const navigate = useNavigate();

  const shortcuts = [
    { label: "Shop", link: "/shop", color: "#FF5733", icon: <ShoppingBagOutlinedIcon /> }, // Red
    { label: "Carts", link: "/cart", color: "#33FF57", icon: <ShoppingCartOutlinedIcon /> }, // Green
    { label: "Order History", link: "/order-history", color: "#3357FF", icon: <ImportContactsOutlinedIcon /> }, // Blue
    { label: "Connect Vendors", link: "/integrations", color: "#FFD700", icon: <SyncOutlinedIcon /> }, //Yellow
  ];

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        overflowX: "auto",
        width: "100%",
      }}
    >
      {shortcuts.map((shortcut, index) => (
        <Box
          key={index}
          onClick={() => navigate(shortcut.link)}
          sx={{
            width: "200px",
            height: "240px",
            border: "1px solid #d2d6da",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "4px",
            padding: "0.5rem",
            transition: "box-shadow 0.3s ease",
            marginBottom: "1.5rem",
            "&:hover": {
              boxShadow: `
                0 15px 35px rgba(50, 50, 93, 0.1),
                0 5px 15px rgba(0, 0, 0, 0.07)
              `,
              cursor: "pointer",
            },
            flexShrink: 0,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              textAlign: "left",
              marginBottom: "0.5rem",
              borderBottom: `5px solid ${shortcut.color}`,
              paddingBottom: "0.25rem",
              paddingLeft: "0.25rem",
            }}
          >
            {shortcut.label}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {React.cloneElement(shortcut.icon, { sx: { fontSize: 80, color: "#414B56" } })}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
