import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { suggestVendorConnections } from "constants/vendors";
import vendors from "constants/vendors";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDataContext } from "App";

function SuggestVendorConnection({ vendorCode }) {
  const showOnlySyncedVendors = useSelector(
    (state) => state.user?.user?.show_only_synced_vendors
  );
  const { connectedVendorCodes } = useDataContext();
  const navigate = useNavigate();
  const vendorDataKey = vendorCode + "_data";
  const displayConnectBtn =
    suggestVendorConnections.has(vendorCode) &&
    !connectedVendorCodes[vendorDataKey] &&
    !showOnlySyncedVendors;
  const vendorName = vendors[vendorDataKey]?.["name"];
  if (displayConnectBtn) {
    return (
      <Box
        className={`flex flex-col items-center 
            gap-[1.5rem] py-[1.25rem]`}
      >
        <Typography className="text-xl">
          Connect to {vendorName} to see inventory
        </Typography>
        <Button
          className={`bg-blueblue hover:bg-denim 
            text-grayscaleWhite text-xl normal-case
            py-[6px] px-[14px]`}
          onClick={() => navigate("/integrations")}
        >
          Connect
        </Button>
      </Box>
    );
  }
  return null;
}

export default SuggestVendorConnection;
